import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';
// import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/system';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import TableTopMenu from './TableTopMenu';
import { useNavigate } from 'react-router-dom';
import { setSelectedSendSurvey } from '../../store/reducers/facultyPerformanceReview.reducer';
import MaterialButton from '../common/MaterialButton';
import { customBoxShadow } from '../../lib/utils';

const StyledTableCell = styled(TableCell)(({ theme,onClick }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#ffdfd6',
    color: 'black',
  },
  padding: '3px 5px',
  width : 'max-content',
  cursor :  `${onClick ? `pointer !important` : 'inherit'}`
}));


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const {
    headArray,
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    sendSurveyMode,
    areSingleUnitAllRowsSelected,
    handleSingleUnitAllRowsCheckBoxClick
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headArray.map((headCell) => (

          headCell.id !== 'feedbacks' ?
          <StyledTableCell
            key={headCell.id}
            align={"center"}
            // padding={headCell.disablePadding ? "none" : "normal"}
            // padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell> 
          : 
          ["Unit I", "Unit II", "Unit III", "Unit IV","Unit V", "Unit VI"].map((unit,index)=>{
            let singleUnitAllRowsSelected = false;
            if (sendSurveyMode) {
              singleUnitAllRowsSelected = areSingleUnitAllRowsSelected(index);
            }
             
          
            return (<StyledTableCell
            key={unit}
            align={"center"}
            // padding={headCell.disablePadding ? "none" : "normal"}
            // padding='normal'
            >
              <span>
                {unit}
              </span>
              <span key={unit} align="center">
                {sendSurveyMode? 
                    <Checkbox
                        color="primary"
                        checked={singleUnitAllRowsSelected}
                        inputProps={{
                          "aria-labelledby": `${unit}`,
                        }}
                        onClick={() =>
                          handleSingleUnitAllRowsCheckBoxClick(index)
                        }
                        />                            
                : null}
              </span>
            </StyledTableCell> 
            )

          })


        ))}
        {sendSurveyMode && <StyledTableCell align={"center"}>
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all rows",
            }}
          /> */}
          All Units
        </StyledTableCell>}
        
        {!sendSurveyMode && <StyledTableCell
          align={"center"}
        >
          Actions
        </StyledTableCell>}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected,heading, handleCancelSendSurvey, sendSurveyMode, handleSendSurvey } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        borderRadius : '20px 20px 0 0',
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {sendSurveyMode ? (
        <Typography
          sx={{ flex: "1 1" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
          align="center"
        >
          {heading}
        </Typography>
      )}

      {sendSurveyMode ? (
        //  <Tooltip title="Delete">
        //   <IconButton>
        //     <DeleteIcon />
        //   </IconButton> 
        // </Tooltip>
        <React.Fragment>
          <MaterialButton onClick={handleCancelSendSurvey} type='secondary' label={'Cancel'} sx={{mr:2}}/>
          <MaterialButton onClick={handleSendSurvey}  disabled={numSelected < 1} label={'Send Survey'}/>
        </React.Fragment>
      ) : (
        <Tooltip title="Table Actions">
          <IconButton onClick={props.handleMainMenuClick}>
            <ArrowDropDownCircleIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


FacultyPerformanceTable.propDefaults = {
  heading : 'Table',
  headArray : [],
  primaryKey : 'id',
  sendSurveyMode : false,
  openSendSurveyModal : false,
  setSendSurveyMode : null,
}


// We get an array of data, one of the keys in the array should have primary key to tag the map key correctly. 
// A head array which is used for extracting the data from array into the table, in the order we want to show it in.
// A heading is provided.
// It has a download Excel Option


export default function FacultyPerformanceTable({data,heading,headArray,primaryKey,sendSurveyMode,setSendSurveyMode, setOpenSendSurveyModal}) {

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(headArray[0]['id']);
  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [mainMenuOpen, setMainMenuOpen] = React.useState(null);
  const [singleColUnitsCatData, setSingleColUnitsCatData] = React.useState(null);

  // const [openSendSurveyModal, setOpenSendSurveyModal] = React.useState(null);
  // const [sendSurveyMode, setSendSurveyMode] = React.useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch(); 


  const {filteredAllFacPerRevData, selectedSendSurvey} = useSelector((state) => state.FacultyPerformanceReview);
  // console.log(selectedSendSurvey)

  React.useEffect(()=>{
    // When data is changed like when filter is applied page
    //  change should be done as we run into errors if the user is in any other page.
    // console.log('auto page change triggered')
    setPage(0)
    
  },[data])

  // ------- Getting units data categoried into columns

  const getSingleUnitAllRowsData = React.useCallback(() => {
    const singleColUnitsCatData = [0, 1, 2, 3, 4, 5].map((unitIndex) => {
      const singleUnitAllRowsData = data
        ?.filter((rowData) => {
          return rowData?.feedbacks[unitIndex];
        })
        ?.map((rowData) => {
          const newRow = { ...rowData, ...rowData?.feedbacks[unitIndex] };
          delete newRow?.feedbacks;
          return newRow;
        });

      return singleUnitAllRowsData;
    });

    setSingleColUnitsCatData(singleColUnitsCatData)
  },[data])

  React.useEffect(()=>{
    getSingleUnitAllRowsData()
  },[getSingleUnitAllRowsData])

//  --------------------------------------

  if (!filteredAllFacPerRevData?.length) {
    return;  
  }

  // Adding Header Array IDs as keys to the Table
  const tableKeys = headArray?.map((head)=>{
    return head.id;
  })
  // console.log(tableKeys)

  // console.log(data)
  // Adding data to rows
  const rows = data;


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelected = rows.map((n) => n[primaryKey]);
  //     setSelected(newSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleRowCheckboxClick = (row) => {

    // const name = row[primaryKey];
    // const selectedIndex = selected.indexOf(name);
    const selectedIndex = isRowSelected(row[primaryKey]);
    console.log(selectedIndex);
    let newselectedSendSurvey = [];

    if (!selectedIndex) {
      // newSelected = newSelected.concat(selected, name);

      row?.feedbacks?.forEach((unitData)=>{

        // If the unit already exists we'll not add/ dispatch it to the state.
        // if(selectedSendSurvey?.some((selectedUnitData) => selectedUnitData[primaryKey] === row[primaryKey]
        // &&  selectedUnitData?.unit_id === unitData?.unit_id))
        if (
          selectedSendSurvey?.some(
            (selectedUnitData) =>
              selectedUnitData?.unit_id === unitData?.unit_id &&
              selectedUnitData?.college_account_subject_college_department_section ===
                row?.college_account_subject_college_department_section
          )
        )
         {
          return
        }

        const newRow = {...row,...unitData};
        delete newRow?.feedbacks;
        console.log(newRow);
        
        newselectedSendSurvey.push(newRow);
    
      })
      newselectedSendSurvey = [...selectedSendSurvey,...newselectedSendSurvey];
      console.log(newselectedSendSurvey)
    }

    else {
      newselectedSendSurvey = [...selectedSendSurvey]
      row?.feedbacks?.forEach((unitData)=>{

        // const selectedIndex = newselectedSendSurvey.findIndex((selectedUnitData?.unique_unit === unique_unit) );
        const selectedIndex = newselectedSendSurvey.findIndex(((selectedUnitData) => selectedUnitData[primaryKey] === row[primaryKey]
        &&  selectedUnitData?.unit_id === unitData?.unit_id) );

        newselectedSendSurvey = [].concat(
          newselectedSendSurvey.slice(0, selectedIndex),
          newselectedSendSurvey.slice(selectedIndex + 1),
        );

      })
    }
    
    // else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1),
    //   );
    // }
    
    console.log(newselectedSendSurvey)
    dispatch(setSelectedSendSurvey({selectedSendSurvey : newselectedSendSurvey}));
    // setSelected(newSelected);



  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMainMenuClick = (event) => {
    setMainMenuOpen(event.currentTarget);
  };

  // Event.currentTarget is required by the 'Menu' component in the Material UI.
  const handleRowViewActionsClick = (event,row) => {
    // console.log(event.currentTarget);
    console.log(row);
    // /facultyPerformanceReview/:subsemId/:facId/:sectionId/:unitId
    navigate(`/facultyPerformanceReview/${row.subject_semester_id}/${row.college_account_id}/${row.section_id}/${row?.feedbacks[0]?.unit_id}`);
  };

  const handleUnitCheckBoxClick = (row,{unit_id,score,unit_name,unique_unit,has_feedback}) => {


    // const selectedIndex = selectedSendSurvey.findIndex(((selectedUnitData) => selectedUnitData?.unique_unit === unique_unit) );

    const selectedIndex = selectedSendSurvey.findIndex(((selectedUnitData) => selectedUnitData[primaryKey] === row[primaryKey]
    &&  selectedUnitData?.unit_id === unit_id) );

    let newselectedSendSurvey = [];

    if (selectedIndex === -1) {

      // console.log(row);
      const newRow = {...row,unit_id,score,unit_name,unique_unit,has_feedback};
      delete newRow?.feedbacks;
      // console.log(newRow);
      
      // console.log(selectedSendSurvey)
      newselectedSendSurvey = [...selectedSendSurvey,newRow];
    } 
    
    else{
      newselectedSendSurvey = newselectedSendSurvey.concat(
        selectedSendSurvey.slice(0, selectedIndex),
        selectedSendSurvey.slice(selectedIndex + 1),
      );
    }
    dispatch(setSelectedSendSurvey({selectedSendSurvey : newselectedSendSurvey}));

  };

  const handleViewUnitClick = (unit_id,row,index) => {
    // console.log(event.currentTarget);
    console.log(row);
    // /facultyPerformanceReview/:subsemId/:facId
    navigate(`/facultyPerformanceReview/${row.subject_semester_id}/${row.college_account_id}/${row.section_id}/${unit_id}`);
    // navigate(`/facultyPerformanceReview/506/1156`);

  };

  const handleSendSurveyButton = () =>{
    // dispatch(setSelectedSendSurvey({selectedSendSurvey : []}))
    console.log(selectedSendSurvey?.length)
    if(selectedSendSurvey?.length <= 10){
      setOpenSendSurveyModal(true);
    }
    else{
    // else if(selectedSendSurvey?.length > 10){
      navigate(`/facultyPerformanceReview/sendSurvey`)
    }
    // setSendSurveyMode(false);
  }

  const handleCancelSendSurvey = () =>{
    dispatch(setSelectedSendSurvey({selectedSendSurvey : []}))
    setSendSurveyMode(false);
  }
  
  const isRowSelected = (uniqueId) => {
    const rowData = rows.find((rowData)=>{
      return rowData[primaryKey] === uniqueId;
    })

    //  We are checking if every unit is selected in the row. Then select the row value if all are selected.
    // console.log(rowData)


    if(rowData?.feedbacks?.length === 0){
      return
    }

    const isSelected = rowData?.feedbacks?.every((unitData)=>{
      return selectedSendSurvey?.some((selectedUnitData) => selectedUnitData[primaryKey] === rowData[primaryKey]
      &&  selectedUnitData?.unit_id === unitData?.unit_id)
    })

    return isSelected;
    // return selected.indexOf(uniqueId) !== -1;

  };

  //  ---------- Handling Single Column All Units Selecting

  const areSingleUnitAllRowsSelected = (unitIndex) =>{

    if(!singleColUnitsCatData){
      return false
    }

    const areSelectedOrNot = singleColUnitsCatData[unitIndex]?.every((unitData)=>{
      return selectedSendSurvey?.some((selectedUnitData) => selectedUnitData[primaryKey] === unitData[primaryKey]
      &&  selectedUnitData?.unit_id === unitData?.unit_id)
    })
    
    // console.log(singleUnitAllRowsData);
    return areSelectedOrNot
  }

  const handleSingleUnitAllRowsCheckBoxClick = (unitIndex) =>{
    const singleUnitAllRowsData = singleColUnitsCatData ? singleColUnitsCatData[unitIndex] : [];

    const areSelectedOrNot =  areSingleUnitAllRowsSelected(unitIndex);
    let newselectedSendSurvey = [];

    // If not selected then we select all the nth column units or else we 
    // remove them all
    if (!areSelectedOrNot) {
      singleUnitAllRowsData?.forEach((unitData)=>{
        if (
          selectedSendSurvey?.some(
            (selectedUnitData) =>
              selectedUnitData?.unit_id === unitData?.unit_id &&
              selectedUnitData[primaryKey] ===
              unitData[primaryKey]
          )
        )
        {return}
        newselectedSendSurvey.push(unitData);
      })
      newselectedSendSurvey = [...selectedSendSurvey,...newselectedSendSurvey];
    }

    else {
      newselectedSendSurvey = [...selectedSendSurvey]
      singleUnitAllRowsData?.forEach((unitData)=>{
        // const selectedIndex = newselectedSendSurvey.findIndex((selectedUnitData?.unique_unit === unique_unit) );
        const selectedIndex = newselectedSendSurvey.findIndex(((selectedUnitData) => selectedUnitData[primaryKey] === unitData[primaryKey]
        &&  selectedUnitData?.unit_id === unitData?.unit_id) );

        newselectedSendSurvey = [].concat(
          newselectedSendSurvey.slice(0, selectedIndex),
          newselectedSendSurvey.slice(selectedIndex + 1),
        );

      })
    }

    console.log(newselectedSendSurvey)
    dispatch(setSelectedSendSurvey({selectedSendSurvey : newselectedSendSurvey}));
  }

  //  ------------------ 

  const isUnitSelected = (row,unit_id) => {

    const isIncluded = selectedSendSurvey.some((selectedUnitData)=>{
      return selectedUnitData[primaryKey] === row[primaryKey]
      &&  selectedUnitData?.unit_id === unit_id;
    })

    return isIncluded
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    // <Box sx={{ width: "100%" }}>
    <Box
      sx={{
        // width: "max-content",
        width: "100%",
        maxWidth : "1300px",
        boxShadow: customBoxShadow,
        borderRadius : '20px'
      }}
    >
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
      <Paper elevation={0} sx={{ width: "100%", mb: 1, borderRadius: '20px'}}>
      <EnhancedTableToolbar
          sendSurveyMode={sendSurveyMode}
          handleSendSurvey ={handleSendSurveyButton}
          handleCancelSendSurvey={handleCancelSendSurvey}
          handleMainMenuClick={handleMainMenuClick}
          heading={heading}
          numSelected={selectedSendSurvey?.length}
        />
        <TableContainer>
          <Table
            sx={{maxHeight: 200}}
            aria-labelledby="tableTitle"
            size={"small"}
            // size={dense ? "small" : "medium"}
            stickyHeader
          >
            <EnhancedTableHead
              numSelected={selectedSendSurvey?.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headArray={headArray}
              sendSurveyMode ={sendSurveyMode}
              areSingleUnitAllRowsSelected ={areSingleUnitAllRowsSelected}
              handleSingleUnitAllRowsCheckBoxClick = {handleSingleUnitAllRowsCheckBoxClick}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isRowSelected(row[primaryKey]);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row[primaryKey]}
                      // key={row[primaryKey]}
                      selected={isItemSelected}
                    >
                      {/* First Element in the Array */}
                      <StyledTableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="center"
                      >
                        {row[tableKeys[0]]? row[tableKeys[0]] : <i style={{color : 'grey'}}>NA</i>}
                      </StyledTableCell>
                      {/* All Elements except first element in array using slice method */}
                      {tableKeys.slice(1).map((key, index) => {

                        // Handling Feedbacks key and if not, rendering the keys as usual
                        return key === 'feedbacks' ? 
                        
                        <React.Fragment key={index}>
                          {row[key].map(({unit_id,score,unit_name,unique_unit,has_feedback},index)=>{
                            // <StyledTableCell key={unit_id} align="center">
                            // <StyledTableCell key={unit_id} align="center" onClick={handleViewUnitClick(unit_id,row,index)}>\

                            const unitSelectedOrNot = isUnitSelected(row,unit_id);

                            //---- Showing Checkboxs only when in Survey Mode
                            return sendSurveyMode ? 
                            <StyledTableCell key={`${row[primaryKey]}-${unit_id}`} align="center">
                            <Checkbox
                                color="primary"
                                checked={unitSelectedOrNot}
                                inputProps={{
                                  "aria-labelledby": `${labelId}-${unit_id}`,
                                }}
                                onClick={() =>
                                  handleUnitCheckBoxClick(row,{unit_id,score,unit_name,unique_unit,has_feedback})
                                }
                                />                            
                            </StyledTableCell>
                            //----- If has score and is not in sendSurveyMode, we'll show the scores when they are available
                            : score && !sendSurveyMode ?
                            <StyledTableCell key={`${row[primaryKey]}-${unit_id}`} align="center" onClick={(event)=>handleViewUnitClick(unit_id,row,index)}>
                              {score} 
                            </StyledTableCell>
                            : 
                            // Handling condition when no score is give. Also checkbox shown when survey mode is handled above.
                            // If has_feedback is false then we want to let user know that feedback is not sent.
                            <StyledTableCell key={`${row[primaryKey]}-${unit_id}`} align="center">
                              {has_feedback ? 
                              <Tooltip title="No Rating Available">
                                <i style={{color : 'grey'}}>NA</i>
                              </Tooltip> 
                              :<Tooltip title="No Survey Sent">
                                <i style={{color : 'grey'}}>NS</i>
                              </Tooltip> 
                              }
                            </StyledTableCell>


                            // </StyledTableCell>
                          })} 
                          {/* If unit 6 already exists we do not add a cell, or else we'll add a cell */}
                          {row[key].some(({unit_name})=> unit_name === "Unit VI")? null 
                          : 
                          <StyledTableCell key={index} align="center">
                            <Tooltip title="No Unit">
                              {<i style={{color : 'grey'}}>--</i>}
                            </Tooltip>
                          </StyledTableCell>
                          }
                        </React.Fragment>

                        :<StyledTableCell key={index} align="center" onClick={key === 'feedback_overall_score' && row[key]? (event)=>handleRowViewActionsClick(event,row): null}>
                          {row[key] ? row[key] : <i style={{color : 'grey'}}>NA</i>}
                        </StyledTableCell>

                        })}


                      {sendSurveyMode && <StyledTableCell>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          onClick={() => handleRowCheckboxClick(row)}
                        /> 
                      </StyledTableCell>}


                      {!sendSurveyMode && <StyledTableCell align="center" sx={{p:'1px'}} >
                        <Tooltip title="View Unit Level Performance">
                          <IconButton disableRipple sx={{p:0}} onClick={(event)=>handleRowViewActionsClick(event,row)}>
                            <Typography variant='body2'>View</Typography>
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>}

                      {/* <StyledTableCell align="center" sx={{p:'1px'}} >
                        <Tooltip title="Make Changes">
                          <IconButton disableRipple sx={{p:0}} onClick={(event)=>handleRowViewActionsClick(event,row)}>
                            ...
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell> */}

                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 1 * emptyRows,
                    // height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <StyledTableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <TableTopMenu sendSurveyMode={sendSurveyMode} setSendSurveyMode={setSendSurveyMode}  headArray={headArray} data ={rows} mainMenuOpen ={mainMenuOpen} setMainMenuOpen={setMainMenuOpen} />
      </Paper>
    </Box>
  );
}