import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CustomChip from "../common/CustomChip";
import QuestionWiseBellCurve from "./QuestionWiseBellCurve";
import { customBoxShadow } from '../../lib/utils';


export default function QuestionGraph ({questionData}) {

    const {question_name, average_score,individual_student_scores } =questionData;
    return(
        <Paper elevation={0} sx={{borderRadius:'20px',boxShadow: customBoxShadow}}>
        {/* <Paper elevation={0} sx={{borderRadius:'20px',boxShadow: `0px 0px 0px rgba(0, 0, 0, 0.19), 6px 6px 6px rgba(0, 0, 0, 0.23)`}}> */}
        <Grid container>
          <Grid item sx={{ m:1, pl:2}}>
          <Typography component={'div'} sx={{ mt:1.5, fontSize: 14 }} color="text.secondary" gutterBottom>
            Question : <CustomChip label={question_name}/>
          </Typography>
          <Typography component={'div'} sx={{ mt:1.5, fontSize: 14 }} color="text.secondary" gutterBottom>
            Avg Score : <CustomChip label={average_score}/>
          </Typography>
          </Grid>
          <Grid item xs={12}>
            <QuestionWiseBellCurve
              title="Unit Data"
              xLabel="Ratings"
              yLabel="No. of Students"
              data={individual_student_scores}
            />
          </Grid>
        </Grid>
      </Paper>
    )
}

