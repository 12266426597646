
// Used for Sorting Strings with Object key named 'name'.
export function sortByString( a, b ) {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }
  
//   objs.sort( sortByString );


// 

export const getAllDepartments = (ObjectData) =>{
  let allDepartments = [];

  ObjectData.forEach(({department_id,department_name}) => {
    const data = {
      name: department_name.trim(),
      value: department_id,
    };

    if (allDepartments?.length === 0) {
      return allDepartments.push(data);
    }
    const isIncluded = allDepartments.some((element) => {
      return element.name === department_name.trim();
    });

    if (!isIncluded) {
      return allDepartments.push(data);
    }
  });

  allDepartments.sort(sortByString); 
  // console.log(allDepartments);
  return allDepartments
}


// export const getAllDepartmentsForPerformance = (ObjectData) =>{
//   let allDepartments = [];

//   ObjectData.forEach(({department_id,department_name}) => {
//     const data = {
//       name: department_name.trim(),
//       value: department_id,
//     };

//     if (allDepartments?.length === 0) {
//       return allDepartments.push(data);
//     }
//     const isIncluded = allDepartments.some((element) => {
//       // console.log(department_name)
//       return element.name === department_name.trim();
//     });

//     if (!isIncluded) {
//       return allDepartments.push(data);
//     }
//   });

//   allDepartments.sort(sortByString); 
//   // console.log(allDepartments);
//   return allDepartments
// }

export const getAllSemester = (ObjectData) =>{
  let allSemesters = [];
  ObjectData.forEach(({semester_id}) => {

    const data = {
      name: semester_id,
      value: semester_id,
    };

    if (allSemesters?.length === 0) {
      return allSemesters.push(data);
    }
    const isIncluded = allSemesters.some((element) => {
      return element.value === semester_id;
    });

    if (!isIncluded) {
      return allSemesters.push(data);
    }
  });

  allSemesters.sort((a,b)=>{return a.name -b.name}); 
  // console.log(allSemesters);
  return allSemesters
}


// export const getAllSubjects = (ObjectData) =>{
//   let allSubjects = [];
//   ObjectData.forEach((sectionsAtDeptSem) => {
//     const data = {
//       name: sectionsAtDeptSem.subject_name.trim(),
//       value: sectionsAtDeptSem.subject_semester_id,
//     };

//     if (allSubjects?.length === 0) {
//       return allSubjects.push(data);
//     }
//     const isIncluded = allSubjects.some((element) => {
//       return element.name === sectionsAtDeptSem.subject_name.trim();
//     });

//     if (!isIncluded) {
//       return allSubjects.push(data);
//     }
//   });

//   allSubjects.sort(sortByString); 
//   console.log(allSubjects);
//   return allSubjects
// }

export const getAllSubjects = (ObjectData,deparmentId=null,selectedSemesters=null) =>{
  
  const allSubjects = [];

  // Functinoality to dynamically change subjects based on dept and sem filters even before clicking on apply.
  ObjectData.forEach(({department_id,subject_name,subject_semester_id,semester_id}) => {
    // console.log(selectedSemesters)
    // console.log(`semester_id : ${semester_id}`)
    // console.log(`includes : ${selectedSemesters.includes(semester_id)}`)

    let subjectInDeptSem =true;

    if(selectedSemesters?.length){
      if (!selectedSemesters.includes(semester_id))  subjectInDeptSem = false;
    }

    if(deparmentId){
      if (department_id !== deparmentId)  subjectInDeptSem = false;
    }
    // console.log(subjectInDeptSem)

    // If the subject is not a part of dept and semester selected in the filter then remove it.
    if (!subjectInDeptSem) {
      return
    }

    const data = {
      name: subject_name.trim(),
      value: subject_semester_id,
    };

    if (allSubjects?.length === 0) {
      return allSubjects.push(data);
    }

    const isIncluded = allSubjects.some((element) => {
      return element.name === subject_name.trim();
    });

    if (!isIncluded) {
      return allSubjects.push(data);
    }
  });

  return allSubjects
}


export const getAllSections = (ObjectData,deparmentId=null,selectedSemesters=null) =>{

  let allSections = [];

  ObjectData.forEach(({section_id,section_name,department_id,semester_id}) => {

    // Functinoality to dynamically change section based on dept and sem filters even before clicking on apply.
    let sectionInDeptSem =true;
    if(selectedSemesters?.length){
      if (!selectedSemesters.includes(semester_id))  sectionInDeptSem = false;
    }
    if(deparmentId){
      if (department_id !== deparmentId)  sectionInDeptSem = false;
    }
    // console.log(sectionInDeptSem)
    // If the subject is not a part of dept and semester selected in the filter then remove it.
    if (!sectionInDeptSem) {
      return
    }

    const data = {
      name: section_name.trim(),
      value: section_name.trim(),
    };

    if (allSections?.length === 0) {
      return allSections.push(data);
    }
    const isIncluded = allSections.some((element) => {
      return element.value === section_name.trim();
    });

    if (!isIncluded) {
      return allSections.push(data);
    }
  });

  allSections.sort(sortByString); 
  // console.log(allSections);
  return allSections
}

export const getAllFaculty = (ObjectData) =>{
  let allFaculty = [];
  ObjectData.forEach(({faculty_name,college_account_id}) => {
    const data = {
      name: faculty_name.trim(),
      value: college_account_id,
    };

    if (allFaculty?.length === 0) {
      return allFaculty.push(data);
    }
    const isIncluded = allFaculty.some((element) => {
      return element.value === college_account_id;
    });

    if (!isIncluded) {
      return allFaculty.push(data);
    }
  });

  allFaculty.sort(sortByString); 
  // console.log(allFaculty);
  return allFaculty
}





// Maintaining at one place, so if we want to change it in one go.
export const customBoxShadow = `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`


// Array to Reccuring Data Transformation
export const arryToFrequencyGraph = (data) =>{
  // const data = [
  //   1,2,3,4,1,2,3,4,2,4,2,4,2,4,2,1,3,4,2,2,1,1,2,3,2,3
  //   ];
    // const data = anychart.data.set([
    // 1,2,3,4,1,2,3,4,2,4,2,4,2,4,2,1,3,4,2,2,1,1,2,3,2,3
    // ]);
    
    let transfromedData={
        0.5 : 0,
        1 : 0,
        1.5 : 0,
        2 : 0,
        2.5 : 0,
        3 : 0,
        3.5 : 0,
        4 : 0,
        4.5 : 0,
        5 : 0,
    };
    
    data.forEach(element => {
        transfromedData[element] += 1;
    });
    // console.log(transfromedData)
    
    let transformedForGraph = [];
    for(const key in transfromedData){
        // console.log(`${key}: ${transfromedData[key]}`);
        transformedForGraph.push({x: parseFloat(key), value: transfromedData[key]})
    }

    // Arranging Based on key value
    transformedForGraph?.sort((a,b)=> a?.x -b?.x)
    
    return transformedForGraph
}

// Licensing Data

// Anychart License
export const anychart_key = "edwisely.com-64c3ee84-a648e4ab";

