import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import WheelComponent from 'react-wheel-of-prizes';
import "./challengeSpinWheel.styles.css";
import { addWeeklyChallengeQuestion, getAllTeams, getWeeklyChallengeQuestion } from '../../api/weeklyChallengeAPIs';
import { RiTeamLine } from "react-icons/ri";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
//import { realtimePChannel } from "../challengeLobby/challengeLobby.component";

const ChallengeSpinWheel = (props) => {
    const realtimePChannel = useSelector(state => state.joinChallenge.realtimePChannel);
    const channel = useSelector(state => state.joinChallenge.participantChannel);
    const viewer_channel = useSelector(state => state.joinChallenge.viewerChannel);
    const navigate = useNavigate();
    const [current_topic_id, setCurrentTopicId] = useState(null);
    const [current_topic_name, setCurrentTopicName] = useState(null);
    const [show_TopicSelectedLabel, setShowTopicSelectedLabel] = useState(false);
    const [showPublishRandomQuestionBtn, setShowPublishRandomQuestionBtn] = useState(false);
    const [showPublishSpinnedQuestionBtn, setShowPublishSpinnedQuestionBtn] = useState(false);
    const [showQuestionNotFoundError, setShowQuestionNotFoundError] = useState(false);
    const [winner, setWinner] = useState("");
    const [showOtherTeamsModal, setShowOtherTeamsModal] = useState(false);
    const [allTeams, setAllTeams] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(null);

    console.log(props.challenge_details);

    const onFinished = async (winner) => {
        setShowQuestionNotFoundError(false);
        let topic_id;
        console.log(winner);
        for (let i = 0; i < props.topicsData.length; i++) {
            const topic_name = props.topicsData[i].name;
            if (topic_name === winner) {
                topic_id = props.topicsData[i].id;
                break;
            }
        }
        setWinner(topic_id);

        const questionData = await addWeeklyChallengeQuestion(props.challenge_id, topic_id);
        if (questionData.data != null) {
            setCurrentQuestion(questionData);
            channel.publish("topic_selected", `${topic_id}`);
            if (parseInt(props.viewer_topic_on) === 1) {
                viewer_channel.publish("topic_selection_on", JSON.stringify(props.topicsData));
            }

            setCurrentTopicId(topic_id);
            setCurrentTopicName(winner);
            setShowTopicSelectedLabel(true);
            setShowPublishSpinnedQuestionBtn(true);
            setShowPublishRandomQuestionBtn(false);
        } else if (questionData.message === "Question not found for the selected topic. Please spin the wheel again to get the different topic") {
            setShowTopicSelectedLabel(false);
            setShowPublishSpinnedQuestionBtn(false);
            setShowPublishRandomQuestionBtn(false);
            setShowQuestionNotFoundError(true);
        }
    };
    console.log(show_TopicSelectedLabel,"selectedlae");
    const publishSpinnedQuestion = async () => {
        const questionData = currentQuestion;
        console.log(questionData);
        if (questionData.data != null) {
            questionData.data["current_pos"] = props.question_index;
            const current_time = new Date().getTime();
            questionData.data["timer_ts"] = current_time;
            channel.publish("question", JSON.stringify(questionData.data));
            viewer_channel.publish("topic_selected", `${winner}`);

            navigate('/questionnaire', { state: { question_data: questionData.data, weekly_challenge_id: props.challenge_id, challenge_details: props.challenge_details, is_random: 0, question_index: props.question_index } });
        } else {
            console.log('questions not found for this topic!');
        }
    };

    const onRandomFinished = async (winner) => {
        let topic_id;
        for (let i = 0; i < props.topicsData.length; i++) {
            const topic_name = props.topicsData[i].name;
            if (topic_name === winner) {
                topic_id = props.topicsData[i].id;
                break;
            }
        }
        channel.publish("topic_selected", `${topic_id}`);
        setWinner(topic_id);
        if (parseInt(props.viewer_topic_on) === 1) {
            viewer_channel.publish("topic_selection_on", JSON.stringify(props.topicsData));
        }
        setCurrentTopicId(topic_id);
        setCurrentTopicName(winner);
        setShowTopicSelectedLabel(true);
        setShowPublishRandomQuestionBtn(true);
        setShowPublishSpinnedQuestionBtn(false);
    };

    const publishRandomQuestion = async () => {
        const questionData = props.challenge_details.questions[props.question_index];
        questionData["current_pos"] = props.question_index;
        const current_time = new Date().getTime();
        questionData["timer_ts"] = current_time;
        channel.publish("question", JSON.stringify(questionData));
        viewer_channel.publish("topic_selected", `${winner}`);

        navigate('/questionnaire', { state: { question_data: props.challenge_details.questions, weekly_challenge_id: props.challenge_id, challenge_details: props.challenge_details, is_random: 1, question_index: props.question_index } });
    };

    const handleShowOtherTeamsModalOpen = async () => {
        setShowOtherTeamsModal(true);
        const allteams = await getAllTeams(props.challenge_id);
        setAllTeams(allteams.data);
    }
    const handleShowOtherTeamsModalClose = () => setShowOtherTeamsModal(false);
    console.log(props.selected_topic_name);
    console.log(allTeams);

    return (
        <div className="row w-100">

            <Modal show={showOtherTeamsModal} onHide={handleShowOtherTeamsModalClose} id="otherTeamsModal" dialogClassName="otherTeamsModalWidth">
                <Modal.Header closeButton>
                    <Modal.Title>Teams Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {
                            allTeams.length !== 0
                                ? allTeams.map(team => (
                                    <div className="col-lg-4 col-md-4 col-sm-4">
                                        <table className="table table-bordered table-curved" id="teams-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" colSpan="3">
                                                        {team.team_name}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    team.members.length > 0
                                                        ? team.members.map(teamMember => (
                                                            <tr>
                                                                <td scope="row">
                                                                    {
                                                                        teamMember.faculty_id != ''
                                                                            ? teamMember.is_captain
                                                                                ? teamMember.faculty_name + " (C)"
                                                                                : teamMember.is_vice_captain
                                                                                    ? teamMember.faculty_name + " (VC)"
                                                                                    : teamMember.faculty_name
                                                                            : teamMember.is_captain
                                                                                ? teamMember.student_name + " (C)"
                                                                                : teamMember.is_vice_captain
                                                                                    ? teamMember.student_name + " (VC)"
                                                                                    : teamMember.student_name
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                        : (
                                                            <tr>
                                                                <td colSpan="3">
                                                                    <span colSpan="3" className="d-flex justify-content-center" id="no-coordinators-msg-label">This Team has no members!</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                ))
                                : (<p style={{ textAlign: 'center' }}>There are no teams to show!</p>)
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="btn btn-primary shadow-sm" onClick={handleShowOtherTeamsModalClose} style={{ width: "25%" }} id="modalCloseBtn" >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>

            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="spin-wheel-page">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-4">
                            <div className="spinwheel-question-index">Question: {props.question_index + 1}/{props.challenge_details.no_of_questions}</div>
                            <div className="spin-wheel-info">
                                <h3>Spin the wheel and generate the question</h3>
                                {
                                    show_TopicSelectedLabel && (
                                        <div className="spin-inner-wheel-info">
                                            <p>Spinned the wheel</p>
                                            <p>Topic selected: <strong style={{ color: "black" }}>{current_topic_name}</strong></p>
                                        </div>
                                    )
                                }
                                {
                                    showPublishRandomQuestionBtn && (
                                        <button className="btn btn-primary" type="button" id="publishQuestionButton" onClick={publishRandomQuestion}>Publish Question</button>
                                    )
                                }
                                {
                                    showPublishSpinnedQuestionBtn && (
                                        <button className="btn btn-primary" type="button" id="publishQuestionButton" onClick={publishSpinnedQuestion}>Publish Question</button>
                                    )
                                }
                                {
                                    showQuestionNotFoundError && (
                                        <div className="spin-inne-wheel-info mt-5">
                                            <p>Question not found for the selected topic. Please spin the wheel again to get the different Question!</p>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-8">
                            <div className="admin-spin-wheel-div">
                                {
                                    props.selected_topic_name === "not_random"
                                        ? (
                                            <div className="spinwheel">
                                                <WheelComponent
                                                    segments={props.segTopics}
                                                    segColors={props.segColors}
                                                    winningSegment=''
                                                    onFinished={(winner) => onFinished(winner)}
                                                    primaryColor='grey'
                                                    contrastColor='black'
                                                    buttonText='Spin'
                                                    isOnlyOnce={false}
                                                    size={290}
                                                    upDuration={100}
                                                    downDuration={1000}
                                                    fontFamily='Arial'
                                                />
                                            </div>
                                        )
                                        : (
                                            <div className="spinwheel">
                                                <WheelComponent
                                                    segments={props.segTopics}
                                                    segColors={props.segColors}
                                                    winningSegment={`${props.selected_topic_name}`}
                                                    onFinished={(winner) => onRandomFinished(winner)}
                                                    primaryColor='grey'
                                                    contrastColor='black'
                                                    buttonText='Spin'
                                                    isOnlyOnce={false}
                                                    size={290}
                                                    upDuration={100}
                                                    downDuration={1000}
                                                    fontFamily='Arial'
                                                />
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    </div>
                    {
                        props.challenge_details.participation_type === 1
                            ? (
                                <div className="row" style={{ marginTop: "-50px" }}>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div style={{ marginLeft: "25px" }}>
                                            <RiTeamLine className="teams-icon" onClick={handleShowOtherTeamsModalOpen} style={{ cursor: 'pointer' }} /><br />
                                            <span>Other Teams</span>
                                        </div>
                                    </div>
                                </div>
                            )
                            : ('')
                    }
                </div>
            </div>
        </div>

    )
}

export default ChallengeSpinWheel;