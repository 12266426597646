import React, { useState } from "react";
import styled, { keyframes, css } from 'styled-components';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BsFillCircleFill } from "react-icons/bs";
import { AiFillLock } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import "./leaderBoard.styles.css";

const LeaderBoard = (props) => {
    const [showdetails, setShowdetails] = useState(null);
    const [showdetailsmodal, setShowdetailsmodal] = useState(false);
    const colors = [
        "rgb(225 54 58 / 85%)",
        "rgb(72 103 246 / 83%)",
        "rgb(102 175 110 / 86%)",
        "rgb(221 165 57 / 86%)",
        "rgb(235 133 187)",
        "rgb(225 54 58 / 85%)",
        "rgb(72 103 246 / 83%)",
        "rgb(102 175 110 / 86%)",
        "rgb(221 165 57 / 86%)",
        "rgb(235 133 187)",
        "rgb(225 54 58 / 85%)",
        "rgb(72 103 246 / 83%)",
        "rgb(102 175 110 / 86%)",
        "rgb(221 165 57 / 86%)",
        "rgb(235 133 187)",
        "rgb(225 54 58 / 85%)",
        "rgb(72 103 246 / 83%)",
        "rgb(102 175 110 / 86%)",
        "rgb(221 165 57 / 86%)",
        "rgb(235 133 187)",
        "rgb(225 54 58 / 85%)",
        "rgb(72 103 246 / 83%)",
        "rgb(102 175 110 / 86%)",
        "rgb(221 165 57 / 86%)",
        "rgb(235 133 187)",
        "rgb(225 54 58 / 85%)",
        "rgb(72 103 246 / 83%)",
        "rgb(102 175 110 / 86%)",
        "rgb(221 165 57 / 86%)",
        "rgb(235 133 187)",
        "rgb(225 54 58 / 85%)",
        "rgb(72 103 246 / 83%)",
        "rgb(102 175 110 / 86%)",
        "rgb(221 165 57 / 86%)",
        "rgb(235 133 187)",
        "rgb(225 54 58 / 85%)",
        "rgb(72 103 246 / 83%)",
        "rgb(102 175 110 / 86%)",
        "rgb(221 165 57 / 86%)",
        "rgb(235 133 187)",
    ];

    const lineargradient = [
        "rgb(242 111 116 / 71%)",
        "#a7b5f6",
        "#9ce1a4",
        "#fbd793",
        "#fdcee8",
        "rgb(242 111 116 / 71%)",
        "#a7b5f6",
        "#9ce1a4",
        "#fbd793",
        "#fdcee8",
        "rgb(242 111 116 / 71%)",
        "#a7b5f6",
        "#9ce1a4",
        "#fbd793",
        "#fdcee8",
        "rgb(242 111 116 / 71%)",
        "#a7b5f6",
        "#9ce1a4",
        "#fbd793",
        "#fdcee8",
        "rgb(242 111 116 / 71%)",
        "#a7b5f6",
        "#9ce1a4",
        "#fbd793",
        "#fdcee8",
        "rgb(242 111 116 / 71%)",
        "#a7b5f6",
        "#9ce1a4",
        "#fbd793",
        "#fdcee8",
        "rgb(242 111 116 / 71%)",
        "#a7b5f6",
        "#9ce1a4",
        "#fbd793",
        "#fdcee8",
        "rgb(242 111 116 / 71%)",
        "#a7b5f6",
        "#9ce1a4",
        "#fbd793",
        "#fdcee8",
    ];
    const img_extensions = [
        'png',
        'jpg',
        'jpeg',
        'gif',
        'tiff'
    ];
    const video_extensions = [
        'mp4',
        'mov',
        'wmv',
        'avi',
        'flv',
        'f4v',
        'swf',
        'mkv'
    ];
    const audio_extensions = [
        'mp3',
        'aac',
        'flac',
        'alac',
        'wac',
        'aiff',
        'dsd',
        'pcm'
    ];
    let color_index = -1;

    const options = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
    const optionbgcolors = [
        "#f7d5b2",
        "#fee091",
        "#f1c7db",
        "#bdcaff",
        "#f7d5b2",
        "#fee091",
        "#f1c7db",
        "#bdcaff",
    ];

    const viewdetails = (index) => {
        setShowdetails(null);
        setTimeout(() => {
            setShowdetails(index);
        }, 100);
        if (index || index == 0) {
            setShowdetailsmodal(true);
        }
        console.log(index);
    };

    const removeTags = (str) => {
        if (str === null || str === "") return false;
        else str = str.toString();

        return str.replace(/<[^>]*>/g, "");
    }
    console.log("0000000000000000000");
    console.log(props.data);

    return (
        <div className="row" style={{ padding: "18px" }}>
            <div className="col-lg-1 col-md-1 col-sm-0"></div>
            <div className="col-lg-10 col-md-10 col-sm-12">
                {
                    props.user_type === "admin" || props.user_type === "viewer"
                        ? props.data.map((teams, index) => {
                            let height = 0;
                            let fontsize = 0;
                            let questionsfontsize = 0;
                            let animationprop = "";
                            let rankfontsize, avatarheight, avatarwidth, student_score_font_size;
                            let total_time_taken = 0;
                            if (index == 0) {
                                height = 75;
                                fontsize = 20;
                                rankfontsize = 40;
                                animationprop = true;
                                questionsfontsize = 10;
                                avatarwidth = 60;
                                avatarheight = 63;
                                student_score_font_size = 30;
                            } else {
                                height = 65;
                                fontsize = 15;
                                rankfontsize = 30;
                                questionsfontsize = 9;
                                animationprop = false;
                                avatarwidth = 54;
                                avatarheight = 47;
                                student_score_font_size = 25;
                            }
                            let top;
                            if (index == 1) {
                                top = 325;
                            } else if (index == 6) {
                                top = -325;
                            } else if (index == 2) {
                                top = 130;
                            } else if (index == 4) {
                                top = -65;
                            } else if (index == 3) {
                                top = -65;
                            } else {
                                top = '';
                            }
                            color_index = color_index + 1;
                            if (color_index >= colors.length) {
                                color_index = 0;
                            }

                            return (
                                <Rotate className="col-lg-12 leaderboardrow" primary={index + 1} top={null} style={{ marginBottom: "10px", background: "linear-gradient(45deg, rgb(235, 200, 175), rgb(241, 178, 197))", height: `${height}px`, padding: '5px' }}>
                                    <div className="col-lg-1 col-md-1 col-sm-1 leaderboardrow-col" style={{ fontSize: `${rankfontsize}px` }}>{index + 1}</div>
                                    <div className="col-lg-9 col-md-9 col-sm-7 leaderboardrow-col" style={{ fontSize: `${fontsize}px` }}>
                                        <div>
                                            {
                                                props.challenge_details.participation_type === 0
                                                    ? teams.student_id !== "" ? teams.student_name : teams.faculty_name
                                                    : teams.team_name
                                            }
                                        </div>
                                        <div className="questiondetails" style={{ fontSize: `${questionsfontsize}px` }}>
                                            {teams.questions.map((questiondetails, index) => {
                                                if (props.type === "running") {
                                                    if (index <= props.question_index) {
                                                        total_time_taken = total_time_taken + questiondetails.time_taken;
                                                        return (
                                                            <span style={{ marginLeft: '7px' }}>
                                                                {/* <div style={{ color: "white" }}>
                                                        Q{index + 1} ({questiondetails.time_taken} s)
                                                    </div> */}
                                                                {
                                                                    parseInt(questiondetails.is_correct) === 1
                                                                        ? <BsFillCircleFill style={{ color: "green", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                        : parseInt(questiondetails.is_correct) === 0
                                                                            ? <BsFillCircleFill style={{ color: "red", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                            : <BsFillCircleFill style={{ color: "grey", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                }
                                                            </span>
                                                        );
                                                    }
                                                } else {
                                                    total_time_taken = total_time_taken + questiondetails.time_taken;
                                                    return (
                                                        <span style={{ marginLeft: '7px' }}>
                                                            {/* <div style={{ color: "white" }}>
                                                        Q{index + 1} ({questiondetails.time_taken} s)
                                                    </div> */}
                                                            {
                                                                parseInt(questiondetails.is_correct) === 1
                                                                    ? <BsFillCircleFill style={{ color: "green", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                    : parseInt(questiondetails.is_correct) === 0
                                                                        ? <BsFillCircleFill style={{ color: "red", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                        : <BsFillCircleFill style={{ color: "grey", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                            }
                                                        </span>
                                                    );
                                                }

                                            })}
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-2">
                                        <div><br />
                                            <div style={{ fontSize: '0.5em', width: '100%', marginTop: '-30px' }}>Time - <span style={{ fontWeight: "700" }}>{teams.total_time_taken}s</span></div>
                                            {/* <div style={{ fontSize: '0.5em' }}>{teams.total_time_taken} sec</div> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-2 leaderboardrow-col" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-end" }}>
                                        {/* <img src={avatars[index]} style={{ width: `${avatarwidth}px`, height: `${avatarheight}px`, borderRadius: "10px", marginTop: "0px" }} /> */}
                                        <span style={{ fontSize: `${student_score_font_size}px` }}>{teams.total_correct_answers}/{teams.questions.length}</span>
                                        {
                                            props.type === "running"
                                                ? (
                                                    <button
                                                        className="btn btn-success"
                                                        style={{
                                                            padding: "1px",
                                                            height: "31px",
                                                            fontSize: "10px",
                                                        }}
                                                        onClick={() => viewdetails(index)}
                                                    >
                                                        View question details
                                                    </button>
                                                )
                                                : ''
                                        }

                                    </div>
                                </Rotate>
                            );
                        })
                        : ""

                }
                {
                    props.user_type === "participant"
                        ? (
                            <>
                                {
                                    props.data.map((teams, index) => {
                                        let height = 0;
                                        let fontsize = 0;
                                        let questionsfontsize = 0;
                                        let animationprop = "";
                                        let rankfontsize, avatarheight, avatarwidth, student_score_font_size;
                                        let total_time_taken = 0;
                                        if (index == 0) {
                                            height = 75;
                                            fontsize = 20;
                                            rankfontsize = 40;
                                            animationprop = true;
                                            questionsfontsize = 10;
                                            avatarwidth = 60;
                                            avatarheight = 63;
                                            student_score_font_size = 30;
                                        } else {
                                            height = 65;
                                            fontsize = 15;
                                            rankfontsize = 30;
                                            questionsfontsize = 9;
                                            animationprop = false;
                                            avatarwidth = 54;
                                            avatarheight = 47;
                                            student_score_font_size = 25;
                                        }
                                        let top;
                                        if (index == 1) {
                                            top = 325;
                                        } else if (index == 6) {
                                            top = -325;
                                        } else if (index == 2) {
                                            top = 130;
                                        } else if (index == 4) {
                                            top = -65;
                                        } else if (index == 3) {
                                            top = -65;
                                        } else {
                                            top = '';
                                        }
                                        color_index = color_index + 1;
                                        if (color_index >= colors.length) {
                                            color_index = 0;
                                        }

                                        let showCard = false;
                                        if (props.challenge_details.participation_type === 0) {
                                            if (teams.student_id !== "") {
                                                if (teams.student_id === props.myTeam_id) {
                                                    showCard = true;
                                                }
                                            } else {
                                                if (teams.college_account_id === props.myTeam_id) {
                                                    showCard = true;
                                                }
                                            }
                                        } else {
                                            if (teams.team_id === props.myTeam_id) {
                                                showCard = true;
                                            }
                                        }
                                        if (showCard) {
                                            return (
                                                <Rotate className="col-lg-12 leaderboardrow" primary={index + 1} top={null} style={{ marginBottom: "10px", background: "linear-gradient(45deg, rgb(235, 200, 175), rgb(241, 178, 197))", height: `${height}px`, padding: '5px' }}>
                                                    <div className="col-lg-1 col-md-1 col-sm-1 leaderboardrow-col" style={{ fontSize: `${rankfontsize}px` }}>{index + 1}</div>
                                                    <div className="col-lg-9 col-md-9 col-sm-7 leaderboardrow-col" style={{ fontSize: `${fontsize}px` }}>
                                                        <div>
                                                            {
                                                                props.challenge_details.participation_type === 0
                                                                    ? teams.student_id !== "" ? teams.student_name : teams.faculty_name
                                                                    : teams.team_name
                                                            }
                                                        </div>
                                                        <div className="questiondetails" style={{ fontSize: `${questionsfontsize}px` }}>
                                                            {teams.questions.map((questiondetails, index) => {
                                                                if (props.type === "running") {
                                                                    if (index <= props.question_index) {
                                                                        total_time_taken = total_time_taken + questiondetails.time_taken;
                                                                        return (
                                                                            <span style={{ marginLeft: '7px' }}>
                                                                                {
                                                                                    questiondetails.is_correct === 1
                                                                                        ? <BsFillCircleFill style={{ color: "green", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                                        : questiondetails.is_correct === 0
                                                                                            ? <BsFillCircleFill style={{ color: "red", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                                            : <BsFillCircleFill style={{ color: "grey", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                                }
                                                                            </span>
                                                                        );
                                                                    }
                                                                } else {
                                                                    total_time_taken = total_time_taken + questiondetails.time_taken;
                                                                    return (
                                                                        <span style={{ marginLeft: '7px' }}>
                                                                            {
                                                                                questiondetails.is_correct === 1
                                                                                    ? <BsFillCircleFill style={{ color: "green", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                                    : questiondetails.is_correct === 0
                                                                                        ? <BsFillCircleFill style={{ color: "red", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                                        : <BsFillCircleFill style={{ color: "grey", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                            }
                                                                        </span>
                                                                    );
                                                                }
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 col-sm-2">
                                                        <div><br />
                                                            <div style={{ fontSize: '0.5em', width: '100%', marginTop: '-30px' }}>Time - <span style={{ fontWeight: "700" }}>{teams.total_time_taken}s</span></div>
                                                            {/* <div style={{ fontSize: '0.5em' }}>{teams.total_time_taken} sec</div> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 col-sm-2 leaderboardrow-col" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-end" }}>
                                                        {/* <img src={avatars[index]} style={{ width: `${avatarwidth}px`, height: `${avatarheight}px`, borderRadius: "10px", marginTop: "0px" }} /> */}
                                                        <span style={{ fontSize: `${student_score_font_size}px` }}>{teams.total_correct_answers}/{teams.questions.length}</span>
                                                        {
                                                            props.type === "running"
                                                                ? (
                                                                    <button
                                                                        className="btn btn-success"
                                                                        style={{
                                                                            padding: "1px",
                                                                            height: "31px",
                                                                            fontSize: "10px",
                                                                        }}
                                                                        onClick={() => viewdetails(index)}
                                                                    >
                                                                        View question details
                                                                    </button>
                                                                )
                                                                : ''
                                                        }

                                                    </div>
                                                </Rotate>
                                            );
                                        }
                                    })
                                }
                                {
                                    props.data.length > 1
                                        ? props.challenge_details.participation_type === 0
                                            ? (<span style={{ marginBottom: "10px" }}><strong>Other Participants</strong></span>)
                                            : (<span style={{ marginBottom: "10px" }}><strong>Other Teams</strong></span>)
                                        : ""
                                }
                                {
                                    props.data.map((teams, index) => {
                                        let height = 0;
                                        let fontsize = 0;
                                        let questionsfontsize = 0;
                                        let animationprop = "";
                                        let rankfontsize, avatarheight, avatarwidth, student_score_font_size;
                                        let total_time_taken = 0;
                                        if (index == 0) {
                                            height = 75;
                                            fontsize = 20;
                                            rankfontsize = 40;
                                            animationprop = true;
                                            questionsfontsize = 10;
                                            avatarwidth = 60;
                                            avatarheight = 63;
                                            student_score_font_size = 30;
                                        } else {
                                            height = 65;
                                            fontsize = 15;
                                            rankfontsize = 30;
                                            questionsfontsize = 9;
                                            animationprop = false;
                                            avatarwidth = 54;
                                            avatarheight = 47;
                                            student_score_font_size = 25;
                                        }
                                        let top;
                                        if (index == 1) {
                                            top = 325;
                                        } else if (index == 6) {
                                            top = -325;
                                        } else if (index == 2) {
                                            top = 130;
                                        } else if (index == 4) {
                                            top = -65;
                                        } else if (index == 3) {
                                            top = -65;
                                        } else {
                                            top = '';
                                        }
                                        color_index = color_index + 1;
                                        if (color_index >= colors.length) {
                                            color_index = 0;
                                        }

                                        let showCard = false;
                                        if (props.challenge_details.participation_type === 0) {
                                            if (teams.student_id !== "") {
                                                if (teams.student_id !== props.myTeam_id) {
                                                    showCard = true;
                                                }
                                            } else {
                                                if (teams.college_account_id !== props.myTeam_id) {
                                                    showCard = true;
                                                }
                                            }
                                        } else {
                                            if (teams.team_id !== props.myTeam_id) {
                                                showCard = true;
                                            }
                                        }
                                        if (showCard) {
                                            return (
                                                <Rotate className="col-lg-12 leaderboardrow" primary={index + 1} top={null} style={{ marginBottom: "10px", background: "linear-gradient(45deg, rgb(235, 200, 175), rgb(241, 178, 197))", height: `${height}px`, padding: '5px' }}>
                                                    <div className="col-lg-1 col-md-1 col-sm-1 leaderboardrow-col" style={{ fontSize: `${rankfontsize}px` }}>{index + 1}</div>
                                                    <div className="col-lg-9 col-md-9 col-sm-7 leaderboardrow-col" style={{ fontSize: `${fontsize}px` }}>
                                                        <div>
                                                            {
                                                                props.challenge_details.participation_type === 0
                                                                    ? teams.student_id !== "" ? teams.student_name : teams.faculty_name
                                                                    : teams.team_name
                                                            }
                                                        </div>
                                                        <div className="questiondetails" style={{ fontSize: `${questionsfontsize}px` }}>
                                                            {teams.questions.map((questiondetails, index) => {
                                                                if (props.type === "running") {
                                                                    if (index <= props.question_index) {
                                                                        total_time_taken = total_time_taken + questiondetails.time_taken;
                                                                        return (
                                                                            <span style={{ marginLeft: '7px' }}>
                                                                                {
                                                                                    questiondetails.is_correct === 1
                                                                                        ? <BsFillCircleFill style={{ color: "green", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                                        : questiondetails.is_correct === 0
                                                                                            ? <BsFillCircleFill style={{ color: "red", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                                            : <BsFillCircleFill style={{ color: "grey", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                                }
                                                                            </span>
                                                                        );
                                                                    }
                                                                } else {
                                                                    total_time_taken = total_time_taken + questiondetails.time_taken;
                                                                    return (
                                                                        <span style={{ marginLeft: '7px' }}>
                                                                            {
                                                                                questiondetails.is_correct === 1
                                                                                    ? <BsFillCircleFill style={{ color: "green", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                                    : questiondetails.is_correct === 0
                                                                                        ? <BsFillCircleFill style={{ color: "red", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                                        : <BsFillCircleFill style={{ color: "grey", fontSize: "10px" }} data-toggle="tooltip" title={`Time taken: ${questiondetails.time_taken} sec`} />
                                                                            }
                                                                        </span>
                                                                    );
                                                                }
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 col-sm-2">
                                                        <div><br />
                                                            <div style={{ fontSize: '0.5em', width: '100%', marginTop: '-30px' }}>Time - <span style={{ fontWeight: "700" }}>{teams.total_time_taken}s</span></div>
                                                            {/* <div style={{ fontSize: '0.5em' }}>{teams.total_time_taken} sec</div> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 col-sm-2 leaderboardrow-col" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-end" }}>
                                                        {/* <img src={avatars[index]} style={{ width: `${avatarwidth}px`, height: `${avatarheight}px`, borderRadius: "10px", marginTop: "0px" }} /> */}
                                                        <span style={{ fontSize: `${student_score_font_size}px` }}>{teams.total_correct_answers}/{teams.questions.length}</span>
                                                        {
                                                            props.type === "running"
                                                                ? (
                                                                    <button
                                                                        className="btn btn-success"
                                                                        style={{
                                                                            padding: "1px",
                                                                            height: "31px",
                                                                            fontSize: "10px",
                                                                        }}
                                                                        onClick={() => viewdetails(index)}
                                                                    >
                                                                        View question details
                                                                    </button>
                                                                )
                                                                : ''
                                                        }

                                                    </div>
                                                </Rotate>
                                            );
                                        }
                                    })
                                }
                            </>
                        )
                        : ""
                }



                {showdetails || showdetails === 0 ? (
                    <>
                        {props.data[showdetails].questions.map(
                            (questionsdata, index) => {
                                console.log(props.data[showdetails].questions);
                                return (
                                    <>
                                        {questionsdata.id == props.current_question_id ? (
                                            <Modal
                                                size="lg"
                                                show={showdetailsmodal}
                                                onHide={() =>
                                                    setShowdetailsmodal(false)
                                                }
                                                aria-labelledby="example-modal-sizes-title-sm"
                                            >
                                                <Modal.Header closeButton>
                                                    <div className="row" style={{ width: "100%" }}>
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <div>
                                                                <strong style={{ fontSize: "1.2em" }}>Last attempted question details</strong>
                                                            </div>
                                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                                                                <div>
                                                                    {
                                                                        questionsdata.is_correct === 1
                                                                            ? (<span style={{ color: "green" }}>Correct Answer</span>)
                                                                            : questionsdata.is_correct === 0
                                                                                ? (<span style={{ color: "red" }}>Wrong Answer</span>)
                                                                                : (<span style={{ color: "grey" }}>Unanswered!</span>)

                                                                    }
                                                                </div>
                                                                <div>{
                                                                    questionsdata.is_correct !== ""
                                                                        ? (
                                                                            <>
                                                                                Time Taken: {
                                                                                    questionsdata.time_taken === ""
                                                                                        ? "0s"
                                                                                        : questionsdata.time_taken + "s"
                                                                                }
                                                                            </>
                                                                        )
                                                                        : ""
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div style={{ height: "500px", overflowY: "auto", overflowX: "hidden" }}>
                                                        <div
                                                            style={{ display: "flex", alignItems: "center" }}
                                                        >
                                                            <div
                                                                className="questionsection_review"
                                                                style={{ display: "flex" }}
                                                            >
                                                                <div className="classdiv2" dir="auto">
                                                                    <span role="heading" aria-level="1">
                                                                        <strong>Q.</strong> {removeTags(
                                                                            questionsdata.question_data.question
                                                                        )}
                                                                    </span>
                                                                    <br />
                                                                    {questionsdata.question_data.question_img ? (
                                                                        <span role="heading" aria-level="1">
                                                                            {/* <img
                                                                                src={
                                                                                    questionsdata.question_data
                                                                                        .question_img
                                                                                }
                                                                                alt="question"
                                                                                style={{
                                                                                    height: "150px",
                                                                                    width: "200px",
                                                                                    marginLeft: "35%",
                                                                                    marginTop: "15px"
                                                                                }}
                                                                            /> */}
                                                                            {
                                                                                img_extensions.includes(questionsdata.question_data.question_img.split('.').pop().toLowerCase())
                                                                                    ? (
                                                                                        <img src={questionsdata.question_data.question_img}
                                                                                        //  className="question-image"
                                                                                        style={{
                                                                                            height: "150px",
                                                                                            width: "200px",
                                                                                            marginLeft: "35%",
                                                                                            marginTop: "15px"
                                                                                        }}
                                                                                        alt="question picture" />
                                                                                    )
                                                                                    : video_extensions.includes(questionsdata.question_data.question_img.split('.').pop().toLowerCase())
                                                                                        ? (
                                                                                            <video alt="question video"
                                                                                            controls 
                                                                                            style={{
                                                                                                height: "150px",
                                                                                                width: "200px",
                                                                                                marginLeft: "35%",
                                                                                                marginTop: "15px"
                                                                                            }}
                                                                                            >
                                                                                                <source src={questionsdata.question_data.question_img} type={`video/${questionsdata.question_data.question_img.split('.').pop().toLowerCase()}`} loop={true} />
                                                                                            </video>
                                                                                        )
                                                                                        : (
                                                                                            <audio controls style={{ marginTop: "25%" }}>
                                                                                                {
                                                                                                    questionsdata.question_data.question_img.split('.').pop().toLowerCase() === "mp3"
                                                                                                        ? (<source src={questionsdata.question_data.question_img} 
                                                                                                            style={{
                                                                                                                height: "75px",
                                                                                                                // width: "200px",
                                                                                                                marginLeft: "35%",
                                                                                                                marginTop: "15px"
                                                                                                            }}
                                                                                                            type="audio/mpeg" />)
                                                                                                        : (<source src={questionsdata.question_data.question_img} 
                                                                                                            style={{
                                                                                                                height: "75px",
                                                                                                                // width: "200px",
                                                                                                                marginLeft: "35%",
                                                                                                                marginTop: "15px"
                                                                                                            }}
                                                                                                            type={`audio/${questionsdata.question_data.question_img.split('.').pop().toLowerCase()}`} 
                                                                                                            />)
                                                                                                }
                                                                                                Your browser does not support the audio element.
                                                                                            </audio>
                                                                                        )
	                                                                             }
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {questionsdata.question_data.field_type == 0 ? (
                                                            <div className="answersdiv_review">
                                                                {questionsdata.question_data.options.map(
                                                                    (optiondetails, index) => {
                                                                        let is_correct_option;
                                                                        let is_correct_option_selected;
                                                                        if (optiondetails.is_correct === 1) {
                                                                            is_correct_option = true;
                                                                        }
                                                                        if (
                                                                            optiondetails.id ==
                                                                            questionsdata.question_data
                                                                                .answered_option &&
                                                                            !optiondetails.is_correct
                                                                        ) {
                                                                            console.log("is selected option");
                                                                            is_correct_option_selected = true;
                                                                        }
                                                                        return (
                                                                            <>
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        color="#e21b3c"
                                                                                        // onClick={() => this.optionselected(id)}
                                                                                        className="answermaindiv"
                                                                                        // style={{ pointerEvents: none }}
                                                                                        style={{ marginTop: "10px" }}
                                                                                    >
                                                                                        <div className="">
                                                                                            <div
                                                                                                className="card-icon__icon"
                                                                                                style={{
                                                                                                    background: `${optionbgcolors[index]}`,
                                                                                                    opacity: "0.9",
                                                                                                }}
                                                                                            >
                                                                                                {options[index]}
                                                                                            </div>
                                                                                        </div>

                                                                                        <span
                                                                                            aria-live="off"
                                                                                            dir="auto"
                                                                                            className="answertext"
                                                                                            style={{ fontSize: "15px" }}
                                                                                        >
                                                                                            <span>
                                                                                                {removeTags(
                                                                                                    optiondetails.option_name
                                                                                                )}
                                                                                            </span>
                                                                                        </span>
                                                                                        {is_correct_option ? (
                                                                                            <AiOutlineCheckCircle
                                                                                                style={{
                                                                                                    color: "green",
                                                                                                    fontSize: "40px",
                                                                                                }}
                                                                                            />
                                                                                        ) : null}
                                                                                        {is_correct_option_selected ? (
                                                                                            <AiFillLock
                                                                                                style={{
                                                                                                    color: "red",
                                                                                                    fontSize: "40px",
                                                                                                }}
                                                                                            />
                                                                                        ) : null}
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div className="answersdiv_review">
                                                                <div><strong>Your Answer: </strong>{questionsdata.question_data.answered_answer}</div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Modal.Body>
                                            </Modal>
                                        ) : null}
                                    </>
                                );
                            }
                        )}
                    </>
                ) : null}
            </div>
            <div className="col-lg-1 col-md-1 col-sm-0"></div>
        </div >
    )
}

const Rotate = styled.div`${props => props.top && css`
     animation-delay:5s;
     animation-fill-mode: forwards;
     animation:${(props) =>
        movediv(props.top)} 5s forwards;
  `}
`;

const movediv = (top) => keyframes`
  from {
   
   top:0px;
  }

  to {
  
   top:${top}px;
   margin:5px;
`;

export default LeaderBoard;