import React, { useEffect } from "react";
import DeptSemFacSectionMapping from "../components/facultySectionMapping/DeptSemFacSectionMapping";
import { getFacultySubjectMappings } from "../store/actions/facultySectionMapping.actions";
import { useDispatch} from "react-redux";
import {setTableIsLoading } from "../store/reducers/facultySectionMapping.reducer";
import LayoutwithSideNav from "../components/common/LayoutwithSideNav";
import MaterialReduxNotification from "../components/common/MaterialReduxNotification";
import { setNotification } from "../store/reducers/common.reducer";


// const drawerWidth = 200;

export default function FacultySectionMapping() {

  const dispatch = useDispatch();

  // Setting the Title
  useEffect(() => {
    document.title = 'Faculty-Student Allocation';
  }, []);

  // Getting Faculty Sections Data
  useEffect(() => {
    async function fetchData() {
      dispatch(setTableIsLoading(true));
      const semMappingResponse = await dispatch(getFacultySubjectMappings());
      dispatch(setTableIsLoading(false));
      console.log(semMappingResponse);
      if (semMappingResponse?.data?.status  !== 200) {
        dispatch( setNotification({ 
          openNotification: true,
          notificationMessage: `Error : ${semMappingResponse?.data?.message}, Retriving Faculty-Student Allocation Data Failed`,
          notificationType: "error",
        }));

        return ;
      }
    }
    try {
      fetchData();
    } catch (error) {
      return error;
    }
  }, [dispatch]);

  return (
    <LayoutwithSideNav>
      {/* <div className="col-lg-6 col-md-6 col-sm-6"> */}
      <div>
        <h2 className="mt-1">
          <p className="font-weight-bold" id="courseName">
            Faculty Student Allocation
          </p>
          <hr className="titlehr" />
        </h2>
      </div>
      <DeptSemFacSectionMapping />
      <MaterialReduxNotification/>
    </LayoutwithSideNav>
  );
}

// Pagination i.e bottom page indexing needs to be added.
// SideNav bar to be made responsive.
