import React, { useRef, useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';
import { Fragment } from 'react';
// import { PieChart, Pie, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from 'recharts';
import Html from 'react-pdf-html';
// import html2canvas from 'html2canvas';
// import domtoimage from 'dom-to-image';
// import ChartImage from './chartImage.component';

const styles = StyleSheet.create({
    viewer: {
        width: '100%', //the pdf viewer will take up all of the width and height
        height: window.innerHeight,
    },
    view: {
        fontSize: 10,
    },
    page: {
        backgroundColor: 'white',
        flexDirection: 'column',
        margin: 15,
        paddingBottom: 70,
        paddingTop: 25,
        marginLeft: 30,
        marginRight: 10,
        width: '90%',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 5,
        width: '90%'
    },
    logo1: {
        width: 50,
        height: 70,
        marginLeft: "30px",
    },
    logo01: {
        width: 60,
        height: 70,
        marginLeft: "30px",
    },
    logo02: {
        width: 50,
        height: 50,
        marginLeft: "30px",
    },
    logo03: {
        width: 70,
        height: 50,
        marginLeft: "30px",
    },
    logo2: {
        width: 70,
        height: 60,
        marginRight: "10px",
        marginTop: '10px'
    },
    logo21: {
        width: 45,
        height: 55,
        marginRight: "10px",
        marginTop: '10px'
    },
    headerText: {
        fontSize: 15,
        fontWeight: 'bold',
        marginLeft: 15,
        marginRight: 15,
        textAlign: 'center'
    },
    subHeaderText: {
        fontSize: 12,
        marginTop: "10px",
        textAlign: 'center'
    },
    clgName: {
        alignItems: 'center',
        width: "66.67%",
    },
    semYearDetails: {
        alignItems: 'center',
        width: '90%'
    },
    sfReportLabel: {
        fontWeight: 500,
        fontSize: 14
    },
    semYearLabel: {
        fontSize: 10
    },
    rowSpan: {
        paddingLeft: 20,
        paddingRight: 30,
        marginTop: 10,
        width: '90%',
        lineHeight: 0.7,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    rowSpan_details: {
        paddingLeft: 20,
        paddingRight: 30,
        marginTop: 2,
        width: '90%',
        lineHeight: 0.7,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    rowSpan1: {
        width: '60%',
        flexDirection: 'row',
        // flexWrap: 'wrap',
        wordBreak: 'break-all',
        marginTop: 5,
        lineHeight: 0.9,
    },
    rowSpan2: {
        width: '30%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        wordBreak: 'break-all',
        marginTop: 5,
        lineHeight: 0.9,
    },
    rowSpan22: {
        width: '30%',
        flexDirection: 'row',
        // flexWrap: 'wrap',
        wordBreak: 'break-all',
        marginTop: 5,
        lineHeight: 0.9,
    },
    labelHeading: {
        fontWeight: 'bold',
        fontSize: 12,
        textDecoration: 'underline'
    },
    sectionHeading: {
        fontWeight: 'bold',
        fontSize: 14,
        textDecoration: 'underline'
    },
    label: {
        fontWeight: 'bold',
        fontSize: 11,
    },
    value: {
        marginLeft: 5,
        fontWeight: 'normal',
        fontSize: 10,
        marginTop: '1px'
    },
    rowValues: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        // marginBottom: 50,
        width: '90%'
    },
    chart: {
        width: '100%',
        height: 250,
        marginLeft: 20,
        overflow: 'hidden'
    },
    chartRowSpan: {
        marginTop: 10,
        width: '180%',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    chartRowSpan1: {
        width: '40%',
        flexDirection: 'column',
        marginTop: 5,
        wordBreak: 'break-all',
        lineHeight: 1,
        paddingLeft: 25,
        paddingTop: 50,
    },
    chartRowSpan2: {
        width: '140%',
        flexDirection: 'row',
        marginTop: 5,
    },
    chartBoxColorView: {
        width: 20,
        height: 10,
        border: '1px solid black',
        marginRight: 10
    },
    chartInnerrowSpan1: {
        width: '100%',
        flexDirection: 'row',
        // flexWrap: 'wrap',
        wordBreak: 'break-all',
        marginTop: 8,
        lineHeight: 1,
    }
});

const LiveSurveyReportTemplate = (props) => {
    Font.register({ family: 'arial', src: '/files/arial.ttf' });
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    let tag_line = ""
    if(user){
        if(user.college_id === 27){
            tag_line = "(An Autonomous Institution)";
        }else if(user.college_id === 29){
            tag_line = "(An Autonomous Institution)";
        }else if(user.college_id === 32){
            tag_line = "(An Autonomous Institution)";
        }else if(user.college_id === 40){
            tag_line = "(An Autonomous Institution)";
        }else if(user.college_id === 41){
            tag_line = "(An Autonomous Institution)";
        }else if(user.college_id === 38){
            tag_line = "(Deemed to be University)";
        }else if(user.college_id === 51){
            tag_line = "";
        }else if(user.college_id === 36){
            tag_line = "(An Autonomous Institution)";
        }else{
            tag_line = "(An Autonomous Institution)";
        }
    }

    return (
        // <PDFViewer style={styles.viewer}>
            <Document>
                {
                    props.reportData.map(report_data => {
                        let pdfPages = []
                        pdfPages.push(
                            <React.Fragment key='test_details'>
                                <Page size='A4' style={styles.page}>
                                    <View style={styles.header}>
                                        <View style={styles.col2}>
                                            <Image 
                                                style={
                                                    [38,40,41].includes(user.college_id) 
                                                        ? styles.logo01 
                                                        : [51].includes(user.college_id)
                                                            ? styles.logo02
                                                            : [36].includes(user.college_id)
                                                                ? styles.logo03
                                                                : styles.logo1
                                                } 
                                                src={report_data.college_logo ? report_data.college_logo : `/images/feedback_images/resultsPage.jpg`} 
                                            />
                                        </View>
                                        <View style={styles.clgName}>
                                            <Text style={styles.headerText}>{report_data.college_name}</Text>
                                            {/* <Text style={styles.headerText}>R.M.K College of Engineering and Technology</Text> */}
                                            {
                                                ![51].includes(user.college_id)
                                                    ? (
                                                        <Text style={styles.subHeaderText}>{tag_line}</Text>
                                                    )
                                                    : ""
                                            }
                                        </View>
                                        <View style={styles.col2}>
                                            {
                                                [27].includes(user.college_id)
                                                    ? (
                                                        <Image style={styles.logo2} src="/images/feedback_images/resultsPage-logo2.jpg" />
                                                    )
                                                    : [32].includes(user.college_id) 
                                                        ? (
                                                            <Image style={styles.logo21} src="/images/feedback_images/rmk_group_28years_logo.png" />
                                                        )
                                                        : (
                                                            <View style={styles.logo2}></View>
                                                        )
                                            }
                                        </View>
                                    </View>
                                    <View style={styles.semYearDetails}>
                                        <Text style={styles.sfReportLabel}>LIVE SURVEY REPORT</Text>
                                        {/* <Text style={styles.semYearLabel}>ACADEMIC YEAR: 2022-23 {parseInt(feedback.semester_id) % 2 == 0 ? 'EVEN' : 'ODD'} SEMESTER</Text> */}
                                        <Text style={styles.semYearLabel}>ACADEMIC YEAR: {report_data.academic_year}</Text>
                                    </View>

                                    {/* Test details */}
                                    <View style={styles.rowSpan}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.sectionHeading}>Survey Details</Text>
                                        </View>
                                        <View style={styles.rowSpan2}>
                                            <Text style={styles.sectionHeading}>Faculty Details</Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Survey Name : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>{report_data.test_name}</Text>
                                        </View>
                                        <View style={styles.rowSpan2}>
                                            <Text style={styles.label}>Faculty Name : </Text>
                                            <Text style={styles.value} numberOfLines={1} ellipsizeMode='tail'>{report_data.faculty_name}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Description : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>{report_data.description}</Text>
                                        </View>
                                        <View style={styles.rowSpan22}>
                                            <Text style={styles.label}>Role : </Text>
                                            <Text style={{ ...styles.value, textOverflow: 'ellipsis' }} numberOfLines={1} ellipsizeMode='tail'>{report_data.role}</Text>
                                        </View>
                                    </View>

                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Start Time : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.start_time}
                                            </Text>
                                        </View>
                                        <View style={styles.rowSpan22}>
                                            <Text style={styles.label}>Department : </Text>
                                            <Text style={{ ...styles.value, textOverflow: 'ellipsis', marginLeft: '25px', marginRight: '5px' }} numberOfLines={2} ellipsizeMode='tail'>{report_data.branch_name}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>End Time : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.end_time}
                                            </Text>
                                        </View>
                                    </View>
                                    {/* <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Subject : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.subject}
                                            </Text>
                                        </View>
                                    </View> */}
                                    {/* <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Department : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.branch_name}
                                            </Text>
                                        </View>
                                    </View> */}
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Total Questions : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.total_no_of_questions}
                                            </Text>
                                        </View>
                                    </View>
                                    {/* <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Total Marks : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.total_test_marks}
                                            </Text>
                                        </View>
                                    </View> */}
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Total Attendees : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.no_of_attendees}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Total Submissions : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.no_of_submissions}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Total Followers : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.total_no_of_followers}
                                            </Text>
                                        </View>
                                    </View>


                                    {/* Overall Questions performance */}
                                    <View style={styles.rowSpan}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.sectionHeading}>Overall Questions Performance</Text>
                                        </View>
                                    </View>
                                    {/* <View style={styles.tableContainer}>
                                        <OverallQuestionsPerformanceTable data={report_data.question_overall_analytics}
                                            question_start={0} question_end={6} />
                                    </View> */}
                                    <OverallQuestionsList data={report_data.question_overall_analytics}
                                        question_start={0}
                                        question_end={report_data.questions_has_options >= 2 ? 1 : 7}
                                    />

                                </Page>
                            </React.Fragment>
                        )

                        //

                        // overall questions performance table if over 10 questions
                        let total_questions = report_data.question_overall_analytics.length;
                        let questions_in_first_page = report_data.questions_has_options >= 2 ? 2 : 7;
                        if (total_questions > questions_in_first_page) {
                            let questions_in_page = getOverallQuestionInEachPage(report_data.questions_has_options);
                            let pages = Math.ceil((total_questions - questions_in_first_page) / questions_in_page);
                            console.log("pages -> ", pages);
                            for (let i = 1; i <= pages; i++) {
                                let question_start = (i - 1) * (questions_in_page - 1) + (i - 1);
                                let question_end = i * (questions_in_page - 1) + (i - 1);
                                question_start = question_start + questions_in_first_page;
                                question_end = question_end + questions_in_first_page;
                                // Adjust question_end if it exceeds the actual number of questions
                                question_end = Math.min(question_end, total_questions);
                                console.log("question_end -> ", question_end);
                                pdfPages.push(
                                    <React.Fragment key={`question_analytics_` + i}>
                                        <Page size='A4' style={styles.page}>
                                            <View style={styles.header}>
                                                <View style={styles.col2}>
                                                    <Image 
                                                        style={
                                                            [38,40,41].includes(user.college_id) 
                                                                ? styles.logo01 
                                                                : [51].includes(user.college_id)
                                                                    ? styles.logo02
                                                                    : [36].includes(user.college_id)
                                                                        ? styles.logo03
                                                                        : styles.logo1
                                                        } 
                                                        src={report_data.college_logo ? report_data.college_logo : `/images/feedback_images/resultsPage.jpg`} 
                                                    />
                                                </View>
                                                <View style={styles.clgName}>
                                                    <Text style={styles.headerText}>{report_data.college_name}</Text>
                                                    {/* <Text style={styles.headerText}>R.M.K College of Engineering and Technology</Text> */}
                                                    {
                                                        ![51].includes(user.college_id)
                                                            ? (
                                                                <Text style={styles.subHeaderText}>{tag_line}</Text>
                                                            )
                                                            : ""
                                                    }
                                                </View>
                                                <View style={styles.col2}>
                                                    {
                                                        [27].includes(user.college_id)
                                                            ? (
                                                                <Image style={styles.logo2} src="/images/feedback_images/resultsPage-logo2.jpg" />
                                                            )
                                                            : [32].includes(user.college_id) 
                                                                ? (
                                                                    <Image style={styles.logo21} src="/images/feedback_images/rmk_group_28years_logo.png" />
                                                                )
                                                                : (
                                                                    <View style={styles.logo2}></View>
                                                                )
                                                    }
                                                </View>
                                            </View>

                                            {/* <View style={styles.tableContainer}>
                                                <OverallQuestionsPerformanceTable data={report_data.question_overall_analytics}
                                                    question_start={question_start} question_end={question_end} />
                                            </View> */}
                                            <OverallQuestionsList data={report_data.question_overall_analytics}
                                                question_start={question_start} question_end={question_end} />
                                        </Page>
                                    </React.Fragment>
                                )
                            }

                        }

                        // students performance
                        let students_in_page = user.college_id === 38 ? 20 : 25;
                        let total_students = report_data.studentwise_analytics.length;
                        let student_pages = Math.ceil(total_students / students_in_page);
                        for (let i = 1; i <= student_pages; i++) {
                            let student_start = (i - 1) * (students_in_page - 1) + (i - 1);
                            let student_end = i * (students_in_page - 1) + (i - 1);
                            // Adjust student_end if it exceeds the actual number of questions
                            student_end = Math.min(student_end, total_students);

                            pdfPages.push(
                                <React.Fragment key={`student_performance_` + i}>
                                    <Page size='A4' style={styles.page}>
                                        <View style={styles.header}>
                                            <View style={styles.col2}>
                                                <Image 
                                                    style={
                                                        [38,40,41].includes(user.college_id) 
                                                            ? styles.logo01 
                                                            : [51].includes(user.college_id)
                                                                ? styles.logo02
                                                                : [36].includes(user.college_id)
                                                                    ? styles.logo03
                                                                    : styles.logo1
                                                    } 
                                                    src={report_data.college_logo ? report_data.college_logo : `/images/feedback_images/resultsPage.jpg`} 
                                                />
                                            </View>
                                            <View style={styles.clgName}>
                                                <Text style={styles.headerText}>{report_data.college_name}</Text>
                                                {/* <Text style={styles.headerText}>R.M.K College of Engineering and Technology</Text> */}
                                                {
                                                    ![51].includes(user.college_id)
                                                        ? (
                                                            <Text style={styles.subHeaderText}>{tag_line}</Text>
                                                        )
                                                        : ""
                                                }
                                            </View>
                                            <View style={styles.col2}>
                                                {
                                                    [27].includes(user.college_id)
                                                        ? (
                                                            <Image style={styles.logo2} src="/images/feedback_images/resultsPage-logo2.jpg" />
                                                        )
                                                        : [32].includes(user.college_id) 
                                                            ? (
                                                                <Image style={styles.logo21} src="/images/feedback_images/rmk_group_28years_logo.png" />
                                                            )
                                                            : (
                                                                <View style={styles.logo2}></View>
                                                            )
                                                }
                                            </View>
                                        </View>

                                        {
                                            i === 1 ? (
                                                <View style={styles.rowSpan}>
                                                    <View style={styles.rowSpan1}>
                                                        <Text style={styles.sectionHeading}>Attendees Performance</Text>
                                                    </View>
                                                </View>
                                            )
                                                : ''
                                        }

                                        <View style={styles.tableContainer}>
                                            <StudentsPerformanceTable data={report_data.studentwise_analytics}
                                                student_start={student_start} student_end={student_end} />
                                        </View>
                                    </Page>
                                </React.Fragment>
                            )

                        }

                        return <>{pdfPages}</>
                    })
                }
            </Document>
        // </PDFViewer >
    )

}

export default LiveSurveyReportTemplate;

const getOverallQuestionInEachPage = (no_of_questions) => {
    console.log("no_of_questions -> ", no_of_questions);
    let pages = 16;
    switch (no_of_questions) {
        case 0: pages = 16; break;
        case 1: pages = 16; break;
        case 2: pages = 16; break;
        case 3: pages = 12; break;
        case 4: pages = 8; break;
        case 5: pages = 6; break;
        case 6: pages = 4; break;
        default: if (no_of_questions > 6) {
            pages = 4; break;
        }
    }
    console.log("pages -> ", pages);
    return pages;
}

const OverallQuestionsList = (props) => {
    const rows = props.data.map((question, index) => {
        if (index >= props.question_start && index <= props.question_end) {
            let question_name = question.name.replace("<!DOCTYPE html>","");
            return (
                <View>
                    <View style={styles.rowSpan}>
                        <Text style={styles.label}>{index + 1}.&nbsp;
                            <Html style={styles.label}>{question_name ? question_name : ""}</Html>
                        </Text>
                    </View>
                    <View style={styles.rowSpan}>
                        {
                            question.image
                                ? (
                                    <Image src={question.image} style={styles2.question_img1} alt="image" />
                                )
                                : ''
                        }
                    </View>
                    {
                        question.has_options
                            ? (
                                <OverallQuestionsListOptionsTable optionData={question.options} />
                            )
                            : (
                                <View style={{...styles.rowSpan, marginTop: 0}}>
                                    <Text style={styles.value}> - No Options</Text>
                                </View>
                            )
                    }
                </View>
            )
        }
    })

    return <Fragment>{rows}</Fragment>
}

const OverallQuestionsListOptionsTable = (props) => {
    return (
        <View style={styles2.optionsTable}>
            <View>
                <View style={styles2.tableHeader}>
                    <View style={styles2.tableRow}>
                        <View style={{ ...styles2.tableCol01, borderRight: '1px solid white' }} >
                            <Text style={styles2.tableCell1}>S.No</Text>
                        </View>
                        <View style={{ ...styles2.otableCol2, borderRight: '1px solid white' }}>
                            <Text style={styles2.tableCell2}>Option</Text>
                        </View>
                        <View style={styles2.otableCol3}>
                            <Text style={styles2.tableCell3}>Attempted Count</Text>
                        </View>
                    </View>
                </View>
                <OverallQuestionsListOptionsTableRow items={props.optionData} />
            </View>
        </View>
    )
}

const OverallQuestionsListOptionsTableRow = (props) => {
    const rows = props.items.map((option, index) => {
        let option_name = option.name.replace("<!DOCTYPE html>","");
        return (
            <View style={styles2.tableRow}>
                <View style={{ ...styles2.tableCol01, borderRight: '1px solid #e6e6e6' }}>
                    <Text style={styles2.tableValueCell1}>{index + 1}</Text>
                </View>
                <View style={{ ...styles2.otableCol2, borderRight: '1px solid #e6e6e6' }}>
                    <Text style={styles2.tableValueCell2}>
                        <Html style={styles2.tableValueCell2}>{option_name ? option_name : ""}</Html>
                    </Text>
                </View>
                <View style={{ ...styles2.otableCol3, borderRight: '1px solid #e6e6e6' }}>
                    <Text style={styles2.tableValueCell1}>{option.attempted_count ? option.attempted_count : 0}</Text>
                </View>
            </View>
        )
    })
    return <Fragment>{rows}</Fragment>;
}

//table stylings
const styles2 = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
    },
    table: {
        marginBottom: 10,
        fontSize: 10,
        width: '100%',
        border: '1px solid grey',
        marginTop: 20,
        marginLeft: 20,
        marginRight: 30,
        borderRadius: '5px'
    },
    optionsTable: {
        marginBottom: 10,
        fontSize: 10,
        width: '60%',
        border: '1px solid grey',
        marginTop: 20,
        marginLeft: 20,
        marginRight: 30,
        borderRadius: '5px'
    },
    tableHeader: {
        backgroundColor: '#F0F0F0',
        fontWeight: 'bold',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottom: '1px solid #e6e6e6'
    },
    tableRow2: {
        flexDirection: 'row',
    },
    tableCol01: {
        width: '10%',
    },
    tableCol2: {
        width: '50%',
    },
    otableCol2: {
        width: '60%',
    },
    tableValCol2: {
        width: '50%',
    },
    tableCol3: {
        width: '20%',
    },
    otableCol3: {
        width: '30%',
    },
    tableValCol3: {
        width: '20%',
    },
    tableCell1: {
        margin: '3px',
        fontSize: 11,
    },
    tableValueCell1: {
        margin: '3px',
        fontSize: 9,
        padding: "1px",
        textAlign: 'center',
    },
    tableValueCell2: {
        margin: '3px',
        fontSize: 9,
        padding: "1px",
    },
    tableCell2: {
        margin: '3px',
        fontSize: 10,
        // wordBreak: 'break-all', // Allow long words to break
        whiteSpace: 'nowrap', // Prevent line breaks within the text
        flexWrap: 'nowrap'
    },
    tableCell3: {
        margin: '3px',
        fontSize: 10,
        textAlign: 'center',
        whiteSpace: 'nowrap', // Prevent line breaks within the text
        flexWrap: 'nowrap'
    },

    tpTableCol1: {
        width: '10%',
    },
    tpTableCol2: {
        width: '50%',
    },
    tpTableCol3: {
        width: '20%',
    },
    tpTableCell2: {
        margin: '3px',
        fontSize: 10,
    },
    tpTableValueCell1: {
        margin: '3px',
        fontSize: 10,
        padding: "1px",
    },

    qaTableCol2: {
        width: '75%',
    },
    qaTableCol3: {
        width: '25%',
    },
    qaTableCol4: {
        width: '15%',
    },

    spTableCol1: {
        width: '10%',
        textAlign: 'center'
    },
    spTableCol12: {
        width: '12%',
        textAlign: 'center'
    },
    spTableCol13: {
        width: '8%',
        textAlign: 'center'
    },
    spTableCol01: {
        width: '7%',
    },
    spTableCol2: {
        width: '18%',
        textAlign: 'center',
    },
    spTableCol3: {
        width: '20%',
        textAlign: 'center'
    },
    subTableCol1: {
        width: '100%',
    },
    subTableColVal1: {
        width: '100%',
        fontSize: 11,
        fontWeight: 700,
        margin: '3px',
        padding: '1px',
    },
    sqpTableCol2: {
        width: '50%'
    },
    sqpTableCol3: {
        width: '20%'
    },
    sqpTableCol4: {
        width: '10%'
    },

    spTableCol21: {
        width: '25%',
    },
    spTableCol22: {
        width: '20%',
        textAlign: 'center'
    },

    question_img: {
        width: '20%',
        height: '45px',
        marginLeft: '15px',
        marginBottom: '2px'
    },
    question_img1: {
        width: '15%',
        height: '45px',
        marginLeft: '15px',
        marginBottom: '2px'
    }
});

// const OverallQuestionsPerformanceTable = (props) => {
//     return (
//         <View style={styles2.table}>
//             <View>
//                 <View style={styles2.tableHeader}>
//                     <View style={styles2.tableRow}>
//                         <View style={{ ...styles2.tableCol01, borderRight: '1px solid white' }} >
//                             <Text style={styles2.tableCell1}>S.No</Text>
//                         </View>
//                         <View style={{ ...styles2.tableCol2, borderRight: '1px solid white' }}>
//                             <Text style={styles2.tableCell2}>Question</Text>
//                         </View>
//                         <View style={{ ...styles2.tableCol3, borderRight: '1px solid white' }}>
//                             <Text style={styles2.tableCell3}>Attempted Count</Text>
//                         </View>
//                         <View style={styles2.tableCol3}>
//                             <Text style={styles2.tableCell3}>Unattempted Count</Text>
//                         </View>
//                     </View>
//                 </View>
//                 <OverallQuestionsPerformanceTableRow items={props.data} question_start={props.question_start}
//                     question_end={props.question_end} />
//             </View>
//         </View>
//     )
// }


// export const OverallQuestionsPerformanceTableRow = (props) => {
//     const rows = props.items.map((question, index) => {
//         if (index >= props.question_start && index <= props.question_end) {
//             return (
//                 <View style={styles2.tableRow}>
//                     <View style={{ ...styles2.tableCol01, borderRight: '1px solid #e6e6e6' }}>
//                         <Text style={styles2.tableValueCell1}>{index + 1}</Text>
//                     </View>
//                     <View style={{ ...styles2.tableValCol2, borderRight: '1px solid #e6e6e6' }}>
//                         <Text style={styles2.tableValueCell2}>
//                             <Html style={styles2.tableValueCell2}>{question.name ? question.name : ""}</Html>
//                         </Text>
//                         {
//                             question.image
//                                 ? (
//                                     <Image src={question.image} style={styles2.question_img} alt="image" />
//                                 )
//                                 : ''
//                         }
//                     </View>
//                     <View style={{ ...styles2.tableValCol3, borderRight: '1px solid #e6e6e6' }}>
//                         <Text style={styles2.tableValueCell1}>{question.attempted_count ? question.attempted_count : 0}</Text>
//                     </View>
//                     <View style={styles2.tableValCol3}>
//                         <Text style={styles2.tableValueCell1}>{question.unattempted_count ? question.unattempted_count : 0}</Text>
//                     </View>
//                 </View>
//             )
//         }
//     })
//     return <Fragment>{rows}</Fragment>;
// };


//table for students performance
const StudentsPerformanceTable = (props) => {
    return (
        <View style={styles2.table}>
            <View>
                <View style={{ ...styles2.tableHeader, backgroundColor: '#e6e6e6' }}>
                    <View style={styles2.tableRow}>
                        <View style={{ ...styles2.spTableCol01, borderRight: '1px solid white' }} >
                            <Text style={styles2.tableCell1}>S.No</Text>
                        </View>
                        <View style={{ ...styles2.spTableCol21, borderRight: '1px solid white' }}>
                            <Text style={styles2.tpTableCell2}>Name</Text>
                        </View>
                        <View style={{ ...styles2.spTableCol2, borderRight: '1px solid white' }}>
                            <Text style={styles2.tableCell2}>Roll number</Text>
                        </View>
                        <View style={{ ...styles2.spTableCol1, borderRight: '1px solid white' }}>
                            <Text style={styles2.tableCell2}>Role</Text>
                        </View>
                        <View style={{ ...styles2.spTableCol22, borderRight: '1px solid white' }}>
                            <Text style={styles2.tableCell2}>Branch</Text>
                        </View>
                        <View style={{ ...styles2.spTableCol12, borderRight: '1px solid white' }}>
                            <Text style={styles2.tableCell2}>Question Count</Text>
                        </View>
                        <View style={styles2.spTableCol13}>
                            <Text style={styles2.tableCell2}>Time (m)</Text>
                        </View>
                    </View>
                </View>
                <StudentsPerformanceTableRow items={props.data} student_start={props.student_start} student_end={props.student_end} />
            </View>
        </View>
    )
}

const StudentsPerformanceTableRow = (props) => {
    const rows = props.items.map((item, index) => {
        if (index >= props.student_start && index <= props.student_end) {
            return (
                <View style={styles2.tableRow}>
                    <View style={{ ...styles2.spTableCol01, borderRight: '1px solid #e6e6e6' }}>
                        <Text style={styles2.tableValueCell1}>{index + 1}</Text>
                    </View>
                    <View style={{ ...styles2.spTableCol21, borderRight: '1px solid #e6e6e6' }}>
                        <Text style={styles2.tpTableValueCell1}>{item.name ? item.name : '-'}</Text>
                    </View>
                    <View style={{ ...styles2.spTableCol2, borderRight: '1px solid #e6e6e6' }}>
                        <Text style={styles2.tableValueCell1}>{item.roll_number ? item.roll_number : '-'}</Text>
                    </View>
                    <View style={{ ...styles2.spTableCol1, borderRight: '1px solid #e6e6e6' }}>
                        <Text style={styles2.tableValueCell1}>{item.role ? item.role : '-'}</Text>
                    </View>
                    <View style={{ ...styles2.spTableCol22, borderRight: '1px solid #e6e6e6' }}>
                        <Text style={styles2.tableValueCell1}>
                            {
                                item.branch_short_name
                                    ? item.section_name
                                        ? `${item.branch_short_name} (${item.section_name})`
                                        : item.branch_short_name
                                    : '-'
                            }
                        </Text>
                    </View>
                    <View style={{ ...styles2.spTableCol12, borderRight: '1px solid #e6e6e6' }}>
                        <Text style={styles2.tableValueCell1}>{item.attempted_count ? item.attempted_count : '-'}</Text>
                    </View>
                    <View style={styles2.spTableCol13}>
                        <Text style={styles2.tableValueCell1}>{item.total_time_spent ? (item.total_time_spent / 60).toFixed(1) : '-'}</Text>
                    </View>
                </View>

            )
        }
    })
    return <Fragment>{rows}</Fragment>;
}
{/* submissions
    <View style={styles2.tableRow}>
        <View style={{ ...styles2.spTableCol1, borderRight: '1px solid #e6e6e6' }}>
            <Text style={styles2.tableValueCell1}></Text>
        </View>
        <View style={styles2.subTableCol1}>
            <Text style={styles2.subTableColVal1}>Submissions : </Text>
        </View>
    </View>
    <StudentsQuesPerformanceTable data={item.submissions} />
    <View style={styles2.tableRow}>
        <View style={{ ...styles2.subTableCol1, padding: '10px' }}></View>
    </View> */}
// const StudentsQuesPerformanceTable = (props) => {
//     return (
//         <>
//             <View style={{
//                 ...styles2.tableRow,
//                 backgroundColor: '#caf0fa',
//                 fontWeight: 'bold',
//             }}>
//                 <View style={{ ...styles2.spTableCol1, borderRight: '1px solid #e6e6e6', backgroundColor: 'white' }}>
//                     <Text style={styles2.tableValueCell1}></Text>
//                 </View>
//                 <View style={{ ...styles2.sqpTableCol2, borderRight: '1px solid white' }}>
//                     <Text style={styles2.tableCell2}>Question</Text>
//                 </View>
//                 <View style={{ ...styles2.sqpTableCol3, borderRight: '1px solid white' }}>
//                     <Text style={styles2.tableCell3}>Option</Text>
//                 </View>
//                 <View style={{ ...styles2.sqpTableCol3, borderRight: '1px solid white' }}>
//                     <Text style={styles2.tableCell3}>Answer</Text>
//                 </View>
//                 <View style={styles2.sqpTableCol4}>
//                     <Text style={styles2.tableCell3}>Time (s)</Text>
//                 </View>
//             </View>
//             <StudentsQuesPerformanceTableRow items={props.data} />
//         </>
//     )
// }


// const StudentsQuesPerformanceTableRow = (props) => {
//     const rows = props.items.map((item, index) => {
//         return (
//             <View style={styles2.tableRow}>
//                 <View style={{ ...styles2.spTableCol1, borderRight: '1px solid #e6e6e6', backgroundColor: 'white' }}>
//                     <Text style={styles2.tableValueCell1}></Text>
//                 </View>
//                 <View style={{ ...styles2.sqpTableCol2, borderRight: '1px solid #e6e6e6' }}>
//                     <Text style={styles2.tpTableValueCell1}>{parseLatex(item.name) ? item.name : '-'}</Text>
//                 </View>
//                 <View style={{ ...styles2.sqpTableCol3, borderRight: '1px solid #e6e6e6' }}>
//                     <Text style={styles2.tableValueCell1}>{item.option ? item.option : '-'}</Text>
//                 </View>
//                 <View style={{ ...styles2.sqpTableCol3, borderRight: '1px solid #e6e6e6' }}>
//                     <Text style={styles2.tableValueCell1}>{item.answer ? item.answer : '-'}</Text>
//                 </View>
//                 <View style={styles2.sqpTableCol4}>
//                     <Text style={styles2.tableValueCell1}>{item.time_taken ? item.time_taken : '-'}</Text>
//                 </View>
//             </View>
//         )
//     })
//     return <Fragment>{rows}</Fragment>;
// }


// Basic LaTeX parser for demonstration purposes
function parseLatex(input) {
    // Replace LaTeX syntax with corresponding React components
    // This is a simplified example, you might need to implement a more complete parser
    const latexToReact = {
        '\\textbf': (content) => <Text style={{ fontWeight: 'bold' }}>{content}</Text>,
        '\\textit': (content) => <Text style={{ fontStyle: 'italic' }}>{content}</Text>,
        // Add more LaTeX commands here
    };

    const regex = /\\(.*?)\{(.*?)\}/g;
    const parsed = input.replace(regex, (_, command, content) => {
        const reactComponent = latexToReact[command];
        return reactComponent ? reactComponent(content) : content;
    });

    return parsed;
}