import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFacultyQuestionSubjectWiseScore } from "../../store/actions/facultyPerformanceReview.actions";
import { setNotification } from "../../store/reducers/common.reducer";
import { setTableIsLoading, setFacultyQuestionSubjectWiseScore } from "../../store/reducers/facultyPerformanceReview.reducer";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Breadcrumb from "../common/Breadcrumb";
import HeadingSecondary from "../common/HeadingSecondary";
import Grow from '@mui/material/Grow';
import BasicTable from "../common/BasicTable";
import { useParams } from "react-router-dom";
// import MaterialButton from "../common/MaterialButton";
import RateReviewIcon from '@mui/icons-material/RateReview';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Alert from "@mui/material/Alert";
import UnitDetailsCard from "./UnitDetailsCard";
import QuestionGraphsList from "./QuestionGraphsList";
import SendSurveyModal from "./SendSurveyModal";
import { setSelectedSendSurvey } from "../../store/reducers/facultyPerformanceReview.reducer";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function UnitWiseReview() {
  const {subsemId, facId, unitId, sectionId} = useParams();

  const [tabsValue, setTabsValue] = useState(0);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [facultyDetails, setFacultyDetails] = useState({});
  const [selectedQuestionsData, setSelectedQuestionsData] = useState({});
  const [allSurveyQuestionLevelData, setAllSurveyQuestionLevelData] = useState([]);
  const [openSendSurveyModal, setOpenSendSurveyModal] = useState(false);

  const dispatch = useDispatch();
  // console.log(subsemId)
  // console.log(facId)

  const {allFacultyQuestionSubjectWiseScore} = useSelector(state => state.FacultyPerformanceReview)

  // Clearing selectedSendSurvey data when unmounting as it will else showup in the application
  useEffect(()=>{
    return () => {dispatch(setSelectedSendSurvey({selectedSendSurvey : []}))}
  },[])

  // Get Unit Wise Faculty Performance Data
  useEffect(() => {
    async function fetchData() {
      setTableIsLoading(true)
      const unitWisePerfResponse = await dispatch(getFacultyQuestionSubjectWiseScore(subsemId, facId,sectionId));
      // console.log(unitWisePerfResponse)
      if (unitWisePerfResponse?.data?.status  !== 200) {
        dispatch( setNotification({ 
          openNotification: true,
          notificationMessage: `Error : ${unitWisePerfResponse?.data?.message}, Status : ${unitWisePerfResponse?.data?.status}, Retriving Unit Wise Faculty Performance Data Failed`,
          notificationType: "error",
        }));

        return ;
      }

      // dispatch(setFacultyQuestionSubjectWiseScore({allFacultyQuestionSubjectWiseScore : unitWisePerfResponse.data.data}))
      setTableIsLoading(false)
    }
    try {
      fetchData();
    } catch (error) {
      return error;
    }
  }, [subsemId, facId]);

  useEffect(()=>{
    const {feedbacks,...allFacultyUnitDetails} =allFacultyQuestionSubjectWiseScore;
    setAllSurveyQuestionLevelData(feedbacks);
    setFacultyDetails(allFacultyUnitDetails);

  },[allFacultyQuestionSubjectWiseScore])

  useEffect(()=>{
    // console.log(unitId)
    // console.log(allFacultyQuestionSubjectWiseScore)
    // Selecting the Tab based on Index and url
    const unitIndex = allSurveyQuestionLevelData?.findIndex((unitData)=>{
      return unitData?.unit_id === parseInt(unitId)
    })
    if (unitIndex > -1) {
      setTabsValue(unitIndex)
    }

    if(allSurveyQuestionLevelData?.length){
      console.log(allSurveyQuestionLevelData[unitIndex])
      setSelectedQuestionsData(allSurveyQuestionLevelData[unitIndex])
    }
    // setTabsValue(unitIndex)
  },[unitId, allSurveyQuestionLevelData])

  const handleSendSurveyUnit = (overall_score, unit_id, unit_name,has_feedback) =>{


    console.log(facultyDetails)

    const newselectedSendSurvey = [{...facultyDetails,overall_score, unit_id, unit_name,has_feedback}]

    dispatch(setSelectedSendSurvey({selectedSendSurvey : newselectedSendSurvey}));

    setOpenSendSurveyModal(true)
    // API to createSubjectFeedback survey 
    // facId subsemId

    // const data = [
    //   {
    //     college_account_id: facId,
    //     subject_semester_id: subsemId,
    //     start_time: "2023-02-17 02:32:07",
    //     doe: "2023-02-18 02:33:20",
    //     unit_id: unit_id,
    //     section_id: 723,
    //   },
    // ];


  }

  const handleSendSurveyModalClose = () => {
    setOpenSendSurveyModal(false);
    dispatch(setSelectedSendSurvey({selectedSendSurvey : []}));
  };


  let tableRow = {};
  allSurveyQuestionLevelData?.forEach(
    ({ overall_score, unit_id, unit_name,has_feedback}) => {
      tableRow[unit_name] = overall_score ? (
        overall_score.toFixed(2)
      ) : (
        <Tooltip title={!has_feedback ? `No Survey Exists. Send Survey.` : `Feeback is sent but no data is available. Send Survey Again?`}>
          <IconButton onClick={() => handleSendSurveyUnit(overall_score, unit_id, unit_name,has_feedback)}>
            <RateReviewIcon />
          </IconButton>
        </Tooltip>
      );

      // <MaterialButton type='secondary' sx={{height : '20px', minWidth : '20px'}} label={'Send'} />;
      // tableRow [unit_name] = overall_score? overall_score.toFixed(2) : overall_score;
    }
  );


  const headArray = allSurveyQuestionLevelData?.map(({unit_name})=>{
      return {
        id: unit_name,
        label: unit_name,
      }
  })

  // console.log(headArray)
  // console.log(tableRow)


    // Table Data
    const tableData =  <Grid item>
    <BasicTable
      data={[tableRow]}
      heading={'Overall Unit Level Surveys'}
      headArray={headArray}
      primaryKey={"Unit I"}
    />
  </Grid>

  const atLeastOneUnitHasScore = allSurveyQuestionLevelData?.some(
    ({ overall_score }) => {
      return overall_score;
    }
  );

  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  return (
    <React.Fragment>
      <Box sx={{padding:'0 0 15px 0', m:0}}>
       <Breadcrumb currentPage={'Unit Level Performance'} links={[{name : "Faculty Performance Review", path : '/facultyPerformanceReview' }]}/>
      </Box>
      <HeadingSecondary title={'Unit Level Performance'}/>
      {/* {allSurveyQuestionLevelData.length >0 && <p> This is unit wise review data {allSurveyQuestionLevelData[0].unit_id}</p>} */}
      
      <Grid
          container
          sx={{
            justfityContent: "center",
            flexDirection: "row",
            alignItems: "center",
            pt: 2,
          }}
        >

        {!atLeastOneUnitHasScore && !tableIsLoading && 
         <Grow in={!atLeastOneUnitHasScore && !tableIsLoading}>
          <Grid item xs={12} sx={{pt:2}}>
            <Alert severity="info">No Units have Survey Submitted by Students. Please send surveys to student and let students submit the surveys to show data here.</Alert>
          </Grid>
        </Grow>
        }

        {!tableIsLoading &&
        <Grid container item sm={12} sx={{justifyContent:'center',py:2}}>
          <Grid item sx={{mr:3}}>
            <Grow in={!tableIsLoading}>
              <div><UnitDetailsCard facultyDetails={facultyDetails}/></div>
            </Grow>
          </Grid>
          <Grid item>
            <Grow in={!tableIsLoading}>
              {tableData}
            </Grow>
          </Grid>
        </Grid>
        } 

      
        {atLeastOneUnitHasScore && !tableIsLoading && 
        <Grid item xs={12}>
          <Box sx={{ minWidth: 0, borderBottom: 1, borderColor: "divider", marginBottom : 3}}>
            <Tabs
              value={tabsValue}
              onChange={handleTabChange}
              aria-label="Units Tabs"
              // indicatorColor ='#ffdfd6'
              centered
            >
              {allSurveyQuestionLevelData?.map(({unit_name, unit_id,overall_score},index)=>(
                overall_score && <Tab key={unit_id} label={unit_name} {...a11yProps(unit_id)} />
              ))}
            </Tabs>
          </Box>
        </Grid>
        }

      
        {atLeastOneUnitHasScore && !tableIsLoading && 
        <Grow in={atLeastOneUnitHasScore && !tableIsLoading}>
          <div>
            <QuestionGraphsList selectedQuestionsData={selectedQuestionsData}/>
          </div>
        </Grow>
        }
      </Grid>

      <SendSurveyModal
        openSendSurveyModal ={openSendSurveyModal}
        setOpenSendSurveyModal = {setOpenSendSurveyModal}
        handleSendSurveyModalClose ={handleSendSurveyModalClose}
        />

    </React.Fragment>
  );
}
