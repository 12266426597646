import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import Button from './Button';
import MaterialButton from './MaterialButton';
import Divider from '@mui/material/Divider';

HeadingWithButtons.defaultProps = {
    cancelLabel : 'Cancel',
    submitLabel : 'Submit',
    onSubmitClick : null,
    onCancelClick : null,
    divider : true,
    title : 'Secondary Heading',
}


export default function HeadingWithButtons ({onCancelClick,onSubmitClick,cancelLabel,submitLabel, divider, title}) {
  return(
    <React.Fragment>
        <Grid sx={{py:1}} container spacing={2} justifyContent="space-between" alignItems="center" >
            <Grid item >
                <Typography align="left" variant="h5" sx={{mb:0}} gutterBottom>
                    {title}
                    {/* <hr className="titlehr" /> */}
                </Typography>
            </Grid>
            <Grid item  >
                <Grid container spacing={2}  alignItems="center">
                    {/* <MaterialButton label={'Add'}/> */}
                    <Grid item >
                        <MaterialButton onClick={onCancelClick} type={'secondary'} label={cancelLabel}/>
                        {/* <Button onClick={onCancelClick} type={'secondary'} name={cancelLabel}/> */}
                    </Grid>
                    <Grid item >
                        <MaterialButton onClick={onSubmitClick} sx={{ml:'5px'}} label={submitLabel}/>
                        {/* <Button onClick={onSubmitClick} style={{paddingLeft:'5px'}} name={submitLabel}/> */}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {divider && <Divider variant="middle" sx={{ml:0, color:'black', opacity:'100%'}}/>}
    </React.Fragment>
  )
}