import React, { useEffect, useState } from "react";
import "./SideNavBar.styles.css";
import { NavLink } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import SimpleModal from "../SimpleModal";
import Roles from "../../../lib/Constants/Roles"
import { useSelector } from "react-redux";

const SideNavBar = ({name ,onClick}) => {
  const [open, setOpen] = useState(false);

  const baseUrlFacultyWeb = useSelector(state => state?.Common?.facultyWebBaseUrl)
  const user =JSON.parse(localStorage.getItem('user'));



  const NavBarList = [
    {
      name: "Dashboard",
      path: `/index.html`,
      codeModule: "web",
    },
    {
      name: "Your Courses",
      path: `/courses.html`,
      codeModule: "web",
    },
    {
      name: "Assessments",
      path: "/myAssessment.html",
      codeModule: "web",
    },
    {
      name: "Assignments",
      path: "/myAssignment.html",
      codeModule: "web",
    },
    {
      name: "Live Assessments",
      path: "/myLiveAssessment.html",
      codeModule: "web",
    },
    {
      name: "Live Survey",
      path: "/myLiveSurvey.html",
      codeModule: "web",
    },
    {
      name: "Live Class",
      path: "/liveClass.html",
      codeModule: "web",
    },
    {
      name: "Notify",
      path: "/notify.html",
      codeModule: "web",
    },
    {
      name: "Feedback",
      path: "/myFeedbacks.html",
      codeModule: "web",
    },
    {
      name: "Arena",
      path: "/weeklyChallenge",
    },
    {
      name: "Faculty-Student Allocation",
      path: "/facultySectionMapping",
      // access : Roles?.hod
    },
    {
      name: "Faculty Performance Review",
      path: "/facultyPerformanceReview",
      access : Roles?.hod
    },
    {
      name: "Faculty Semester Feedback",
      path: "/feedbackHome",
      sub_paths: [
        'feedback','questions', 'details', 'feedbackHome', 'feedbackResults'
      ]
    },
    {
      name: "Coding Assessment",
      path: "/myCodingAssessment.html",
      codeModule: "web",
    },
    {
      name: "CO Mapping",
      path: "/nba.html",
      codeModule: "web",
    },
    {
      name: "My Profile",
      path: "/viewProfile.html",
      codeModule: "web",
    },
    // {
    //   name : "Analytics Dashboard",
    //   path : "/analytics.html",
    // },
    {
      name: "Analytics",
      path: "/analytics/summary.html",
      codeModule: "web",
    },
    {
      name: "Logout",
      path: "/logout.html",
      icon : '/images/up-right-from-square.svg',
      codeModule: "web",
    },
    // {
    //   name : "Analytics",
    //   path : "/analytics/summary.html",
    // },
  ];
  

  const handleModaClose = () => setOpen(false);

  const handleLogout = () =>{
    console.log("handle logout triggered");
    const user = localStorage.getItem('user');
    localStorage.clear();
    localStorage.setItem('user', user);
  }

  const handleCodingAssessmnet = () =>{
    console.log("handle coding assessment triggered");
    setOpen(true)
    // return <SimpleModal open={open}>
    //   We are upgrading this module. We will be back shortly!
    // </SimpleModal>;
  }

  // Writing the logic to get Nav Bar Data 
  const NavBarRender = NavBarList.map((navData,index)=>{

    // Handling Access Restricted Links 
    if(navData?.access && navData?.access === Roles.hod && user?.role_id > 3){
      return null
    }

    if(navData?.access && navData?.access === Roles.management && user?.role_id > 2){
      return null
    }

    // Handling Active Link
    // console.log(window.location.pathname.split('/'))
    // console.log(navData?.path.split('/')[1])
    const activePath =window.location.pathname.split("/");
    const navPanePath = navData?.path.split('/')[1];
    // const sub_paths = navData?.sub_paths;

   // Handling if sub paths are present in the application
   let isPresent = false;
    if (navData?.sub_paths){
      isPresent = activePath.some((elem) => navData?.sub_paths.includes(elem));
    }
    
    // Handling if nested pathing is present in the application
    if(activePath.includes(navPanePath) || isPresent){
     return (
       <div key={index} className="sidebarLinks active">
         <NavLink relative="path" className="sidebarNav" to={navData.path}>
           <div>
             <div>{navData.name}</div>
             <hr className="navtitlehr" />
           </div>
         </NavLink>
       </div>
     );
    }
    
    // Handling Logout Link
    if(navData?.path === "/logout.html"){
      // return <div key={index} className="sidebarLinks"><NavLink onClick={handleLogout} relative="path" className="sidebarNav" to={navData.path}>{navData.name} <LogoutIcon sx={{pl:1}}/></NavLink></div>
      return <div key={index} className="sidebarLinks"><a onClick={handleLogout} className="sidebarNav" href={`${baseUrlFacultyWeb}${navData.path}`}>{navData.name} <LogoutIcon sx={{pl:1}}/></a></div>
    }
    
    if(navData?.path === "/myCodingAssessment.html"){
      return <div key={index} className="sidebarLinks"><NavLink onClick={handleCodingAssessmnet} relative="path" className="sidebarNav">{navData.name}</NavLink></div>
    }

    // Handling Faculty HTML Modules
    if(navData?.codeModule && navData?.codeModule === 'web'){
      return <div key={index} className="sidebarLinks"><a className="sidebarNav" href={`${baseUrlFacultyWeb}${navData.path}`}>{navData.name}</a></div>
    }
    // return !navData.react ? <div key={index} className="sidebarLinks"><a className="sidebarNav" href={navData.path}>{navData.name}</a></div> : 
    // <div className="sidebarLinks"><NavLink key={index} relative="path" className="sidebarNav" to={navData.path}>{navData.name}</NavLink></div>

    return <div key={index} className="sidebarLinks"><NavLink relative="path" className="sidebarNav" to={navData.path}>{navData.name}</NavLink></div>
  })


    return (
      <React.Fragment>
        <div className="navbar-div">
        <div className="sideNav">
          <div className="navContainer">
          {NavBarRender}
          </div>
          {/* <div className="sidebarOtherLinks text-center"><a className="sidebarHelpLink" href="#" id="iosAppLink">Request for IOS App<i className="fas fa-external-link-alt"></i></a></div> */}
          <div className="sidebarOtherLinks text-center"><a className="sidebarHelpLink" target="_blank" href="https://blog.edwisely.com/help/educator">Help Center <FontAwesomeIcon icon={faUpRightFromSquare} /></a> </div>
          {/* <div className="sidebarOtherLinks text-center"><a className="sidebarHelpLink" target="_blank" href="https://blog.edwisely.com/help/educator">Help Center <OutboundOutlinedIcon/></a> </div> */}
        </div>
      </div>

      {/* Coding Module Modal */}
      <SimpleModal open={open} handleModaClose={handleModaClose}>
      We are upgrading this module. We will be back shortly!
      </SimpleModal>
      </React.Fragment>
    );
};

export default SideNavBar;
