import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
// import Chip from '@mui/material/Chip';
import CustomChip from '../common/CustomChip';
import { customBoxShadow } from '../../lib/utils';

export default function UnitDetailsCard({facultyDetails}) {
  // console.log(facultyDetails)

  const {department_name, faculty_name, section_name,subject_name,semester_id } = facultyDetails;
  return (
    <Card sx={{ maxWidth:'100%',m:1, boxShadow: customBoxShadow, borderRadius : '20px',}}>
      <CardContent>
        <Typography variant="h6" component="div">
          Faculty Details
        </Typography>
        <Divider variant="middle" sx={{ml:0, color:'black', opacity:'100%'}}/>

        <Typography component={'div'} sx={{ mt:1.5, fontSize: 14 }} color="text.secondary" gutterBottom>
          {/* Deparment : < CustomChip label={'Department Short Name'}/> */}
          Faculty : < CustomChip label={faculty_name}/>
        </Typography>
        <Typography component={'div'} sx={{ mt:1.5, fontSize: 14 }} color="text.secondary" gutterBottom>
          {/* Faculty Name : < CustomChip label={'Faculty Full Name'}/> */}
          Deparment : < CustomChip label={department_name}/>
        </Typography>
        <Typography component={'div'} sx={{ mt:1.5, fontSize: 14 }} color="text.secondary" gutterBottom>
          {/* Semester : <CustomChip label={'4'}/> */}
          Semester : <CustomChip label={semester_id}/>
        </Typography>
        <Typography component={'div'} sx={{ mt:1.5, fontSize: 14 }} color="text.secondary" gutterBottom>
          Subject : <CustomChip label={subject_name}/>
          {/* Subject : <CustomChip label={'Distributed and Cloud Computing (Lab integrated)'}/> */}
        </Typography>
        <Typography component={'div'} sx={{ mt:1.5, fontSize: 14 }} color="text.secondary" gutterBottom>
          Section : <CustomChip label={section_name}/>
          {/* Section : <CustomChip label={'3 Section T'}/> */}
        </Typography>
      </CardContent>
    </Card>
  );
}
