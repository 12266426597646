import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from '@mui/system/styled';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { customBoxShadow } from "../../lib/utils";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#ffdfd6',
      color: 'black',
    },
    padding: '3px 6px',
    width : 'max-content',
  }));
  

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
// ];

export default function BasicTable({data, headArray, primaryKey, heading}) {


    // Adding Header Array IDs as keys to the Table
  const tableKeys = headArray?.map((head)=>{
    return head.id;
  })
  // console.log(tableKeys)

  const rows = data;

  return (
    <Box
      sx={{
        width: "max-content",
        // width: "100%",
        maxWidth: "1300px",
        m:1,
        borderRadius : '20px',
        boxShadow : customBoxShadow,
      }}
      component={Paper}
    >
      <Toolbar
        sx={{
          minHeight: "25px",
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          borderRadius: "20px 20px 0 0",
        }}
      >
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
          align="center"
        >
          {heading}
        </Typography>
      </Toolbar>
      <TableContainer >
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell>Dessert (100g serving)</TableCell>
            <TableCell align="right">Calories</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
              {headArray.map((headCell) => (
                <StyledTableCell key={headCell.id} align="center">
                  {headCell.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row,index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {/* <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}

                <StyledTableCell component="th" scope="row" align="center">
                {row[tableKeys[0]]? row[tableKeys[0]] : <i style={{color : 'grey'}}>NA</i>}
                </StyledTableCell>
                {/* All Elements except first element in array using slice method */}
                {tableKeys.slice(1).map((key, index) => (
                  <StyledTableCell key={index} align="center">
                    {row[key] ? row[key] : <i style={{color : 'grey'}}>NA</i>}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
