import React, {useState, useEffect} from 'react';
import DropDown from "../common/DropDown";
import MultipleSelectCheckmarks from '../common/MultipleSelectCheckmarks';
// import Button from '@mui/material/Button';
import Button from '../common/Button';
import { useDispatch, useSelector } from 'react-redux';
import {  setFilteredSubjects,setFilteredSections, setFilteredFacPerRevData } from '../../store/reducers/facultyPerformanceReview.reducer';
// import Box  from '@mui/material/Box';
import Grid  from '@mui/material/Grid';
// import Paper from "@mui/material/Paper";
import SearchMultiSelect from '../common/SearchMultiSelect';
import { getAllSubjects, getAllSections } from '../../lib/utils';


const SearchComponent = ({tabsValue}) => {
  const [showResetButton, setShowResetButton] = useState(false);
  const [deparmentId, setDepartmentId] = useState('');
  const [selectedSemesters, setSelectedSemesters] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  const {
    filteredDepartments,
    filteredSemesters,
    filteredSubjects,
    filteredSections,
    filteredFaculty,
    filteredAllFacPerRevData,
    allFacPerRevData,
  } = useSelector((state) => state.FacultyPerformanceReview);
  // const {filteredSections} = useSelector((state) => state.FacultyPerformanceReview)
  
  const dispatch = useDispatch();
  
  // Retriving role as HOD or below needs to be shown only the dept.
  // const isManagement = user?.role_id <=2 ; 
  // console.log(isManagement)
  // // console.log(role)

  // Reset Button To be shown or not based on the filtering status.
  useEffect(() => {
    filteredAllFacPerRevData?.length === allFacPerRevData?.length
      ? setShowResetButton(false)
      : setShowResetButton(true);
  }, [filteredAllFacPerRevData, allFacPerRevData]);

  // Handling Tab Swtich to Reset the Search Filters
  useEffect(()=>{
    resetFilterOnTabSwitch();
  },[tabsValue])

    // Handling Dynamic Subject Filter Based on Departments and Semensters
    useEffect(()=>{

      const filteredSubjects = getAllSubjects(allFacPerRevData,deparmentId,selectedSemesters);
      const filteredSections = getAllSections(allFacPerRevData,deparmentId,selectedSemesters);
      // console.log(filteredSubjects)
      // console.log(filteredSections)
      dispatch(setFilteredSubjects({filteredSubjects : filteredSubjects}))
      dispatch(setFilteredSections({filteredSections : filteredSections}))
  
    },[deparmentId,selectedSemesters])

  // // When allFacPerRevData changes, we need to filter data again
  useEffect(()=>{
    dispatch(setFilteredFacPerRevData({deparmentId,selectedSemesters,selectedSubjects}));
  },[allFacPerRevData])


  // Handling Buttons and DropDowns
  const handleDepartmentDropDown = (event) => {
    console.log(event.target.value);
    const deptId = event.target.value ? event.target.value : '';

    setDepartmentId(deptId);
  };

  const handleSemesterDropDown = (selectedValues) => {
    const semIds = selectedValues ? selectedValues : [];
    console.log(semIds);
    setSelectedSemesters(semIds);
  };

  const handleSubjectDropDown = (selectedValues) => {
    const subjecIds = selectedValues ? selectedValues : [];
    // console.log(subjecIds);
    setSelectedSubjects(subjecIds);
  };

  const handleSectionDropDown = (selectedValues) => {
    const sectionNames = selectedValues ? selectedValues : [];
    // console.log(subjecIds);
    setSelectedSections(sectionNames);
  };

  const handleFacultyDropDown = (selectedValues) => {
    const facultyIds = selectedValues ? selectedValues : [];
    // console.log(subjecIds);
    setSelectedFaculty(facultyIds);
  };

  const handleApplyOnClick = () =>{
    console.log(`deparmentId in Apply : ${deparmentId}`)
    console.log(`selectedSemesters in Apply : ${selectedSemesters}`)
    console.log(`selectedSubjects in Apply : ${selectedSubjects}`)
    console.log(`selectedSections in Apply : ${selectedSections}`)
    console.log(`selectedFaculty in Apply : ${selectedFaculty}`)
    if(tabsValue ===0){
      dispatch(setFilteredFacPerRevData({deparmentId,selectedSemesters,selectedSubjects}));
      return console.log('Apply Clicked')
    }
    if(tabsValue ===1){
      dispatch(setFilteredFacPerRevData({deparmentId,selectedSemesters,selectedSections}));
      return console.log('Apply Clicked')
    }
    if(tabsValue ===2){
      dispatch(setFilteredFacPerRevData({deparmentId,selectedSemesters,selectedFaculty}));
      return console.log('Apply Clicked')
    }
  }

  const resetFilterOnTabSwitch = ()=>{
    setSelectedSubjects([])
    setSelectedSections([])
    setSelectedFaculty([])
    dispatch(
      setFilteredFacPerRevData({
        selectedSubjects: [],
        selectedSections: [],
        selectedFaculty: [],
      })
    );
  }

  const handleReset = ()=>{
    setDepartmentId('');
    setSelectedSemesters([])
    setSelectedSubjects([])
    setSelectedSections([])
    setSelectedFaculty([])
    dispatch(
      setFilteredFacPerRevData({
        deparmentId: "",
        selectedSemesters: [],
        selectedSubjects: [],
        selectedSections: [],
        selectedFaculty: [],
      })
    );
  }

  return (
    <React.Fragment>
      {/* <Box display={'flex'} padding={'10px'} alignItems="center" flexDirection={'row'} > */}
      {/* <Paper sx={{margin:'5px'}} > */}
      <Grid container  padding='10px' alignItems="center"  spacing={1}>

      <Grid item>
      <DropDown
        data={
          filteredDepartments
        }
        onChange={handleDepartmentDropDown}
        label={"Departments"}
        nullIsAll = {true}
        dropDownValue ={deparmentId}
        setDropDownValue ={setDepartmentId}
        // disabled={!isManagement}
      />
      </Grid>

      <Grid item>
      <MultipleSelectCheckmarks
        data={filteredSemesters}
        onChange={handleSemesterDropDown}
        label={"Semester"}
        selectedValues ={selectedSemesters}
        setSelectedValues={setSelectedSemesters}
      />
      </Grid>

      {/* {tabsValue === 0 && <Grid item>
      <SubjectMultipleSelectCheckmarks
        data={filteredSubjects}
        onChange={handleSubjectDropDown}
        label={"Subject"}
        selectedValues ={selectedSubjects}
        setSelectedValues={setSelectedSubjects}
      />
      </Grid>} */}

      {tabsValue === 0 && <Grid item>
      <SearchMultiSelect
      label={"Subject"}
      data={filteredSubjects}
      selectedValues = {selectedSubjects}
      setSelectedValues ={setSelectedSubjects}
      onChange={handleSubjectDropDown}
      />
      </Grid>}

      {tabsValue === 1 && <Grid item>
      <SearchMultiSelect
      label={"Section"}
      data={filteredSections}
      selectedValues = {selectedSections}
      setSelectedValues ={setSelectedSections}
      onChange={handleSectionDropDown}
      />
      </Grid>}

      {tabsValue === 2 && <Grid item>
      <SearchMultiSelect
      label={"Faculty"}
      data={filteredFaculty}
      selectedValues = {selectedFaculty}
      setSelectedValues ={setSelectedFaculty}
      onChange={handleFacultyDropDown}
      multipleSeletedLabel ="Faculties Selected"
      />
      </Grid>}

      <Grid item>
      <Button onClick={handleApplyOnClick} name='Apply'/>
      </Grid>

      {showResetButton &&<Grid item>
      <Button style={{marginLeft : '10px'}} onClick={handleReset} name='Reset' type={'secondary'}>
        Reset
      </Button>
      </Grid>
      }
      
      {/* </Box> */}
      </Grid>
      {/* </Paper> */}
      </React.Fragment>

  );
}

export default SearchComponent;