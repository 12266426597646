import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Paper from "@mui/material/Paper";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

SearchMultiSelect.defaultProps = {
  data: [],
  label: "No Label",
  selectedValues: [],
  setSelectedValues: null,
  multipleSeletedLable : null,
};

export default function SearchMultiSelect({
  data,
  label,
  selectedValues,
  setSelectedValues,
  onChange,
  multipleSeletedLabel
}) {
  // const [inputValue, setInputValue] = React.useState("");

  const multipleSeletedLabelUsed = multipleSeletedLabel? multipleSeletedLabel : `${label}s Selected`;

  const subjectSelectedLabel = selectedValues?.length > 1  ? multipleSeletedLabelUsed : selectedValues?.length > 0 ? `${label} Selected` :  `${label}` ;
  // const subjectSelectedLabel = selectedValues?.length < 1 ? null : selectedValues?.length > 1 ? 'Subjects Selected' : 'Subject Selected' ;

  const handleChange = (event, value) => {
    console.log(value);
    // setSelectedValues(setSelectedValues);
    onChange(value)
  };

  return (
    <Autocomplete
      multiple
      id="checkboxes-autocomplete"
      options={data}
      disableCloseOnSelect
      onChange={handleChange}
      value={selectedValues}
    //   inputValue={inputValue}
    //   onInputChange={(event, newInputValue) => {
    //     setInputValue(newInputValue);
    //   }}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue = {(option,value)=> option.value === value.value}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      sx={{ minWidth: selectedValues?.length > 0 ? 200 : 150, m: 1 }}
      // renderInput={({...params}) => (
      renderInput={({InputProps, ...params}) => (
        <React.Fragment>
          {/* {console.log(InputProps)} */}
        
        <TextField {...params} InputProps={{...InputProps,startAdornment: null}} label={subjectSelectedLabel} placeholder={subjectSelectedLabel} />
        {/* <TextField {...params} InputProps={{...InputProps,startAdornment: subjectSelectedLabel}} label={'Subject'} placeholder={'Subject'} /> */}
        </React.Fragment>
        // <TextField {...params} label="Checkboxes" placeholder="Favorites" />
      )}
      size="small"
      PaperComponent={(props) => (
        <Paper {...props} sx={{ width: "max-content" }} />
      )}
    />
  );
}