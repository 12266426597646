import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaLongArrowAltRight } from "react-icons/fa";
import { getWeeklyChallengeData } from "../../api/weeklyChallengeAPIs";
import "./weeklyChallengeCard.styles.css";
import { openNewAblyConnection } from "../../store/actions/joinchallengeactions";

const UserWeeklyChallengeCard = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleOpenChallenge = async () => {
        const challenge = await getWeeklyChallengeData(props.challengeDetails.id);
        console.log(challenge.data);
        if (challenge.data.participation_type === 1) {
            if (challenge.data.is_completed === 0) {
                if (props.challengeDetails.is_admin === 1 || props.challengeDetails.is_coordinator === 1) {
                    if(challenge.data.random_questions === 0){
                        if (challenge.data.resume_test === 0 && (challenge.data.is_teams_created === 0 || challenge.data.is_teams_created === 1) && challenge.data.questions.length === 0) {
                            navigate('/challengeLobby', { state: { challenge_id: props.challengeDetails.id, challengeDetails: props.challengeDetails } });
                        } else {
                            //dispatch({ type: 'openAblyCon', challenge_id: props.challengeDetails.id, pChannelId: challenge.data.participation_channel_id, vChannelId: challenge.data.viewer_channel_id });
                            dispatch(openNewAblyConnection({ challenge_id: props.challengeDetails.id, pChannelId: challenge.data.participation_channel_id, vChannelId: challenge.data.viewer_channel_id }));
                            navigate('/startChallenge', { state: { challenge_id: props.challengeDetails.id } });
                        }
                    }else{
                        if (challenge.data.resume_test === 0 && challenge.data.is_teams_created === 0) {
                            navigate('/challengeLobby', { state: { challenge_id: props.challengeDetails.id, challengeDetails: props.challengeDetails } });
                        } else {
                            //dispatch({ type: 'openAblyCon', challenge_id: props.challengeDetails.id, pChannelId: challenge.data.participation_channel_id, vChannelId: challenge.data.viewer_channel_id });
                            dispatch(openNewAblyConnection({ challenge_id: props.challengeDetails.id, pChannelId: challenge.data.participation_channel_id, vChannelId: challenge.data.viewer_channel_id }));
                            navigate('/startChallenge', { state: { challenge_id: props.challengeDetails.id } });
                        }
                    }
                } else {
                    navigate('/userChallengeLobby', { state: { challenge_id: props.challengeDetails.id, challengeDetails: props.challengeDetails } });
                }
            } else {
                if (props.challengeDetails.is_admin === 1 || props.challengeDetails.is_coordinator === 1) {
                    navigate('/challengeLobby', { state: { challenge_id: props.challengeDetails.id, challengeDetails: props.challengeDetails } });
                } else {
                    navigate('/userChallengeLobby', { state: { challenge_id: props.challengeDetails.id, challengeDetails: props.challengeDetails } });
                }
            }
        } else {
            if (challenge.data.is_completed === 0) {
                if (props.challengeDetails.is_admin === 1 || props.challengeDetails.is_coordinator === 1) {
                    if (challenge.data.resume_test === 0 && challenge.data.last_submitted_question === "") {
                        navigate('/challengeLobby', { state: { challenge_id: props.challengeDetails.id, challengeDetails: props.challengeDetails } });
                    } else {
                        //dispatch({ type: 'openAblyCon', challenge_id: props.challengeDetails.id, pChannelId: challenge.data.participation_channel_id, vChannelId: challenge.data.viewer_channel_id });
                        dispatch(openNewAblyConnection({ challenge_id: props.challengeDetails.id, pChannelId: challenge.data.participation_channel_id, vChannelId: challenge.data.viewer_channel_id }));
                        navigate('/startChallenge', { state: { challenge_id: props.challengeDetails.id } });
                    }
                } else {
                    navigate('/userChallengeLobby', { state: { challenge_id: props.challengeDetails.id, challengeDetails: props.challengeDetails } });
                }
            } else {
                if (props.challengeDetails.is_admin === 1 || props.challengeDetails.is_coordinator === 1) {
                    navigate('/challengeLobby', { state: { challenge_id: props.challengeDetails.id, challengeDetails: props.challengeDetails } });
                } else {
                    navigate('/userChallengeLobby', { state: { challenge_id: props.challengeDetails.id, challengeDetails: props.challengeDetails } });
                }
            }
        }


    };

    const downloadReportsAsPdf = () => {
        navigate("/reports/inplaceReports", {state: {test_id: props.challengeDetails.id, download_type: 'pdf', report_type: 'wcr'}});
    };

    const downloadReportsAsExcel = () => {
        navigate("/reports/inplaceReports", {state: {test_id: props.challengeDetails.id, download_type: 'excel', report_type: 'wcr'}});
    };
    return (
        <div id="challenge-card">
            <div className="row challenge-inner-card shadow">
                <div className="col-lg-2 col-md-2 col-sm-2 d-felx justify-content-center text-center">{props.challengeDetails.start_time}</div>
                <div className="col-lg-3 col-md-3 col-sm-3 d-felx justify-content-center text-center">{props.challengeDetails.title}</div>
                <div className="col-lg-3 col-md-3 col-sm-3 d-felx justify-content-center text-center">{props.challengeDetails.description}</div>
                <div className="col-lg-1 col-md-1 col-sm-1 d-felx justify-content-center text-center">
                    {
                        new Date(props.challengeDetails.enrollment_end_time).getTime() > new Date().getTime() && props.challengeDetails.is_completed === 0 && props.challengeDetails.is_admin === 1
                            ? (<button type="button" className="btn" id="edit-challenge-button" onClick={() => navigate('/editWeeklyChallenge', { state: { challenge_id: props.challengeDetails.id } })}>Edit</button>)
                            : ''
                    }
                </div>
                <div className="col-lg-1 col-md-1 col-sm-1 d-felx justify-content-center text-center">
                    {
                        props.challengeDetails.is_completed === 1
                            ? (
                                <div style={{display:"flex",justifyContent:"space-between"}}>
                                    <img onClick={downloadReportsAsPdf} class='rounded ml-2 downloadResults downloadMcqResultsAsPdf' src="/images/pdf_icon.png" width="30" height="30" data-toggle='tooltip' data-placement='top' title='Download Result as Pdf' />
                                    <img onClick={downloadReportsAsExcel} class='rounded ml-2 downloadResults downloadMcqResultsAsExcel' src="/images/excel_icon.png" width="30" height="30" data-toggle='tooltip' data-placement='top' title='Download Result as Excel' />
                                </div>
                            )
                            : ""
                    }
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 d-felx justify-content-center text-center">
                    {
                        props.challengeDetails.is_admin === 1
                            ? (<span id="challenge-user-type">Admin</span>)
                            : props.challengeDetails.is_coordinator === 1
                                ? (<span id="challenge-user-type">Coordinator</span>)
                                : props.challengeDetails.is_participant === 1
                                    ? (<span id="challenge-user-type">Participant</span>)
                                    : props.challengeDetails.is_member === 1
                                        ? (<span id="challenge-user-type">Participant</span>)
                                        : (<span id="challenge-user-type">Viewer</span>)
                    }
                    {
                        new Date(props.challengeDetails.start_time).getTime() > new Date().getTime() && props.challengeDetails.is_completed === 0
                            ? (<button type="button" className="btn btn-primary shadow" id="openChallengeBtn" onClick={handleOpenChallenge}>
                                {
                                    props.challengeDetails.is_enrolled === 1 ? "Join" : "Open"
                                } 
                                <FaLongArrowAltRight className="right-arrow" /></button>)
                            : new Date(new Date().getTime() - new Date(props.challengeDetails.start_time).getTime()).getHours() - 5 >= 6 && props.challengeDetails.is_completed === 0
                                ? (<button type="button" className="btn btn-primary shadow" id="openChallengeBtn" onClick={handleOpenChallenge}>
                                    {
                                        props.challengeDetails.is_enrolled === 1 ? "Join" : "Open"
                                    } 
                                    <FaLongArrowAltRight className="right-arrow" /></button>)
                                : props.challengeDetails.is_completed === 0
                                    ? props.challengeDetails.is_admin === 1
                                        ? (<button type="button" className="btn btn-primary shadow" id="openChallengeBtn" onClick={handleOpenChallenge}>Start <FaLongArrowAltRight className="right-arrow" /></button>)
                                        : (<button type="button" className="btn btn-primary shadow" id="openChallengeBtn" onClick={handleOpenChallenge}>Join <FaLongArrowAltRight className="right-arrow" /></button>)
                                    : (<button type="button" className="btn btn-primary shadow" id="openChallengeBtn" onClick={handleOpenChallenge}>Leaderboard <FaLongArrowAltRight className="right-arrow" /></button>)
                    }
                </div>
            </div>
        </div>
    );
}

export default UserWeeklyChallengeCard;