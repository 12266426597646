import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaLongArrowAltRight } from "react-icons/fa";
import { RiTeamLine } from "react-icons/ri";
import { Buffer } from "buffer";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Navbar from "../components/navbar/navbar.component";
import LeaderBoard from "../components/leaderBoard/leaderBoard.component";
import { getAllTeams, setAllViewersChat } from "../api/weeklyChallengeAPIs";
import { messages } from "../components/chatwindow/viewerChatroom";
import { firebase_db } from "../components/chatwindow/viewerChatroom";
import "./finalLeaderBoardPage.css";
import { closePAblyConnection } from "../store/reducers/joinChallenge.reducer";

const FinalLeaderBoardPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const realtimePChannel = useSelector(state => state.joinChallenge.realtimePChannel);
    const channel = useSelector(state => state.joinChallenge.participantChannel);
    const viewer_channel = useSelector(state => state.joinChallenge.viewerChannel);
    const [showChallengeCloseAlertModal, setShowChallengeCloseAlertModal] = useState(false);
    const [showOtherTeamsModal, setShowOtherTeamsModal] = useState(false);
    const [allTeams, setAllTeams] = useState([]);
    console.log(location.state.leaderBoard_data)
    const handleHomeButton = () => {
        setShowChallengeCloseAlertModal(true);
    };

    const handleChallengeCloseAlertModalClose = () => setShowChallengeCloseAlertModal(false);
    const handleGoHome = async () => {
        if (channel) {
            channel.detach();
            //dispatch({ type: 'closePAblyCon', challenge_id: location.state.challenge_details.id });
            dispatch(closePAblyConnection({ challenge_id: location.state.challenge_details.id }));
            realtimePChannel.close();
        }
        //setting viewer chat msg
        let chatmessages = JSON.stringify(messages);
        let chatmessagesJsonB64 = Buffer.from(chatmessages).toString("base64");
        const insertMsgs = await setAllViewersChat(location.state.challenge_details.id, chatmessagesJsonB64);
        console.log("viewer chat set");
        firebase_db.ref(`${location.state.challenge_details.id}_Viewer`).remove();
        navigate('/weeklyChallenge');
    };
    console.log(location.state.challenge_details);

    const handleShowOtherTeamsModalOpen = async () => {
        setShowOtherTeamsModal(true);
        const allteams = await getAllTeams(location.state.challenge_details.id);
        setAllTeams(allteams.data);
    }
    const handleShowOtherTeamsModalClose = () => setShowOtherTeamsModal(false);

    console.log(location.state.current_question_id);

    return (
        <div className="weekly-challenge" style={{ overflowX: "hidden" }}>
            <Navbar />
            <div className="row" style={{ marginTop: '70px', backgroundColor: '#f9f9f9', backgroundImage: `url('/images/animation_final_leaderboard.gif')` }}>

                <Modal show={showOtherTeamsModal} onHide={handleShowOtherTeamsModalClose} id="otherTeamsModal" dialogClassName="otherTeamsModalWidth">
                    <Modal.Header closeButton>
                        <Modal.Title>Teams Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {
                                allTeams.length !== 0
                                    ? allTeams.map(team => (
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <table className="table table-bordered table-curved" id="teams-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" colSpan="3">
                                                            {team.team_name}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Team Member Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        team.members.length > 0
                                                            ? team.members.map(teamMember => (
                                                                <tr>
                                                                    <td scope="row">
                                                                        {
                                                                            teamMember.faculty_id != ''
                                                                                ? teamMember.is_captain
                                                                                    ? teamMember.faculty_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.faculty_name + " (VC)"
                                                                                        : teamMember.faculty_name
                                                                                : teamMember.is_captain
                                                                                    ? teamMember.student_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.student_name + " (VC)"
                                                                                        : teamMember.student_name
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            : (
                                                                <tr>
                                                                    <td colSpan="3">
                                                                        <span colSpan="3" className="d-flex justify-content-center" id="no-coordinators-msg-label">This Team has no members!</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    ))
                                    : (<p style={{ textAlign: 'center' }}>There are no teams to show!</p>)
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary shadow-sm" onClick={handleShowOtherTeamsModalClose} id="closeErrorAlertModalBtn" >
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="row w-100">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <Modal show={showChallengeCloseAlertModal} onHide={handleChallengeCloseAlertModalClose} scrollable id="challengeCloseAlertModal" dialogClassName="challengeCustomAlertmodalWidth">
                            <Modal.Body>
                                <div>
                                    <img src="/images/thankyou-animation.gif" className="thankyou-animation" />
                                    <span className="d-flex justify-content-center" id="challenge-custom-msg-label"><strong>Arena Completed Successfully. Thanks for conducting!</strong></span>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="btn btn-primary" onClick={handleGoHome} id="modalAdminHomeBtn">
                                    Continue
                                </Button>
                            </Modal.Footer>
                        </Modal>


                        <div className="final-leaderboard-div">
                            <div className="leaderboard-details" style={{ height: '500px', overflowY: 'auto', overflowX: 'hidden' }}>
                                <button className="btn btn-primary" id="go-home-btn" onClick={handleHomeButton}>Home <FaLongArrowAltRight className="right-arrow" /></button>
                                <h2 style={{ textAlign: 'center', color: 'red' }}>Final LeaderBoard</h2>
                                <div style={{ height: "450px", overflowY: "auto", overflowX: "hidden" }}>
                                    {
                                        location.state.leaderBoard_data.message !== `No data found for this Weekly Challenge: ${location.state.challenge_details.id}`
                                            ? (
                                                <LeaderBoard data={location.state.leaderBoard_data} question_index={location.state.question_index} myTeam_id="" user_type="admin" type="running" current_question_id={location.state.current_question_id} challenge_details={location.state.challenge_details} />
                                            )
                                            : (
                                                <div>
                                                    <h4 style={{ marginTop: '2.5%', textAlign: 'center' }}>LeaderBoard data is not found!</h4>
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                            {
                                location.state.challenge_details.participation_type === 1
                                    ? (
                                        <div className="row" style={{ marginTop: "-50px" }}>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div style={{ marginLeft: "25px" }}>
                                                    <RiTeamLine className="teams-icon" onClick={handleShowOtherTeamsModalOpen} style={{ cursor: 'pointer' }} /><br />
                                                    <span>Other Teams</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : ('')
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default FinalLeaderBoardPage;