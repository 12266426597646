import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FacultyPerformanceTable from './FacultyPerformanceTable';
import Box from '@mui/material/Box';
import SearchComponent from './SearchComponent';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import NoDataFound from '../common/NoDataFound';
import InComponentLoader from '../common/InComponentLoader';
import Fade from '@mui/material/Fade';
import { setTableIsLoading } from '../../store/reducers/facultyPerformanceReview.reducer';
import { setNotification } from '../../store/reducers/common.reducer';
import { getFacultyPerformanceReviews } from '../../store/actions/facultyPerformanceReview.actions';
import HeadingPrimary from '../common/HeadingPrimary';
import MaterialButton from '../common/MaterialButton'; 
import SendSurveyModal from './SendSurveyModal';

// Tabs Functionality
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


// -------- Component

const FacPerRevPage = () => {
    const {filteredAllFacPerRevData,tableIsLoading, selectedSendSurvey} = useSelector((state) => state.FacultyPerformanceReview);
    const [tabsValue, setTabsValue] = useState(0);
    const [sendSurveyMode, setSendSurveyMode] = useState(false);
    const [openSendSurveyModal, setOpenSendSurveyModal] = useState(false);


    const dispatch = useDispatch();

    // const {loadingFirstTime,setLoadingFirstTime} = useState(true);
    // console.log(filteredAllFacPerRevData)

  // Getting Faculty Performance Data
  useEffect(() => {
    async function fetchData() {
      dispatch(setTableIsLoading(true));
      const facultyPerformanceResponse = await dispatch(getFacultyPerformanceReviews());
      dispatch(setTableIsLoading(false));
      // console.log(facultyPerformanceResponse);
      if (facultyPerformanceResponse?.data?.status  !== 200) {
        dispatch( setNotification({ 
          openNotification: true,
          notificationMessage: `Error : ${facultyPerformanceResponse?.data?.message}, Retriving Faculty Performance Review Data Failed`,
          notificationType: "error",
        }));

        return ;
      }
    }
    try {
      fetchData();
    } catch (error) {
      return error;
    }
  }, [dispatch]);


  //  If selectedSendSurvey has length > 0 we need to on the sendSurveyMode to true, or else selected rows will not be shown.
  useEffect(()=>{
    if(selectedSendSurvey?.length > 0){
      setSendSurveyMode(true);
    }
    else{
      setSendSurveyMode(false);
    }
  },[])


    if (!filteredAllFacPerRevData) {
      return;  
    }

    // console.log(`tableIsLoading : ${tableIsLoading}`)
  const headArraySubject = [
    {
      id: 'department_name',
      label: 'Department',
    },
    {
      id: 'semester_id',
      label: 'Semester',
    },
    {
      id: 'subject_name',
      label: 'Subject',
    },
    {
      id: 'section_name',
      label: 'Section',
    },
    {
      id: 'faculty_name',
      label: 'Faculty',
    },
    {
      id: 'feedback_overall_score',
      label: 'Overall Score',
    },
    {
      id: 'feedbacks',
      label: 'Units',
    },
  ];


  const headArraySection = [
    {
      id: 'department_name',
      label: 'Department',
    },
    {
      id: 'semester_id',
      label: 'Semester',
    },
    {
      id: 'section_name',
      label: 'Section',
    },
    {
      id: 'subject_name',
      label: 'Subject',
    },
    {
      id: 'faculty_name',
      label: 'Faculty',
    },
    {
      id: 'feedback_overall_score',
      label: 'Overall Score',
    },
    {
      id: 'feedbacks',
      label: 'Units',
    },
  ];

  const headArrayFaculty = [
    {
      id: 'department_name',
      label: 'Department',
    },
    {
      id: 'faculty_name',
      label: 'Faculty',
    },
    {
      id: 'semester_id',
      label: 'Semester',
    },
    {
      id: 'subject_name',
      label: 'Subject',
    },
    {
      id: 'section_name',
      label: 'Section',
    },
    {
      id: 'feedback_overall_score',
      label: 'Overall Score',
    },
    {
      id: 'feedbacks',
      label: 'Units',
    },
  ];

  // Assig
  let headArray;
  let heading;
  // console.log(tabsValue)
  switch (tabsValue) {
    case 0:
      headArray = headArraySubject;
      heading = "Subject Wise Faculty Performance";
      break;
    case 1:
      headArray = headArraySection;
      heading = "Section Wise Faculty Performance";
      break;
    case 2:
      headArray = headArrayFaculty;
      heading = "Faculty Performance";
      break;

    default:
      headArray = headArraySubject;
      heading = "Subject Wise Faculty Performance";
      break;
  }


  // Table Data
  const tableData = filteredAllFacPerRevData?.length ? (
    <Grid item xs={12}>
      <FacultyPerformanceTable
        data={filteredAllFacPerRevData}
        heading={heading}
        headArray={headArray}
        // primaryKey={"unique_id"}
        primaryKey={"college_account_subject_college_department_section"}
        sendSurveyMode = {sendSurveyMode}
        setSendSurveyMode = {setSendSurveyMode}
        setOpenSendSurveyModal = {setOpenSendSurveyModal}
      />
    </Grid>
  ) : <React.Fragment/>;

  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  const handleCreateSurvey = () => {
    setSendSurveyMode(true);
  };

  const handleSendSurveyModalClose = () => {
    setOpenSendSurveyModal(false);
  };

    return (
      <React.Fragment>
        <HeadingPrimary title={'Faculty Performance Review'}/>
        <Grid container sx={{ minWidth: 0, borderBottom: 1, borderColor: "divider", alignItems : 'center', justifyContent:'space-between' }}>
          <Grid item>

          <Tabs
            value={tabsValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            // indicatorColor ='#ffdfd6'
            >
            <Tab label="Subject View" {...a11yProps(0)} />
            <Tab label="Section View" {...a11yProps(1)} />
            <Tab label="Faculty View" {...a11yProps(2)} />
          </Tabs>
          </Grid>
          <Grid item>
          {/* {!sendSurveyMode && <MaterialButton label={'Send Satisfaction Survey'} onClick={handleCreateSurvey}/>} */}
          </Grid>

        </Grid>

        <Grid
          container
          sx={{
            display: "flex",
            justfityContent: "center",
            flexDirection: "column",
            alignItems: "center",
            pt: 2,
          }}
        >
          <Grid item xs={12}>
            <SearchComponent tabsValue={tabsValue}/>{" "}
          </Grid>

          {/* {allFacPerRevData?.length !== 0 && (
                tableData
              )} */}

          {tableIsLoading && (
            <Grid item pt={"100px"}  xs={12}>
              <InComponentLoader />
            </Grid>
          )}

          {!tableIsLoading && filteredAllFacPerRevData?.length !== 0 && (
              <Fade in={!tableIsLoading}>
                {tableData}
              </Fade>
          )}
          {/* <TabPanel value={tabsValue} index={0}>
            {tableData}
          </TabPanel> */}

          {!tableIsLoading && filteredAllFacPerRevData?.length === 0 && (
            <Grid item  xs={12}>
              <NoDataFound message="No Data Found, Please Re-Apply Filter" />
              {/* No Data Found */}
            </Grid>
          )}
          
        </Grid>
        <SendSurveyModal
        openSendSurveyModal ={openSendSurveyModal}
        setOpenSendSurveyModal = {setOpenSendSurveyModal}
        handleSendSurveyModalClose ={handleSendSurveyModalClose}
        setSendSurveyMode = {setSendSurveyMode}
        />
      </React.Fragment>
    );

}

export default FacPerRevPage;

