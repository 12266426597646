import React from "react";

import "./navbar.styles.css"

const Navbar = () => {
    const user_details = localStorage.getItem("profile_details");
    if (user_details) {
        return (
          // <nav className="navbar navbar-fixed-top" id="navbar">
          //     <div className="row w-100">
          //         <div className="col-lg-9 col-md-7 col-sm-7 d-flex align-items-center">
          //             <a href="some.html" className="ml-4" style={{textDecoration: 'none',overflow: 'hidden'}}>
          //                 <img width="200" height="100" src="images/nav_logo.jpeg" className="nav-logo" alt="nav-logo"/>
          //                 <div className="nav-text">Powered by Edwisely</div>
          //             </a>
          //             <div className="text-center text-white pl-3" id="greetingNav">Mr. Anil</div>
          //         </div>
          //         <div className="col-lg-3 col-md-5 col-sm-5 px-0 d-flex align-items-center justify-content-center">
          //             <h6 className="text-white m-0" id="collegeName">R.M.K College of Engineering and Technology </h6>
          //         </div>
          //     </div>
          // </nav>
          <nav className="navbar navbar-fixed-top" id="navbar">
            <div className="d-flex align-items-center">
              <a
                href="index.html"
                className="ml-1"
                style={{
                  textDecoration: "none",
                  overflow: "hidden",
                  width: "max-content",
                }}
              >
                <img
                  width="190"
                  height="80"
                  src="/images/rmk-nextgen-nav-logo.jpg"
                  className="nav-logo"
                />
                <div className="nav-text">Powered by Edwisely</div>
              </a>
              <p id="greetingsNav">
                <span style={{ color: "grey", fontSize: "0.9em" }}>Hello,</span>
                <br />
                <span id="greetingsNavName">
                  {JSON.parse(user_details)
                    ? JSON.parse(user_details).first_name +
                      " " +
                      JSON.parse(user_details).last_name
                    : "User"}
                </span>
              </p>
            </div>
          </nav>
        );
    }
}

export default Navbar;