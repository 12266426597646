import {configureStore } from "@reduxjs/toolkit" ;
import joinChallengeReducer from "./reducers/joinChallenge.reducer";
import facultySectionMappingReducer from "./reducers/facultySectionMapping.reducer";
import facultyPerformanceReviewReducer from "./reducers/facultyPerformanceReview.reducer";
import commonReducer from "./reducers/common.reducer";
// import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web


const rootReducer = {
    joinChallenge : joinChallengeReducer,
    SectionMapping : facultySectionMappingReducer,
    Common : commonReducer,
    FacultyPerformanceReview : facultyPerformanceReviewReducer,
} ;


// const persistConfig = {
//     key: "root",
//     storage,
//   };

// const persistedReducer = persistReducer(persistConfig, rootReducer);


let store = configureStore({reducer : rootReducer});


export default store ;