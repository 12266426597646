const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const getAccessToken = () => localStorage.getItem("token");
const accessToken = getAccessToken();
let user_details;
let current_user;
if (accessToken) {
  console.log(accessToken  && accessToken !== 'null');
  user_details = atob(accessToken.split('.')[1]);
  user_details = JSON.parse(user_details);
  current_user = user_details.user_id;
}
export const current_user_id = current_user;

export const getDepartments = async () => {
  let endpoint = `${BASE_API_URL}/weekly_challenge/getDepartments`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const getSemesters = async (college_university_degree_department_id) => {
  let endpoint = `${BASE_API_URL}/weekly_challenge/getSemesters?college_university_degree_department_id=${college_university_degree_department_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const getAllWeeklyChallenges = async () => {
  let endpoint = `${BASE_API_URL}/weekly_challenge/getAllWeeklyChallenges`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};


export const getFaculty = async (college_university_degree_department_id) => {
  const endpoint = `${BASE_API_URL}/weekly_challenge/getFaculty?college_university_degree_department_id=${college_university_degree_department_id}`
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};


export const addNewCoordinator = async (faculty_list) => {
  const formData = new FormData();
  formData.append("college_account_id", JSON.stringify(faculty_list));

  let endpoint = `${BASE_API_URL}/weekly_challenge/addChallengeGeneralCoordinators`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "POST",
    redirect: "follow",
    body: formData,
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const createWeeklyChallenge = async (challengeTitle, challengeDesc, challengeDate, challengeFrequency,
  enrollmentType, participationType, teamSize, maxRegistrations, noofquestions, departments,
  questionsCategory, timePerQuestion, choosingQuestions, coordinator_list, department_sem_data, challengeSubFrequency,
  challengeFrequencyDays, challengeFrequencyWeek, deptSemDataFile,viewer_enrollmentType, viewer_department_sem_data) => {

  const formData = new FormData();
  formData.append("title", challengeTitle);
  formData.append("description", challengeDesc);
  formData.append("start_date", challengeDate);
  formData.append("category_id", JSON.stringify(questionsCategory));
  formData.append("college_university_degree_department_id", JSON.stringify(departments));
  formData.append("random_questions", choosingQuestions);
  formData.append("no_of_participants", maxRegistrations);
  formData.append("team_size", teamSize);
  formData.append("noofquestions", noofquestions);
  formData.append("time_per_question", timePerQuestion);
  formData.append("participation_type", participationType);
  formData.append("enrollment_type", enrollmentType);
  formData.append("recurring", challengeFrequency);
  formData.append("coordinator_list", JSON.stringify(coordinator_list));
  formData.append("department_sem_data", JSON.stringify(department_sem_data));
  formData.append("recurring_type", challengeSubFrequency);
  formData.append("recurring_day", challengeFrequencyDays);
  formData.append("recurring_week", challengeFrequencyWeek);
  formData.append("attachment", deptSemDataFile);
  formData.append("viewer_type", viewer_enrollmentType);
  formData.append("viewer_department_sem_data", JSON.stringify(viewer_department_sem_data));

  let endpoint = `${BASE_API_URL}/weekly_challenge/v2/createWeeklyChallenge`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "POST",
    redirect: "follow",
    body: formData,
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const editWeeklyChallenge = async (challenge_id, challengeTitle, challengeDesc, challengeDate, challengeFrequency,
  enrollmentType, participationType, teamSize, maxRegistrations, noofquestions, departments,
  questionsCategory, timePerQuestion, choosingQuestions, coordinator_list, department_sem_data, challengeSubFrequency,
  challengeFrequencyDays, challengeFrequencyWeek, deptSemDataFile,viewer_enrollmentType, viewer_department_sem_data) => {

  const formData = new FormData();
  formData.append("id", challenge_id);
  formData.append("title", challengeTitle);
  formData.append("description", challengeDesc);
  formData.append("start_date", challengeDate);
  formData.append("category_id", JSON.stringify(questionsCategory));
  formData.append("college_university_degree_department_id", JSON.stringify(departments));
  formData.append("random_questions", choosingQuestions);
  formData.append("no_of_participants", maxRegistrations);
  formData.append("team_size", teamSize);
  formData.append("noofquestions", noofquestions);
  formData.append("time_per_question", timePerQuestion);
  formData.append("participation_type", participationType);
  formData.append("enrollment_type", enrollmentType);
  formData.append("recurring", challengeFrequency);
  formData.append("coordinator_list", JSON.stringify(coordinator_list));
  formData.append("department_sem_data", JSON.stringify(department_sem_data));
  formData.append("attachment", deptSemDataFile);
  formData.append("viewer_type", viewer_enrollmentType);
  formData.append("viewer_department_sem_data", JSON.stringify(viewer_department_sem_data));

  let endpoint = `${BASE_API_URL}/weekly_challenge/v2/editWeeklyChallenge`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "POST",
    redirect: "follow",
    body: formData,
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};


export const getWeeklyGeneralCoordinators = async () => {
  const endpoint = `${BASE_API_URL}/weekly_challenge/getChallengeGeneralCoordinators`
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const deleteGeneralCoordinatorApi = async (coordinator_id) => {
  let endpoint = `${BASE_API_URL}/weekly_challenge/deleteChallengeGeneralCoordinator?college_account_id=${coordinator_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const getWeeklyChallengeDetails = async (weekly_Challenge_id) => {
  const endpoint = `${BASE_API_URL}/weekly_challenge/getWeeklyChallengeDetails?weekly_challenge_id=${weekly_Challenge_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const getWeeklyChallengeData = async (weekly_Challenge_id) => {
  const endpoint = `${BASE_API_URL}/weekly_challenge/getWeeklyChallenge?weekly_challenge_id=${weekly_Challenge_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const getEnrolledMembers = async (weekly_Challenge_id) => {
  const endpoint = `${BASE_API_URL}/weekly_challenge/getAllEnrolledMembers?weekly_challenge_id=${weekly_Challenge_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const generateTeams = async (student_ids, faculty_ids, weekly_challenge_id) => {
  let endpoint = `${BASE_API_URL}/weekly_challenge/generateTeams`;
  const formData = new FormData();
  formData.append("weekly_challenge_id", weekly_challenge_id);
  formData.append("student_ids", JSON.stringify(student_ids));
  formData.append("faculty_ids", JSON.stringify(faculty_ids));
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "POST",
    redirect: "follow",
    body: formData,
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const getAllTeams = async (weekly_challenge_id) => {
  const endpoint = `${BASE_API_URL}/weekly_challenge/getAllTeams?weekly_challenge_id=${weekly_challenge_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const getMyTeam = async (weekly_challenge_id) => {
  const endpoint = `${BASE_API_URL}/weekly_challenge/getMyTeam?weekly_challenge_id=${weekly_challenge_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const getTopics = async (category_ids, college_university_degree_department_ids) => {
  const endpoint = `${BASE_API_URL}/weekly_challenge/getTopics`
  const formData = new FormData();
  formData.append("category_id", JSON.stringify(category_ids));
  formData.append("college_university_degree_department_id", JSON.stringify(college_university_degree_department_ids));
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "POST",
    redirect: "follow",
    body: formData,
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const addWeeklyChallengeQuestion = async (weekly_challenge_id, topic_id) => {
  const endpoint = `${BASE_API_URL}/weekly_challenge/addWeeklyChallengeQuestion?weekly_challenge_id=${weekly_challenge_id}&topic_id=${topic_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const getLeaderBoard = async (weekly_Challenge_id, question_id) => {
  const endpoint = `${BASE_API_URL}/weekly_challenge/getLeaderBoard?weekly_challenge_id=${weekly_Challenge_id}&question_id=${question_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const addWeeklyChallengeParticipants = async (student_ids, faculty_ids, weekly_challenge_id) => {
  let endpoint = `${BASE_API_URL}/weekly_challenge/addWeeklyChallengeParticipants`;
  const formData = new FormData();
  formData.append("weekly_challenge_id", weekly_challenge_id);
  formData.append("student_ids", JSON.stringify(student_ids));
  formData.append("faculty_ids", JSON.stringify(faculty_ids));
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "POST",
    redirect: "follow",
    body: formData,
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};


export const setWeeklyChallengeStatus = async (weekly_Challenge_id, status) => {
  const endpoint = `${BASE_API_URL}/weekly_challenge/setWeeklyChallengeStatus?weekly_challenge_id=${weekly_Challenge_id}&status=${status}`
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const setUnConfirmedCaptains = async (weekly_Challenge_id, team_data) => {
  const endpoint = `${BASE_API_URL}/weekly_challenge/setUnconfirmedCaptains`
  const formData = new FormData();
  formData.append("weekly_challenge_id", weekly_Challenge_id);
  formData.append("team_data", JSON.stringify(team_data));
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "POST",
    redirect: "follow",
    body: formData,
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};


//get dhashboard details
export const getDashBoardDetails = async (from_date, delta_days, to_date) => {
  const endpoint = `${BASE_API_URL}/college/dashboardData?from_date=${from_date}&delta_days=${delta_days}&to_date=${to_date}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

//enroll to Arena
export const enrollWeeklyChallenge = async (weekly_Challenge_id) => {
  let endpoint = `${BASE_API_URL}/weekly_challenge/enrollWeeklyChallenge?weekly_challenge_id=${weekly_Challenge_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const setCaptaincyStatus = async (weekly_challenge_id, team_id, team_name, is_captain, vice_captain) => {
  const formData = new FormData();
  formData.append("weekly_challenge_id", weekly_challenge_id);
  formData.append("team_id", team_id);
  formData.append("team_name", team_name);
  formData.append("is_captain", is_captain);
  formData.append("vice_captain", vice_captain);

  let endpoint = `${BASE_API_URL}/weekly_challenge/confirmCaptainStatus`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "POST",
    redirect: "follow",
    body: formData,
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

//submit participant answer
export const submitChallengeAnswer = async (team_id, weekly_challenge_id, question_id, option_id, answer, time_taken) => {
  const formData = new FormData();
  formData.append("team_id", team_id);
  formData.append("weekly_challenge_id", weekly_challenge_id);
  formData.append("question_id", question_id);
  formData.append("option_id", option_id);
  formData.append("answer", answer);
  formData.append("time_taken", time_taken);

  let endpoint = `${BASE_API_URL}/weekly_challenge/submitWeeklyChallengeAnswer`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "POST",
    redirect: "follow",
    body: formData,
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

//get profile details
export const getAllProfileDetails = async () => {
  const endpoint = `${BASE_API_URL}/profile/getAllProfileDetails`;
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

//set team chat
export const setAllTeamChat = async (weekly_challenge_id, team_id, chat) => {
  const formData = new FormData();
  formData.append("weekly_challenge_id", weekly_challenge_id);
  formData.append("team_id", team_id);
  formData.append("chats", JSON.stringify(chat));

  let endpoint = `${BASE_API_URL}/weekly_challenge/setTeamChat`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "POST",
    redirect: "follow",
    body: formData,
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

//get Arena categories
export const getChallengeCategories = async () => {
  let endpoint = `${BASE_API_URL}/weekly_challenge/getWeeklyChallengeCategories`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

//enroll to Arena
export const getQuestionSubmissionOrder = async (weekly_Challenge_id,question_id) => {
  let endpoint = `${BASE_API_URL}/weekly_challenge/getSubmissionOrder?weekly_challenge_id=${weekly_Challenge_id}&question_id=${question_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

//submit viewer answer
export const submitViewerChallengeAnswer = async (weekly_challenge_id, question_id, option_id, answer, time_taken) => {
  const formData = new FormData();
  formData.append("weekly_challenge_id", weekly_challenge_id);
  formData.append("question_id", question_id);
  formData.append("option_id", option_id);
  formData.append("answer", answer);
  formData.append("time_taken", time_taken);

  let endpoint = `${BASE_API_URL}/weekly_challenge/submitWeeklyChallengeViewerAnswer`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "POST",
    redirect: "follow",
    body: formData,
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

//set viewers chat
export const setAllViewersChat = async (weekly_challenge_id, chat) => {
  const formData = new FormData();
  formData.append("weekly_challenge_id", weekly_challenge_id);
  formData.append("chats", JSON.stringify(chat));

  let endpoint = `${BASE_API_URL}/weekly_challenge/setViewerschat`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "POST",
    redirect: "follow",
    body: formData,
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

//get Arena question
export const getWeeklyChallengeQuestion = async (weekly_Challenge_id) => {
  let endpoint = `${BASE_API_URL}/weekly_challenge/getWeeklyChallengeQuestion?weekly_challenge_id=${weekly_Challenge_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};