import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Divider, Grid, Button } from "@mui/material";
import Navbar from "../navbar/navbar.component";
import LayoutwithSideNav from "../common/LayoutwithSideNav";
import McqReportTemplate from "./mcqReportTemplate/mcqReportTemplate.component";
import LiveAssessmentReportTemplate from "./liveAssessmentReportTemplate/liveAssessmentReportTemplate.component";
import LiveSurveyReportTemplate from "./liveSurveyTemplate/liveSurveyTemplate.component";
import ChallengeReportTemplate from "./challengeReportTemplate/challengeReportTemplate.component";
import * as XLSX from 'xlsx';
import { useSelector } from "react-redux";
import { generateMcqReports, generateLiveAssessmentsReports, generateLiveSurveyReports, generateWeeklyChallengeReports } from "../../api/facultyfeedbackapis";
import { PieChart, Pie, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from 'recharts';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
// import JSZip from 'jszip';
// import saveAs from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import FileDownload from 'react-file-download';

const ReportsDownload = () => {
    const baseUrlFacultyWeb = useSelector(state => state?.Common?.facultyWebBaseUrl);
    const location = useLocation();
    const navigate = useNavigate();
    const [feedbackDownloadingFlag, setFeedbackDownloadingFlag] = useState(true);
    const [chartData, setChartData] = useState([]);
    const [chartUrl, setChartUrl] = useState("");
    const [reportData, setReportData] = useState("");
    const overall_performance_data2 = [
        { name: 'Very Good', value: 30 },
        { name: 'Good', value: 25 },
        { name: 'Average', value: 25 },
        { name: 'Below Average', value: 20 },
    ];
    const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

    const [chartImage, setChartImage] = React.useState(null);

    const chartRef = useRef(null); // Add this ref
    useEffect(() => {
        console.log("test id -> ", location?.state?.test_id);
        console.log("download type -> ", location?.state?.download_type);
        console.log("report type -> ", location?.state?.report_type);
        if (location.state?.download_type === "pdf") {
            if (location.state.report_type === "mcq") {
                DownloadDataAsPdf(location.state.test_id);
            } else if (location.state.report_type === "lar") {
                DownloadLiveAssessmentDataAsPdf(location.state.test_id);
            } else if (location.state.report_type === "lsr") {
                DownloadLiveSurveyDataAsPdf(location.state.test_id);
            } else if (location.state.report_type === "wcr") {
                DownloadChallengeDataAsPdf(location.state.test_id);
            } else {
                window.location.href = `${baseUrlFacultyWeb}/index.html`;
            }
        } else if (location.state?.download_type === "excel") {
            if (location.state.report_type === "mcq") {
                generateMcqTestReports(location.state.test_id);
            } else if (location.state.report_type === "lar") {
                generateLiveAssReports(location.state.test_id);
            } else if (location.state.report_type === "lsr") {
                generateSurveyReports(location.state.test_id);
            } else if (location.state.report_type === "wcr") {
                generateChallengeReports(location.state.test_id);
            } else {
                window.location.href = `${baseUrlFacultyWeb}/index.html`;
            }
        } else {
            window.location.href = `${baseUrlFacultyWeb}/index.html`;
        }
    }, []);
    // useEffect(() => {
    //     DownloadDataAsPdf(42285);
    // }, []);

    //generate mcq test reports
    const generateMcqTestReports = async (test_id) => {
        const test_id2 = 4444;
        const result = await generateMcqReports(test_id);
        if (result.data) {
            let data = result.data;
            const workbook = XLSX.utils.book_new();
            let sheetData = []
            sheetData.push([`${data.college_name}`]);
            sheetData.push(['']);
            sheetData.push(['Test Details', '', '', '', '', '', '', '', 'Faculty Details']);
            sheetData.push(['Test Name:', `${data.test_name}`, '', '', '', '', '', '', 'Faculty Name:', `${data.faculty_name}`]);
            sheetData.push(['Description:', `${data.description}`, '', '', '', '', '', '', 'Department:', `${data.branch_name}`]);
            sheetData.push(['Subject:', `${data.subject}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Start Time:', `${data.start_time}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['End Time:', `${data.end_time}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Test Duration:', `${data.test_duration}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Total Questions:', `${data.total_test_questions}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Total Marks:', `${data.total_test_marks}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Sent To:', `${data.total_students}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Attempted by:', `${data.total_students_attempted}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['']);
            sheetData.push(['']);
            sheetData.push(['Overall Performace', '', 'Very Good', 'Good', 'Average', 'Below Average', 'Test Average', '', '', '']);
            sheetData.push(['', 'Percentage Scored (%)',
                `${data.overall_performance.very_good_percentage ? data.overall_performance.very_good_percentage : 0}`,
                `${data.overall_performance.good_percentage ? data.overall_performance.good_percentage : 0}`,
                `${data.overall_performance.average_percentage ? data.overall_performance.average_percentage : 0}`,
                `${data.overall_performance.below_average_percentage ? data.overall_performance.below_average_percentage : 0}`,
                `${data.overall_performance.test_average}`, '', '', '']);
            sheetData.push(['']);
            sheetData.push(['']);
            sheetData.push(['Topics Performance']);
            sheetData.push(['S.No', 'Name', 'Total Questions', 'Average Performance (%)']);
            data.topic_performance.map((topic, index) => {
                return sheetData.push([`${index + 1}`, `${topic.name}`, `${topic.total_count}`, `${topic.average}`])
            })

            const sheetName = 'Test Details';
            const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
            worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 9 } }];
            worksheet["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
            worksheet["!rows"] = [{ hpx: 40 }];
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

            //students information sheet
            let sheet2 = [];
            sheet2.push([`${data.college_name}`]);
            sheet2.push(['']);
            sheet2.push(['Students Performance']);
            sheet2.push(['S.No', 'Roll Number', 'Name', 'Department', 'Section', 'Right Answered', 'Wrong Answered', 'Unattempted',
                'Marks Scored', 'Percentage Scored', 'Time Spent (min)', 'Status (1: Attempted, 0: Unattempted)', 'Submission Type']);
            data.students_performance.map((student, index) => {
                if (student.status === 1) {
                    return sheet2.push([`${index + 1}`, `${student.roll_number}`, `${student.name}`, `${student.department}`, `${student.section}`,
                    `${student.right_questions}`, `${student.wrong_questions}`, `${student.unattempted_questions}`,
                    `${student.total_marks}`, `${student.percentage_scored}`, `${(student.total_time_spent / 60).toFixed(2)}`, `${student.status}`,
                    `${student.submission_type}`])
                } else {
                    return sheet2.push([`${index + 1}`, `${student.roll_number}`, `${student.name}`, `${student.department}`, `${student.section}`,
                        `-`, `-`, `-`,
                        `-`, `-`, `-`, `${student.status}`, `-`])
                }
            })
            const sheetName2 = 'Student Performance';
            const worksheet2 = XLSX.utils.aoa_to_sheet(sheet2);
            worksheet2['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }, { s: { r: 2, c: 0 }, e: { r: 2, c: 10 } }];
            worksheet2["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
            worksheet2["!rows"] = [{ hpx: 40 }];
            XLSX.utils.book_append_sheet(workbook, worksheet2, sheetName2);

            //Question Analytics
            let sheet3 = []
            sheet3.push([`${data.college_name}`]);
            sheet3.push(['']);
            sheet3.push(['Question Analytics']);
            sheet3.push(['S.No', 'Question', 'Question image', 'Marks Assigned'])
            data.question_list.map((question, index) => {
                return sheet3.push([`${index}`, `${question.name}`, `${question.image ? question.image : ''}`, `${question.marks_assigned}`])
            })
            const sheetName3 = 'Question Analytics';
            const worksheet3 = XLSX.utils.aoa_to_sheet(sheet3);
            worksheet3['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 3 } }, { s: { r: 2, c: 0 }, e: { r: 2, c: 3 } }];
            worksheet3["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
            worksheet3["!rows"] = [{ hpx: 40 }];
            XLSX.utils.book_append_sheet(workbook, worksheet3, sheetName3);

            // Convert the workbook to a binary string
            const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

            // Create a Blob from the binary string
            const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create a link and simulate a click to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${data.test_name}_${data.start_time}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.location.href = `${baseUrlFacultyWeb}/myAssessment.html`;
        } else {
            window.location.href = `${baseUrlFacultyWeb}/myAssessment.html`;
        }

    }
    // Convert a string to an ArrayBuffer
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }

    //generating live assessments reports
    const generateLiveAssReports = async (assessment_id) => {
        const assessment_id2 = 359;
        const result = await generateLiveAssessmentsReports(assessment_id);
        if (result.data) {
            let data = result.data;
            const workbook = XLSX.utils.book_new();
            let sheetData = []
            sheetData.push([`${data.college_name}`]);
            sheetData.push(['']);
            sheetData.push(['Live Assessment Details', '', '', '', '', '', '', '', 'Faculty Details']);
            sheetData.push(['Assessment Name:', `${data.test_name}`, '', '', '', '', '', '', 'Faculty Name:', `${data.faculty_name}`]);
            sheetData.push(['Description:', `${data.description}`, '', '', '', '', '', '', 'Department:', `${data.branch_name}`]);
            sheetData.push(['Subject:', `${data.subject}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Start Time:', `${data.start_time}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['End Time:', `${data.end_time}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Total Questions:', `${data.total_test_questions}`, '', '', '', '', '', '', '', '']);
            // sheetData.push(['Total Marks:', `${data.total_test_marks}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Sent To:', `${data.total_students}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Attempted by:', `${data.total_students_attempted}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['']);
            sheetData.push(['']);
            sheetData.push(['Overall Performace', '', 'Vey Good', 'Good', 'Average', 'Below Average', 'Test Average', '', '', '']);
            sheetData.push(['', 'Percentage Scored (%)',
                `${data.overall_performance.very_good_percentage ? data.overall_performance.very_good_percentage : 0}`,
                `${data.overall_performance.good_percentage ? data.overall_performance.good_percentage : 0}`,
                `${data.overall_performance.average_percentage ? data.overall_performance.average_percentage : 0}`,
                `${data.overall_performance.below_average_percentage ? data.overall_performance.below_average_percentage : 0}`,
                `${data.overall_performance.test_average}`, '', '', '']);
            sheetData.push(['']);
            sheetData.push(['']);
            sheetData.push(['Topics Performance']);
            sheetData.push(['S.No', 'Name', 'Total Questions', 'Average Performance (%)']);
            data.topic_performance.map((topic, index) => {
                return sheetData.push([`${index + 1}`, `${topic.name}`, `${topic.total_count}`, `${topic.average}`])
            })

            const sheetName = 'Assessment Details';
            const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
            worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 9 } }];
            worksheet["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
            worksheet["!rows"] = [{ hpx: 40 }];
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

            //students information sheet
            let sheet2 = [];
            sheet2.push([`${data.college_name}`]);
            sheet2.push(['']);
            sheet2.push(['Students Performance']);
            sheet2.push(['S.No', 'Roll Number', 'Name', 'Department', 'Section', 'Right Answered', 'Wrong Answered', 'Unattempted',
                'Marks Scored', 'Percentage Scored', 'Time Spent (min)', 'Status (1: Attempted, 0: Unattempted)']);
            data.students_performance.map((student, index) => {
                if (student.status === 1) {
                    return sheet2.push([`${index + 1}`, `${student.roll_number}`, `${student.name}`, `${student.department}`, `${student.section}`,
                    `${student.right_questions}`, `${student.wrong_questions}`, `${student.unattempted_questions}`,
                    `${student.total_marks}`, `${student.percentage_scored}`, `${(student.total_time_spent / 60).toFixed(2)}`, `${student.status}`])
                } else {
                    return sheet2.push([`${index + 1}`, `${student.roll_number}`, `${student.name}`, `${student.department}`, `${student.section}`,
                        `-`, `-`, `-`, `-`, `-`, `-`, `${student.status}`])
                }
            })
            const sheetName2 = 'Student Performance';
            const worksheet2 = XLSX.utils.aoa_to_sheet(sheet2);
            worksheet2['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }, { s: { r: 2, c: 0 }, e: { r: 2, c: 10 } }];
            worksheet2["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
            worksheet2["!rows"] = [{ hpx: 40 }];
            XLSX.utils.book_append_sheet(workbook, worksheet2, sheetName2);

            //Question Analytics
            let sheet3 = []
            sheet3.push([`${data.college_name}`]);
            sheet3.push(['']);
            sheet3.push(['Question Analytics']);
            sheet3.push(['S.No', 'Question', 'Question image', 'Total Right Answered', 'Total Wrong Answered', 'Total Answered'])
            data.question_list.map((question, index) => {
                return sheet3.push([`${index}`, `${question.name}`, `${question.image ? question.image : ''}`,
                `${question.right_answered_count}`, `${question.wrong_answered_count}`, `${question.total_answered_count}`])
            })
            const sheetName3 = 'Question Analytics';
            const worksheet3 = XLSX.utils.aoa_to_sheet(sheet3);
            worksheet3['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 3 } }, { s: { r: 2, c: 0 }, e: { r: 2, c: 3 } }];
            worksheet3["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
            worksheet3["!rows"] = [{ hpx: 40 }];
            XLSX.utils.book_append_sheet(workbook, worksheet3, sheetName3);

            // Convert the workbook to a binary string
            const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

            // Create a Blob from the binary string
            const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create a link and simulate a click to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${data.test_name}_${data.start_time}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.location.href = `${baseUrlFacultyWeb}/myLiveAssessment.html`;
        } else {
            window.location.href = `${baseUrlFacultyWeb}/myLiveAssessment.html`;
        }
    };

    //generate live survey reports
    const generateSurveyReports = async (survey_id) => {
        const survey_id1 = 201;
        const result = await generateLiveSurveyReports(survey_id);
        if (result.data) {
            let data = result.data;
            const workbook = XLSX.utils.book_new();
            let sheetData = []
            sheetData.push([`${data.college_name}`]);
            sheetData.push(['']);
            sheetData.push(['Live Survey Details', '', '', '', '', '', '', '', 'Faculty Details']);
            sheetData.push(['Survey Name:', `${data.test_name}`, '', '', '', '', '', '', 'Faculty Name:', `${data.faculty_name}`]);
            sheetData.push(['Description:', `${data.description}`, '', '', '', '', '', '', 'Department:', `${data.branch_name}`]);
            sheetData.push(['Start Time:', `${data.start_time}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['End Time:', `${data.end_time}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Total Questions:', `${data.total_no_of_questions}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Total Attendees:', `${data.no_of_attendees}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Total Submissions:', `${data.no_of_submissions}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Total Followers:', `${data.total_no_of_followers}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['']);
            sheetData.push(['']);
            sheetData.push(['Question Overall Analytics']);
            sheetData.push(['S.No', 'Question', 'Attempted Count', 'Unattempted Count']);
            data.question_overall_analytics.map((question, index) => {
                return sheetData.push([`${index + 1}`, `${question.name}`, `${question.attempted_count}`, `${question.unattempted_count}`]);
            });

            const sheetName = 'Survey Details';
            const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
            worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 9 } }];
            worksheet["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
            worksheet["!rows"] = [{ hpx: 40 }];
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

            //Question wise performance
            // let sheet2 = [];
            // sheet2.push([`${data.college_name}`]);
            // sheet2.push(['']);
            // sheet2.push(['QuestionWise Performance']);
            // sheet2.push(['S.No', 'Question', 'Image']);
            // data.questionwise_analytics.map((question, index) => {
            //     sheet2.push([`${index + 1}`, `${question.name}`, `${question.image ? question.image : ""}`]);
            //     sheet2.push(['Submissions:']);
            //     sheet2.push(['', 'S.No', 'Name', 'Roll Number', 'Role', 'Branch', 'Section', 'Option', 'Answer', 'Time Taken (min)']);
            //     question.submissions.map((participant, index2) => {
            //         return sheet2.push(['', `${index2 + 1}`, `${participant.name}`, `${participant.roll_number}`, `${participant.role}`,
            //             `${participant.branch_name}`, `${participant.section_name ? participant.section_name : ''}`, `${participant.option}`,
            //             `${participant.answer ? participant.answer : ""}`, `${(participant.time_taken/60).toFixed(2)}`])
            //     })
            // });

            // const sheetName2 = 'QuestionWise Analytics';
            // const worksheet2 = XLSX.utils.aoa_to_sheet(sheet2);
            // worksheet2['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }, { s: { r: 2, c: 0 }, e: { r: 2, c: 10 } }];
            // worksheet2["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
            // worksheet2["!rows"] = [{ hpx: 40 }];
            // XLSX.utils.book_append_sheet(workbook, worksheet2, sheetName2);

            //student wise analytics
            let sheet3 = [];
            sheet3.push([`${data.college_name}`]);
            sheet3.push(['']);
            sheet3.push(['Students Performance']);
            sheet3.push(['S.No', 'Name', 'Roll Number', 'Role', 'Branch', 'Section', 'Questions count', 'Time Spent (min)']);
            data.studentwise_analytics.map((student, index) => {
                sheet3.push([`${index + 1}`, `${student.name}`, `${student.roll_number}`, `${student.role}`, `${student.branch_name}`,
                `${student.section_name ? student.section_name : ''}`, `${student.attempted_count ? student.attempted_count : 0}`,
                `${student.total_time_spent ? (student.total_time_spent / 60).toFixed(2) : 0}`]);
                // sheet3.push(['Submissions:']);
                // sheet3.push(['', 'S.No', 'Question', 'Image', 'Option', 'Answer', 'Time taken (min)']);
                // student.submissions.map((question, index2) => {
                //     return sheet3.push(['', `${index2 + 1}`, `${question.name}`, `${question.image ? question.image : ''}`,
                //         `${question.option ? question.option : ''}`, `${question.answer ? question.answer : ''}`,
                //         `${question.time_taken}`]);
                // })
                // sheet3.push(['']);
            });

            const sheetName3 = 'Students Performance';
            const worksheet3 = XLSX.utils.aoa_to_sheet(sheet3);
            worksheet3['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }, { s: { r: 2, c: 0 }, e: { r: 2, c: 10 } }];
            worksheet3["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
            worksheet3["!rows"] = [{ hpx: 40 }];
            XLSX.utils.book_append_sheet(workbook, worksheet3, sheetName3);


            // Convert the workbook to a binary string
            const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

            // Create a Blob from the binary string
            const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create a link and simulate a click to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${data.test_name}_${data.start_time}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.location.href = `${baseUrlFacultyWeb}/myLiveSurvey.html`;
        } else {
            window.location.href = `${baseUrlFacultyWeb}/myLiveSurvey.html`;
        }
    };

    //generating Arena reports
    const generateChallengeReports = async (challenge_id) => {
        const challenge_id1 = 1406;
        const result = await generateWeeklyChallengeReports(challenge_id);
        if (result.data) {
            let data = result.data;
            const workbook = XLSX.utils.book_new();
            let sheetData = []
            sheetData.push([`${data.college_name}`]);
            sheetData.push(['']);
            sheetData.push(['Arena Details', '', '', '', '', '', '', '', 'Faculty Details']);
            sheetData.push(['Arena Name:', `${data.challenge_name}`, '', '', '', '', '', '', 'Faculty Name:', `${data.faculty_name}`]);
            sheetData.push(['Description:', `${data.challenge_desc}`, '', '', '', '', '', '', 'Department:', `${data.branch_name}`]);
            sheetData.push(['Start Time:', `${data.start_time}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Categories:', `${data.categories}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['No of Questions:', `${data.no_of_questions}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Enrollment Type:', `${data.enrollment_type}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['Participation Type:', `${data.participation_type}`, '', '', '', '', '', '', '', '']);
            // sheetData.push(['Arena Size:', `${data.total_no_of_participants}`, '', '', '', '', '', '', '', '']);
            sheetData.push(['No of Participants:', `${data.no_of_members_participated}`, '', '', '', '', '', '', '', '']);
            if (data.participation_type === "Team") {
                sheetData.push(['No of Teams:', `${data.no_of_teams}`, '', '', '', '', '', '', '', '']);
                sheetData.push(['Team Size:', `${data.team_size}`, '', '', '', '', '', '', '', '']);
            }
            sheetData.push(['Winner:', `${data.winner}`, '', '', '', '', '', '', '', '']);
            sheetData.push([''])
            sheetData.push([''])
            sheetData.push(['Analytics'])
            if (data.participation_type === "Team") {
                sheetData.push(['S.No', 'Team Name', 'Team Members', 'No of Right Questions', 'No of Wrong Questions', 'No of Unattempted Questions',
                    'Rank', 'Time Taken (sec)']);
                data.analytics.map((row, index) => {
                    let team_member_names = "";
                    if (row.team_members.length > 0) {
                        row.team_members.map((team_member, index) => {
                            if (index === row.team_members.length - 1) {
                                return team_member_names = team_member_names + team_member.name
                            } else {
                                return team_member_names = team_member_names + team_member.name + ", "
                            }
                        })
                    }
                    return sheetData.push([`${index + 1}`, `${row.team_name}`, `${team_member_names}`, `${row.total_correct_answers}`, `${row.total_wrong_answers}`,
                    `${row.unattempted_questions}`, `${index + 1}`, `${row.total_time_taken}`]);
                });
            } else {
                sheetData.push(['S.No', 'Student Name', 'Roll Number', 'Faculty Name', 'No of Right Questions', 'No of Wrong Questions', 'No of Unattempted Questions',
                    'Rank', 'Time Taken (sec)']);
                data.analytics.map((row, index) => {
                    return sheetData.push([`${index + 1}`, `${row.student_name}`, `${row.student_roll_number}`, `${row.faculty_name}`,
                    `${row.total_correct_answers}`, `${row.total_wrong_answers}`, `${row.unattempted_questions}`, `${index + 1}`,
                    `${row.total_time_taken}`]);
                });
            }

            const sheetName = 'Arena Details';
            const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
            worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 9 } }];
            worksheet["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
            worksheet["!rows"] = [{ hpx: 40 }];
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

            // Convert the workbook to a binary string
            const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

            // Create a Blob from the binary string
            const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create a link and simulate a click to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${data.challenge_name}_${data.start_time}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            navigate("/weeklyChallenge");
        } else {
            navigate("/weeklyChallenge");
        }
    }

    //downloading the pdf of mcq reports
    async function DownloadDataAsPdf(test_id) {
        console.log("downloading pdfs!");
        const test_id1 = 4444;
        const result = await generateMcqReports(test_id);
        if (result.data) {
            setReportData(result.data)
            const chartContainer = chartRef.current;
            const overall_performance_keypairs = Object.entries(result.data.overall_performance);
            console.log("overall_performance_data -> ", overall_performance_keypairs);
            let overall_performance_data = [];
            let overall_performance_names = ["Very Good", "Good", "Average", "Below Average"];
            let has_chart = false;
            overall_performance_keypairs.map((keypair, index) => {
                if (index < overall_performance_keypairs.length - 1) {
                    overall_performance_data.push({ name: overall_performance_names[index], value: keypair[1] });
                    if(keypair[1] > 0){
                        has_chart = true;
                    }
                }
            });
            setChartData(overall_performance_data);
            let url_link = "";
            setTimeout(() => {
                const chartContainer2 = document.getElementById('chart-container');
                console.log("chartContainer -> ", chartContainer);
                console.log("chartContainer2 -> ", chartContainer2);
                if (chartContainer) {
                    // Export chart as image using html2canvas
                    html2canvas(chartContainer)
                        .then(canvas => {
                            console.log("c-----> ", canvas);
                            const link = canvas.toDataURL();
                            setTimeout(() => {
                                console.log("---------");
                                console.log(link);
                                url_link = link;
                                setChartImage(link);
                            }, 300)
                        })
                        .catch(error => {
                            console.error('Error generating chart image:', error);
                        });
                }
            }, 3500);

            try {
                while (!url_link) {
                    // Wait until url_link is assigned a value
                    await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before checking again
                }
                console.log("chartImage -> ", url_link);
                setChartUrl(url_link);
                const pdfBlob = await pdf(<McqReportTemplate reportData={[result.data]} chart_available={has_chart} image_url={url_link} />).toBlob();
                FileDownload(pdfBlob, `${result.data.test_name}_${result.data.start_time}.pdf`);
            } catch (error) {
                console.error("Error generating or saving the zip file:", error);
            }
            window.location.href = `${baseUrlFacultyWeb}/myAssessment.html`;
        } else {
            window.location.href = `${baseUrlFacultyWeb}/myAssessment.html`;
        }

    };

    //downloading the pdf of live assessment reports
    async function DownloadLiveAssessmentDataAsPdf(assessment_id) {
        console.log("downloading pdfs!");
        const assessment_id1 = 359;
        const result = await generateLiveAssessmentsReports(assessment_id);
        if (result.data) {
            setReportData(result.data)
            const chartContainer = chartRef.current;
            const overall_performance_keypairs = Object.entries(result.data.overall_performance);
            console.log("overall_performance_data -> ", overall_performance_keypairs);
            let overall_performance_data = [];
            let overall_performance_names = ["Very Good", "Good", "Average", "Below Average"];
            let has_chart = false;
            overall_performance_keypairs.map((keypair, index) => {
                if (index < overall_performance_keypairs.length - 1) {
                    overall_performance_data.push({ name: overall_performance_names[index], value: keypair[1] });
                    if(keypair[1] > 0){
                        has_chart = true;
                    }
                }
            });
            setChartData(overall_performance_data);
            let url_link = "";
            setTimeout(() => {
                const chartContainer2 = document.getElementById('chart-container');
                console.log("chartContainer -> ", chartContainer);
                console.log("chartContainer2 -> ", chartContainer2);
                if (chartContainer) {
                    // Export chart as image using html2canvas
                    html2canvas(chartContainer)
                        .then(canvas => {
                            console.log("c-----> ", canvas);
                            const link = canvas.toDataURL();
                            setTimeout(() => {
                                console.log("---------");
                                console.log(link);
                                url_link = link;
                                setChartImage(link);
                            }, 300)
                        })
                        .catch(error => {
                            console.error('Error generating chart image:', error);
                        });
                }
            }, 3500);

            try {
                while (!url_link) {
                    // Wait until url_link is assigned a value
                    await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before checking again
                }
                console.log("chartImage -> ", url_link);
                const pdfBlob = await pdf(<LiveAssessmentReportTemplate reportData={[result.data]} chart_available={has_chart} image_url={url_link} />).toBlob();
                FileDownload(pdfBlob, `${result.data.test_name}_${result.data.start_time}.pdf`);
            } catch (error) {
                console.error("Error generating or saving the zip file:", error);
            }

            window.location.href = `${baseUrlFacultyWeb}/myLiveAssessment.html`;
        } else {
            window.location.href = `${baseUrlFacultyWeb}/myLiveAssessment.html`;
        }

    };

    //downloading the pdf of live assessment reports
    async function DownloadLiveSurveyDataAsPdf(survey_id) {
        console.log("downloading pdfs!");
        const survey_id1 = 201;
        const result = await generateLiveSurveyReports(survey_id);
        if (result.data) {
            try {
                const pdfBlob = await pdf(<LiveSurveyReportTemplate reportData={[result.data]} />).toBlob();
                FileDownload(pdfBlob, `${result.data.test_name}_${result.data.start_time}.pdf`);
            } catch (error) {
                console.error("Error generating or saving the zip file:", error);
            }

            window.location.href = `${baseUrlFacultyWeb}/myLiveSurvey.html`;
        } else {
            window.location.href = `${baseUrlFacultyWeb}/myLiveSurvey.html`;
        }

    };

    //downloading the pdf of live assessment reports
    async function DownloadChallengeDataAsPdf(challenge_id) {
        console.log("downloading pdfs!");
        const challenge_id1 = 216;
        const result = await generateWeeklyChallengeReports(challenge_id);
        if (result.data) {
            try {
                const pdfBlob = await pdf(<ChallengeReportTemplate reportData={[result.data]} />).toBlob();
                FileDownload(pdfBlob, `${result.data.challenge_name}_${result.data.start_time}.pdf`);
            } catch (error) {
                console.error("Error generating or saving the zip file:", error);
            }

            navigate("/weeklyChallenge");
        } else {
            navigate("/weeklyChallenge");
        }

    };

    return (
        <Box>
            <Navbar />
            <LayoutwithSideNav>
                {
                    feedbackDownloadingFlag ? (
                        <>
                            <div style={{backgroundColor: 'white', marginTop: '-2%'}}>
                                <img src="/images/feedback_images/icons8-download.gif" className="inplace-reports-gif" />
                                <p className="generating-reports-label">Downloading Report . . .</p>
                            </div>
                            <Box sx={{position:'relative', zIndex: -1, marginTop: '-32%'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} sm={12} lg={12}>
                                        <div ref={chartRef} id="chart-container">
                                            <PieChart width={400} height={400}>
                                                <Pie dataKey="value" data={chartData} label>
                                                    {
                                                        chartData.length != 0
                                                            ? chartData.map((d, index) => {
                                                                return (<Cell key={`cell-${index}`} fill={colors[index]} />)
                                                            })
                                                            : ""
                                                    }
                                                </Pie>
                                            </PieChart>
                                        </div>
                                        {/* {chartImage ? <LiveAssessmentReportTemplate chart_available={true} image_url={chartImage} /> : "No chart to show!"} */}

                                    </Grid>
                                </Grid>
                            </Box>
                            {/* { chartUrl ? <McqReportTemplate reportData={[reportData]} chart_available={true} image_url={chartUrl} /> : ""} */}
                        </>
                    )
                        : (
                            <>
                                <div>
                                    <img src="/images/feedback_images/generate-report-giff.gif" className="reports-generate-gif" />
                                    <p className="generating-reports-label">Generating Reports . . .</p>
                                </div>
                                {/* page header part */}
                                {/* <Box sx={{ p: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={8} md={8} lg={8}>
                                            <Typography variant="h4" sx={{ wordBreak: "break-all", fontFamily: "Source Sans Pro" }}>
                                                MCQ Reports Download
                                            </Typography>
                                            <Divider sx={{
                                                width: "5rem",
                                                height: "4px",
                                                textAlign: "start",
                                                ml: 0,
                                                mt: 1,
                                                mb: "15px",
                                                background: "linear-gradient(131deg, #7175E0 0%, #4D50CB 33%, #E25BC8 100%)",
                                                borderRadius: "5px",
                                                opacity: "100%",
                                            }} />
                                            <Button variant="contained" id="next" onClick={DownloadDataAsPdf}>Mcq PDF Download</Button>
                                            <Button variant="contained" id="next" onClick={DownloadLiveAssessmentDataAsPdf}> Live Assess PDF Download</Button>
                                            <Button variant="contained" id="next" onClick={DownloadLiveSurveyDataAsPdf}> Live Survey PDF Download</Button>
                                            <Button variant="contained" id="next" onClick={DownloadChallengeDataAsPdf}> Arena PDF Download</Button>
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4}>
                                            <Button variant="contained" id="next" onClick={generateMcqTestReports}>MCQ Reports XL</Button>
                                            <Button variant="contained" id="next" onClick={generateLiveAssReports}>Live Assessments XL</Button>
                                            <Button variant="contained" id="next" onClick={generateSurveyReports}>Live Surveys XL</Button>
                                            <Button variant="contained" id="next" onClick={generateChallengeReports}>Arena Reports XL</Button>
                                        </Grid>
                                    </Grid>
                                </Box> */}
                            </>
                        )
                }
            </LayoutwithSideNav>
        </Box>
    )
}

export default ReportsDownload;