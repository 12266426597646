import React, { useState, useEffect } from "react";
//import WheelComponent from 'react-wheel-of-prizes';
import { BiChat } from "react-icons/bi";
import "./spinWheel.styles.css";
import WheelComponent from "./wheelcomponent";

const Wheel = (props) => {
    const [segTopics, setSegTopics] = useState([]);
    const [segColors, setSegColors] = useState([]);
    const [topic_to_select, setTopicToSelect] = useState("");
    const [beforeSpinning, setBeforeSpinning] = useState(true);
    const [afterSpinning, setAfterSpinning] = useState(false);
    const existingSegColors = [
        '#aec7e8',
        '#ffbb78',
        '#98df8a',
        '#ff9896',
        '#c5b0d5',
        '#c49c94',
        '#f7b6d2',
        '#c7c7c7',
        '#dbdb8d',
        '#9edae5'
    ];
    useEffect(() => {
        if (segTopics.length === 0) {
            let topic_list = [];
            let color_list = [];
            let topicselect = "";
            for (let i = 0, j = 0; i < props.topic_data.length && j < existingSegColors.length; i++, j++) {
                const topic_name = props.topic_data[i].name;
                console.log(topic_name);
                topic_list.push(topic_name);
                const color_id = existingSegColors[j];
                color_list.push(color_id);
                const topic_id = props.topic_data[i].id;
                if (topic_id === props.topicselected) {
                    topicselect = topic_name;
                }
                if (j === existingSegColors.length - 1) {
                    j = -1;
                }
            }
            setSegTopics(topic_list);
            setSegColors(color_list);
            setTopicToSelect(topicselect);
        }
    });

    const onFinished = (winner) => {
        setBeforeSpinning(false);
        setAfterSpinning(true);
        console.log(winner);
    }
    console.log(segColors);
    console.log(segTopics);
    console.log(props.topicselected);
    console.log(topic_to_select);
    if (segTopics.length !== 0 && segColors.length !== 0) {
        return (
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                    {
                        beforeSpinning && (
                            <div className="spinned-topic-info">
                                <h4>Arena Begins!</h4>
                                <p><strong>Initiating Spin Wheel.</strong></p>
                            </div>
                        )
                    }
                    {
                        afterSpinning && (
                            <div className="spinned-topic-info slide-right">
                                <h4>Generating Question in</h4>
                                <h2>{topic_to_select}</h2>
                                <p><strong>Team Get Ready!</strong></p>
                            </div>
                        )
                    }
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="participant-spinwheel" style={{ marginTop: '5%', marginLeft: '2.5%' }}>
                        <WheelComponent
                            segments={segTopics}
                            segColors={segColors}
                            winningSegment={topic_to_select}
                            onFinished={(winner) => onFinished(winner)}
                            primaryColor='grey'
                            contrastColor='black'
                            buttonText='Spin'
                            isOnlyOnce={false}
                            size={290}
                            upDuration={100}
                            downDuration={1000}
                            fontFamily='Arial'
                            isPlaying={false}
                        />
                    </div>
                </div>
            </div>
        )
    }

}

export default Wheel;