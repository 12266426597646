import React from "react";
import Navbar from "../components/navbar/navbar.component";
import AllWeeklyChallenges from "../components/weeklyChallenge/AllWeeklyChallenges.component";
import LayoutwithSideNav from "../components/common/LayoutwithSideNav";
const WeeklyChallenge = () => {
    return (
        <div className="weekly-challenge" style={{overflowX:"hidden"}}>
            <Navbar />
            <LayoutwithSideNav>
                <div className="row mb-5">
                    <AllWeeklyChallenges />
                </div>
            </LayoutwithSideNav>
        </div>
    );
}

export default WeeklyChallenge;