import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactCountdownClock from "react-countdown-clock";
import { FaLongArrowAltRight } from "react-icons/fa";
import { BsFillCaretDownFill } from "react-icons/bs";
import { RiTeamLine } from "react-icons/ri";
import { Buffer } from "buffer";
import ChallengeSpinWheel from "../challengeSpinWheel/challengeSpinWheel.component";
import LeaderBoard from "../leaderBoard/leaderBoard.component";
import { getTopics, getLeaderBoard, getWeeklyChallengeDetails, setWeeklyChallengeStatus, getAllTeams, getWeeklyChallengeData, setAllViewersChat } from "../../api/weeklyChallengeAPIs";
import "./questionnaire.styles.css";
import { messages } from "../chatwindow/viewerChatroom";

const QuestionnairePanel = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const realtimePChannel = useSelector(state => state.joinChallenge.realtimePChannel);
    const channel = useSelector(state => state.joinChallenge.participantChannel);
    const viewer_channel = useSelector(state => state.joinChallenge.viewerChannel);

    const [showLeaderBoardModal, setShowLeaderBoardModal] = useState(false);
    const [leaderBoardData, setLeaderBoardData] = useState([]);
    const [showLeaderBoardAlertModal, setShowLeaderBoardAlertModal] = useState(false);
    const [showQuestionTimeAlertModal, setShowQuestionTimeAlertModal] = useState(false);
    const [is_showLeaderBoard_timer1, setIsShowLeaderBoardTimer1] = useState(true);
    const [is_showLeaderBoard_timer2, setIsShowLeaderBoardTimer2] = useState(true);
    const [showOtherTeamsModal, setShowOtherTeamsModal] = useState(false);
    const [showQuestionTimer, setShowQuestionTimer] = useState(true);
    const [allTeams, setAllTeams] = useState([]);
    const option_labels = [
        "A",
        "B",
        "C",
        "D",
        "E"
    ];
    const segColors = [
        '#aec7e8',
        '#ffbb78',
        '#98df8a',
        '#ff9896',
        '#c5b0d5',
        '#c49c94',
        '#f7b6d2',
        '#c7c7c7',
        '#dbdb8d',
        '#9edae5'
    ];
    const img_extensions = [
        'png',
        'jpg',
        'jpeg',
        'gif',
        'tiff'
    ];
    const video_extensions = [
        'mp4',
        'mov',
        'wmv',
        'avi',
        'flv',
        'f4v',
        'swf',
        'mkv'
    ];
    const audio_extensions = [
        'mp3',
        'aac',
        'flac',
        'alac',
        'wac',
        'aiff',
        'dsd',
        'pcm'
    ];

    const handleNextButton = async () => {
        //hide timer
        handleTimerComplete();
        console.log("question number : ", location.state.question_index, location.state.challenge_details.no_of_questions);
        if (location.state.is_random === 0) {
            if (location.state.question_index + 1 < location.state.challenge_details.no_of_questions) {
                setIsShowLeaderBoardTimer1(true);
                setIsShowLeaderBoardTimer2(true);
                handleLeaderBoardModalShow();
                const current_time = new Date().getTime();
                channel.publish("leader_board", `${current_time}_${location.state.question_index}_${location.state.question_data.id}`);
                let viewer_question = location.state.question_data;
                viewer_question["current_pos"] = location.state.question_index;
                viewer_question["timer_ts"] = current_time;
                viewer_channel.publish("question", JSON.stringify(viewer_question));
            } else {
                const leaderboard = await getLeaderBoard(location.state.weekly_challenge_id, location.state.question_data.id);
                const current_time = new Date().getTime();
                channel.publish("final_leader_board", `${current_time}_${location.state.question_index}_${location.state.question_data.id}`);
                console.log("showing viewer question last 1");
                let viewer_question = location.state.question_data;
                viewer_question["current_pos"] = location.state.question_index;
                viewer_question["timer_ts"] = current_time;
                viewer_channel.publish("question", JSON.stringify(viewer_question));
                const status = setWeeklyChallengeStatus(location.state.weekly_challenge_id, 1);
                console.log(status.data);
                //setting viewer chat msg
                let chatmessages = JSON.stringify(messages);
                let chatmessagesJsonB64 = Buffer.from(chatmessages).toString("base64");
                const insertMsgs = await setAllViewersChat(location.state.weekly_challenge_id, chatmessagesJsonB64);
                console.log("viewer chat set");
                navigate('/finalLeaderBoard', { state: { leaderBoard_data: leaderboard.data, current_question_id: location.state.question_data.id, question_index: location.state.question_index, challenge_details: location.state.challenge_details } });
            }
        } else {
            if ((location.state.question_index + 1) < location.state.challenge_details.no_of_questions) {
                setIsShowLeaderBoardTimer1(true);
                setIsShowLeaderBoardTimer2(true);
                handleLeaderBoardModalShow();
                const current_time = new Date().getTime();
                channel.publish("leader_board", `${current_time}_${location.state.question_index}_${location.state.question_data[location.state.question_index].id}`);
                let viewer_question = location.state.question_data[location.state.question_index];
                viewer_question["current_pos"] = location.state.question_index;
                viewer_question["timer_ts"] = current_time;
                viewer_channel.publish("question", JSON.stringify(viewer_question));
            } else {
                const leaderboard = await getLeaderBoard(location.state.weekly_challenge_id, location.state.question_data[location.state.question_index].id);
                const current_time = new Date().getTime();
                channel.publish("final_leader_board", `${current_time}_${location.state.question_index}_${location.state.question_data[location.state.question_index].id}`);
                let viewer_question = location.state.question_data[location.state.question_index];
                viewer_question["current_pos"] = location.state.question_index;
                viewer_question["timer_ts"] = current_time;
                viewer_channel.publish("question", JSON.stringify(viewer_question));
                const status = setWeeklyChallengeStatus(location.state.weekly_challenge_id, 1);
                console.log(status.data);
                //setting viewer chat msg
                let chatmessages = JSON.stringify(messages);
                let chatmessagesJsonB64 = Buffer.from(chatmessages).toString("base64");
                const insertMsgs = await setAllViewersChat(location.state.weekly_challenge_id, chatmessagesJsonB64);
                console.log("viewer chat set");
                navigate('/finalLeaderBoard', { state: { leaderBoard_data: leaderboard.data, current_question_id: location.state.question_data[location.state.question_index].id, question_index: location.state.question_index, challenge_details: location.state.challenge_details } });
            }
        }

    };

    const handleShowNextQuestion = async () => {
        console.log(location.state.challenge_details);
        if (location.state.question_index + 1 < location.state.challenge_details.no_of_questions) {
            if (location.state.is_random === 0) {
                handleNextQuestion();
            } else {
                if (location.state.question_index < location.state.question_data.length - 1) {
                    handleNextRandomQuestion();
                } else {
                    const leaderboard = await getLeaderBoard(location.state.weekly_challenge_id, location.state.question_data[location.state.question_index].id);
                    const current_time = new Date().getTime();
                    channel.publish("final_leader_board", `${current_time}_${location.state.question_index}_${location.state.question_data[location.state.question_index].id}`);
                    console.log("showing viewer question last 3");
                    let viewer_question = location.state.question_data[location.state.question_index];
                    viewer_question["current_pos"] = location.state.question_index;
                    viewer_question["timer_ts"] = current_time;
                    viewer_channel.publish("question", JSON.stringify(viewer_question));
                    const status = setWeeklyChallengeStatus(location.state.weekly_challenge_id, 1);
                    //setting viewer chat msg
                    let chatmessages = JSON.stringify(messages);
                    let chatmessagesJsonB64 = Buffer.from(chatmessages).toString("base64");
                    const insertMsgs = await setAllViewersChat(location.state.weekly_challenge_id, chatmessagesJsonB64);
                    console.log("viewer chat set");
                    navigate('/finalLeaderBoard', { state: { leaderBoard_data: leaderboard.data, question_index: location.state.question_index, current_question_id: location.state.question_data[location.state.question_index].id, challenge_details: location.state.challenge_details } });
                }
            }
        } else {
            if (location.state.is_random === 0) {
                const leaderboard = await getLeaderBoard(location.state.weekly_challenge_id, location.state.question_data.id);
                const current_time = new Date().getTime();
                channel.publish("final_leader_board", `${current_time}_${location.state.question_index}_${location.state.question_data.id}`);
                let viewer_question = location.state.question_data;
                viewer_question["current_pos"] = location.state.question_index;
                viewer_question["timer_ts"] = current_time;
                viewer_channel.publish("question", JSON.stringify(viewer_question));
                const status = setWeeklyChallengeStatus(location.state.weekly_challenge_id, 1);
                console.log(status.data);
                //setting viewer chat msg
                let chatmessages = JSON.stringify(messages);
                let chatmessagesJsonB64 = Buffer.from(chatmessages).toString("base64");
                const insertMsgs = await setAllViewersChat(location.state.weekly_challenge_id, chatmessagesJsonB64);
                console.log("viewer chat set");
                navigate('/finalLeaderBoard', { state: { leaderBoard_data: leaderboard.data, question_index: location.state.question_index, current_question_id: location.state.question_data.id, challenge_details: location.state.challenge_details } });
            } else {
                const leaderboard = await getLeaderBoard(location.state.weekly_challenge_id, location.state.question_data[location.state.question_index].id);
                const current_time = new Date().getTime();
                channel.publish("final_leader_board", `${current_time}_${location.state.question_index}_${location.state.question_data[location.state.question_index].id}`);
                let viewer_question = location.state.question_data[location.state.question_index];
                viewer_question["current_pos"] = location.state.question_index;
                viewer_question["timer_ts"] = current_time;
                viewer_channel.publish("question", JSON.stringify(viewer_question));
                const status = setWeeklyChallengeStatus(location.state.weekly_challenge_id, 1);
                //setting viewer chat msg
                let chatmessages = JSON.stringify(messages);
                let chatmessagesJsonB64 = Buffer.from(chatmessages).toString("base64");
                const insertMsgs = await setAllViewersChat(location.state.weekly_challenge_id, chatmessagesJsonB64);
                console.log("viewer chat set");
                navigate('/finalLeaderBoard', { state: { leaderBoard_data: leaderboard.data, question_index: location.state.question_index, current_question_id: location.state.question_data[location.state.question_index].id, challenge_details: location.state.challenge_details } });
            }
        }
    }

    const handleNextQuestion = async () => {
        const weekly_challenge_details = await getWeeklyChallengeData(location.state.weekly_challenge_id);
        // const category_ids = weekly_challenge_details.data.category_ids;
        // const degree_ids = weekly_challenge_details.data.college_university_degree_department_id;
        // const topics = await getTopics(category_ids, degree_ids);
        let topic_list = [];
        let color_list = [];

        for (let i = 0, j = 0; i < weekly_challenge_details.data.topics.length && j < segColors.length; i++, j++) {
            const topic_name = weekly_challenge_details.data.topics[i].name;
            topic_list.push(topic_name);
            const color_id = segColors[j];
            color_list.push(color_id);
            if (j === segColors.length - 1) {
                j = -1;
            }
        }

        if (weekly_challenge_details.data.topics) {
            //channel.publish("topic_selection_on", JSON.stringify(topics.data));
            const current_time = new Date().getTime();
            viewer_channel.publish("leader_board", `${current_time}_${location.state.question_index}_${location.state.question_data.id}`);

            navigate('/spinWheel', {
                state: {
                    allTopics: topic_list, segColors: color_list, topicsData: weekly_challenge_details.data.topics,
                    challenge_id: location.state.weekly_challenge_id, challenge_details: location.state.challenge_details,
                    question_index: location.state.question_index + 1, topic_to_select: "not_random", topic_to_select_id: null, viewer_topic_on: 0
                }
            });
        } else {
            console.log("No topics data found!");
        }
    };

    const handleNextRandomQuestion = async () => {
        const weekly_challenge_details = await getWeeklyChallengeDetails(location.state.weekly_challenge_id);
        console.log(weekly_challenge_details);
        const category_ids = weekly_challenge_details.data.category_ids;
        const degree_ids = weekly_challenge_details.data.college_university_degree_department_id;
        const topic_to_select_id = location.state.challenge_details.questions[location.state.question_index].topic_id;
        const topics = await getTopics(category_ids, degree_ids);
        let topic_list = [];
        let color_list = [];
        let topic_to_select;
        console.log(topic_to_select_id);
        console.log(topics.data);
        for (let i = 0, j = 0; i < topics.data.length && j < segColors.length; i++, j++) {
            const topic_name = topics.data[i].name;
            topic_list.push(topic_name);
            if (topics.data[i].id === topic_to_select_id) {
                topic_to_select = topic_name;
            }
            const color_id = segColors[j];
            color_list.push(color_id);
            if (j === segColors.length - 1) {
                j = -1;
            }
        }

        if (topics) {
            //channel.publish("topic_selection_on", JSON.stringify(topics.data));
            const current_time = new Date().getTime();
            viewer_channel.publish("leader_board", `${current_time}_${location.state.question_index}_${location.state.question_data[location.state.question_index].id}`);

            console.log(topic_to_select, topic_to_select_id);
            navigate('/spinWheel', {
                state: {
                    allTopics: topic_list, segColors: color_list, topicsData: topics.data,
                    challenge_id: location.state.weekly_challenge_id, challenge_details: location.state.challenge_details, question_index: location.state.question_index + 1,
                    topic_to_select: topic_to_select, topic_to_select_id: topic_to_select_id, viewer_topic_on: 0
                }
            });
        } else {
            console.log("No topics data found!");
        }
    };

    const handleTimerComplete = () => {
        console.log("completed time");
        setShowQuestionTimer(false);
        // handleNextButton();
    };

    const handleLeaderBoardModalShowButton = () => {
        setIsShowLeaderBoardTimer1(false);
        setIsShowLeaderBoardTimer2(false);
        handleLeaderBoardModalShow();
    };

    //showing and hiding leaderboard
    const handleLeaderBoardModalClose = () => setShowLeaderBoardModal(false);
    const handleLeaderBoardModalShow = async () => {
        let leaderboard;
        if (location.state.is_random === 0) {
            console.log(location.state.question_data.id);
            leaderboard = await getLeaderBoard(location.state.weekly_challenge_id, location.state.question_data.id);
        } else {
            console.log(location.state.question_data[location.state.question_index].id);
            leaderboard = await getLeaderBoard(location.state.weekly_challenge_id, location.state.question_data[location.state.question_index].id);
        }
        console.log(location.state.weekly_challenge_id);
        console.log(leaderboard.data);

        if (leaderboard.data) {
            setLeaderBoardData([]);
            setLeaderBoardData(leaderboard.data);
            setShowLeaderBoardModal(true);
        } else {
            setShowLeaderBoardAlertModal(true);
        }
    };
    const handleLeaderBoardAlertModalClose = () => setShowLeaderBoardAlertModal(false);
    const handleQuestionTimeAlertModalClose = () => setShowQuestionTimeAlertModal(false);

    const handleLeaderBoardTimer = () => {
        handleShowNextQuestion();
    };

    const removeTags = (str) => {
        if (str === null || str === "") return false;
        else str = str.toString();

        return str.replace(/<[^>]*>/g, "");
    }

    const handleShowOtherTeamsModalOpen = async () => {
        setShowOtherTeamsModal(true);
        const allteams = await getAllTeams(location.state.weekly_challenge_id);
        setAllTeams(allteams.data);
    }
    const handleShowOtherTeamsModalClose = () => setShowOtherTeamsModal(false);

    const handleAdminNextLeaderBoard = () => {
        setShowLeaderBoardModal(true);
    };
    console.log("leader: ", showLeaderBoardModal);
    console.log("field type", location.state.question_data.field_type);
    return (
        <div className="row w-100">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="challenge-questionnaire-div">
                    <Modal show={showLeaderBoardModal} onHide={is_showLeaderBoard_timer1 ? handleAdminNextLeaderBoard : handleLeaderBoardModalClose} scrollable dialogClassName="challengeleaderboardmodalWidth">
                        {/* <Modal.Header
                            {
                            ...!is_showLeaderBoard_timer1 && ("closeButton")
                            }>
                            <Modal.Title>Arena LeaderBoard</Modal.Title>
                        </Modal.Header> */}
                        <Modal.Body style={{ background: "linear-gradient(to right, rgb(253, 243, 194), rgb(255, 194, 213))" }}>
                            <div className="row">
                                <div className="col-lg-11 col-md-11 col-sm-10">
                                    <h3 style={{ textAlign: "center", color: "red" }}>LeaderBoard</h3>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-2">
                                    {
                                        is_showLeaderBoard_timer1 && (
                                            <div style={{ marginLeft: '10px', marginBottom: '10px', float: 'right' }}>
                                                <ReactCountdownClock seconds={45}
                                                    color="red"
                                                    size={60}
                                                    showMilliseconds={false}
                                                    weight={6}
                                                    alpha={0.9}
                                                    onComplete={handleLeaderBoardTimer} />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div style={{ height: "450px", overflowY: "auto", overflowX: "hidden" }}>
                                {
                                    location.state.is_random === 0
                                        ? (<LeaderBoard data={leaderBoardData} current_question_id={location.state.question_data.id} question_index={location.state.question_index} myTeam_id="" user_type="admin" type="running" challenge_details={location.state.challenge_details} />)
                                        : (<LeaderBoard data={leaderBoardData} current_question_id={location.state.question_data[location.state.question_index].id} question_index={location.state.question_index} myTeam_id="" user_type="admin" type="running" challenge_details={location.state.challenge_details} />)
                                }
                            </div>
                            {
                                location.state.challenge_details.participation_type === 1
                                    ? (
                                        <div className="row" style={{ marginTop: "-50px" }}>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div style={{ marginLeft: "25px" }}>
                                                    <RiTeamLine className="teams-icon" onClick={handleShowOtherTeamsModalOpen} style={{ cursor: 'pointer' }} /><br />
                                                    <span>Other Teams</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : ('')
                            }
                        </Modal.Body>
                        {
                            !is_showLeaderBoard_timer1 && (
                                <Modal.Footer style={{ background: "linear-gradient(to right, rgb(253, 243, 194), rgb(255, 194, 213))" }}>
                                    <Button variant="secondary" onClick={handleLeaderBoardModalClose} style={{ width: '15%', borderRadius: "25px" }}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            )
                        }
                    </Modal>
                    <Modal show={showLeaderBoardAlertModal} onHide={handleLeaderBoardAlertModalClose} scrollable dialogClassName="challengeleaderboardAlertmodalWidth">
                        <Modal.Header closeButton>
                            <Modal.Title>Arena LeaderBoard</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                is_showLeaderBoard_timer2 && (
                                    <div className="row">
                                        <div className="col-lg-11 col-md-11 col-sm-10">
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-sm-2">
                                            <div style={{ marginLeft: '10px', marginBottom: '10px', float: 'right' }}>
                                                <ReactCountdownClock seconds={45}
                                                    color="red"
                                                    size={60}
                                                    weight={6}
                                                    alpha={0.9}
                                                    showMilliseconds={false}
                                                    onComplete={handleLeaderBoardTimer} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <h5 style={{ textAlign: 'center' }}>This Arena has no LeaderBoard data!</h5>
                        </Modal.Body>
                    </Modal>

                    <Modal show={showQuestionTimeAlertModal} onHide={handleQuestionTimeAlertModalClose} scrollable dialogClassName="challengeQuestionTimermodalWidth">
                        <Modal.Header closeButton>
                            <Modal.Title>Alert </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <span className="d-flex justify-content-center" id="no-coordinators-msg-label">Time up. please change the question!</span>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleQuestionTimeAlertModalClose} id="closeTimerModalBtn" style={{ width: '25%' }}>
                                Okay
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showOtherTeamsModal} onHide={handleShowOtherTeamsModalClose} id="otherTeamsModal" dialogClassName="otherTeamsModalWidth">
                        <Modal.Header closeButton>
                            <Modal.Title>Teams Information</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                {
                                    allTeams.length !== 0
                                        ? allTeams.map(team => (
                                            <div className="col-lg-4 col-md-4 col-sm-4">
                                                <table className="table table-bordered table-curved" id="teams-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" colSpan="3">
                                                                {team.team_name}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            team.members.length > 0
                                                                ? team.members.map(teamMember => (
                                                                    <tr>
                                                                        <td scope="row">
                                                                            {
                                                                                teamMember.faculty_id != ''
                                                                                    ? teamMember.is_captain
                                                                                        ? teamMember.faculty_name + " (C)"
                                                                                        : teamMember.is_vice_captain
                                                                                            ? teamMember.faculty_name + " (VC)"
                                                                                            : teamMember.faculty_name
                                                                                    : teamMember.is_captain
                                                                                        ? teamMember.student_name + " (C)"
                                                                                        : teamMember.is_vice_captain
                                                                                            ? teamMember.student_name + " (VC)"
                                                                                            : teamMember.student_name
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                : (
                                                                    <tr>
                                                                        <td colSpan="3">
                                                                            <span colSpan="3" className="d-flex justify-content-center" id="no-coordinators-msg-label">This Team has no members!</span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        ))
                                        : (<p style={{ textAlign: 'center' }}>There are no teams to show!</p>)
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn btn-primary shadow-sm" onClick={handleShowOtherTeamsModalClose} id="closeErrorAlertModalBtn" >
                                Close
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-8">
                            <h3 className="questionpanel-challenge-title">{location.state.challenge_details.title}</h3>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3">
                            <button className="btn btn-primary" id="showLeaderBoardBtn" onClick={handleLeaderBoardModalShowButton}>Show LeaderBoard</button>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-1">
                            <div style={{ float: 'right', marginRight: '15px', marginTop: '10px' }}>
                                {
                                    showQuestionTimer && (
                                        <ReactCountdownClock seconds={location.state.challenge_details.time_per_question}
                                            color="red"
                                            size={80}
                                            weight={6}
                                            alpha={0.9}
                                            showMilliseconds={false}
                                            onComplete={handleTimerComplete} />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "450px", overflowY: "auto", overflowX: "hidden", marginTop: "10px" }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="question-info">
                                    {
                                        location.state.is_random === 0
                                            ?
                                            (
                                                <>
                                                    <span style={{ fontSize: "0.7em", fontWeight: "500", color: "grey" }}>Question: {location.state.question_index + 1}/{location.state.challenge_details.no_of_questions}</span>
                                                    <p><strong>{removeTags(location.state.question_data.question)}</strong></p>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <span style={{ fontSize: "0.7em", fontWeight: "500", color: "grey" }}>Question: {location.state.question_index + 1}/{location.state.challenge_details.no_of_questions}</span>
                                                    <p><strong>{removeTags(location.state.question_data[location.state.question_index].question)}</strong></p>
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-1 col-md-1 col-sm-1">
                                <div><BsFillCaretDownFill className="carrotdown-icon" /></div>
                                <div className="admin-verticalline"></div>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-7" style={{ height: '360px', overflowY: 'auto', marginTop: '20px' }}>
                                {
                                    location.state.is_random === 0
                                        ? location.state.question_data.field_type === 0
                                            ? location.state.question_data.options.map((option_data, i = 0) => {
                                                return (
                                                    <div className="row">
                                                        <div className="col-lg-12 col-sm-12 col-sm-12">
                                                            <div className="options-info shadow">
                                                                <strong>{option_labels[i++]}. </strong>
                                                                {
                                                                    removeTags(option_data.option)
                                                                }
                                                            </div>
                                                            {
                                                                option_data.option_img != null
                                                                    ? (
                                                                        <div className="option-image-div">
                                                                            <img src={option_data.option_img} className="option-image" alt="option picture" />
                                                                        </div>
                                                                    )
                                                                    : ''
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : (
                                                <div style={{ width: '60%', marginLeft: '10%', marginTop: '5%' }}>
                                                    <label><strong>Enter you answer:</strong></label>
                                                    <textarea type="text" className="form-control" placeholder="write..." style={{ width: '100%', height: "100px", resize: "none" }} ></textarea>
                                                </div>
                                            )
                                        : location.state.question_data[location.state.question_index].field_type === 0
                                            ? location.state.question_data[location.state.question_index].options.map((option_data, i = 0) => {
                                                return (
                                                    <div className="row">
                                                        <div className="col-lg-12 col-sm-12 col-sm-12">
                                                            <div className="options-info shadow">
                                                                <strong>{option_labels[i++]}. </strong>
                                                                {
                                                                    removeTags(option_data.option)
                                                                }
                                                            </div>
                                                            {
                                                                option_data.option_img != null
                                                                    ? (
                                                                        <div className="option-image-div">
                                                                            <img src={option_data.option_img} className="option-image" alt="option picture" />
                                                                        </div>
                                                                    )
                                                                    : ''
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : (
                                                <div style={{ width: '60%', marginLeft: '10%', marginTop: '5%' }}>
                                                    <label><strong>Enter you answer:</strong></label>
                                                    <textarea type="text" className="form-control" placeholder="write..." style={{ width: '100%', height: "100px", resize: "none" }} ></textarea>
                                                </div>
                                            )

                                }
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                {
                                    location.state.is_random === 1
                                        ? location.state.question_data[location.state.question_index].question_img !== null
                                            ? (
                                                <div className="question-image-div" style={{ height: '350px', overflowY: 'auto', width: '100%', marginTop: '10px' }}>
                                                    {
                                                        img_extensions.includes(location.state.question_data[location.state.question_index].question_img.split('.').pop().toLowerCase())
                                                            ? (
                                                                <img src={location.state.question_data.question_img} className="question-image" alt="question picture" />
                                                            )
                                                            : video_extensions.includes(location.state.question_data[location.state.question_index].question_img.split('.').pop().toLowerCase())
                                                                ? (
                                                                    <video className="question-image" alt="question video" controls>
                                                                        <source src={location.state.question_data[location.state.question_index].question_img} type={`video/${location.state.question_data[location.state.question_index].question_img.split('.').pop().toLowerCase()}`} loop={true} />
                                                                    </video>
                                                                )
                                                                : (
                                                                    <audio controls style={{ marginTop: "25%" }}>
                                                                        {
                                                                            location.state.question_data[location.state.question_index].question_img.split('.').pop().toLowerCase() === "mp3"
                                                                                ? (<source src={location.state.question_data[location.state.question_index].question_img} type="audio/mpeg" />)
                                                                                : (<source src={location.state.question_data[location.state.question_index].question_img} type={`audio/${location.state.question_data[location.state.question_index].question_img.split('.').pop().toLowerCase()}`} />)
                                                                        }
                                                                        Your browser does not support the audio element.
                                                                    </audio>
                                                                )
                                                    }
                                                </div>
                                            )
                                            : ''
                                        : location.state.question_data.question_img !== null
                                            ? (
                                                <div className="question-image-div" style={{ height: '350px', overflowY: 'auto', width: '100%', marginTop: '10px' }}>
                                                    {
                                                        img_extensions.includes(location.state.question_data.question_img.split('.').pop().toLowerCase())
                                                            ? (
                                                                <img src={location.state.question_data.question_img} className="question-image" alt="question picture" />
                                                            )
                                                            : video_extensions.includes(location.state.question_data.question_img.split('.').pop().toLowerCase())
                                                                ? (
                                                                    <video className="question-image" alt="question video" controls>
                                                                        <source src={location.state.question_data.question_img} type={`video/${location.state.question_data.question_img.split('.').pop().toLowerCase()}`} loop={true} />
                                                                    </video>
                                                                )
                                                                : (
                                                                    <audio controls style={{ marginTop: "25%" }}>
                                                                        {
                                                                            location.state.question_data.question_img.split('.').pop().toLowerCase() === "mp3"
                                                                                ? (<source src={location.state.question_data.question_img} type="audio/mpeg" />)
                                                                                : (<source src={location.state.question_data.question_img} type={`audio/${location.state.question_data.question_img.split('.').pop().toLowerCase()}`} />)
                                                                        }
                                                                        Your browser does not support the audio element.
                                                                    </audio>
                                                                )
                                                    }
                                                </div>
                                            )
                                            : ''

                                }
                            </div>
                        </div>
                    </div>
                    {/* <hr /> */}
                    <div className="row" style={{ marginTop: "30px" }}>
                        <div className="col-sm-12 col-lg-12 col-sm-12">
                            {
                                location.state.challenge_details.participation_type === 1
                                    ? (

                                        <div style={{ marginLeft: "3.5%", marginBottom: "-30px", marginTop: "-5px" }}>
                                            <RiTeamLine className="teams-icon" onClick={handleShowOtherTeamsModalOpen} style={{ cursor: 'pointer', fontSize: "1.75em" }} /><br />
                                            <span style={{ fontSize: "0.9em" }}>All Teams</span>
                                        </div>

                                    )
                                    : ('')
                            }
                            {
                                location.state.is_random === 0
                                    ? (location.state.question_index + 1) < location.state.challenge_details.no_of_questions
                                        ? (
                                            <button className="btn btn-primary" id="getnext-question-btn" onClick={handleNextButton}>
                                                Next question <FaLongArrowAltRight />
                                            </button>
                                        )
                                        : (
                                            <button className="btn btn-primary" id="getnext-question-btn" onClick={handleNextButton}>
                                                End Arena <FaLongArrowAltRight />
                                            </button>
                                        )
                                    : (location.state.question_index + 1) < location.state.challenge_details.no_of_questions
                                        ? (
                                            <button className="btn btn-primary" id="getnext-question-btn" onClick={handleNextButton}>
                                                Next question <FaLongArrowAltRight />
                                            </button>
                                        )
                                        : (
                                            <button className="btn btn-primary" id="getnext-question-btn" onClick={handleNextButton}>
                                                End Arena <FaLongArrowAltRight />
                                            </button>
                                        )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuestionnairePanel;