import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  allDepartments,
  allSemesters,
  getSubjects,
  getSections,
  createsurvey,
} from "../../../api/facultyfeedbackapis";
import Button from "react-bootstrap/esm/Button";
import Select from "react-select";
import { FaPlus } from "react-icons/fa";
import { GrSend } from "react-icons/gr";
import "./Feedbackdetails.css";
import { ToastContainer, toast } from "react-toastify";
import { Oval } from 'react-loader-spinner';
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../navbar/navbar.component";
import LayoutwithSideNav from "../../common/LayoutwithSideNav";
import ModalwithButtons from "../../common/ModalwithButtons";
import { Typography } from "@mui/material";
import LoadingOverlay from 'react-loading-overlay';

const FeedbackDetails = () => {
  const [Dept, setDept] = useState([]);
  const [selectedDeptOptions, setSelectedDeptOptions] = useState();
  const [Department, setDepartment] = useState([]);
  const [deptsAndSems, setdeptsAndSems] = useState([]);
  const [subjects, setsubjects] = useState([]);
  const [selectedSubjectOptions, setSelectedSubjectOptions] = useState();
  const [selectSubjects, setselectSubjects] = useState([]);
  const [chooseSubjects, setChooseSubjects] = useState([]);
  const [sections, setsections] = useState([]);
  const [selectedSectionOptions, setSelectedSectionOptions] = useState();
  const [createsections, setcreatesections] = useState([]);
  const [chooseSections, setChooseSections] = useState([]);
  const [flagNext, setFlagNext] = useState(false);
  const [selectedSemesters, setSelectedSemesters] = useState([]);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [allSubjects, setAllSubjects] = useState([]);
  const [allSections, setAllSections] = useState([]);
  const [filteredSections, setFilteredSections] = useState([]);
  const [sectionsFlag, setSectionsFlag] = useState(false);
  const [selectedSubjected, setSelectedSubject] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isCreateLoaderActive, setIsCreateLoaderActive] = useState(false);

  const handleDepartment = async (e) => {
    const departmentlist = [{ value: "select-all", label: "Select All" }];
    // setDept(e.target.value);
    const depts = await allDepartments();
    // console.log(depts);

    for (let i = 0; i < depts.data.length; i++) {
      const id = depts.data[i].university_degree_department_id;
      const key = depts.data[i].name;
      const cudd_id = depts.data[i].college_university_degree_department_id;
      departmentlist.push({ value: id, label: key, college_university_degree_department_id: cudd_id });
    }
    setDept(departmentlist);
  };
  useEffect(() => {
    if (Dept.length === 0) {
      handleDepartment();
    }

  }, []);
  const selectDepartment = async (e) => {
        setIsShowLoader(true);
    let flag = true;
    if (e.find((option) => option.value === "select-all")) {
      setSelectedDeptOptions(Dept.filter((option) => option.value !== "select-all"));
      flag = false;
    } else {
      setSelectedDeptOptions(e);
    }
    let depList = [];
    let existingDeptsAndSems = deptsAndSems;
    let existingSelectedSems = selectedSemesters;
    let dept_ids_list = [];

    if (flag) {

      for (let i = 0; i < e.length; i++) {
        const sems = await allSemesters(e[i].value);
        let semList = [];
        for (let j = 0; j < sems.data.length; j++) {
          const key = sems.data[j];
          semList.push({ value: key, label: key });
        }
        semList = semList.sort((a, b) => a.value > b.value ? 1 : -1);
        depList.push({ "department_id": parseInt(e[i].value), "department_name": e[i].label, "college_university_degree_department_id": e[i].college_university_degree_department_id, "department_semesters": semList });
        dept_ids_list.push(parseInt(e[i].value));
      }

      //deleting from deptsAndSems
      for (let j = 0; j < deptsAndSems.length; j++) {
        if (!dept_ids_list.includes(parseInt(deptsAndSems[j].university_degree_department_id))) {
          existingDeptsAndSems = existingDeptsAndSems.filter(depSem => depSem.university_degree_department_id !== deptsAndSems[j].university_degree_department_id);
          for (let k = 0; k < deptsAndSems[j].semesters.length; k++) {
            existingSelectedSems = existingSelectedSems.filter(semid => parseInt(semid) != parseInt(deptsAndSems[j].university_degree_department_id + "" + parseInt(deptsAndSems[j].semesters[k])));
          }
        }
      }
    } else {
      e = Dept;
      for (let i = 0; i < e.length; i++) {
        if (e[i].value !== "select-all") {
          const sems = await allSemesters(e[i].value);
          let semList = [];
          for (let j = 0; j < sems.data.length; j++) {
            const key = sems.data[j];
            semList.push({ value: key, label: key });
          }
          semList = semList.sort((a, b) => a.value > b.value ? 1 : -1);
          depList.push({ "department_id": parseInt(e[i].value), "department_name": e[i].label, "college_university_degree_department_id": e[i].college_university_degree_department_id, "department_semesters": semList });
          dept_ids_list.push(parseInt(e[i].value));
        }
      }

      //deleting from deptsAndSems
      for (let j = 0; j < deptsAndSems.length; j++) {
        if (!dept_ids_list.includes(parseInt(deptsAndSems[j].university_degree_department_id))) {
          existingDeptsAndSems = existingDeptsAndSems.filter(depSem => depSem.university_degree_department_id !== deptsAndSems[j].university_degree_department_id);
          for (let k = 0; k < deptsAndSems[j].semesters.length; k++) {
            existingSelectedSems = existingSelectedSems.filter(semid => parseInt(semid) != parseInt(deptsAndSems[j].university_degree_department_id + "" + parseInt(deptsAndSems[j].semesters[k])));
          }
        }
      }
    }
        setdeptsAndSems(existingDeptsAndSems);
    setSelectedSemesters(existingSelectedSems);
    setDepartment(depList);
    setIsShowLoader(false);
    setFlagNext(false);
    setselectSubjects([]);
    setChooseSubjects([]);
  };


  const selectSem = (e, department_id, cudd_id) => {
    if (e.target.checked === true) {
      let existingData = deptsAndSems;
      let i = 0;
      for (i = 0; i < deptsAndSems.length; i++) {
        if (deptsAndSems[i].university_degree_department_id === department_id) {
          existingData[i].semesters.push(parseInt(e.target.value));
          break;
        }
      }
      if (i === deptsAndSems.length) {
        existingData.push({ "university_degree_department_id": department_id,"college_university_degree_department_id": cudd_id, "semesters": [parseInt(e.target.value)] });
      }
      setdeptsAndSems(existingData);
      setSelectedSemesters(prevData => [...prevData, parseInt(department_id + "" + e.target.value)]);
    } else {
      let existingData = deptsAndSems;
      let i = 0;
      for (i = 0; i < deptsAndSems.length; i++) {
        if (deptsAndSems[i].university_degree_department_id === department_id) {
          if (deptsAndSems[i].semesters.length > 1) {
            let existing_sems = deptsAndSems[i].semesters;
            existing_sems = existing_sems.filter(item => item !== parseInt(e.target.value));
            existingData[i].semesters = existing_sems;
          } else {
            existingData = existingData.filter(item => parseInt(item.university_degree_department_id) !== parseInt(department_id));
          }
        }
      }
      setdeptsAndSems(existingData);
      let existingSelectedSemData = selectedSemesters;
      existingSelectedSemData = existingSelectedSemData.filter(item => item != parseInt(department_id + "" + e.target.value));
      setSelectedSemesters(existingSelectedSemData);
    }

    setFlagNext(false);
    setselectSubjects([]);
    setChooseSubjects([])
  };

  const handlesubjects = async (e) => {
    const subs = await getSubjects(JSON.stringify(deptsAndSems));
    const subList = [{ value: "select-all", label: "Select All" }];
    for (let i = 0; i < subs.data.length; i++) {
      const value = subs.data[i].id;
      const label = subs.data[i].name + " - (Sem " + subs.data[i].semester_id + ")";
      subList.push({ value: value, label: label });
    }
    setFlagNext(!flagNext);
    setsubjects(subList);
    setAllSubjects(subs.data);
    handlesections();
  };

  // const handleSelectSubjects = (e) => {
  //   let flag = true;
  //   let subarray = [];
  //   if (e.find((option) => option.value === "select-all")) {
  //     setSelectedSubjectOptions(subjects.filter((option) => option.value !== "select-all"));
  //     flag = false;
  //   } else {
  //     setSelectedSubjectOptions(e);
  //   }

  //   if (flag) {
  //     for (let j = 0; j < e.length; j++) {
  //       subarray.push(e[j].value);
  //     }
  //   } else {
  //     e = subjects;
  //     for (let j = 0; j < e.length; j++) {
  //       if (e[j].value !== "select-all") {
  //         subarray.push(e[j].value);
  //       }
  //     }
  //   }

  //   //handlesections();
  //   setselectSubjects(subarray);
  // };
  const handleSelectSubjects = (e,optional) => {
    console.log(e.target.checked)
    setselectSubjects((prevSubjects) => {
      let existingSubjs = [...prevSubjects];
      if (e.target.checked === true) {
        console.log(e.target.value);
        console.log("in");
        existingSubjs.push(parseInt(e.target.value));

        //setting sections
        let sections = allSections;
        let sectionsToFilter = [];
        if(sections.length > 0){
          for(let i=0;i<sections.length;i++){
            if(sections[i].subjects.includes(parseInt(e.target.value))){
              sectionsToFilter.push(sections[i]);
            }
          }
        }
        setFilteredSections(sectionsToFilter);
        setSectionsFlag(true);
        setSelectedSubject(parseInt(e.target.value));
      } else {
        existingSubjs = existingSubjs.filter(id => id !== parseInt(e.target.value));
        setFilteredSections([]);
        setSectionsFlag(false);
      }
      console.log(existingSubjs);
            return existingSubjs;
    });

    setChooseSubjects((prevSubjects) => {
      let existingSubjs = [...prevSubjects];
    
      if (e.target.checked === true) {
        console.log(e.target.value);
        console.log("in");
        // Assuming e.target.value is an object { cudd_id: someValue, id: someOtherValue }
        existingSubjs.push({"optional":optional, "id":e.target.value});
      } else {
        // Assuming e.target.value is an object { cudd_id: someValue, id: someOtherValue }
        existingSubjs = existingSubjs.filter(obj => parseInt(obj.id) !== parseInt(e.target.value));
      }
    
      console.log(existingSubjs);
      return existingSubjs;
    });
  };

  console.log("-->", selectSubjects);
  const handlesections = async (e) => {
    const sectionsresponse = await getSections(JSON.stringify(deptsAndSems));
    const sectionList = [{ value: "select-all", label: "Select All" }];
    for (let i = 0; i < sectionsresponse.data.length; i++) {
      const value = sectionsresponse.data[i].id;
      const key = sectionsresponse.data[i].name;
      sectionList.push({ value: value, label: key });
    }
    setsections(sectionList);
    setAllSections(sectionsresponse.data);
  }

  // const handleSelectSections = (e) => {
  //   let flag = true;
  //   let selected_sections = [];
  //   if (e.find((option) => option.value === "select-all")) {
  //     setSelectedSectionOptions(sections.filter((option) => option.value !== "select-all"));
  //     flag = false;
  //   } else {
  //     setSelectedSectionOptions(e);
  //   }

  //   if (flag) {
  //     for (let j = 0; j < e.length; j++) {
  //       selected_sections.push(e[j].value);
  //     }
  //   } else {
  //     e = sections;
  //     for (let j = 0; j < e.length; j++) {
  //       if (e[j].value !== "select-all") {
  //         selected_sections.push(e[j].value);
  //       }
  //     }
  //   }

  //   setcreatesections(selected_sections);
  // };
  const handleSelectSections = (e, section_id,optional,subject_id,subject_optional) => {
    console.log("optional ----->",optional)
    setcreatesections((prevSections) => {
      let selected_sections = [...prevSections];
      if (e.target.checked === true) {
        selected_sections.push(`${subject_id}_${section_id}`);
      } else {
        selected_sections = selected_sections.filter(id => id !== `${subject_id}_${section_id}`);
      }
      return selected_sections;
    });

    setChooseSections((prevSections) => {
      let selected_sections = [...prevSections];
      if (e.target.checked === true) {
        selected_sections.push({"section_optional": optional, "id": parseInt(section_id), "subject_id": parseInt(subject_id), "subject_optional": subject_optional});
      } else {
        // selected_sections = selected_sections.filter(obj => parseInt(obj.id) === parseInt(section_id) && parseInt(obj.subject_id) === parseInt(subject_id));
        let updated_selected_sections = [];
        if(selected_sections.length > 0){
          for(let i=0;i<selected_sections.length;i++){
            if(selected_sections[i].id === section_id && selected_sections[i].subject_id === subject_id){
              continue;
            }else{
              updated_selected_sections.push(selected_sections[i]);
            }
          }
        }
        selected_sections = updated_selected_sections;
      }
      return selected_sections;
    });
  };
  console.log("sections => ", createsections);

  const selectAllSections = (e) => {
    if(e.target.checked){
      let sections = [];
      let selected_sections = [];
      if(allSubjects.length > 0 && allSections.length > 0){
        for(let i=0; i<allSubjects.length; i++){
          for(let j=0; j<allSections.length; j++){
            if(allSections[j].subjects.includes(parseInt(allSubjects[i].id))){
              if(!selected_sections.includes(`${allSubjects[i].id}_${allSections[j].id}`)){
                selected_sections.push(`${allSubjects[i].id}_${allSections[j].id}`)
                sections.push({"section_optional": allSections[j].optional, "id": parseInt(allSections[j].id), "subject_id": parseInt(allSubjects[i].id), "subject_optional": allSubjects[i].optional})
              }
            }
          }
        }
      }
      setChooseSections(sections);
      setcreatesections(selected_sections);
    }else{
      setChooseSections([]);
      setcreatesections([]);
    }
  }

  const handlecreatesurvey = async (e) => {
    //classifying sections and subjects
    let sections = [];
    let subjects = [];
    let sections_appended = [];
    let subjects_appended = [];
    if(chooseSections.length > 0){
      for(let i = 0; i < chooseSections.length ; i++){
        if(!sections_appended.includes(parseInt(chooseSections[i].id))){
          sections_appended.push(parseInt(chooseSections[i].id));
          sections.push({"id": chooseSections[i].id, "optional": chooseSections[i].section_optional});
        }
        if(!subjects_appended.includes(parseInt(chooseSections[i].subject_id))){
          subjects_appended.push(parseInt(chooseSections[i].subject_id));
          subjects.push({"id": chooseSections[i].subject_id, "optional": chooseSections[i].subject_optional})
        }
      }
    }
    console.log("sections -> ",sections);
    console.log("subjects -> ",subjects);
   
    closeConfirmationModal();
    if (sections.length === 0 || location.state.startTime === "" || location.state.endTime === "" || location.state.scale === "" || subjects.length === 0 || Department.length === 0) {
      toast.error("Please enter all details", {
        position: "top-right",
      });
    }
    else {
      setIsCreateLoaderActive(true);
      const create = await createsurvey(sections, location.state.startTime, location.state.endTime, location.state.scale, 1, location.state.selectedquestions, location.state.title, location.state.Description, subjects, location.state.ratingScale);
      if(parseInt(create.status) == 200){
        setTimeout(() => {
          setIsCreateLoaderActive(false);
          navigate("/submit");
        },2000);
      }else{
        setTimeout(() => {
          setIsCreateLoaderActive(false);
          toast.error(`Some error occured, Please try again! - ${create.message} - ${create.data}`, {
            position: "top-right",
          });
        },2000);
      }
    }
  }

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  }

  const openShowConfirmationModal = () => {
    setOpenConfirmationModal(true);
  }

  return (
    <>
      <div className="feedback-sel-questions-page">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Navbar />
          </div>
        </div>
      </div>
      <div className="container-fluid px-0">
        <LayoutwithSideNav>
        <LoadingOverlay
            active={isCreateLoaderActive}
            spinner
            text='Creating Feedbacks, Please wait!'
            >
          <div className="feedback-choose-timings-div">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10">
                <h2 className="mt-1">
                  <p className="font-weight-bold" id="courseName">{location.state.title}</p>
                </h2>
                <hr className="titlehr" />
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <Button id="createSurveyBtn" className="btn btn-primary shadow"
                  disabled={chooseSections.length === 0 || Department.length === 0}
                  onClick={openShowConfirmationModal}>
                  Send
                  &nbsp;<GrSend />
                </Button>
              </div>
            </div>
          </div>
          {
            openConfirmationModal 
              ? (
                  <ModalwithButtons
                      open={openConfirmationModal}
                      onClose={closeConfirmationModal}
                      submitLabel={"Continue"}
                      onSubmit={handlecreatesurvey}
                      title={"Confirmation Alert"}
                      submitButtonType="primary"
                      errorMessage=""
                  >
                      <Typography variant="body1">Are you sure, Do you want to send the feedback?</Typography>
                  </ModalwithButtons>
              )
                : ""
            }         

          <div className="container-fluid">
            <div className="form-group createFeedbackDetails mb-5">
              <div className="dept-details-div-label">
                <label className="col-form-label col-form-label-lg" style={{ fontsize: "1.15rem" }}>Select Departments and Semesters<span
                  style={{ color: "red" }}>*</span></label>
                <Select
                  className="dropdown"
                  name="category"
                  classNamePrefix="select"
                  onChange={selectDepartment}
                  options={Dept}
                  value={selectedDeptOptions}
                  isMulti
                ></Select>

              </div>
              {
                Department.length !== 0
                  ? (
                    <>
                      <div className="row mt-4 department-sem-labels">
                        <div className="col-lg-4 col-md-4 col-sm-4">
                          <label>Department Name</label>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-8">
                          <label>Select Semesters</label>
                        </div>
                      </div>
                      <hr />
                    </>
                  )
                  : null
              }
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  {
                    Department.map((department) => (
                      <div className="department-card">
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-sm-4">
                            <div className="col-lg-12 col-md-12 col-sm-12 department-card_name">
                              <strong>{department.department_name}</strong>
                            </div>
                          </div>
                          {
                            department.department_semesters.length > 0
                              ? department.department_semesters.map(sem => (
                                <div className="col-lg-1 col-md-1 col-sm-1">
                                  <div className="department_sem-div">
                                    <label className={selectedSemesters.includes(parseInt(department.department_id + "" + sem.value)) ? "department_sem-card shadow semester-selected" : "department_sem-card shadow"} >{sem.label}
                                      <input value={sem.value} className="semesterCheckBox" defaultChecked={selectedSemesters.includes(parseInt(department.value + "" + sem.value)) ? true : false} type='checkbox' style={{ display: 'none' }} name='selectSemester'
                                        onChange={(e) => selectSem(e, department.department_id, department.college_university_degree_department_id)} /></label>
                                  </div>
                                </div>
                              ))
                              : ("")
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  {flagNext === false ? (
                    <Button id="addSubjectsBtn" onClick={handlesubjects}>
                      <FaPlus style={{ marginBottom: "5px" }} />&nbsp; Select Subjects
                    </Button>
                  ) : null}
                </div>
              </div>

              {/* <div className="row mt-4">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    {flagNext === true ? (
                      <div className="dept-details-div-label">
                        <label className="col-form-label col-form-label-lg" for="select-subject-objective" style={{ fontsize: "1.15rem" }}>Select Subjects<span
                          style={{ color: "red" }}>*</span></label>
                        <Select
                          classNamePrefix="select"
                          onChange={handleSelectSubjects}
                          isMulti
                          options={subjects}
                          value={selectedSubjectOptions}
                          name="sem"
                        ></Select>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div>
                      {selectSubjects.length >= 1 ? (
                        <div className="dept-details-div-label">
                          <label className="col-form-label col-form-label-lg" for="select-sections-objective">Select Sections<span
                            style={{ color: "red" }}>*</span></label>
                          <Select
                            classNamePrefix="select"
                            onChange={handleSelectSections}
                            isMulti
                            options={sections}
                            value={selectedSectionOptions}
                            name="sem"
                          ></Select>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div> */}

              <div className="row mt-4">
                {flagNext === true ? (
                  <div className="dept-details-div-label">
                    <label className="col-form-label col-form-label-lg" for="select-subject-objective" style={{ fontsize: "1.15rem" }}>
                      Select Subjects and Sections<span style={{ color: "red" }}>*</span></label>
                    
                    <input type="checkbox" name="selectAllSections" onChange={selectAllSections} style={{marginLeft: '5px', width: '17px', height: '17px', float: 'right', marginTop: '14px', marginRight: '15px'}} />
                    <span style={{fontWeight: 'bold', marginLeft: '10px', textDecoration: 'underline', float: 'right', marginTop: '10px',}}>Select all: </span>
                    {/* <div className="row">
                      {
                        Department.map(department => (
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="department-name mt-3">
                              <strong style={{ fontSize: "17px" }}>{department.department_name}</strong>
                            </div>
                            <div className="select-subjects-div scrollable">
                              <label style={{fontWeight:"550"}}>Select Subjects:</label>
                              <div className="row">
                                {
                                  allSubjects.map(subject => {
                                    if (subject.university_degree_department_id === department.department_id) {
                                      return (
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                          <label className={selectSubjects.includes(parseInt(subject.id)) ? "select-subject-label shadow subject-selected" : "shadow select-subject-label"}
                                            style={{border: parseInt(selectedSubjected) === parseInt(subject.id) ? '2px solid #FFDFD6' : ""}}
                                          >
                                            <input type="checkbox" value={subject.id} style={{ display: "none" }}
                                              onChange={(e) => handleSelectSubjects(e,subject.optional)} defaultChecked={selectSubjects.includes(parseInt(subject.id)) ? true : false} />
                                            {subject.name} - (Sem {subject.semester_id})</label>
                                        </div>
                                      )
                                    }
                                  })
                                }
                              </div>
                            </div>
                            <div className="select-sections-div scrollable">
                              <label style={{fontWeight:"550"}}>Select Sections:</label>
                              <div className="row">
                                {
                                  sectionsFlag === true
                                  ? filteredSections.length > 0
                                    ? filteredSections.map(section => {
                                        if (section.university_degree_department_id === department.department_id) {
                                          return (
                                            <div className="col-lg-4 col-md-4 col-sm-4">
                                              <label className={createsections.includes(parseInt(section.id)) ? "select-section-label shadow section-selected" : "select-section-label shadow"}>
                                                <input type="checkbox" value={section.id} style={{ display: "none" }}
                                                  onChange={(e) => handleSelectSections(e, section.id,section.optional)} defaultChecked={createsections.includes(parseInt(section.id)) ? true : false} />
                                                {section.name} - (Sem {section.semester_id})</label>
                                            </div>
                                          )
                                        }
                                      })
                                    : (<p style={{textAlign:'center',marginTop:'10px'}}>No Sections to this subject!</p>) 
                                  : (<p style={{textAlign:'center',marginTop:'10px'}}>Select subject to see sections!</p>)
                                  
                                }
                              </div>
                            </div>
                            <hr />
                          </div>
                        ))
                      }
                    </div> */}
                    {/* {
                      Department.length > 0
                      ? (
                        <>
                            <div className="row mt-4 department-sem-labels">
                              <div className="col-lg-4 col-md-4 col-sm-4">
                                <label>Subject Name</label>
                              </div>
                              <div className="col-lg-8 col-md-8 col-sm-8">
                                <label>Select Sections</label>
                              </div>
                            </div>
                            <hr />
                        </>
                      )
                      : null
                    } */}
                    {
                      Department.length > 0
                      ? Department.map(department => (
                        <>
                          <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                              <div className="departmentName mt-1">
                                <strong style={{ fontSize: "17px" }}>{department.department_name}</strong>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2 department-sem-labels">
                              <div className="col-lg-4 col-md-4 col-sm-4">
                                <label>Subject Name</label>
                              </div>
                              <div className="col-lg-8 col-md-8 col-sm-8">
                                <label>Select Sections</label>
                              </div>
                            </div>
                            <hr />
                          {
                            allSubjects.map(subject => {
                              if (subject.university_degree_department_id === department.department_id) {
                                return (
                                  <>
                                  <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-4">
                                      <label id="select-subject-label2">
                                        {/* <input type="checkbox" value={subject.id} style={{ display: "none" }}
                                          onChange={(e) => handleSelectSubjects(e,subject.optional)} defaultChecked={selectSubjects.includes(parseInt(subject.id)) ? true : false} /> */}
                                        {subject.name} - (Sem {subject.semester_id})</label>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-sm-8">
                                      <div className="row">
                                        {
                                          allSections.length > 0
                                            ? allSections.map(section => {
                                              if(section.subjects.includes(parseInt(subject.id))){
                                                console.log(subject.name,  subject.university_degree_department_id)
                                                if (section.semester_id === subject.semester_id) {
                                                  return (
                                                    <div className="col-lg-3 col-md-3 col-sm-3">
                                                      {/* <div className="select-section-label">{section.name} - (Sem {section.semester_id})</div> */}
                                                      <label className={createsections.includes(`${subject.id}_${section.id}`) ? "select-section-label shadow section-selected" : "select-section-label shadow"}
                                                        style={{fontSize: '15px', minHeight: '60px'}}
                                                      >
                                                        <input type="checkbox" value={section.id} style={{ display: "none" }}
                                                          onChange={(e) => handleSelectSections(e, section.id,section.optional, subject.id, subject.optional)} defaultChecked={createsections.includes(parseInt(section.id)) ? true : false} />
                                                        {section.name} - (Sem {section.semester_id})</label>
                                                    </div>
                                                  )
                                                }
                                              }
                                            })
                                          : <span style={{fontSize: '15px'}}>No Sections!</span>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  </>
                                )
                              }
                            })
                          }
                        </>
                      ))
                      : <span style={{fontSize: '15px'}}>No Subjects!</span>
                    }
                  </div>
                ) : ""}
              </div>

              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <Oval
                    height={80}
                    width={80}
                    color="#EB5C6F"
                    wrapperStyle={{ marginLeft: "30%" }}
                    wrapperClass=""
                    visible={isShowLoader}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={3}
                    strokeWidthSecondary={2}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>

            </div>
          </div>


          {/* <div className="container-fluid">
              <div className="row">
                <div className="col-lg-10 col-md-10 col-sm-10"></div>
                <div className="col-lg-2 col-md-2 col-sm-2">
                  <Button id="createSurveyBtn" className="btn btn-primary shadow"
                    disabled={createsections.length === 0 || selectSubjects.length === 0 || Department.length === 0}
                    onClick={handlecreatesurvey}>
                    Send
                    &nbsp;<GrSend />
                  </Button>
                </div>
              </div>
            </div> */}
          <ToastContainer />
          </LoadingOverlay>
        </LayoutwithSideNav>
        
      </div >
    </>
  );
};
export default FeedbackDetails;
