import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Ably from 'ably/promises';
import Modal from 'react-bootstrap/Modal';
import LeaderBoard from "../../leaderBoard/leaderBoard.component";
import { getLeaderBoard, enrollWeeklyChallenge, getWeeklyChallengeDetails, getMyTeam } from "../../../api/weeklyChallengeAPIs";
import Countdowntimer from "../../countdownTimer/Countdowntimer.component";
import { current_user_id } from "../../../api/weeklyChallengeAPIs";
import "./userChallengeLobby.styles.css";
import { openNewAblyConnection } from "../../../store/actions/joinchallengeactions";

const accessToken = localStorage.getItem("token");
//const accessToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMTc1LCJlbWFpbCI6ImFuaWxAZWR3aXNlbHkuY29tIiwiaW5pIjoiMTY2NjE5NTcyOSIsImV4cCI6IjE2Njc0OTE3MjkifQ.9WP0YXtwNEQ4e2f8whQ9XSeqppmqoDRCP-dMc4Lmx6I";
// export const realtimePChannel = new Ably.Realtime({ authUrl: `https://stagingfacultypython.edwisely.com/weekly_challenge/weeklyChallengeAuth?weekly_challenge_id=51&token=${accessToken}` });
// console.log(realtimePChannel);
// realtimePChannel.connection.once('connected', () => {
//     console.log("authenticated");
// });

const UserChallengeLobby = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const realtimePChannel = useSelector(state => state.joinChallenge.realtimePChannel);
    const channel = useSelector(state => state.joinChallenge.participantChannel);
    const viewer_channel = useSelector(state => state.joinChallenge.viewerChannel);
    console.log(channel);
    const [challengeDetails, setChallengeDetails] = useState(location.state.challengeDetails);
    const [weeklyChallengeDetails, setWeeklyChallengeDetails] = useState(null);
    const [leaderBoardData, setLeaderBoardData] = useState([]);
    const [enableJoinChallengeBtn, setEnableJoinChallengeBtn] = useState(true);
    const [isEnrolled, setIsEnrolled] = useState(location.state.challengeDetails.is_enrolled);
    const [enableViewChallengeBtn, setEnableViewChallengeBtn] = useState(true);
    const [enableEnrollChallengeBtn, setEnableEnrollChallengeBtn] = useState(false);
    const [enrollmentTimerAlertLabel, setEnrollmentTimerAlertLabel] = useState(true);
    const [enrollmentTImerEndLabel, setEnrollmentTImerEndLabel] = useState(false);
    const [enrolledToChallengeLabel1, setEnrolledToChallengeLabel1] = useState(false);
    const [enrolledToChallengeLabel2, setEnrolledToChallengeLabel2] = useState(false);
    const [enrolledToChallengeLabel3, setEnrolledToChallengeLabel3] = useState(false);
    const [joinChallengeAlertLabel1, setJoinChallengeAlertLabel1] = useState(true);
    const [joinChallengeAlertLabel2, setJoinChallengeAlertLabel2] = useState(false);
    const [myTeam, setMyTeam] = useState([]);
    const [myTeamRank, setMyTeamRank] = useState(1);
    const [leaderBoardFlag, setLeaderBoardFlag] = useState(false);
    const [flag, setFlag] = useState(true);
    const [showChallengeErrorAlertModal, setShowChallengeErrorAlertModal] = useState(false);
    const [challengeErrorMessage, setChallengeErrorMessage] = useState("");


    console.log("details ---> ", location.state.challengeDetails);

    const getLeaderBoardData = async (team_data) => {
        const leaderboard = await getLeaderBoard(location.state.challenge_id, "");
        setLeaderBoardData(leaderboard.data);

        if (leaderboard.message === "Successfully fetched the data") {
            setLeaderBoardFlag(true);
        }

        const challenge_details = await getWeeklyChallengeDetails(location.state.challenge_id);
        //getting team rank
        if (challenge_details.data.participation_type === 1) {
            let team_rank = 1;
            for (let i = 0; i < leaderboard.data.length; i++) {
                if (leaderboard.data[i].team_id === team_data.id) {
                    team_rank = i + 1;
                    break;
                }
            }
            setMyTeamRank(team_rank);
        } else {
            let team_rank = 1;
            for (let i = 0; i < leaderboard.data.length; i++) {
                if (leaderboard.data[i].student_id === current_user_id || leaderboard.data[i].college_account_id === current_user_id) {
                    team_rank = i + 1;
                    break;
                }
            }
            setMyTeamRank(team_rank);
        }
    }
    useEffect(() => {
        async function openNewConnection() {
            const openAblyCon = await dispatch(openNewAblyConnection({ challenge_id: location.state.challenge_id, pChannelId: location.state.challengeDetails.participation_channel_id, vChannelId: location.state.challengeDetails.viewer_channel_id }));
        };
        if (weeklyChallengeDetails === null) {
            getChallengeDetails();
        }

        if (challengeDetails && flag) {
            const enable_time = (new Date(challengeDetails.start_time).getTime() - (parseInt(challengeDetails.start_button_timer) * 1000));

            if (new Date().getTime() > enable_time) {
                setEnableJoinChallengeBtn(false);
                setEnableEnrollChallengeBtn(true);
            }

            const enable_viewer_time = (new Date(challengeDetails.start_time).getTime() - (parseInt(challengeDetails.start_button_timer) * 1000));
            if (new Date().getTime() > enable_viewer_time) {
                setEnableViewChallengeBtn(false);
                setJoinChallengeAlertLabel2(true);
                setJoinChallengeAlertLabel1(false);
            }

            if (challengeDetails.is_completed === 1) {
                showTeams();
            }
            setFlag(false);
        }

        try {
            openNewConnection();
        } catch (error) {
            return error;
        }
    }, [dispatch]);

    const showTeams = async () => {
        const myteam = await getMyTeam(challengeDetails.id);
        setMyTeam(myteam.data);
        getLeaderBoardData(myteam.data);
    }

    console.log(weeklyChallengeDetails);

    const getChallengeDetails = async () => {
        const challenge_details = await getWeeklyChallengeDetails(location.state.challenge_id);
        setWeeklyChallengeDetails(challenge_details.data);

        const enable_time = (new Date(challengeDetails.start_time).getTime() - (parseInt(challengeDetails.start_button_timer) * 1000));
        if ((new Date().getTime() < new Date(challengeDetails.enrollment_end_time) && challengeDetails.is_enrolled === 0)) {
            setEnableJoinChallengeBtn(true);
            setEnableEnrollChallengeBtn(false);
            setEnrollmentTimerAlertLabel(true);
            setEnrollmentTImerEndLabel(false);

        } else if ((new Date().getTime() < new Date(challengeDetails.enrollment_end_time) && challengeDetails.is_enrolled === 1)) {
            setEnableJoinChallengeBtn(true);
            setEnableEnrollChallengeBtn(true);
            setEnrollmentTimerAlertLabel(false);
            setEnrollmentTImerEndLabel(false);
        }

        if (new Date().getTime() > new Date(challengeDetails.enrollment_end_time)) {
            if (new Date().getTime() < enable_time) {
                setEnableJoinChallengeBtn(true);
                setEnableEnrollChallengeBtn(true);
                setEnrollmentTimerAlertLabel(false);
                setEnrollmentTImerEndLabel(false);
            }
        }

        if (new Date().getTime() > enable_time && challengeDetails.is_enrolled === 1) {
            setEnableJoinChallengeBtn(false);
            setEnrolledToChallengeLabel1(false);
            setEnrolledToChallengeLabel2(true);
            setEnrolledToChallengeLabel3(false);
        } else if ((new Date().getTime() < new Date(challengeDetails.enrollment_end_time)) && challengeDetails.is_enrolled === 1) {
            setEnableJoinChallengeBtn(true);
            setEnrolledToChallengeLabel1(true);
            setEnrolledToChallengeLabel2(false);
            setEnrolledToChallengeLabel3(false);
        } else if (new Date().getTime() < enable_time && (new Date().getTime() > new Date(challengeDetails.enrollment_end_time)) && challengeDetails.is_enrolled === 1) {
            setEnableJoinChallengeBtn(true);
            setEnrolledToChallengeLabel1(false);
            setEnrolledToChallengeLabel2(false);
            setEnrolledToChallengeLabel3(true);
        }
    }

    //enroll Arena
    const handleEnrollChallenge = async () => {
        if (challengeDetails.enrolled_count >= challengeDetails.no_of_participants) {
            setChallengeErrorMessage("You can`t enroll as the enrollments were exceeded!");
            setShowChallengeErrorAlertModal(true);
            return;
        }
        const enrollChallenge = await enrollWeeklyChallenge(location.state.challenge_id);
        console.log(enrollChallenge.message);
        if (enrollChallenge.message === "Successfully enrolled to Weekly Challenge") {
            setIsEnrolled(1);
            setEnrolledToChallengeLabel1(true);
            setEnrolledToChallengeLabel2(false);
        } else {
            setChallengeErrorMessage(enrollChallenge.message);
            setShowChallengeErrorAlertModal(true);
            return;
        }
    };
    //join Arena
    const handleJoinChallenge = () => {
        channel.presence.enter();
        navigate('/joinChallenge', { state: { challengeDetails: challengeDetails } });
    };

    //viewer join Arena
    const handleViewerJoinChallenge = () => {
        navigate('/viewChallenge', { state: { challengeDetails: challengeDetails } });
    }

    const enrollmentTimeCompleted = () => {
        setEnableEnrollChallengeBtn(true);
        setEnrollmentTimerAlertLabel(false);
        setEnrollmentTImerEndLabel(true);
    };

    const enrollmentTimeCompleted2 = () => {
        setEnrolledToChallengeLabel1(false);
        setEnrolledToChallengeLabel2(false);
        setEnrolledToChallengeLabel3(true);
    };

    const enableJoinChallengeTimer = () => {
        setEnableJoinChallengeBtn(false);
        setEnrolledToChallengeLabel1(false);
        setEnrolledToChallengeLabel2(true);
        setEnrolledToChallengeLabel3(false);
    };

    const enableJoinViewerChallengeTimer = () => {
        setEnableViewChallengeBtn(false);
        setJoinChallengeAlertLabel2(true);
        setJoinChallengeAlertLabel1(false);
    };

    const handleChallengeErrorAlertModalClose = () => setShowChallengeErrorAlertModal(false);

    if (!challengeDetails.is_viewer && weeklyChallengeDetails !== null) {
        let challenge_start_hours = new Date(challengeDetails.enrollment_end_time).getHours();
        const challenge_start_mins = new Date(challengeDetails.enrollment_end_time).getMinutes();
        let am_pm = "";
        if (challenge_start_hours > 12) {
            challenge_start_hours = challenge_start_hours - 12;
            am_pm = "PM";
        } else {
            am_pm = "AM";
        }

        const enrollment_end_timer = ((new Date(challengeDetails.enrollment_end_time).getTime() - new Date().getTime()) / 1000) / 60;
        const challenge_start_timer = ((new Date(challengeDetails.start_time).getTime() - new Date().getTime()) / 1000) / 60;

        return (
            <div className="row w-100">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <Modal show={showChallengeErrorAlertModal} onHide={handleChallengeErrorAlertModalClose} id="challengeCreateAlertModal" dialogClassName="challengeCreateAlertmodalWidth">
                        <Modal.Header closeButton>
                            <Modal.Title>Arena Alert</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p style={{ textAlign: 'center', fontSize: '1.2em' }}><strong>{challengeErrorMessage}</strong></p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn btn-primary shadow-sm" onClick={handleChallengeErrorAlertModalClose} id="closeErrorAlertModalBtn" >
                                Okay
                            </button>
                        </Modal.Footer>
                    </Modal>
                    <div className={challengeDetails.is_completed === 1 ? "join-challenge-lobby-div leaderboard-background" : "join-challenge-lobby-div"}>
                        <h2 className="challenge-title">{challengeDetails.title}</h2>
                        <p className="challenge-desc">{challengeDetails.description}</p>
                        {
                            new Date(challengeDetails.start_time).getTime() < new Date().getTime() || challengeDetails.is_completed === 1
                                ? challengeDetails.is_completed === 1
                                    ? leaderBoardFlag === true
                                        ? leaderBoardData.length !== 0
                                            ? (
                                                <div style={{ height: '400px', overflowY: "auto", overflowX: "hidden" }}>
                                                    <h2 style={{ textAlign: 'center' }}>LeaderBoard</h2>
                                                    {
                                                        weeklyChallengeDetails.participation_type === 1
                                                            ? (<div style={{ fontSize: '1.5em', marginLeft: '10%' }} className="myteam-rank-label"><strong>Your Team Rank: {myTeamRank}</strong></div>)
                                                            : (<div style={{ fontSize: '1.5em', marginLeft: '10%' }} className="myteam-rank-label"><strong>Your Rank: {myTeamRank}</strong></div>)
                                                    }
                                                    {
                                                        weeklyChallengeDetails.participation_type === 1
                                                            ? weeklyChallengeDetails.random_questions === 1
                                                                ? (<LeaderBoard data={leaderBoardData} current_question_id="" myTeam_id={myTeam.id} user_type="participant" question_index="" type="completed" challenge_details={weeklyChallengeDetails} />)
                                                                : (<LeaderBoard data={leaderBoardData} current_question_id="" myTeam_id={myTeam.id} user_type="participant" question_index="" type="completed" challenge_details={weeklyChallengeDetails} />)
                                                            : weeklyChallengeDetails.random_questions === 1
                                                                ? (<LeaderBoard data={leaderBoardData} current_question_id="" myTeam_id={current_user_id} user_type="participant" question_index="" type="completed" challenge_details={weeklyChallengeDetails} />)
                                                                : (<LeaderBoard data={leaderBoardData} current_question_id="" myTeam_id={current_user_id} user_type="participant" question_index="" type="completed" challenge_details={weeklyChallengeDetails} />)
                                                    }
                                                </div>
                                            )
                                            : (
                                                <div><h2 style={{ textAlign: 'center', color: 'red' }}>LeaderBoard Data is not available!</h2></div>
                                            )
                                        : ""
                                    : isEnrolled === 0
                                        ? (
                                            <div>
                                                <div className="time-div">
                                                    <h4 className="time-label">Starts At</h4>
                                                    <h2 className="org-time" style={{ color: 'balck' }}>{challengeDetails.start_time}</h2>
                                                </div>
                                                {
                                                    enrollmentTimerAlertLabel && (
                                                        <p id="enrolled-challenge-label"><strong>Enrollment is open now and will be closed in <Countdowntimer time={enrollment_end_timer * 60} timercompleted={enrollmentTimeCompleted} />.</strong></p>
                                                    )
                                                }
                                                {
                                                    enrollmentTImerEndLabel && (
                                                        <p id="enrolled-challenge-label"><strong>Enrollment has ended at {challenge_start_hours}:{challenge_start_mins} {am_pm}.</strong></p>
                                                    )
                                                }

                                                <button className="btn btn-default" id="open-challenge-btn" disabled={enableEnrollChallengeBtn} onClick={handleEnrollChallenge}>Enroll Arena</button>
                                            </div>
                                        )
                                        : (
                                            <div>
                                                <div className="time-div">
                                                    <h4 className="time-label">Starts At</h4>
                                                    <h2 className="org-time" style={{ color: 'balck' }}>{challengeDetails.start_time}</h2>
                                                </div>
                                                {
                                                    enrolledToChallengeLabel1 && (
                                                        <p id="enrolled-challenge-label"><strong>You have been enrolled to the Arena.<br /> Enrollment Ends in <Countdowntimer time={enrollment_end_timer * 60} timercompleted={enrollmentTimeCompleted2} />.</strong></p>
                                                    )
                                                }
                                                {
                                                    enrolledToChallengeLabel3 && (
                                                        <p id="enrolled-challenge-label"><strong>You have been enrolled to the Arena.<br /> You can join in <Countdowntimer time={(challenge_start_timer - (parseInt(challengeDetails.start_button_timer) / 60)) * 60} timercompleted={enableJoinChallengeTimer} />.</strong></p>
                                                    )
                                                }
                                                {
                                                    enrolledToChallengeLabel2 && (
                                                        <p id="enrolled-challenge-label"><strong>You can join the Arena now.</strong></p>
                                                    )
                                                }

                                                <button className="btn btn-default" id="open-challenge-btn" disabled={enableJoinChallengeBtn} onClick={handleJoinChallenge}>Join Arena</button>
                                            </div>
                                        )

                                : isEnrolled === 0
                                    ? (
                                        <div>
                                            <div className="time-div">
                                                <h4 className="time-label">Starts At</h4>
                                                <h2 className="org-time" style={{ color: 'balck' }}>{challengeDetails.start_time}</h2>
                                            </div>
                                            {
                                                enrollmentTimerAlertLabel && (
                                                    <p id="enrolled-challenge-label"><strong>Enrollment is open now and will be closed in <Countdowntimer time={enrollment_end_timer * 60} timercompleted={enrollmentTimeCompleted} />.</strong></p>
                                                )
                                            }
                                            {
                                                enrollmentTImerEndLabel && (
                                                    <p id="enrolled-challenge-label"><strong>Enrollment has ended at {challenge_start_hours}:{challenge_start_mins} {am_pm}.</strong></p>
                                                )
                                            }

                                            <button className="btn btn-default" id="open-challenge-btn" disabled={enableEnrollChallengeBtn} onClick={handleEnrollChallenge}>Enroll Arena</button>
                                        </div>
                                    )
                                    : (
                                        <div>
                                            <div className="time-div">
                                                <h4 className="time-label">Starts At</h4>
                                                <h2 className="org-time" style={{ color: 'balck' }}>{challengeDetails.start_time}</h2>
                                            </div>
                                            {
                                                enrolledToChallengeLabel1 && (
                                                    <p id="enrolled-challenge-label"><strong>You have been enrolled to the Arena.<br /> Enrollment Ends in <Countdowntimer time={enrollment_end_timer * 60} timercompleted={enrollmentTimeCompleted2} />.</strong></p>
                                                )
                                            }
                                            {
                                                enrolledToChallengeLabel3 && (
                                                    <p id="enrolled-challenge-label"><strong>You have been enrolled to the Arena.<br /> You can join in <Countdowntimer time={(challenge_start_timer - (parseInt(challengeDetails.start_button_timer) / 60)) * 60} timercompleted={enableJoinChallengeTimer} />.</strong></p>
                                                )
                                            }
                                            {
                                                enrolledToChallengeLabel2 && (
                                                    <p id="enrolled-challenge-label"><strong>You can join the Arena now.</strong></p>
                                                )
                                            }

                                            <button className="btn btn-default" id="open-challenge-btn" disabled={enableJoinChallengeBtn} onClick={handleJoinChallenge}>Join Arena</button>
                                        </div>
                                    )
                        }
                    </div>
                </div>
            </div>
        );
    } else if (challengeDetails.is_viewer && weeklyChallengeDetails !== null) {
        const challenge_start_timer = ((new Date(challengeDetails.start_time).getTime() - new Date().getTime()) / 1000) / 60;
        return (
            <div className="row w-100">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className={challengeDetails.is_completed === 1 ? "join-challenge-lobby-div leaderboard-background" : "join-challenge-lobby-div"}>
                        <h2 className="challenge-title">{challengeDetails.title}</h2>
                        <p className="challenge-desc">{challengeDetails.description}</p>
                        {
                            new Date(challengeDetails.start_time).getTime() < new Date().getTime() || challengeDetails.is_completed === 1
                                ? challengeDetails.is_completed === 1
                                    ? leaderBoardFlag === true
                                        ? leaderBoardData.length !== 0
                                            ? (
                                                <div style={{ height: '400px', overflowY: "auto", overflowX: "hidden" }}>
                                                    <h2 style={{ textAlign: 'center' }}>LeaderBoard</h2>
                                                    {
                                                        weeklyChallengeDetails.participation_type === 1
                                                            ? weeklyChallengeDetails.random_questions === 1
                                                                ? (<LeaderBoard data={leaderBoardData} current_question_id="" myTeam_id="" user_type="viewer" question_index="" type="completed" challenge_details={weeklyChallengeDetails} />)
                                                                : (<LeaderBoard data={leaderBoardData} current_question_id="" myTeam_id="" user_type="viewer" question_index="" type="completed" challenge_details={weeklyChallengeDetails} />)
                                                            : weeklyChallengeDetails.random_questions === 1
                                                                ? (<LeaderBoard data={leaderBoardData} current_question_id="" myTeam_id="" user_type="viewer" question_index="" type="completed" challenge_details={weeklyChallengeDetails} />)
                                                                : (<LeaderBoard data={leaderBoardData} current_question_id="" myTeam_id="" user_type="viewer" question_index="" type="completed" challenge_details={weeklyChallengeDetails} />)
                                                    }
                                                </div>
                                            )
                                            : (
                                                <div><h2 style={{ textAlign: 'center', color: 'red' }}>LeaderBoard Data is not available!</h2></div>
                                            )
                                        : ""
                                    : (
                                        <div>
                                            <div className="time-div">
                                                <h4 className="time-label">Starts At</h4>
                                                <h2 className="org-time" style={{ color: 'balck' }}>{challengeDetails.start_time}</h2>
                                            </div>
                                            {
                                                joinChallengeAlertLabel1 && (
                                                    <p id="enrolled-challenge-label"><strong>You are the viewer to the Arena. You can join the Arena in <Countdowntimer time={(challenge_start_timer - (parseInt(challengeDetails.start_button_timer) / 60)) * 60} timercompleted={enableJoinViewerChallengeTimer} />.</strong></p>
                                                )
                                            }
                                            {
                                                joinChallengeAlertLabel2 && (
                                                    <p id="enrolled-challenge-label"><strong>You are the viewer to the Arena. You can join now.</strong></p>
                                                )
                                            }
                                            <button className="btn btn-default" id="open-challenge-btn" disabled={enableViewChallengeBtn} onClick={handleViewerJoinChallenge}>Join Arena</button>
                                        </div>
                                    )

                                : (
                                    <div>
                                        <div className="time-div">
                                            <h4 className="time-label">Starts At</h4>
                                            <h2 className="org-time" style={{ color: 'balck' }}>{challengeDetails.start_time}</h2>
                                        </div>
                                        {
                                            joinChallengeAlertLabel1 && (
                                                <p id="enrolled-challenge-label"><strong>You are the viewer to the Arena. You can join the Arena in <Countdowntimer time={(challenge_start_timer - (parseInt(challengeDetails.start_button_timer) / 60)) * 60} timercompleted={enableJoinViewerChallengeTimer} />.</strong></p>
                                            )
                                        }
                                        {
                                            joinChallengeAlertLabel2 && (
                                                <p id="enrolled-challenge-label"><strong>You are the viewer to the Arena. You can join now.</strong></p>
                                            )
                                        }
                                        <button className="btn btn-default" id="open-challenge-btn" disabled={enableViewChallengeBtn} onClick={handleViewerJoinChallenge}>Join Arena</button>
                                    </div>
                                )
                        }

                    </div>
                </div>
            </div>
        );
    }
}

export default UserChallengeLobby;