import React, { useRef, useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';
import { Fragment } from 'react';
// import { PieChart, Pie, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from 'recharts';
import Html from 'react-pdf-html';
// import html2canvas from 'html2canvas';
// import domtoimage from 'dom-to-image';
// import ChartImage from './chartImage.component';

const ChallengeReportTemplate = (props) => {
    Font.register({ family: 'arial', src: '/files/arial.ttf' });
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    let tag_line = ""
    if(user){
        if(user.college_id === 27){
            tag_line = "(An Autonomous Institution)";
        }else if(user.college_id === 29){
            tag_line = "(An Autonomous Institution)";
        }else if(user.college_id === 32){
            tag_line = "(An Autonomous Institution)";
        }else if(user.college_id === 40){
            tag_line = "(An Autonomous Institution)";
        }else if(user.college_id === 41){
            tag_line = "(An Autonomous Institution)";
        }else if(user.college_id === 38){
            tag_line = "(Deemed to be University)";
        }else if(user.college_id === 51){
            tag_line = "";
        }else if(user.college_id === 36){
            tag_line = "(An Autonomous Institution)";
        }else{
            tag_line = "(An Autonomous Institution)";
        }
    }
    const styles = StyleSheet.create({
        viewer: {
            width: '100%', //the pdf viewer will take up all of the width and height
            height: window.innerHeight,
        },
        view: {
            fontSize: 10,
        },
        page: {
            backgroundColor: 'white',
            flexDirection: 'column',
            margin: 15,
            paddingBottom: 70,
            paddingTop: 25,
            marginLeft: 30,
            marginRight: 10,
            width: '90%',
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 5,
            width: '90%'
        },
        logo1: {
            width: 50,
            height: 70,
            marginLeft: "30px",
        },
        logo01: {
            width: 60,
            height: 70,
            marginLeft: "30px",
        },
        logo02: {
            width: 50,
            height: 50,
            marginLeft: "30px",
        },
        logo03: {
            width: 70,
            height: 50,
            marginLeft: "30px",
        },
        logo2: {
            width: 70,
            height: 60,
            marginRight: "10px",
            marginTop: '10px'
        },
        logo21: {
            width: 45,
            height: 55,
            marginRight: "10px",
            marginTop: '10px'
        },
        headerText: {
            fontSize: 15,
            fontWeight: 'bold',
            marginLeft: 15,
            marginRight: 15,
            textAlign: 'center'
        },
        subHeaderText: {
            fontSize: 12,
            marginTop: "10px",
            textAlign: 'center'
        },
        clgName: {
            alignItems: 'center',
            width: "66.67%",
        },
        semYearDetails: {
            alignItems: 'center',
            width: '90%'
        },
        sfReportLabel: {
            fontWeight: 500,
            fontSize: 14
        },
        semYearLabel: {
            fontSize: 10
        },
        rowSpan: {
            paddingLeft: 20,
            paddingRight: 30,
            marginTop: 10,
            width: '90%',
            lineHeight: 0.7,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        rowSpan_details: {
            paddingLeft: 20,
            paddingRight: 30,
            marginTop: 2,
            width: '90%',
            lineHeight: 0.7,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        rowSpan1: {
            width: '60%',
            flexDirection: 'row',
            // flexWrap: 'wrap',
            wordBreak: 'break-all',
            marginTop: 5,
            lineHeight: 0.9,
        },
        rowSpan2: {
            width: '30%',
            flexDirection: 'row',
            flexWrap: 'wrap',
            wordBreak: 'break-all',
            marginTop: 5,
            lineHeight: 0.9,
        },
        rowSpan22: {
            width: '30%',
            flexDirection: 'row',
            // flexWrap: 'wrap',
            wordBreak: 'break-all',
            marginTop: 5,
            lineHeight: 0.9,
        },
        labelHeading: {
            fontWeight: 'bold',
            fontSize: 12,
            textDecoration: 'underline'
        },
        sectionHeading: {
            fontWeight: 'bold',
            fontSize: 14,
            textDecoration: 'underline'
        },
        label: {
            fontWeight: 'bold',
            fontSize: 11,
        },
        value: {
            marginLeft: 5,
            fontWeight: 'normal',
            fontSize: 10,
            marginTop: '1px'
        },
        rowValues: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        tableContainer: {
            flexDirection: "row",
            flexWrap: "wrap",
            // marginBottom: 50,
            width: '90%'
        },
        chart: {
            width: '100%',
            height: 255,
            marginLeft: 20,
            overflow: 'hidden'
        },
        chartRowSpan: {
            marginTop: 10,
            width: '180%',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        chartRowSpan1: {
            width: '40%',
            flexDirection: 'column',
            marginTop: 5,
            wordBreak: 'break-all',
            lineHeight: 1,
            paddingLeft: 25,
            paddingTop: 50,
        },
        chartRowSpan2: {
            width: '140%',
            flexDirection: 'row',
            marginTop: 5,
        },
        chartBoxColorView: {
            width: 20,
            height: 10,
            border: '1px solid black',
            marginRight: 10
        },
        chartInnerrowSpan1: {
            width: '100%',
            flexDirection: 'row',
            // flexWrap: 'wrap',
            wordBreak: 'break-all',
            marginTop: 8,
            lineHeight: 1,
        }
    });

    return (
        // <PDFViewer style={styles.viewer}>
            <Document>
                {
                    props.reportData.map(report_data => {
                        let pdfPages = []
                        pdfPages.push(
                            <React.Fragment key='test_details'>
                                <Page size='A4' style={styles.page}>
                                    <View style={styles.header}>
                                        <View style={styles.col2}>
                                            <Image 
                                                style={
                                                    [38,40,41].includes(user.college_id) 
                                                        ? styles.logo01 
                                                        : [51].includes(user.college_id)
                                                            ? styles.logo02
                                                            : [36].includes(user.college_id)
                                                                ? styles.logo03
                                                                : styles.logo1
                                                } 
                                                src={report_data.college_logo ? report_data.college_logo : `/images/feedback_images/resultsPage.jpg`} 
                                            />
                                        </View>
                                        <View style={styles.clgName}>
                                            <Text style={styles.headerText}>{report_data.college_name}</Text>
                                            {/* <Text style={styles.headerText}>R.M.K College of Engineering and Technology</Text> */}
                                            {
                                                ![51].includes(user.college_id)
                                                    ? (
                                                        <Text style={styles.subHeaderText}>{tag_line}</Text>
                                                    )
                                                    : ""
                                            }
                                        </View>
                                        <View style={styles.col2}>
                                            {
                                                [27].includes(user.college_id)
                                                    ? (
                                                        <Image style={styles.logo2} src="/images/feedback_images/resultsPage-logo2.jpg" />
                                                    )
                                                    : [32].includes(user.college_id) 
                                                        ? (
                                                            <Image style={styles.logo21} src="/images/feedback_images/rmk_group_28years_logo.png" />
                                                        )
                                                        : (
                                                            <View style={styles.logo2}></View>
                                                        )
                                            }
                                        </View>
                                    </View>
                                    <View style={styles.semYearDetails}>
                                        <Text style={styles.sfReportLabel}>ARENA REPORT</Text>
                                        {/* <Text style={styles.semYearLabel}>ACADEMIC YEAR: 2022-23 {parseInt(feedback.semester_id) % 2 == 0 ? 'EVEN' : 'ODD'} SEMESTER</Text> */}
                                        <Text style={styles.semYearLabel}>ACADEMIC YEAR: {report_data.academic_year}</Text>
                                    </View>

                                    {/* Test details */}
                                    <View style={styles.rowSpan}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.sectionHeading}>Arena Details</Text>
                                        </View>
                                        <View style={styles.rowSpan2}>
                                            <Text style={styles.sectionHeading}>Faculty Details</Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Test Name : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>{report_data.challenge_name}</Text>
                                        </View>
                                        <View style={styles.rowSpan2}>
                                            <Text style={styles.label}>Faculty Name : </Text>
                                            <Text style={styles.value} numberOfLines={1} ellipsizeMode='tail'>{report_data.faculty_name}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Description : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>{report_data.challenge_desc}</Text>
                                        </View>
                                        <View style={styles.rowSpan22}>
                                            <Text style={styles.label}>Role : </Text>
                                            <Text style={{ ...styles.value, textOverflow: 'ellipsis' }} numberOfLines={1} ellipsizeMode='tail'>{report_data.role}</Text>
                                        </View>
                                    </View>

                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Start Time : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.start_time}
                                            </Text>
                                        </View>
                                        <View style={styles.rowSpan22}>
                                            <Text style={styles.label}>Department : </Text>
                                            <Text style={{ ...styles.value, textOverflow: 'ellipsis', marginLeft: '25px', marginRight: '5px' }} numberOfLines={2} ellipsizeMode='tail'>{report_data.branch_name}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Categories : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.categories}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Total Questions : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.no_of_questions}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Enrollment Type : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.enrollment_type}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Participation Type : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.participation_type}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>No of Participants : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.no_of_members_participated}
                                            </Text>
                                        </View>
                                    </View>
                                    {
                                        report_data.participation_type === "Team"
                                            ? (
                                                <>
                                                    <View style={styles.rowSpan_details}>
                                                        <View style={styles.rowSpan1}>
                                                            <Text style={styles.label}>No of Teams : </Text>
                                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                                {report_data.no_of_teams ? report_data.no_of_teams : 0}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View style={styles.rowSpan_details}>
                                                        <View style={styles.rowSpan1}>
                                                            <Text style={styles.label}>No of Participants : </Text>
                                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                                {report_data.team_size ? report_data.team_size : 0}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </>
                                            )
                                            : ""
                                    }

                                    {/* Arena analytics */}
                                    <View style={styles.rowSpan}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.sectionHeading}>Arena Analytics</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableContainer}>
                                        {
                                            report_data.participation_type === "Team"
                                                ? (<ChallengeAnalyticsTable data={report_data.analytics} type={report_data.participation_type}
                                                    row_start={0}
                                                    row_end={5} />
                                                )
                                                : (<ChallengeAnalyticsTable data={report_data.analytics} type={report_data.participation_type}
                                                    row_start={0}
                                                    row_end={11} />
                                                )
                                        }

                                    </View>

                                </Page>
                            </React.Fragment>
                        )

                        // Arena analytics for the rows over 12
                        let total_rows = report_data.analytics.length;
                        let rows_in_first_page = 12;
                        if(report_data.participation_type == "Team"){
                            rows_in_first_page = 6;
                        }
                        if (total_rows > rows_in_first_page) {
                            let rows_in_page = 25;
                            if(report_data.participation_type == "Team"){
                                rows_in_page = 15;
                            }
                            let pages = Math.ceil((total_rows - rows_in_first_page) / rows_in_page);
                            console.log("pages -> ", pages);
                            for (let i = 1; i <= pages; i++) {
                                let row_start = (i - 1) * (rows_in_page - 1) + (i - 1);
                                let row_end = i * (rows_in_page - 1) + (i - 1);
                                row_start = row_start + rows_in_first_page;
                                row_end = row_end + rows_in_first_page;
                                // Adjust row_end if it exceeds the actual number of questions
                                row_end = Math.min(row_end, total_rows);
                                console.log("row_end -> ", row_end);
                                pdfPages.push(
                                    <React.Fragment key={`question_analytics_` + i}>
                                        <Page size='A4' style={styles.page}>
                                            <View style={styles.header}>
                                                <View style={styles.col2}>
                                                    <Image 
                                                        style={
                                                            [38,40,41].includes(user.college_id) 
                                                                ? styles.logo01 
                                                                : [51].includes(user.college_id)
                                                                    ? styles.logo02
                                                                    : [36].includes(user.college_id)
                                                                        ? styles.logo03
                                                                        : styles.logo1
                                                        } 
                                                        src={report_data.college_logo ? report_data.college_logo : `/images/feedback_images/resultsPage.jpg`} 
                                                    />
                                                </View>
                                                <View style={styles.clgName}>
                                                    <Text style={styles.headerText}>{report_data.college_name}</Text>
                                                    {/* <Text style={styles.headerText}>R.M.K College of Engineering and Technology</Text> */}
                                                    {
                                                        ![51].includes(user.college_id)
                                                            ? (
                                                                <Text style={styles.subHeaderText}>{tag_line}</Text>
                                                            )
                                                            : ""
                                                    }
                                                </View>
                                                <View style={styles.col2}>
                                                    {
                                                        [27].includes(user.college_id)
                                                            ? (
                                                                <Image style={styles.logo2} src="/images/feedback_images/resultsPage-logo2.jpg" />
                                                            )
                                                            : [32].includes(user.college_id) 
                                                                ? (
                                                                    <Image style={styles.logo21} src="/images/feedback_images/rmk_group_28years_logo.png" />
                                                                )
                                                                : (
                                                                    <View style={styles.logo2}></View>
                                                                )
                                                    }
                                                </View>
                                            </View>

                                            <View style={styles.tableContainer}>
                                                <ChallengeAnalyticsTable data={report_data.analytics}
                                                    type={report_data.participation_type} row_start={row_start} row_end={row_end} />
                                            </View>
                                        </Page>
                                    </React.Fragment>
                                )
                            }

                        }

                        // questions analytics
                        let questions_in_page = 13;
                        let total_questions = report_data.question_analytics.length;
                        let question_pages = Math.ceil(total_questions / questions_in_page);
                        for (let i = 1; i <= question_pages; i++) {
                            let question_start = (i - 1) * (questions_in_page - 1) + (i - 1);
                            let question_end = i * (questions_in_page - 1) + (i - 1);
                            // Adjust question_end if it exceeds the actual number of questions
                            question_end = Math.min(question_end, total_questions);

                            pdfPages.push(
                                <React.Fragment key={`student_performance_` + i}>
                                    <Page size='A4' style={styles.page}>
                                        <View style={styles.header}>
                                            <View style={styles.col2}>
                                                <Image 
                                                    style={
                                                        [38,40,41].includes(user.college_id) 
                                                            ? styles.logo01 
                                                            : [51].includes(user.college_id)
                                                                ? styles.logo02
                                                                : [36].includes(user.college_id)
                                                                    ? styles.logo03
                                                                    : styles.logo1
                                                    } 
                                                    src={report_data.college_logo ? report_data.college_logo : `/images/feedback_images/resultsPage.jpg`} 
                                                />
                                            </View>
                                            <View style={styles.clgName}>
                                                <Text style={styles.headerText}>{report_data.college_name}</Text>
                                                {/* <Text style={styles.headerText}>R.M.K College of Engineering and Technology</Text> */}
                                                {
                                                    ![51].includes(user.college_id)
                                                        ? (
                                                            <Text style={styles.subHeaderText}>{tag_line}</Text>
                                                        )
                                                        : ""
                                                }
                                            </View>
                                            <View style={styles.col2}>
                                                {
                                                    [27].includes(user.college_id)
                                                        ? (
                                                            <Image style={styles.logo2} src="/images/feedback_images/resultsPage-logo2.jpg" />
                                                        )
                                                        : [32].includes(user.college_id) 
                                                            ? (
                                                                <Image style={styles.logo21} src="/images/feedback_images/rmk_group_28years_logo.png" />
                                                            )
                                                            : (
                                                                <View style={styles.logo2}></View>
                                                            )
                                                }
                                            </View>
                                        </View>

                                        {
                                            i === 1 ? (
                                                <View style={styles.rowSpan}>
                                                    <View style={styles.rowSpan1}>
                                                        <Text style={styles.sectionHeading}>Question Analytics</Text>
                                                    </View>
                                                </View>
                                            )
                                                : ''
                                        }

                                        <View style={styles.tableContainer}>
                                            <QuestionAnalyticsTable data={report_data.question_analytics}
                                                question_start={question_start} question_end={question_end} />
                                        </View>
                                    </Page>
                                </React.Fragment>
                            )

                        }

                        return <>{pdfPages}</>
                    })
                }
            </Document>
        // </PDFViewer >
    )

}

export default ChallengeReportTemplate;

//table stylings
const styles2 = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
    },
    table: {
        marginBottom: 10,
        fontSize: 10,
        width: '100%',
        border: '1px solid grey',
        marginTop: 20,
        marginLeft: 20,
        marginRight: 30,
        borderRadius: '5px'
    },
    tableHeader: {
        backgroundColor: '#F0F0F0',
        fontWeight: 'bold',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottom: '1px solid #e6e6e6'
    },
    tableRow2: {
        flexDirection: 'row',
    },
    tableCol01: {
        width: '10%',
    },
    tableCol2: {
        width: '20%',
    },
    tableValCol2: {
        width: '60%',
    },
    tableCol3: {
        width: '10%',
    },
    tableTCol3: {
        width: '20%'
    },
    tableValCol3: {
        width: '20%',
    },
    tableCell1: {
        margin: '3px',
        fontSize: 11,
        textAlign: 'center'
    },
    tableValueCell1: {
        margin: '3px',
        fontSize: 10,
        padding: "1px",
        textAlign: 'center',
    },
    tableValueCell2: {
        margin: '3px',
        fontSize: 10,
        padding: "1px",
    },
    tableCell2: {
        margin: '3px',
        fontSize: 10,
        // wordBreak: 'break-all', // Allow long words to break
        whiteSpace: 'nowrap', // Prevent line breaks within the text
        flexWrap: 'nowrap'
    },
    tableCell3: {
        margin: '3px',
        fontSize: 10,
        textAlign: 'center',
        whiteSpace: 'nowrap', // Prevent line breaks within the text
        flexWrap: 'nowrap'
    },

    tpTableCol1: {
        width: '10%',
    },
    tpTableCol2: {
        width: '50%',
    },
    tpTableCol3: {
        width: '20%',
    },
    tpTableCell2: {
        margin: '3px',
        fontSize: 10,
    },
    tpTableValueCell1: {
        margin: '3px',
        fontSize: 10,
        padding: "1px",
    },

    qaTableCol2: {
        width: '75%',
    },
    qaTableCol3: {
        width: '25%',
    },
    qaTableCol4: {
        width: '15%',
    },

    spTableCol1: {
        width: '10%',
    },
    spTableCol2: {
        width: '20%',
    },
    spTableCol3: {
        width: '20%',
        textAlign: 'center'
    },
    subTableCol1: {
        width: '100%',
    },
    subTableColVal1: {
        width: '100%',
        fontSize: 11,
        fontWeight: 700,
        margin: '3px',
        padding: '1px',
    },
    sqpTableCol2: {
        width: '40%'
    },
    sqpTableCol3: {
        width: '20%'
    },

    question_img: {
        width: '20%',
        height: '45px',
        marginLeft: '15px',
        marginBottom: '2px'
    },
    team_member_names: {
        fontSize: 8.5,
        marginTop: 0,
        marginRight: 5,
        marginLeft: 5,
        marginBottom: 5
    }
});

const ChallengeAnalyticsTable = (props) => {
    if (props.type == "Team") {
        return (
            <View style={styles2.table}>
                <View>
                    <View style={styles2.tableHeader}>
                        <View style={styles2.tableRow}>
                            <View style={{ ...styles2.tableCol01, borderRight: '1px solid white' }} >
                                <Text style={styles2.tableCell3}>S.No</Text>
                            </View>
                            <View style={{ ...styles2.tableCol2, width: '60%', borderRight: '1px solid white' }}>
                                <Text style={styles2.tableCell2}>Team Name</Text>
                            </View>
                            <View style={{ ...styles2.tableTCol3, borderRight: '1px solid white' }}>
                                <Text style={styles2.tableCell3}>Right</Text>
                            </View>
                            <View style={{ ...styles2.tableTCol3, borderRight: '1px solid white' }}>
                                <Text style={styles2.tableCell3}>Wrong</Text>
                            </View>
                            <View style={{ ...styles2.tableTCol3, borderRight: '1px solid white' }}>
                                <Text style={styles2.tableCell3}>Rank</Text>
                            </View>
                            <View style={{...styles2.tableTCol3, width: '10%'}}>
                                <Text style={styles2.tableCell3}>Time (s)</Text>
                            </View>
                        </View>
                    </View>
                    <ChallengeAnalyticsTableRow type={props.type} items={props.data} row_start={props.row_start} row_end={props.row_end} />
                </View>
            </View>
        )
    } else {
        return (
            <View style={styles2.table}>
                <View>
                    <View style={styles2.tableHeader}>
                        <View style={styles2.tableRow}>
                            <View style={{ ...styles2.tableCol01, borderRight: '1px solid white' }} >
                                <Text style={styles2.tableCell3}>S.No</Text>
                            </View>
                            <View style={{ ...styles2.tableCol2, borderRight: '1px solid white' }}>
                                <Text style={styles2.tableCell2}>Name</Text>
                            </View>
                            <View style={{ ...styles2.tableCol2, borderRight: '1px solid white' }}>
                                <Text style={styles2.tableCell2}>Roll No</Text>
                            </View>
                            <View style={{ ...styles2.tableCol3, borderRight: '1px solid white' }}>
                                <Text style={styles2.tableCell3}>Role</Text>
                            </View>
                            <View style={{ ...styles2.tableCol3, borderRight: '1px solid white' }}>
                                <Text style={styles2.tableCell3}>Right</Text>
                            </View>
                            <View style={{ ...styles2.tableCol3, borderRight: '1px solid white' }}>
                                <Text style={styles2.tableCell3}>Wrong</Text>
                            </View>
                            <View style={{ ...styles2.tableCol3, borderRight: '1px solid white' }}>
                                <Text style={styles2.tableCell3}>Rank</Text>
                            </View>
                            <View style={styles2.tableCol3}>
                                <Text style={styles2.tableCell3}>Time (s)</Text>
                            </View>
                        </View>
                    </View>
                    <ChallengeAnalyticsTableRow type={props.type} items={props.data} row_start={props.row_start} row_end={props.row_end} />
                </View>
            </View>
        )
    }
}

const ChallengeAnalyticsTableRow = (props) => {
    if (props.type === "Team") {
        const rows = props.items.map((team, index) => {
            if (index >= props.row_start && index <= props.row_end) {
                let team_member_names = "";
                if(team.team_members.length > 0){
                    team.team_members.map((team_member,index) => {
                        if(index === team.team_members.length - 1){
                            return team_member_names = team_member_names + team_member.name
                        }else{
                            return team_member_names = team_member_names + team_member.name + ", "
                        }
                    })
                }
                return (
                    <View style={styles2.tableRow}>
                        <View style={{ ...styles2.tableCol01, borderRight: '1px solid #e6e6e6' }}>
                            <Text style={styles2.tableValueCell1}>{index + 1}</Text>
                        </View>
                        <View style={{ ...styles2.tableValCol2, borderRight: '1px solid #e6e6e6' }}>
                            <Text style={styles2.tableValueCell2}>{team.team_name ? team.team_name : ''}</Text>
                            {
                                team.team_members.length > 0 
                                ? (<Text style={styles2.team_member_names}>Team: {team_member_names}</Text>)
                                : ""
                            }
                        </View>
                        <View style={{ ...styles2.tableTCol3, borderRight: '1px solid #e6e6e6' }}>
                            <Text style={styles2.tableValueCell1}>{team.total_correct_answers ? team.total_correct_answers : 0}</Text>
                        </View>
                        <View style={{ ...styles2.tableTCol3, borderRight: '1px solid #e6e6e6' }}>
                            <Text style={styles2.tableValueCell1}>{team.total_wrong_answers ? team.total_wrong_answers : 0}</Text>
                        </View>
                        <View style={{ ...styles2.tableTCol3, borderRight: '1px solid #e6e6e6' }}>
                            <Text style={styles2.tableValueCell1}>{index + 1}</Text>
                        </View>
                        <View style={{...styles2.tableTCol3, width: '10%'}}>
                            <Text style={styles2.tableValueCell1}>{team.total_time_taken ? (team.total_time_taken/60).toFixed(1) : 0}</Text>
                        </View>
                    </View>
                )
            }
        })
        return <Fragment>{rows}</Fragment>;
    } else {
        const rows = props.items.map((participant, index) => {
            if (index >= props.row_start && index <= props.row_end) {
                return (
                    <View style={styles2.tableRow}>
                        <View style={{ ...styles2.tableCol01, borderRight: '1px solid #e6e6e6' }}>
                            <Text style={styles2.tableValueCell1}>{index + 1}</Text>
                        </View>
                        <View style={{ ...styles2.tableCol2, borderRight: '1px solid #e6e6e6' }}>
                            <Text style={styles2.tableValueCell2}>
                                {
                                    participant.student_name
                                        ? participant.student_name
                                        : participant.faculty_name
                                            ? participant.faculty_name
                                            : "-"
                                }
                            </Text>
                        </View>
                        <View style={{ ...styles2.tableCol2, borderRight: '1px solid #e6e6e6' }}>
                            <Text style={styles2.tableValueCell2}>{participant.student_roll_number ? participant.student_roll_number : '-'}</Text>
                        </View>
                        <View style={{ ...styles2.tableCol3, borderRight: '1px solid #e6e6e6' }}>
                            <Text style={styles2.tableValueCell1}>
                                {
                                    participant.student_name
                                        ? "Student"
                                        : "Faculty"
                                }
                            </Text>
                        </View>
                        <View style={{ ...styles2.tableCol3, borderRight: '1px solid #e6e6e6' }}>
                            <Text style={styles2.tableValueCell1}>{participant.total_correct_answers ? participant.total_correct_answers : 0}</Text>
                        </View>
                        <View style={{ ...styles2.tableCol3, borderRight: '1px solid #e6e6e6' }}>
                            <Text style={styles2.tableValueCell1}>{participant.total_wrong_answers ? participant.total_wrong_answers : 0}</Text>
                        </View>
                        <View style={{ ...styles2.tableCol3, borderRight: '1px solid #e6e6e6' }}>
                            <Text style={styles2.tableValueCell1}>{index + 1}</Text>
                        </View>
                        <View style={styles2.tableCol3}>
                            <Text style={styles2.tableValueCell1}>{participant.total_time_taken ? (participant.total_time_taken/60).toFixed(1) : 0}</Text>
                        </View>
                    </View>
                )
            }
        })
        return <Fragment>{rows}</Fragment>;
    }
}


//question analytics
const QuestionAnalyticsTable = (props) => {
    return (
        <View style={styles2.table}>
            <View>
                <View style={styles2.tableHeader}>
                    <View style={styles2.tableRow}>
                        <View style={{ ...styles2.tpTableCol1, borderRight: '1px solid white' }} >
                            <Text style={styles2.tableCell1}>S.No</Text>
                        </View>
                        <View style={{ ...styles2.qaTableCol2, borderRight: '1px solid white' }}>
                            <Text style={styles2.tpTableCell2}>Question</Text>
                        </View>
                        <View style={styles2.qaTableCol4}>
                            <Text style={styles2.tableCell2}>Correct Count</Text>
                        </View>
                    </View>
                </View>
                <QuestionAnalyticsTableRow items={props.data} question_start={props.question_start} question_end={props.question_end} />
            </View>
        </View>
    )
}

const QuestionAnalyticsTableRow = (props) => {
    const rows = props.items.map((item, index) => {
        if (index >= props.question_start && index <= props.question_end) {
            let question = item.question.replace("<!DOCTYPE html>",""); 
            return (
                <View style={styles2.tableRow}>
                    <View style={{ ...styles2.tpTableCol1, borderRight: '1px solid #e6e6e6' }}>
                        <Text style={styles2.tableValueCell1}>{index + 1}</Text>
                    </View>
                    <View style={{ ...styles2.qaTableCol2, borderRight: '1px solid #e6e6e6' }}>
                        <Text style={styles2.tpTableValueCell1}>
                            <Html style={styles2.tpTableValueCell1}>{question ? question : ""}</Html>
                        </Text>
                        {
                            item.image 
                            ? (
                                <Image src={item.image} style={styles2.question_img} alt="image" />
                            )
                            : ''
                        }
                    </View>
                    <View style={styles2.qaTableCol4}>
                        <Text style={styles2.tableValueCell1}>{item.correct_count ? item.correct_count : 0.0}</Text>
                    </View>
                </View>
            )
        }
    })
    return <Fragment>{rows}</Fragment>;
}

// Basic LaTeX parser for demonstration purposes
function parseLatex(input) {
    // Replace LaTeX syntax with corresponding React components
    // This is a simplified example, you might need to implement a more complete parser
    const latexToReact = {
        '\\textbf': (content) => <Text style={{ fontWeight: 'bold' }}>{content}</Text>,
        '\\textit': (content) => <Text style={{ fontStyle: 'italic' }}>{content}</Text>,
        // Add more LaTeX commands here
    };

    const regex = /\\(.*?)\{(.*?)\}/g;
    const parsed = input.replace(regex, (_, command, content) => {
        const reactComponent = latexToReact[command];
        return reactComponent ? reactComponent(content) : content;
    });

    return parsed;
}