import React, { useEffect, useState } from "react";
import {useDispatch, useSelector } from "react-redux";
import ModalwithButtons from "../common/ModalwithButtons";
import SendSurvey from "./SendSurvey";
import { setNotification } from "../../store/reducers/common.reducer";
import { setSelectedSendSurvey } from "../../store/reducers/facultyPerformanceReview.reducer";
import moment from 'moment';
import { createSubjectFeedbacks } from "../../store/actions/facultyPerformanceReview.actions";

export default function SendSurveyModal({
  openSendSurveyModal,
  handleSendSurveyModalClose,
  setSendSurveyMode,
  setOpenSendSurveyModal,
}) {
  const { selectedSendSurvey } = useSelector((state) => state.FacultyPerformanceReview);
  const [startDate,setStartDate] = useState(moment().add(1, 'minutes'));
  const [endDate,setEndDate] = useState();
  const [errorMessage,setErrorMessage] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedSendSurvey?.length < 1) {
      setOpenSendSurveyModal(false);
    }
  }, [selectedSendSurvey,setOpenSendSurveyModal]);

  const handleSendSurveyAPI = async () =>{

    // Validating Start and End Times.
    let errorMessage='';
    let validationError = false;
    setErrorMessage('')


    if(!endDate){
      validationError = true;
      // errorMessage.push('Please enter Start Date\n')
      errorMessage = errorMessage + 'Please enter Start Date.\n'  
    }
    if(!endDate){
      validationError = true;
      // errorMessage.push('Please enter End Date\n')
      errorMessage = errorMessage + 'Please enter End Date.\n'  
    }
    if (startDate < moment().add(-10, 'minutes')) {
      validationError = true;
      // errorMessage.push('Please select valid start date. Start Date cannot be before the current date.\n')
      errorMessage = errorMessage + 'Start Date cannot be before the current date.\n'
    }
    if (endDate < startDate) {
      validationError = true;
      // errorMessage.push('Please select valid End Date. End Date cannot be before the Start Date.\n')
      errorMessage = errorMessage + 'End Date cannot be before the Start Date.\n'
    }

    console.log(validationError);
    console.log(JSON.stringify(errorMessage));


    if(validationError){
      dispatch( setNotification({ 
        openNotification: true,
        notificationMessage: errorMessage,
        // notificationMessage: errorMessage.map((error, index)=>{
        //   return <li key={index}>{error}</li>
        // }),
        notificationType: "warning",
      }));

      setErrorMessage(errorMessage)
      
      return ;
    }

    // console.log(selectedSendSurvey)
    const selectedSendSurveyAPIData = selectedSendSurvey.map(({college_account_id,subject_semester_id,unit_id,section_id,elective})=>{
      return {
        college_account_id,
        subject_semester_id,
        unit_id,
        section_id : !elective ? section_id : "Null",
        elective_section_id : elective ? section_id : "Null",
        start_time : startDate?.format("YYYY-MM-DD hh:mm:ss"),
        doe : endDate?.format("YYYY-MM-DD hh:mm:ss")
      }
    })

    // console.log(selectedSendSurveyAPIData)
    const response = await dispatch(createSubjectFeedbacks(selectedSendSurveyAPIData));
    console.log(response.data)

    const {sections_skipped_no_students,sections_created} = response?.data;

    if (response?.data?.status  !== 200) {
      dispatch( setNotification({ 
        openNotification: true,
        notificationMessage: `Error : ${response?.data?.message}, Status : ${response?.data?.status}, Sending Faculty Performance Surveys Failed. Please try again.`,
        notificationType: "error",
      }));
      
      return ;
    }
    
    // If No Feedbacks are sent as of any reason, probably cause no students exist in the section.
    if (response?.data?.status  === 200 && sections_skipped_no_students?.length > 0) {
      let errorMessage = null;
      sections_created?.length === 0 ? 
      errorMessage = `No Students exist in the selected sections. Could not send any feedbacks.` 
      : 
      errorMessage = `Successfully sent Faculty Performance Surveys.\n Some Sections don't have Students. Could not send feedback to some of these sections.`;
      
      dispatch( setNotification({ 
        openNotification: true,
        notificationMessage: errorMessage,
        notificationType: "warning",
      }));
    }

    else if(response?.data?.status  === 200 && sections_skipped_no_students?.length === 0){
      dispatch( setNotification({ 
        openNotification: true,
        notificationMessage: `Successfully sent Faculty Performance Surveys.`,
        notificationType: "success",
      }));
    }

    if(setSendSurveyMode){
      setSendSurveyMode(false)
    }
    dispatch(setSelectedSendSurvey({selectedSendSurvey : []}));
  }


  return (
    <ModalwithButtons
      open={openSendSurveyModal}
      onClose={handleSendSurveyModalClose}
      submitLabel={"Send Surveys"}
      onSubmit={handleSendSurveyAPI}
      title={"Please verify and send the surveys."}
      submitButtonType="primary"
      errorMessage={errorMessage}
    >
      <SendSurvey
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    </ModalwithButtons>
  );
}
