import React from "react";
import { useLocation } from "react-router-dom";

import Navbar from "../components/navbar/navbar.component";
import GenerateTeams from "../components/generateTeams/generateTeams.component";

const GenerateTeamsPage = () => {
    const location = useLocation();
    return (
        <div className="weekly-challenge" style={{overflowX:"hidden"}}>
            <Navbar />
            <div className="row" style={{marginTop:'70px',backgroundColor:'#f9f9f9'}}>
                <GenerateTeams challengeDetails={location.state.challenge_details} allTeamsData={location.state.teams_data} weeklyChallengeDetails={location.state.weekly_challenge_details}/>
            </div>
        </div>
    );
}

export default GenerateTeamsPage;