import { createSlice } from "@reduxjs/toolkit";

const commonReducer = createSlice({
  name: "Common",
  initialState: {
    openNotification: false,
    notificationMessage: null,
    notificationType: null,
    user:null,
    facultyWebBaseUrl:null,
  },
  reducers: {
    setUserData: (state, action) => {
      const {user} = action.payload;
      state.user = user;
      return state;
    },
    setOpenNotification: (state, action) => {
      state.openNotification = action.payload;
      return state;
    },
    setNotificationMessage: (state, action) => {
      state.notificationMessage = action.payload;
      return state;
    },
    setNotificationType: (state, action) => {
      state.notificationType = action.payload;
      return state;
    },
    setNotification: (state, action) => {
      const { openNotification, notificationMessage, notificationType } = action.payload;
      state.openNotification = openNotification;
      state.notificationMessage = notificationMessage;
      state.notificationType = notificationType;
      return state;
    },
    setFacultyWebBaseUrl: (state, action) => {
      // console.log(action.payload)
      const facultyWebBaseUrl = action.payload;
      state.facultyWebBaseUrl = facultyWebBaseUrl;
      return state;
    },
  },
});

export const {
  setOpenNotification,
  setNotificationMessage,
  setNotificationType,
  setNotification,
  setUserData,
  setFacultyWebBaseUrl
} = commonReducer.actions;
export default commonReducer.reducer;
