import React, { useEffect } from "react";
import FacPerRevPage from "../components/FacultyPerformanceReview/FacPerRevPage";
import LayoutwithSideNav from "../components/common/LayoutwithSideNav";
import MaterialReduxNotification from "../components/common/MaterialReduxNotification";
import { useParams, useLocation } from "react-router-dom";
import UnitWiseReview from "../components/FacultyPerformanceReview/UnitWiseReview";
// import { setSelectedSendSurvey } from "../store/reducers/facultyPerformanceReview.reducer";
// import { useDispatch, useSelector } from "react-redux";
import SendSurveyFullWidth from "../components/FacultyPerformanceReview/SendSurveyFullWidth";

// const drawerWidth = 200;

export default function FacultyPerformanceReviewPage() {

  const {subsemId, facId, sectionId} = useParams();
  // const dispatch = useDispatch();
  const location = useLocation()
  // console.log(subsemId)
  // console.log(facId)

  const sendSurveyValidation = location.pathname.split('/').includes('sendSurvey');
  // const {selectedSendSurvey} = useSelector((state) => state.FacultyPerformanceReview);

  // console.log(location.pathname);
  // console.log(exp);
  useEffect(() => {
    // Setting the Title

    document.title = !sendSurveyValidation ? "Faculty Performance Review" : "Send Survey - Faculty Performance Review";    
    return (event) => {
      // Anything in here is fired on component unmount.
      console.log("Faculty Performance Review Unmounted");
      //Resetting selected send survey data.
      // if(selectedSendSurvey?.length >0){
      //   alert('You have lost the selected faculty units you wanted to send surveys to.')
      // }
      // window.onbeforeunload = () => true
      // dispatch(setSelectedSendSurvey({ selectedSendSurvey: [] }));
    };
  }, [sendSurveyValidation]); 
  


  return (
    <LayoutwithSideNav>
      {/* <div className="col-lg-6 col-md-6 col-sm-6"> */}
      {!subsemId && !sendSurveyValidation && <FacPerRevPage/>}
      {subsemId && facId && sectionId && <UnitWiseReview/>}
      {sendSurveyValidation && <SendSurveyFullWidth/>}
      <MaterialReduxNotification/>
    </LayoutwithSideNav>
  );
}

// Pagination i.e bottom page indexing needs to be added.
// SideNav bar to be made responsive.
