import React from "react";

import Navbar from "../components/navbar/navbar.component";
import UserChallengeLobby from "../components/participants/userChallengeLobby/userChallengeLobby.component";

const UserChallengeLobbyPage = () => {
    return (
        <div className="weekly-challenge" style={{ overflowX: "hidden" }}>
            <Navbar />
            <div className="row" style={{ marginTop: '70px', backgroundColor: '#f9f9f9' }}>
                <UserChallengeLobby />
            </div>
        </div>
    )
}

export default UserChallengeLobbyPage;