import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
// import { purple } from '@mui/material/colors';


const BootstrapButton = styled(Button)(({type}) =>({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  minWidth : "100px", 
  // maxWidth : '150px',
  padding: '6px 12px',
  border: type === 'secondary' ? `1px solid grey` : '1px solid #ffdfd6',
  lineHeight: 1.3,
  // height : '35px',
  backgroundColor: type === 'secondary' ? `grey` : '#ffdfd6 !important',
  // backgroundColor: type === 'secondary' ? `#ffffff !important` : '#ffdfd6 !important',
  // borderColor: '#ffdfd6',
  borderRadius : '15px',
  color : type === 'secondary' ? `white` : 'black',
  // color : 'black',
  // fontFamily: [
  //   '-apple-system',
  //   'BlinkMacSystemFont',
  //   '"Segoe UI"',
  //   'Roboto',
  //   '"Helvetica Neue"',
  //   'Arial',
  //   'sans-serif',
  //   '"Apple Color Emoji"',
  //   '"Segoe UI Emoji"',
  //   '"Segoe UI Symbol"',
  // ].join(','),
  '&:hover': {
    backgroundColor: type === 'secondary' ? `#505050 !important` : '#fac7be !important',
    // borderColor: '#ffdfd6',
    boxShadow: 'none',
  },
//   '&:active': {
//     boxShadow: 'none',
//     backgroundColor: '#0062cc',
//     borderColor: '#005cbf',
//   },
//   '&:focus': {
//     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
//   },
}));

// const ColorButton = styled(Button)(({ theme }) => ({
//   color: theme.palette.getContrastText(purple[500]),
//   backgroundColor: purple[500],
//   '&:hover': {
//     backgroundColor: purple[700],
//   },
// }));

{/* <ColorButton variant="contained">Custom CSS</ColorButton> */}

MaterialButton.defaultProps ={
  label : 'Button',
  onClick : null,
  sx : {},
  primary : 'primary',
  disabled : false
}

export default function MaterialButton({label, onClick, sx, type,disabled}) {
  return (
      <BootstrapButton type={type} sx={sx} onClick={onClick} disabled={disabled} variant="contained">
        {label}
      </BootstrapButton>
  );
}
