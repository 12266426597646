import * as React from 'react';
// import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { customBoxShadow } from '../../lib/utils';
import CustomChip from '../common/CustomChip';

// const bull = (
//   <Box
//     component="span"
//     sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
//   >
//     •
//   </Box>
// );

export default function SectionDetailsCard({selectedSectionDetails}) {
  return (
    <Card sx={{ maxWidth:'100%', mt:1, boxShadow: customBoxShadow,borderRadius:'20px'}}>
      <CardContent>
        <Typography variant="h6" component="div">
          Section Being Added To
        </Typography>
        <Divider variant="middle" sx={{ml:0, color:'black', opacity:'100%'}}/>

        <Typography component={'div'} sx={{ mt:1.5, fontSize: 14 }} color="text.secondary" gutterBottom>
          Deparment : < CustomChip label={selectedSectionDetails?.department_name}/>

        </Typography>
        <Typography component={'div'} sx={{ mt:1.5, fontSize: 14 }} color="text.secondary" gutterBottom>
          Semester : <CustomChip label={selectedSectionDetails?.semester_id}/>
        </Typography>
        <Typography component={'div'} sx={{ mt:1.5, fontSize: 14 }} color="text.secondary" gutterBottom>
          Subject : <CustomChip label={selectedSectionDetails?.subject_name}/>
        </Typography>
        <Typography component={'div'} sx={{ mt:1.5, fontSize: 14 }} color="text.secondary" gutterBottom>
          Section : <CustomChip label={selectedSectionDetails?.section_name}/>
        </Typography>
        {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
          adjective
        </Typography>
        <Typography variant="body2">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography> */}
      </CardContent>
    </Card>
  );
}
