import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button"
import { FaPlus } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import Select from "react-select";
import "./SubjectFeedback.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RichTextEditor from "react-rte";
import moment from 'moment';
import { addSubCategory, fetchAllData } from "../../../api/facultyfeedbackapis";
import Navbar from "../../navbar/navbar.component";
import LayoutwithSideNav from "../../common/LayoutwithSideNav";

const SubjectFeedback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [categoryList, setCategoryList] = useState([]);
  const [defaultCategoryList, setDefaultCategoryList] = useState(location.state ? location.state.category_list : []);
  const [title, settitle] = useState(location.state ? location.state.title : "");
  const [name, setName] = useState("");
  const [Description, setDescription] = useState(location.state ? RichTextEditor.createValueFromString(location.state.Description, 'html') : RichTextEditor.createEmptyValue());
  const [selectedCategoryList, setselectedCategoryList] = useState(location.state ? location.state.category_list : []);
  const [showAddSubCategories, setShowAddSubCategories] = useState(false);
  const [show, setShow] = useState(false);
  const [showCatSelected, setShowCatSelected] = useState("show");
  const [startTime, setStartTime] = useState(location.state ? location.state.startTime : moment().format('YYYY-MM-DDTHH:mm'));
  const [endTime, setEndTime] = useState(location.state ? location.state.endTime : "");
  const [scale, setScale] = useState(location.state ? location.state.scale : 10);
  const [ratingScale, setRatingScale] = useState(location.state ? location.state.ratingScale : 10);
  const minDate = moment().format("YYYY-MM-DDTHH:mm");
  const scales = [
    {
      value: 5,
      label: '1-5'
    },
    {
      value: 10,
      label: '1-10'
    },
    {
      value: 50,
      label: '1-50'
    },
    {
      value: 100,
      label: '1-100'
    }
  ];

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' }
    ]
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const category_list = [];

  const fetchData = async () => {
    const fdata = await fetchAllData();
    for (let i = 0; i < fdata.data.length; i++) {
      const id = fdata.data[i].id;
      const key = fdata.data[i].name;
      category_list.push({ value: id, label: key });
    }
    setCategoryList(category_list);
    // const newData = await response.json();
  };
  const getCategory = (e) => {
    setName(e.target.value);
  };
  useEffect(() => {
    if (categoryList.length === 0) {
      fetchData();
    }
  }, []);
  const handleChange = (event) => {
    setselectedCategoryList(event);
  };
  const addCategory = async (e) => {
    e.preventDefault();
    const addCat = await addSubCategory(name);

    fetchData();
    setName("");
    setShow(false);
  };
  const ChangeComponent = () => {
    const desc = JSON.stringify(Description.toString("html")).replace(/<\/?p>/g, '').slice(1, -1)
    if (title === "" || desc === "" || startTime === "" || endTime === "" || scale === "" || ratingScale === "") {
      toast.error("Please enter all details", {
        position: "top-right",
      });
    }
    else {
      navigate("/questions", {
        state: {
          list: selectedCategoryList,
          title: title,
          Description: desc,
          startTime: startTime,
          endTime: endTime,
          scale: scale,
          ratingScale: ratingScale
        }
      });
    }
  };
  //  console.log(category_list);
  const setShowCategories = (e) => {
    e.preventDefault();
    setShowAddSubCategories(true);
    setShowCatSelected("showSelected");
  };
  const sethideCategories = (e) => {
    e.preventDefault();
    setselectedCategoryList([]);
    setShowAddSubCategories(false);
    setShowCatSelected("show");
  };
  const handletitle = (e) => {
    settitle(e.target.value);
  };
  const handleDescription = (newDesc) => {
    setDescription(newDesc);
  };
  const handleStartTime = (e) => {
    let datetime = e.target.value;
    datetime = datetime.replace("T", " ");
    setStartTime(datetime);
  };
  const handleEndTime = (e) => {
    let datetime = e.target.value;
    datetime = datetime.replace("T", " ");
    setEndTime(datetime);
  };
  const handleScale = (e) => {
    setScale(e.value);
  };
  const handleRatingScale = (e) => {
    setRatingScale(e.value);
  };
  // console.log(title, Description);
  return (
    <div class="subject-feedback-page">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <Navbar />
        </div>
      </div>

      <div className="container-fluid px-0">
        <LayoutwithSideNav>
          <div className="subject-feedback-creation-div mb-4">
            <Modal show={show} onHide={handleClose} className="mt-5">
              <Modal.Header closeButton>
                <Modal.Title>Add new category</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <label for="categiry-title-objective"><strong>Category</strong></label>
                  <Form.Control type="text" className="mt-2" name="category" onChange={getCategory} placeholder="Example: Cleanliness, Co-Curricular activities, etc..." />
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" id="addNewCatModalCloseBtn" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" id="addNewCatBtn" onClick={addCategory}>
                  Add
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10">
                <h2 className="mt-1">
                  <p className="font-weight-bold" id="courseName">Subject Feedback</p>
                </h2>
                <hr className="titlehr" />
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <Button id="next" className="btn btn-primary shadow" onClick={ChangeComponent}>
                  Next
                  &nbsp;<BsArrowRight />
                </Button>
              </div>
            </div>

            <div className="container-fluid">
              <div className="form-group createFeedback">
                <label className="col-form-label col-form-label-lg" for="title-objective">Title<span
                  style={{ color: "red" }}>*</span></label>
                <input className="form-control form-control-lg" defaultValue={title} type="text" id="feedback-title" onChange={handletitle} />
                <label className="col-form-label col-form-label-lg" for="desc-objective">Description<span
                  style={{ color: "red" }}>*</span></label>
                {/* <textarea className="form-control form-control-lg" defaultValue={Description} type="text" id="feedback-description" onChange={handleDescription}></textarea> */}
                <RichTextEditor toolbarConfig={toolbarConfig} className="RTE" editorClassName="my-editor" value={Description} onChange={handleDescription} />
                <div className="row">
                  <div className="col-lg-6 col-md-6 colsm-6">
                    <label className="col-form-label col-form-label-lg" for="start-time-objective">Start Time<span
                      style={{ color: "red" }}>*</span></label>
                    <input className="form-control form-control-md" defaultValue={startTime} type="datetime-local" step="60"
                      placeholder="Time" style={{ height: "45px" }} min={minDate} onChange={handleStartTime} />
                  </div>
                  <div className="col-lg-6 col-md-6 colsm-6">
                    <label className="col-form-label col-form-label-lg" for="end-time-objective">End Time<span
                      style={{ color: "red" }}>*</span></label>
                    <input className="form-control form-control-md" defaultValue={endTime} type="datetime-local" step="60"
                      placeholder="Time" style={{ height: "45px" }} min={startTime} onChange={handleEndTime} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 colsm-6">
                    <label className="col-form-label col-form-label-lg" for="select-scale-objective">Select Grading Scale<span
                      style={{ color: "red" }}>*</span></label>
                    <Select onChange={handleScale} classNamePrefix="select" defaultValue={{ "value": scale, "label": scale !== "" ? `1-${scale}` : "select" }} options={scales} className="dropdown mb-4">
                    </Select>
                  </div>
                  <div className="col-lg-6 col-md-6 colsm-6">
                    <label className="col-form-label col-form-label-lg" for="select-scale-objective">Select Rating Scale<span
                      style={{ color: "red" }}>*</span></label>
                    <Select onChange={handleRatingScale} classNamePrefix="select" defaultValue={{ "value": ratingScale, "label": ratingScale !== "" ? `1-${ratingScale}` : "select" }} options={scales} className="dropdown mb-4">
                    </Select>
                  </div>
                </div>


                <div>
                  <span className="mt-4">Do you have sub-categories in the feedback?</span>
                  <button id={showCatSelected} className="btn btn-primary col-lg-8" onClick={setShowCategories} type="button">Yes</button>
                  <Button id="show2" className="col-lg-8" onClick={sethideCategories} type="button">No</Button>

                  {
                    showAddSubCategories && (
                      <div className="row mb-5">
                        <label className="col-form-label col-form-label-lg" for="Choose-category-objective">Choose Categories</label>
                        <div className="col-lg-5 col-md-5 col-sm-5">
                          <Select
                            className="categories lg"
                            name="category"
                            classNamePrefix="select"
                            onChange={handleChange}
                            isMulti
                            options={categoryList}
                            defaultValue={defaultCategoryList}
                          />
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2">
                          <Button variant="primary" onClick={handleShow} id="addnewcategory">
                            Add new category <FaPlus />
                          </Button>
                        </div>

                      </div>
                    )
                  }
                </div>

              </div>
            </div>

            <ToastContainer />
          </div>
        </LayoutwithSideNav>

      </div>
    </div >
  );
};
export default SubjectFeedback;
