import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import Alert from 'react-bootstrap/Alert';
import { IoArrowBackOutline } from "react-icons/io5";
import SimpleDateTime from "react-simple-timestamp-to-date";
import "./AllWeeklyChallenges.styles.css";
import { getAllWeeklyChallenges, getDepartments, getSemesters, getFaculty, addNewCoordinator, getWeeklyGeneralCoordinators, getDashBoardDetails } from "../../api/weeklyChallengeAPIs";
import { deleteGeneralCoordinatorApi, current_user_id } from "../../api/weeklyChallengeAPIs";
import WeeklyChallengeCard from "../weeklyChallengeCard/weeklyChallengeCard.component";
import UserWeeklyChallengeCard from "../weeklyChallengeCard/userWeeklyChallengeCard";
import { BASE_WEBSITE_URL } from "../../pages/IndexPage";

const AllWeeklyChallenges = () => {
    const navigate = useNavigate();
    const [is_admin, setIsAdmin] = useState(parseInt(localStorage.getItem("is_admin")) === 1 ? true : false);
    const [is_coordinator, setIsCoordinator] = useState(parseInt(localStorage.getItem("is_coordinator")) === 1 ? true : false);
    const [allWeeklyChallenges, setAllWeeklyChallenges] = useState([]);
    const [userWeeklyChallenges, setUserWeeklyChallenges] = useState([]);
    const [showClgCoordinatorsModal, setShowClgCoordinatorsModal] = useState(false);
    const [showClgAddCoordinatorsModal, setShowClgAddCoordinatorsModal] = useState(false);
    const [allDepartments, setAllDepartments] = useState(null);
    const [allSemesters, setAllSemesters] = useState(null);
    const [allFaculty, setAllFaculty] = useState(null);
    const [newCoordinators, setNewCoordinators] = useState([]);
    const [isSuccessAlertShow, setIsSuccessAlertShow] = useState(false);
    const [isFailAlertShow, setIsFailAlertShow] = useState(false);
    const [allWeeklyGeneralCoordinators, setAllWeeklyGeneralCoordinators] = useState(null);
    const [challengeListSize, setChallengeListSize] = useState(15);
    const [dashBoardDetails, setDashBoardDetails] = useState(null);
    const [flag1, setFlag1] = useState(true);
    const [flag, setFlag] = useState(true);

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "numeric", day: "numeric", hour: 'numeric', hour12: false, minute: "numeric", second: "numeric" }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    //get all the Arenas list created by the admin
    const getWeeklyChallenges = async () => {
        const challenges = await getAllWeeklyChallenges();
        let weeklyChallenges_list = [];
        for (let i = 0; i < challenges.data.length; i++) {
            const id = challenges.data[i].id;
            const title = challenges.data[i].title;
            const description = challenges.data[i].description;
            const start_date = challenges.data[i].start_date;
            const is_completed = challenges.data[i].is_completed;
            //start_date = Moment(start_date).format("DD-MM-YYYY");
            const date = formatDate(start_date);
            weeklyChallenges_list.push({ id: id, title: title, desc: description, start_date: date, is_completed: is_completed });
        }
        setAllWeeklyChallenges([]);
        setAllWeeklyChallenges(weeklyChallenges_list);
    };

    const handleGetDashBoardDetails = async () => {
        const year = new Date().getFullYear();
        const month = new Date().getMonth();
        const day = new Date().getDate();
        const hrs = new Date().getHours();
        const mins = new Date().getMinutes();
        const secs = new Date().getSeconds();
        let present_date = year + "-" + (month + 1) + "-" + day + " " + hrs + ":" + mins + ":" + secs;
        console.log(present_date);
        const dashboard = await getDashBoardDetails(present_date, 30, "");
        console.log(dashboard.activity_tab);
        let challenge_list = [];
        for (let i = 0; i < dashboard.activity_tab.length; i++) {
            if (dashboard.activity_tab[i].type === "Weekly_Challenge") {
                challenge_list.push(dashboard.activity_tab[i]);
            }
        }
        console.log(challenge_list);
        setUserWeeklyChallenges(challenge_list);
    };

    useEffect(() => {
        if (allWeeklyChallenges.length === 0 && is_admin && flag1) {
            getWeeklyChallenges();
            setFlag1(false);
        }
        if (userWeeklyChallenges.length === 0 && flag) {
            handleGetDashBoardDetails();
            setFlag(false);
        }
    });

    const handleClgCoordinatorsModalShow = async () => {
        setShowClgCoordinatorsModal(true);
        const faculty_list = await getWeeklyGeneralCoordinators();
        setAllWeeklyGeneralCoordinators(faculty_list.data);
    };
    const handleClgCoordinatorsModalClose = () => {
        setShowClgCoordinatorsModal(false);
    };
    const handleClgAddCoordinatorsModalShow = async () => {
        setShowClgCoordinatorsModal(false);
        setShowClgAddCoordinatorsModal(true);

        const departments_list = [];
        const departments = await getDepartments();
        for (let i = 0; i < departments.data.length; i++) {
            const id = departments.data[i].id;
            const key = departments.data[i].name;
            departments_list.push({ value: id, label: key });
        }
        setAllDepartments(departments_list);
    }
    const handleClgAddCoordinatorsModalClose = async () => {
        setShowClgAddCoordinatorsModal(false);
        setShowClgCoordinatorsModal(true);
        const faculty_list = await getWeeklyGeneralCoordinators();
        setAllWeeklyGeneralCoordinators(faculty_list.data);
        setIsSuccessAlertShow(false);
        setIsFailAlertShow(false);
    }

    const handleGetFaculty = async (e) => {
        const faculty = await getFaculty(e.value);
        const faculty_list = [];
        for (let i = 0; i < faculty.data.length; i++) {
            const id = faculty.data[i].college_account_id;
            const name = faculty.data[i].faculty_name;
            faculty_list.push({ value: id, label: name });
        }
        setAllFaculty(faculty_list);
    }

    const getAllSemesters = async (e) => {
        const semesters = await getSemesters(e.value);
        const semesters_list = [];
        for (let i = 0; i < semesters.data.length; i++) {
            const id = semesters.data[i].id;
            semesters_list.push({ value: id, label: "Semester " + id });
        }
        setAllSemesters(semesters_list);
    }

    const handleSaveNewCoordinator = async (e) => {
        e.preventDefault();
        if (newCoordinators.length === 0) {
            setIsFailAlertShow(true);
            setIsSuccessAlertShow(false);
            return;
        } else {
            const coordinators_list = [];
            for (let i = 0; i < newCoordinators.length; i++) {
                const id = newCoordinators[i].value;
                coordinators_list.push(parseInt(id));
            }
            addNewCoordinator(coordinators_list);
            setIsSuccessAlertShow(true);
            setIsFailAlertShow(false);
        }
    };

    const handleAddCoordinators = (e) => {
        setNewCoordinators(e);
        setIsSuccessAlertShow(false);
        setIsFailAlertShow(false);
    };

    const handleDeleteGeneralCoordinator = async (e) => {
        const result = await deleteGeneralCoordinatorApi(parseInt(e.target.id));
        if (result.status === 200 && result.message === "Success") {
            const faculty_list = await getWeeklyGeneralCoordinators();
            setAllWeeklyGeneralCoordinators(faculty_list.data);
        }
    };

    // const handleTakeToHome = () => {
    //     window.open(`${BASE_WEBSITE_URL}`, "_self");
    //     localStorage.removeItem("token");
    //     localStorage.removeItem("is_admin");
    //     localStorage.removeItem("is_coordinator");
    //     localStorage.removeItem('profile_details');
    // };

    return (
        <div className="all-weekly-challenges w-100">
            <div className="container-fluid px-0">
                <div className="internal-padding">
                    <div className="headerBanner px-3">
                        <div className="row w-100">
                            <div className="col-lg-7 col-md-7 col-sm-7">
                                <h2 className="mt-0">
                                    <p className="font-weight-bold" id="courseName">Your Arenas</p>
                                    <hr className="titlehr" />
                                </h2>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-5 d-flex align-items-center justify-content-end">
                                {
                                    is_admin && (
                                        <div>
                                            <button className="btn btn-primary shadow-sm" id="addGeneralCoordinatorsBtn" onClick={handleClgCoordinatorsModalShow}>Add Coordinators to College</button>
                                            <Link className="btn btn-primary shadow-sm" id="createChallengeBtn" to="/createWeeklyChallenge">Create Arena</Link>
                                        </div>
                                    )
                                }
                            </div>

                            <Modal show={showClgCoordinatorsModal} onHide={handleClgCoordinatorsModalClose} scrollable dialogClassName="addClgCoordinatorsmodalWidth" style={{marginTop:'55px'}}>
                                <Modal.Header>
                                    <Modal.Title>Add Coordinators to College</Modal.Title>
                                    <button className="btn btn-primary shadow-sm" id="addnewChallengeCoordinatorsBtn"
                                        onClick={handleClgAddCoordinatorsModalShow}>
                                        Add Coordinator
                                    </button>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        {
                                            allWeeklyGeneralCoordinators != null
                                                ? (
                                                    <table className="table table-bordered table-curved" id="general-coordinators-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Department</th>
                                                                <th scope="col">Remove</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                allWeeklyGeneralCoordinators.map(coordinator => (
                                                                    <tr>
                                                                        <td scope="row">{coordinator.name}</td>
                                                                        <td scope="row">{coordinator.department_name}</td>
                                                                        <td scope="row" className="d-flex align-items-center justify-content-center">
                                                                            <button className="btn btn-default" id={coordinator.id} onClick={handleDeleteGeneralCoordinator}>X</button>
                                                                        </td>
                                                                    </tr>)
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                )
                                                : (<span className="d-flex justify-content-center" id="no-coordinators-msg-label">No Coordinators added yet!</span>)
                                        }

                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClgCoordinatorsModalClose} id="modalCloseBtn" style={{ width: '25%' }}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showClgAddCoordinatorsModal} onHide={handleClgAddCoordinatorsModalClose} scrollable dialogClassName="saveClgCoordinatorsmodalWidth" style={{marginTop:'55px'}}>
                                <Modal.Header>
                                    <Modal.Title>Add Coordinators to College</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {
                                        isSuccessAlertShow && (
                                            <Alert variant="success" onClose={() => setIsSuccessAlertShow(false)} dismissible>
                                                <p>
                                                    Coordinator has been added Successfully!
                                                </p>
                                            </Alert>
                                        )
                                    }
                                    {
                                        isFailAlertShow && (
                                            <Alert variant="danger" onClose={() => setIsFailAlertShow(false)} dismissible>
                                                <p>
                                                    Choose Coordinators to add!
                                                </p>
                                            </Alert>
                                        )
                                    }
                                    <form action="" method="post" className="custom-coordinators-form">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <label>Choose the Department</label>
                                                <Select
                                                    name="departments"
                                                    options={allDepartments}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={handleGetFaculty}
                                                />
                                                <label>Choose Coordinators</label>
                                                <Select
                                                    isMulti
                                                    name="colors"
                                                    options={allFaculty}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={handleAddCoordinators}
                                                />
                                            </div>
                                        </div>
                                        <button className="btn btn-primary shadow-sm" onClick={handleSaveNewCoordinator} id="savenewChallengeCoordinatorsBtn" >
                                            Add Coordinator
                                        </button>
                                    </form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClgAddCoordinatorsModalClose} id="modalCloseBtn" style={{ width: '25%' }}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                                {
                                    // is_admin
                                    //     ? allWeeklyChallenges.length > 0
                                    //         ? allWeeklyChallenges.map(challenge => <WeeklyChallengeCard key={challenge.id} id={challenge.id} title={challenge.title} desc={challenge.desc} start_date={challenge.start_date} is_completed={challenge.is_completed} />)
                                    //         : (<div className="d-flex justify-content-center no-challenges-msg-label">No Challenges created Yet!</div>)
                                    //     :
                                    userWeeklyChallenges.length > 0
                                        ? userWeeklyChallenges.map(challenge => <UserWeeklyChallengeCard key={challenge.id} challengeDetails={challenge} />)
                                        : (<div className="d-flex justify-content-center no-challenges-msg-label">No Arenas to show!</div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllWeeklyChallenges;