import React, { useState, useEffect } from "react";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import LayoutwithSideNav from "../../common/LayoutwithSideNav";
import "./feedbackSummaryReports.styles.css";
import { getFeedbackSummaryResults } from "../../../api/facultyfeedbackapis";
import { AiOutlineArrowDown } from "react-icons/ai";

const FeedbackSummaryReport = () => {

    const [summaryResults, setSummaryResults] = useState([]);

    useEffect(() => {
        if (summaryResults.length == 0) {
            getSummaryResults();
        }
    }, []);

    const getSummaryResults = async () => {
        const results = await getFeedbackSummaryResults();
        console.log(results.data);
        setSummaryResults(results.data.feedbacks);
    };


    const downloadSummaryReport = () => {
        const table = document.getElementById('myTable');
        const wb = XLSX.utils.table_to_book(table);
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const fileData = new Blob([wbout], { type: 'application/octet-stream' });
        saveAs(fileData, 'Feedback_Summary_Report.xlsx');
    };

    return (
        <LayoutwithSideNav>
            <div className="feedback-choose-timings-div">
                <div className="row">
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <h2 className="mt-1">
                            <p className="font-weight-bold" id="pageName">Feedback Summary Report</p>
                        </h2>
                        <hr className="titlehr" />
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2">
                        <button type="button" className="btn btn-default" id="downloadSummaryBtn" onClick={downloadSummaryReport}>Download <AiOutlineArrowDown /></button>
                    </div>
                </div>

                <div className="row">
                    <table className="table table-bordered shadow table-curved table-hover table-responsive{-sm|-md|-lg|-xl}" id="myTable">
                        <thead>
                            <tr className="thead2">
                                <th scope="col" style={{ textAlign: "center" }}>S.NO</th>
                                <th scope="col" style={{ textAlign: "center" }}>Department</th>
                                <th scope="col" style={{ textAlign: "center" }}>Section</th>
                                <th scope="col" style={{ textAlign: "center" }}>Subject</th>
                                <th scope="col" style={{ textAlign: "center" }}>Faculty Name</th>
                                <th scope="col" style={{ textAlign: "center" }}>overall Effectiveness</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                summaryResults.length != 0 ?
                                    summaryResults.map((summary, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{summary.branch_name}</td>
                                            <td>{summary.section_name}</td>
                                            <td>{summary.subject_name}</td>
                                            <td>{summary.faculty_name}</td>
                                            <td>{summary.overall_ffectiveness}</td>
                                        </tr>
                                    ))
                                    : ""
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </LayoutwithSideNav>
    )
}

export default FeedbackSummaryReport;