import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

HeadingSecondary.defaultProps = {
    divider : true,
    title : 'Secondary Heading',
}


export default function HeadingSecondary ({divider,title}) {
  return(
    <React.Fragment>
        <Grid sx={{py:1}} container spacing={2} justifyContent="space-between" alignItems="center" >
            <Grid item >
                <Typography align="left" variant="h5" sx={{mb:0}} gutterBottom>
                    {title}
                    {/* <hr className="titlehr" /> */}
                </Typography>
            </Grid>
        </Grid>
        {divider && <Divider variant="middle" sx={{ml:0, color:'black', opacity:'100%'}}/>}
    </React.Fragment>
  )
}