import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactCountdownClock from "react-countdown-clock";
import { configureAbly, useChannel, usePresence } from "@ably-labs/react-hooks";
import {
    getWeeklyChallengeData, getWeeklyChallengeDetails, setUnConfirmedCaptains, setWeeklyChallengeStatus, getLeaderBoard,
    getEnrolledMembers, generateTeams, getAllTeams, getTopics, addWeeklyChallengeParticipants
} from "../../api/weeklyChallengeAPIs";
import { RiTeamLine } from 'react-icons/ri';
import { TiTick } from "react-icons/ti";
import { current_user_id } from "../../api/weeklyChallengeAPIs";
import "./startChallenge.styles.css";


const StartChallenge = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    //const accessToken = localStorage.getItem("token");
    //configureAbly({ key: `https://stagingfacultypython.edwisely.com/weekly_challenge/weeklyChallengeAuth?weekly_challenge_id=${location.state.challenge_id}&token=${accessToken}`, clientId: current_user_id });
    const realtimePChannel = useSelector(state => state.joinChallenge.realtimePChannel);
    const channel = useSelector(state => state.joinChallenge.participantChannel);
    const viewer_channel = useSelector(state => state.joinChallenge.viewerChannel);
    //const [presenceData, updateStatus] = usePresence(channel);
    const [challengeDetails, setChallengeDetails] = useState(null);
    const [weeklyChallengeDetails, setWeeklyChallengeDetails] = useState(null);
    const [showChallengeParticipantsModal, setShowChallengeParticipantsModal] = useState(false);
    const [showTeamGenerationAlertModal, setShowTeamGenerationAlertModal] = useState(false);
    const [allChallengeParticipants, setAllChallengeParticipants] = useState([]);
    const [startTimer, setStartTimer] = useState(300);
    const [showStartTimer, setShowStartTimer] = useState(true);
    const [showStartTime, setShowStartTime] = useState(false);
    const [allTeamsData, setAllTeamsData] = useState([]);
    const [showTeamsGenerationLoadingAlertModal, setShowTeamsGenerationLoadingAlertModal] = useState(false);
    const [is_teamgeneration_loading, setIsTeamGenerationLoading] = useState(true);
    const [is_team_generation_confirmmsg, setIsTeamGenerationConfirmMsg] = useState(false);
    const [team_generation_confirmmsg, setTeamGenerationConfirmMsg] = useState('Teams generated Successfully!');
    const [showTimeAlertModal, setShowTimeAlertModal] = useState(false);
    const [enableStartButton, setEnableStartButton] = useState(false);
    const [captainsConfirmedTeams, setCaptainsConfirmedTeams] = useState([]);
    const [regenerateCaptainsButton, setRegenerateCaptainsButton] = useState(false);
    const [activeTeamMembers, setActiveTeamMembers] = useState([]);
    const [is_allcaptains_confirmed, setIsAllCaptainsConfirmed] = useState(false);
    const [showAddParticipantsLoadingAlertModal, setShowAddParticipantsLoadingAlertModal] = useState(false);
    const [is_addParticipants_loading, setIsAddParticipantsLoading] = useState(true);
    const [is_add_participants_confirmmsg, setIsAddParticipantsConfirmMsg] = useState(false);
    const [add_participants_confirmmsg, setAddParticipantsConfirmMsg] = useState("Participants added Successfully!")
    const [activeMembers, setActiveMembers] = useState([]);
    const [isParticipantsAdded, setIsParticipantsAdded] = useState(false);
    const [isParticipantsNotAdded, setIsParticipantsNotAdded] = useState(true);

    //console.log("presencedata : ", presenceData);

    useEffect(() => {
        if (challengeDetails === null) {
            getChallengeDetails();
        }

        if (channel) {
            channel.presence.subscribe((msg) => {
                console.log(msg);
                const member_status = msg.clientId.split(/[-]/);
                const member_id = parseInt(member_status[0]);
                const action_status = msg.action;
                if (member_id !== current_user_id) {
                    if (action_status === 'enter') {
                        if (!activeMembers.includes(member_id)) {
                            setActiveMembers(prevData => [...prevData, member_id]);
                        }
                    } else if (action_status === 'update') {
                        if (!activeMembers.includes(member_id)) {
                            setActiveMembers(prevData => [...prevData, member_id]);
                        }
                    } else if (action_status === 'present') {
                        if (!activeMembers.includes(member_id)) {
                            setActiveMembers(prevData => [...prevData, member_id]);
                        }
                    } else if (action_status === 'leave') {
                        const newMembers = activeMembers.filter((item) => item !== member_id);
                        setActiveMembers(newMembers);
                    }
                }
            });

            return function cleanup() {
                channel.presence.unsubscribe();
            };
        }

    });
    const getChallengeDetails = async () => {
        if (challengeDetails === null) {
            const challenge = await getWeeklyChallengeData(location.state.challenge_id);
            setChallengeDetails(challenge.data);
        }
        if (weeklyChallengeDetails === null) {
            const weekly_challenge_details = await getWeeklyChallengeDetails(location.state.challenge_id);
            setWeeklyChallengeDetails(weekly_challenge_details.data);

            const challenge_start_timer = ((new Date(weekly_challenge_details.data.start_date).getTime() - new Date().getTime()) / 1000);
            if (challenge_start_timer > 0) {
                setStartTimer(challenge_start_timer);
                setShowStartTimer(true);
                setShowStartTime(false);
            } else {
                setStartTimer(0);
                setShowStartTimer(false);
                setShowStartTime(true);
            }
        }
    }
    const handleChallengeParticipantsModalShow = async () => {
        setShowChallengeParticipantsModal(true);
        const enrollers = await getEnrolledMembers(location.state.challenge_id);
        let enrollers_list = [];
        for (let i = 0; i < enrollers.data.length; i++) {
            let participant_type, participant_id, participant_name, department_name, roll_number, section_name, semester_id;
            if (enrollers.data[i].college_account_id != '') {
                participant_type = "Facuty";
                participant_id = enrollers.data[i].college_account_id;
                participant_name = enrollers.data[i].faculty_first_name + " " + enrollers.data[i].faculty_last_name;
                department_name = enrollers.data[i].department_name;
                roll_number = "-";
                section_name = "-";
                semester_id = "-";
            } else {
                participant_type = "Student";
                participant_id = enrollers.data[i].student_id;
                participant_name = enrollers.data[i].student_name;
                department_name = enrollers.data[i].department_name;
                roll_number = enrollers.data[i].student_roll_number;
                section_name = enrollers.data[i].student_section_name;
                semester_id = enrollers.data[i].student_semester_id;
            }
            const status = "Active";
            enrollers_list.push({
                "participant_type": participant_type,
                "participant_id": participant_id,
                "participant_name": participant_name,
                "department_name": department_name,
                "roll_number": roll_number,
                "section_name": section_name,
                "semester_id": semester_id,
                "status": status,
            });
        }
        //console.log(enrollers_list);
        setAllChallengeParticipants(enrollers_list);
    };
    //console.log(allChallengeParticipants);
    const handleChallengeParticipantsModalClose = () => {
        setShowChallengeParticipantsModal(false);
    }

    const handleGenerateTeams = async () => {
        if (activeMembers.length > 0) {
            setShowTeamsGenerationLoadingAlertModal(true);
            const enrollers = await getEnrolledMembers(location.state.challenge_id);
            if (enrollers.data.length > 0) {
                let student_list = [];
                let faculty_list = [];
                for (let i = 0; i < enrollers.data.length; i++) {
                    if (enrollers.data[i].college_account_id != '') {
                        if (activeMembers.includes(enrollers.data[i].college_account_id)) {
                            faculty_list.push(parseInt(enrollers.data[i].college_account_id));
                        }
                    } else {
                        if (activeMembers.includes(enrollers.data[i].student_id)) {
                            student_list.push(parseInt(enrollers.data[i].student_id));
                        }
                    }
                }

                const teams_info = await generateTeams(student_list, faculty_list, parseInt(location.state.challenge_id));

                if (teams_info.message === "Success") {
                    channel.publish("team_generated", `${location.state.challenge_id}`);
                    const current_time = new Date().getTime();
                    //setShowTeamGenerationAlertModal(true);
                    setTeamGenerationConfirmMsg("Teams generated Successfully!");
                    setTimeout(function () {
                        setIsTeamGenerationLoading(false);
                        setIsTeamGenerationConfirmMsg(true);
                        channel.publish("show_team_timer", `${current_time}`);
                        handleGetAllTeams();
                    }, 1000);
                } else if (teams_info.message === "Teams already Generated") {
                    channel.publish("team_generated", `${location.state.challenge_id}`);
                    const current_time = new Date().getTime();
                    channel.publish("show_team_timer", `${current_time}`);

                    setTeamGenerationConfirmMsg("Teams already Generated!");
                    setIsTeamGenerationLoading(false);
                    setIsTeamGenerationConfirmMsg(true);
                    handleGetAllTeams();
                } else {
                    setTeamGenerationConfirmMsg("Unable to generate teams. Try again!");
                    setIsTeamGenerationLoading(false);
                    setIsTeamGenerationConfirmMsg(true);
                }
            } else {
                setTeamGenerationConfirmMsg("No one has enrolled yet!");
                setIsTeamGenerationLoading(false);
                setIsTeamGenerationConfirmMsg(true);
            }
            setTimeout(function () {
                setShowTeamsGenerationLoadingAlertModal(false);
            }, 2000);
        }

    };

    const handleTeamsGenerationLoadingAlertModalClose = () => setShowTeamsGenerationLoadingAlertModal(false);
    //hiding team generation alert modal.
    const handleTeamGenerationAlertModalClose = () => setShowTeamGenerationAlertModal(false);

    //adding members into participants table when participation_type = 0
    const handleAddParticipants = async () => {
        if (activeMembers.length > 0) {
            setShowAddParticipantsLoadingAlertModal(true);
            const enrollers = await getEnrolledMembers(location.state.challenge_id);
            console.log(enrollers.data);
            if (enrollers.data.length > 0) {
                let student_list = [];
                let faculty_list = [];
                for (let i = 0; i < enrollers.data.length; i++) {
                    if (enrollers.data[i].college_account_id != '') {
                        if (activeMembers.includes(enrollers.data[i].college_account_id)) {
                            faculty_list.push(parseInt(enrollers.data[i].college_account_id));
                        }
                    } else {
                        if (activeMembers.includes(enrollers.data[i].student_id)) {
                            student_list.push(parseInt(enrollers.data[i].student_id));
                        }
                    }
                }
                const teams_info = await addWeeklyChallengeParticipants(student_list, faculty_list, parseInt(location.state.challenge_id));
                if (teams_info.mssage === "Successfully Added Participants") {
                    setAddParticipantsConfirmMsg("Participants added Successfully!");
                    setIsParticipantsAdded(true);
                    setIsParticipantsNotAdded(false);
                    setTimeout(function () {
                        setIsAddParticipantsLoading(false);
                        setIsAddParticipantsConfirmMsg(true);
                    }, 1000);
                } else if (teams_info.message === "Participants already added") {
                    setIsParticipantsAdded(true);
                    setIsParticipantsNotAdded(false);
                    setTimeout(function () {
                        setIsAddParticipantsLoading(false);
                        setIsAddParticipantsConfirmMsg(true);
                        setAddParticipantsConfirmMsg("Participants already added!");
                    }, 1000);
                } else {
                    setTimeout(function () {
                        setIsAddParticipantsLoading(false);
                        setIsAddParticipantsConfirmMsg(true);
                        setAddParticipantsConfirmMsg("Unable to add participants.Try again!");
                    }, 1000);
                }
                setTimeout(function () {
                    setShowAddParticipantsLoadingAlertModal(false);
                }, 2000);
            }
        }
    };

    const handleAddParticipantsLoadingAlertModalClose = () => setShowAddParticipantsLoadingAlertModal(false);

    //showing all teams
    const handleGetAllTeams = async () => {
        const allteams = await getAllTeams(parseInt(location.state.challenge_id));
        console.log(allteams);
        setAllTeamsData(allteams.data);
        navigate('/generateTeams', { state: { challenge_details: challengeDetails, teams_data: allteams.data, weekly_challenge_details: weeklyChallengeDetails } });
    };
    const segColors = [
        '#aec7e8',
        '#ffbb78',
        '#98df8a',
        '#ff9896',
        '#c5b0d5',
        '#c49c94',
        '#f7b6d2',
        '#c7c7c7',
        '#dbdb8d',
        '#9edae5'
    ];
    const handleStartChallenge = () => {
        if (weeklyChallengeDetails.random_questions === 0) {
            handleSpinWheelModalShow();
        } else {
            handleGenerateRandomQuestions();
        }
    };
    const handleSpinWheelModalShow = async () => {
        const category_ids = weeklyChallengeDetails.category_ids;
        const degree_ids = weeklyChallengeDetails.college_university_degree_department_id;
        const topics = await getTopics(category_ids, degree_ids);
        let topic_list = [];
        let color_list = [];
        console.log(topics.data);
        for (let i = 0, j = 0; i < topics.data.length && j < segColors.length; i++, j++) {
            const topic_name = topics.data[i].name;
            topic_list.push(topic_name);
            const color_id = segColors[j];
            color_list.push(color_id);
            if (j === segColors.length - 1) {
                j = -1;
            }
        }
        // setAllTopics(topic_list);
        // setAllSegColors(color_list);
        // setTopicsData(topics.data);
        //const time_now = new Date().getTime();
        channel.publish("topic_selection_on", JSON.stringify(topics.data));
        //viewer_channel.publish("team_generated", `${location.state.challenge_id}`);

        navigate('/spinWheel', {
            state: {
                allTopics: topic_list, segColors: color_list, topicsData: topics.data,
                challenge_id: location.state.challenge_id, challenge_details: challengeDetails, question_index: 0,
                topic_to_select: "not_random", topic_to_select_id: null, viewer_topic_on: 1
            }
        });
    };

    const handleGenerateRandomQuestions = async () => {
        const category_ids = weeklyChallengeDetails.category_ids;
        const degree_ids = weeklyChallengeDetails.college_university_degree_department_id;
        const topic_to_select_id = challengeDetails.questions[0].topic_id;
        const topics = await getTopics(category_ids, degree_ids);
        let topic_list = [];
        let color_list = [];
        let topic_to_select;
        console.log(topic_to_select_id);
        console.log(topics.data);
        for (let i = 0, j = 0; i < topics.data.length && j < segColors.length; i++, j++) {
            const topic_name = topics.data[i].name;
            topic_list.push(topic_name);
            if (topics.data[i].id === topic_to_select_id) {
                topic_to_select = topic_name;
            }
            const color_id = segColors[j];
            color_list.push(color_id);
            if (j === segColors.length - 1) {
                j = -1;
            }
        }

        //const time_now = new Date().getTime();
        channel.publish("topic_selection_on", JSON.stringify(topics.data));
        // viewer_channel.publish("team_generated", `${location.state.challenge_id}`);
        console.log(topic_to_select, topic_to_select_id);
        navigate('/spinWheel', {
            state: {
                allTopics: topic_list, segColors: color_list, topicsData: topics.data,
                challenge_id: location.state.challenge_id, challenge_details: challengeDetails, question_index: 0,
                topic_to_select: topic_to_select, topic_to_select_id: topic_to_select_id, viewer_topic_on: 1
            }
        });
        //navigate('/questionnaire', { state: { question_data: challengeDetails.questions, weekly_challenge_id: challengeDetails.id, challenge_details: challengeDetails, is_random: 1, question_index: 0 } });
    };


    const handleChallengeStartTimer = () => {
        console.log("time up!");
        setEnableStartButton(false);
        setShowTimeAlertModal(true);

        setShowStartTimer(false);
        setShowStartTime(true);
    };
    const handleTimeAlertModalClose = () => setShowTimeAlertModal(false);

    const handleResumeChallenge = () => {
        console.log("yes");
        console.log(challengeDetails.random_questions);
        console.log(challengeDetails.participation_type);
        console.log(challengeDetails.resume_test);
        console.log(challengeDetails.is_teams_created);
        console.log(challengeDetails.questions.length);
        console.log(challengeDetails.last_submitted_question);
        if (challengeDetails.random_questions === 0 && challengeDetails.participation_type === 1) {
            if (challengeDetails.resume_test === 0 && challengeDetails.is_teams_created === 1 &&
                challengeDetails.questions.length === 0 && challengeDetails.last_submitted_question === "") {
                gotoTeamGenerationPage();
                console.log("1");
            }

            if (challengeDetails.resume_test === 1 && challengeDetails.is_teams_created === 1 &&
                challengeDetails.questions.length === 0 && challengeDetails.last_submitted_question === "") {
                gotoAtTimeOfQuizSpinWheel();
                console.log("01");
            }

            if (challengeDetails.resume_test === 1 &&
                challengeDetails.questions.length !== 0 && challengeDetails.last_submitted_question === "") {
                console.log("2");
                gotoAtTimeOfQuizQuestion();

            }

            if (challengeDetails.resume_test === 1 && challengeDetails.questions.length !== 0 &&
                challengeDetails.last_submitted_question !== "") {
                if (parseInt(challengeDetails.last_submitted_question) === challengeDetails.questions.length - 1) {
                    if (parseInt(challengeDetails.last_submitted_question) === challengeDetails.no_of_questions - 1) {
                        gotoAtTimeOfQuizFinalLeaderBoard();
                        console.log("3");
                    } else {
                        gotoAtTimeOfQuizSpinWheel();
                        console.log("4");
                    }
                } else if (parseInt(challengeDetails.last_submitted_question) < challengeDetails.questions.length - 1) {
                    gotoAtTimeOfQuizQuestion();
                    console.log("5");
                }
            }
        } else if (challengeDetails.random_questions === 1 && challengeDetails.participation_type === 1) {
            if (challengeDetails.resume_test === 0 && challengeDetails.is_teams_created === 1 &&
                challengeDetails.last_submitted_question === "") {
                gotoTeamGenerationPage();
                console.log("6");
            }

            if (challengeDetails.resume_test === 1 && challengeDetails.is_teams_created === 1 &&
                challengeDetails.questions.length === 0 && challengeDetails.last_submitted_question === "") {
                gotoTeamGenerationPage();
                console.log("06");
            }

            if (challengeDetails.resume_test === 1 && challengeDetails.last_submitted_question === "") {
                gotoRandomQuizQuestion();
                console.log("7");
            }

            if (challengeDetails.resume_test === 1 && challengeDetails.last_submitted_question !== "") {
                if (parseInt(challengeDetails.last_submitted_question) === challengeDetails.no_of_questions - 1) {
                    gotoRandmonFinalLeaderBoard();
                    console.log("8");
                } else {
                    gotoRandomQuizQuestion();
                    console.log("9");
                }
            }
        } else if (challengeDetails.random_questions === 0 && challengeDetails.participation_type === 0) {
            if (challengeDetails.resume_test === 1 && challengeDetails.questions.length === 0 &&
                challengeDetails.last_submitted_question === "") {
                handleStartChallenge();
                console.log("10");
            }

            if (challengeDetails.resume_test === 1 &&
                challengeDetails.questions.length !== 0 && challengeDetails.last_submitted_question === "") {
                gotoAtTimeOfQuizQuestion();
                console.log("11");
            }

            if (challengeDetails.resume_test === 1 && challengeDetails.questions.length !== 0 &&
                challengeDetails.last_submitted_question !== "") {
                if (parseInt(challengeDetails.last_submitted_question) === challengeDetails.questions.length - 1) {
                    if (parseInt(challengeDetails.last_submitted_question) === challengeDetails.no_of_questions - 1) {
                        gotoAtTimeOfQuizFinalLeaderBoard();
                        console.log("12");
                    } else {
                        gotoAtTimeOfQuizSpinWheel();
                        console.log("13");
                    }
                } else if (parseInt(challengeDetails.last_submitted_question) < challengeDetails.questions.length - 1) {
                    gotoAtTimeOfQuizQuestion();
                    console.log("14");
                }
            }
        } else if (challengeDetails.random_questions === 1 && challengeDetails.participation_type === 0) {
            // if (challengeDetails.resume_test === 1 && challengeDetails.last_submitted_question === "") {
            //     handleStartChallenge();
            //     console.log("15");
            // }

            if (challengeDetails.resume_test === 1 && challengeDetails.last_submitted_question === "") {
                gotoRandomQuizQuestion();
                console.log("16");
            }

            if (challengeDetails.resume_test === 1 && challengeDetails.last_submitted_question !== "") {
                if (parseInt(challengeDetails.last_submitted_question) === challengeDetails.no_of_questions - 1) {
                    gotoRandmonFinalLeaderBoard();
                    console.log("17");
                } else {
                    gotoRandomQuizQuestion();
                    console.log("18");
                }
            }
        }
    };

    const gotoTeamGenerationPage = () => {
        const current_time = new Date().getTime();
        channel.publish("show_team_timer", `${current_time}`);
        handleGetAllTeams();
    };

    const gotoAtTimeOfQuizSpinWheel = () => {
        let lastSubmittedQuestion = 0;
        let viewerLastSubmitted = 0;
        if (challengeDetails.last_submitted_question === "") {
            lastSubmittedQuestion = 0;
        } else {
            lastSubmittedQuestion = parseInt(challengeDetails.last_submitted_question) + 1;
            viewerLastSubmitted = parseInt(challengeDetails.last_submitted_question);
        }
        let topic_list = [];
        let color_list = [];

        for (let i = 0, j = 0; i < challengeDetails.topics.length && j < segColors.length; i++, j++) {
            const topic_name = challengeDetails.topics[i].name;
            topic_list.push(topic_name);
            const color_id = segColors[j];
            color_list.push(color_id);
            if (j === segColors.length - 1) {
                j = -1;
            }
        }

        if (challengeDetails.topics) {
            //channel.publish("topic_selection_on", JSON.stringify(topics.data));
            const current_time = new Date().getTime();
            if (challengeDetails.last_submitted_question !== "") {
                viewer_channel.publish("leader_board", `${current_time}_${viewerLastSubmitted}_${challengeDetails.questions[viewerLastSubmitted].id}`);
            }
            navigate('/spinWheel', {
                state: {
                    allTopics: topic_list, segColors: color_list, topicsData: challengeDetails.topics,
                    challenge_id: challengeDetails.id, challenge_details: challengeDetails,
                    question_index: lastSubmittedQuestion, topic_to_select: "not_random", topic_to_select_id: null, viewer_topic_on: 0
                }
            });
        } else {
            console.log("No topics data found!");
        }
    };

    const gotoAtTimeOfQuizQuestion = () => {
        let lastSubmittedQuestion = 0;
        if (challengeDetails.last_submitted_question === "") {
            lastSubmittedQuestion = 0;
        } else {
            lastSubmittedQuestion = parseInt(challengeDetails.last_submitted_question) + 1;
        }
        let questionData = challengeDetails.questions[lastSubmittedQuestion];
        questionData["current_pos"] = lastSubmittedQuestion;
        const current_time = new Date().getTime();
        questionData["timer_ts"] = current_time;
        channel.publish("question", JSON.stringify(questionData));

        viewer_channel.publish("topic_selected", `${questionData.topic_id}`);

        navigate('/questionnaire', {
            state: {
                question_data: questionData, weekly_challenge_id: challengeDetails.id,
                challenge_details: challengeDetails, is_random: 0, question_index: lastSubmittedQuestion
            }
        });
    };

    const gotoRandomQuizQuestion = () => {
        let lastSubmittedQuestion = 0;
        if (challengeDetails.last_submitted_question === "") {
            lastSubmittedQuestion = 0;
        } else {
            lastSubmittedQuestion = parseInt(challengeDetails.last_submitted_question) + 1;
        }
        let questionData = challengeDetails.questions[lastSubmittedQuestion];
        questionData["current_pos"] = lastSubmittedQuestion;
        const current_time = new Date().getTime();
        questionData["timer_ts"] = current_time;
        channel.publish("question", JSON.stringify(questionData));

        viewer_channel.publish("topic_selected", `${questionData.topic_id}`);

        navigate('/questionnaire', {
            state: {
                question_data: challengeDetails.questions, weekly_challenge_id: challengeDetails.id,
                challenge_details: challengeDetails, is_random: 1, question_index: lastSubmittedQuestion
            }
        });
    };

    const gotoAtTimeOfQuizFinalLeaderBoard = async () => {
        const leaderboard = await getLeaderBoard(challengeDetails.id, parseInt(challengeDetails.questions[parseInt(challengeDetails.last_submitted_question)].id));
        const current_time = new Date().getTime();
        channel.publish("final_leader_board", `${current_time}_${parseInt(challengeDetails.last_submitted_question)}_${challengeDetails.questions[parseInt(challengeDetails.last_submitted_question)].id}`);
        let viewer_question = challengeDetails.questions[parseInt(challengeDetails.last_submitted_question)];
        viewer_question["current_pos"] = challengeDetails.last_submitted_question;
        viewer_question["timer_ts"] = current_time;
        viewer_channel.publish("question", JSON.stringify(viewer_question));
        const status = setWeeklyChallengeStatus(challengeDetails.id, 1);
        console.log(status.data);
        navigate('/finalLeaderBoard', {
            state: {
                leaderBoard_data: leaderboard.data, question_index: parseInt(challengeDetails.last_submitted_question),
                current_question_id: parseInt(challengeDetails.questions[parseInt(challengeDetails.last_submitted_question)].id),
                challenge_details: challengeDetails
            }
        });
    };

    const gotoRandmonFinalLeaderBoard = async () => {
        const leaderboard = await getLeaderBoard(challengeDetails.id, parseInt(challengeDetails.questions[parseInt(challengeDetails.last_submitted_question)].id));
        const current_time = new Date().getTime();
        channel.publish("final_leader_board", `${current_time}_${parseInt(challengeDetails.last_submitted_question)}_${challengeDetails.questions[parseInt(challengeDetails.last_submitted_question)].id}`);
        let viewer_question = challengeDetails.questions[parseInt(challengeDetails.last_submitted_question)];
        viewer_question["current_pos"] = challengeDetails.last_submitted_question;
        viewer_question["timer_ts"] = current_time;
        viewer_channel.publish("question", JSON.stringify(viewer_question));
        const status = setWeeklyChallengeStatus(challengeDetails.id, 1);
        console.log(status.data);
        navigate('/finalLeaderBoard', {
            state: {
                leaderBoard_data: leaderboard.data, question_index: parseInt(challengeDetails.last_submitted_question),
                current_question_id: parseInt(challengeDetails.questions[parseInt(challengeDetails.last_submitted_question)].id),
                challenge_details: challengeDetails
            }
        });
    };

    console.log("----------------");
    console.log(challengeDetails);
    console.log(weeklyChallengeDetails);
    console.log("--------------------");

    console.log(activeMembers);
    if (challengeDetails != null) {
        return (
            <div className="row w-100">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="start-challenge-div">
                        <div className="row">
                            <div className="col-sm-4 colmd-4 col-lg-4">
                                <div style={{ marginLeft: '20px', marginTop: '20px' }}>
                                    {
                                        showStartTimer && (
                                            <ReactCountdownClock seconds={startTimer}
                                                color="red"
                                                size={80}
                                                weight={6}
                                                alpha={0.9}
                                                showMilliseconds={false}
                                                onComplete={handleChallengeStartTimer} />
                                        )
                                    }
                                    {
                                        showStartTime && (
                                            <span><strong>Start Time:<br/>{challengeDetails.start_date}</strong></span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col-sm-8 colmd-8 col-lg-8">
                                <button className="btn btn-primary view-participants-btn" onClick={handleChallengeParticipantsModalShow} id="view-participants-btn">View Participants</button>
                            </div>

                            <Modal show={showChallengeParticipantsModal} onHide={handleChallengeParticipantsModalClose} scrollable dialogClassName="challengeParticipantsmodalWidth">
                                <Modal.Header closeButton>
                                    <div className="enrollers-head-div">
                                        <div>Participants enrolled : {allChallengeParticipants.length}</div>
                                        <div style={{ marginRight: '5%' }}>Active:
                                            {activeMembers.length > allChallengeParticipants.length ? allChallengeParticipants.length : activeMembers.length}/{allChallengeParticipants.length}</div>
                                    </div>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        {
                                            allChallengeParticipants.length != 0
                                                ? (
                                                    <table className="table table-bordered table-curved" id="general-coordinators-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Participant Type</th>
                                                                <th scope="col">Participant Name</th>
                                                                <th scope="col">Department Name</th>
                                                                <th scope="col">Roll number</th>
                                                                <th scope="col">Section</th>
                                                                <th scope="col">Semester</th>
                                                                <th scope="col">Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                allChallengeParticipants.map(participant => (
                                                                    <tr>
                                                                        <td scope="row">{participant.participant_type}</td>
                                                                        <td scope="row">{participant.participant_name}</td>
                                                                        <td scope="row">{participant.department_name}</td>
                                                                        <td scope="row">{participant.roll_number}</td>
                                                                        <td scope="row">{participant.section_name}</td>
                                                                        <td scope="row">{participant.semester_id}</td>
                                                                        <td scope="row" style={{ color: "green" }}>{activeMembers.includes(participant.participant_id) ? "Active" : "-"}</td>
                                                                        {/*<td scope="row" style={{ color: 'green' }}>{participant.status}</td>*/}
                                                                    </tr>)
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                )
                                                : (<span className="d-flex justify-content-center" id="no-coordinators-msg-label">No Participants has enrolled yet!</span>)
                                        }

                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleChallengeParticipantsModalClose} id="modalCloseBtn" style={{ width: '15%' }}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                            <Modal show={showTeamGenerationAlertModal} onHide={handleTeamGenerationAlertModalClose} scrollable dialogClassName="challengeParticipantsmodalWidth">
                                <Modal.Header closeButton>
                                    <Modal.Title>Alert </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        <span className="d-flex justify-content-center" id="no-coordinators-msg-label">Teams has been generated Successfully!</span>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleTeamGenerationAlertModalClose} id="modalCloseBtn" style={{ width: '25%' }}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showTimeAlertModal} onHide={handleTimeAlertModalClose} scrollable dialogClassName="challengeTimermodalWidth">
                                <Modal.Header closeButton>
                                    <Modal.Title>Alert </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        {
                                            challengeDetails.participation_type === 1
                                                ? (<span className="d-flex justify-content-center" id="no-coordinators-msg-label">Time up. please continue with generating teams!</span>)
                                                : (<span className="d-flex justify-content-center" id="no-coordinators-msg-label">Time up. please continue with adding participants!</span>)
                                        }
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleTimeAlertModalClose} id="modalCloseBtn" style={{ width: '25%' }}>
                                        Continue
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showTeamsGenerationLoadingAlertModal} onHide={handleTeamsGenerationLoadingAlertModalClose} id="challengeCreateAlertModal" dialogClassName="challengeCreateAlertmodalWidth">
                                <Modal.Body>
                                    {
                                        is_teamgeneration_loading && (
                                            <div className="row w-100 d-flex align-items-center loading-div p-5">
                                                <span className="loading-div-label">Generating Teams</span><br />
                                                <div className="loading">...</div>

                                            </div>
                                        )
                                    }
                                    {
                                        is_team_generation_confirmmsg && (
                                            <div className="row w-100 d-flex align-items-center p-5">
                                                {
                                                    team_generation_confirmmsg === "Teams generated Successfully!"
                                                        ? <TiTick id="success-tick-icon" />
                                                        : ''
                                                }
                                                <span className="challenge-success-label">{team_generation_confirmmsg}</span><br />
                                            </div>
                                        )
                                    }
                                </Modal.Body>
                            </Modal>

                            <Modal show={showAddParticipantsLoadingAlertModal} onHide={handleAddParticipantsLoadingAlertModalClose} id="challengeCreateAlertModal" dialogClassName="challengeCreateAlertmodalWidth">
                                <Modal.Body>
                                    {
                                        is_addParticipants_loading && (
                                            <div className="row w-100 d-flex align-items-center loading-div p-5">
                                                <span className="loading-div-label">Adding Participants</span><br />
                                                <div className="loading">...</div>

                                            </div>
                                        )
                                    }
                                    {
                                        is_add_participants_confirmmsg && (
                                            <div className="row w-100 d-flex align-items-center p-5">
                                                {
                                                    team_generation_confirmmsg === "Participants added Successfully!"
                                                        ? <TiTick id="success-tick-icon" />
                                                        : ''
                                                }
                                                <span className="challenge-success-label">{add_participants_confirmmsg}</span><br />
                                            </div>
                                        )
                                    }
                                </Modal.Body>
                            </Modal>
                        </div>
                        <h2 className="challenge-title">{challengeDetails.title}</h2>
                        <p className="challenge-desc">{challengeDetails.description}</p>
                        <div className="time-div2">
                            {/* <h4 className="time-label">Starts At</h4>
                            <h2 className="org-time" style={{ color: 'balck' }}>{challengeDetails.start_date}</h2> */}
                            {
                                challengeDetails.participation_type === 1
                                    ? challengeDetails.resume_test === 0 && challengeDetails.is_teams_created === 0 && challengeDetails.questions.length === 0
                                        ? (<p id="participant-join-wait-label"><strong>Let`s wait for the participants to join!</strong></p>)
                                        : (<p id="participant-join-wait-label"><strong>Start from where you left!</strong></p>)
                                    : challengeDetails.resume_test === 0 && challengeDetails.last_submitted_question === ""
                                        ? (<p id="participant-join-wait-label"><strong>Let`s wait for the participants to join!</strong></p>)
                                        : (<p id="participant-join-wait-label"><strong>Start from where you left!</strong></p>)
                            }

                        </div>
                        {
                            challengeDetails.resume_test === 0 && challengeDetails.is_teams_created === 0 && challengeDetails.last_submitted_question === ""
                                ? (
                                    <>
                                        {
                                            challengeDetails.participation_type === 0
                                                ? isParticipantsAdded && (
                                                    <button className="btn btn-default" id="start-challenge-btn" disabled={enableStartButton} onClick={handleStartChallenge} > Start Arena</button>
                                                )
                                                : (
                                                    <button className="btn btn-default" onClick={handleGenerateTeams} id="generate-teams-btn">Genereate Teams</button>
                                                )
                                        }

                                        {
                                            challengeDetails.participation_type === 0 && (
                                                isParticipantsNotAdded && (
                                                    <button className="btn btn-default generate-teams-btn" onClick={handleAddParticipants} id="generate-teams-btn">Add Participants</button>
                                                )
                                            )
                                        }
                                    </>
                                )
                                : (<button className="btn btn-default generate-teams-btn" onClick={handleResumeChallenge} id="generate-teams-btn">Resume Arena</button>)
                        }
                    </div>
                </div>
            </div >
        );
    }

}

export default StartChallenge;