import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export default function InComponentLoader() {
  return (
    <Grid item>
      <CircularProgress />
    </Grid>
  );
}  