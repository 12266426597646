import React, {useState, useEffect, useCallback} from 'react';
import DropDown from "../common/DropDown";
import MultipleSelectCheckmarks from '../common/MultipleSelectCheckmarks';
// import Button from '@mui/material/Button';
import Button from '../common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setFilteredSubjects,setFilteredSections,setFilteredSectionMapping } from '../../store/reducers/facultySectionMapping.reducer';
// import Box  from '@mui/material/Box';
import Grid  from '@mui/material/Grid';
import SearchMultiSelect from '../common/SearchMultiSelect';
import { getAllSubjects, getAllSections } from '../../lib/utils';


const SearchComponent = ({tabsValue}) => {
  const [showResetButton, setShowResetButton] = useState(false);
  const [deparmentId, setDepartmentId] = useState('');
  const [selectedSemesters, setSelectedSemesters] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState([]);
  // const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  const {
    filteredDepartments,
    filteredSemesters,
    filteredSubjects,
    filteredSections,
    filteredFaculty,
    filteredSectionMapping,
    allFacultySectionsMapping,
  } = useSelector((state) => state.SectionMapping);
  
  const dispatch = useDispatch();
  
  // Retriving role as HOD or below needs to be shown only the dept.


  // const isManagement = user?.role_id <=2 ; 
  // console.log(isManagement)
  
  // Reset Button To be shown or not based on the filtering status.
  useEffect(() => {
    filteredSectionMapping?.length === allFacultySectionsMapping?.length
      ? setShowResetButton(false)
      : setShowResetButton(true);
  }, [filteredSectionMapping, allFacultySectionsMapping]);


  // const resetFilterOnTabSwitch = ()=>{
  //   setSelectedSubjects([])
  //   setSelectedSections([])
  //   setSelectedFaculty([])
  //   dispatch(
  //     setFilteredSectionMapping({
  //       selectedSubjects: [],
  //       selectedSections: [],
  //       selectedFaculty: [],
  //     })
  //   );
  // }

  const resetFilterOnTabSwitch = useCallback(()=>{
    setSelectedSubjects([])
    setSelectedSections([])
    setSelectedFaculty([])
    dispatch(
      setFilteredSectionMapping({
        selectedSubjects: [],
        selectedSections: [],
        selectedFaculty: [],
      })
    );
  }, [dispatch])

  // Handling Tab Swtich to Reset the Search Filters
    useEffect(()=>{
      resetFilterOnTabSwitch();
    },[tabsValue,resetFilterOnTabSwitch])

  // Handling Dynamic Subject Filter Based on Departments and Semensters
  useEffect(()=>{

    const filteredSubjects = getAllSubjects(allFacultySectionsMapping,deparmentId,selectedSemesters);
    const filteredSections = getAllSections(allFacultySectionsMapping,deparmentId,selectedSemesters);
    // console.log(filteredSubjects)
    // console.log(filteredSections)
    dispatch(setFilteredSubjects({filteredSubjects : filteredSubjects}))
    dispatch(setFilteredSections({filteredSections : filteredSections}))

  },[deparmentId,selectedSemesters,allFacultySectionsMapping,dispatch])

  // // When allFacultySectionsMapping changes, we need to filter data again
  useEffect(()=>{
    dispatch(setFilteredSectionMapping({deparmentId,selectedSemesters,selectedSubjects}));
  },[allFacultySectionsMapping,dispatch])


  // Handling Buttons and DropDowns
  const handleDepartmentDropDown = (event) => {
    console.log(event.target.value);
    const deptId = event.target.value ? event.target.value : '';

    setDepartmentId(deptId);
  };

  const handleSemesterDropDown = (selectedValues) => {
    const semIds = selectedValues ? selectedValues : [];
    console.log(semIds);
    setSelectedSemesters(semIds);
  };

  const handleSubjectDropDown = (selectedValues) => {
    const subjecIds = selectedValues ? selectedValues : [];
    console.log(subjecIds);
    setSelectedSubjects(subjecIds);
  };

  const handleSectionDropDown = (selectedValues) => {
    const sectionNames = selectedValues ? selectedValues : [];
    // console.log(subjecIds);
    setSelectedSections(sectionNames);
  };

  const handleFacultyDropDown = (selectedValues) => {
    const facultyIds = selectedValues ? selectedValues : [];
    // console.log(subjecIds);
    setSelectedFaculty(facultyIds);
  };

  const handleApplyOnClick = () =>{
    console.log(`deparmentId in Apply : ${deparmentId}`)
    console.log(`selectedSemesters in Apply : ${selectedSemesters}`)
    console.log(`selectedSubjects in Apply : ${selectedSubjects}`)
    console.log(`selectedSections in Apply : ${selectedSections}`)
    console.log(`selectedFaculty in Apply : ${selectedFaculty}`)
    // dispatch(setFilteredSectionMapping({deparmentId,selectedSemesters,selectedSubjects}));
    if(tabsValue ===0){
      dispatch(setFilteredSectionMapping({deparmentId,selectedSemesters,selectedSubjects}));
      return console.log('Apply Clicked')
    }
    if(tabsValue ===1){
      dispatch(setFilteredSectionMapping({deparmentId,selectedSemesters,selectedSections}));
      return console.log('Apply Clicked')
    }
    if(tabsValue ===2){
      dispatch(setFilteredSectionMapping({deparmentId,selectedSemesters,selectedFaculty}));
      return console.log('Apply Clicked')
    }


    return console.log('Apply Clicked')
  }


  const handleReset = () => {
    setDepartmentId("");
    setSelectedSemesters([]);
    setSelectedSubjects([]);
    setSelectedSections([])
    setSelectedFaculty([])
    dispatch(
      setFilteredSectionMapping({
        deparmentId: "",
        selectedSemesters: [],
        selectedSubjects: [],
        selectedSections: [],
        selectedFaculty: [],
      })
    );
  };

  return (
    <React.Fragment>
      {/* <Box display={'flex'} padding={'10px'} alignItems="center" flexDirection={'row'} > */}
      <Grid container  padding={'10px'} alignItems="center"  spacing={1}>

      <Grid item>
      <DropDown
        data={
          filteredDepartments
        }
        onChange={handleDepartmentDropDown}
        label={"Departments"}
        nullIsAll = {true}
        dropDownValue ={deparmentId}
        setDropDownValue ={setDepartmentId}
        // disabled={!isManagement}
      />
      </Grid>

      <Grid item>
      <MultipleSelectCheckmarks
        data={filteredSemesters}
        onChange={handleSemesterDropDown}
        label={"Semester"}
        selectedValues ={selectedSemesters}
        setSelectedValues={setSelectedSemesters}
      />
      </Grid>

      {tabsValue === 0 && <Grid item>
      <SearchMultiSelect
      label={"Subject"}
      data={filteredSubjects}
      selectedValues = {selectedSubjects}
      setSelectedValues ={setSelectedSubjects}
      onChange={handleSubjectDropDown}
      />
      </Grid>}

      {tabsValue === 1 && <Grid item>
      <SearchMultiSelect
      label={"Section"}
      data={filteredSections}
      selectedValues = {selectedSections}
      setSelectedValues ={setSelectedSections}
      onChange={handleSectionDropDown}
      />
      </Grid>}

      {tabsValue === 2 && <Grid item>
      <SearchMultiSelect
      label={"Faculty"}
      data={filteredFaculty}
      selectedValues = {selectedFaculty}
      setSelectedValues ={setSelectedFaculty}
      onChange={handleFacultyDropDown}
      multipleSeletedLabel ="Faculties Selected"
      />
      </Grid>}


      <Grid item>
      <Button onClick={handleApplyOnClick} name='Apply'/>
      </Grid>

      {showResetButton &&<Grid item>
      <Button style={{marginLeft : '10px'}} onClick={handleReset} name='Reset' type={'secondary'}>
        Reset
      </Button>
      </Grid>
      }
      
      {/* </Box> */}
      </Grid>
      </React.Fragment>

  );
}

export default SearchComponent;