import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import {Link as MaterialLink} from '@mui/material';


export default function Breadcrumb({ links, currentPage }) {
  // To have values not as undefined
  const currentText = currentPage ? currentPage : null;
  const linksArray = links ? links : [];

  //   const breadcrumbs = [
  //     // <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick}>
  //     //   Home
  //     // </Link>,
  //     <MaterialLink
  //       underline="hover"
  //       key="2"
  //       color="inherit"
  //       to="/facultySectionMapping"
  //       onClick={handleClick}
  //       component={RouterLink}
  //     >
  //       Faculty Student Allocation
  //     </MaterialLink>,
  //     <Typography key="3" color="text.primary">
  //       {}
  //     </Typography>,
  //   ];

  let breadcrumbs = [];
  if (linksArray?.length) {
    breadcrumbs = [
      linksArray.map(({ name, path }) => {
        return (
          <MaterialLink
            underline="hover"
            key="2"
            color="inherit"
            to={path}
            component={RouterLink}
          >
            {name}
          </MaterialLink>
        );
      }),
    ];

    if (currentText) {
      breadcrumbs.push(
        <Typography key="3" color="text.disabled" sx={{m:0, fontSize:"0.9rem"}}>
          {currentText}
        </Typography>
      );
    }
  }

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{m:0, fontSize:"0.9rem"}}
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
}
