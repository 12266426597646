import { createSlice } from "@reduxjs/toolkit";

//reducer
const joinChallengeReducer = createSlice({
    name: "joinChallenge",
    initialState: {
        realtimePChannel: null,
        participantChannel: null,
        viewerChannel: null,
        prevChallenge: null
    },
    reducers: {
        openAblyConnection: (state, action) => {
            console.log("state = ", state);
            console.log("action = ", action);
            console.log(action.payload.challenge_id);
            if (state.realtimePChannel === null || state.prevChallenge !== action.payload.challenge_id) {
                state.realtimePChannel = action.payload.realtime;
                state.participantChannel = action.payload.channel;
                state.viewerChannel = action.payload.viewer_channel;
                state.prevChallenge = action.payload.challenge_id;

                return state;
            }
        },
        closePAblyConnection: (state, action) => {
            state.realtimePChannel = null;
            state.participantChannel = null;
            state.viewerChannel = state.viewerChannel;
            state.prevChallenge = null;

            return state;
        },
        closeVAblyConnection: (state, action) => {
            state.realtimePChannel = state.realtimePChannel;
            state.participantChannel = state.participantChannel;
            state.viewerChannel = null;
            state.prevChallenge = state.prevChallenge;

            return state;
        }
    }
});

export const { openAblyConnection, closePAblyConnection, closeVAblyConnection } = joinChallengeReducer.actions;
export default joinChallengeReducer.reducer;