import React, { useRef, useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';
import { Fragment } from 'react';
// import { PieChart, Pie, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from 'recharts';
import Html from 'react-pdf-html';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';

const McqReportTemplate = (props) => {
    console.log("props.image url -> ",props.image_url);
    Font.register({ family: 'arial', src: '/files/arial.ttf' });
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    let tag_line = ""
    if(user){
        if(user.college_id === 27){
            tag_line = "(An Autonomous Institution)";
        }else if(user.college_id === 29){
            tag_line = "(An Autonomous Institution)";
        }else if(user.college_id === 32){
            tag_line = "(An Autonomous Institution)";
        }else if(user.college_id === 40){
            tag_line = "(An Autonomous Institution)";
        }else if(user.college_id === 41){
            tag_line = "(An Autonomous Institution)";
        }else if(user.college_id === 38){
            tag_line = "(Deemed to be University)";
        }else if(user.college_id === 51){
            tag_line = "";
        }else if(user.college_id === 36){
            tag_line = "(An Autonomous Institution)";
        }else{
            tag_line = "(An Autonomous Institution)";
        }
    }
    const styles = StyleSheet.create({
        viewer: {
            width: '100%', //the pdf viewer will take up all of the width and height
            height: window.innerHeight,
        },
        view: {
            fontSize: 10,
        },
        page: {
            backgroundColor: 'white',
            flexDirection: 'column',
            margin: 15,
            marginBottom: 50,
            paddingTop: 25,
            marginLeft: 30,
            marginRight: 10,
            width: '90%',
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 5,
            width: '90%'
        },
        logo1: {
            width: 50,
            height: 70,
            marginLeft: "30px",
        },
        logo01: {
            width: 60,
            height: 70,
            marginLeft: "30px",
        },
        logo02: {
            width: 50,
            height: 50,
            marginLeft: "30px",
        },
        logo03: {
            width: 70,
            height: 50,
            marginLeft: "30px",
        },
        logo2: {
            width: 70,
            height: 60,
            marginRight: "10px",
            marginTop: '10px'
        },
        logo21: {
            width: 45,
            height: 55,
            marginRight: "10px",
            marginTop: '10px'
        },
        headerText: {
            fontSize: 15,
            fontWeight: 'bold',
            marginLeft: 15,
            marginRight: 15,
            textAlign: 'center'
        },
        subHeaderText: {
            fontSize: 12,
            marginTop: "10px",
            textAlign: 'center'
        },
        clgName: {
            alignItems: 'center',
            width: "66.67%",
        },
        semYearDetails: {
            alignItems: 'center',
            width: '90%'
        },
        sfReportLabel: {
            fontWeight: 500,
            fontSize: 14
        },
        semYearLabel: {
            fontSize: 10
        },
        rowSpan: {
            paddingLeft: 20,
            paddingRight: 30,
            marginTop: 10,
            width: '90%',
            lineHeight: 0.7,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        rowSpan_details: {
            paddingLeft: 20,
            paddingRight: 30,
            marginTop: 2,
            width: '90%',
            lineHeight: 0.7,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        rowSpan1: {
            width: '60%',
            flexDirection: 'row',
            // flexWrap: 'wrap',
            wordBreak: 'break-all',
            marginTop: 5,
            lineHeight: 0.9,
        },
        rowSpan2: {
            width: '30%',
            flexDirection: 'row',
            flexWrap: 'wrap',
            wordBreak: 'break-all',
            marginTop: 5,
            lineHeight: 0.9,
        },
        rowSpan22: {
            width: '30%',
            flexDirection: 'row',
            // flexWrap: 'wrap',
            wordBreak: 'break-all',
            marginTop: 5,
            lineHeight: 0.9,
        },
        labelHeading: {
            fontWeight: 'bold',
            fontSize: 12,
            textDecoration: 'underline'
        },
        sectionHeading: {
            fontWeight: 'bold',
            fontSize: 14,
            textDecoration: 'underline'
        },
        label: {
            fontWeight: 'bold',
            fontSize: 11,
        },
        value: {
            marginLeft: 5,
            fontWeight: 'normal',
            fontSize: 10,
            marginTop: '1px'
        },
        rowValues: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        tableContainer: {
            flexDirection: "row",
            flexWrap: "wrap",
            // marginBottom: 50,
            width: '90%'
        },
        chart: {
            width: '100%',
            height: 250,
            marginLeft: 20,
            overflow: 'hidden'
        },
        chartRowSpan: {
            marginTop: 10,
            width: '180%',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        chartRowSpan1: {
            width: '40%',
            flexDirection: 'column',
            marginTop: 5,
            wordBreak: 'break-all',
            lineHeight: 1,
            paddingLeft: 25,
            paddingTop: 50,
        },
        chartRowSpan2: {
            width: '140%',
            flexDirection: 'row',
            marginTop: 5,
        },
        chartBoxColorView: {
            width: 20,
            height: 10,
            border: '1px solid black',
            marginRight: 10
        },
        chartInnerrowSpan1: {
            width: '100%',
            flexDirection: 'row',
            // flexWrap: 'wrap',
            wordBreak: 'break-all',
            marginTop: 8,
            lineHeight: 1,
        }
    });
    
    const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

    return (
        // <PDFViewer style={styles.viewer}>
            <Document>
                {
                    props.reportData.map(report_data => {
                        let pdfPages = []
                        const overall_performance_keypairs = Object.entries(report_data.overall_performance);
                        console.log("overall_performance_data -> ", overall_performance_keypairs);
                        let overall_performance_data = [];
                        let overall_performance_names = ["Very Good", "Good", "Average", "Below Average"];
                        overall_performance_keypairs.map((keypair,index) => {
                            if(index < overall_performance_keypairs.length - 1){
                                overall_performance_data.push({ name: overall_performance_names[index], value: keypair[1] });
                            }
                        });
                        pdfPages.push(
                            <React.Fragment key='test_details'>
                                <Page size='A4' style={styles.page}>
                                    <View style={styles.header}>
                                        <View style={styles.col2}>
                                            <Image 
                                                style={
                                                    [38,40,41].includes(user.college_id) 
                                                        ? styles.logo01 
                                                        : [51].includes(user.college_id)
                                                            ? styles.logo02
                                                            : [36].includes(user.college_id)
                                                                ? styles.logo03
                                                                : styles.logo1
                                                } 
                                                src={report_data.college_logo ? report_data.college_logo : `/images/feedback_images/resultsPage.jpg`} 
                                            />
                                        </View>
                                        <View style={styles.clgName}>
                                            <Text style={styles.headerText}>{report_data.college_name}</Text>
                                            {/* <Text style={styles.headerText}>R.M.K College of Engineering and Technology</Text> */}
                                            {
                                                ![51].includes(user.college_id)
                                                    ? (
                                                        <Text style={styles.subHeaderText}>{tag_line}</Text>
                                                    )
                                                    : ""
                                            }
                                        </View>
                                        <View style={styles.col2}>
                                            {
                                                [27].includes(user.college_id)
                                                    ? (
                                                        <Image style={styles.logo2} src="/images/feedback_images/resultsPage-logo2.jpg" />
                                                    )
                                                    : [32].includes(user.college_id) 
                                                        ? (
                                                            <Image style={styles.logo21} src="/images/feedback_images/rmk_group_28years_logo.png" />
                                                        )
                                                        : (
                                                            <View style={styles.logo2}></View>
                                                        )
                                            }
                                        </View>
                                    </View>
                                    <View style={styles.semYearDetails}>
                                        <Text style={styles.sfReportLabel}>MCQ TEST REPORT</Text>
                                        {/* <Text style={styles.semYearLabel}>ACADEMIC YEAR: 2022-23 {parseInt(feedback.semester_id) % 2 == 0 ? 'EVEN' : 'ODD'} SEMESTER</Text> */}
                                        <Text style={styles.semYearLabel}>ACADEMIC YEAR: {report_data.academic_year}</Text>
                                    </View>

                                    {/* Test details */}
                                    <View style={styles.rowSpan}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.sectionHeading}>Test Details</Text>
                                        </View>
                                        <View style={styles.rowSpan2}>
                                            <Text style={styles.sectionHeading}>Faculty Details</Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Test Name : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>{report_data.test_name}</Text>
                                        </View>
                                        <View style={styles.rowSpan22}>
                                            <Text style={styles.label}>Faculty Name : </Text>
                                            <Text style={{ ...styles.value, textOverflow: 'ellipsis', marginLeft: '28px', marginRight: '5px' }} numberOfLines={1} ellipsizeMode='tail'>{report_data.faculty_name}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Description : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>{report_data.description}</Text>
                                        </View>
                                        <View style={styles.rowSpan22}>
                                            <Text style={styles.label}>Role : </Text>
                                            <Text style={{ ...styles.value, textOverflow: 'ellipsis' }} numberOfLines={1} ellipsizeMode='tail'>{report_data.role}</Text>
                                        </View>
                                    </View>

                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Start Time : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.start_time}
                                            </Text>
                                        </View>
                                        <View style={styles.rowSpan22}>
                                            <Text style={styles.label}>Department : </Text>
                                            <Text style={{ ...styles.value, textOverflow: 'ellipsis', marginLeft: '25px', marginRight: '5px' }} numberOfLines={2} ellipsizeMode='tail'>{report_data.branch_name}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>End Time : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.end_time}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Test Duration : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {Math.ceil(report_data.test_duration / 60)} &nbsp;{Math.ceil(report_data.test_duration / 60) > 1 ? "minutes" : "minutes"}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Subject : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.subject}
                                            </Text>
                                        </View>
                                    </View>
                                    {/* <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Department : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.branch_name}
                                            </Text>
                                        </View>
                                    </View> */}
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Total Questions : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.total_test_questions}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Total Marks : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.total_test_marks}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Sent To : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.total_students}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowSpan_details}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.label}>Attempted By : </Text>
                                            <Text style={{ ...styles.value, width: '70%' }} numberOfLines={1} ellipsizeMode='tail'>
                                                {report_data.total_students_attempted}
                                            </Text>
                                        </View>
                                    </View>


                                    {/* Overall performance */}
                                    <View style={styles.rowSpan}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.sectionHeading}>Overall Performance</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableContainer}>
                                        <OverallPerformanceTable data={report_data.overall_performance} />
                                    </View>

                                    {
                                        props.chart_available
                                        ? (
                                            <View style={styles.chartRowSpan}>
                                                {/* <Text>Pie Chart</Text> */}
                                                <View style={styles.chartRowSpan1}>
                                                    {
                                                        overall_performance_data.map((data, index) => {
                                                            return (
                                                                <View style={styles.chartInnerrowSpan1}>
                                                                    <View style={{ ...styles.chartBoxColorView, backgroundColor: `${colors[index]}` }}></View>
                                                                    <Text style={styles.label}>{data.name} -</Text>
                                                                    <Text style={styles.value}>{data.value}%</Text>
                                                                </View>
                                                            )
                                                        })
                                                    }
                                                </View>
                                                <View style={styles.chartRowSpan2}>
                                                    {props.image_url ? <Image style={styles.chart} src={props.image_url} /> : ""}
                                                </View>
                                            </View>
                                        )
                                        : ""
                                    }
                                </Page>
                            </React.Fragment>
                        )

                        pdfPages.push(
                            <React.Fragment key='topic_performance'>
                                <Page size='A4' style={styles.page}>
                                    <View style={styles.header}>
                                        <View style={styles.col2}>
                                            <Image 
                                                style={
                                                    [38,40,41].includes(user.college_id) 
                                                        ? styles.logo01 
                                                        : [51].includes(user.college_id)
                                                            ? styles.logo02
                                                            : [36].includes(user.college_id)
                                                                ? styles.logo03
                                                                : styles.logo1
                                                } 
                                                src={report_data.college_logo ? report_data.college_logo : `/images/feedback_images/resultsPage.jpg`} 
                                            />
                                        </View>
                                        <View style={styles.clgName}>
                                            <Text style={styles.headerText}>{report_data.college_name}</Text>
                                            {/* <Text style={styles.headerText}>R.M.K College of Engineering and Technology</Text> */}
                                            {
                                                ![51].includes(user.college_id)
                                                    ? (
                                                        <Text style={styles.subHeaderText}>{tag_line}</Text>
                                                    )
                                                    : ""
                                            }
                                        </View>
                                        <View style={styles.col2}>
                                            {
                                                [27].includes(user.college_id)
                                                    ? (
                                                        <Image style={styles.logo2} src="/images/feedback_images/resultsPage-logo2.jpg" />
                                                    )
                                                    : [32].includes(user.college_id) 
                                                        ? (
                                                            <Image style={styles.logo21} src="/images/feedback_images/rmk_group_28years_logo.png" />
                                                        )
                                                        : (
                                                            <View style={styles.logo2}></View>
                                                        )
                                            }
                                        </View>
                                    </View>
                                    {/* Topic performance */}
                                    <View style={styles.rowSpan}>
                                        <View style={styles.rowSpan1}>
                                            <Text style={styles.sectionHeading}>Topics Performance</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableContainer}>
                                        {
                                            report_data.topic_performance.length > 0
                                                ? (<TopicPerformanceTable data={report_data.topic_performance} />)
                                                : (
                                                    <View style={styles.rowSpan}>
                                                        <Text style={{...styles.subHeaderText,marginLeft: '45%'}}>No Data Available</Text>
                                                    </View>
                                                )
                                        }
                                    </View>
                                </Page>
                            </React.Fragment>
                        )

                        {/* Question Analytics */ }
                        const total_page_character_count = 2600;
                        // let questions_in_page = 13;
                        let total_questions = report_data.question_list.length;
                        let question_page_array = [];
                        let total_character_count = 0;
                        let qs = -1;
                        let qe = 0;
                        let flag = 0;
                        for(let i = 0; i < total_questions; i++){
                            const count = report_data.question_list[i].character_count;
                            if((count + total_character_count) > total_page_character_count){
                                flag = 1;
                            }else{
                                total_character_count = total_character_count + count;
                            }

                            if(flag || (flag === 0 && i === (total_questions-1))){
                                question_page_array.push(
                                    {
                                        'question_start': qs+1,
                                        'question_end': i,
                                    }
                                )
                                qs = i;
                                qe = i;
                                flag = 0;
                                total_character_count = 0;
                            }
                        }
                        // let pages = Math.ceil(total_questions / questions_in_page);
                        let pages = question_page_array.length;
                        for (let i = 0; i < pages; i++) {
                            // let question_start = (i - 1) * (questions_in_page - 1) + (i - 1);
                            // let question_end = i * (questions_in_page - 1) + (i - 1);
                            // // Adjust question_end if it exceeds the actual number of questions
                            // question_end = Math.min(question_end, total_questions);
                            let question_start = question_page_array[i].question_start;
                            let question_end = question_page_array[i].question_end;
                            pdfPages.push(
                                <React.Fragment key={`question_analytics_` + i}>
                                    <Page size='A4' style={styles.page}>
                                        <View style={styles.header}>
                                            <View style={styles.col2}>
                                                <Image 
                                                    style={
                                                        [38,40,41].includes(user.college_id) 
                                                            ? styles.logo01 
                                                            : [51].includes(user.college_id)
                                                                ? styles.logo02
                                                                : [36].includes(user.college_id)
                                                                    ? styles.logo03
                                                                    : styles.logo1
                                                    } 
                                                    src={report_data.college_logo ? report_data.college_logo : `/images/feedback_images/resultsPage.jpg`} 
                                                />
                                            </View>
                                            <View style={styles.clgName}>
                                                <Text style={styles.headerText}>{report_data.college_name}</Text>
                                                {/* <Text style={styles.headerText}>R.M.K College of Engineering and Technology</Text> */}
                                                {
                                                    ![51].includes(user.college_id)
                                                        ? (
                                                            <Text style={styles.subHeaderText}>{tag_line}</Text>
                                                        )
                                                        : ""
                                                }
                                            </View>
                                            <View style={styles.col2}>
                                                {
                                                    [27].includes(user.college_id)
                                                        ? (
                                                            <Image style={styles.logo2} src="/images/feedback_images/resultsPage-logo2.jpg" />
                                                        )
                                                        : [32].includes(user.college_id) 
                                                            ? (
                                                                <Image style={styles.logo21} src="/images/feedback_images/rmk_group_28years_logo.png" />
                                                            )
                                                            : (
                                                                <View style={styles.logo2}></View>
                                                            )
                                                }
                                            </View>
                                        </View>

                                        {
                                            i === 1 ? (
                                                <View style={styles.rowSpan}>
                                                    <View style={styles.rowSpan1}>
                                                        <Text style={styles.sectionHeading}>Question Analytics</Text>
                                                    </View>
                                                </View>
                                            )
                                                : ''
                                        }

                                        <View style={styles.tableContainer}>
                                            <QuestionAnalyticsTable data={report_data.question_list}
                                                question_start={question_start} question_end={question_end} />
                                        </View>
                                    </Page>
                                </React.Fragment>
                            )
                        }

                        // students performance
                        let students_in_page = user.college_id === 38 ? 20 : 25;
                        let total_students = report_data.students_performance.length;
                        let student_pages = Math.ceil(total_students / students_in_page);
                        console.log("student pages -> ",student_pages)
                        for (let i = 1; i <= student_pages; i++) {
                            let student_start = (i - 1) * (students_in_page - 1) + (i - 1);
                            let student_end = i * (students_in_page - 1) + (i - 1);
                            // Adjust student_end if it exceeds the actual number of questions
                            student_end = Math.min(student_end, total_students);

                            pdfPages.push(
                                <React.Fragment key={`student_performance_` + i}>
                                    <Page size='A4' style={styles.page}>
                                        <View style={styles.header}>
                                            <View style={styles.col2}>
                                                <Image 
                                                    style={
                                                        [38,40,41].includes(user.college_id) 
                                                            ? styles.logo01 
                                                            : [51].includes(user.college_id)
                                                                ? styles.logo02
                                                                : [36].includes(user.college_id)
                                                                    ? styles.logo03
                                                                    : styles.logo1
                                                    } 
                                                    src={report_data.college_logo ? report_data.college_logo : `/images/feedback_images/resultsPage.jpg`} 
                                                />
                                            </View>
                                            <View style={styles.clgName}>
                                                <Text style={styles.headerText}>{report_data.college_name}</Text>
                                                {/* <Text style={styles.headerText}>R.M.K College of Engineering and Technology</Text> */}
                                                {
                                                    ![51].includes(user.college_id)
                                                        ? (
                                                            <Text style={styles.subHeaderText}>{tag_line}</Text>
                                                        )
                                                        : ""
                                                }
                                            </View>
                                            <View style={styles.col2}>
                                                {
                                                    [27].includes(user.college_id)
                                                        ? (
                                                            <Image style={styles.logo2} src="/images/feedback_images/resultsPage-logo2.jpg" />
                                                        )
                                                        : [32].includes(user.college_id) 
                                                            ? (
                                                                <Image style={styles.logo21} src="/images/feedback_images/rmk_group_28years_logo.png" />
                                                            )
                                                            : (
                                                                <View style={styles.logo2}></View>
                                                            )
                                                }
                                            </View>
                                        </View>

                                        {
                                            i === 1 ? (
                                                <View style={styles.rowSpan}>
                                                    <View style={styles.rowSpan1}>
                                                        <Text style={styles.sectionHeading}>Students Performance</Text>
                                                    </View>
                                                </View>
                                            )
                                                : ''
                                        }

                                        <View style={styles.tableContainer}>
                                            <StudentsPerformanceTable data={report_data.students_performance}
                                                student_start={student_start} student_end={student_end} test_duration={Math.ceil(report_data.test_duration / 60)} />
                                        </View>
                                    </Page>
                                </React.Fragment>
                            )

                        }

                        return <>{pdfPages}</>
                    })
                }
            </Document>
        // </PDFViewer >
    )
}

export default McqReportTemplate;

//table stylings
const styles2 = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
    },
    table: {
        marginBottom: 10,
        fontSize: 10,
        width: '100%',
        border: '1px solid grey',
        marginTop: 20,
        marginLeft: 20,
        marginRight: 30,
        borderRadius: '5px'
    },
    tableHeader: {
        backgroundColor: '#F0F0F0',
        fontWeight: 'bold',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottom: '1px solid #e6e6e6'
    },
    tableRow2: {
        flexDirection: 'row',
    },
    tableCol01: {
        width: '16%',
    },
    tableCol: {
        width: '18%',
    },
    tableCol1: {
        width: '16%',
    },
    tableCol3: {
        width: '100%',
    },
    tableCell1: {
        margin: '3px',
        fontSize: 11,
    },
    tableValueCell1: {
        margin: '3px',
        fontSize: 8.5,
        padding: "1px",
        textAlign: 'center',
    },
    tableCell2: {
        margin: '3px',
        fontSize: 10,
        textAlign: 'center',
        // wordBreak: 'break-all', // Allow long words to break
        whiteSpace: 'nowrap', // Prevent line breaks within the text
        flexWrap: 'nowrap'
    },
    tableCell3: {
        margin: '3px',
        fontSize: 12,
        textAlign: 'center',
        fontWeight: 'bolder'
    },

    tpTableCol1: {
        width: '10%',
    },
    tpTableCol2: {
        width: '50%',
    },
    tpTableCol3: {
        width: '20%',
    },
    tpTableCell2: {
        margin: '3px',
        fontSize: 10,
    },
    tpTableValueCell1: {
        margin: '3px',
        fontSize: 9,
        padding: "1px",
    },

    qaTableCol2: {
        width: '75%',
    },
    qaTableCol3: {
        width: '25%',
    },
    qaTableCol4: {
        width: '15%',
    },

    spTableCol1: {
        width: '10%',
    },
    spTableCol01: {
        width: '7%',
    },
    spTableCol2: {
        width: '18%',
    },
    spTableCol21: {
        width: '25%',
    },
    spTableCol22: {
        width: '20%',
    },

    question_img: {
        width: '20%',
        height: '45px',
        marginLeft: '15px',
        marginBottom: '2px'
    }
});

const OverallPerformanceTable = (props) => {
    return (
        <View style={styles2.table}>
            <View>
                <View style={styles2.tableHeader}>
                    <View style={styles2.tableRow}>
                        <View style={{ ...styles2.tableCol01, borderRight: '1px solid white' }} >
                            <Text style={styles2.tableCell1}></Text>
                        </View>
                        <View style={{ ...styles2.tableCol1, borderRight: '1px solid white' }}>
                            <Text style={styles2.tableCell2}>Very Good</Text>
                        </View>
                        <View style={{ ...styles2.tableCol1, borderRight: '1px solid white' }}>
                            <Text style={styles2.tableCell2}>Good</Text>
                        </View>
                        <View style={{ ...styles2.tableCol1, borderRight: '1px solid white' }}>
                            <Text style={styles2.tableCell2}>Average</Text>
                        </View>
                        <View style={{ ...styles2.tableCol, borderRight: '1px solid white' }}>
                            <Text style={styles2.tableCell2}>Below Average</Text>
                        </View>
                        <View style={styles2.tableCol}>
                            <Text style={styles2.tableCell2}>Test Average</Text>
                        </View>
                    </View>
                </View>
                <OverallPerformanceTableRow items={props.data} />
            </View>
        </View>
    )
}


export const OverallPerformanceTableRow = (props) => {
    return (
        <View style={styles2.tableRow}>
            <View style={{ ...styles2.tableCol01, borderRight: '1px solid #e6e6e6' }}>
                <Text style={styles2.tableValueCell1}>(%) Scored</Text>
            </View>
            <View style={{ ...styles2.tableCol1, borderRight: '1px solid #e6e6e6' }}>
                <Text style={styles2.tableValueCell1}>{props.items.very_good_percentage ? props.items.very_good_percentage : 0}</Text>
            </View>
            <View style={{ ...styles2.tableCol1, borderRight: '1px solid #e6e6e6' }}>
                <Text style={styles2.tableValueCell1}>{props.items.good_percentage ? props.items.good_percentage : 0}</Text>
            </View>
            <View style={{ ...styles2.tableCol1, borderRight: '1px solid #e6e6e6' }}>
                <Text style={styles2.tableValueCell1}>{props.items.average_percentage ? props.items.average_percentage : 0}</Text>
            </View>
            <View style={{ ...styles2.tableCol, borderRight: '1px solid #e6e6e6' }}>
                <Text style={styles2.tableValueCell1}>{props.items.below_average_percentage ? props.items.below_average_percentage : 0}</Text>
            </View>
            <View style={styles2.tableCol}>
                <Text style={styles2.tableValueCell1}>{props.items.test_average ? props.items.test_average : 0.0}</Text>
            </View>
        </View>
    )
};


const TopicPerformanceTable = (props) => {
    return (
        <View style={styles2.table}>
            <View>
                <View style={styles2.tableHeader}>
                    <View style={styles2.tableRow}>
                        <View style={{ ...styles2.tpTableCol1, borderRight: '1px solid white' }} >
                            <Text style={styles2.tableCell1}>S.No</Text>
                        </View>
                        <View style={{ ...styles2.tpTableCol2, borderRight: '1px solid white' }}>
                            <Text style={styles2.tpTableCell2}>Topic Name</Text>
                        </View>
                        <View style={{ ...styles2.tpTableCol3, borderRight: '1px solid white' }}>
                            <Text style={styles2.tableCell2}>Total Questions</Text>
                        </View>
                        <View style={styles2.tpTableCol3}>
                            <Text style={styles2.tableCell2}>Average (%)</Text>
                        </View>
                    </View>
                </View>
                <TopicPerformanceTableRow items={props.data} />
            </View>
        </View>
    )
}

const TopicPerformanceTableRow = (props) => {
    const rows = props.items.map((item, index) => {
        return (
            <View style={styles2.tableRow}>
                <View style={{ ...styles2.tpTableCol1, borderRight: '1px solid #e6e6e6' }}>
                    <Text style={styles2.tableValueCell1}>{index + 1}</Text>
                </View>
                <View style={{ ...styles2.tpTableCol2, borderRight: '1px solid #e6e6e6' }}>
                    <Text style={styles2.tpTableValueCell1}>{item.name ? item.name : ''}</Text>
                </View>
                <View style={{ ...styles2.tpTableCol3, borderRight: '1px solid #e6e6e6' }}>
                    <Text style={styles2.tableValueCell1}>{item.total_count ? item.total_count : 0}</Text>
                </View>
                <View style={{ ...styles2.tpTableCol3, borderRight: '1px solid #e6e6e6' }}>
                    <Text style={styles2.tableValueCell1}>{item.average ? item.average : 0.0}</Text>
                </View>
            </View>
        )
    })
    return <Fragment>{rows}</Fragment>;
}


const QuestionAnalyticsTable = (props) => {
    return (
        <View style={styles2.table}>
            <View>
                <View style={styles2.tableHeader}>
                    <View style={styles2.tableRow}>
                        <View style={{ ...styles2.tpTableCol1, borderRight: '1px solid white' }} >
                            <Text style={styles2.tableCell1}>S.No</Text>
                        </View>
                        <View style={{ ...styles2.qaTableCol2, borderRight: '1px solid white' }}>
                            <Text style={styles2.tpTableCell2}>Question</Text>
                        </View>
                        {/* <View style={{ ...styles2.qaTableCol3, borderRight: '1px solid white' }}>
                            <Text style={styles2.tableCell2}>Question image</Text>
                        </View> */}
                        <View style={styles2.qaTableCol4}>
                            <Text style={styles2.tableCell2}>Marks</Text>
                        </View>
                    </View>
                </View>
                <QuestionAnalyticsTableRow items={props.data} question_start={props.question_start} question_end={props.question_end} />
            </View>
        </View>
    )
}

const QuestionAnalyticsTableRow = (props) => {
    const rows = props.items.map((item, index) => {
        if (index >= props.question_start && index <= props.question_end) {
            let question = item.name.replace("<!DOCTYPE html>","");
            return (
                <View style={styles2.tableRow}>
                    <View style={{ ...styles2.tpTableCol1, borderRight: '1px solid #e6e6e6' }}>
                        <Text style={styles2.tableValueCell1}>{index + 1}</Text>
                    </View>
                    <View style={{ ...styles2.qaTableCol2, borderRight: '1px solid #e6e6e6' }}>
                        <Text style={styles2.tpTableValueCell1}>
                        <Html style={styles2.tpTableValueCell1}>{question ? question : ""}</Html>
                            </Text>
                        {
                            item.image
                                ? (
                                    <Image src={item.image} style={styles2.question_img} alt="image" />
                                )
                                : ''
                        }
                    </View>
                    {/* <View style={{ ...styles2.qaTableCol3, borderRight: '1px solid #e6e6e6' }}>
                        <Text style={styles2.tableValueCell1}>{item.image ? item.image : ''}</Text>
                    </View> */}
                    <View style={styles2.qaTableCol4}>
                        <Text style={styles2.tableValueCell1}>{item.marks_assigned ? item.marks_assigned : 0.0}</Text>
                    </View>
                </View>
            )
        }
    })
    return <Fragment>{rows}</Fragment>;
}


const StudentsPerformanceTable = (props) => {
    return (
        <View style={styles2.table}>
            <View>
                <View style={styles2.tableHeader}>
                    <View style={styles2.tableRow}>
                        <View style={{ ...styles2.spTableCol01, borderRight: '1px solid white' }} >
                            <Text style={styles2.tableCell1}>S.No</Text>
                        </View>
                        <View style={{ ...styles2.spTableCol21, borderRight: '1px solid white' }}>
                            <Text style={styles2.tpTableCell2}>Name</Text>
                        </View>
                        <View style={{ ...styles2.spTableCol2, borderRight: '1px solid white' }}>
                            <Text style={styles2.tableCell2}>Roll number</Text>
                        </View>
                        <View style={{ ...styles2.spTableCol22, borderRight: '1px solid white' }}>
                            <Text style={styles2.tableCell2}>Branch</Text>
                        </View>
                        {/* <View style={[styles2.spTableCol4, { borderRight: '1px solid white' }]}>
                            <Text style={styles2.tableCell2}>Section</Text>
                        </View> */}
                        <View style={{ ...styles2.spTableCol1, borderRight: '1px solid white' }}>
                            <Text style={styles2.tableCell2}>Marks</Text>
                        </View>
                        <View style={{ ...styles2.spTableCol1, borderRight: '1px solid white' }}>
                            <Text style={styles2.tableCell2}>(%) Scored</Text>
                        </View>
                        <View style={styles2.spTableCol1}>
                            <Text style={styles2.tableCell2}>Time (min)</Text>
                        </View>
                    </View>
                </View>
                <StudentsPerformanceTableRow items={props.data} student_start={props.student_start} student_end={props.student_end}
                    test_duration={props.test_duration} />
            </View>
        </View>
    )
}

const StudentsPerformanceTableRow = (props) => {
    const rows = props.items.map((item, index) => {
        if (index >= props.student_start && index <= props.student_end) {
            return (
                <View style={styles2.tableRow}>
                    <View style={{ ...styles2.spTableCol01, borderRight: '1px solid #e6e6e6' }}>
                        <Text style={styles2.tableValueCell1}>{index + 1}</Text>
                    </View>
                    <View style={{ ...styles2.spTableCol21, borderRight: '1px solid #e6e6e6' }}>
                        <Text style={styles2.tpTableValueCell1}>{item.name ? item.name : ''}</Text>
                    </View>
                    <View style={{ ...styles2.spTableCol2, borderRight: '1px solid #e6e6e6' }}>
                        <Text style={styles2.tableValueCell1}>{item.roll_number ? item.roll_number : ''}</Text>
                    </View>
                    <View style={{ ...styles2.spTableCol22, borderRight: '1px solid #e6e6e6' }}>
                        <Text style={styles2.tableValueCell1}>{item.department_short_name ? `${item.department_short_name} (${item.section})` : ''}</Text>
                    </View>
                    {/* <View style={[styles2.spTableCol4, { borderRight: '1px solid #e6e6e6' }]}>
                        <Text style={styles2.tableValueCell1}>{item.section ? item.section : ''}</Text>
                    </View> */}
                    {
                        item.status === 1
                            ? (
                                <>
                                    <View style={{ ...styles2.spTableCol1, borderRight: '1px solid #e6e6e6' }}>
                                        <Text style={styles2.tableValueCell1}>{item.total_marks ? item.total_marks : 0}</Text>
                                    </View>
                                    <View style={{ ...styles2.spTableCol1, borderRight: '1px solid #e6e6e6' }}>
                                        <Text style={styles2.tableValueCell1}>{item.percentage_scored ? item.percentage_scored : 0.0}</Text>
                                    </View>
                                    <View style={styles2.spTableCol1}>
                                        <Text style={styles2.tableValueCell1}>
                                            {item.total_time_spent
                                                ? (item.total_time_spent / 60).toFixed(1) < props.test_duration
                                                    ? (item.total_time_spent / 60).toFixed(1) : props.test_duration
                                                : 0}
                                        </Text>
                                    </View>
                                </>
                            )
                            : (
                                <>
                                    <View style={{ ...styles2.spTableCol1, borderRight: '1px solid #e6e6e6' }}>
                                        <Text style={styles2.tableValueCell1}>-</Text>
                                    </View>
                                    <View style={{ ...styles2.spTableCol1, borderRight: '1px solid #e6e6e6' }}>
                                        <Text style={styles2.tableValueCell1}>-</Text>
                                    </View>
                                    <View style={styles2.spTableCol1}>
                                        <Text style={styles2.tableValueCell1}>-</Text>
                                    </View>
                                </>
                            )
                    }

                </View>
            )
        }
    })
    return <Fragment>{rows}</Fragment>;
}






// function parseLatex(input) {
//     // Replace LaTeX syntax with corresponding React components
//     // This is a simplified example, you might need to implement a more complete parser
//     const latexToReact = {
//         '\\textbf': (content) => <Text style={{ fontWeight: 'bold' }}>{content}</Text>,
//         '\\textit': (content) => <Text style={{ fontStyle: 'italic' }}>{content}</Text>,
//         // Add more LaTeX commands here
//     };

//     const regex = /\\(.*?)\{(.*?)\}/g;
//     const parsed = input.replace(regex, (_, command, content) => {
//         const reactComponent = latexToReact[command];
//         return reactComponent ? reactComponent(content) : content;
//     });

//     return parsed;
// }