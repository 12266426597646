import React from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Navbar from "../navbar/navbar.component";
import SideNavBar from "./SideNavBar/SideNavBar";

const drawerWidth = 200;


export default function LayoutwithSideNav({children}) {


  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Navbar />
      </AppBar>

      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            border: "none",
          },
        }}
      >
        <SideNavBar />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {/* <Typography align="center" variant="h5" gutterBottom>
        Faculty-Student Allocation
      </Typography> */}
        {/* <div className="col-lg-6 col-md-6 col-sm-6">
          <h2 className="mt-1">
            <p className="font-weight-bold" id="courseName">
              Faculty Student Allocation
            </p>
            <hr className="titlehr" />
          </h2>
        </div> */}
        {/* <Grid container alignItems="center" columns={{ xs: 12, sm: 12, md: 8 }}>
          <SearchComponent />
        </Grid> */}
        {children}
      </Box>
    </Box>
  );
}

// Pagination i.e bottom page indexing needs to be added.
// SideNav bar to be made responsive.
