import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenNotification } from '../../store/reducers/common.reducer';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


// MaterialReduxNotification.deafultProps={
//     open : false,
//     setOpen : null,
//     severity : "error",
//     message : 'No Notification Text',
// }

export default function MaterialReduxNotification() {
//   const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const {openNotification,notificationMessage,notificationType} = useSelector(state=>state.Common)


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setOpenNotification(false));
  };

  return (
      <Snackbar open={openNotification} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{horizontal:'right',vertical : 'top'}}>
        <Alert variant="filled" onClose={handleClose} severity={notificationType} sx={{ width: '100%' }}>
          {notificationMessage}
        </Alert>
      </Snackbar>
  );
}
