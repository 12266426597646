import React from "react";
import Navbar from "../components/navbar/navbar.component";
import QuestionnairePanel from "../components/questionnaire/questionnaire.component";

const QuestionnairePage = () => {
    return (
        <div className="weekly-challenge" style={{overflowX:"hidden"}}>
            <Navbar />
            <div className="row" style={{marginTop:'70px',backgroundColor:'#f9f9f9'}}>
                <QuestionnairePanel />
            </div>
        </div>
    )
}

export default QuestionnairePage;