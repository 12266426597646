import React, {useState} from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';
import { Fragment } from 'react';
// import { Table, Cell, Column, Row } from '@react-pdf/table';

const styles = StyleSheet.create({
  viewer: {
    width: '100%', //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  page: {
    backgroundColor: 'white',
    flexDirection: 'column',
    margin: 5,
    // paddingBottom: 30,
    // paddingTop: 5,
    marginLeft: 50,
    marginRight: 30,
    width: '90%'
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 2,
    width: '90%'
  },
  logo1: {
    width: 50,
    height: 70,
    marginLeft: "30px",
  },
  logo01: {
    width: 60,
    height: 70,
    marginLeft: "30px",
  },
  logo02: {
      width: 50,
      height: 50,
      marginLeft: "30px",
  },
  logo03: {
      width: 70,
      height: 50,
      marginLeft: "30px",
  },
  logo2: {
    width: 70,
    height: 60,
    marginRight: "10px"
  },
  headerText: {
    fontSize: 15,
    fontWeight: 'bold',
    marginTop:-10,
    marginLeft: 15,
    marginRight: 15,
    textAlign: 'center'
  },
  subHeaderText: {
    fontSize: 12,
    marginTop: "5px",
    textAlign: 'center'
  },
  clgName: {
    alignItems: 'center',
    width: "66.67%",
  },
  semYearDetails: {
    alignItems: 'center',
    width: '90%',
    marginTop:-15
  },
  sfReportLabel: {
    fontWeight: 500,
    fontSize: 12
  },
  semYearLabel: {
    fontSize: 10
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 5,
  },
  col2: {
    width: "16.67%",
  },
  col8: {
    width: "66.67%",
  },
  col3: {
    width: "16.67%",
  },
  branchDetails: {
    paddingLeft: 20,
    paddingRight: 30,
    marginTop: 8,
    width: '90%',
    lineHeight: 0.7
  },
  label: {
    fontWeight: 'bold',
    fontSize: 11,
  },
  value: {
    marginLeft: 5,
    fontWeight: 'normal',
    fontSize: 10,
    marginTop: '1px'
  },
  branchDetail1: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  branchDetail21: {
    width: '70%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    wordBreak: 'break-all',
    marginTop: 5,
    lineHeight: 1,
  },
  branchDetail22: {
    width: '30%',
    flexDirection: 'row',
    marginTop: 5,
    justifyContent: 'flex-end',
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 5,
    marginTop:-2,
    width: '90%'
  },
  signatureRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 20,
    width: '90%',
  },
  hodSign: {
    fontSize: 11,
    marginLeft: 30
  },
  principalSign: {
    fontSize: 11,
    marginRight: 30,
  }
});

const PdfDocument = (props) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  let tag_line = ""
  if(user){
      if(user.college_id === 27){
          tag_line = "(An Autonomous Institution)";
      }else if(user.college_id === 29){
          tag_line = "(An Autonomous Institution)";
      }else if(user.college_id === 32){
          tag_line = "(An Autonomous Institution)";
      }else if(user.college_id === 40){
          tag_line = "(An Autonomous Institution)";
      }else if(user.college_id === 41){
          tag_line = "(An Autonomous Institution)";
      }else if(user.college_id === 38){
          tag_line = "(Deemed to be University)";
      }else if(user.college_id === 51){
          tag_line = "";
      }else if(user.college_id === 36){
          tag_line = "(An Autonomous Institution)";
      }else{
          tag_line = "(An Autonomous Institution)";
      }
  }
  return (
    // <PDFViewer style={styles.viewer}>
      <Document>
        {
          props.resultsData.map(feedback => {
            if (feedback.feedback_type === 1) {
              let questions_in_page = 25;
              let total_questions = feedback.questions.length;
              let pages = Math.ceil(total_questions / questions_in_page);
              
              let pdfPages = []
              for (let i = 1; i <= pages; i++) {
                let question_start = (i - 1) * (questions_in_page-1) + (i - 1);
                let question_end = i * (questions_in_page-1) + (i - 1);
                // Adjust question_end if it exceeds the actual number of questions
                question_end = Math.min(question_end, total_questions);

                pdfPages.push(
                  <React.Fragment key={i}>
                    <Page size='A4' style={styles.page}>
                      <View style={styles.header}>
                        <View style={styles.col2}>
                            <Image 
                                style={
                                    [38,40,41].includes(user.college_id) 
                                        ? styles.logo01 
                                        : [51].includes(user.college_id)
                                            ? styles.logo02
                                            : [36].includes(user.college_id)
                                                ? styles.logo03
                                                : styles.logo1
                                } 
                                src={feedback.college_logo ? feedback.college_logo : `/images/feedback_images/resultsPage.jpg`} 
                            />
                        </View>
                        <View style={styles.clgName}>
                            <Text style={styles.headerText}>{feedback.college_name}</Text>
                            {/* <Text style={styles.headerText}>R.M.K College of Engineering and Technology</Text> */}
                            {
                                ![51].includes(user.college_id)
                                    ? (
                                        <Text style={styles.subHeaderText}>{tag_line}</Text>
                                    )
                                    : ""
                            }
                        </View>
                        <View style={styles.col2}>
                            {
                                [27].includes(user.college_id)
                                    ? (
                                        <Image style={styles.logo2} src="/images/feedback_images/resultsPage-logo2.jpg" />
                                    )
                                    : (
                                        <View style={styles.logo2}></View>
                                    )
                            }
                          </View>
                      </View>
                      <View style={styles.semYearDetails}>
                        <Text style={styles.sfReportLabel}>STUDENT FEEDBACK REPORT</Text>
                        <Text style={styles.semYearLabel}>ACADEMIC YEAR: 2023-24 {parseInt(feedback.semester_id) % 2 == 0 ? 'EVEN' : 'ODD'} SEMESTER</Text>
                        {/* <Text style={styles.semYearLabel}>ACADEMIC YEAR: 2023-24 {feedback.type.toUpperCase()} SEMESTER</Text> */}
                      </View>

                      <View style={styles.branchDetails}>
                        <View style={styles.branchDetail1}>
                          <View style={styles.branchDetail21}>
                            <Text style={styles.label}>Branch: </Text>
                            <Text style={[styles.value, { width: '80%' }]} numberOfLines={1} ellipsizeMode='tail'>{feedback.branch_name}</Text>
                          </View>
                          <View style={[styles.branchDetail22, { width: '30%' }]}>
                            <Text style={styles.label}>Semester: </Text>
                            <Text style={styles.value}>{feedback.semester_id} ({feedback.section_name})</Text>
                          </View>
                        </View>
                        <View style={styles.branchDetail21}>
                          <Text style={styles.label}>Subject: </Text>
                          <Text style={[styles.value, { width: '80%' }]} numberOfLines={1} ellipsizeMode='tail'>{feedback.subject_name}</Text>
                        </View>
                        <View style={styles.branchDetail21}>
                          <Text style={styles.label}>Faculty: </Text>
                          <Text style={[styles.value, { width: '80%' }]} numberOfLines={1} ellipsizeMode='tail'>{props.faculty_name}</Text>
                        </View>
                        {
                          [40,41].includes(feedback.college_id)
                            ? (
                              <View style={styles.branchDetail21}>
                                <Text style={styles.label}>Gender: </Text>
                                <Text style={[styles.value, { width: '80%' }]} numberOfLines={1} ellipsizeMode='tail'>{props.faculty_gender}</Text>
                              </View>
                            )
                            : ""
                        }
                      </View>

                      <View style={styles.tableContainer}>
                        <Table feedback={feedback} num1={question_start} num={question_end} total_questions={total_questions} />
                      </View>

                      <View style={styles.signatureRow} fixed={true}>
                        <Text style={styles.hodSign}>HoD</Text>
                        <Text style={styles.principalSign}>PRINCIPAL</Text>
                      </View>
                    </Page>
                  </React.Fragment>
                )
              }
              return <>{pdfPages}</>
            } else if (feedback.feedback_type == 2) {
              let questions_in_page = 25;
              let total_questions = feedback.questions.length;
              let pages = Math.ceil(total_questions / questions_in_page);
              
              let pdfPages = []
              for (let i = 1; i <= pages; i++) {
                let question_start = (i - 1) * (questions_in_page-1) + (i - 1);
                let question_end = i * (questions_in_page-1) + (i - 1);
                // Adjust question_end if it exceeds the actual number of questions
                question_end = Math.min(question_end, total_questions);

                pdfPages.push(
                  <React.Fragment>
                    <Page size='A4' style={styles.page}>
                    <View style={styles.header}>
                        <View style={styles.col2}>
                            <Image 
                                style={
                                    [38,40,41].includes(user.college_id) 
                                        ? styles.logo01 
                                        : [51].includes(user.college_id)
                                            ? styles.logo02
                                            : [36].includes(user.college_id)
                                                ? styles.logo03
                                                : styles.logo1
                                } 
                                src={feedback.college_logo ? feedback.college_logo : `/images/feedback_images/resultsPage.jpg`} 
                            />
                        </View>
                        <View style={styles.clgName}>
                            <Text style={styles.headerText}>{feedback.college_name}</Text>
                            {/* <Text style={styles.headerText}>R.M.K College of Engineering and Technology</Text> */}
                            {
                                ![51].includes(user.college_id)
                                    ? (
                                        <Text style={styles.subHeaderText}>{tag_line}</Text>
                                    )
                                    : ""
                            }
                        </View>
                        <View style={styles.col2}>
                            {
                                [27].includes(user.college_id)
                                    ? (
                                        <Image style={styles.logo2} src="/images/feedback_images/resultsPage-logo2.jpg" />
                                    )
                                    : (
                                        <View style={styles.logo2}></View>
                                    )
                            }
                          </View>
                      </View>
                      <View style={styles.semYearDetails}>
                        <Text style={styles.sfReportLabel}>HOD FEEDBACK REPORT</Text>
                        <Text style={styles.semYearLabel}>ACADEMIC YEAR: 2023-24 {parseInt(feedback.semester_id) % 2 == 0 ? 'EVEN' : 'ODD'} SEMESTER</Text>
                        {/* <Text style={styles.semYearLabel}>ACADEMIC YEAR: 2023-24 {feedback.type.toUpperCase()} SEMESTER</Text> */}
                      </View>

                      <View style={styles.branchDetails}>
                        <View style={styles.branchDetail1}>
                          <View style={styles.branchDetail21}>
                            <Text style={styles.label}>Branch: </Text>
                            <Text style={[styles.value, { width: '80%' }]} numberOfLines={1} ellipsizeMode='tail'>{feedback.branch_name}</Text>
                          </View>
                        </View>
                        <View style={styles.branchDetail1}>
                          <View style={styles.branchDetail21}>
                            <Text style={styles.label}>Name of HoD: </Text>
                            <Text style={[styles.value, { width: '70%' }]} numberOfLines={1} ellipsizeMode='tail'>{props.faculty_name}</Text>
                          </View>
                          <View style={[styles.branchDetail22, { width: '30%' }]}>
                            <Text style={styles.label}>No of Students: </Text>
                            <Text style={styles.value}>{feedback.number_of_students}</Text>
                          </View>
                        </View>
                        {
                          [40,41].includes(feedback.college_id)
                            ? (
                              <View style={styles.branchDetail1}>
                                <View style={styles.branchDetail21}>
                                  <Text style={styles.label}>Gender: </Text>
                                  <Text style={[styles.value, { width: '80%' }]} numberOfLines={1} ellipsizeMode='tail'>{props.faculty_name}</Text>
                                </View>
                              </View>
                            )
                            : ""
                        }
                      </View>

                      <View style={styles.tableContainer}>
                        <Table feedback={feedback} num1={question_start} num={question_end} total_questions={total_questions} />
                      </View>

                      <View style={styles.signatureRow} fixed={true}>
                        <Text style={styles.hodSign}>HoD</Text>
                        <Text style={styles.principalSign}>PRINCIPAL</Text>
                      </View>
                    </Page >
                  </React.Fragment>
                )
              }
              return <>{pdfPages}</>
            } else if (feedback.feedback_type == 3) {
              let questions_in_page = 25;
              let total_questions = feedback.questions.length;
              let pages = Math.ceil(total_questions / questions_in_page);
              
              let pdfPages = []
              for (let i = 1; i <= pages; i++) {
                let question_start = (i - 1) * (questions_in_page-1) + (i - 1);
                let question_end = i * (questions_in_page-1) + (i - 1);
                // Adjust question_end if it exceeds the actual number of questions
                question_end = Math.min(question_end, total_questions);

                pdfPages.push(
                  <React.Fragment>
                    <Page size='A4' style={styles.page}>
                    <View style={styles.header}>
                        <View style={styles.col2}>
                            <Image 
                                style={
                                    [38,40,41].includes(user.college_id) 
                                        ? styles.logo01 
                                        : [51].includes(user.college_id)
                                            ? styles.logo02
                                            : [36].includes(user.college_id)
                                                ? styles.logo03
                                                : styles.logo1
                                } 
                                src={feedback.college_logo ? feedback.college_logo : `/images/feedback_images/resultsPage.jpg`} 
                            />
                        </View>
                        <View style={styles.clgName}>
                            <Text style={styles.headerText}>{feedback.college_name}</Text>
                            {/* <Text style={styles.headerText}>R.M.K College of Engineering and Technology</Text> */}
                            {
                                ![51].includes(user.college_id)
                                    ? (
                                        <Text style={styles.subHeaderText}>{tag_line}</Text>
                                    )
                                    : ""
                            }
                        </View>
                        <View style={styles.col2}>
                            {
                                [27].includes(user.college_id)
                                    ? (
                                        <Image style={styles.logo2} src="/images/feedback_images/resultsPage-logo2.jpg" />
                                    )
                                    : (
                                        <View style={styles.logo2}></View>
                                    )
                            }
                          </View>
                      </View>
                      <View style={styles.semYearDetails}>
                        <Text style={styles.sfReportLabel}>COUNSELLOR FEEDBACK REPORT</Text>
                        <Text style={styles.semYearLabel}>ACADEMIC YEAR: 2023-24 {parseInt(feedback.semester_id) % 2 == 0 ? 'EVEN' : 'ODD'} SEMESTER</Text>
                        {/* <Text style={styles.semYearLabel}>ACADEMIC YEAR: 2023-24 {feedback.type.toUpperCase()} SEMESTER</Text> */}
                      </View>

                      <View style={styles.branchDetails}>
                        <View style={styles.branchDetail1}>
                          <View style={styles.branchDetail21}>
                            <Text style={styles.label}>Branch: </Text>
                            <Text style={[styles.value, { width: '80%' }]} numberOfLines={1} ellipsizeMode='tail'>{feedback.branch_name}</Text>
                          </View>
                          <View style={[styles.branchDetail22, { width: '30%' }]}>
                            <Text style={styles.label}>Semester: </Text>
                            <Text style={styles.value}>{feedback.semester_id}</Text>
                          </View>
                        </View>
                        <View style={styles.branchDetail1}>
                          <View style={styles.branchDetail21}>
                            <Text style={styles.label}>Counsellor: </Text>
                            <Text style={[styles.value, { width: '80%' }]} numberOfLines={1} ellipsizeMode='tail'>{props.faculty_name}</Text>
                          </View>
                          <View style={[styles.branchDetail22, { width: '30%' }]}>
                            <Text style={styles.label}>No of Students: </Text>
                            <Text style={styles.value}>{feedback.number_of_students}</Text>
                          </View>
                        </View>
                        {
                          [40,41].includes(feedback.college_id)
                            ? (
                              <View style={styles.branchDetail1}>
                                <View style={styles.branchDetail21}>
                                  <Text style={styles.label}>Gender: </Text>
                                  <Text style={[styles.value, { width: '80%' }]} numberOfLines={1} ellipsizeMode='tail'>{props.faculty_gender}</Text>
                                </View>
                              </View>
                            )
                            : ""
                        }
                      </View>

                      <View style={styles.tableContainer}>
                        <Table feedback={feedback} num1={question_start} num={question_end} total_questions={total_questions} />
                      </View>

                      <View style={styles.signatureRow} fixed={true}>
                        <Text style={styles.hodSign}>HoD</Text>
                        <Text style={styles.principalSign}>PRINCIPAL</Text>
                      </View>
                    </Page>
                  </React.Fragment>
                )
              }
              return <>{pdfPages}</>
            } else if (feedback.feedback_type == 4) {
              let questions_in_page = 25;
              let total_questions = feedback.questions.length;
              let pages = Math.ceil(total_questions / questions_in_page);
              
              let pdfPages = []
              for (let i = 1; i <= pages; i++) {
                let question_start = (i - 1) * (questions_in_page-1) + (i - 1);
                let question_end = i * (questions_in_page-1) + (i - 1);
                // Adjust question_end if it exceeds the actual number of questions
                question_end = Math.min(question_end, total_questions);

                pdfPages.push(
                  <React.Fragment>
                    <Page size='A4' style={styles.page}>
                    <View style={styles.header}>
                        <View style={styles.col2}>
                            <Image 
                                style={
                                    [38,40,41].includes(user.college_id) 
                                        ? styles.logo01 
                                        : [51].includes(user.college_id)
                                            ? styles.logo02
                                            : [36].includes(user.college_id)
                                                ? styles.logo03
                                                : styles.logo1
                                } 
                                src={feedback.college_logo ? feedback.college_logo : `/images/feedback_images/resultsPage.jpg`} 
                            />
                        </View>
                        <View style={styles.clgName}>
                            <Text style={styles.headerText}>{feedback.college_name}</Text>
                            {/* <Text style={styles.headerText}>R.M.K College of Engineering and Technology</Text> */}
                            {
                                ![51].includes(user.college_id)
                                    ? (
                                        <Text style={styles.subHeaderText}>{tag_line}</Text>
                                    )
                                    : ""
                            }
                        </View>
                        <View style={styles.col2}>
                            {
                                [27].includes(user.college_id)
                                    ? (
                                        <Image style={styles.logo2} src="/images/feedback_images/resultsPage-logo2.jpg" />
                                    )
                                    : (
                                        <View style={styles.logo2}></View>
                                    )
                            }
                          </View>
                      </View>
                      <View style={styles.semYearDetails}>
                        <Text style={styles.sfReportLabel}>BRANCH/YEAR COORDINATOR FEEDBACK REPORT</Text>
                        <Text style={styles.semYearLabel}>ACADEMIC YEAR: 2023-24 {parseInt(feedback.semester_id) % 2 == 0 ? 'EVEN' : 'ODD'} SEMESTER</Text>
                        {/* <Text style={styles.semYearLabel}>ACADEMIC YEAR: 2023-24 {feedback.type.toUpperCase()} SEMESTER</Text> */}
                      </View>

                      <View style={styles.branchDetails}>
                        <View style={styles.branchDetail1}>
                          <View style={styles.branchDetail21}>
                            <Text style={styles.label}>Branch: </Text>
                            <Text style={[styles.value, { width: '80%' }]} numberOfLines={1} ellipsizeMode='tail'>{feedback.branch_name}</Text>
                          </View>
                          <View style={[styles.branchDetail22, { width: '30%' }]}>
                            <Text style={styles.label}>Year: </Text>
                            <Text style={styles.value}>{feedback.year}</Text>
                          </View>
                        </View>
                        <View style={styles.branchDetail1}>
                          <View style={styles.branchDetail21}>
                            <Text style={styles.label}>Coordinator: </Text>
                            <Text style={[styles.value, { width: '70%' }]} numberOfLines={1} ellipsizeMode='tail'>{props.faculty_name}</Text>
                          </View>
                          <View style={[styles.branchDetail22, { width: '30%' }]}>
                            <Text style={styles.label}>No of Students: </Text>
                            <Text style={styles.value}>{feedback.number_of_students}</Text>
                          </View>
                        </View>
                        {
                          [40,41].includes(feedback.college_id)
                            ? (
                              <View style={styles.branchDetail1}>
                                <View style={styles.branchDetail21}>
                                  <Text style={styles.label}>Gender: </Text>
                                  <Text style={[styles.value, { width: '80%' }]} numberOfLines={1} ellipsizeMode='tail'>{props.faculty_gender}</Text>
                                </View>
                              </View>
                            )
                            : ""
                        }
                      </View>

                      <View style={styles.tableContainer}>
                        <Table feedback={feedback} num1={question_start} num={question_end} total_questions={total_questions} />
                      </View>

                      <View style={styles.signatureRow} fixed={true}>
                        <Text style={styles.hodSign}>HoD</Text>
                        <Text style={styles.principalSign}>PRINCIPAL</Text>
                      </View>
                    </Page>
                  </React.Fragment>
                )
              }
              return <>{pdfPages}</>
            }
          })
        }
      </Document>
  );

}
//ReactPDF.render(doc);
export default PdfDocument;

const styles2 = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  table: {
    marginBottom: 50,
    fontSize: 10,
    width: '100%',
    border: '1px solid grey',
    marginTop: 10,
    marginLeft: 20,
    marginRight: 30,
    borderRadius: '5px'
  },
  tableHeader: {
    backgroundColor: '#F0F0F0',
    fontWeight: 'bold',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: '1px solid #e6e6e6'
  },
  tableRow2: {
    flexDirection: 'row',
  },
  tableCol1: {
    width: '85%',
  },
  tableCol2: {
    width: '15%',
  },
  tableCol3: {
    width: '100%',
  },
  tableCell1: {
    margin: '4px',
    fontSize: 11,
  },
  tableValueCell1: {
    margin: '4px',
    fontSize: 10,
    padding: "1px"
  },
  tableCell2: {
    margin: '4px',
    fontSize: 10,
    textAlign: 'center',
  },
  tableCell3: {
    margin: '4px',
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'bolder'
  },
});

export const Table = (props) => {
  console.log(props)
  return (
    <View style={styles2.table}>
      <View>
        <View style={styles2.tableHeader}>
          <View style={styles2.tableRow}>
            <View style={[styles2.tableCol1, { borderRight: '1px solid white' }]} >
              <Text style={styles2.tableCell1}>Question</Text>
            </View>
            {/* <View style={styles2.tableCol2}>
            <Text style={styles2.tableCell2}>Category</Text>
          </View> */}
            <View style={styles2.tableCol2}>
              <Text style={styles2.tableCell2}>Score</Text>
            </View>
          </View>
        </View>
        <TableRow items={props.feedback.questions} num1={props.num1} num={props.num} />
      </View>
      {
        props.num+1 == props.feedback.questions.length || props.total_questions == props.num ? (
          <View style={styles2.tableRow2}>
            <View style={styles2.tableCol3}>
              <Text style={[styles2.tableCell3, { fontStyle: 'italic' }]}>
                {
                  props.feedback.feedback_type === 1 ? (
                    <>Teaching Effectiveness: {(props.feedback.overall_ffectiveness * 10).toFixed(2)}%</>
                  )
                    : null
                }
                {
                  props.feedback.feedback_type === 2 ? (
                    <>Effectiveness: {(props.feedback.overall_effectiveness * 10).toFixed(2)}%</>
                  )
                    : null
                }
                {
                  props.feedback.feedback_type === 3 ? (
                    <>Counselling Effectiveness: {(props.feedback.overall_effectiveness * 10).toFixed(2)}%</>
                  )
                    : null
                }
                {
                  props.feedback.feedback_type === 4 ? (
                    <>Coordinator Effectiveness: {(props.feedback.overall_effectiveness * 10).toFixed(2)}%</>
                  )
                    : null
                }
              </Text>
            </View>
          </View>
        )
          : ""
      }

    </View>
  );
};

export const TableRow = (props) => {
  const rows = props.items.map((item, index) => {

    if (index >= props.num1 && index <= props.num) {
      return (
        <View style={styles2.tableRow} key={item.category_id}>
          <View style={[styles2.tableCol1, { borderRight: '1px solid #e6e6e6' }]}>
            <Text style={styles2.tableValueCell1}>{item.question}</Text>
          </View>
          {/* <View style={styles2.tableCol2}>
        <Text style={styles2.tableCell2}>{item.category_name}</Text>
      </View> */}
          <View style={styles2.tableCol2}>
            <Text style={styles2.tableCell2}>{(item.Score).toFixed(2)}</Text>
          </View>
        </View>
      )
    }
  });
  return <Fragment>{rows}</Fragment>;
};