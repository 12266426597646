import axios from "axios";
import { setAllDepartments, setAllSemesters, setAllFacPerRevData ,setAllSubjects, setAllSections, setAllFaculty} from "../reducers/facultyPerformanceReview.reducer";
import { getAllDepartments,getAllSemester,getAllSubjects,getAllSections, getAllFaculty } from "../../lib/utils";
import { v4 as uuidv4 } from 'uuid';
import { sortByString } from "../../lib/utils";
import { setFacultyQuestionSubjectWiseScore } from "../reducers/facultyPerformanceReview.reducer";
import { arryToFrequencyGraph } from "../../lib/utils";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const getAccessToken = () => localStorage.getItem("token");

export const getFacultyPerformanceReviews = () => {
  return async (dispatch) => {
    const fetchData = async () => {

      let endpoint = `${BASE_API_URL}/feedback/getFacultySubjectFeedbacks`;
      let options = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        redirect: "follow",
      };
      const response = await axios.get(endpoint, options);

      if (response?.data?.status !== 200) {
        // alert(
        //   `Error : ${response?.data?.message}, Retriving Faculty-Student Allocation Data Failed`
        // );
        throw response;
      }
      // console.log(response);

      const semMappingResponse = response?.data?.data;

      // Transforming Response data to Faculty Section Mapping Data
      let deptSemMappingData = [];
      semMappingResponse.forEach(({ id, name,short_name, sections }) => {
        sections.forEach(
          ({ section_id, section_name, semester_id, subjects }) => {
            subjects.forEach(({feedbacks,feedback_overall_score, ...subject}) => {

              const feedback_overall_score_number_type = parseInt(feedback_overall_score)
              // Adding a unique key for unit
              // truncating the score to 2 decimals
              const feedbacksWithUniqueKey = feedbacks.map(({score,has_feedback, ...elementData})=>{
                const scoreStringToNumber = parseInt(score)
                // console.log(typeof scoreStringToNumber)
                // console.log('scoreStringToNumber', scoreStringToNumber)
                return {
                  ...elementData,
                  has_feedback : has_feedback,
                  score: scoreStringToNumber ? scoreStringToNumber?.toFixed(2) : scoreStringToNumber,
                  // score: score ? score.toFixed(2) : has_feedback ? "NS" : 'NA',
                  unique_unit: uuidv4().slice(0, 8),
                };
              })

              const unique_id = uuidv4().slice(0,8)
              const subjectData = {
                ...subject,
                feedback_overall_score : feedback_overall_score_number_type ? feedback_overall_score_number_type?.toFixed(2) : feedback_overall_score_number_type,
                department_id: id,
                // department_name: name,
                department_name : short_name,
                section_id,
                section_name,
                semester_id,
                unique_id,
                feedbacks : feedbacksWithUniqueKey,
              };
              deptSemMappingData.push(subjectData);
            });
            // return spreadedData
          }
        );
      });
      // console.log(deptSemMappingData);
      dispatch(setAllFacPerRevData(deptSemMappingData));


      if (deptSemMappingData?.length) {
        // ------ Setting Department Data
        let allDepartments = getAllDepartments(deptSemMappingData);

        allDepartments.sort(sortByString); 
        // console.log(allDepartments);
        dispatch(setAllDepartments({ allDepartments }));

        // -------- Setting Subjects Data
        const allSubjects = getAllSubjects(deptSemMappingData);
        // console.log(allSubjects);
        dispatch(setAllSubjects({ allSubjects }));

        // ----- Setting Semesters Data
        const allSemesters = getAllSemester(deptSemMappingData);
        // console.log(allSemesters);
        dispatch(setAllSemesters({ allSemesters }));

        // ----- Setting Sections Data
        const allSections = getAllSections(deptSemMappingData);
        // console.log(allSections);
        dispatch(setAllSections({ allSections }));

        // ----- Setting Faculty Data
        const allFaculty = getAllFaculty(deptSemMappingData);
        // console.log(allFaculty);
        dispatch(setAllFaculty({ allFaculty }));

      }

      // const data = response.data;
      // console.log(data);
      return response;
    };

    try {
      const fetchedData = await fetchData();
      console.log(fetchedData);
      return fetchedData;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
};


export const getFacultyQuestionSubjectWiseScore = (subject_semester_id, college_account_id,section_id) => {
  return async (dispatch) => {
    const fetchData = async () => {

      // let endpoint = `${BASE_API_URL}/feedback/getOverallFacultyUnitWiseScore?subject_semester_id=${subject_semester_id}&college_account_id=${college_account_id}`;
      let endpoint = `${BASE_API_URL}/feedback/getFacultyQuestionSubjectWiseScore?subject_semester_id=${subject_semester_id}&college_account_id=${college_account_id}&section_id=${section_id}`;
      let options = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        redirect: "follow",
      };
      const response = await axios.get(endpoint, options);

      if (response?.data?.status !== 200) {
        // alert(
        //   `Error : ${response?.data?.message}, Retriving Department Data`
        // );
        throw response;
      }
      // console.log(response?.data?.data);

      const {feedbacks,...sectionSubjectFacultyData} =response?.data?.data

      const newFeedbacks = feedbacks.map(({question_wise_score,overall_score,...unitLevelData})=>{
        let newQuestionWiseScore=[]
        // console.log(question_wise_score)

        const {y_axis,questions,individual_student_scores} = question_wise_score;

        // Transforming Individual Scores according to our needs
        const newIndividualScores = individual_student_scores?.map((questionScores)=>{
          return arryToFrequencyGraph(questionScores)
        })

        // console.log(newIndividualScores)

        // console.log(y_axis)
        question_wise_score?.x_axis?.forEach((qid,index)=>{
          newQuestionWiseScore.push({
            question_id : qid,
            average_score : y_axis[index],
            question_name :  questions[index],
            individual_student_scores :  newIndividualScores[index],
          })
        })
        // console.log(newQuestionWiseScore)

        return{
          ...unitLevelData,
          overall_score : parseInt(overall_score),
          question_wise_score: newQuestionWiseScore
        }
      })

      const {faculty,subject,department,section} = sectionSubjectFacultyData;

      const newResponse = {
        feedbacks : newFeedbacks,
        college_account_id : faculty?.id,
        faculty_name : faculty?.faculty_name,
        subject_id : subject?.id,
        subject_semester_id : subject?.subject_semester_id,
        semester_id : subject?.semester_id,
        subject_name : subject?.name,
        department_id : department?.id,
        department_name : department?.name,
        // department_name : department?.full_name,
        section_id : section?.id,
        section_name : section?.name,
      }

      // console.log(newResponse)

      dispatch(setFacultyQuestionSubjectWiseScore({allFacultyQuestionSubjectWiseScore : newResponse}))

      // const data = response.data;
      // console.log(data);
      return response;
    };

    try {
      const fetchedData = await fetchData();
      // console.log(fetchedData);
      return fetchedData;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
};



export const createSubjectFeedbacks = (data) => {

  
  return async (dispatch) => {
    const fetchData = async () => {

      const formData = new FormData();

      //data = [
      //   {
      //     college_account_id: 1156,
      //     subject_semester_id: 1234,
      //     start_time: "2020-01-06 06:16:04",
      //     doe: "2020-01-06 06:16:04",
      //     unit_id: 2022,
      //     section_id: 2123,
      //   }
      // ];
      formData.append("data", JSON.stringify(data));

      let endpoint = `${BASE_API_URL}/feedback/createSubjectFeedbacks`;
      let options = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        redirect: "follow",
        body: formData,

      };
      const response = await axios.post(endpoint,formData, options);

      if (response?.data?.status !== 200) {
        // alert(
        //   `Error : ${response?.data?.message}, Sending Faculty Performance Surveys Failed`
        // );
        throw response;
      }
      // console.log(response);

      // const data = response.data;
      // console.log(data);
      return response;
    };

    try {
      const fetchedData = await fetchData();
      console.log(fetchedData);
      return fetchedData;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
};
