import React from "react";
import Navbar from "../components/navbar/navbar.component";
import CreateWeeklyChallenge from "../components/weeklyChallenge/CreateWeeklyChallenge.component";
const WeeklyChallenge = () => {
    return (
        <div className="weekly-challenge" style={{overflowX:"hidden"}}>
            <Navbar />
            <div className="row" style={{marginTop:'70px',backgroundColor:'#f9f9f9'}}>
                <CreateWeeklyChallenge />
            </div>
        </div>
    );
}

export default WeeklyChallenge;