import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// We provide onChange attribute to get the changes from the funcion, dropDownValue 
// and setDropDown are mandatory as they are to be accessible to the patent component and mutable by parent component 

// Disabled, nullIsAll is optional
export default function DropDown({label,data,onChange,nullIsAll,dropDownValue,setDropDownValue, disabled, ...props}) {
  // const [dropDownValue, setDropDownValue] = React.useState('');

  const handleChange = (event) => {
    setDropDownValue(event.target.value);
    onChange(event)
  };
  
  // console.log(disabled)
  const isDisabled = disabled ? true : false;
  
  React.useEffect(()=>{
    // console.log(data)
    if(isDisabled && data){
      setDropDownValue(data[0]?.value)
    }
  
  },[isDisabled,data])


  return (
    
    <FormControl sx={{ minWidth: 140, width: props?.width }} size={props?.size} disabled={isDisabled}>
      <InputLabel id="dropdown-small">{label}</InputLabel>
      <Select
        {...props}
        labelId="dropdown-small"
        id="dropdown-small"
        value={dropDownValue ? dropDownValue : ''}
        label={label}
        onChange={handleChange}
        disabled={isDisabled}
      >
        <MenuItem value="">
          {nullIsAll ? "All" : <em>None</em>}
        </MenuItem>
        {data && data?.map((input,index)=>(
          <MenuItem key={index} value={input?.value}>{input?.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}