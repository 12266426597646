import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import TextField from '@mui/material/TextField';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import MaterialButton from './MaterialButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


// Sample of How this Component used in other components , states are made available outside
{/* <ModalwithButtons open={openAddFaculty} onClose={handleModalClose} submitLabel={'Add Faculty'} onSubmit={handleSubmit}>{add children components}</ModalwithButtons> */}


ModalwithButtons.defaultProps ={
  open : false,
  submitLabel : 'Apply',
  openFullScreen : false,
  title : 'Modal',
  errorMessage : null,
  submitButtonType : 'error',
}

export default function ModalwithButtons({openFullScreen,open, onClose, children, onSubmit, submitLabel,title, errorMessage, submitButtonType}) {
    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
//   const [open, setOpen] = React.useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

  const handleClose = () => {
    // setOpen(false);
    onClose();
  };



  return (
    <div>
      <Dialog
        fullScreen={openFullScreen}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        // maxWidth={"xl"}
        PaperProps={{ sx: { maxWidth: "90vw", maxHeight: "90vh",minWidth : '50vw' } }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          /> */}
          {children}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {submitLabel && onSubmit && <Button onClick={onSubmit}>{submitLabel}</Button>}
        </DialogActions> */}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <DialogActions>
          <Button
            size="small"
            sx={{
              borderRadius: "15px",
              backgroundColor: "grey",
              mr : 1,
              "&:hover": {
                backgroundColor: "#505050",
              },
            }}
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>

          {submitLabel && onSubmit && submitButtonType === "primary" ? (
            <MaterialButton
              label={submitLabel}
              onClick={onSubmit}
              sx={{mr : 1}}
            />
          ) : (
            <Button
              size="small"
              sx={{ borderRadius: "15px", "&:hover": {
                backgroundColor: "#52C3FF",
              }, }}
              color="error"
              variant="contained"
              onClick={onSubmit}
            >
              {submitLabel}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
