import * as React from "react";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from "react-redux";
// import { Link } from "react-router-dom";
// import { Link as RouterLink } from 'react-router-dom';
// import {Link as MaterialLink} from '@mui/material';
import ModalwithButtons from "../common/ModalwithButtons";
import DeleteFacultyCard from "./DeleteFacultyCard";
import { deactivateFacultySubjectMapping, getFacultySubjectMappings } from "../../store/actions/facultySectionMapping.actions";
import { setTableIsLoading } from "../../store/reducers/facultySectionMapping.reducer";
import { setNotification } from "../../store/reducers/common.reducer";

// Sample Array
// const headArraySubject = [
//   {
//     id: 'department_name',
//     label: 'Department',
//   },
//   {
//     id: 'semester_id',
//     label: 'Semester',
//   },
// ];


export default function TableRowActions({
  setRowMenuOpen,
  rowMenuOpen,
  rowActionsData,
  setRowActionsData,
  handleAddFaculty
}) {

  const dispatch = useDispatch()
  const [openDeleteFaculty, setOpenDeleteFaculty] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const open = Boolean(rowMenuOpen);
  // console.log(rowActionsData)

  const resetRowActions = () =>{
    setRowMenuOpen(null);
    setRowActionsData(null);
  }
  

  // const handleAddFaculty = () => {
  //   navigate(`/facultySectionMapping/addFaculty/${rowActionsData?.unique_id}`,{ state: rowActionsData });
  //   resetRowActions();
  // };

  const handleDeleteFaculty = () => {
    setOpenDeleteFaculty(true);
    // setRowMenuOpen(null);
    // setRowActionsData(null);
  };

  const handleClose = () => {
    resetRowActions();
  };

  const handleDeleteButton = async() => {
    console.log('delete button clicked')
    // setRowMenuOpen(null);
    // setRowActionsData(null);
    const response = await dispatch(deactivateFacultySubjectMapping(rowActionsData?.college_account_subject_college_department_section));
    // console.log(response)

    if (response?.data?.status !== 200) {
      setErrorMessage(`Error : ${response?.data?.message}, Could not delete Faculty from the Subject-Section. Please try again.`)
      return
    }
      // console.log('Successfully deleted faculty from section')
      // Giving a notification
      dispatch( setNotification({ 
        openNotification: true,
        notificationMessage: `Successfully Deleted Faculty`,
        notificationType: "success",
      }));

      setErrorMessage(null);
      setOpenDeleteFaculty(false);
      resetRowActions();

      // Getting the latest Table Data
      dispatch(setTableIsLoading(true));
      await dispatch(getFacultySubjectMappings());
      dispatch(setTableIsLoading(false));
  };

  const handleModalClose = () => {
    setOpenDeleteFaculty(false)
    resetRowActions();
  };

  return (
    <React.Fragment>
      <Paper>
        <Menu
          id="basic-menu"
          anchorEl={rowMenuOpen}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuList
            sx={{
              py: "0px",
            }}
            dense
          >
            <MenuItem onClick={()=>handleAddFaculty(rowActionsData)}>
              {" "}
              <ListItemIcon>
                <AddIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                  Add Faculty
              </ListItemText>
            </MenuItem>
            {rowActionsData?.college_account_id && <MenuItem onClick={handleDeleteFaculty}>
              {" "}
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete Faculty</ListItemText>
            </MenuItem>}
            {/* <MenuItem onClick={handleDownloadExcel}>
              {" "}
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit Name</ListItemText>
            </MenuItem> */}
            {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
            {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
          </MenuList>
        </Menu>
      </Paper>
      <ModalwithButtons
        open={openDeleteFaculty}
        onClose={handleModalClose}
        submitLabel={"Delete Faculty"}
        onSubmit={handleDeleteButton}
        title={'Are you sure you want to delete?'}
        errorMessage={errorMessage}
      >
        <DeleteFacultyCard selectedSectionDetails={rowActionsData}/>
      </ModalwithButtons>
    </React.Fragment>
  );
}
