import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { BiRefresh } from "react-icons/bi";
import * as Ably from 'ably/promises';
import { getLeaderBoard, getWeeklyChallengeData, getWeeklyChallengeDetails, getEnrolledMembers, getMyTeam } from "../../api/weeklyChallengeAPIs";
import "./challengeLobby.styles.css";
import LeaderBoard from "../leaderBoard/leaderBoard.component";
import Countdowntimer from "../countdownTimer/Countdowntimer.component";
import { current_user_id } from "../../api/weeklyChallengeAPIs";
import { openNewAblyConnection } from "../../store/actions/joinchallengeactions";

const ChallengeLobby = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const realtimePChannel = useSelector(state => state.joinChallenge.realtimePChannel);
    console.log(realtimePChannel);
    //dispatch({ type: 'openAblyCon', challenge_id: location.state.challenge_id, pChannelId: location.state.challengeDetails.participation_channel_id, vChannelId: location.state.challengeDetails.viewer_channel_id });
    const [challengeDetails, setChallengeDetails] = useState(location.state.challengeDetails);
    const [weeklyChallengeDetails, setWeeklyChallengeDetails] = useState(null);
    const [leaderBoardData, setLeaderBoardData] = useState([]);
    const [enableOpenChallengeBtn, setEnableOpenChallengeBtn] = useState(true);
    const [enrollmentTimeUpLabel, setEnrollmentTimeUpLabel] = useState(false);
    const [startChallengeTimeUpLabel, setStartChallengeTimeUpLabel] = useState(false);
    const [startChallengeLabel, setStartChallengeLabel] = useState(false);
    const [allChallengeParticipants, setAllChallengeParticipants] = useState([]);
    const [showChallengeParticipantsModal, setShowChallengeParticipantsModal] = useState(false);
    const [leaderBoardFlag, setLeaderBoardFlag] = useState(false);
    const channel = useSelector(state => state.joinChallenge.participantChannel);
    useEffect(() => {
        async function openNewConnection() {
            const openAblyCon = await dispatch(openNewAblyConnection({ challenge_id: location.state.challenge_id, pChannelId: location.state.challengeDetails.participation_channel_id, vChannelId: location.state.challengeDetails.viewer_channel_id }));
        };
        if (weeklyChallengeDetails === null) {
            getChallengeDetails();
        }
        if (challengeDetails !== null && challengeDetails.is_completed === 1 && leaderBoardData.length === 0) {
            getLeaderBoardData();
        }
        if (challengeDetails) {
            const enable_time = (new Date(challengeDetails.start_time).getTime() - (parseInt(challengeDetails.start_button_timer) * 1000));
            if (new Date().getTime() > enable_time) {
                setEnableOpenChallengeBtn(false);
                setEnrollmentTimeUpLabel(false);
                setStartChallengeTimeUpLabel(false);
                setStartChallengeLabel(true);
            }

        }
        try {
            openNewConnection();
        } catch (error) {
            return error;
        }
    }, [dispatch]);


    const getChallengeDetails = async () => {
        const challenge_details = await getWeeklyChallengeDetails(location.state.challenge_id);
        setWeeklyChallengeDetails(challenge_details.data);
        const enable_time = (new Date(challengeDetails.start_time).getTime() - (parseInt(challengeDetails.start_button_timer) * 1000));

        if ((new Date().getTime() > new Date(challengeDetails.enrollment_end_time).getTime()) && (new Date().getTime() <= enable_time)) {
            setEnrollmentTimeUpLabel(true);
            setStartChallengeTimeUpLabel(false);
            setStartChallengeLabel(false);
        } else {
            console.log("2");
            if (new Date().getTime() > enable_time) {
                setEnrollmentTimeUpLabel(false);
                setStartChallengeTimeUpLabel(false);
                setStartChallengeLabel(true);
            } else {
                setEnrollmentTimeUpLabel(false);
                setStartChallengeTimeUpLabel(true);
                setStartChallengeLabel(false);
            }
        }
    }

    const handleChallengeParticipantsModalShow = async () => {
        setShowChallengeParticipantsModal(true);
        const enrollers = await getEnrolledMembers(location.state.challenge_id);
        let enrollers_list = [];
        for (let i = 0; i < enrollers.data.length; i++) {
            let participant_type, participant_id, participant_name, department_name, roll_number, section_name, semester_id;
            if (enrollers.data[i].college_account_id != '') {
                participant_type = "Facuty";
                participant_id = enrollers.data[i].college_account_id;
                participant_name = enrollers.data[i].faculty_first_name + " " + enrollers.data[i].faculty_last_name;
                department_name = enrollers.data[i].department_name;
                roll_number = "-";
                section_name = "-";
                semester_id = "-";
            } else {
                participant_type = "Student";
                participant_id = enrollers.data[i].student_id;
                participant_name = enrollers.data[i].student_name;
                department_name = enrollers.data[i].department_name;
                roll_number = enrollers.data[i].student_roll_number;
                section_name = enrollers.data[i].student_section_name;
                semester_id = enrollers.data[i].student_semester_id;
            }
            const status = "---";
            enrollers_list.push({
                "participant_type": participant_type,
                "participant_id": participant_id,
                "participant_name": participant_name,
                "department_name": department_name,
                "roll_number": roll_number,
                "section_name": section_name,
                "semester_id": semester_id,
                "status": status,
            });
        }
        //console.log(enrollers_list);
        setAllChallengeParticipants(enrollers_list);
    };
    //console.log(allChallengeParticipants);
    const handleChallengeParticipantsModalClose = () => {
        setShowChallengeParticipantsModal(false);
    }

    //getting leaderboard data
    const getLeaderBoardData = async () => {
        const leaderboard = await getLeaderBoard(location.state.challenge_id, "");
        console.log(leaderboard.data);
        setLeaderBoardData(leaderboard.data);
        if (leaderboard.message === "Successfully fetched the data") {
            setLeaderBoardFlag(true);
        }
    };

    const enbaleOpenChallengeBtn = () => {
        setEnableOpenChallengeBtn(false);
        setEnrollmentTimeUpLabel(false);
        setStartChallengeTimeUpLabel(false);
        setStartChallengeLabel(true);
    };

    const handleRefreshParticipants = async () => {
        const enrollers = await getEnrolledMembers(location.state.challenge_id);
        let enrollers_list = [];
        for (let i = 0; i < enrollers.data.length; i++) {
            let participant_type, participant_id, participant_name, department_name, roll_number, section_name, semester_id;
            if (enrollers.data[i].college_account_id != '') {
                participant_type = "Facuty";
                participant_id = enrollers.data[i].college_account_id;
                participant_name = enrollers.data[i].faculty_first_name + " " + enrollers.data[i].faculty_last_name;
                department_name = enrollers.data[i].department_name;
                roll_number = "-";
                section_name = "-";
                semester_id = "-";
            } else {
                participant_type = "Student";
                participant_id = enrollers.data[i].student_id;
                participant_name = enrollers.data[i].student_name;
                department_name = enrollers.data[i].department_name;
                roll_number = enrollers.data[i].student_roll_number;
                section_name = enrollers.data[i].student_section_name;
                semester_id = enrollers.data[i].student_semester_id;
            }
            const status = "---";
            enrollers_list.push({
                "participant_type": participant_type,
                "participant_id": participant_id,
                "participant_name": participant_name,
                "department_name": department_name,
                "roll_number": roll_number,
                "section_name": section_name,
                "semester_id": semester_id,
                "status": status,
            });
        }
        //console.log(enrollers_list);
        setAllChallengeParticipants(enrollers_list);
    };

    const enableChallengeTimer = () => {
        setEnrollmentTimeUpLabel(true);
        setStartChallengeTimeUpLabel(false);
    };

    //starting the Arena
    const handleStartChallenge = () => {
        //channel.presence.enter();
        navigate('/startChallenge', { state: { challenge_id: location.state.challenge_id } });
    };

    console.log(challengeDetails);

    if (challengeDetails != null && weeklyChallengeDetails != null) {
        const challenge_start_hours = new Date(weeklyChallengeDetails.enrollment_end_time).getHours();
        const challenge_start_mins = new Date(weeklyChallengeDetails.enrollment_end_time).getMinutes();
        const challenge_start_year = new Date(weeklyChallengeDetails.enrollment_end_time).getFullYear();
        const challenge_start_month = new Date(weeklyChallengeDetails.enrollment_end_time).getMonth();
        const challenge_start_day = new Date(weeklyChallengeDetails.enrollment_end_time).getDate();

        const enrollment_end_timer = ((new Date(weeklyChallengeDetails.enrollment_end_time).getTime() - new Date().getTime()) / 1000) / 60;
        const challenge_start_timer = ((new Date(challengeDetails.start_time).getTime() - new Date().getTime()) / 1000) / 60;
        let am_pm = "";
        if (challenge_start_hours > 12) {
            am_pm = "PM";
        } else {
            am_pm = "AM";
        }
        const enroll_end_d = challenge_start_day + "/" + (challenge_start_month + 1) + "/" + challenge_start_year;
        const enroll_end_t = challenge_start_hours + ":" + challenge_start_mins + " " + am_pm;
        return (
            <div className="row w-100">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className={challengeDetails.is_completed === 1 ? "start-challenge-lobby-div leaderboard-background" : "start-challenge-lobby-div"}>
                        <div className="row">
                            <div className="col-sm-4 colmd-4 col-lg-4">
                            </div>
                            <div className="col-sm-8 colmd-8 col-lg-8">
                                {
                                    challengeDetails.is_completed === 0
                                        ? (<button className="btn btn-primary view-participants-btn" onClick={handleChallengeParticipantsModalShow} id="view-participants-btn">View Participants</button>)
                                        : ""
                                }
                            </div>

                            <Modal show={showChallengeParticipantsModal} onHide={handleChallengeParticipantsModalClose} scrollable dialogClassName="challengeParticipantsmodalWidth">
                                <Modal.Header >
                                    <div className="enrollers-head-div" style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>Participants enrolled : {allChallengeParticipants.length}</div>
                                        <div className="participants-refresh" onClick={handleRefreshParticipants}><BiRefresh /></div>
                                    </div>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        {
                                            allChallengeParticipants.length != 0
                                                ? (
                                                    <table className="table table-bordered table-curved" id="general-coordinators-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Participant Type</th>
                                                                <th scope="col">Participant Name</th>
                                                                <th scope="col">Department Name</th>
                                                                <th scope="col">Roll number</th>
                                                                <th scope="col">Section</th>
                                                                <th scope="col">Semester</th>
                                                                <th scope="col">Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                allChallengeParticipants.map(participant => (
                                                                    <tr>
                                                                        <td scope="row">{participant.participant_type}</td>
                                                                        <td scope="row">{participant.participant_name}</td>
                                                                        <td scope="row">{participant.department_name}</td>
                                                                        <td scope="row">{participant.roll_number}</td>
                                                                        <td scope="row">{participant.section_name}</td>
                                                                        <td scope="row">{participant.semester_id}</td>
                                                                        <td scope="row" style={{ color: "green" }}>-</td>
                                                                        {/*<td scope="row" style={{ color: 'green' }}>{participant.status}</td>*/}
                                                                    </tr>)
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                )
                                                : (<span className="d-flex justify-content-center" id="no-coordinators-msg-label">No Participants has enrolled yet!</span>)
                                        }

                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleChallengeParticipantsModalClose} id="modalCloseBtn" style={{ width: '15%' }}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>


                        <h2 className="challenge-title">{challengeDetails.title}</h2>
                        <p className="challenge-desc">{challengeDetails.description}</p>
                        {
                            new Date(challengeDetails.start_time).getTime() < new Date().getTime() || challengeDetails.is_completed === 1
                                ? challengeDetails.is_completed === 1
                                    ? leaderBoardFlag === true
                                        ? leaderBoardData.length !== 0
                                            ? (
                                                <div style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
                                                    <h2 style={{ textAlign: 'center' }}>LeaderBoard</h2>
                                                    {
                                                        weeklyChallengeDetails.is_random === 0
                                                            ? (<LeaderBoard data={leaderBoardData} current_question_id="" question_index="" myTeam_id="" user_type="admin" type="completed" challenge_details={weeklyChallengeDetails} />)
                                                            : (<LeaderBoard data={leaderBoardData} current_question_id="" question_index="" myTeam_id="" user_type="admin" type="completed" challenge_details={weeklyChallengeDetails} />)
                                                    }
                                                </div>
                                            )
                                            : (
                                                <div><h2 style={{ textAlign: 'center', color: 'red' }}>LeaderBoard Data is not available!</h2></div>
                                            )
                                        : ""
                                    : (
                                        <div>
                                            <div className="time-div">
                                                <h4 className="time-label">Starts At</h4>
                                                <h2 className="org-time" style={{ color: 'balck' }}>{challengeDetails.start_time}</h2>
                                            </div>
                                            {
                                                enrollmentTimeUpLabel && (
                                                    <>
                                                        <p id="challenge-alert-label"><strong>Enrollment has closed at {enroll_end_t} on {enroll_end_d}.</strong></p>
                                                        <p id="challenge-alert-label"><strong>You can start the Arena in  <Countdowntimer time={(challenge_start_timer - (parseInt(challengeDetails.start_button_timer) / 60)) * 60} timercompleted={enbaleOpenChallengeBtn} />.</strong></p>
                                                    </>
                                                )
                                            }
                                            {
                                                startChallengeTimeUpLabel && (
                                                    <p id="challenge-alert-label"><strong>Enrollment is going on and will be closed in <Countdowntimer time={enrollment_end_timer * 60} timercompleted={enableChallengeTimer} />.</strong></p>
                                                )
                                            }
                                            {
                                                startChallengeLabel && (
                                                    <p id="challenge-alert-label"><strong>You can start the Arena now.</strong></p>
                                                )
                                            }

                                            <button className="btn btn-default" id="open-challenge-btn" disabled={enableOpenChallengeBtn} onClick={handleStartChallenge}>Open Arena</button>
                                        </div>
                                    )
                                : (
                                    <div>
                                        <div className="time-div">
                                            <h4 className="time-label">Starts At</h4>
                                            <h2 className="org-time" style={{ color: 'balck' }}>{challengeDetails.start_time}</h2>
                                        </div>
                                        {
                                            enrollmentTimeUpLabel && (
                                                <>
                                                    <p id="challenge-alert-label"><strong>Enrollment has closed at {enroll_end_t} on {enroll_end_d}.</strong></p>
                                                    <p id="challenge-alert-label"><strong>You can start the Arena in  <Countdowntimer time={(challenge_start_timer - (parseInt(challengeDetails.start_button_timer) / 60)) * 60} timercompleted={enbaleOpenChallengeBtn} />.</strong></p>
                                                </>
                                            )
                                        }
                                        {
                                            startChallengeTimeUpLabel && (
                                                <p id="challenge-alert-label"><strong>Enrollment is going on and will be closed in <Countdowntimer time={enrollment_end_timer * 60} timercompleted={enableChallengeTimer} />.</strong></p>
                                            )
                                        }
                                        {
                                            startChallengeLabel && (
                                                <p id="challenge-alert-label"><strong>You can start the Arena now.</strong></p>
                                            )
                                        }

                                        <button className="btn btn-default" id="open-challenge-btn" disabled={enableOpenChallengeBtn} onClick={handleStartChallenge}>Open Arena</button>
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div >
        );
    }
}

export default ChallengeLobby;