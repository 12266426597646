import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../../navbar/navbar.component";
import LayoutwithSideNav from "../../../common/LayoutwithSideNav";
import { Box, Typography, Divider, Button, Grid, TextField } from "@mui/material";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import AddIcon from '@mui/icons-material/Add';
import { allDepartments, allSemesters, getSections, getGeneralFeedbackCategories } from "../../../../api/facultyfeedbackapis";
import MultipleSelectCheckmarks from "../../../common/MultipleSelectCheckmarks";
import { Oval } from 'react-loader-spinner';
import MaterialCheckBox from "../../../common/MaterialCheckBox";
import { useTheme } from "@mui/material/styles";

const FeedbackCategories = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const [departments, setAllDepartments] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState(location.state?.selectedDepartments ? location.state.selectedDepartments : []);
    const [isShowLoader, setIsShowLoader] = useState(false);
    const [deptsAndSems, setdeptsAndSems] = useState([]);
    const [selectedSemesters, setSelectedSemesters] = useState(location.state?.selectedSemesters ? location.state.selectedSemesters : []);
    const [Departments, setDepartments] = useState([]);
    const [flagNext, setFlagNext] = useState(false);
    const [allSections, setAllSections] = useState([]);
    const [createsections, setcreatesections] = useState(location.state?.selectedSections ? location.state.selectedSections : []);
    const [feedbackCategories, setFeedbackCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState(location.state?.selectedCategories ? location.state.selectedCategories.map((obj) => obj.value) : []);
    const [isSelectAllDeptSections, setIsSelectAllDeptSections] = useState(false);
    const [isSelectAllDeptWiseSections, setIsSelectAllDeptWiseSections] = useState(false);
    const [allSelectedDeptWiseSections, setAllSelectedDeptWiseSections] = useState([]);

    const styles = {
        button: {
            textTransform: 'none', // Disable capitalization
            fontSize: '1.1rem',
            maxWidth: "150px",
            float: "right"
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            marginTop: "15px",
            fontSize: "1.15rem",
            marginBottom: "7.5px"
        },
        redText: {
            color: 'red',
            position: 'relative',
            top: '-0.1em',
        },
        dept_name: {
            fontWeight: 600,
            marginTop: "20px"
        },
        divider1: {
            width: "100%",
            height: "1px",
            textAlign: "start",
            ml: 0,
            mt: 1,
            backgroundColor: "#e6e6e6",
            opacity: "100%",
        },
        divider2: {
            width: "100%",
            height: "0.2px",
            textAlign: "start",
            ml: 0,
            mt: 2,
            backgroundColor: "#e6e6e6",
            opacity: "50%",
        },
        dept_name_label: {
            width: "100%",
            backgroundColor: "#FFDFD6",
            borderTopRightRadius: "15px",
            borderTopLeftRadius: "15px",
            padding: "8px",
            fontWeight: 600,
        },
        section_scrollable_div: {
            maxHeight: "200px",
            minHeight: "120px",
            overflowY: "auto",
            padding: "5px",
        }
    };

    // const useStyles = makeStyles((theme) => ({
    //     thickDivider: {
    //         borderBottom: `2px solid ${theme.palette.divider}`,
    //         margin: theme.spacing(2, 0),
    //     },
    // }));
    // const classes = useStyles();


    useEffect(() => {
        if (departments.length == 0) {
            handleGetAllDepartments();
        }
        if (feedbackCategories.length == 0) {
            handleGetFeedbackCategories();
        }
    }, []);

    const handleGetAllDepartments = async () => {
        const department_list = [{ value: "select-all", name: "Select All" }];
        const depts = await allDepartments();
        for (let i = 0; i < depts.data.length; i++) {
            const id = depts.data[i].university_degree_department_id;
            const key = depts.data[i].name;
            const cudd_id = depts.data[i].college_university_degree_department_id;
            department_list.push({ value: id, name: key, cudd_id: cudd_id });
        }
        setAllDepartments(department_list);
    };
    console.log("dept -> ", departments);

    const handleGetFeedbackCategories = async () => {
        const category_list = [{ value: "select-all", name: "Select All" }, { value: "training_questions", name: "Training Questions" }];
        const cats = await getGeneralFeedbackCategories();
        for (let i = 0; i < cats.data.length; i++) {
            const id = cats.data[i].id;
            const key = cats.data[i].name;
            category_list.push({ value: id, name: key });
        }
        setFeedbackCategories(category_list);
    };

    const handleSelectDepartment = async (e) => {
        setIsShowLoader(true);
        setAllSections([]);
        setcreatesections([]);
        console.log(e);
        let flag = true;
        if (e.find((option) => option === "select-all")) {
            const filteredValues = departments.filter((option) => option.value !== "select-all").map((option) => option.value);
            setSelectedDepartments(filteredValues);
            flag = false;
        } else {
            setSelectedDepartments(e);
        }

        let depList = [];
        let existingDeptsAndSems = deptsAndSems;
        let existingSelectedSems = selectedSemesters;
        let dept_ids_list = [];

        if (flag) {
            for (let i = 0; i < e.length; i++) {
                console.log("-> ", e[i]);
                const sems = await allSemesters(e[i]);
                let semList = [];
                for (let j = 0; j < sems.data.length; j++) {
                    const key = sems.data[j];
                    semList.push({ value: key, label: key });
                }
                semList = semList.sort((a, b) => a.value > b.value ? 1 : -1);
                let dept = departments.find((department) => department.value === e[i]);
                console.log("dept ->-> ",dept);
                depList.push({ "department_id": parseInt(e[i]), "department_name": dept.name, "department_semesters": semList, "college_university_degree_department_id": dept.cudd_id });
                dept_ids_list.push(parseInt(e[i]));
            }

            //deleting from deptsAndSems
            for (let j = 0; j < deptsAndSems.length; j++) {
                if (!dept_ids_list.includes(parseInt(deptsAndSems[j].university_degree_department_id))) {
                    existingDeptsAndSems = existingDeptsAndSems.filter(depSem => depSem.university_degree_department_id !== deptsAndSems[j].university_degree_department_id);
                    for (let k = 0; k < deptsAndSems[j].semesters.length; k++) {
                        existingSelectedSems = existingSelectedSems.filter(semid => parseInt(semid) != parseInt(deptsAndSems[j].university_degree_department_id + "" + parseInt(deptsAndSems[j].semesters[k])));
                    }
                }
            }
        } else {
            e = departments;
            for (let i = 0; i < e.length; i++) {
                if (e[i].value !== "select-all") {
                    const sems = await allSemesters(e[i].value);
                    let semList = [];
                    for (let j = 0; j < sems.data.length; j++) {
                        const key = sems.data[j];
                        semList.push({ value: key, label: key });
                    }
                    semList = semList.sort((a, b) => a.value > b.value ? 1 : -1);
                    depList.push({ "department_id": parseInt(e[i].value), "department_name": e[i].name, "department_semesters": semList,"college_university_degree_department_id": e[i].cudd_id });
                    dept_ids_list.push(parseInt(e[i]));
                }
            }

            //deleting from deptsAndSems
            for (let j = 0; j < deptsAndSems.length; j++) {
                if (!dept_ids_list.includes(parseInt(deptsAndSems[j].university_degree_department_id))) {
                    existingDeptsAndSems = existingDeptsAndSems.filter(depSem => depSem.university_degree_department_id !== deptsAndSems[j].university_degree_department_id);
                    for (let k = 0; k < deptsAndSems[j].semesters.length; k++) {
                        existingSelectedSems = existingSelectedSems.filter(semid => parseInt(semid) != parseInt(deptsAndSems[j].university_degree_department_id + "" + parseInt(deptsAndSems[j].semesters[k])));
                    }
                }
            }
        }
        setTimeout(() => {
            setdeptsAndSems(existingDeptsAndSems);
            setSelectedSemesters(existingSelectedSems);
            setDepartments(depList);
            setIsShowLoader(false);
            setFlagNext(false);
        }, 2000);
    }

    const selectSem = (e, department_id, cudd_id) => {
        console.log(e.target.checked);
        console.log(department_id);
        console.log(e.target);
        console.log("-> ",cudd_id);
        if (e.target.checked === true) {
            let existingData = deptsAndSems;
            let i = 0;
            for (i = 0; i < deptsAndSems.length; i++) {
                if (deptsAndSems[i].university_degree_department_id === department_id) {
                    existingData[i].semesters.push(parseInt(e.target.value));
                    break;
                }
            }
            if (i === deptsAndSems.length) {
                existingData.push({ "university_degree_department_id": department_id, "semesters": [parseInt(e.target.value)], "college_university_degree_department_id": cudd_id });
            }
            setdeptsAndSems(existingData);
            setSelectedSemesters(prevData => [...prevData, parseInt(department_id + "" + e.target.value)]);
        } else {
            let existingData = deptsAndSems;
            let i = 0;
            for (i = 0; i < deptsAndSems.length; i++) {
                if (deptsAndSems[i].university_degree_department_id === department_id) {
                    if (deptsAndSems[i].semesters.length > 1) {
                        let existing_sems = deptsAndSems[i].semesters;
                        existing_sems = existing_sems.filter(item => item !== parseInt(e.target.value));
                        existingData[i].semesters = existing_sems;
                    } else {
                        existingData = existingData.filter(item => parseInt(item.university_degree_department_id) !== parseInt(department_id));
                    }
                }
            }
            setdeptsAndSems(existingData);
            let existingSelectedSemData = selectedSemesters;
            existingSelectedSemData = existingSelectedSemData.filter(item => item != parseInt(department_id + "" + e.target.value));
            setSelectedSemesters(existingSelectedSemData);
        }

        setFlagNext(true);
        setAllSections([]);
    }

    const getAllSections = async (e) => {
        const sectionsresponse = await getSections(JSON.stringify(deptsAndSems));
        setAllSections(sectionsresponse.data);
        setFlagNext(false);
    }

    const handleSelectSections = (e, section_id) => {
        setcreatesections((prevSections) => {
            let selected_sections = [...prevSections];
            if (e.target.checked === true) {
                selected_sections.push(parseInt(section_id));
            } else {
                selected_sections = selected_sections.filter(id => id !== parseInt(section_id));
                setIsSelectAllDeptSections(false);
            }
            return selected_sections;
        });
    };

    const handleSelectAllDeptSections = (e) => {
        console.log(allSections);
        let sec_list = [];
        let dept_selected_list = [];
        if (e.target.checked) {
            for (let i = 0; i < allSections.length; i++) {
                sec_list.push(parseInt(allSections[i].id));
                dept_selected_list.push(parseInt(allSections[i].university_degree_department_id));
            }
            setcreatesections(sec_list);
            setIsSelectAllDeptSections(true);
            setAllSelectedDeptWiseSections(dept_selected_list);
        } else {
            setcreatesections(sec_list);
            setIsSelectAllDeptSections(false);
            setAllSelectedDeptWiseSections(dept_selected_list);
        }
    }

    const handleSelectAllDeptWiseSections = (e, department_id) => {
        let sec_list = createsections;
        if (e.target.checked) {
            for (let i = 0; i < allSections.length; i++) {
                if (allSections[i].university_degree_department_id === department_id) {
                    sec_list.push(parseInt(allSections[i].id));
                }
            }
            setcreatesections(sec_list);
            setAllSelectedDeptWiseSections([...allSelectedDeptWiseSections, parseInt(department_id)]);
        } else {
            let existingSections = createsections;
            for (let i = 0; i < allSections.length; i++) {
                if (allSections[i].university_degree_department_id === department_id) {
                    existingSections = existingSections.filter((item) => item !== allSections[i].id);
                }

            }
            setcreatesections(existingSections);
            setIsSelectAllDeptSections(false);
            setAllSelectedDeptWiseSections(allSelectedDeptWiseSections.filter((ev) => (ev !== parseInt(department_id))));
        }
    }

    const handleSelectCategories = async (e) => {
        if (e.find((option) => option === "select-all")) {
            const filteredValues = feedbackCategories.filter((option) => option.value !== "select-all").map((option) => option.value);
            setSelectedCategories(filteredValues);
        } else {
            setSelectedCategories(e);
        }
    }

    const ChangeComponent = () => {
        console.log("selectedDepartments -> ", selectedDepartments);
        console.log("selectedSemesters -> ", selectedSemesters);
        console.log("createsections -> ", createsections);
        console.log("selectedCategories -> ", selectedCategories);
        const filteredArray = feedbackCategories.filter((obj) => selectedCategories.includes(obj.value));
        navigate('/feedbacks/feedbackQuestions', {
            state: {
                title: location.state.title,
                Description: location.state.Description,
                startTime: location.state.startTime,
                endTime: location.state.endTime,
                gradingScale: location.state.gradingScale,
                ratingScale: location.state.ratingScale,
                selectedDepartments: selectedDepartments,
                selectedSemesters: selectedSemesters,
                selectedSections: createsections,
                selectedCategories: filteredArray
            }
        })
    };

    return (
        <Box>
            <Navbar />
            <LayoutwithSideNav>
                {/* page header part */}
                <Box sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={8} md={8} lg={8}>
                            <Typography variant="h4" sx={{ wordBreak: "break-all", fontFamily: "Source Sans Pro" }}>
                                {location.state.title}
                            </Typography>
                            <Divider sx={{
                                width: "5rem",
                                height: "4px",
                                textAlign: "start",
                                ml: 0,
                                mt: 1,
                                mb: "15px",
                                background: "linear-gradient(131deg, #7175E0 0%, #4D50CB 33%, #E25BC8 100%)",
                                borderRadius: "5px",
                                opacity: "100%",
                            }} />
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <Button variant="contained" id="next" 
                            sx={{...styles.button, backgroundColor: selectedCategories.length > 0 ? 'grey' : ""}} 
                            onClick={ChangeComponent}
                            disabled={selectedCategories.length > 0 ? false : true}
                            >Next &nbsp;
                                <ArrowForwardOutlinedIcon sx={{ fontSize: "1.25rem" }} /></Button>
                        </Grid>
                    </Grid>
                </Box>

                {/* After header  */}
                <Box sx={{ p: 2 }}>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="body1" sx={styles.label}>
                                Select Departments and Semesters
                                <Typography variant="body1" component="sup" sx={styles.redText}>
                                    *
                                </Typography>
                            </Typography>
                            <div style={{width:"100%" ,maxWidth:"1250px"}}>
                                <MultipleSelectCheckmarks
                                    data={departments}
                                    onChange={handleSelectDepartment}
                                    label={"Departments"}
                                    selectedValues={selectedDepartments}
                                    setSelectedValues={setSelectedDepartments}
                                    size="medium"
                                    maxwidth="100%"
                                    width="100%"
                                    sx={{ width: "100%", maxWidth: "100%", overflowX: "hidden" }}
                                />
                            </div>

                            <Oval
                                height={80}
                                width={80}
                                color="#EB5C6F"
                                wrapperStyle={{ marginLeft: "40%", marginTop: "75px" }}
                                wrapperClass=""
                                visible={isShowLoader}
                                ariaLabel='oval-loading'
                                secondaryColor="#4fa94d"
                                strokeWidth={3}
                                strokeWidthSecondary={2}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                            {
                                Departments.length !== 0 && !isShowLoader
                                    ? (
                                        <Box>
                                            <Grid container sx={{ mt: 4 }}>
                                                <Grid item sm={4} md={4} lg={4}>
                                                    <Typography variant="body1" sx={styles.label}>Department Name</Typography>
                                                </Grid>
                                                <Grid item sm={8} md={8} lg={8}>
                                                    <Typography variant="body1" sx={styles.label}>Select Semesters</Typography>
                                                </Grid>
                                            </Grid>
                                            <Divider sx={styles.divider1} />
                                        </Box>
                                    )
                                    : null
                            }
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                            {
                                Departments.length !== 0 && !isShowLoader
                                    ? Departments.map((department) => (
                                        <Box >
                                            <Grid container spacing={2}>
                                                <Grid item sm={4} md={4} lg={4}>
                                                    <Grid item sm={12} md={12} lg={12}>
                                                        <Typography variant="body1" sx={styles.dept_name}>{department.department_name}</Typography>
                                                    </Grid>
                                                </Grid>
                                                {
                                                    department.department_semesters.length > 0
                                                        ? department.department_semesters.map(sem => (
                                                            <Grid item sm={1} md={1} lg={1} >
                                                                <Grid item className="department_sem-div">
                                                                    <MaterialCheckBox
                                                                        label={sem.label}
                                                                        onChange={(e) => selectSem(e, department.department_id, department.college_university_degree_department_id)}
                                                                        defaultchecked={selectedSemesters.includes(parseInt(department.value + "" + sem.value)) ? true : false}
                                                                        sx={{
                                                                            width: "100%",
                                                                            boxShadow: theme.shadows[2],
                                                                            borderRadius: "15px",
                                                                            backgroundColor: selectedSemesters.includes(parseInt(department.department_id + "" + sem.value)) ? "#52C3FF" : "white",
                                                                            fontWeight: 600,
                                                                            textAlign: "center",
                                                                            marginTop: "15px",
                                                                            color: "black",
                                                                        }}
                                                                        onHoverColor="#52C3FF"
                                                                        value={sem.value}
                                                                    />
                                                                    {/* <label className={selectedSemesters.includes(parseInt(department.department_id + "" + sem.value)) ? "department_sem-card shadow semester-selected" : "department_sem-card shadow"} >{sem.label}
                                                                    <input value={sem.value} className="semesterCheckBox" defaultChecked={selectedSemesters.includes(parseInt(department.value + "" + sem.value)) ? true : false} type='checkbox' style={{ display: 'none' }} name='selectSemester'
                                                                        onChange={(e) => selectSem(e, department.department_id)} /></label> */}
                                                                </Grid>
                                                            </Grid>
                                                        ))
                                                        : ("")
                                                }
                                            </Grid>
                                            <Divider sx={styles.divider2} />
                                        </Box>
                                    ))
                                    : null
                            }
                            {
                                selectedSemesters.length > 0 && flagNext
                                    ? (
                                        <Button variant="contained" id="next" sx={{ ...styles.button, maxWidth: "200px" }} onClick={getAllSections}>Select Sections &nbsp;
                                            <AddIcon sx={{ fontSize: "1.4rem" }} /></Button>
                                    )
                                    : null
                            }
                        </Grid>
                    </Grid>
                    {
                        allSections.length > 0 && !flagNext
                            ? (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                            <Typography variant="body1" sx={styles.label}>
                                                Select Sections
                                                <Typography variant="body1" component="sup" sx={styles.redText}>
                                                    *
                                                </Typography>
                                            </Typography>
                                            <MaterialCheckBox
                                                label='Select all'
                                                onChange={(e) => handleSelectAllDeptSections(e)}
                                                defaultchecked={isSelectAllDeptSections ? true : false}
                                                sx={{
                                                    width: "100px",
                                                    boxShadow: theme.shadows[2],
                                                    borderRadius: "15px",
                                                    backgroundColor: isSelectAllDeptSections ? "#FFDFD6" : "white",
                                                    textAlign: "center",
                                                    mt: "10px",
                                                    ml: "15px",
                                                    color: "black",
                                                    textTransform: 'none',
                                                }}
                                                onHoverColor="#52C3FF"
                                            />
                                        </Grid>
                                        <Grid container spacing={2} sx={{ mt: 1 }}>
                                            {
                                                Departments.map(department => (
                                                    <Grid item xs={6} md={6} lg={6}>
                                                        <Typography variant="body1"
                                                            sx={{
                                                                ...styles.dept_name_label,
                                                                display: 'flex',
                                                                justifyContent: 'space-between'
                                                            }}>
                                                            {department.department_name}

                                                            <MaterialCheckBox
                                                                label='Select all'
                                                                onChange={(e) => handleSelectAllDeptWiseSections(e, department.department_id)}
                                                                defaultchecked={allSelectedDeptWiseSections.includes(parseInt(department.department_id)) ? true : false}
                                                                sx={{
                                                                    width: "100px",
                                                                    boxShadow: theme.shadows[2],
                                                                    borderRadius: "15px",
                                                                    backgroundColor: allSelectedDeptWiseSections.includes(parseInt(department.department_id)) ? "#52C3FF" : "white",
                                                                    textAlign: "center",
                                                                    ml: "15px",
                                                                    color: "black",
                                                                    textTransform: 'none',
                                                                }}
                                                                onHoverColor="#52C3FF"
                                                            />
                                                        </Typography>

                                                        <Grid item sx={styles.section_scrollable_div} className="scrollable">
                                                            <Grid container spacing={2}>
                                                                {
                                                                    allSections.map(section => {
                                                                        if (section.university_degree_department_id === department.department_id) {
                                                                            return (
                                                                                <Grid item xs={6} md={6} lg={6}>
                                                                                    <MaterialCheckBox
                                                                                        label={`${section.name} - (Sem ${section.semester_id})`}
                                                                                        onChange={(e) => handleSelectSections(e, section.id)}
                                                                                        defaultchecked={createsections.includes(parseInt(section.id)) ? true : false}
                                                                                        sx={{
                                                                                            width: "100%",
                                                                                            boxShadow: theme.shadows[2],
                                                                                            borderRadius: "15px",
                                                                                            backgroundColor: createsections.includes(parseInt(section.id)) ? "#52C3FF" : "white",
                                                                                            fontWeight: 500,
                                                                                            textAlign: "center",
                                                                                            marginTop: "10px",
                                                                                            color: "black",
                                                                                        }}
                                                                                        onHoverColor="#52C3FF"
                                                                                        value={section.id}
                                                                                    />
                                                                                    {/* <div className="select-section-label">{section.name} - (Sem {section.semester_id})</div> */}
                                                                                    {/* <label className={createsections.includes(parseInt(section.id)) ? "select-section-label shadow section-selected" : "select-section-label shadow"}>
                                                                                    <input type="checkbox" value={section.id} style={{ display: "none" }}
                                                                                        onChange={(e) => handleSelectSections(e, section.id)} defaultChecked={createsections.includes(parseInt(section.id)) ? true : false} />
                                                                                    {section.name} - (Sem {section.semester_id})</label> */}
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        <Divider sx={styles.divider2} />
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                            : null
                    }

                    {
                        createsections.length > 0 ?
                            (
                                <Grid container>
                                    <Grid item xs={12} md={12} lg={!2}>
                                        <Typography variant="body1" sx={styles.label}>
                                            Select Categories
                                            <Typography variant="body1" component="sup" sx={styles.redText}>
                                                *
                                            </Typography>
                                        </Typography>
                                        <MultipleSelectCheckmarks
                                            data={feedbackCategories}
                                            onChange={handleSelectCategories}
                                            label={"Select Categories"}
                                            selectedValues={selectedCategories}
                                            setSelectedValues={setSelectedCategories}
                                            size="medium"
                                            maxwidth="100%"
                                            width="100%"
                                            sx={{ width: "100%" }}
                                        />
                                    </Grid>
                                </Grid>
                            )
                            : null
                    }

                </Box>
            </LayoutwithSideNav >
        </Box >
    )
}

export default FeedbackCategories;