import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./feedbackListCard.styles.css";
import { Collapse } from 'react-collapse';
import {HiDownload} from "react-icons/hi";
import * as XLSX from 'xlsx';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getFeedbackResults, generateGeneralFeedbackReports, editFeedbackDetails, getFeedbackStats, semesterFeedbackAnalytics } from "../../../api/facultyfeedbackapis";
import PdfDocument from "../feedbackResults/download";
import GeneralPdfDocument from "../feedbackResults/generalReportsDownload";
import { BsDot } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const FeedbackListCard = (props) => {
    const navigate = useNavigate();
    const [feedbackDetails, setFeedbackDetails] = useState(props.feedback_details);
    const [isOpenCollapse, setIsOpenCollapse] = useState(false);
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
    const [buttonClicked, setButtonClicked] = useState("view-feedback-details-btn");
    const [showFeedbackEditModal, setShowFeedbackEditModal] = useState(false);
    const minDate = moment().format("YYYY-MM-DDTHH:mm");
    const [enableStartTime, setEnableStartTime] = useState(false);
    const [enableEndTime, setEnableEndTime] = useState(false);
    const [disableDownload, setDisableDownload] = useState(false);
    const [subFeedbackButtons, setSubFeedbackButtons] = useState([]);

    const handleCollapse = () => {
        if (isOpenCollapse == true) {
            setIsOpenCollapse(false);
            setIsFeedbackOpen(false);
            setButtonClicked("view-feedback-details-btn");
        } else {
            setIsOpenCollapse(true);
            setIsFeedbackOpen(true);
            setButtonClicked("feedback-button-clicked")
        }
    };

    const handleOpenMasterFeedback = async (id,feedback_type) => {
        const feedbackResults = await getFeedbackResults("master_feedback_id", id);
        if(feedbackResults.data.analytics_calculated === 2){
            navigate('/feedbackResults', { state: { master_feedback_id: id, feedback_type: "master", sub_feedback_id: "" } });
        }else{
            // if(feedbackResults.data.analytics_calculated === 0){
            //     const res = semesterFeedbackAnalytics("master_feedback_id", id, feedback_type);
            //     console.log("analytics result -> ",res);
            // }
            console.log("log -> ",feedbackResults.data.analytics_calculated);
            toast.success("Reports are getting ready. Please come back after 10minutes.!", {
                position: "top-right",
                autoClose: 2000,
            });
            setDisableDownload(true);
            setTimeout(() => {
                setDisableDownload(false);
            },300*1000)
        }
    };

    const handleOpenSubFeedback = async (id,feedback_type) => {
        const feedbackResults = await getFeedbackResults("feedback_id", id);
        if(feedbackResults.data.analytics_calculated === 2){
            navigate('/feedbackResults', { state: { master_feedback_id: "", feedback_type: "subFeedback", sub_feedback_id: id } });
        }else{
            // if(feedbackResults.data.analytics_calculated === 0){
            //     const res = semesterFeedbackAnalytics("feedback_id", id,feedback_type);
            //     console.log("analytics result -> ",res);
            // }
            console.log("log -> ",feedbackResults.data.analytics_calculated);
            toast.success("Reports are getting ready. Please come back after 10minutes.!", {
                position: "top-right",
                autoClose: 2000,
            });
            setSubFeedbackButtons(prevValues => [...prevValues, parseInt(id)]);
            setTimeout(() => {
                const updatedValues = subFeedbackButtons.filter((val) => parseInt(val) !== parseInt(id))
                setSubFeedbackButtons(updatedValues);
            },300*1000)
        }
    };

    const handleOpenGeneralMasterFeedback = (id) => {
        navigate('/feedbacks/generalFeedbackResults', { state: { master_feedback_id: id, feedback_type: "master", sub_feedback_id: "" } });
    }


    const downloadDataAsExcelAndZip = async () => {
        const feedbackResults = await getFeedbackResults("master_feedback_id", feedbackDetails.id);
        if(feedbackResults.data.analytics_calculated === 2){
            if (feedbackResults.data.feedbacks.length !== 0) {
                const zip = new JSZip();
                feedbackResults.data.feedbacks.forEach((feedback, index) => {
                    const key = feedback.faculty_name;
                    const workbook = XLSX.utils.book_new();
                    console.log(feedback);
                    feedback.feedbacks.map((item, index) => {
                        let sheetData = [];
                        sheetData.push(["", `${item.college_name}`, ""]);
                        sheetData.push(["", "STUDENT FEEDBACK REPORT", ""]);
                        sheetData.push(["", `ACADEMIC YEAR 2022-23 ${parseInt(item.section_name[0]) % 2 == 0 ? 'EVEN' : 'ODD'} SEMESTER`, ""]);
                        sheetData.push([`Branch: ${item.branch_name}`, "", `Section: ${item.section_name}`]);
                        sheetData.push([`Subject: ${item.subject_name}`, "", ""]);
                        sheetData.push([`Faculty: ${key}`, "", ""]);
                        sheetData.push(["Question", "Category", "Score"]);
                        item.questions.forEach((question) => {
                            sheetData.push([question.question, question.category_name, question.Score])
                        });
                        sheetData.push(["", `Overall Effectiveness: ${item.overall_ffectiveness * 10}%`, ""]);
                        const sheetName = `Sheet${index + 1}`;
                        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
                        worksheet["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
                        worksheet["!rows"] = [{ hpx: 40 }];
                        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
                    });
    
                    const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
                    zip.file(`file_${index}.xlsx`, excelBuffer);
                });
    
                zip.generateAsync({ type: 'blob' })
                    .then(blob => {
                        const downloadLink = document.createElement('a');
                        downloadLink.href = URL.createObjectURL(blob);
                        downloadLink.download = 'data.zip';
                        downloadLink.click();
                    })
                    .catch(error => console.error(error));
            } else {
                toast.error("No data to download. Please Try again!", {
                    position: "top-right",
                    autoClose: 2000,
                });
            }
        }else{
            // if(feedbackResults.data.analytics_calculated === 0){
            //     const res = semesterFeedbackAnalytics("master_feedback_id", feedbackDetails.id, 1);
            //     console.log("analytics result -> ",res);
            // }
            console.log("log -> ",feedbackResults.data.analytics_calculated);
            toast.success("Reports are getting ready. Please come back after 10minutes.!", {
                position: "top-right",
                autoClose: 2000,
            });
            setDisableDownload(true);
            setTimeout(() => {
                setDisableDownload(false);
            },300*1000)
        }
        
    };

    async function DownloadDataAsPdf() {
        const feedbackResults = await getFeedbackResults("master_feedback_id", feedbackDetails.id);
        if(feedbackResults.data.analytics_calculated === 2){
            if (feedbackResults.data.feedbacks.length !== 0) {
                const zip = new JSZip();
                // for each pdf you have to add the blob to the zip
                for (const feedback of feedbackResults.data.feedbacks) {
                    const MyDocument = () => (
                        <PdfDocument key={feedback.faculty_id} resultsData={feedback.feedbacks} faculty_name={feedback.faculty_name} faculty_gender={feedback.faculty_gender}/>
                    );

                    zip.file(`${feedback.faculty_name}.pdf`, pdf(<MyDocument />).toBlob());

                }

                // once you finish adding all the pdf to the zip, return the zip file
                return zip.generateAsync({ type: "blob" }).then((blob) => {
                    saveAs(blob, "facultyfeedbacks.zip");
                });
            } else {
                toast.error("No data to download. Please Try again!", {
                    position: "top-right",
                    autoClose: 2000,
                });
            }
        }else{
            // if(feedbackResults.data.analytics_calculated === 0){
            //     const res = semesterFeedbackAnalytics("master_feedback_id", feedbackDetails.id, 1);
            //     console.log("analytics result -> ",res);
            // }
            console.log("log -> ",feedbackResults.data.analytics_calculated);
            toast.success("Reports are getting ready. Please come back after 10minutes.!", {
                position: "top-right",
                autoClose: 2000,
            });
            setDisableDownload(true);
            setTimeout(() => {
                setDisableDownload(false);
            },300*1000)
        }
    };

    const DownloadGenerateReportsAsPdf = async () => {
        const feedbackResults = await generateGeneralFeedbackReports(feedbackDetails.id);
        console.log(feedbackResults);
        if(parseInt(feedbackResults.data.analytics_calculated) === 2){
            if (feedbackResults.data.result.feedbacks || feedbackResults.data.result.general_feedbacks) {
                let result = [];
                if (feedbackResults.data.result.feedbacks.length > 0) {
                    for (let i = 0; i < feedbackResults.data.result.feedbacks.length; i++) {
                        result.push(feedbackResults.data.result.feedbacks[i]);
                    }
                }
                if (feedbackResults.data.result.general_feedbacks.length > 0) {
                    for (let i = 0; i < feedbackResults.data.result.general_feedbacks.length; i++) {
                        if (feedbackResults.data.result.general_feedbacks[i].questions.length != 0) {
                            result.push(feedbackResults.data.result.general_feedbacks[i]);
                        }
                    }
                }
                if (result.length === 0) {
                    toast.error("No data to download. Please Try again!", {
                        position: "top-right",
                        autoClose: 2000,
                    });
                    return;
                }
                const zip = new JSZip();
                // for each pdf you have to add the blob to the zip
                for (const feedback of feedbackResults.data.result.feedbacks) {
                    const MyDocument = () => (
                        <GeneralPdfDocument key={feedback.faculty_id} type={1} resultsData={feedback.feedbacks} faculty_name={feedback.faculty_name} faculty_gender={feedback.faculty_gender} />
                    );
    
                    zip.file(`${feedback.faculty_name}.pdf`, pdf(<MyDocument />).toBlob());
    
                }
                if (feedbackResults.data.result.general_feedbacks) {
                    for (const feedback of feedbackResults.data.result.general_feedbacks) {
                        const MyDocument = () => (
                            <GeneralPdfDocument key={feedback.section_name} type={2} resultsData={[feedback]} />
                        );
    
                        zip.file(`General Amenitites - ${feedback.branch_short_name}-${feedback.section_name}.pdf`, pdf(<MyDocument />).toBlob());
    
                    }
                }
    
                // once you finish adding all the pdf to the zip, return the zip file
                return zip.generateAsync({ type: "blob" }).then((blob) => {
                    saveAs(blob, "facultyfeedbacks.zip");
                });
            } else {
                toast.error("No data to download. Please Try again!", {
                    position: "top-right",
                    autoClose: 2000,
                });
            }
        }else{
            // if(feedbackResults.data.analytics_calculated === 0){
            //     const res = semesterFeedbackAnalytics("master_feedback_id", feedbackDetails.id, 2);
            //     console.log("analytics result -> ",res);
            // }
            console.log("log -> ",feedbackResults.data.analytics_calculated);
            toast.success("Reports are getting ready. Please come back after 10minutes.!", {
                position: "top-right",
                autoClose: 2000,
            });
            setDisableDownload(true);
            setTimeout(() => {
                setDisableDownload(false);
            },300*1000)
        }
        
    }

    const downloadGeneralDataAsExcelAndZip = async () => {
        const feedbackResults = await generateGeneralFeedbackReports(feedbackDetails.id);
        console.log(feedbackResults);
        const zip = new JSZip();
        const cellStyle = { font: { bold: true } };

        if(parseInt(feedbackResults.data.analytics_calculated) === 2){
            if (feedbackResults.data.result.feedbacks || feedbackResults.result.data.general_feedbacks) {
                let result = [];
                if (feedbackResults.data.result.feedbacks.length > 0) {
                    for (let i = 0; i < feedbackResults.data.result.feedbacks.length; i++) {
                        result.push(feedbackResults.data.result.feedbacks[i]);
                    }
                }
                if (feedbackResults.data.result.general_feedbacks.length > 0) {
                    for (let i = 0; i < feedbackResults.data.result.general_feedbacks.length; i++) {
                        if (feedbackResults.data.result.general_feedbacks[i].questions.length != 0) {
                            result.push(feedbackResults.data.result.general_feedbacks[i]);
                        }
                    }
                }
                if (result.length > 0) {
                    result.forEach((feedback, index) => {
                        if (feedback.faculty_id) {
                            const key = feedback.faculty_name;
                            const workbook = XLSX.utils.book_new();
                            console.log(feedback);
                            feedback.feedbacks.map((item, index) => {
                                let sheetData = [];
                                sheetData.push(["", `${item.college_name}`, ""]);
                                if (item.feedback_type === 2) {
                                    sheetData.push(["", "STUDENT FEEDBACK REPORT", ""]);
                                } else if (item.feedback_type === 2) {
                                    sheetData.push(["", "HOD FEEDBACK REPORT", ""]);
                                } else if (item.feedback_type === 3) {
                                    sheetData.push(["", "COUNSELLOR FEEDBACK REPORT", ""]);
                                } else if (item.feedback_type === 4) {
                                    sheetData.push(["", "COORDINATOR FEEDBACK REPORT", ""]);
                                }
                                sheetData.push(["", `ACADEMIC YEAR 2022-23 ${item.type.toUpperCase()} SEMESTER`, ""]);
                                if (item.feedback_type === 1) {
                                    sheetData.push([`Branch: ${item.branch_name}`, "", `Subject: ${item.section_name}`]);
                                    sheetData.push([`Subject: ${item.subject_name}`, "", ""]);
                                } else {
                                    sheetData.push([`Branch: ${item.branch_name}`, "", ""]);
                                }
                                sheetData.push([`Faculty: ${key}`, "", ""]);
                                sheetData.push(["Question", "Category", "Score"]);
                                item.questions.forEach((question) => {
                                    sheetData.push([question.question, question.category_name, question.Score])
                                });
                                if (item.feedback_type === 1) {
                                    sheetData.push(["", `Overall Effectiveness: ${item.overall_ffectiveness * 10}%`, ""]);
                                } else if (item.feedback_type === 2) {
                                    sheetData.push(["", `Effectiveness: ${item.overall_effectiveness * 10}%`, ""]);
                                } else if (item.feedback_type === 3) {
                                    sheetData.push(["", `Counselling Effectiveness: ${item.overall_effectiveness * 10}%`, ""]);
                                } else if (item.feedback_type === 4) {
                                    sheetData.push(["", `Coordinator Effectiveness: ${item.overall_effectiveness * 10}%`, ""]);
                                }
                                const sheetName = `Sheet${index + 1}`;
                                const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
                                worksheet["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
                                worksheet["!rows"] = [{ hpx: 40 }];
                                XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
                            });

                            const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
                            zip.file(`${key}.xlsx`, excelBuffer);
                        } else {
                            const workbook = XLSX.utils.book_new();
                            console.log(feedback);
                            let item = feedback;
                            let sheetData = [];
                            sheetData.push(["", `${item.college_name}`, ""]);
                            sheetData.push(["", "GENERAL AMENITIES FEEDBACK REPORT", ""]);
                            sheetData.push(["", `ACADEMIC YEAR 2022-23 ${item.type.toUpperCase()} SEMESTER`, ""]);
                            sheetData.push([`Branch: ${item.branch_name}`, `Semester: ${item.semester_id}`, `Subject: ${item.section_name}`]);
                            sheetData.push(["Question", "Category", "Score"]);
                            item.questions.forEach((template, index) => {
                                sheetData.push([template.template_name, "", ""])
                                template.questions.forEach(question => {
                                    sheetData.push([question.question, question.category_name, question.Score])
                                })
                            });
                            const sheetName = `Sheet${index + 1}`;
                            const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
                            worksheet["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
                            worksheet["!rows"] = [{ hpx: 40 }];
                            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

                            const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
                            zip.file(`General Amenities - ${item.section_name}.xlsx`, excelBuffer);
                        }

                    });

                    zip.generateAsync({ type: 'blob' })
                        .then(blob => {
                            const downloadLink = document.createElement('a');
                            downloadLink.href = URL.createObjectURL(blob);
                            downloadLink.download = 'faculty_semester_feedbacks.zip';
                            downloadLink.click();
                        })
                        .catch(error => console.error(error));
                } else {
                    toast.error("No data to download. Please Try again!", {
                        position: "top-right",
                        autoClose: 2000,
                    });
                }

            } else {
                toast.error("No data to download. Please Try again!", {
                    position: "top-right",
                    autoClose: 2000,
                });
            }
        }else{
            // if(feedbackResults.data.analytics_calculated === 0){
            //     const res = semesterFeedbackAnalytics("master_feedback_id", feedbackDetails.id, 2);
            //     console.log("analytics result -> ",res);
            // }
            console.log("log -> ",feedbackResults.data.analytics_calculated);
            toast.success("Reports are getting ready. Please come back after 10minutes.!", {
                position: "top-right",
                autoClose: 2000,
            });
            setDisableDownload(true);
            setTimeout(() => {
                setDisableDownload(false);
            },300*1000)
        }
    };

    const formatDate = (dateString) => {
        if (dateString) {
            const dateParts = dateString.split(' ');
            const day = dateParts[1];
            const month = dateParts[2];
            const year = dateParts[3];
            const timeParts = dateParts[4].split(':');
            const hours = parseInt(timeParts[0], 10);
            const minutes = timeParts[1];
            const amPm = hours >= 12 ? 'PM' : 'AM';
            const formattedHours = hours % 12 || 12; // Convert to 12-hour format

            // Format the date as "dd MMM yyyy hh:mm a"
            const formattedDate = `${month} ${day} ${year} ${formattedHours}:${minutes} ${amPm}`;
            return formattedDate;
        }
    }

    const formatter = new Intl.DateTimeFormat('en-US', {
        month: 'short', // Short month name (e.g., Sep)
        day: 'numeric', // Day of the month (e.g., 19)
        year: 'numeric', // Year (e.g., 2023)
        hour: '2-digit', // Hour (e.g., 14)
        minute: '2-digit', // Minute (e.g., 20)
    });

    const current_time = new Date().getTime();
    const inputDate = new Date(feedbackDetails?.feedbacks[0]?.doe.replace("GMT", ""));
    inputDate.setTime(inputDate.getTime());
    const startInputDate = new Date(feedbackDetails?.feedbacks[0]?.start_time.replace("GMT", ""));
    startInputDate.setTime(startInputDate.getTime());
    let end_date = formatDate(feedbackDetails?.feedbacks[0]?.doe);
    let start_date = formatDate(feedbackDetails?.feedbacks[0]?.start_time);

    let master_created_at = new Date(feedbackDetails.created_at);
    master_created_at.setTime(master_created_at.getTime());

    master_created_at = formatter.format(master_created_at);
    master_created_at = master_created_at.toString().replace("GMT+0530 (India Standard Time)", "");

    let input_sdate = new Date(start_date); 
    let start_time = `${input_sdate.getFullYear()}-${(input_sdate.getMonth() + 1).toString().padStart(2, '0')}-${input_sdate.getDate().toString().padStart(2, '0')} ${input_sdate.getHours().toString().padStart(2, '0')}:${input_sdate.getMinutes().toString().padStart(2, '0')}`;
    let input_edate = new Date(end_date);
    let end_time = `${input_edate.getFullYear()}-${(input_edate.getMonth() + 1).toString().padStart(2, '0')}-${input_edate.getDate().toString().padStart(2, '0')} ${input_edate.getHours().toString().padStart(2, '0')}:${input_edate.getMinutes().toString().padStart(2, '0')}`;

    const [startTime, setStartTime] = useState(start_time);
    const [endTime, setEndTime] = useState(end_time);

    const handleStartTime = (e) => {
        let datetime = e.target.value;
        datetime = datetime.replace("T", " ");
        setStartTime(datetime);
    };
    const handleEndTime = (e) => {
    let datetime = e.target.value;
    datetime = datetime.replace("T", " ");
    setEndTime(datetime);
    };

    const handleFeedbackEditDetailsModal = () => {
        setShowFeedbackEditModal(false);
    }

    const openShowFeedbackEditModal = (s,e) => {
        setEnableEndTime(!e);
        setEnableStartTime(!s);
        setShowFeedbackEditModal(true);
    }

    const submitEditFeedbackDetails = async () => {
        let start_time = startTime;
        let end_time = endTime;
        start_time = start_time+`:00`;
        end_time = end_time+`:00`;
        if(enableStartTime){
            start_time = "";
        }
        if(enableEndTime){
            end_time = "";
        }
        
        if(start_time !== "" || end_time !== ""){
            const result = await editFeedbackDetails(feedbackDetails.id,start_time,end_time);
            if(result.status === 200){
                setShowFeedbackEditModal(false);
                props.refreshFeedbacks();
                toast.success(result.message, {
                    position: "top-right",
                    autoClose: 2000,
                });
            }else{
                setShowFeedbackEditModal(false);
                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 2000,
                });
            }
        }else{
            setShowFeedbackEditModal(false);
            toast.error("Can`t edit details. Please Try again!", {
                position: "top-right",
                autoClose: 2000,
            });
        }
        
    }

    const generateFeedbackStats = async () => {
        const result = await getFeedbackStats(feedbackDetails.id);
        if(result.status === 200 && result.message === "Successfully fetched data!"){
            console.log(result.data);
            const workbook = XLSX.utils.book_new();
            const cellStyle = { font: { bold: true } };
            let sheetData = [];
            let item = result.data;
            sheetData.push(["", `${item.college_name}`, ""]);
            sheetData.push(["", "FEEDBACK STATISTICS REPORT", ""]);
            sheetData.push(["Feedback Name", `${item.feedback_name}`, ""]);
            sheetData.push(["Description", `${item.description}`, ""]);
            sheetData.push(["Start Time", `${formatDate(item.start_time)}`, ""]);
            sheetData.push(["End Time", `${formatDate(item.end_time)}`, ""]);
            sheetData.push(["Feedback Type", `${item.feedback_type} Feedback`, ""]);
            sheetData.push(["Faculty Name", `${item.faculty_name}`, ""]);
            sheetData.push(["Total Feedbacks Sent", `${item.total_feedbacks_sent}`, ""]);
            sheetData.push(["Total Feedbacks Received", `${item.total_feedbacks_submitted}`, ""]);
            sheetData.push(["Participation Percentage", `${item.participated_percentage}%`, ""]);
            sheetData.push(["", "", ""]);
            sheetData.push(["Student Analytics", "", ""]);
            sheetData.push(["S.NO", "Name", "Roll Number","Department","Section","Feedbacks Sent","Feedbacks Submitted"]);
            if(item.student_statistics.length > 0){
                item.student_statistics.map((student,index) => {
                    return sheetData.push([`${index+1}`,`${student.name}`,`${student.roll_number}`,`${student.department_short_name}`,
                                            `${student.section}`,`${student.feedbacks_sent}`,`${student.feedbacks_submitted}`]);
                })
            }else{
                sheetData.push(["","Feedbacks not sent to students!",""]);
            }
            const sheetName = 'Feedback Statistics';
            const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
            // worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 3 } }, { s: { r: 2, c: 0 }, e: { r: 2, c: 3 } }];
            // worksheet["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
            // worksheet["!rows"] = [{ hpx: 40 }];
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

            // Convert the workbook to a binary string
            const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

            // Create a Blob from the binary string
            const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create a link and simulate a click to trigger the download
            const link = document.createElement('a');
            link.href = url;
            const timestamp = new Date().getTime();
            link.setAttribute('download', `${item.feedback_name}_${timestamp}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);


        }else{
            toast.error(result.message, {
                position: "top-right",
                autoClose: 2000,
            });
        }
    }

    // Convert a string to an ArrayBuffer
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }

    return (
        <div id="feedback-card">
            <Modal show={showFeedbackEditModal} onHide={handleFeedbackEditDetailsModal} scrollable dialogClassName="saveClgCoordinatorsmodalWidth" style={{marginTop:'55px'}}>
                <Modal.Header>
                    <Modal.Title>Edit feedback details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                    <div className="col-lg-6 col-md-6 colsm-6">
                        <label className="col-form-label col-form-label-lg" for="start-time-objective">Start Time<span
                        style={{ color: "red" }}>*</span></label>
                        <input className="form-control form-control-md" defaultValue={start_time} type="datetime-local" step="60"
                        placeholder="Time" style={{ height: "45px" }} min={minDate} onChange={handleStartTime} disabled={enableStartTime} />
                    </div>
                    <div className="col-lg-6 col-md-6 colsm-6">
                        <label className="col-form-label col-form-label-lg" for="end-time-objective">End Time<span
                        style={{ color: "red" }}>*</span></label>
                        <input className="form-control form-control-md" defaultValue={end_time} type="datetime-local" step="60"
                        placeholder="Time" style={{ height: "45px" }} min={startTime} onChange={handleEndTime} disabled={enableEndTime} />
                    </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" id="modalCloseBtn" onClick={handleFeedbackEditDetailsModal} style={{ width: '120px' }}>
                        Close
                    </Button>
                    <Button variant="primary" id="editFeedbackTimeBtn" onClick={submitEditFeedbackDetails} style={{ width: '120px' }}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className={feedbackDetails.master_feedback_type === 2 ? "row general-feedback-inner-card shadow" : "row feedback-inner-card shadow"}>
                <div className="col-lg-2 col-md-2 col-sm-2 d-felx justify-content-center text-center">{master_created_at}</div>
                <div className="col-lg-3 col-md-3 col-sm-3 d-felx justify-content-center text-center">{feedbackDetails.master_feedback_name}</div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 cardTimings"><strong>Starts at: &nbsp;</strong>{start_date ? start_date : `-`}</div>
                        <div className="col-lg-12 col-md-12 col-sm-12 cardTimings"><strong>Ends at:  &nbsp;</strong>{end_date ? end_date : `-`}</div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 d-felx justify-content-center text-center">
                    {
                        current_time > inputDate.getTime()
                            ? (
                                <div className="row">
                                    <div className="col-lg-5 col-md-5 col-sm-5">
                                        {
                                            feedbackDetails.master_feedback_type === 2 ?
                                                (
                                                    <button className="btn btn-primary" id="view-feedback-details-btn2" onClick={() => handleOpenGeneralMasterFeedback(feedbackDetails.id,feedbackDetails.master_feedback_type)} disabled={disableDownload}>
                                                        View</button>
                                                )
                                                : (
                                                    <button className="btn btn-primary" id="view-feedback-details-btn2" onClick={() => handleOpenMasterFeedback(feedbackDetails.id,feedbackDetails.master_feedback_type)} disabled={disableDownload}>
                                                        View</button>
                                                )
                                        }
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-7">
                                        {
                                            feedbackDetails.master_feedback_type === 2 ? (
                                                <>
                                                    {/* <FaRegFilePdf className="pdf-icon" onClick={DownloadGenerateReportsAsPdf} />
                                                    <FaRegFileExcel className="excel-icon" onClick={downloadGeneralDataAsExcelAndZip} /> */}
                                                    <Dropdown >
                                                        <Dropdown.Toggle variant="success" id="odonwloadResultsBtn" className="shadow" disabled={disableDownload}>
                                                            Download
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={DownloadGenerateReportsAsPdf}><BsDot style={{ color: "#f40f02", fontSize: "30px" }} />Download Pdf</Dropdown.Item>
                                                            <Dropdown.Item onClick={downloadGeneralDataAsExcelAndZip}><BsDot style={{ color: "#1d6f42", fontSize: "30px" }} />Download Excel</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </>
                                            )
                                                : (
                                                    <>
                                                        {/* <FaRegFilePdf className="pdf-icon" onClick={DownloadDataAsPdf} />
                                                        <FaRegFileExcel className="excel-icon" onClick={downloadDataAsExcelAndZip} /> */}
                                                        <Dropdown >
                                                            <Dropdown.Toggle variant="success" id="odonwloadResultsBtn" className="shadow" disabled={disableDownload}>
                                                                Download
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={DownloadDataAsPdf}><BsDot style={{ color: "#f40f02", fontSize: "30px" }} />Download Pdf</Dropdown.Item>
                                                                <Dropdown.Item onClick={downloadDataAsExcelAndZip}><BsDot style={{ color: "#1d6f42", fontSize: "30px" }} />Download Excel</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </>
                                                )
                                        }
                                    </div>
                                    {/* <div className="col-lg-2 col-md-2 col-sm-2">
                                        <div onClick={handleCollapse} style={{ cursor: "pointer" }}>
                                            {
                                                isFeedbackOpen
                                                    ? (<AiFillCaretUp />)
                                                    : (<AiFillCaretDown />)
                                            }
                                        </div>
                                    </div> */}
                                </div>
                            )
                            : current_time > startInputDate && current_time < inputDate
                                ? (
                                    <div className="row">
                                        <div className="col-lg-5 col-md-5 col-sm-5">
                                            <span id="editLabel" onClick={() => openShowFeedbackEditModal(false, true)}>Edit</span>
                                        </div>
                                        <div className="col-lg-7 col-md-7 col-sm-7 d-felx justify-content-center text-center cardTimings cardInprogressLabel">
                                            <button className="btn btn-primary" id="percentageLabel" style={{width: '60px', height: '38px', marginTop: '-5px'}}>
                                                {/* <CircularProgressbar strokeWidth={2} value={feedbackDetails.percentage_participated} text={`${feedbackDetails.percentage_participated}`} /> */}
                                                {feedbackDetails.percentage_participated}%
                                            </button>
                                            <span>In Progress</span>
                                        </div>
                                    </div>
                                )
                                : current_time < startInputDate
                                    ? (
                                        <div className="row">
                                            <div className="col-lg-5 col-md-5 col-sm-5">
                                                <span id="editLabel" onClick={() => openShowFeedbackEditModal(true,true)}>Edit</span>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7 d-felx justify-content-center text-center cardTimings cardInprogressLabel">Scheduled</div>
                                        </div>
                                    )
                                    : (
                                        <div className="row">
                                            <div className="col-lg-5 col-md-5 col-sm-5"></div>
                                            <div className="col-lg-7 col-md-7 col-sm-7 d-felx justify-content-center text-center cardTimings cardInprogressLabel">Not Sent</div>
                                        </div>
                                    )
                    }

                </div>
                <div className="col-lg-1 col-md-1 col-sm-1 d-felx justify-content-center text-center">
                    <div className="row">
                        <button className="btn btn-primary" id="view-feedback-details-btn3" onClick={generateFeedbackStats}>Stats <HiDownload /></button>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ marginTop: '8px' }}>
                            {
                                isFeedbackOpen
                                    ? (<span onClick={handleCollapse} id="viewMoreLabel">View Less</span>)
                                    : (<span onClick={handleCollapse} id="viewMoreLabel">View More</span>)
                            }
                        </div>
                    </div>
                </div>
                <Collapse isOpened={isOpenCollapse}>
                    <div className="inner-feedback-card">
                        {
                            feedbackDetails.feedbacks.length !== 0
                                ? feedbackDetails.feedbacks.map(innerFeedback => (
                                    <div className="row feedback-inner-card2 shadow mt-2">
                                        <div className="col-lg-3 col-md-3 col-sm-3 d-felx justify-content-center text-center">{formatDate(innerFeedback.start_time)}</div>
                                        <div className="col-lg-3 col-md-3 col-sm- 3-felx justify-content-center text-center">{innerFeedback.name}</div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 d-felx justify-content-center text-center" dangerouslySetInnerHTML={{ __html: innerFeedback.description }} ></div>
                                        <div className="col-lg-2 col-md-2 col-sm-2 d-felx justify-content-center text-center">
                                            {
                                                feedbackDetails.master_feedback_type === 2 ? (
                                                    <></>
                                                ) : current_time > inputDate
                                                    ? (
                                                        <button className="btn btn-primary" id="view-feedback-details-btn2" onClick={() => handleOpenSubFeedback(innerFeedback.id,1)} disabled={subFeedbackButtons.includes(parseInt(innerFeedback.id)) ? true : false}>View</button>
                                                    )
                                                    : current_time > startInputDate && current_time < inputDate
                                                        ? (
                                                            <div className="d-felx justify-content-center text-center cardTimings cardInprogressLabel">In Progress</div>
                                                        )
                                                        : current_time < startInputDate
                                                            ? (
                                                                <div className="d-felx justify-content-center text-center cardTimings cardInprogressLabel">Scheduled</div>
                                                            )
                                                            : (
                                                                <div className="d-felx justify-content-center text-center cardTimings cardInprogressLabel">Not Sent</div>
                                                            )
                                            }

                                        </div>
                                    </div>
                                ))
                                : (<div className="d-felx justify-content-center text-center" style={{ fontWeight: "500" }}>No feedbacks created yet!</div>)
                        }
                    </div>
                </Collapse>
            </div>
            <ToastContainer />
        </div>
    )
};

export default FeedbackListCard;