import * as React from 'react';
// import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
// import Chip from '@mui/material/Chip';
import { customBoxShadow } from '../../lib/utils';
import CustomChip from '../common/CustomChip';
import Grid from '@mui/material/Grid';


export default function SendSurveyUnitDetailsCard({selectedSectionData}) {

  const array = [
    {
      id: 'department_name',
      label: 'Department',
    },
    {
      id: 'faculty_name',
      label: 'Faculty',
    },
    {
      id: 'semester_id',
      label: 'Semester',
    },
    {
      id: 'subject_name',
      label: 'Subject',
    },
    {
      id: 'section_name',
      label: 'Section',
    },
    {
      id: 'unit_name',
      label: 'Unit Name',
    },
    {
      id: 'score',
      label: 'Score',
    },
    {
      id: 'has_feedback',
      label: 'Sent Feedback',
    },
  ];


  return (
    <Card sx={{ maxWidth:'100%', mt:1, boxShadow : customBoxShadow, borderRadius : '20px'}}>
      <CardContent>

        <Typography variant="h6" component="div">
          Faculty Unit Survey Details
        </Typography>
        <Divider variant="middle" sx={{ml:0, color:'black', opacity:'100%'}}/>
        <Grid container flexDirection={'row'}>
          {array?.map(({label,id})=>(
          <Grid key={id} item xs={3} >
            <Typography component={'div'} sx={{ mt:1.5, fontSize: 14 }} color="text.secondary" gutterBottom>
              {label} : <CustomChip label={selectedSectionData[id] ? selectedSectionData[id] : 'NA'}/>
            </Typography>
          </Grid>
          ))}
          
        </Grid>

      </CardContent>
    </Card>
  );
}
