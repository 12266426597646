import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import "./FeedbackHome.css";
import { viewsurveys } from "../../../api/facultyfeedbackapis";
import Navbar from "../../navbar/navbar.component";
import FeedbackListCard from "../feedbackListCard/feedbackListCard.component";
import LayoutwithSideNav from "../../common/LayoutwithSideNav";
import GeneralPdfDocument from "../feedbackResults/generalReportsDownload";

const FeedbackHome = () => {
  const navigate = useNavigate();
  const [allSurveys, setAllSurveys] = useState([]);
  const showSurveys = async (e) => {
    setAllSurveys([]);
    const getAllSurveys = await viewsurveys();
    setAllSurveys(getAllSurveys.data);
  }
  // console.log(getAllSurveys);
  useEffect(() => {
    console.log("Hello");
    showSurveys();
  }, [])

  return (
    <div className="feedback-home-page">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <Navbar />
        </div>
      </div>

      {/* <div className="feedbacks all-feedbacks w-100"> */}
      <div className="container-fluid px-0">
        <LayoutwithSideNav>
          <div className="feedbacks-headerBanner">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-7">
                <h2 className="mt-1">
                  <p className="font-weight-bold" id="pageTitle">Your Surveys</p>
                  <hr className="titlehr" />
                </h2>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-5 d-flex align-items-center justify-content-end">
                <Dropdown >
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <i className="fa fa-wpforms" aria-hidden="true"></i>&nbsp;
                    Create New Feedback
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => navigate("/feedback")}>Subject Feedback</Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/feedbacks/generalFeedback")}>General Feedback</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                {/* {
                  feedback.length !== 0
                    ? (<GeneralPdfDocument key={feedback[1].section_name} type={2} resultsData={[feedback[1]]} />)
                    : ""
                } */}
                <div className="row">
                  {
                    allSurveys.length !== 0
                      ? allSurveys.map(survey => <FeedbackListCard key={survey.id} feedback_details={survey} refreshFeedbacks={showSurveys} />)
                      : (<div class="mt-5" style={{ textAlign: "center" }}>No feedbacks created to show!</div>)
                  }
                </div>
              </div>


            </div>
          </div>
        </LayoutwithSideNav>

      </div>
    </div >
  );
};
export default FeedbackHome;
