import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactCountdownClock from "react-countdown-clock";
import { AiFillLock } from "react-icons/ai";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FaLongArrowAltRight } from "react-icons/fa";
import { RiTeamLine } from "react-icons/ri";
import { AiFillCloseCircle } from "react-icons/ai";
import { HiOutlineChatAlt2 } from "react-icons/hi";

import { getLeaderBoard, getWeeklyChallengeDetails, submitViewerChallengeAnswer, current_user_id, getAllTeams, getWeeklyChallengeData, getQuestionSubmissionOrder } from "../../api/weeklyChallengeAPIs";
import Wheel from "../participants/spinWheel/spinWheel.component";
import LeaderBoard from "../leaderBoard/leaderBoard.component";
import Chatwidget2 from "../chatwindow/viewerChatroom";
import "./viewChallenge.styles.css";
import { closeVAblyConnection } from "../../store/reducers/joinChallenge.reducer";

const ViewChallenge = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const realtimePChannel = useSelector(state => state.joinChallenge.realtimePChannel);
    const viewer_channel = useSelector(state => state.joinChallenge.viewerChannel);
    const [showChallengeEnvLoader, setShowChallengeEnvLoader] = useState(true);
    const [showSpinWheel, setShowSpinWheel] = useState(false);
    const [showQuestions, setShowQuestions] = useState(false);
    const [showAllTeams, setShowAllTeams] = useState(false);
    const [challengeDetails, setChallengeDetails] = useState(location.state.challengeDetails);
    const [weekly_challenge_details, setWeeklyChallengeDetails] = useState(null);
    const [weekly_challenge_data, setWeeklyChallengeData] = useState(null);
    const [spinWheelData, setSpinWheelData] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [questionData, setQuestionData] = useState("");
    const [leaderBoardData, setLeaderBoardData] = useState([]);
    const [isShowLeaderBoard, setIsShowLeaderBoard] = useState(false);
    const [isShowFinalLeaderBoard, setIsShowFinalLeaderBoard] = useState(false);
    const [allTeams, setAllTeams] = useState([]);
    const [myTeam, setMyTeam] = useState([]);
    const [selectedoption, setSelectedOption] = useState(null);
    const [showChallengeCloseAlertModal, setShowChallengeCloseAlertModal] = useState(false);
    const [questionPosition, setQuestionPosition] = useState(0);
    const [showOtherTeamsModal, setShowOtherTeamsModal] = useState(false);
    const [leaderBoardLoader, setLeaderBoard] = useState(false);
    const [showLeaderBoardTimer, setShowLeaderBoardTimer] = useState(true);
    const [answer, setAnswer] = useState("");
    const [submittedQuestionOrderDataLen, setSubmittedQuestionOrderDataLen] = useState(1);
    const [lastSubmittedQuestionMembers, setLastSubmittedQuestionMembers] = useState(null);
    const [timetaken, setTimeTaken] = useState(new Date().getTime());
    const [newmsgrecieved, setnewmsgrecieved] = useState(false);
    const [showResults, setShowResults] = useState(false);
    let intervalStopper = useRef(true);
    var timeInterval;

    const options = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
    const optionbgcolors = [
        "#f7d5b2",
        "#fee091",
        "#f1c7db",
        "#bdcaff",
        "#f7d5b2",
        "#fee091",
        "#f1c7db",
        "#bdcaff",
    ];
    const img_extensions = [
        'png',
        'jpg',
        'jpeg',
        'gif',
        'tiff'
    ];
    const video_extensions = [
        'mp4',
        'mov',
        'wmv',
        'avi',
        'flv',
        'f4v',
        'swf',
        'mkv'
    ];
    const audio_extensions = [
        'mp3',
        'aac',
        'flac',
        'alac',
        'wac',
        'aiff',
        'dsd',
        'pcm'
    ];

    useEffect(() => {
        if (weekly_challenge_details === null) {
            getMoreWeeklyChallengeDetails();
        }

        if (weekly_challenge_data === null) {
            getChallengeData();
        }

        if (viewer_channel) {
            viewer_channel.subscribe((msg) => {
                console.log(msg);
                if (msg.name === "topic_selection_on") showspinwheelloader(msg.data);
                else if (msg.name === "topic_selected") showSpinwheel(msg.data);
                else if (msg.name === "question") updateQuestion(msg.data);
                else if (msg.name === "leader_board") showLeaderboard(msg.data);
                else if (msg.name === "team_generated") getTeams();
            });

            return function cleanup() {
                viewer_channel.unsubscribe();
            };
        }

    });

    const getMoreWeeklyChallengeDetails = async () => {
        const details = await getWeeklyChallengeDetails(challengeDetails.id);
        setWeeklyChallengeDetails(details.data);
    };

    const getChallengeData = async () => {
        const challengeData = await getWeeklyChallengeData(challengeDetails.id);
        setWeeklyChallengeData(challengeData.data);
    }

    const showspinwheelloader = (data) => {
        console.log("spinWheel Loader", data);
        setSpinWheelData(JSON.parse(data));
        setShowSpinWheel(false);
        setShowQuestions(false);
        setIsShowFinalLeaderBoard(false);
        setShowAllTeams(false);
        setIsShowLeaderBoard(false);
        setShowChallengeEnvLoader(true);
        setLeaderBoard(false);
    };
    const showSpinwheel = (data) => {
        setSelectedTopic(parseInt(data));
        setSpinWheelData(weekly_challenge_data.topics);
        setShowChallengeEnvLoader(false);
        setShowQuestions(false);
        setIsShowFinalLeaderBoard(false);
        setShowAllTeams(false);
        setShowSpinWheel(false);
        setIsShowLeaderBoard(false);
        setLeaderBoard(false);
        setTimeout(function () {
            setShowSpinWheel(true);
        }, 100);
    };
    const updateQuestion = async (data) => {
        console.log("update Question", data);
        const question = JSON.parse(data);
        setQuestionData(JSON.parse(data));
        setQuestionPosition(parseInt(question.current_pos) + 1);

        const submittedQuestionData = await getQuestionSubmissionOrder(challengeDetails.id, question.id);
        if (weekly_challenge_details.participation_type === 1) {
            setSubmittedQuestionOrderDataLen(submittedQuestionData.data[0].teams.length);
        } else {
            setSubmittedQuestionOrderDataLen(submittedQuestionData.data[0].participants.length);
        }
        timealert(weekly_challenge_details.time_per_question, submittedQuestionData.data[0]);

        setShowSpinWheel(false);
        setShowChallengeEnvLoader(false);
        setIsShowFinalLeaderBoard(false);
        setShowAllTeams(false);
        setIsShowLeaderBoard(false);
        setLeaderBoard(false);
        setTimeTaken(new Date().getTime());
        setShowQuestions(true);
    };

    const showLeaderboard = async (msg) => {
        console.log("show leaderboard");
        const data = msg.split(/[_]/);
        const timer = parseInt(data[0]);
        const question_position = parseInt(data[1]);
        const question_id = parseInt(data[2]);
        //get leaderboard data
        const leaderboard = await getLeaderBoard(challengeDetails.id, question_id);
        setLeaderBoardData(leaderboard.data);
        setShowSpinWheel(false);
        setShowChallengeEnvLoader(false);
        setIsShowFinalLeaderBoard(false);
        setShowAllTeams(false);
        setShowQuestions(false);
        setLeaderBoard(false);
        setIsShowLeaderBoard(true);
        //submitting answer if not submitted
        if (selectedoption || answer) {
            onSubmitAnswer();
        }
        clearInterval(timeInterval);
        timeInterval = null;
        intervalStopper = false;
        return;
    };

    const finalleaderboard = async () => {
        console.log("final leader board");
        console.log(questionData.id);
        //get final leaderboard data
        const leaderboard = await getLeaderBoard(challengeDetails.id, questionData.id);
        setLeaderBoardData(leaderboard.data);
        setShowSpinWheel(false);
        setShowChallengeEnvLoader(false);
        setIsShowLeaderBoard(false);
        setShowAllTeams(false);
        setShowQuestions(false);
        setLeaderBoard(false);
        setIsShowFinalLeaderBoard(true);
        //submitting answer if not submitted
        if (selectedoption || answer) {
            onSubmitAnswer();
        }
        clearInterval(timeInterval);
        timeInterval = null;
        intervalStopper = false;
        return;
    };

    const handleQuestionTimerComplete = () => {
        onSubmitAnswer();
        if (questionData.current_pos === (weekly_challenge_details.no_of_questions - 1)) {
            finalleaderboard();
        } else {
            setShowSpinWheel(false);
            setShowChallengeEnvLoader(false);
            setIsShowLeaderBoard(false);
            setShowAllTeams(false);
            setShowQuestions(false);
            setIsShowFinalLeaderBoard(false);
            setLeaderBoard(true);
        }
        clearInterval(timeInterval);
        timeInterval = null;
        intervalStopper = false;
    };

    const getTeams = async () => {
        const teams = await getAllTeams(challengeDetails.id);
        setAllTeams(teams.data);
        setShowSpinWheel(false);
        setShowChallengeEnvLoader(false);
        setIsShowLeaderBoard(false);
        setIsShowFinalLeaderBoard(false);
        setLeaderBoard(false);
        setShowAllTeams(true);
    }

    const handleHomeButton = () => {
        setShowChallengeCloseAlertModal(true);
    };

    const handleChallengeCloseAlertModalClose = () => setShowChallengeCloseAlertModal(false);
    const handleGoHome = () => {
        if (viewer_channel) {
            viewer_channel.detach();
            //dispatch({ type: 'closeVAblyCon', challenge_id: challengeDetails.id });
            dispatch(closeVAblyConnection({ challenge_id: challengeDetails.id }));
        }
        navigate('/weeklyChallenge');
    };

    const removeTags = (str) => {
        console.log(str);
        if (str === null || str === "") return false;
        else str = str.toString();

        return str.replace(/<[^>]*>/g, "");
    }

    const handleShowOtherTeamsModalOpen = async () => {
        setShowOtherTeamsModal(true);
        const allteams = await getAllTeams(challengeDetails.id);
        setAllTeams(allteams.data);
    }
    const handleShowOtherTeamsModalClose = () => setShowOtherTeamsModal(false);

    const handleLeaderBoardTimerComplete = () => {
        setShowLeaderBoardTimer(false);
    };

    const optionselected = (optionid) => {
        if (optionid !== selectedoption) {
            setSelectedOption(optionid);
        } else {
            setSelectedOption(null);
        }
    };

    const onAnswerChange = (event) => {
        let answer = "";
        answer = event.target.value;
        setAnswer(answer);
    };

    const timealert = async (time, question_data) => {
        let end_timer = time;
        let start_timer = 0;
        let default_time;
        let timer = new Date().getTime();
        if (!timeInterval) {
            timeInterval = setInterval(() => {
                let last_submitted_question = {};
                last_submitted_question["question_id"] = question_data.id;
                console.log("start_time: ", start_timer);
                console.log("end_timer: ", end_timer);
                console.log("question datas : ", question_data);
                console.log("time diff", Math.floor((new Date().getTime() - timer) / 1000));
                if (intervalStopper) {
                    if (weekly_challenge_details.participation_type === 1) {
                        if (question_data.teams.length === 0) {
                            clearInterval(timeInterval);
                            timeInterval = null;
                            intervalStopper = false;
                            return;
                        }
                    } else {
                        if (question_data.participants.length === 0) {
                            clearInterval(timeInterval);
                            timeInterval = null;
                            intervalStopper = false;
                            return;
                        }
                    }
                    if (start_timer === 2) {
                        let submitted_members = [];
                        if (weekly_challenge_details.participation_type === 1) {
                            default_time = question_data.teams[0].modified_at;
                            submitted_members.push(question_data.teams[0]);
                        } else {
                            default_time = question_data.participants[0].modified_at;
                            submitted_members.push(question_data.participants[0]);
                        }
                        console.log("s===", submitted_members);
                        last_submitted_question["submitted_members"] = submitted_members;
                        setLastSubmittedQuestionMembers(last_submitted_question);
                        console.log("submitted members : ", last_submitted_question);
                        console.log("yes!1");
                    }

                    if ((start_timer % 3 === 0) && start_timer <= end_timer) {
                        let submitted_members = [];
                        if (weekly_challenge_details.participation_type === 1) {
                            for (let i = 0; i < question_data.teams.length; i++) {
                                if (Math.floor((new Date(question_data.teams[i].modified_at).getTime() - new Date(default_time).getTime()) / 1000) <= start_timer) {
                                    submitted_members.push(question_data.teams[i]);
                                }
                            }
                        } else {
                            console.log("yes!2");
                            for (let i = 0; i < question_data.participants.length; i++) {
                                console.log("T = ", Math.floor((new Date(question_data.participants[i].modified_at).getTime() - new Date(default_time).getTime()) / 1000));
                                if (Math.floor((new Date(question_data.participants[i].modified_at).getTime() - new Date(default_time).getTime()) / 1000) <= start_timer) {
                                    submitted_members.push(question_data.participants[i]);
                                    console.log("yes 22");
                                }
                            }
                        }
                        last_submitted_question["submitted_members"] = submitted_members;
                        setLastSubmittedQuestionMembers(last_submitted_question);
                        console.log("submitted members : ", last_submitted_question);
                    }
                    if (parseInt(start_timer) >= parseInt(end_timer)) {
                        clearInterval(timeInterval);
                        timeInterval = null;
                        intervalStopper = false;
                        return;
                    }
                }
                start_timer = start_timer + 1;
            }, 1000);
        }
    };
    console.log("lastSubmittedQuestionMembers = ", lastSubmittedQuestionMembers);

    //viewer answering the question
    const onSubmitAnswer = async () => {
        if (selectedoption || answer) {
            let ans = answer.toString("html");
            let option_id;
            if (questionData.field_type === 0) {
                option_id = selectedoption;
                ans = "";
            } else if (questionData.field_type === 1) {
                option_id = "";
                ans = ans.replace(/<\/?[^>]+(>|$)/g, "") ? answer : ""
            }
            let time_taken = Math.round((new Date().getTime() - timetaken) / 1000);
            if (time_taken > parseInt(weekly_challenge_details.time_per_question)) {
                time_taken = parseInt(weekly_challenge_details.time_per_question);
            }

            const submitAnswer = await submitViewerChallengeAnswer(challengeDetails.id, questionData.id, parseInt(option_id), ans, time_taken);

            setSelectedOption(null);
            setAnswer("");
        }

        setSelectedOption(null);
        setAnswer("");


        clearInterval(timeInterval);
        timeInterval = null;
        intervalStopper = false;
    };

    const openchatwidget = () => {
        setShowResults(true);
        setnewmsgrecieved(false);
    }
    const closechatwidget = () => {
        setShowResults(false);
        setnewmsgrecieved(false);
    }

    const msgrecieved = () => {
        setnewmsgrecieved(true);
    };

    //Initiating spin wheel loader
    if (showChallengeEnvLoader) {
        return (
            <div className="row w-100">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="join-lobby-div">
                        <img src="/images/animation_initiating_challenge.gif" className="challenge-environment-gif" />
                        <div className="generatingteams-label" style={{ fontSize: "20px", marginTop: "21px" }}>
                            Initiating viewer environment. <br /> Please wait...
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    //showing spin wheel
    if (showSpinWheel) {
        return (
            <div className="row w-100">

                <Modal show={showOtherTeamsModal} onHide={handleShowOtherTeamsModalClose} id="otherTeamsModal" dialogClassName="otherTeamsModalWidth">
                    <Modal.Header closeButton>
                        <Modal.Title>Teams Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {
                                allTeams.length !== 0
                                    ? allTeams.map(team => (
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            <table className="table table-bordered table-curved" id="teams-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" colSpan="3">
                                                            {team.team_name}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        team.members.length > 0
                                                            ? team.members.map(teamMember => (
                                                                <tr>
                                                                    <td scope="row">
                                                                        {
                                                                            teamMember.faculty_id != ''
                                                                                ? teamMember.is_captain
                                                                                    ? teamMember.faculty_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.faculty_name + " (VC)"
                                                                                        : teamMember.faculty_name
                                                                                : teamMember.is_captain
                                                                                    ? teamMember.student_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.student_name + " (VC)"
                                                                                        : teamMember.student_name
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            : (
                                                                <tr>
                                                                    <td colSpan="3">
                                                                        <span colSpan="3" className="d-flex justify-content-center" id="no-coordinators-msg-label">This Team has no members!</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    ))
                                    : (<p style={{ textAlign: 'center' }}>There are no teams to show!</p>)
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary shadow-sm" onClick={handleShowOtherTeamsModalClose} id="closeErrorAlertModalBtn" >
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="join-lobby-div">
                        <h2 id="challenge-title">{challengeDetails.title}</h2>
                        <div className="viewer-question-position">Question: {questionPosition + 1}</div>
                        <div>
                            <Wheel
                                topic_data={spinWheelData}
                                topicselected={selectedTopic}
                            />
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginTop: "-150px" }}>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div class="chat-icon-teams-icon">
                            <div style={{ marginLeft: "2.5%" }}>
                                {
                                    weekly_challenge_details.participation_type === 1
                                        ? (
                                            <div style={{ marginLeft: "25px" }}>
                                                <RiTeamLine className="teams-icon" onClick={handleShowOtherTeamsModalOpen} style={{ cursor: 'pointer' }} /><br />
                                                <span>All Teams</span>
                                            </div>
                                        )
                                        : ('')
                                }
                            </div>

                            <div style={{ marginRight: "2.5%" }}>
                                <div
                                    style={{
                                        float: "right",
                                        cursor: "pointer",
                                        position: "relative",
                                        bottom: "0px",
                                        left: "-10px",
                                    }}
                                    onClick={openchatwidget}
                                    title={`Chat with audience`}
                                >
                                    {newmsgrecieved ? (
                                        <img
                                            src="/images/new-message-recieved.gif"
                                            style={{
                                                position: "absolute",
                                                top: "-10px",
                                                left: "-10px",
                                                width: "30px",
                                                height: "30px"
                                            }}
                                        ></img>
                                    ) : null}
                                    <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
                                    <div>{`Audience`}</div>
                                </div>
                                <div
                                    className={showResults ? "fadeIn" : "fadeOut"}
                                    id="supportmonboxid"
                                >
                                    <div
                                        className="supportmonbox_head col-md-12 col-sm-12"
                                        id="supbox"
                                        style={{ display: "block", height: "41px" }}
                                    >
                                        <div className="col-xs-1" style={{ float: "right" }}>
                                            <AiFillCloseCircle
                                                onClick={closechatwidget}
                                                style={{ fontSize: "26px", cursor: "pointer" }}
                                            />
                                        </div>
                                    </div>

                                    <Chatwidget2
                                        roomid={`${challengeDetails.id}_Viewer`}
                                        foundnewmsg={msgrecieved}
                                        deletechat={false}
                                    />
                                </div>{" "}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //showing spin wheel loader
    if (leaderBoardLoader) {
        return (
            <div className="row w-100">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="join-lobby-div">
                        <img src="/images/animation_waiting_loader.gif" className="challenge-environment-gif" />
                        <div className="generatingteams-label" style={{ fontSize: "20px", marginTop: "21px" }}>
                            Generating Leaderboard. Please wait!
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (showAllTeams) {
        return (
            <div className="row w-100">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="join-lobby-div">
                        <h2 id="challenge-title">{challengeDetails.title}</h2>
                        <h3 style={{ textAlign: 'center' }}>Teams Information</h3>
                        <div className="row">
                            <div className="col-lg-2 col-sm-2 col-md-2"></div>
                            <div className="col-lg-8 col-sm-8 col-md-8">
                                <div className="row">
                                    {
                                        allTeams.length != 0
                                            ? allTeams.map(team => (
                                                <div className="col-lg-4 col-md-4 col-sm-4">
                                                    <table className="table table-bordered table-curved" id="general-coordinators-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">
                                                                    Team Name : {team.team_name}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                team.members.length > 0
                                                                    ? team.members.map(teamMember => (
                                                                        <tr>
                                                                            <td scope="row">
                                                                                {
                                                                                    teamMember.faculty_id != ''
                                                                                        ? teamMember.is_captain
                                                                                            ? teamMember.faculty_name + " (C)"
                                                                                            : teamMember.is_vice_captain
                                                                                                ? teamMember.faculty_name + " (VC)"
                                                                                                : teamMember.faculty_name
                                                                                        : teamMember.is_captain
                                                                                            ? teamMember.student_name + " (C)"
                                                                                            : teamMember.is_vice_captain
                                                                                                ? teamMember.student_name + " (VC)"
                                                                                                : teamMember.student_name
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                    : (
                                                                        <tr>
                                                                            <td colSpan="3">
                                                                                <span colSpan="3" className="d-flex justify-content-center" id="no-coordinators-msg-label">This Team has no members!</span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ))
                                            : (<span className="d-flex justify-content-center" id="no-coordinators-msg-label">Teams has not generated yet!</span>)
                                    }
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-2 col-md-2"></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div class="chat-icon-teams-icon">
                            <div>
                            </div>

                            <div>
                                <div
                                    style={{
                                        float: "right",
                                        cursor: "pointer",
                                        position: "relative",
                                        bottom: "0px",
                                        left: "-10px",
                                    }}
                                    onClick={openchatwidget}
                                    title={`Chat with audience`}
                                >
                                    {newmsgrecieved ? (
                                        <img
                                            src="/images/new-message-recieved.gif"
                                            style={{
                                                position: "absolute",
                                                top: "-10px",
                                                left: "-10px",
                                                width: "30px",
                                                height: "30px"
                                            }}
                                        ></img>
                                    ) : null}
                                    <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
                                    <div>{`Audience`}</div>
                                </div>
                                <div
                                    className={showResults ? "fadeIn" : "fadeOut"}
                                    id="supportmonboxid"
                                >
                                    <div
                                        className="supportmonbox_head col-md-12 col-sm-12"
                                        id="supbox"
                                        style={{ display: "block", height: "41px" }}
                                    >
                                        <div className="col-xs-1" style={{ float: "right" }}>
                                            <AiFillCloseCircle
                                                onClick={closechatwidget}
                                                style={{ fontSize: "26px", cursor: "pointer" }}
                                            />
                                        </div>
                                    </div>

                                    <Chatwidget2
                                        roomid={`${challengeDetails.id}_Viewer`}
                                        foundnewmsg={msgrecieved}
                                        deletechat={false}
                                    />
                                </div>{" "}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }


    //showing leader board
    if (isShowLeaderBoard) {
        return (
            <div className="row w-100" style={{ background: `linear-gradient(to right, rgb(253, 243, 194), rgb(255, 194, 213))`, paddingBottom: "55px", marginLeft: "0px" }}>

                <Modal show={showOtherTeamsModal} onHide={handleShowOtherTeamsModalClose} id="otherTeamsModal" dialogClassName="otherTeamsModalWidth">
                    <Modal.Header closeButton>
                        <Modal.Title>Teams Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {
                                allTeams.length !== 0
                                    ? allTeams.map(team => (
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            <table className="table table-bordered table-curved" id="teams-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" colSpan="3">
                                                            {team.team_name}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        team.members.length > 0
                                                            ? team.members.map(teamMember => (
                                                                <tr>
                                                                    <td scope="row">
                                                                        {
                                                                            teamMember.faculty_id != ''
                                                                                ? teamMember.is_captain
                                                                                    ? teamMember.faculty_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.faculty_name + " (VC)"
                                                                                        : teamMember.faculty_name
                                                                                : teamMember.is_captain
                                                                                    ? teamMember.student_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.student_name + " (VC)"
                                                                                        : teamMember.student_name
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            : (
                                                                <tr>
                                                                    <td colSpan="3">
                                                                        <span colSpan="3" className="d-flex justify-content-center" id="no-coordinators-msg-label">This Team has no members!</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    ))
                                    : (<p style={{ textAlign: 'center' }}>There are no teams to show!</p>)
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary shadow-sm" onClick={handleShowOtherTeamsModalClose} id="closeErrorAlertModalBtn" >
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="join-lobby-div">
                        <h2 id="challenge-title">{challengeDetails.title}</h2>
                        <div className="leaderboard-timer">
                            {
                                showLeaderBoardTimer && (
                                    <ReactCountdownClock
                                        seconds={challengeDetails.leaderBoard_timer}
                                        alpha={0.9}
                                        color="red"
                                        size={70}
                                        weight={5}
                                        showMilliseconds={false}
                                        onComplete={handleLeaderBoardTimerComplete}
                                    />
                                )
                            }
                        </div>
                        <div style={{ height: "500px", overflowY: "auto", overflowX: "hidden" }}>
                            {
                                weekly_challenge_details.random_questions === 1
                                    ? (<LeaderBoard data={leaderBoardData} current_question_id={questionData.id} question_index={questionPosition - 1} myTeam_id="" user_type="viewer" type="running" challenge_details={weekly_challenge_details} />)
                                    : (<LeaderBoard data={leaderBoardData} current_question_id={questionData.id} question_index={questionPosition} myTeam_id="" user_type="viewer" type="running" challenge_details={weekly_challenge_details} />)
                            }
                        </div>
                    </div>
                </div>

                <div className="row" style={{ marginTop: "-150px" }}>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div class="chat-icon-teams-icon">
                            <div style={{ marginLeft: "2.5%" }}>
                                {
                                    weekly_challenge_details.participation_type === 1
                                        ? (
                                            <div style={{ marginLeft: "25px" }}>
                                                <RiTeamLine className="teams-icon" onClick={handleShowOtherTeamsModalOpen} style={{ cursor: 'pointer' }} /><br />
                                                <span>All Teams</span>
                                            </div>
                                        )
                                        : ('')
                                }
                            </div>

                            <div style={{ marginRight: "2.5%" }}>
                                <div
                                    style={{
                                        float: "right",
                                        cursor: "pointer",
                                        position: "relative",
                                        bottom: "0px",
                                        left: "-10px",
                                    }}
                                    onClick={openchatwidget}
                                    title={`Chat with audience`}
                                >
                                    {newmsgrecieved ? (
                                        <img
                                            src="/images/new-message-recieved.gif"
                                            style={{
                                                position: "absolute",
                                                top: "-10px",
                                                left: "-10px",
                                                width: "30px",
                                                height: "30px"
                                            }}
                                        ></img>
                                    ) : null}
                                    <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
                                    <div>{`Audience`}</div>
                                </div>
                                <div
                                    className={showResults ? "fadeIn" : "fadeOut"}
                                    id="supportmonboxid"
                                >
                                    <div
                                        className="supportmonbox_head col-md-12 col-sm-12"
                                        id="supbox"
                                        style={{ display: "block", height: "41px" }}
                                    >
                                        <div className="col-xs-1" style={{ float: "right" }}>
                                            <AiFillCloseCircle
                                                onClick={closechatwidget}
                                                style={{ fontSize: "26px", cursor: "pointer" }}
                                            />
                                        </div>
                                    </div>

                                    <Chatwidget2
                                        roomid={`${challengeDetails.id}_Viewer`}
                                        foundnewmsg={msgrecieved}
                                        deletechat={false}
                                    />
                                </div>{" "}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    //showing final leader board
    if (isShowFinalLeaderBoard) {
        return (
            <div className="row w-100" style={{ backgroundImage: `url('/images/animation_final_leaderboard.gif')`, marginLeft: "0px" }}>
                <Modal show={showChallengeCloseAlertModal} onHide={handleChallengeCloseAlertModalClose} scrollable id="challengeCloseAlertModal" dialogClassName="challengeCustomAlertmodalWidth">
                    <Modal.Body>
                        <div>
                            <img src="/images/thankyou-animation.gif" className="thankyou-animation" />
                            <span className="d-flex justify-content-center" id="challenge-custom-msg-label"><strong>Arena Completed Successfully. Hope you enjoyed the Arena!</strong></span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleGoHome} id="modalHomeBtn">
                            Continue
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showOtherTeamsModal} onHide={handleShowOtherTeamsModalClose} id="otherTeamsModal" dialogClassName="otherTeamsModalWidth">
                    <Modal.Header closeButton>
                        <Modal.Title>Teams Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {
                                allTeams.length !== 0
                                    ? allTeams.map(team => (
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            <table className="table table-bordered table-curved" id="teams-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" colSpan="3">
                                                            {team.team_name}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        team.members.length > 0
                                                            ? team.members.map(teamMember => (
                                                                <tr>
                                                                    <td scope="row">
                                                                        {
                                                                            teamMember.faculty_id != ''
                                                                                ? teamMember.is_captain
                                                                                    ? teamMember.faculty_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.faculty_name + " (VC)"
                                                                                        : teamMember.faculty_name
                                                                                : teamMember.is_captain
                                                                                    ? teamMember.student_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.student_name + " (VC)"
                                                                                        : teamMember.student_name
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            : (
                                                                <tr>
                                                                    <td colSpan="3">
                                                                        <span colSpan="3" className="d-flex justify-content-center" id="no-coordinators-msg-label">This Team has no members!</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    ))
                                    : (<p style={{ textAlign: 'center' }}>There are no teams to show!</p>)
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary shadow-sm" onClick={handleShowOtherTeamsModalClose} id="closeErrorAlertModalBtn" >
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="join-lobby-div">
                        <h2 id="challenge-title">{challengeDetails.title}</h2>
                        <button className="btn btn-primary" id="go-to-home-btn" onClick={handleHomeButton}>Home <FaLongArrowAltRight /></button>
                        <p style={{ textAlign: 'center', fontSize: '1.5em' }}><strong>Final LeaderBoard</strong></p>
                        <div style={{ height: "500px", overflowY: "auto", overflowX: "hidden" }}>
                            {
                                weekly_challenge_details.random_questions === 1
                                    ? (<LeaderBoard data={leaderBoardData} current_question_id={questionData.id} question_index={questionPosition - 1} myTeam_id="" user_type="viewer" type="running" challenge_details={weekly_challenge_details} />)
                                    : (<LeaderBoard data={leaderBoardData} current_question_id={questionData.id} question_index={questionPosition} myTeam_id="" user_type="viewer" type="running" challenge_details={weekly_challenge_details} />)
                            }
                        </div>
                    </div>
                </div>

                <div className="row" style={{ marginTop: "-150px" }}>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div class="chat-icon-teams-icon">
                            <div style={{ marginLeft: "2.5%" }}>
                                {
                                    weekly_challenge_details.participation_type === 1
                                        ? (
                                            <div style={{ marginLeft: "25px" }}>
                                                <RiTeamLine className="teams-icon" onClick={handleShowOtherTeamsModalOpen} style={{ cursor: 'pointer' }} /><br />
                                                <span>All Teams</span>
                                            </div>
                                        )
                                        : ('')
                                }
                            </div>

                            <div style={{ marginRight: "2.5%" }}>
                                <div
                                    style={{
                                        float: "right",
                                        cursor: "pointer",
                                        position: "relative",
                                        bottom: "0px",
                                        left: "-10px",
                                    }}
                                    onClick={openchatwidget}
                                    title={`Chat with audience`}
                                >
                                    {newmsgrecieved ? (
                                        <img
                                            src="/images/new-message-recieved.gif"
                                            style={{
                                                position: "absolute",
                                                top: "-10px",
                                                left: "-10px",
                                                width: "30px",
                                                height: "30px"
                                            }}
                                        ></img>
                                    ) : null}
                                    <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
                                    <div>{`Audience`}</div>
                                </div>
                                <div
                                    className={showResults ? "fadeIn" : "fadeOut"}
                                    id="supportmonboxid"
                                >
                                    <div
                                        className="supportmonbox_head col-md-12 col-sm-12"
                                        id="supbox"
                                        style={{ display: "block", height: "41px" }}
                                    >
                                        <div className="col-xs-1" style={{ float: "right" }}>
                                            <AiFillCloseCircle
                                                onClick={closechatwidget}
                                                style={{ fontSize: "26px", cursor: "pointer" }}
                                            />
                                        </div>
                                    </div>

                                    <Chatwidget2
                                        roomid={`${challengeDetails.id}_Viewer`}
                                        foundnewmsg={msgrecieved}
                                        deletechat={false}
                                    />
                                </div>{" "}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }



    //showing questions
    if (showQuestions) {
        let showsubmitoption;
        let submit = "cursor";
        let autosubmitans = (
            <ReactCountdownClock
                seconds={parseInt(weekly_challenge_details.time_per_question)}
                alpha={0.9}
                color="red"
                size={70}
                weight={5}
                showMilliseconds={false}
                onComplete={handleQuestionTimerComplete}
            />
        );
        console.log("000");
        console.log(weekly_challenge_details.participation_type, challengeDetails.is_member, challengeDetails.is_viewer);
        if (
            weekly_challenge_details.participation_type === 0 &&
            challengeDetails.is_member === 0 &&
            challengeDetails.is_viewer === 1
        ) {
            console.log("003");
            submit = "cursor";
            showsubmitoption = (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                        className="btn btn-danger"
                        style={{ marginTop: "7px" }}
                    >
                        Submit Answer
                    </button>
                </div>
            );
            autosubmitans = (
                <ReactCountdownClock
                    seconds={parseInt(weekly_challenge_details.time_per_question)}
                    alpha={0.9}
                    color="red"
                    size={70}
                    weight={5}
                    showMilliseconds={false}
                    onComplete={handleQuestionTimerComplete}
                />
            );
        }
        let hasimageoption = false;
        if (questionData.field_type == 0) {
            questionData.options.map(({ id, option, media }, index) => {
                if (media) {
                    hasimageoption = true;
                }
            });
        }

        return (
            <div className="row w-100 mb-3">

                <Modal show={showOtherTeamsModal} onHide={handleShowOtherTeamsModalClose} id="otherTeamsModal" dialogClassName="otherTeamsModalWidth">
                    <Modal.Header closeButton>
                        <Modal.Title>Teams Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {
                                allTeams.length !== 0
                                    ? allTeams.map(team => (
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            <table className="table table-bordered table-curved" id="teams-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" colSpan="3">
                                                            {team.team_name}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        team.members.length > 0
                                                            ? team.members.map(teamMember => (
                                                                <tr>
                                                                    <td scope="row">
                                                                        {
                                                                            teamMember.faculty_id != ''
                                                                                ? teamMember.is_captain
                                                                                    ? teamMember.faculty_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.faculty_name + " (VC)"
                                                                                        : teamMember.faculty_name
                                                                                : teamMember.is_captain
                                                                                    ? teamMember.student_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.student_name + " (VC)"
                                                                                        : teamMember.student_name
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            : (
                                                                <tr>
                                                                    <td colSpan="3">
                                                                        <span colSpan="3" className="d-flex justify-content-center" id="no-coordinators-msg-label">This Team has no members!</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    ))
                                    : (<p style={{ textAlign: 'center' }}>There are no teams to show!</p>)
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary shadow-sm" onClick={handleShowOtherTeamsModalClose} id="closeErrorAlertModalBtn" >
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="join-lobby-div" style={{ marginBottom: "20px" }}>
                        <div className="row">
                            <div className="col-lg-10 col-md-10 col-sm-9">
                                <h2 style={{ marginLeft: '10%' }} id="challenge-title">{challengeDetails.title}</h2>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-3">
                                <div style={{ float: 'right' }}>
                                    {autosubmitans}
                                </div>
                            </div>
                        </div>

                        <div className="questionsection" style={{ display: "flex" }}>
                            <div className="classdiv" dir="auto">
                                <span role="heading" aria-level="1">
                                    {questionData.current_pos + 1}. {removeTags(questionData.question)}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8">
                                <div className="viewer-verticalline"></div>
                                <div>
                                    {
                                        lastSubmittedQuestionMembers && (
                                            <>
                                                {
                                                    questionData.field_type == 0 ? (
                                                        <div className="answersdiv_withimage" style={{ height: "350px", overflowY: "auto", overflowX: "hidden" }}>
                                                            {questionData.options.map(
                                                                ({ id, option, media }, index) => {
                                                                    let member_count = 0;
                                                                    for (let i = 0; i < lastSubmittedQuestionMembers.submitted_members.length; i++) {
                                                                        if (id === lastSubmittedQuestionMembers.submitted_members[i].option_id) {
                                                                            member_count = member_count + 1;
                                                                        }
                                                                    }

                                                                    return (
                                                                        <div className="row" style={{ display: "flex", alignItems: "center" }}>
                                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                                <div
                                                                                    style={{
                                                                                        borderTop: `3px dashed ${optionbgcolors[index]}`,
                                                                                        margin: "40px 10px 2px -38px",
                                                                                        width: "9%",
                                                                                    }}
                                                                                ></div>
                                                                                <div
                                                                                    color="#e21b3c"
                                                                                    id={member_count > 0 ? "viewer-answermaindiv2" : "viewer-answermaindiv"}
                                                                                    style={{ pointerEvents: `${submit}`, width: `${((member_count / submittedQuestionOrderDataLen) * 89) + 5}%`, transition: "2s" }}
                                                                                >
                                                                                    <div className="">
                                                                                        <div
                                                                                            className="card-icon__icon"
                                                                                            style={{
                                                                                                backgroundColor: "white",
                                                                                                opacity: "0.9",
                                                                                                borderRight: "1px solid #e6e6e6",
                                                                                                borderRadius: "none"
                                                                                            }}
                                                                                        >
                                                                                            {options[index]}
                                                                                        </div>
                                                                                    </div>
                                                                                    <label
                                                                                        aria-live="off"
                                                                                        dir="auto"
                                                                                        className="answertext"
                                                                                        style={{ textAlign: "right" }}
                                                                                    >
                                                                                        {
                                                                                            member_count > 0
                                                                                                ? `${parseFloat(((member_count / submittedQuestionOrderDataLen) * 100).toFixed(2))}%`
                                                                                                : "0"
                                                                                        }
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="sub-answers">
                                                            <div><label><strong>Submitted Answers:</strong></label></div><br />
                                                            {
                                                                lastSubmittedQuestionMembers.submitted_members.map(subAnswer => (
                                                                    <span className="viewer-sub-answer-div">{subAnswer.answer}</span>
                                                                ))
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        !lastSubmittedQuestionMembers && (
                                            <>
                                                {
                                                    questionData.field_type == 0 ? (
                                                        <div className="answersdiv_withimage" style={{ height: "350px", overflowY: "auto", overflowX: "hidden" }}>
                                                            {questionData.options.map(
                                                                ({ id, option, media }, index) => {
                                                                    let member_count = 0;
                                                                    return (
                                                                        <div className="row" style={{ display: "flex", alignItems: "center" }}>
                                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                                <div
                                                                                    style={{
                                                                                        borderTop: `3px dashed ${optionbgcolors[index]}`,
                                                                                        margin: "40px 10px 2px -38px",
                                                                                        width: "9%",
                                                                                    }}
                                                                                ></div>
                                                                                <div
                                                                                    color="#e21b3c"
                                                                                    className="viewer-answermaindiv"
                                                                                    style={{ pointerEvents: `${submit}`, width: "5%", transition: "2s" }}
                                                                                >
                                                                                    <div className="">
                                                                                        <div
                                                                                            className="card-icon__icon"
                                                                                            style={{
                                                                                                backgroundColor: "white",
                                                                                                opacity: "0.9",
                                                                                                borderRight: "1px solid #e6e6e6",
                                                                                                borderRadius: "none"
                                                                                            }}
                                                                                        >
                                                                                            {options[index]}
                                                                                        </div>
                                                                                    </div>
                                                                                    <label
                                                                                        aria-live="off"
                                                                                        dir="auto"
                                                                                        className="answertext"
                                                                                        style={{ textAlign: "right" }}
                                                                                    >
                                                                                        0
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    ) : ""
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                {
                                    questionData.question_img
                                        ? (
                                            <div className="question-image-div" style={{ height: '350px', overflowY: 'auto', width: '100%', marginTop: '10px' }}>
                                                {
                                                    img_extensions.includes(questionData.question_img.split('.').pop().toLowerCase())
                                                        ? (
                                                            <img src={questionData.question_img} className="question-image" alt="question picture" />
                                                        )
                                                        : video_extensions.includes(questionData.question_img.split('.').pop().toLowerCase())
                                                            ? (
                                                                <video alt="question video" controls style={{ width: "80%", marginLeft: "10%", marginTop: "2.5%" }}>
                                                                    <source src={questionData.question_img} type={`video/${questionData.question_img.split('.').pop().toLowerCase()}`} loop={true} />
                                                                </video>
                                                            )
                                                            : (
                                                                <audio controls style={{ marginTop: "25%" }}>
                                                                    {
                                                                        questionData.question_img.split('.').pop().toLowerCase() === "mp3"
                                                                            ? (<source src={questionData.question_img} type="audio/mpeg" />)
                                                                            : (<source src={questionData.question_img} type={`audio/${questionData.question_img.split('.').pop().toLowerCase()}`} />)
                                                                    }
                                                                    Your browser does not support the audio element.
                                                                </audio>
                                                            )
                                                }
                                            </div>
                                        )
                                        : ""
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-1 col-md-1 col-sm-1"></div>
                            <div className="col-lg-11 col-md-11 col-sm-11">
                                <div><strong>What do you think is the correct answer?</strong></div>
                                {/* {
                                    questionData.field_type == 0
                                        ? (<div style={{ color: "red" }}>Note: Selecting an option will submit the answer</div>)
                                        : ""
                                } */}
                                {questionData.field_type == 0 && !hasimageoption ? (
                                    <div className="row answersdiv_withimage" style={{ marginTop: "50px" }}>
                                        {questionData.options.map(
                                            ({ id, option, media }, index) => {
                                                const isselected = selectedoption === id;
                                                const classname =
                                                    selectedoption && !isselected
                                                        ? "boxshadow answermaindiv"
                                                        : selectedoption && isselected
                                                            ? "boxshadow1 answermaindiv"
                                                            : "answermaindiv";
                                                console.log(classname);
                                                if (id == selectedoption) {
                                                    let isselected = true;
                                                }
                                                return (
                                                    <div className="col-lg-3 col-md-3 col-sm-3" style={{ display: "flex", alignItems: "center" }}>
                                                        <div
                                                            color="#e21b3c"
                                                            className={classname}
                                                            onClick={() => optionselected(id)}
                                                            style={{ pointerEvents: `${submit}` }}
                                                        >
                                                            <div className="">
                                                                <div
                                                                    className="card-icon__icon"
                                                                    style={{
                                                                        background: `${optionbgcolors[index]}`,
                                                                        opacity: "0.9",
                                                                        fontSize: "1.2em"
                                                                    }}
                                                                >
                                                                    {options[index]}
                                                                </div>
                                                            </div>
                                                            <span
                                                                aria-live="off"
                                                                dir="auto"
                                                                className="answertext"
                                                                style={{ fontSize: "1.1em" }}
                                                            >
                                                                <span>{removeTags(option)}</span>
                                                            </span>
                                                            {isselected ? (
                                                                <AiFillLock
                                                                    style={{ color: "green", fontSize: "25px" }}
                                                                />
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                ) : questionData.field_type == 0 && hasimageoption ? (
                                    <div className="row answersdiv_withimage" style={{ marginTop: "50px" }}>
                                        {questionData.options.map(
                                            ({ id, option, media }, index) => {
                                                const isselected = selectedoption === id;
                                                const classname =
                                                    selectedoption && !isselected
                                                        ? "boxshadow answermaindiv"
                                                        : selectedoption && isselected
                                                            ? "boxshadow1 answermaindiv"
                                                            : "answermaindiv";
                                                console.log(classname);
                                                if (id == selectedoption) {
                                                    let isselected = true;
                                                }
                                                return (
                                                    <div className="col-lg-3 col-md-3 col-sm-3">
                                                        <div
                                                            color="#e21b3c"
                                                            className={classname}
                                                            onClick={() => optionselected(id)}
                                                            style={{ pointerEvents: `${submit}` }}
                                                        >
                                                            <div className="">
                                                                <div
                                                                    className="card-icon__icon"
                                                                    style={{
                                                                        background: `${optionbgcolors[index]}`,
                                                                        opacity: "0.9",
                                                                    }}
                                                                >
                                                                    {options[index]}
                                                                </div>
                                                            </div>
                                                            <span
                                                                aria-live="off"
                                                                dir="auto"
                                                                className="answertext"
                                                            >
                                                                <span>{removeTags(option)}</span>
                                                            </span>
                                                            {isselected ? (
                                                                <AiFillLock
                                                                    style={{ color: "green", fontSize: "25px" }}
                                                                />
                                                            ) : null}
                                                        </div>
                                                        <div className="col-lg-12 col-sm-12 col-md-12">
                                                            <img src={media} style={{ width: "100%", height: "150px" }} />
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                ) : (
                                    <div className="viewer-answersdiv">
                                        <input type="text"
                                            placeholder="write..."
                                            value={answer}
                                            onChange={onAnswerChange}
                                            style={{ padding: "5px", borderRadius: "5px", borderColor: "green" }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>


                    </div>
                </div>

                <div>
                    <div>
                        <div class="chat-icon-teams-icon2">
                            <div style={{ marginLeft: "2.5%" }}>
                                {
                                    weekly_challenge_details.participation_type === 1
                                        ? (
                                            <div style={{ marginLeft: "25px" }}>
                                                <RiTeamLine className="teams-icon" onClick={handleShowOtherTeamsModalOpen} style={{ cursor: 'pointer' }} /><br />
                                                <span>All Teams</span>
                                            </div>
                                        )
                                        : ('')
                                }
                            </div>

                            <div style={{ marginRight: "2.5%" }}>
                                <div
                                    style={{
                                        float: "right",
                                        cursor: "pointer",
                                        position: "relative",
                                        bottom: "0px",
                                        left: "-10px",
                                    }}
                                    onClick={openchatwidget}
                                    title={`Chat with audience`}
                                >
                                    {newmsgrecieved ? (
                                        <img
                                            src="/images/new-message-recieved.gif"
                                            style={{
                                                position: "absolute",
                                                top: "-10px",
                                                left: "-10px",
                                                width: "30px",
                                                height: "30px"
                                            }}
                                        ></img>
                                    ) : null}
                                    <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
                                    <div>{`Audience`}</div>
                                </div>
                                <div
                                    className={showResults ? "fadeIn" : "fadeOut"}
                                    id="supportmonboxid"
                                >
                                    <div
                                        className="supportmonbox_head col-md-12 col-sm-12"
                                        id="supbox"
                                        style={{ display: "block", height: "41px" }}
                                    >
                                        <div className="col-xs-1" style={{ float: "right" }}>
                                            <AiFillCloseCircle
                                                onClick={closechatwidget}
                                                style={{ fontSize: "26px", cursor: "pointer" }}
                                            />
                                        </div>
                                    </div>

                                    <Chatwidget2
                                        roomid={`${challengeDetails.id}_Viewer`}
                                        foundnewmsg={msgrecieved}
                                        deletechat={false}
                                    />
                                </div>{" "}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}

export default ViewChallenge;