import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography, Divider, Button, Grid, TextField, Avatar } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import { BsDownload, BsDot } from "react-icons/bs";
import Select from "react-select";
import * as XLSX from 'xlsx';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import Navbar from "../../navbar/navbar.component";
import LayoutwithSideNav from "../../common/LayoutwithSideNav";
import { getFeedbackResults, generateGeneralFeedbackReports } from "../../../api/facultyfeedbackapis";
import "./feedbackResults.styles.css";
import GeneralPdfDocument from "./generalReportsDownload";
import DropDown from "../../common/DropDown";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from '@mui/system/styled';

const FeedbackGeneralResults = () => {
    const location = useLocation();
    const [feedbackResultsList, setFeedbackResultsList] = useState([]);
    const [allFeedbackResultsList, setAllFeedbackResultsList] = useState([]);
    const [generalFeedbackResultsList, setgeneralFeedbackResultsList] = useState([]);
    const [nonGeneralFeedbackResultsList, setNonGeneralFeedbackResultsList] = useState([]);
    const [allgeneralFeedbackResultsList, setAllgeneralFeedbackResultsList] = useState([]);
    const [flag, setFlag] = useState(true);
    const [feedbackResultsFlag, setFeedbackResultsFlag] = useState(false);
    const [feedbackDownloadingFlag, setFeedbackDownloadingFlag] = useState(false);
    const [filterByTypeList, setFilterByTypeList] = useState([
        { value: 1, name: "All" },
        { value: 2, name: "Department" },
        // { value: 3, name: "Subject" },
        { value: 4, name: "Faculty" },
        { value: 5, name: "Section" },
        { value: 6, name: "General Amenities" },
    ]);
    const [filterByValueList, setFilterByValueList] = useState([]);
    const [filterTypeValue, setFilterTypeValue] = useState(1);
    const [selectedValue, setSelectedValue] = useState(null);

    const styles = {
        button: {
            textTransform: 'none', // Disable capitalization
            fontSize: '1.1rem',
            maxWidth: "150px",
            float: "right"
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            marginTop: "15px",
            fontSize: "1.15rem",
            marginBottom: "7.5px"
        },
        redText: {
            color: 'red',
            position: 'relative',
            top: '-0.1em',
        },
        dept_name: {
            fontWeight: 600,
            marginTop: "20px"
        },
        divider1: {
            width: "100%",
            height: "1px",
            textAlign: "start",
            ml: 0,
            mt: 1,
            backgroundColor: "#e6e6e6",
            opacity: "100%",
        },
        divider2: {
            width: "100%",
            height: "0.2px",
            textAlign: "start",
            ml: 0,
            mt: 2,
            backgroundColor: "#e6e6e6",
            opacity: "50%",
        },
        dept_name_label: {
            width: "100%",
            backgroundColor: "#FFDFD6",
            borderTopRightRadius: "15px",
            borderTopLeftRadius: "15px",
            padding: "8px",
            fontWeight: 600,
        },
        section_scrollable_div: {
            maxHeight: "200px",
            minHeight: "120px",
            overflowY: "auto",
            padding: "5px",
        },
        donwloadResultsBtn: {
            width: '200px',
            float: 'right',
        },
        label_name: {
            fontWeight: 500,
            fontSize: "1.05rem",
            float: 'left',
            marginRight: '5px'
        },
        label_value: {
            fontSize: "0.9rem",
            marginTop: "3px"
        }
    };

    useEffect(() => {
        if (flag) {
            getAllFeedbackResults();
        }
    }, [flag]);

    const getAllFeedbackResults = async () => {
        setFlag(false);
        let feedbackResults;
        if (location.state.feedback_type === "master") {
            feedbackResults = await generateGeneralFeedbackReports(location.state.master_feedback_id);
            console.log(feedbackResults);
        } else {
            // feedbackResults = await getFeedbackResults("feedback_id", location.state.sub_feedback_id);
        }
        let result = [];
        if (feedbackResults.data.result.feedbacks.length > 0) {
            for (let i = 0; i < feedbackResults.data.result.feedbacks.length; i++) {
                result.push(feedbackResults.data.result.feedbacks[i]);
            }
        }
        if (feedbackResults.data.result.general_feedbacks.length > 0) {
            for (let i = 0; i < feedbackResults.data.result.general_feedbacks.length; i++) {
                if (feedbackResults.data.result.general_feedbacks[i].questions.length != 0) {
                    result.push(feedbackResults.data.result.general_feedbacks[i]);
                }
            }
        }
        setFeedbackResultsList(result);
        setAllFeedbackResultsList(result);
        setNonGeneralFeedbackResultsList(feedbackResults.data.result.feedbacks);
        setgeneralFeedbackResultsList(feedbackResults.data.result.general_feedbacks);
        setAllgeneralFeedbackResultsList(feedbackResults.data.result.general_feedbacks);
        setTimeout(() => {
            setFeedbackResultsFlag(true);
        }, 3000);
    };

    const handleFilterByCategory = (e) => {
        console.log("-> ", allFeedbackResultsList);
        setSelectedValue(null);
        if (e.target.value === 1) {
            setFeedbackResultsList(allFeedbackResultsList);
            setFilterByValueList([]);
        } else if (e.target.value === 2) {
            let results = [];
            for (let i = 0; i < allFeedbackResultsList.length; i++) {
                if (allFeedbackResultsList[i]?.faculty_id) {
                    for (let j = 0; j < allFeedbackResultsList[i].feedbacks.length; j++) {
                        //check value existed
                        let flag = 1;
                        for (let k = 0; k < results.length; k++) {
                            if (results[k].value === allFeedbackResultsList[i].feedbacks[j].branch_name) {
                                flag = 0;
                                break;
                            }
                        }
                        if (flag) {
                            results.push({ value: allFeedbackResultsList[i].feedbacks[j].branch_name, name: allFeedbackResultsList[i].feedbacks[j].branch_name })
                        }
                    }
                } else {
                    //check value existed
                    let flag = 1;
                    for (let k = 0; k < results.length; k++) {
                        if (results[k].value === allFeedbackResultsList[i].branch_name) {
                            flag = 0;
                            break;
                        }
                    }
                    if (flag) {
                        results.push({ value: allFeedbackResultsList[i].branch_name, name: allFeedbackResultsList[i].branch_name })
                    }
                }
            }
            console.log("2 -> ", results);
            setFilterByValueList([]);
            setFilterByValueList(results);
        } else if (e.target.value === 3) {
            let results = [];
            for (let i = 0; i < allFeedbackResultsList.length; i++) {
                if (allFeedbackResultsList[i]?.faculty_id) {
                    for (let j = 0; j < allFeedbackResultsList[i].feedbacks.length; j++) {
                        //check value existed
                        if (allFeedbackResultsList[i].feedbacks[j]?.subject_name) {
                            let flag = 1;
                            for (let k = 0; k < results.length; k++) {
                                if (results[k].value === allFeedbackResultsList[i].feedbacks[j].subject_name) {
                                    flag = 0;
                                    break;
                                }
                            }
                            if (flag) {
                                results.push({ value: allFeedbackResultsList[i].feedbacks[j].subject_name, name: allFeedbackResultsList[i].feedbacks[j].subject_name })
                            }
                        }
                    }
                }
            }
            setFilterByValueList([]);
            setFilterByValueList(results);
        } else if (e.target.value === 4) {
            let results = [];
            for (let i = 0; i < allFeedbackResultsList.length; i++) {
                if (allFeedbackResultsList[i]?.faculty_id) {
                    let flag = 1;
                    for (let k = 0; k < results.length; k++) {
                        if (results[k].value === allFeedbackResultsList[i].faculty_id) {
                            flag = 0;
                            break;
                        }
                    }
                    if (flag) {
                        results.push({ value: allFeedbackResultsList[i].faculty_id, name: allFeedbackResultsList[i].faculty_name });
                    }
                }
            }
            setFilterByValueList([]);
            setFilterByValueList(results);
        } else if (e.target.value === 5) {
            let results = [];
            for (let i = 0; i < allFeedbackResultsList.length; i++) {
                if (allFeedbackResultsList[i]?.faculty_id) {
                    for (let j = 0; j < allFeedbackResultsList[i].feedbacks.length; j++) {
                        //check value existed
                        let flag = 1;
                        for (let k = 0; k < results.length; k++) {
                            if (results[k].value === allFeedbackResultsList[i].feedbacks[j].section_name) {
                                flag = 0;
                                break;
                            }
                        }
                        if (flag) {
                            results.push({ value: allFeedbackResultsList[i].feedbacks[j].section_name, name: allFeedbackResultsList[i].feedbacks[j].section_name })
                        }
                    }
                } else {
                    //check value existed
                    let flag = 1;
                    for (let k = 0; k < results.length; k++) {
                        if (results[k].value === allFeedbackResultsList[i].section_name) {
                            flag = 0;
                            break;
                        }
                    }
                    if (flag) {
                        results.push({ value: allFeedbackResultsList[i].section_name, name: allFeedbackResultsList[i].section_name })
                    }
                }
            }
            setFilterByValueList([]);
            setFilterByValueList(results);
        } else if (e.target.value === 6) {
            setFilterByValueList(allgeneralFeedbackResultsList);
            setFeedbackResultsList(allgeneralFeedbackResultsList);
            setFilterByValueList([]);
        }
    }

    //handle filter by value
    const handleFilterByValue = (e) => {
        setSelectedValue(e.target.value);
        if (filterTypeValue != 0) {
            if (filterTypeValue === 2) {
                let existingResults = allFeedbackResultsList;
                let filteredResults = [];
                for (let i = 0; i < allFeedbackResultsList.length; i++) {
                    let results = [];
                    let flag = 0;
                    if (allFeedbackResultsList[i]?.faculty_id) {
                        for (let j = 0; j < allFeedbackResultsList[i].feedbacks.length; j++) {
                            if (allFeedbackResultsList[i].feedbacks[j].branch_name === e.target.value) {
                                flag = 1;
                                results.push(allFeedbackResultsList[i].feedbacks[j]);
                            }
                        }

                        if (flag) {
                            existingResults[i].feedbacks = results;
                            filteredResults.push(
                                {
                                    "faculty_id": existingResults[i].faculty_id,
                                    "faculty_name": existingResults[i].faculty_name,
                                    "feedbacks": results
                                });
                        }
                    } else {
                        if (allFeedbackResultsList[i].branch_name === e.target.value) {
                            filteredResults.push(allFeedbackResultsList[i]);
                        }
                    }
                }
                setFeedbackResultsList(filteredResults);
            } else if (filterTypeValue === 3) {
                let existingResults = allFeedbackResultsList;
                let filteredResults = [];
                for (let i = 0; i < allFeedbackResultsList.length; i++) {
                    let results = [];
                    let flag = 0;
                    if (allFeedbackResultsList[i]?.faculty_id) {
                        for (let j = 0; j < allFeedbackResultsList[i].feedbacks.length; j++) {
                            if (allFeedbackResultsList[i].feedbacks[j].subject_name === e.target.value) {
                                flag = 1;
                                results.push(allFeedbackResultsList[i].feedbacks[j]);
                            }
                        }

                        if (flag) {
                            existingResults[i].feedbacks = results;
                            filteredResults.push(
                                {
                                    "faculty_id": existingResults[i].faculty_id,
                                    "faculty_name": existingResults[i].faculty_name,
                                    "feedbacks": results
                                });
                        }
                    }
                }
                setFeedbackResultsList(filteredResults);
            } else if (filterTypeValue === 4) {
                console.log("res => ", allFeedbackResultsList);
                let results = [];
                for (let i = 0; i < allFeedbackResultsList.length; i++) {
                    if (allFeedbackResultsList[i]?.faculty_id) {
                        console.log(allFeedbackResultsList[i].faculty_id, e.target.value);
                        if (parseInt(allFeedbackResultsList[i].faculty_id) === parseInt(e.target.value)) {
                            results.push(allFeedbackResultsList[i]);
                        }
                    }
                }

                setFeedbackResultsList(results);
            } else if (filterTypeValue === 5) {
                let existingResults = allFeedbackResultsList;
                let filteredResults = [];
                for (let i = 0; i < allFeedbackResultsList.length; i++) {
                    let results = [];
                    let flag = 0;
                    if (allFeedbackResultsList[i]?.faculty_id) {
                        for (let j = 0; j < allFeedbackResultsList[i].feedbacks.length; j++) {
                            if (allFeedbackResultsList[i].feedbacks[j].section_name === e.target.value) {
                                flag = 1;
                                results.push(allFeedbackResultsList[i].feedbacks[j]);
                            }
                        }

                        if (flag) {
                            existingResults[i].feedbacks = results;
                            filteredResults.push(
                                {
                                    "faculty_id": existingResults[i].faculty_id,
                                    "faculty_name": existingResults[i].faculty_name,
                                    "feedbacks": results
                                });
                        }
                    } else {
                        if (allFeedbackResultsList[i].section_name === e.target.value) {
                            filteredResults.push(allFeedbackResultsList[i]);
                        }
                    }

                }
                setFeedbackResultsList(filteredResults);
            }
        }
    }

    const downloadGeneralDataAsExcelAndZip = () => {
        if (feedbackResultsList.length > 0) {
            setFeedbackResultsFlag(false);
            setFeedbackDownloadingFlag(true);
            console.log("in!");
            const zip = new JSZip();
            const cellStyle = { font: { bold: true } };

            feedbackResultsList.forEach((feedback, index) => {
                if (feedback.faculty_id) {
                    const key = feedback.faculty_name;
                    const workbook = XLSX.utils.book_new();
                    console.log(feedback);
                    feedback.feedbacks.map((item, index) => {
                        let sheetData = [];
                        sheetData.push(["", `${item.college_name}`, ""]);
                        if (item.feedback_type === 2) {
                            sheetData.push(["", "STUDENT FEEDBACK REPORT", ""]);
                        } else if (item.feedback_type === 2) {
                            sheetData.push(["", "HOD FEEDBACK REPORT", ""]);
                        } else if (item.feedback_type === 3) {
                            sheetData.push(["", "COUNSELLOR FEEDBACK REPORT", ""]);
                        } else if (item.feedback_type === 4) {
                            sheetData.push(["", "COORDINATOR FEEDBACK REPORT", ""]);
                        }
                        sheetData.push(["", `ACADEMIC YEAR 2022-23 ${item.type.toUpperCase()} SEMESTER`, ""]);
                        if (item.feedback_type === 1) {
                            sheetData.push([`Branch: ${item.branch_name}`, "", `Subject: ${item.section_name}`]);
                            sheetData.push([`Subject: ${item.subject_name}`, "", ""]);
                        } else {
                            sheetData.push([`Branch: ${item.branch_name}`, "", ""]);
                        }
                        sheetData.push([`Faculty: ${key}`, "", ""]);
                        sheetData.push(["Question", "Category", "Score"]);
                        item.questions.forEach((question) => {
                            sheetData.push([question.question, question.category_name, (question.Score).toFixed(2)])
                        });
                        if (item.feedback_type === 1) {
                            sheetData.push(["", `Overall Effectiveness: ${item.overall_ffectiveness * 10}%`, ""]);
                        } else if (item.feedback_type === 2) {
                            sheetData.push(["", `Effectiveness: ${item.overall_effectiveness * 10}%`, ""]);
                        } else if (item.feedback_type === 3) {
                            sheetData.push(["", `Counselling Effectiveness: ${item.overall_effectiveness * 10}%`, ""]);
                        } else if (item.feedback_type === 4) {
                            sheetData.push(["", `Coordinator Effectiveness: ${item.overall_effectiveness * 10}%`, ""]);
                        }
                        const sheetName = `Sheet${index + 1}`;
                        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
                        worksheet["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
                        worksheet["!rows"] = [{ hpx: 40 }];
                        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
                    });

                    const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
                    zip.file(`${key}.xlsx`, excelBuffer);
                } else {
                    const workbook = XLSX.utils.book_new();
                    console.log(feedback);
                    let item = feedback;
                    let sheetData = [];
                    sheetData.push(["", `${item.college_name}`, ""]);
                    sheetData.push(["", "GENERAL AMENITIES FEEDBACK REPORT", ""]);
                    sheetData.push(["", `ACADEMIC YEAR 2022-23 ${item.type.toUpperCase()} SEMESTER`, ""]);
                    sheetData.push([`Branch: ${item.branch_name}`, `Semester: ${item.semester_id}`, `Subject: ${item.section_name}`]);
                    sheetData.push(["Question", "Category", "Score"]);
                    item.questions.forEach((template, index) => {
                        sheetData.push([template.template_name, "", ""])
                        template.questions.forEach(question => {
                            sheetData.push([question.question, question.category_name, (question.Score).toFixed(2)])
                        })
                    });
                    const sheetName = `Sheet${index + 1}`;
                    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
                    worksheet["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
                    worksheet["!rows"] = [{ hpx: 40 }];
                    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

                    const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
                    zip.file(`General Amenities - ${item.section_name}.xlsx`, excelBuffer);
                }

            });

            zip.generateAsync({ type: 'blob' })
                .then(blob => {
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(blob);
                    downloadLink.download = 'faculty_semester_feedbacks.zip';
                    downloadLink.click();
                    setFeedbackResultsFlag(true);
                    setFeedbackDownloadingFlag(false);
                })
                .catch(error => console.error(error));
        } else {
            toast.error("No data to download. Please Try again!", {
                position: "top-right",
                autoClose: 2000,
            });
        }

    };

    const DownloadGenerateReportsAsPdf = async () => {
        // const feedbackResults = await generateGeneralFeedbackReports(feedbackDetails.id);
        // console.log(feedbackResults);
        if (nonGeneralFeedbackResultsList || generalFeedbackResultsList) {
            if (feedbackResultsList.length > 0) {
                const zip = new JSZip();
                // for each pdf you have to add the blob to the zip
                if (nonGeneralFeedbackResultsList) {
                    for (const feedback of nonGeneralFeedbackResultsList) {
                        const MyDocument = () => (
                            <GeneralPdfDocument key={feedback.faculty_id} type={1} resultsData={feedback.feedbacks} faculty_name={feedback.faculty_name} faculty_gender={feedback.faculty_gender} />
                        );

                        zip.file(`${feedback.faculty_name}.pdf`, pdf(<MyDocument />).toBlob());

                    }
                }
                if (generalFeedbackResultsList) {
                    if (generalFeedbackResultsList) {
                        for (const feedback of generalFeedbackResultsList) {
                            const MyDocument = () => (
                                <GeneralPdfDocument key={feedback.section_name} type={2} resultsData={[feedback]} />
                            );

                            zip.file(`General Amenitites - ${feedback.section_name}.pdf`, pdf(<MyDocument />).toBlob());

                        }
                    }
                }


                // once you finish adding all the pdf to the zip, return the zip file
                return zip.generateAsync({ type: "blob" }).then((blob) => {
                    saveAs(blob, "facultyfeedbacks.zip");
                });
            } else {
                toast.error("No data to download. Please Try again!", {
                    position: "top-right",
                    autoClose: 2000,
                });
            }
        } else {
            toast.error("No data to download. Please Try again!", {
                position: "top-right",
                autoClose: 2000,
            });
        }
    }

    console.log("feedback results -> ", feedbackResultsList);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#ffdfd6',
            color: 'black',
        },
        padding: '3px 6px',
        width: 'max-content',
    }));

    const headArray = [
        {
            id: 1,
            label: "Question"
        },
        {
            id: 2,
            label: "Category"
        },
        {
            id: 3,
            label: "Score"
        }
    ];

    return (
        <Box>
            <Navbar />
            <LayoutwithSideNav>
                {
                    feedbackResultsFlag === true ?
                        (
                            <>
                                {/* page header part */}
                                <Box sx={{ p: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={8} md={8} lg={8}>
                                            <Typography variant="h4" sx={{ wordBreak: "break-all", fontFamily: "Source Sans Pro" }}>
                                                General Feedback Results
                                            </Typography>
                                            <Divider sx={{
                                                width: "5rem",
                                                height: "4px",
                                                textAlign: "start",
                                                ml: 0,
                                                mt: 1,
                                                mb: "15px",
                                                background: "linear-gradient(131deg, #7175E0 0%, #4D50CB 33%, #E25BC8 100%)",
                                                borderRadius: "5px",
                                                opacity: "100%",
                                            }} />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4}>
                                            <Dropdown >
                                                <Dropdown.Toggle variant="success" id="donwloadResultsBtn" className="shadow" style={styles.donwloadResultsBtn}>
                                                    <BsDownload /> &nbsp;Download
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={DownloadGenerateReportsAsPdf}><BsDot style={{ color: "#f40f02", fontSize: "30px" }} />Download Pdf</Dropdown.Item>
                                                    <Dropdown.Item onClick={downloadGeneralDataAsExcelAndZip}><BsDot style={{ color: "#1d6f42", fontSize: "30px" }} />Download Excel</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* After header  */}
                                <Box sx={{ p: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3} lg={3}>
                                            <Typography variant="body1" sx={styles.label}>
                                                Filter By Category
                                            </Typography>
                                            <DropDown
                                                data={filterByTypeList}
                                                onChange={handleFilterByCategory}
                                                label={"Categories"}
                                                nullIsAll={false}
                                                dropDownValue={filterTypeValue}
                                                setDropDownValue={setFilterTypeValue}
                                                disabled={false}
                                                width="100%"
                                                size="medium"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3} lg={3}>
                                            <Typography variant="body1" sx={styles.label}>
                                                Filter By Value
                                            </Typography>
                                            <DropDown
                                                data={filterByValueList}
                                                onChange={handleFilterByValue}
                                                label={"Values"}
                                                nullIsAll={false}
                                                dropDownValue={selectedValue}
                                                setDropDownValue={setSelectedValue}
                                                disabled={false}
                                                width="100%"
                                                size="medium"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}></Grid>
                                    </Grid>

                                    {/* result tables */}
                                    {
                                        feedbackResultsList.length !== 0
                                            ? feedbackResultsList.map(feedback => {
                                                if (feedback?.faculty_id) {
                                                    return (
                                                        <Grid container spacing={2} sx={{ marginTop: "15px" }}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                        <Typography sx={styles.label_name}>Faculty Name : </Typography>
                                                                        <Typography sx={styles.label_value}>{feedback.faculty_name}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                        {
                                                                            feedback.feedbacks.length != 0
                                                                                ? feedback.feedbacks.map((feed) => {
                                                                                    if (feed.feedback_type === 2) {
                                                                                        return (
                                                                                            <>
                                                                                                <Grid container spacing={2}>
                                                                                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                                                        <Typography sx={styles.label_name}>Feedback Type : </Typography>
                                                                                                        <Typography sx={styles.label_value}>HOD</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                                                        <Typography sx={styles.label_name}>Branch : </Typography>
                                                                                                        <Typography sx={styles.label_value}>{feed.branch_name}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4} sm={4} md={4} lg={4}>

                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                                <Grid container spacing={2}>
                                                                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                                                        <TableContainer>
                                                                                                            <Table sx={{ minWidth: 500, border: '1px solid #e6e6e6' }} aria-label="simple table">
                                                                                                                <TableHead>
                                                                                                                    <TableRow>
                                                                                                                        {headArray.map((headCell) => (
                                                                                                                            <StyledTableCell key={headCell.id}
                                                                                                                                align={headCell.id !== 1 ? "center" : ""}
                                                                                                                                sx={{ marginLeft: "10px", fontWeight: 600 }}
                                                                                                                            >
                                                                                                                                {headCell.label}
                                                                                                                            </StyledTableCell>
                                                                                                                        ))}
                                                                                                                        {/* <StyledTableCell key={1} align="center">
                                                                                                            Hek
                                                                                                        </StyledTableCell> */}
                                                                                                                    </TableRow>
                                                                                                                </TableHead>
                                                                                                                <TableBody>
                                                                                                                    {
                                                                                                                        feed.questions.map((question, index) => {
                                                                                                                            return (
                                                                                                                                <TableRow
                                                                                                                                    key={index}
                                                                                                                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                                                                                                >
                                                                                                                                    <StyledTableCell sx={{ width: '60%' }}>
                                                                                                                                        {question.question}
                                                                                                                                    </StyledTableCell>
                                                                                                                                    <StyledTableCell align="center">
                                                                                                                                        -
                                                                                                                                    </StyledTableCell>
                                                                                                                                    <StyledTableCell align="center">
                                                                                                                                        {(question.Score).toFixed(2)}
                                                                                                                                    </StyledTableCell>
                                                                                                                                </TableRow>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                    <TableRow
                                                                                                                        key={0}
                                                                                                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                                                                                    >
                                                                                                                        <StyledTableCell sx={{ padding: "5px" }}>
                                                                                                                            <Typography sx={{ ...styles.label_value, float: 'right' }}>{(feed.overall_effectiveness * 10).toFixed(2)}%</Typography>
                                                                                                                            <Typography sx={{ ...styles.label_name, float: 'right' }}>Effectiveness :</Typography>
                                                                                                                        </StyledTableCell>
                                                                                                                    </TableRow>
                                                                                                                </TableBody>
                                                                                                            </Table>
                                                                                                        </TableContainer>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </>
                                                                                        )
                                                                                    } else if (feed.feedback_type === 3) {
                                                                                        return (
                                                                                            <>
                                                                                                <Grid container spacing={2} sx={{ marginTop: "15px" }}>
                                                                                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                                                        <Typography sx={styles.label_name}>Feedback Type : </Typography>
                                                                                                        <Typography sx={styles.label_value}>Counsellor</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                                                        <Typography sx={styles.label_name}>Branch : </Typography>
                                                                                                        <Typography sx={styles.label_value}>{feed.branch_name}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4} sm={4} md={4} lg={4}>

                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                                <Grid container spacing={2}>
                                                                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                                                        <TableContainer>
                                                                                                            <Table sx={{ minWidth: 500, border: '1px solid #e6e6e6' }} aria-label="simple table">
                                                                                                                <TableHead>
                                                                                                                    <TableRow>
                                                                                                                        {headArray.map((headCell) => (
                                                                                                                            <StyledTableCell key={headCell.id}
                                                                                                                                align={headCell.id !== 1 ? "center" : ""}
                                                                                                                                sx={{ marginLeft: "10px", fontWeight: 600 }}
                                                                                                                            >
                                                                                                                                {headCell.label}
                                                                                                                            </StyledTableCell>
                                                                                                                        ))}
                                                                                                                        {/* <StyledTableCell key={1} align="center">
                                                                                                            Hek
                                                                                                        </StyledTableCell> */}
                                                                                                                    </TableRow>
                                                                                                                </TableHead>
                                                                                                                <TableBody>
                                                                                                                    {
                                                                                                                        feed.questions.map((question, index) => {
                                                                                                                            return (
                                                                                                                                <TableRow
                                                                                                                                    key={index}
                                                                                                                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                                                                                                >
                                                                                                                                    <StyledTableCell sx={{ width: '60%' }}>
                                                                                                                                        {question.question}
                                                                                                                                    </StyledTableCell>
                                                                                                                                    <StyledTableCell align="center">
                                                                                                                                        -
                                                                                                                                    </StyledTableCell>
                                                                                                                                    <StyledTableCell align="center">
                                                                                                                                        {(question.Score).toFixed(2)}
                                                                                                                                    </StyledTableCell>
                                                                                                                                </TableRow>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                    <TableRow
                                                                                                                        key={0}
                                                                                                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                                                                                    >
                                                                                                                        <StyledTableCell sx={{ padding: "5px" }}>
                                                                                                                            <Typography sx={{ ...styles.label_value, float: 'right' }}>{(feed.overall_effectiveness * 10).toFixed(2)}%</Typography>
                                                                                                                            <Typography sx={{ ...styles.label_name, float: 'right' }}>Counselling Effectiveness :</Typography>
                                                                                                                        </StyledTableCell>
                                                                                                                    </TableRow>
                                                                                                                </TableBody>
                                                                                                            </Table>
                                                                                                        </TableContainer>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </>
                                                                                        )
                                                                                    } else if (feed.feedback_type === 4) {
                                                                                        return (
                                                                                            <>
                                                                                                <Grid container spacing={2} sx={{ marginTop: "15px" }}>
                                                                                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                                                        <Typography sx={styles.label_name}>Feedback Type : </Typography>
                                                                                                        <Typography sx={styles.label_value}>Coordinator</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                                                        <Typography sx={styles.label_name}>Branch : </Typography>
                                                                                                        <Typography sx={styles.label_value}>{feed.branch_name}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4} sm={4} md={4} lg={4}>

                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                                <Grid container spacing={2}>
                                                                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                                                        <TableContainer>
                                                                                                            <Table sx={{ minWidth: 500, border: '1px solid #e6e6e6' }} aria-label="simple table">
                                                                                                                <TableHead>
                                                                                                                    <TableRow>
                                                                                                                        {headArray.map((headCell) => (
                                                                                                                            <StyledTableCell key={headCell.id}
                                                                                                                                align={headCell.id !== 1 ? "center" : ""}
                                                                                                                                sx={{ marginLeft: "10px", fontWeight: 600 }}
                                                                                                                            >
                                                                                                                                {headCell.label}
                                                                                                                            </StyledTableCell>
                                                                                                                        ))}
                                                                                                                        {/* <StyledTableCell key={1} align="center">
                                                                                                            Hek
                                                                                                        </StyledTableCell> */}
                                                                                                                    </TableRow>
                                                                                                                </TableHead>
                                                                                                                <TableBody>
                                                                                                                    {
                                                                                                                        feed.questions.map((question, index) => {
                                                                                                                            return (
                                                                                                                                <TableRow
                                                                                                                                    key={index}
                                                                                                                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                                                                                                >
                                                                                                                                    <StyledTableCell sx={{ width: '60%' }}>
                                                                                                                                        {question.question}
                                                                                                                                    </StyledTableCell>
                                                                                                                                    <StyledTableCell align="center">
                                                                                                                                        -
                                                                                                                                    </StyledTableCell>
                                                                                                                                    <StyledTableCell align="center">
                                                                                                                                        {(question.Score).toFixed(2)}
                                                                                                                                    </StyledTableCell>
                                                                                                                                </TableRow>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                    <TableRow
                                                                                                                        key={0}
                                                                                                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                                                                                    >
                                                                                                                        <StyledTableCell sx={{ padding: "5px" }}>
                                                                                                                            <Typography sx={{ ...styles.label_value, float: 'right' }}>{(feed.overall_effectiveness * 10).toFixed(2)}%</Typography>
                                                                                                                            <Typography sx={{ ...styles.label_name, float: 'right' }}>Coordinator Effectiveness :</Typography>
                                                                                                                        </StyledTableCell>
                                                                                                                    </TableRow>
                                                                                                                </TableBody>
                                                                                                            </Table>
                                                                                                        </TableContainer>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                })
                                                                                : ""
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                } else {
                                                    console.log("--------");
                                                    console.log("--------> ", feedback);
                                                    return (
                                                        <Grid container spacing={2} sx={{ marginTop: "15px" }}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                {
                                                                    feedback.feedback_type === 5 ?
                                                                        (
                                                                            <>
                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                                        <Typography sx={styles.label_name}>Feedback Type : </Typography>
                                                                                        <Typography sx={styles.label_value}>General Amenities</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                                        <Typography sx={styles.label_name}>Branch : </Typography>
                                                                                        <Typography sx={styles.label_value}>{feedback.branch_name}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={4} sm={4} md={4} lg={4}
                                                                                        style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingRight: "10px" }}
                                                                                    >
                                                                                        <Typography sx={{ ...styles.label_name, textAlign: "right" }}>Section : </Typography>
                                                                                        <Typography sx={styles.label_value}>{feedback.section_name}</Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                                        <TableContainer>
                                                                                            <Table sx={{ minWidth: 500, border: '1px solid #e6e6e6' }} aria-label="simple table">
                                                                                                <TableHead>
                                                                                                    <TableRow>
                                                                                                        {headArray.map((headCell) => (
                                                                                                            <StyledTableCell key={headCell.id}
                                                                                                                align={headCell.id !== 1 ? "center" : ""}
                                                                                                                sx={{ marginLeft: "10px", fontWeight: 600 }}
                                                                                                            >
                                                                                                                {headCell.label}
                                                                                                            </StyledTableCell>
                                                                                                        ))}
                                                                                                        {/* <StyledTableCell key={1} align="center">
                                                                                                            Hek
                                                                                                        </StyledTableCell> */}
                                                                                                    </TableRow>
                                                                                                </TableHead>
                                                                                                <TableBody>
                                                                                                    {
                                                                                                        feedback.questions.map((template, index) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <TableRow
                                                                                                                        key={index}
                                                                                                                        sx={{ "&:last-child td, &:last-child th": { border: 0, width: "100%" } }}
                                                                                                                    >
                                                                                                                        <StyledTableCell sx={{ fontWeight: 600, padding: "5px" }}>
                                                                                                                            {template.template_name}
                                                                                                                        </StyledTableCell>
                                                                                                                        <StyledTableCell sx={{ padding: "5px" }}></StyledTableCell>
                                                                                                                        <StyledTableCell sx={{ padding: "5px" }}></StyledTableCell>
                                                                                                                    </TableRow>

                                                                                                                    {
                                                                                                                        template.questions.map((question, index2) => {
                                                                                                                            return (
                                                                                                                                <TableRow
                                                                                                                                    key={index2}
                                                                                                                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                                                                                                >
                                                                                                                                    <StyledTableCell sx={{ width: '60%' }}>
                                                                                                                                        {question.question}
                                                                                                                                    </StyledTableCell>
                                                                                                                                    <StyledTableCell align="center">
                                                                                                                                        -
                                                                                                                                    </StyledTableCell>
                                                                                                                                    <StyledTableCell align="center">
                                                                                                                                        {(question.Score).toFixed(2)}
                                                                                                                                    </StyledTableCell>
                                                                                                                                </TableRow>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </>
                                                                                                            )

                                                                                                        })
                                                                                                    }
                                                                                                    {/* <TableRow
                                                                                                        key={0}
                                                                                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                                                                    >
                                                                                                        <StyledTableCell sx={{ padding: "5px" }}>
                                                                                                            <Typography sx={{ ...styles.label_value, float: 'right' }}>{(feedback.overall_effectiveness * 10).toFixed(2)}%</Typography>
                                                                                                            <Typography sx={{ ...styles.label_name, float: 'right' }}>Effectiveness :</Typography>
                                                                                                        </StyledTableCell>
                                                                                                    </TableRow> */}
                                                                                                </TableBody>
                                                                                            </Table>
                                                                                        </TableContainer>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </>
                                                                        )
                                                                        : ""

                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                }
                                            })
                                            : (<Typography style={{ fontSize: '18px', textAlign: 'center', marginTop: '5%', fontWeight: '500' }}>
                                                No Feedback Reports to show!</Typography>)
                                    }

                                </Box>
                            </>
                        )
                        : feedbackDownloadingFlag === false ? (
                            <Box>
                                <Avatar
                                    src="/images/feedback_images/generate-report-giff.gif"
                                    className="reports-generate-gif"
                                    alt="image"
                                    sx={{ width: '50%', height: '400px', marginLeft: '25%' }}
                                />
                                <Typography
                                    variant="body1"
                                    className="generating-reports-label"
                                    sx={{
                                        fontSize: '1.3rem',
                                        fontWeight: 500,
                                    }}
                                >
                                    Generating Reports . . .</Typography>
                            </Box>
                        )
                            : ""
                }
                {
                    feedbackDownloadingFlag && (
                        <div>
                            <Avatar
                                src="/images/feedback_images/download-reports-gifff.gif"
                                className="reports-generate-gif"
                                alt="image"
                                sx={{ width: '50%', height: '400px', marginLeft: '25%' }}
                            />
                            <Typography
                                variant="body1"
                                className="generating-reports-label"
                                sx={{
                                    fontSize: '1.3rem',
                                    fontWeight: 500,
                                }}
                            >
                                Downloading Reports . . .</Typography>
                        </div>
                    )
                }
                <ToastContainer />
            </LayoutwithSideNav>
        </Box >
    )

}

export default FeedbackGeneralResults;