import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../../navbar/navbar.component";
import LayoutwithSideNav from "../../../common/LayoutwithSideNav";
import { Box, Typography, Divider, Button, Grid, TextField } from "@mui/material";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import RichTextEditor from "react-rte";
import MaterialDateTimePicker from "../../../common/MaterialDateTimePicker";
import DropDown from "../../../common/DropDown";
import moment from 'moment';
import { ToastContainer, toast } from "react-toastify";

const GeneralFeedback = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [title, setTitle] = useState(location.state ? location.state.title : "");
    const [Description, setDescription] = useState(RichTextEditor.createEmptyValue());
    const [startTime, setStartTime] = useState(location.state ? location.state.startTime : moment());
    const [endTime, setEndTime] = useState(location.state ? location.state.endTime : "");
    const [gradingScale, setGradingScale] = useState(location.state ? location.state.scale : 10);
    const [ratingScale, setRatingScale] = useState(location.state ? location.state.ratingScale : 10);
    const minDate = moment().format("YYYY-MM-DDTHH:mm");
    const styles = {
        button: {
            textTransform: 'none', // Disable capitalization
            fontSize: '1.1rem',
            maxWidth: "150px",
            float: "right"
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            marginTop: "15px",
            fontSize: "1.15rem",
            marginBottom: "7.5px"
        },
        redText: {
            color: 'red',
            position: 'relative',
            top: '-0.1em',
        },
    };

    const scales = [
        {
            value: 5,
            name: '1-5'
        },
        {
            value: 10,
            name: '1-10'
        },
        {
            value: 50,
            name: '1-50'
        },
        {
            value: 100,
            name: '1-100'
        }
    ];

    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };

    const handleTitle = (e) => {
        setTitle(e.target.value);
    };

    const handleDescription = (newDesc) => {
        console.log(newDesc);
        setDescription(newDesc);
    };

    const handleGradingScale = (e) => {
        console.log(e.target.value);
        setGradingScale(e.target.value);
    };

    const handleRatingScale = (e) => {
        console.log(e.target.value);
        setRatingScale(e.target.value);
    };

    const formatDateTime = (startTime) => {
        const start_ime = startTime.toDate().toISOString();
        const selectedDateTime = new Date(start_ime);
        selectedDateTime.setMinutes(selectedDateTime.getMinutes() + 30); // Add 30 minutes
        selectedDateTime.setHours(selectedDateTime.getHours() + 5); // Add 5 hours
        const year = selectedDateTime.getUTCFullYear();
        const month = selectedDateTime.getUTCMonth() + 1; // Months are zero-based, so add 1
        const day = selectedDateTime.getUTCDate();
        const hours = selectedDateTime.getUTCHours();
        const minutes = selectedDateTime.getUTCMinutes();
        const formattedDateTime = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        return formattedDateTime;
    };

    const ChangeComponent = () => {
        const desc = JSON.stringify(Description.toString("html")).replace(/<\/?p>/g, '').slice(1, -1);

        if (title === "" || desc === "" || startTime === "" || endTime === "" || gradingScale === "" || ratingScale === "") {
            console.log("Enter all details!");
            toast.error("Please enter all details", {
                position: "top-right",
            });
            return;
        }else{
            const start_time = formatDateTime(startTime);
            const end_time = formatDateTime(endTime);
            console.log("success!");
            navigate("/feedbacks/feedbackCategories", {
                state: {
                    title: title,
                    Description: desc,
                    startTime: start_time,
                    endTime: end_time,
                    gradingScale: gradingScale,
                    ratingScale: ratingScale
                }
            })
        }
    };

    return (
        <Box>
            <Navbar />
            <LayoutwithSideNav>
                {/* page header part */}
                <Box sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={8} md={8} lg={8}>
                            <Typography variant="h4" sx={{ wordBreak: "break-all", fontFamily: "Source Sans Pro" }}>
                                General Amenities Feedback
                            </Typography>
                            <Divider sx={{
                                width: "5rem",
                                height: "4px",
                                textAlign: "start",
                                ml: 0,
                                mt: 1,
                                mb: "15px",
                                background: "linear-gradient(131deg, #7175E0 0%, #4D50CB 33%, #E25BC8 100%)",
                                borderRadius: "5px",
                                opacity: "100%",
                            }} />
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <Button variant="contained" id="next" sx={styles.button} onClick={ChangeComponent}>Next &nbsp;
                                <ArrowForwardOutlinedIcon sx={{ fontSize: "1.25rem" }} /></Button>
                        </Grid>
                    </Grid>
                </Box>

                {/* After header  */}
                <Box>
                    <Grid>
                        <Typography variant="body1" sx={styles.label}>
                            Title
                            <Typography variant="body1" component="sup" sx={styles.redText}>
                                *
                            </Typography>
                        </Typography>
                        <TextField hiddenLabel variant="outlined" sx={{ width: "100%" }} defaultValue={title} onChange={handleTitle} size="Normal" />
                    </Grid>
                    <Grid>
                        <Typography variant="body1" sx={styles.label}>
                            Description
                            <Typography variant="body1" component="sup" sx={styles.redText}>
                                *
                            </Typography>
                        </Typography>
                        {/* <TextField multiline fullWidth hiddenLabel variant="outlined" sx={{mt:1}} rows={4}/> */}
                        <RichTextEditor toolbarConfig={toolbarConfig} className="RTE" editorClassName="my-editor" value={Description} onChange={handleDescription} />
                    </Grid>
                    <Grid container>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={6} md={6} lg={6}>
                                <Typography variant="body1" sx={styles.label}>
                                    Start Time
                                    <Typography variant="body1" component="sup" sx={styles.redText}>
                                        *
                                    </Typography>
                                </Typography>
                                <MaterialDateTimePicker
                                    label="Start Time"
                                    value={startTime}
                                    setValue={setStartTime}
                                    minDateTime={moment()}
                                    sx={{ mt: 1 }} />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <Typography variant="body1" sx={styles.label}>
                                    End Time
                                    <Typography variant="body1" component="sup" sx={styles.redText}>
                                        *
                                    </Typography>
                                </Typography>
                                <MaterialDateTimePicker
                                    label="End Time"
                                    value={endTime}
                                    setValue={setEndTime}
                                    minDateTime={startTime}
                                    width="100%" sx={{ mt: 1 }} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6} lg={6}>
                                <Typography variant="body1" sx={styles.label}>
                                    Select Grading Scale
                                    <Typography variant="body1" component="sup" sx={styles.redText}>
                                        *
                                    </Typography>
                                </Typography>
                                <DropDown
                                    data={scales}
                                    onChange={handleGradingScale}
                                    label={"Grading Scale"}
                                    nullIsAll={false}
                                    dropDownValue={gradingScale}
                                    setDropDownValue={setGradingScale}
                                    disabled={false}
                                    width="100%"
                                    size="medium"
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <Typography variant="body1" sx={styles.label}>
                                    Select Rating Scale
                                    <Typography variant="body1" component="sup" sx={styles.redText}>
                                        *
                                    </Typography>
                                </Typography>
                                <DropDown
                                    data={scales}
                                    onChange={handleRatingScale}
                                    label={"Grading Scale"}
                                    nullIsAll={false}
                                    dropDownValue={ratingScale}
                                    setDropDownValue={setRatingScale}
                                    disabled={false}
                                    width="100%"
                                    size="medium"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <ToastContainer />
            </LayoutwithSideNav >
        </Box >
    )
}

export default GeneralFeedback;