import {createSlice} from "@reduxjs/toolkit";
import { getAllDepartments,getAllSemester,getAllSubjects,getAllSections,getAllFaculty } from "../../lib/utils";


// Sample Faculty Mapping Object
// const allFacPerRevData = [{college_account_id: 1141,
// college_account_subject_college_department_section: 1472,
// department_name : "Civil"
// faculty_name: "madan ME",
// section_id: 713,
// section_name: "2 Section A",
// semester_id: 3,
// subject_name: "Strength of Materials - I",
// subject_semester_id: 465}]


// We are having allDepartmentsInCollege and allFacultyInCollege extra, as the apis 
// used to fetch the data might have more data then the one we fetched with section mapping
// ---------------------------------- Reducer

const facultyPerformanceReviewReducer = createSlice({
  name: "FacultyPerformanceReview",
  initialState: {
    allFacPerRevData: [],
    allSemesters: [],
    allDepartments: [],
    allSubjects: [],
    allSections: [],
    allFaculty: [],
    filteredAllFacPerRevData: [],
    filteredDepartments: [],
    filteredSemesters: [],
    filteredSubjects: [],
    filteredSections: [],
    filteredFaculty: [],
    tableIsLoading: false,
    allDepartmentsInCollege : [],
    allFacultyInCollege : [],
    allFacultyQuestionSubjectWiseScore : [],
    selectedSendSurvey : [],
  },
  reducers: {
    setTableIsLoading: (state, action) => {
      state.tableIsLoading = action.payload;
      return state;
    },
    setAllDepartments: (state, action) => {
      // console.log(action.payload);
      const { allDepartments } = action.payload;
      // console.log(allDepartments)
      state.allDepartments = allDepartments;
      state.filteredDepartments = allDepartments;
      return state;
    },
    setAllSemesters: (state, action) => {
      const { allSemesters } = action.payload;
      state.allSemesters = allSemesters;
      state.filteredSemesters = allSemesters;
      return state;
    },
    setAllSubjects: (state, action) => {
      const { allSubjects } = action.payload;
      state.allSubjects = allSubjects;
      state.filteredSubjects = allSubjects;
      return state;
    },
    setAllSections: (state, action) => {
      const { allSections } = action.payload;
      state.allSections = allSections;
      state.filteredSections = allSections;
      return state;
    },
    setAllFaculty: (state, action) => {
      const { allFaculty } = action.payload;
      state.allFaculty = allFaculty;
      state.filteredFaculty = allFaculty;
      return state;
    },
    setFilteredSubjects :(state,action) =>{
      const { filteredSubjects } = action.payload;
      state.filteredSubjects = filteredSubjects;
      return state;
    },
    setFilteredSections :(state,action) =>{
      const { filteredSections } = action.payload;
      state.filteredSections = filteredSections;
      return state;
    },
    setAllFacPerRevData: (state, action) => {
      const allFacPerRevData = action.payload;
      state.allFacPerRevData = allFacPerRevData;
      state.filteredAllFacPerRevData = allFacPerRevData;
      return state;
    },
    // Setting faculty performance review data
    setFilteredFacPerRevData: (state, action) => {
      const { deparmentId, selectedSemesters, selectedSubjects,selectedSections, selectedFaculty } =
        action.payload;

      const filteredAllFacPerRevData = state.allFacPerRevData.filter(
        (element, index) => {
          let selected = true;
          // If dept id is selected
          if (deparmentId) {
            if (element?.department_id !== deparmentId) selected = false;
          }
          // If sem id is included in the selected array
          if (selectedSemesters?.length) {
            if (!selectedSemesters.includes(element?.semester_id))
              selected = false;
          }
          // If subjects id is included in the selected array
          if (selectedSubjects?.length) {
            // console.log(selectedSubjects)
            if (!selectedSubjects.some((subject) => subject.value === element?.subject_semester_id))
              selected = false;
          }
          if (selectedSections?.length) {
            // console.log(selectedSections)
            if (!selectedSections.some((section) => section.name === element?.section_name.trim()))
              selected = false;
          }
          if (selectedFaculty?.length) {
            // console.log(selectedFaculty)
            if (!selectedFaculty.some((faculty) => faculty.value === element?.college_account_id))
              selected = false;
          }
          return selected;
        }
      );

      console.log(filteredAllFacPerRevData)

      let filteredDepartments;
      let filteredSemesters;
      let filteredSubjects;
      let filteredSections;
      let filteredFaculty;

      if (filteredAllFacPerRevData?.length) {
        filteredDepartments = getAllDepartments(filteredAllFacPerRevData);
        filteredSemesters = getAllSemester(filteredAllFacPerRevData);
        filteredSubjects = getAllSubjects(filteredAllFacPerRevData);
        filteredSections = getAllSections(filteredAllFacPerRevData);
        filteredFaculty = getAllFaculty(filteredAllFacPerRevData);
      } else {
        filteredDepartments = state.allDepartments;
        filteredSemesters = state.allSemesters;
        filteredSubjects = state.allSubjects;
        filteredSections = state.allSections;
        filteredFaculty = state.allFaculty;
      }

      state.filteredAllFacPerRevData = filteredAllFacPerRevData;
      state.filteredDepartments = filteredDepartments;
      state.filteredSemesters = filteredSemesters;
      state.filteredSubjects = filteredSubjects;
      state.filteredSections = filteredSections;
      state.filteredFaculty = filteredFaculty;
    },
    setSelectedSendSurvey: (state, action) => {
      const {selectedSendSurvey} = action.payload;
      // console.log(selectedSendSurvey)
      state.selectedSendSurvey = selectedSendSurvey;
      return state;
    },
    setFacultyQuestionSubjectWiseScore: (state, action) => {
      const {allFacultyQuestionSubjectWiseScore} = action.payload;
      state.allFacultyQuestionSubjectWiseScore = allFacultyQuestionSubjectWiseScore;
      return state;
    },
  },
}); ;

export const {
  setTableIsLoading,
  setAllDepartments,
  setAllSemesters,
  setAllSubjects,
  setAllSections,
  setAllFaculty,
  setFilteredSubjects,
  setFilteredSections,
  setAllFacPerRevData,
  setFilteredFacPerRevData,
  setSelectedSendSurvey,
  setFacultyQuestionSubjectWiseScore,
} = facultyPerformanceReviewReducer.actions;

export default facultyPerformanceReviewReducer.reducer ;