import React from 'react';
import Chip from '@mui/material/Chip';


export default function CustomChip({label}) {

    // const modifiedLabel = <span>{label}</span>;
    const modifiedLabel = <span style={{whiteSpace :'normal'}}>{label}</span>;

    return <Chip sx={{whiteSpace:'normal', height:'auto', p:0.3}} variant="filled" color="default" size="small" label={modifiedLabel}/>
}