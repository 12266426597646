import React from "react";
import QuestionGraph from "./QuestionGraph";
import Grid from '@mui/material/Grid';

export default function QuestionGraphsList({selectedQuestionsData}) {
    // console.log(selectedQuestionsData)
  return (
    <Grid container spacing={3}>
      {selectedQuestionsData?.question_wise_score?.map((questionData)=>(
        <Grid item xs={12} sm={12} md={6} key={questionData?.question_id}>
            <QuestionGraph questionData={questionData}/>
        </Grid>
      ))}
    </Grid>
  );
}
