import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP * 2,
      maxWidth: 700,
      overflowWrap : 'break-word'
    },
  },
};

// We had to provide selectedValues and setSelectedValues function as we are managing the state in the parent state.

export default function MultipleSelectCheckmarks({label,data,onChange,disabled,onClose,selectedValues,setSelectedValues,...props}) {
  // const [selectedValues, setSelectedValues] = React.useState([]);
  const [randomKey, setRandomKey] = React.useState([]);


  React.useEffect(()=>{
    const number = Math.random();
    setRandomKey(number);
  },[])

  // Filtering when data is changed while retaining the selected values
  React.useEffect(()=>{

    let newSelectedValues = [];
    
    selectedValues?.forEach((selectedValue)=>{
      const isIncluded = data.some((element) => {
        return element.value === selectedValue;
      });
      if (isIncluded) {
        newSelectedValues.push(selectedValue)
      }
    })

    console.log(newSelectedValues)

    setSelectedValues(newSelectedValues)

  },[data])

  const handleChange = (event) => {
    console.log(event.target.value);
    const {
      target: { value },
    } = event;

    // console.log(value.includes(-1))
    if (value.includes(-1)){
      setSelectedValues([])
      onChange([])      
   }
    else{

      // On autofill we get a stringified value.
      const selectedValues = typeof value === 'string' ? value.split(',') : value;
  
      setSelectedValues(selectedValues);
      onChange(selectedValues)
    }

  };

  return (
    <FormControl
      key={randomKey}
      sx={{ m: props?.formMargin, minWidth: 150, maxWidth: props?.maxwidth, width:props?.width }}
      size="small"
      disabled={disabled}
    >
      <InputLabel id="multiple-checkbox-label">{label}</InputLabel>
      <Select
        {...props}
        labelId="multiple-checkbox-label"
        id="multiple-checkbox"
        multiple
        value={selectedValues}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        // renderValue={(selected) => selected.join(", ")}
        renderValue={(selected) => selected.map((value) => data.find((item) => item.value === value)?.name).join(", ")}
        // renderValue={(selected) => selected.join(", ")}
        onClose={onClose ? onClose : null}
        MenuProps={MenuProps}
      >
        <MenuItem key={"Reset"} value={-1}>
          {/* <Checkbox checked={selectedValues.indexOf(-1) > -1} /> */}
          <ListItemText primary={"Reset"} />
        </MenuItem>
        {data?.map(({ name, value }) => (
          <MenuItem key={value} value={value}>
            <Checkbox checked={selectedValues.indexOf(value) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
