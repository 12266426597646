import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayoutwithSideNav from "../components/common/LayoutwithSideNav";
import Breadcrumb from "../components/common/Breadcrumb";
import Box from '@mui/material/Box';
import SearchDropDown from "../components/common/SearchDropDown";
import Grid from '@mui/material/Grid';
import { setAllDepartmentsInCollege, setAllFacultyInCollege } from "../store/reducers/facultySectionMapping.reducer";
import { fetchAllDepartments, getAllFaculties, addFacultySubjectMapping } from "../store/actions/facultySectionMapping.actions";
import TableWithSortingCommon from "../components/common/Table/TableWithSortingCommon";
import SearchField from "../components/common/SearchField";
// import MaterialButton from "../components/common/MaterialButton";
import { useNavigate, useParams } from "react-router-dom";
import SectionDetailsCard from "../components/facultySectionMapping/SectionDetailsCard";
import NoDataFound from "../components/common/NoDataFound";
import InComponentLoader from "../components/common/InComponentLoader";
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import FullScreenLoader from "../components/common/FullScreenLoader";
import MaterialReduxNotification from "../components/common/MaterialReduxNotification";
import {setNotification } from "../store/reducers/common.reducer";
import HeadingWithButtons from "../components/common/HeadingWithButtons";

const AddFacultyToSectionsPage = () => {

  const [selectedFaculty, setSelectedFaculty] = useState([]);
  const [filterDepartmentValue, setFilterDepartmentValue] = React.useState(null);
  const [facultySearchValue, setFacultySearchValue] = useState(null);
  const [filteredFacultyData, setFilteredFacultyData] = useState([]);
  const [selectedSectionDetails, setSelectedSectionDetails] = useState([]);
  const [loadingFirstTime, setLoadingFirstTime] = useState(true);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);


  const {allDepartmentsInCollege} = useSelector((state)=>state.SectionMapping)
  const {allFacultyInCollege} = useSelector((state)=>state.SectionMapping)
  const {allFacultySectionsMapping} = useSelector(state=>state.SectionMapping)
  // console.log(allDepartmentsInCollege);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();


  // Navigating back to Faculty Student Allocation page if no allFacultySectionsMapping exist. 
  // As we need that data to add faculty

  useEffect(()=>{
    console.log(allFacultySectionsMapping?.length)
    if(!allFacultySectionsMapping?.length){
      navigate('/facultySectionMapping', { replace: true })
    }
  },[allFacultySectionsMapping,navigate])

  
  // college_account_subject_college_department_section id
  let { uniqueId } = useParams();
  // cascdsId = cascdsId ? parseInt(cascdsId) : null ;
  console.log(uniqueId)
  

  // Get all faculty data, merge the first and last name for easy searching.
  useEffect(() => {
    async function fetchData() {
      setTableLoading(true)
      setLoadingFirstTime(false)
      const allFacultiesData = await dispatch(getAllFaculties());
      // console.log(allFacultiesData?.data?.data);

      const allFacultiesTransformed = allFacultiesData?.data?.data?.map(({department_id,department_name,first_name,last_name,id,profile_pic,role_name,roles_id})=>{
        const name = first_name + ' ' + last_name;
        // console.log(name);
        return{
          name,
          department_id,
          department_name,
          id,
          profile_pic,
          role_name,
          roles_id
        }
      })

      // console.log(allFacultiesTransformed)

      dispatch(
        setAllFacultyInCollege({
          allFacultyInCollege: allFacultiesTransformed,
        })
      );

      setFilteredFacultyData(allFacultiesTransformed);
      setTableLoading(false)

    }

    try {
      fetchData();
    } catch (error) {
      throw error;
    }
  }, [dispatch]);


  // Adding Department College Data to Redux
  useEffect(() => {
    async function fetchData() {
      const departmentsData = await dispatch(fetchAllDepartments());
      // console.log(departmentsData);
      dispatch(
        setAllDepartmentsInCollege({
          allDepartmentsInCollege: departmentsData.data.data,
        })
      );
    }

    try {
      fetchData();
    } catch (error) {
      throw error;
    }
  }, [dispatch]);
  


  // Adding Section Data 
  useEffect(()=>{
    // console.log(cascdsId)
    // console.log(allFacultySectionsMapping)
    const selectedSection = allFacultySectionsMapping.find((element)=>{
      // console.log(element)
      return element.unique_id === uniqueId;
    })
    // console.log(selectedSection)
    if (selectedSection) {
      setSelectedSectionDetails(selectedSection)
    }
  },[uniqueId,allFacultySectionsMapping])


  // Filtering Table Based on Search 
  useEffect(()=>{
    // console.log(filterDepartmentValue)
    // console.log(facultySearchValue)

    // If not dept or search is done then no filter needs to be applied
    if (!filterDepartmentValue && !facultySearchValue) {
      setFilteredFacultyData(allFacultyInCollege)
        return
    }

    const filteredData = allFacultyInCollege.filter((facultyData)=>{
      let isIncluded =false;

      if (filterDepartmentValue) {
        isIncluded = filterDepartmentValue.id === facultyData.department_id ? true : false;
      }

      // Checking if name matches with department
      if (facultySearchValue) {
      // console.log(`isIncluded before ${isIncluded}`)

      const facultyString = facultyData.name.toLowerCase();
      const searchString = facultySearchValue.toLowerCase();

      isIncluded = facultyString.includes(searchString) ? true : false;
      // console.log(`isIncluded after ${isIncluded}`)
      }
      return isIncluded
    })
    console.log(filteredData)
    setFilteredFacultyData(filteredData)
  },[facultySearchValue,filterDepartmentValue,allFacultyInCollege])


  const headArray = [
    {
      id: 'department_name',
      label: 'Department',
    },
    {
      id: 'name',
      label: 'Name',
    }
  ];

  // Table Data
  const tableData =  <Grid item>
    <TableWithSortingCommon
      data={filteredFacultyData}
      heading={'Faculty List'}
      headArray={headArray}
      primaryKey={"id"}
      multiselect={false}
      showCheckbox ={true}
      selected={selectedFaculty}
      setSelected={setSelectedFaculty}
    />
  </Grid>


  const handleFacultySearch = (event) =>{
    // console.log(event.target.value);
    setFacultySearchValue(event.target.value)
  }
  
  const handleAddFaculty = async () =>{
    // console.log(selectedFaculty)
    if (!selectedFaculty?.length)
      return dispatch(
        setNotification({
          openNotification: true,
          notificationMessage: "Please select faculty",
          notificationType: "warning",
        })
      );

      setLoading(true);
      const response = await dispatch(addFacultySubjectMapping(selectedSectionDetails.section_id,selectedSectionDetails.subject_semester_id,selectedFaculty[0]));
      setLoading(false);
      //  console.log(response)
      if(response?.data?.status === 200){

      dispatch( setNotification({ 
        openNotification: true,
        notificationMessage: "Successfully Added Faculty",
        notificationType: "success",
      }));

      navigate('/facultySectionMapping', { replace: true })
    }
    else if(response?.data?.status === 204){
      dispatch( setNotification({ 
        openNotification: true,
        notificationMessage: 'The faculty is already added to the Subject-Section',
        notificationType: "error",
      }));
    }

    else{
      dispatch( setNotification({ 
        openNotification: true,
        notificationMessage: `Error : ${response?.data?.message}, Could not add Faculty to the Subject-Section`,
        notificationType: "error",
      }));
    }
  }
  
  const handleCancel = () =>{
    // console.log(event.target.value);
    navigate('/facultySectionMapping', { replace: true });
  }
  
  return (
    <React.Fragment>

      <LayoutwithSideNav>
        <Box sx={{padding:'0 0 15px 0', m:0}}>
          <Breadcrumb currentPage={'Add Faculty'} links={[{name : "Faculty Student Allocation", path : '/facultySectionMapping' }]}/>
        </Box>

        <Box sx={{pl:'1.5rem'}}>  
          <HeadingWithButtons
          onCancelClick ={handleCancel}
          onSubmitClick ={handleAddFaculty}
          submitLabel ={'Add'}
          title={'Add Faculty to Section'}
          />

          <Grid container spacing={2} sx={{ pt: "1rem" }}>
            <Grid container item sx={{ mt: 1, py :1 }} justifyContent="flex-start" alignItems="baseline" spacing={2} sm={12} md={5}>
              {/* <Paper elevation={2} sx={{ mt: 1, py :1 }}> */}
              <Grow in={!loadingFirstTime}>
              <Grid container item direction="column" spacing={1} >
                <Grid item xs={12}>
                  <SearchField onChange={handleFacultySearch} />
                </Grid>
                <Grid item xs={12}>
                  <SearchDropDown
                    getOptionLabel={"name"}
                    label={"Department"}
                    data={allDepartmentsInCollege}
                    value ={filterDepartmentValue}
                    setValue={setFilterDepartmentValue}
                  />
                </Grid>

                <Grid item xs={12}>
                  <SectionDetailsCard selectedSectionDetails={selectedSectionDetails}/>
                </Grid>

              </Grid>
              </Grow>
              {/* </Paper> */}
            </Grid>

            <Grid container item sm={12} md={7} justifyContent='center' alignItems='center'>

              {filteredFacultyData?.length > 0 &&
              <Grid item xs={12}>
                <Grow in={!tableLoading}>
                  {tableData}
                </Grow>
              </Grid>
              }

                
            {!tableLoading && <Fade in={!tableLoading && !(filteredFacultyData?.length > 0) && !loadingFirstTime}>
              <Grid item xs={12}>
                <NoDataFound message="No Faculty Found, Please Re-Apply Filter" />
                {/* No Data Found */}
              </Grid>
            </Fade>}

            {tableLoading &&(
              <Grid container item xs={12} justifyContent='center'>
                <InComponentLoader/>
              </Grid>
            )}
            </Grid>
          </Grid>
        </Box>

        <FullScreenLoader open={loading} />
      </LayoutwithSideNav>
      <MaterialReduxNotification/>
    </React.Fragment>

  );
};

export default AddFacultyToSectionsPage;
