import React from "react";

import Navbar from "../components/navbar/navbar.component";
import ChallengeLobby from "../components/challengeLobby/challengeLobby.component";

const ChallengeLobbyPage = () => {
    return (
        <div className="weekly-challenge" style={{ overflowX: "hidden" }}>
            <Navbar />
            <div className="row" style={{ marginTop: '70px', backgroundColor: '#f9f9f9' }}>
                <ChallengeLobby />
            </div>
        </div>
    )
}

export default ChallengeLobbyPage;