import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
// import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import TableTopMenu from './TableTopMenu';
import TableRowActions from './TableRowActions';
import { customBoxShadow } from '../../lib/utils';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import Alert from "@mui/material/Alert";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#ffdfd6',
    color: 'black',
  },
  padding: 8,
  width : 'max-content'
}));


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const {
    headArray,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    hasEditActions
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headArray.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={"center"}
            // padding={headCell.disablePadding ? "none" : "normal"}
            // padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        {/* <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </StyledTableCell> */}
        {hasEditActions ? <StyledTableCell
          align={"center"}
        >
          Actions
        </StyledTableCell> : null}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected,heading } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        borderRadius : '20px 20px 0 0',
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
          align="center"
        >
          {heading}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Faculty Actions">
          <IconButton onClick={props.handleMainMenuClick}>
            <ArrowDropDownCircleIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


// We get an array of data, one of the keys in the array should have primary key to tag the map key correctly. 
// A head array which is used for extracting the data from array into the table, in the order we want to show it in.
// A heading is provided.
// It has a download Excel Option


export default function TableWithSorting({data,heading,headArray,primaryKey}) {

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(headArray[0]['id']);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [mainMenuOpen, setMainMenuOpen] = React.useState(null);
  const [rowMenuOpen, setRowMenuOpen] = React.useState(null);
  const [rowActionsData, setRowActionsData] = React.useState(null);
  const [hasEditActions, setHasEditActions] = React.useState(JSON.parse(localStorage.getItem('user'))?.role_id <=3);

  const {allFacultySectionsMapping} = useSelector((state) => state.SectionMapping);
  const navigate = useNavigate();


  React.useEffect(()=>{
    // When data is changed like when filter is applied page
    //  change should be done as we run into errors if the user is in any other page.
    // console.log('auto page change triggered')
    setPage(0)
  },[data])

  
  if (!allFacultySectionsMapping) {
    return;  
  }

  // Adding Header Array IDs as keys to the Table
  const tableKeys = headArray?.map((head)=>{
    return head.id;
  })
  // console.log(tableKeys)

  // console.log(data)
  // Adding data to rows
  const rows = data;


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelected = rows.map((n) => n[primaryKey]);
  //     setSelected(newSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMainMenuClick = (event) => {
    setMainMenuOpen(event.currentTarget);
  };

  // Event.currentTarget is required by the 'Menu' component in the Material UI.
  const handleRowActionsClick = (event,row) => {
    console.log(event.currentTarget)
    setRowMenuOpen(event.currentTarget);
    setRowActionsData(row);
  };

  const handleAddFaculty = (row) => {
    navigate(`/facultySectionMapping/addFaculty/${row?.unique_id}`,{ state: row });
    setRowMenuOpen(null);
    setRowActionsData(null);  
  }

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    // <Box sx={{ width: "100%" }}>
    <Box
      sx={{
        // width: "max-content",
        width: "100%",
        maxWidth : "1300px",
        boxShadow: customBoxShadow,
        borderRadius : '20px'
      }}
    >
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
      {!hasEditActions ? <Alert severity="info">
       You can add yourself to a Section from the "Your Courses" module.
      </Alert> : null}
      <Paper elevation={0} sx={{ width: "100%", mb: 1, borderRadius: '20px'}}>
        <EnhancedTableToolbar handleMainMenuClick={handleMainMenuClick} heading={heading} numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{maxHeight: 200}}
            aria-labelledby="tableTitle"
            size={"small"}
            // size={dense ? "small" : "medium"}
            stickyHeader
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headArray={headArray}
              hasEditActions = {hasEditActions}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row[primaryKey]);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row[primaryKey])}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row[primaryKey]}
                      selected={isItemSelected}
                    >
                      {/* First Element in the Array */}
                      <StyledTableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="center"
                      >
                        {row[tableKeys[0]]? row[tableKeys[0]] : <i style={{color : 'grey'}}>NA</i>}
                      </StyledTableCell>
                      {/* All Elements except first element in array using slice method */}
                      {tableKeys.slice(1).map((key, index) => (
                        <StyledTableCell key={index} align="center">
                          {row[key] ? row[key] : <i style={{color : 'grey'}}>NA</i>}
                        </StyledTableCell>
                      ))}
                      {/* <StyledTableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="center"
                      >
                        {row.department_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.semester_id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.subject_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.section_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.faculty_name}
                      </StyledTableCell> */}
                      {/* <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </StyledTableCell> */}
                      {hasEditActions ? <StyledTableCell align="center" sx={{p:'1px'}} >
                        {row?.college_account_id ? <Tooltip title="Make Changes">
                          <IconButton disableRipple sx={{p:0}} onClick={(event)=>handleRowActionsClick(event,row)}>
                            ...
                          </IconButton>
                        </Tooltip> 
                        : <Tooltip title="Add Faculty">
                        <IconButton disableRipple sx={{p:0}} onClick={(event)=>handleAddFaculty(row)}>
                          <AddCircleIcon/>
                        </IconButton>
                      </Tooltip> 
                        }
                      </StyledTableCell> : null}


                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                    // height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <StyledTableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <TableTopMenu headArray={headArray} data ={rows} mainMenuOpen ={mainMenuOpen} setMainMenuOpen={setMainMenuOpen} />
        <TableRowActions
          handleAddFaculty={handleAddFaculty}
          setRowActionsData={setRowActionsData}
          rowActionsData={rowActionsData}
          rowMenuOpen={rowMenuOpen}
          setRowMenuOpen={setRowMenuOpen}
        />
      </Paper>
    </Box>
  );
}