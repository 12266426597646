import React from "react";
// import AnyChart from '../../../'
import AnyChart from "anychart-react";
import anychart from "anychart";
import { v4 as uuidv4 } from 'uuid';
import Grid from '@mui/material/Grid';
import { anychart_key } from "../../lib/utils";

anychart.licenseKey(anychart_key);


// import AnyChart from '../../../../node_modules/anychart-react/dist/anychart-react.js'

// const complexSettings = {
//   width: 800,
//   height: 600,
//   type: 'column',
//   data: "P1,5\nP2,3\nP3,6\nP4,4",
//   title: 'Column chart',
//   yAxis: [1, {
//     orientation: 'right',
//     enabled: true,
//     labels: {
//       format: '{%Value}{decimalPoint:\\,}',
//       fontColor: 'red'
//     }
//   }],
//   legend: {
//     background: 'lightgreen 0.4',
//     padding: 0
//   },
//   lineMarker: {
//     value: 4.5
//   }
// };

// const data = anychart.data.set([
//   { x: "January", value: 10000 },
//   { x: "February", value: 12000 },
//   { x: "March", value: 18000 },
//   { x: "April", value: 11000 },
//   { x: "May", value: 9000 },
// ]);


QuestionWiseBellCurve.defaultProps = {
    xLabel : 'x-axis',
    yLabel : 'y-axis',
    title : 'Bell Curve',
}


export default function QuestionWiseBellCurve({xLabel, yLabel, title, data}) {

  const unique_id = uuidv4()
  const complexSettings = {
    id : unique_id,
    type: "area",
    //   series : 'splineArea',
    //   data: data,
    title: title,
    // width: 800,
    height: 300,
    maxWidth : 800,
    xAxis: [0,{
        title : xLabel
    }],
    yAxis: [0, {
        title : yLabel,
        orientation: 'left',
        enabled: true,
        labels: {
        format: '{%value}',
        // fontColor: 'grey'
        }
    }],
    series : 'Students vs Ratings',
    // legend: {
    //   background: "lightgreen 0.4",
    //   padding: 1,
    // },
    tooltip : {
        title : 'Ratings',
        format : 'Rating: {%categoryName} \nNo. of Students: {%value}'
    }

  };

  const chart = anychart.area();
  chart.splineArea(data);
//   chart.splineArea(data);

  return (
        <Grid item sx={{m:1}}>
            <AnyChart {...complexSettings} instance={chart} />
        </Grid>

    // <AnyChart
    // {...complexSettings}
    // />
  );
}
