import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import TableTopMenuCommon from './TableTopMenuCommon';
import Radio from '@mui/material/Radio';
import { customBoxShadow } from '../../../lib/utils';

const StyledTableCell = styled(TableCell)(({ theme,onClick }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#ffdfd6',
    color: 'black',
  },
  padding: '3px 6px',
  width : 'max-content',
  cursor :  `${onClick ? `pointer !important` : 'inherit'}`
}));


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const {
    headArray,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    haveSort,
    buttonName,
    handleButtonSubmit
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headArray.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={"center"}
            // padding={headCell.disablePadding ? "none" : "normal"}
            // padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* Handling If Sort is enabled or not */}
            {haveSort ? <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel> : headCell.label}
          </StyledTableCell>
        ))}
        {props.showCheckbox && <StyledTableCell>
          {props.multiselect && <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />}
        </StyledTableCell>}

        {buttonName && handleButtonSubmit && (
          <StyledTableCell
            component="th"
            align="center"
          >
            Actions
          </StyledTableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected,heading } = props;

  return (
    <Toolbar
      sx={{
        minHeight:"25px",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        borderRadius : '20px 20px 0 0',
        ...(numSelected > 0 && props.multiselect && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 && props.multiselect ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
          align="center"
        >
          {heading}
        </Typography>
      )}

      {
      
      (numSelected && props.showTopDeleteIcon > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (props.showTopMenu &&
        <Tooltip title="Faculty Actions">
          <IconButton onClick={props.handleMainMenuClick}>
            <ArrowDropDownCircleIcon />
          </IconButton>
        </Tooltip>
      ))
      }
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};





TableWithSortingCommon.defaultProps = {
  heading: "Table",
  showTopMenu: false,
  multiselect : true,
  showCheckbox : false,
  showTopDeleteIcon : false,
  selected : [],
  setSelected : null,
  searchRemovesMultiSelection : true,
  setSelected : null,
  haveSort : true,
  haveBottomNavigation : true,
  buttonColor : 'inherit'
}


// We get an array of data, one of the keys in the array should have primary key to tag the map key correctly. 
// A head array which is used for extracting the data from array into the table, in the order we want to show it in.
// A heading is provided.
// It has a download Excel Option
// haveSort is by default on, if given as false will be removed.
// Has option to show checkboxes, show delete icon, only enable single selection, can show top menu and has functions

export default function TableWithSortingCommon({
  selected,
  setSelected,
  data,
  heading,
  headArray,
  primaryKey,
  showTopMenu,
  multiselect,
  showCheckbox,
  showTopDeleteIcon,
  searchRemovesMultiSelection,
  haveSort,
  haveBottomNavigation,
  buttonName,
  handleButtonSubmit,
  buttonColor,
}) {

  console.log(headArray[0]['id'])

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(headArray[0]['id']);
  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [mainMenuOpen, setMainMenuOpen] = React.useState(null);


  // If data changes, we need to reslect the selected options or else it will keep the option selected.
  // Need to verify for multiselect enabled
  React.useEffect(()=>{
    // console.log(selected);
    // console.log(data);

    setPage(0)

    // When we want to keep the selected options when multiselection is possible
    if(multiselect && !searchRemovesMultiSelection){
      return
    }

    if (selected?.length > 0) {
      const newSelected = selected.filter((element) => {
        return data.some((row) => {
          return row[primaryKey] === element;
        });
      });

      // console.log(newSelected)
      setSelected(newSelected)
    }
  },[data])


  const {allFacultySectionsMapping} = useSelector((state) => state.SectionMapping);
  if (!allFacultySectionsMapping) {
    return;  
  }

  // Adding Header Array IDs as keys to the Table
  const tableKeys = headArray?.map((head)=>{
    return head.id;
  })
  // console.log(tableKeys)



  // console.log(data)
  // Adding data to rows
  const rows = data;




  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n[primaryKey]);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {

    // If no selection is required then do nothing when clicked to select
    if(!setSelected){
      return
    }

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];


    if(multiselect){
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
    }
    else{
      newSelected.push(name)
    }
    // console.log(newSelected)
    setSelected(newSelected);


  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMainMenuClick = (event) => {
    setMainMenuOpen(event.currentTarget);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    // <Box sx={{ width: "100%" }}>
    <Box
      sx={{
        // width: "max-content",
        width: "100%",
        maxWidth : "1300px",
        boxShadow: customBoxShadow,
        borderRadius : '20px'
      }}
    >
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
      <Paper elevation={0} sx={{ width: "100%", my: 1, borderRadius: '20px' }}>
        <EnhancedTableToolbar multiselect={multiselect} showTopMenu={showTopMenu} handleMainMenuClick={handleMainMenuClick} heading={heading} numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ maxHeight: 200 }}
            aria-labelledby="tableTitle"
            size={"small"}
            // size={dense ? "small" : "medium"}
            stickyHeader
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headArray={headArray}
              showCheckbox = {showCheckbox}
              showTopDeleteIcon ={showTopDeleteIcon}
              multiselect ={multiselect}
              haveSort={haveSort}
              handleButtonSubmit = {handleButtonSubmit}
              buttonName = {buttonName}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row[primaryKey]);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row[primaryKey])}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row[primaryKey]}
                      selected={isItemSelected}
                    >
                      {/* First Element in the Array */}
                      <StyledTableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="center"
                      >
                        {row[tableKeys[0]]? row[tableKeys[0]] : <i style={{color : 'grey'}}>NA</i>}
                      </StyledTableCell>
                      {/* All Elements except first element in array using slice method */}
                      {tableKeys.slice(1).map((key,index) => (
                        <StyledTableCell key={index} align="center">
                          {row[key]? row[key] : <i style={{color : 'grey'}}>NA</i>}
                        </StyledTableCell>
                      ))}
                      {/* <StyledTableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="center"
                      >
                        {row.department_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.semester_id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.subject_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.section_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.faculty_name}
                      </StyledTableCell> */}
                      {showCheckbox && <StyledTableCell>
                        {multiselect ? <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        /> : <Radio
                        checked={isItemSelected}
                        color="primary"
                        name="radio-buttons"
                        // inputProps={{
                        //   "aria-labelledby": labelId,
                        // }}
                        sx={{p:0}}
                        size="small"
                      />}

                      </StyledTableCell>}
                      {buttonName && handleButtonSubmit && <StyledTableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="center"
                        onClick={()=>handleButtonSubmit(row)}
                        sx={{color : buttonColor}}
                      >
                       {buttonName}
                      </StyledTableCell>}
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                    // height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <StyledTableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        {haveBottomNavigation && <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />}

        <TableTopMenuCommon headArray={headArray} data ={rows} mainMenuOpen ={mainMenuOpen} setMainMenuOpen={setMainMenuOpen} />
      </Paper>
    </Box>
  );
}