import React from "react";
import Box from '@mui/material/Box';
import { Grid, Typography } from "@mui/material";

const NoDataFound = ({message}) => {
  return (
    <React.Fragment>
    <Grid container  pt={'20px'} flexDirection={"column"} alignItems='center'>
      <Box
        component="img"
        sx={{
          width: 350,
          maxWidth: { xs: 350, md: 250 },
        }}
        alt="No Data Found Image."
        src="/images/no_data_found.gif"
      />
      {/* <img
        src="/images/no_data_found.gif"
        alt="No Data Found"
        style={{ width: "300px" }}
      /> */}

      <Typography variant="h6" component="h6" pt={3}>
        {message}
      </Typography>
      </Grid>
    </React.Fragment>
  );
};

export default NoDataFound;
