import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import { BsArrowRight } from "react-icons/bs";
import { Collapse } from 'react-collapse';
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { GiCardPickup } from "react-icons/gi";
import { FaPlus } from "react-icons/fa";
import { BiEdit, BiInfoCircle } from "react-icons/bi";
import RichTextEditor from "react-rte";
import { AllQuestions, addQuestion, getCategoryQuestions } from "../../../api/facultyfeedbackapis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CategoryQuestion.css";
import Navbar from "../../navbar/navbar.component";
import LayoutwithSideNav from "../../common/LayoutwithSideNav";


const CategoryQuestion = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [questionData, setQuestionData] = useState([]);
  const [allquestionData, setallQuestionData] = useState([]);
  const [flag, setflag] = useState(1);
  const [selectedquestions, setselectedquestions] = useState([]);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [questioncollapseOpen, setQuestionCollapseOpen] = useState({});
  const [showChooseQuestions, setShowChooseQuestions] = useState(true);
  const [showAddQuestion, setAddQuestion] = useState(false);
  const [newQuestionName, setNewQuestionName] = useState(RichTextEditor.createEmptyValue());
  const [addedQuestions, setAddedQuestions] = useState([]);
  const [selectedCategoryId, setselectedCategoryId] = useState(-1);
  const [allQuestionsChecked, setAllQuestionsChecked] = useState(false);

  useEffect(() => {
    handleAllQuestions();
  }, []);

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' }
    ]
  };

  const ChangeComponent = () => {
    if (selectedquestions.length === 0) {
      toast.error("Please select questions to continue!", {
        position: "top-right",
      });
    }
    else {
      navigate("/details", {
        state:
        {
          title: location.state.title,
          Description: location.state.Description,
          selectedquestions: selectedquestions,
          startTime: location.state.startTime,
          endTime: location.state.endTime,
          scale: location.state.scale,
          ratingScale: location.state.ratingScale
        }
      });
    }
  };
  // console.log(location.state.Description);
  const handleAllQuestions = async () => {
    // navigate("/allquestions");
    const questions = await AllQuestions();
    setallQuestionData(questions.data);
    setflag(0);
    setselectedCategoryId(-1);

    setShowChooseQuestions(true);
    setAddQuestion(false);
  };

  const showQuestions = async (cat_id) => {
    setflag(1);
    const id = cat_id;
    console.log(cat_id);
    setselectedCategoryId(cat_id);
    const questions = await getCategoryQuestions(id);
    console.log(questions.data);
    setQuestionData(questions.data);
  };

  const handleQuestionInput = (newValue) => {
    setNewQuestionName(newValue);
  };

  const handleSaveChanges = async (e) => {
    let cat_id = selectedCategoryId;
    if (cat_id === -1) {
      cat_id = "";
    }

    const questionName = JSON.stringify(newQuestionName.toString("html")).replace(/<\/?p>/g, '').slice(1, -1)
    if (questionName === "") {
      toast.error("Please add a question to continue!", {
        position: "top-right",
      });
    } else {
      const q = await addQuestion(
        cat_id,
        questionName
      );
      if (q.message === "Successfully added Question" && q.status === 200) {
        handleAllQuestions();
        toast.success("Question added Successfully!", {
          position: "top-right",
        });
        setNewQuestionName(RichTextEditor.createEmptyValue());

      } else {
        toast.error("Unable to add question, Please try again!", {
          position: "top-right",
        });
      }
    }
  };

  const selectquestions = (e, question) => {
    if (e.target.checked) {
      setselectedquestions([...selectedquestions, parseInt(e.target.value)]);
      setAddedQuestions([...addedQuestions, question]);
      if(flag === 0 && selectedquestions.length + 1 === allquestionData.length){
        setAllQuestionsChecked(true);
      }else if(flag === 1 && selectedquestions.length + 1 === questionData.length){
        setAllQuestionsChecked(true);
      }
    }
    else {
      setselectedquestions(selectedquestions.filter((ev) => (ev !== parseInt(e.target.value))));
      setAddedQuestions(addedQuestions.filter(ques => parseInt(ques.id) != parseInt(e.target.value)));
      setAllQuestionsChecked(false);
    }

  }

  const handleAddaNewQuestionBtn = () => {
    setShowChooseQuestions(false);
    setAddQuestion(true);
    setNewQuestionName(RichTextEditor.createEmptyValue());
  }

  const QuestionCollapseToggle = (index) => {
    console.log(index, "=");
    setQuestionCollapseOpen({
      ...questioncollapseOpen,
      [index]: !questioncollapseOpen[index]
    });

    if (isOptionsOpen) {
      setIsOptionsOpen(false);
    } else {
      setIsOptionsOpen(true);
    }
  };

  const handleEditCategories = () => {
    navigate("/feedback", {
      state:
      {
        title: location.state.title,
        Description: location.state.Description,
        startTime: location.state.startTime,
        endTime: location.state.endTime,
        scale: location.state.scale,
        category_list: location.state.list,
        ratingScale: location.state.ratingScale
      }
    });
  };

  const selectAllQuestions = (e) => {
    if(e.target.checked){
      setAllQuestionsChecked(true);
      let selected_questions = selectedquestions;
      let added_questions = addedQuestions;
      if(flag === 0){
        if(allquestionData.length > 0){
          for(let i=0; i < allquestionData.length; i++){
            console.log(allquestionData[i]);
            if(!selected_questions.includes(parseInt(allquestionData[i].id))){
              selected_questions.push(parseInt(allquestionData[i].id));
              added_questions.push(allquestionData[i]);
            }
          }
        }
      }else if(flag === 1){
        if(questionData.length > 0){
          for(let i=0; i < questionData.length; i++){
            if(!selected_questions.includes(parseInt(questionData[i].id))){
              selected_questions.push(parseInt(questionData[i].id));
              added_questions.push(questionData[i]);
            }
          }
        }
      }
      setselectedquestions(selected_questions);
      setAddedQuestions(added_questions);
    }else{
      setAllQuestionsChecked(false);
      setselectedquestions([]);
      setAddedQuestions([]);
    }
  }

  console.log("sel -> ",selectedquestions);

  if (location.state) {
    return (
      <>
        <div className="feedback-sel-questions-page">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <Navbar />
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          <LayoutwithSideNav>
            <div className="container-fluid feedback-choose-ques-div mb-5">
              <div className="row">
                <div className="col-lg-10 col-md-10 col-sm-10">
                  <h2 className="mt-1">
                    <p className="font-weight-bold" id="courseName">{location.state.title}</p>
                  </h2>
                  <hr className="titlehr" />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2">
                  <Button id="next" className="btn btn-primary shadow" onClick={ChangeComponent}>
                    Next
                    &nbsp;<BsArrowRight />
                  </Button>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-10 col-md-10 col-sm-10">
                  <div className="categorySection-div">
                    <label className={selectedCategoryId === -1 ? "categorySection-label active" : "categorySection-label"} onClick={handleAllQuestions}>All</label>
                  </div>
                  {
                    location.state.list.map(cat => (
                      <>
                        <div className="categorySection-div">
                          <label className={selectedCategoryId === cat.value ? "categorySection-label active" : "categorySection-label"} onClick={() => showQuestions(cat.value)}>{cat.label}</label>
                        </div>
                      </>
                    ))
                  }
                  <label><BiEdit id="editCategoriesBtn" onClick={handleEditCategories} /></label>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2">
                  {
                    showChooseQuestions && (
                      <>
                        <label className="mt-4 field-label">Add Your own Question</label>
                        <Button
                          id="AddQuestionsBtn"
                          className="shadow"
                          onClick={handleAddaNewQuestionBtn}
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          <FaPlus style={{ marginTop: "-5px" }} />&nbsp; Add Question
                        </Button>
                      </>
                    )
                  }

                  {
                    showAddQuestion && (
                      <>
                        <label className="mt-4 field-label">Choose from Questions</label>
                        <Button
                          id="AddQuestionsBtn"
                          className="shadow"
                          onClick={handleAllQuestions}
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          <GiCardPickup style={{ marginTop: "-5px" }} />&nbsp; Choose Question
                        </Button>
                      </>
                    )
                  }
                </div>
              </div>


              <div className="row" style={{ marginTop: "25px" }}>
                <div className="col-lg-2 col-md-2 col-sm-2">
                  <label>Question Panel</label>
                  <div className="added-questions-box">
                    {
                      addedQuestions.length !== 0
                        ? addedQuestions.map((question, index) => (
                          <div>
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="added-question-name">
                                  <span>{index + 1}) {question.name}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                        : (<div className="row" style={{ fontSize: "12px", textAlign: "center" }}><strong>No Questiones added yet!</strong></div>)
                    }
                  </div>

                </div>
                <div className="col-lg-10 col-md-10 col-sm-10">
                  {
                    showChooseQuestions && (
                      <>
                        <label style={{float: 'left'}}>Choose Questions from the List</label>
                        <span style={{ marginLeft: '10px', textDecoration: 'underline', marginBottom: '10px',float: 'left'}}>Select all</span>
                        <input type="checkbox" name="selectAllSections" checked={allQuestionsChecked} onChange={selectAllQuestions} style={{marginLeft: '5px', width: '17px', height: '17px'}} />
                        
                        <div className="questions-box">
                          <div>
                            {
                              questionData.length !== 0 && flag === 1
                                ? questionData.map((question, index) => (
                                  <div>
                                    <div className="row categoryquestion">
                                      <div className="col-lg-10 col-md-10 col-sm-10">
                                        <strong style={{ float: "left", marginRight: "10px" }}>Q{index + 1}).</strong> <div className="question_name"><div dangerouslySetInnerHTML={{ __html: question.name }} /></div>
                                      </div>
                                      <div className="col-lg-2 col-md-2 col-sm-2">
                                        <div className="row">
                                          <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="question-checkbox-div">
                                              <input type="checkbox"
                                                value={question.id}
                                                onChange={(e) => selectquestions(e, question)}
                                                name="allcategoryquestion"
                                                className="checkbox"
                                                checked={selectedquestions.length !== 0 ? selectedquestions.includes(question.id) ? "checked" : false : false}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-12 col-md-12 col-sm-12">
                                            {
                                              isOptionsOpen
                                                ? <span className="viewmore-label" onClick={() => QuestionCollapseToggle(index)}>View Less <BsChevronUp id="chevronIcon" /></span>
                                                : <span className="viewmore-label" onClick={() => QuestionCollapseToggle(index)}>View More<BsChevronDown id="chevronIcon" /></span>
                                            }
                                          </div>
                                        </div>

                                      </div>
                                      <Collapse isOpened={questioncollapseOpen[index]}>
                                        <hr style={{ marginTop: "15px" }} />
                                        <div className="row">
                                          <span className="question-category-name">
                                            <strong>Category Name: </strong>&nbsp;{question.category_name !== "" ? question.category_name : "No Category"}
                                          </span>
                                        </div>
                                        {
                                          question.options.length > 0
                                            ? (
                                              <div className="row">
                                                
                                                  <span className="question-category-name">
                                                    <strong>Options: </strong>
                                                  </span>
                                                
                                              </div>
                                            )
                                            : null
                                        }
                                        <div className="row">
                                          {
                                            question.options.length > 0
                                              ? question.options.reverse().map(option => {
                                                return (
                                                  <div className="col-md-6 col-lg-6 col-sm-6">
                                                    <div style={{padding: '5px',margin: '5px'}}>
                                                      {option.name}
                                                    </div>
                                                  </div>
                                                )
                                              })
                                              : null
                                          }
                                        </div>
                                      </Collapse>

                                    </div>

                                  </div>
                                ))
                                : null
                            }
                          </div>
                          <div>
                            {allquestionData.length !== 0 && flag === 0
                              ? allquestionData.map((question, index) => (
                                //  < QuestionCard key={question.id} question_details={question} selectedquestions={selectedquestions} selectquestions={selectquestions}/>
                                <div>
                                  <div className="row categoryquestion">
                                    <div className="col-lg-10 col-md-10 col-sm-10">
                                      <strong style={{ float: "left", marginRight: "10px" }}>Q{index + 1}).</strong> <div className="question_name"><div dangerouslySetInnerHTML={{ __html: question.name }} /></div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2">
                                      <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                          <div className="question-checkbox-div">
                                            <input type="checkbox"
                                              value={question.id}
                                              onChange={(e) => selectquestions(e, question)}
                                              name="allcategoryquestion"
                                              className="checkbox"
                                              checked={selectedquestions.length !== 0 ? selectedquestions.includes(question.id) ? "checked" : false : false}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                          {
                                            isOptionsOpen
                                              ? <span className="viewmore-label" onClick={() => QuestionCollapseToggle(index)}>View Less <BsChevronUp id="chevronIcon" /></span>
                                              : <span className="viewmore-label" onClick={() => QuestionCollapseToggle(index)}>View More<BsChevronDown id="chevronIcon" /></span>
                                          }
                                        </div>
                                      </div>

                                    </div>
                                    <Collapse isOpened={questioncollapseOpen[index]}>
                                      <hr style={{ marginTop: "15px" }} />
                                      <div className="row">
                                        <span className="question-category-name">
                                          <strong>Category Name: </strong>&nbsp;{question.category_name !== "" ? question.category_name : "No Category"}
                                        </span>
                                      </div>
                                      {
                                        question.options.length > 0
                                          ? (
                                            <div className="row">
                                              
                                                <span className="question-category-name">
                                                  <strong>Options: </strong>
                                                </span>
                                              
                                            </div>
                                          )
                                          : null
                                      }
                                      <div className="row">
                                        {
                                          question.options.length > 0
                                            ? question.options.reverse().map(option => {
                                              return (
                                                <div className="col-md-6 col-lg-6 col-sm-6">
                                                  <div style={{padding: '5px',margin: '5px'}}>
                                                    {option.name}
                                                  </div>
                                                </div>
                                              )
                                            })
                                            : null
                                        }
                                      </div>
                                    </Collapse>

                                  </div>

                                </div>
                              ))
                              : null
                            }
                          </div>
                          <div className="row" style={{ width: "100%" }}>
                            {
                              questionData.length === 0 && flag === 1
                                ? (<span style={{ fontWeight: "500", textAlign: "center" }}>No Questions available to show!</span>)
                                : null
                            }
                            {
                              allquestionData.length === 0 && flag === 0
                                ? (<span style={{ fontWeight: "500", textAlign: "center" }}>No Questions available to show!</span>)
                                : null
                            }
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    //code for adding a question to the question list

                    showAddQuestion && (
                      <>
                        <div>
                          <label>Type in your Question</label>
                          <div className="mt-3">
                            <RichTextEditor toolbarConfig={toolbarConfig} className="RTE" editorClassName="my-editor" value={newQuestionName} placeholder="Write your own question" onChange={handleQuestionInput} />
                          </div>

                          <div className="row mt-5">
                            <div className="col-lg-9 col-md-9 col-sm-9">
                              <p><span style={{ color: "red" }}>*</span><strong>Note:</strong> Rating will be given on the scale of <strong>1-{location.state.ratingScale}</strong>.</p>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                              <button type="button" className="btn btn-primary" id="saveQuestionBtn" onClick={handleSaveChanges}>Save Question <FaPlus /></button>
                            </div>
                          </div>

                        </div>
                      </>
                    )
                  }
                </div>
                <ToastContainer />
              </div>
            </div>
          </LayoutwithSideNav>
         
        </div>
      </>
    );
  }
};
export default CategoryQuestion;
