import React from "react";

HeadingPrimary.defaultProps ={
    title : 'No Heading'
}

export default function HeadingPrimary ({title}){
 
    return(
        <div>
        <h2 className="mt-1">
          <p className="font-weight-bold" id="courseName">
            {title}
          </p>
          <hr className="titlehr" />
        </h2>
      </div>
    )
}