import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactCountdownClock from "react-countdown-clock";
import { TiTick } from "react-icons/ti";
import { setUnConfirmedCaptains, getTopics, getAllTeams } from "../../api/weeklyChallengeAPIs";
import "./generateTeams.styles.css";

//import { realtimePChannel } from "../challengeLobby/challengeLobby.component";

const GenerateTeams = (props) => {
    const navigate = useNavigate();
    const realtimePChannel = useSelector(state => state.joinChallenge.realtimePChannel);
    const channel = useSelector(state => state.joinChallenge.participantChannel);
    const viewer_channel = useSelector(state => state.joinChallenge.viewerChannel);
    const [allTeamsData, setAllTeamsData] = useState(props.allTeamsData);
    const [captainsConfirmedTeams, setCaptainsConfirmedTeams] = useState([]);
    const [regenerateCaptainsButton, setRegenerateCaptainsButton] = useState(false);
    const [activeTeamMembers, setActiveTeamMembers] = useState([]);
    const [is_allcaptains_confirmed, setIsAllCaptainsConfirmed] = useState(false);
    const [is_startChallenge, setIsStartChallenge] = useState(false);
    const [showCapConfimredTeams, setShowCapConfimredTeams] = useState(true);
    const [showTeamGenerationTimer, setShowTeamGenerationTimer] = useState(true);
    const [viceCaptainId, setViceCaptainId] = useState(null);

    const segColors = [
        '#aec7e8',
        '#ffbb78',
        '#98df8a',
        '#ff9896',
        '#c5b0d5',
        '#c49c94',
        '#f7b6d2',
        '#c7c7c7',
        '#dbdb8d',
        '#9edae5'
    ];
    console.log("active: ", activeTeamMembers);

    useEffect(() => {
        // if(allTeams.length === 0){
        //     setAllTeams(props.allTeams);
        // }
        //check all team captains are confirmed
        let flag = true;
        for (let i = 0; i < allTeamsData.length; i++) {
            if (!captainsConfirmedTeams.includes(allTeamsData[i].id)) {
                flag = false;
            }
        }
        if (flag === true) {
            setShowCapConfimredTeams(false);
            setIsAllCaptainsConfirmed(true);
            setIsStartChallenge(true);
        }


        //subscribing the channel for getting the confirmed captains
        if (channel) {
            channel.subscribe((msg) => {
                console.log(msg);
                if (msg.name === "captain_confirmed") {
                    console.log("hello 1");
                    let team_status_content = msg.data.split(/[_]/);
                    const team_id = team_status_content[0];
                    const vice_captain_id = team_status_content[2];
                    console.log(team_id);
                    const team_name = team_status_content[1];
                    setCaptainsConfirmedTeams(prevData => [...prevData, parseInt(team_id)]);
                    setViceCaptainId(parseInt(vice_captain_id));
                    getTeams();
                }
                if (msg.name === "team_member_status") {
                    let team_status_content = msg.data.split(/[_]/);
                    const team_id = team_status_content[0];
                    const member_id = team_status_content[1];
                    setActiveTeamMembers(prevData => [...prevData, { "team_id": parseInt(team_id), "member_id": parseInt(member_id) }]);
                }
            });

            return function cleanup() {
                channel.unsubscribe();
            };
        }

    });

    const getTeams = async () => {
        //getting teams data
        const teams_data = await getAllTeams(props.challengeDetails.id);
        setAllTeamsData(teams_data.data);
    };

    const handleTeamsConfirmationTimer = () => {
        //check all team captains are confirmed
        let flag = true;
        for (let i = 0; i < allTeamsData.length; i++) {
            if (!captainsConfirmedTeams.includes(allTeamsData[i].id)) {
                flag = false;
            }
        }
        if (flag === false) {
            setRegenerateCaptainsButton(true);
            setIsStartChallenge(false);
        } else {
            setIsAllCaptainsConfirmed(true);
            setIsStartChallenge(true);
        }
        setShowTeamGenerationTimer(false);
    };

    const handleRegenerateTeams = async () => {
        //get all active members data
        let team_data = [];
        let added_teams = [];
        console.log(activeTeamMembers);
        for (let i = 0; i < activeTeamMembers.length; i++) {
            const team_id = activeTeamMembers[i].team_id;
            if (!captainsConfirmedTeams.includes(team_id)) {
                if (!added_teams.includes(team_id)) {
                    let team_members = [];
                    for (let j = 0; j < activeTeamMembers.length; j++) {
                        if (activeTeamMembers[j].team_id === team_id) {
                            const mem_id = activeTeamMembers[j].member_id;
                            team_members.push(mem_id);
                        }
                    }
                    team_members = [...new Set(team_members)];
                    team_data.push({ "team_id": team_id, "active_members": team_members });
                    added_teams.push(team_id);
                }
            }
        }

        const team_regeneration = await setUnConfirmedCaptains(props.challengeDetails.id, team_data);
        if (team_regeneration.message === "Success") {
            channel.publish("team_regenerated", `${props.challengeDetails.id}`);
            //getting teams data
            const teams_data = await getAllTeams(props.challengeDetails.id);
            setAllTeamsData(teams_data.data);

            setShowCapConfimredTeams(false);
            setIsAllCaptainsConfirmed(true);
            setIsStartChallenge(true);
        }
    };

    const handleStartChallenge = () => {
        if (props.weeklyChallengeDetails.random_questions === 0) {
            handleSpinWheelModalShow();
        } else {
            handleGenerateRandomQuestions();
        }
    };
    const handleSpinWheelModalShow = async () => {
        const category_ids = props.weeklyChallengeDetails.category_ids;
        const degree_ids = props.weeklyChallengeDetails.college_university_degree_department_id;
        const topics = await getTopics(category_ids, degree_ids);
        let topic_list = [];
        let color_list = [];
        console.log(topics.data);
        for (let i = 0, j = 0; i < topics.data.length && j < segColors.length; i++, j++) {
            const topic_name = topics.data[i].name;
            topic_list.push(topic_name);
            const color_id = segColors[j];
            color_list.push(color_id);
            if (j === segColors.length - 1) {
                j = -1;
            }
        }
        // setAllTopics(topic_list);
        // setAllSegColors(color_list);
        // setTopicsData(topics.data);
        if (topics) {
            channel.publish("topic_selection_on", JSON.stringify(topics.data));
            viewer_channel.publish("team_generated", `${props.challengeDetails.id}`);
            navigate('/spinWheel', {
                state: {
                    allTopics: topic_list, segColors: color_list, topicsData: topics.data,
                    challenge_id: props.challengeDetails.id, challenge_details: props.challengeDetails, question_index: 0,
                    topic_to_select: "not_random", topic_to_select_id: null, viewer_topic_on: 1
                }
            });
        } else {
            console.log("No topics data found!");
        }
    };

    const handleGenerateRandomQuestions = async () => {
        const category_ids = props.weeklyChallengeDetails.category_ids;
        const degree_ids = props.weeklyChallengeDetails.college_university_degree_department_id;
        const topic_to_select_id = props.challengeDetails.questions[0].topic_id;
        const topics = await getTopics(category_ids, degree_ids);
        let topic_list = [];
        let color_list = [];
        let topic_to_select;
        console.log(topic_to_select_id);
        console.log(topics.data);
        for (let i = 0, j = 0; i < topics.data.length && j < segColors.length; i++, j++) {
            const topic_name = topics.data[i].name;
            topic_list.push(topic_name);
            if (topics.data[i].id === topic_to_select_id) {
                topic_to_select = topic_name;
            }
            const color_id = segColors[j];
            color_list.push(color_id);
            if (j === segColors.length - 1) {
                j = -1;
            }
        }

        if (topics) {
            channel.publish("topic_selection_on", JSON.stringify(topics.data));
            viewer_channel.publish("team_generated", `${props.challengeDetails.id}`);
            console.log(topic_to_select, topic_to_select_id);
            navigate('/spinWheel', {
                state: {
                    allTopics: topic_list, segColors: color_list, topicsData: topics.data,
                    challenge_id: props.challengeDetails.id, challenge_details: props.challengeDetails, question_index: 0,
                    topic_to_select: topic_to_select, topic_to_select_id: topic_to_select_id, viewer_topic_on: 1
                }
            });
        } else {
            console.log("No topics data found!");
        }

        //navigate('/questionnaire', { state: { question_data: challengeDetails.questions, weekly_challenge_id: challengeDetails.id, challenge_details: challengeDetails, is_random: 1, question_index: 0 } });
    };
    console.log(viceCaptainId);
    return (
        <div className="row w-100">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="generate-teams-div">
                    <div className="row">
                        {
                            allTeamsData.length != 0
                                ? (
                                    <div style={{ marginBottom: "5px" }}>
                                        <div className="row">
                                            <div className="col-lg-5 col-md-5 col-sm-4">
                                                {
                                                    is_allcaptains_confirmed && (
                                                        <h4 className="all-captains-confirmed-label">All Captains are confirmed!</h4>
                                                    )
                                                }
                                                {

                                                    showCapConfimredTeams && (
                                                        <h4>Captaincy Confirmed:
                                                            {captainsConfirmedTeams.length > allTeamsData.length ? allTeamsData.length : captainsConfirmedTeams.length}/{allTeamsData.length}</h4>
                                                    )
                                                }
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4">
                                                <h3>Teams Information</h3>
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-2">
                                                {
                                                    regenerateCaptainsButton && (
                                                        <button className="btn btn-primary" id="regenerateCaptainsBtn" onClick={handleRegenerateTeams}>Regenerate Teams</button>
                                                    )
                                                }
                                            </div>
                                            <div className="col-lg-1 col-md-1 col-sm-2">
                                                <div style={{ marginLeft: '10px', marginBottom: '10px', float: 'right' }}>
                                                    {
                                                        showTeamGenerationTimer && (
                                                            <ReactCountdownClock seconds={props.challengeDetails.captain_conmfirmation_timer}
                                                                color="red"
                                                                size={60}
                                                                showMilliseconds={false}
                                                                weight={6}
                                                                alpha={0.9}
                                                                onComplete={handleTeamsConfirmationTimer} />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            is_startChallenge && (
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <button className="btn btn-default" id="start-challenge-btn2" onClick={handleStartChallenge}>Start Arena</button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                                : ''
                        }

                        {
                            allTeamsData.length != 0
                                ? allTeamsData.map(team => (
                                    <div className="col-lg-3 col-md-3 col-sm-4">
                                        <table className="table table-bordered table-curved" id="general-coordinators-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" colSpan="3">
                                                        Team Name : {team.team_name}
                                                        {
                                                            captainsConfirmedTeams.includes(team.id)
                                                                ? (<TiTick id="captain-confirmed-tickmark" />)
                                                                : ''
                                                        }
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    team.members.length > 0
                                                        ? team.members.map(teamMember => (
                                                            <tr>
                                                                <td scope="row">
                                                                    {
                                                                        teamMember.faculty_id != ''
                                                                            ? teamMember.is_captain === 1
                                                                                ? `${teamMember.faculty_name} (C)`
                                                                                : teamMember.is_vice_captain === 1
                                                                                    ? `${teamMember.faculty_name} (VC)`
                                                                                    : teamMember.faculty_name
                                                                            : teamMember.is_captain === 1
                                                                                ? `${teamMember.student_name} (C)`
                                                                                : teamMember.is_vice_captain === 1
                                                                                    ? `${teamMember.student_name} (VC)`
                                                                                    : teamMember.student_name
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                        : (
                                                            <tr>
                                                                <td colSpan="3">
                                                                    <span colSpan="3" className="d-flex justify-content-center" id="no-coordinators-msg-label">This Team has no members!</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                ))
                                : (<span className="d-flex justify-content-center" id="no-coordinators-msg-label">Teams has not generated yet!</span>)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GenerateTeams;