import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { BsDownload, BsDot } from "react-icons/bs";
import Select from "react-select";
import * as XLSX from 'xlsx';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import Navbar from "../../navbar/navbar.component";
import LayoutwithSideNav from "../../common/LayoutwithSideNav";
import { getFeedbackResults } from "../../../api/facultyfeedbackapis";
import "./feedbackResults.styles.css";
import PdfDocument from "./download";

const FeedbackResults = () => {
    const location = useLocation();
    const [master_feedback_id, setMasterFeedbackId] = useState(location.state.master_feedback_id);
    const [feedback_type, setFeedbackType] = useState(location.state.feedback_type);
    const [sub_feedback_id, setSubFeedbackId] = useState(location.state.sub_feedback_id);
    const [feedbackResultsList, setFeedbackResultsList] = useState([]);
    const [allFeedbackResultsList, setAllFeedbackResultsList] = useState([]);
    const [flag, setFlag] = useState(true);
    const [feedbackResultsFlag, setFeedbackResultsFlag] = useState(false);
    const [feedbackDownloadingFlag, setFeedbackDownloadingFlag] = useState(false);
    const [filterByTypeList, setFilterByTypeList] = useState([
        { value: 1, label: "All" },
        { value: 2, label: "Department" },
        { value: 3, label: "Subject" },
        { value: 4, label: "Faculty" },
        { value: 5, label: "Section" }
    ]);
    const [filterByValueList, setFilterByValueList] = useState([]);
    const [filterTypeValue, setFilterTypeValue] = useState(0);
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        if (flag) {
            getAllFeedbackResults();
        }
    }, [flag]);

    const getAllFeedbackResults = async () => {
        setFlag(false);
        let feedbackResults;
        if (feedback_type === "master") {
            feedbackResults = await getFeedbackResults("master_feedback_id", location.state.master_feedback_id);
        } else {
            feedbackResults = await getFeedbackResults("feedback_id", location.state.sub_feedback_id);
        }
        setFeedbackResultsList(feedbackResults.data.feedbacks);
        setAllFeedbackResultsList(feedbackResults.data.feedbacks);
        setTimeout(() => {
            setFeedbackResultsFlag(true);
        }, 3000);
    };

    const handleFilterType = (e) => {
        setFilterTypeValue(parseInt(e.value));
        setSelectedValue(null);
        if (e.value === 1) {
            setFeedbackResultsList(allFeedbackResultsList);
            setFilterByValueList([]);
        } else if (e.value === 2) {
            let results = [];
            for (let i = 0; i < allFeedbackResultsList.length; i++) {
                for (let j = 0; j < allFeedbackResultsList[i].feedbacks.length; j++) {
                    //check value existed
                    let flag = 1;
                    for (let k = 0; k < results.length; k++) {
                        if (results[k].value === allFeedbackResultsList[i].feedbacks[j].branch_name) {
                            flag = 0;
                            break;
                        }
                    }
                    if (flag) {
                        results.push({ value: allFeedbackResultsList[i].feedbacks[j].branch_name, label: allFeedbackResultsList[i].feedbacks[j].branch_name })
                    }
                }
            }
            setFilterByValueList([]);
            setFilterByValueList(results);
        } else if (e.value === 3) {
            let results = [];
            for (let i = 0; i < allFeedbackResultsList.length; i++) {
                for (let j = 0; j < allFeedbackResultsList[i].feedbacks.length; j++) {
                    //check value existed
                    let flag = 1;
                    for (let k = 0; k < results.length; k++) {
                        if (results[k].value === allFeedbackResultsList[i].feedbacks[j].subject_name) {
                            flag = 0;
                            break;
                        }
                    }
                    if (flag) {
                        results.push({ value: allFeedbackResultsList[i].feedbacks[j].subject_name, label: allFeedbackResultsList[i].feedbacks[j].subject_name })
                    }
                }
            }
            setFilterByValueList([]);
            setFilterByValueList(results);
        } else if (e.value === 4) {
            let results = [];
            for (let i = 0; i < allFeedbackResultsList.length; i++) {
                let flag = 1;
                for (let k = 0; k < results.length; k++) {
                    if (results[k].value === allFeedbackResultsList[i].faculty_id) {
                        flag = 0;
                        break;
                    }
                }
                if (flag) {
                    results.push({ value: allFeedbackResultsList[i].faculty_id, label: allFeedbackResultsList[i].faculty_name });
                }
            }
            setFilterByValueList([]);
            setFilterByValueList(results);
        } else if (e.value === 5) {
            let results = [];
            for (let i = 0; i < allFeedbackResultsList.length; i++) {
                for (let j = 0; j < allFeedbackResultsList[i].feedbacks.length; j++) {
                    //check value existed
                    let flag = 1;
                    for (let k = 0; k < results.length; k++) {
                        if (results[k].value === allFeedbackResultsList[i].feedbacks[j].section_name) {
                            flag = 0;
                            break;
                        }
                    }
                    if (flag) {
                        results.push({ value: allFeedbackResultsList[i].feedbacks[j].section_name, label: allFeedbackResultsList[i].feedbacks[j].section_name })
                    }
                }
            }
            setFilterByValueList([]);
            setFilterByValueList(results);
        }
    };

    const handleFilterByValue = (e) => {
        setSelectedValue(e);
        if (filterTypeValue != 0) {
            if (filterTypeValue === 2) {
                let existingResults = allFeedbackResultsList;
                let filteredResults = [];
                for (let i = 0; i < allFeedbackResultsList.length; i++) {
                    let results = [];
                    let flag = 0;
                    for (let j = 0; j < allFeedbackResultsList[i].feedbacks.length; j++) {
                        if (allFeedbackResultsList[i].feedbacks[j].branch_name === e.value) {
                            flag = 1;
                            results.push(allFeedbackResultsList[i].feedbacks[j]);
                        }
                    }

                    if (flag) {
                        existingResults[i].feedbacks = results;
                        filteredResults.push(
                            {
                                "faculty_id": existingResults[i].faculty_id,
                                "faculty_name": existingResults[i].faculty_name,
                                "feedbacks": results
                            });
                    }

                }
                setFeedbackResultsList(filteredResults);
            } else if (filterTypeValue === 3) {
                let existingResults = allFeedbackResultsList;
                let filteredResults = [];
                for (let i = 0; i < allFeedbackResultsList.length; i++) {
                    let results = [];
                    let flag = 0;
                    for (let j = 0; j < allFeedbackResultsList[i].feedbacks.length; j++) {
                        if (allFeedbackResultsList[i].feedbacks[j].subject_name === e.value) {
                            flag = 1;
                            results.push(allFeedbackResultsList[i].feedbacks[j]);
                        }
                    }

                    if (flag) {
                        existingResults[i].feedbacks = results;
                        filteredResults.push(
                            {
                                "faculty_id": existingResults[i].faculty_id,
                                "faculty_name": existingResults[i].faculty_name,
                                "feedbacks": results
                            });
                    }

                }
                setFeedbackResultsList(filteredResults);
            } else if (filterTypeValue === 4) {
                console.log("res => ", allFeedbackResultsList);
                let results = [];
                for (let i = 0; i < allFeedbackResultsList.length; i++) {
                    console.log(allFeedbackResultsList[i].faculty_id, e.value);
                    if (parseInt(allFeedbackResultsList[i].faculty_id) === parseInt(e.value)) {
                        results.push(allFeedbackResultsList[i]);
                    }
                }

                setFeedbackResultsList(results);
            } else if (filterTypeValue === 5) {
                let existingResults = allFeedbackResultsList;
                let filteredResults = [];
                for (let i = 0; i < allFeedbackResultsList.length; i++) {
                    let results = [];
                    let flag = 0;
                    for (let j = 0; j < allFeedbackResultsList[i].feedbacks.length; j++) {
                        if (allFeedbackResultsList[i].feedbacks[j].section_name === e.value) {
                            flag = 1;
                            results.push(allFeedbackResultsList[i].feedbacks[j]);
                        }
                    }

                    if (flag) {
                        existingResults[i].feedbacks = results;
                        filteredResults.push(
                            {
                                "faculty_id": existingResults[i].faculty_id,
                                "faculty_name": existingResults[i].faculty_name,
                                "feedbacks": results
                            });
                    }

                }
                setFeedbackResultsList(filteredResults);
            }
        }
    };

    const downloadDataAsExcelAndZip = () => {
        setFeedbackResultsFlag(false);
        setFeedbackDownloadingFlag(true);
        console.log("in!");
        const zip = new JSZip();
        const cellStyle = { font: { bold: true } };

        feedbackResultsList.forEach((feedback, index) => {
            const key = feedback.faculty_name;
            const workbook = XLSX.utils.book_new();
            console.log(feedback);
            feedback.feedbacks.map((item, index) => {
                let sheetData = [];
                sheetData.push(["", `${item.college_name}`, ""]);
                sheetData.push(["", "STUDENT FEEDBACK REPORT", ""]);
                sheetData.push(["", `ACADEMIC YEAR 2022-23 ${parseInt(item.section_name[0]) % 2 == 0 ? 'EVEN' : 'ODD'} SEMESTER`, ""]);
                sheetData.push([`Branch: ${item.branch_name}`, "", `Section: ${item.section_name}`]);
                sheetData.push([`Subject: ${item.subject_name}`, "", ""]);
                sheetData.push([`Faculty: ${key}`, "", ""]);
                sheetData.push(["Question", "Category", "Score"]);
                item.questions.forEach((question) => {
                    sheetData.push([question.question, question.category_name, question.Score])
                });
                sheetData.push(["", `Overall Effectiveness: ${item.overall_ffectiveness*10}%`, ""]);
                const sheetName = `Sheet${index + 1}`;
                const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
                worksheet["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 10 }];
                worksheet["!rows"] = [{ hpx: 40 }];
                XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
            });

            const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
            zip.file(`${key}.xlsx`, excelBuffer);
        });

        zip.generateAsync({ type: 'blob' })
            .then(blob => {
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = 'faculty_semester_feedbacks.zip';
                downloadLink.click();
                setFeedbackResultsFlag(true);
                setFeedbackDownloadingFlag(false);
            })
            .catch(error => console.error(error));

    };

    async function DownloadDataAsPdf() {
        setFeedbackResultsFlag(false);
        setFeedbackDownloadingFlag(true);
        console.log("downloading pdfs!");
        const zip = new JSZip();
        // for each pdf you have to add the blob to the zip
        try{
            for (const feedback of feedbackResultsList) {
                const MyDocument = () => (
                    <PdfDocument key={feedback.faculty_id} resultsData={feedback.feedbacks} faculty_name={feedback.faculty_name} faculty_gender={feedback.faculty_gender} />
                );
    
                zip.file(`${feedback.faculty_name}.pdf`, pdf(<MyDocument />).toBlob());
    
            }
          
            // once you finish adding all the pdf to the zip, return the zip file
            return zip.generateAsync({ type: "blob" }).then((blob) => {
                saveAs(blob, "faculty_semester_feedbacks.zip");
                setFeedbackResultsFlag(true);
                setFeedbackDownloadingFlag(false);
            });       
        }catch (error) {
            console.error("Error generating or saving the zip file:", error);
            // Handle the error and set the flags accordingly
            setFeedbackResultsFlag(true);
            setFeedbackDownloadingFlag(false);
        }
         
    };


    return (
        <div>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <Navbar />
                </div>
            </div>

            <div className="container-fluid px-0">
                <LayoutwithSideNav>
                    {
                        feedbackResultsFlag === true ? (
                            <div className="show-feedback-results">
                                <div className="row">
                                    <div className="col-lg-10 col-md-10 col-sm-10">
                                        <h2 className="mt-1">
                                            <p className="font-weight-bold" id="courseName">Feedback Results</p>
                                        </h2>
                                        <hr className="titlehr" />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2">
                                        <Dropdown >
                                            <Dropdown.Toggle variant="success" id="donwloadResultsBtn" className="shadow">
                                                <BsDownload /> &nbsp;Download
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={DownloadDataAsPdf}><BsDot style={{ color: "#f40f02", fontSize: "30px" }} />Download Pdf</Dropdown.Item>
                                                <Dropdown.Item onClick={downloadDataAsExcelAndZip}><BsDot style={{ color: "#1d6f42", fontSize: "30px" }} />Download Excel</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-lg-3 col-md-3 col-sm-3">
                                        <div>
                                            <label style={{fontSize:"15px"}}><strong>Filter By Category</strong></label>
                                            <Select
                                                className="dropdowncategories"
                                                onChange={handleFilterType}
                                                classNamePrefix="Select Type"
                                                options={filterByTypeList}
                                                defaultValue={{ label: "All", value: 1 }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3">
                                        <div>
                                            <label style={{fontSize:"15px"}}><strong>Filter By Value</strong></label>
                                            <Select
                                                className="dropdowncategories"
                                                onChange={handleFilterByValue}
                                                classNamePrefix="Select Category"
                                                options={filterByValueList}
                                                value={selectedValue}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3"></div>
                                    <div className="col-lg-3 col-md-3 col-sm-3"></div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div>
                                            {
                                                feedbackResultsList.length !== 0
                                                    ? feedbackResultsList.map(feedback => (
                                                        <>
                                                            <div className="row feedbackList mt-3">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="p-1 table-faculty-name">
                                                                        <span className="label field-label">Faculty Name: </span>
                                                                        <span className="label-value">{feedback.faculty_name}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                feedback.feedbacks.map(subFeedback => (
                                                                    <>
                                                                        <div className="row">
                                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                                <div style={{ display: "flex", justifyContent: "space-between", padding: "5px" }}>
                                                                                    <div>
                                                                                        <span className="label field-label">Subject: </span>
                                                                                        <span className="label-value">{subFeedback.subject_name}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        <span className="label field-label">Department: </span>
                                                                                        <span className="label-value">{subFeedback.branch_name}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        <span className="label field-label">Section: </span>
                                                                                        <span className="label-value">{subFeedback.section_name}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                                <table className="table shadow table-curved table-hover table-responsive{-sm|-md|-lg|-xl}" id="fedbackListTable">
                                                                                    <thead>
                                                                                        {/* <tr className="thead1">
                                                                                        <td colSpan={3}>
                                                                                            <div style={{ display: "flex", justifyContent: "space-between", padding: "5px" }}>
                                                                                                <div>
                                                                                                    <span className="t-label">Feedback Type: </span>
                                                                                                    <span className="t-label-value">{subFeedback.feedback_type}</span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span className="t-label">Received From: </span>
                                                                                                    <span className="t-label-value">{subFeedback.feedback_received_from}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr> */}
                                                                                        <tr className="thead2">
                                                                                            <th scope="col">Question</th>
                                                                                            <th scope="col" style={{ textAlign: "center" }}>Category</th>
                                                                                            <th scope="col" style={{ textAlign: "center" }}>Score</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            subFeedback.questions.length != 0
                                                                                                ? subFeedback.questions.map(question => (
                                                                                                    <tr>
                                                                                                        <td scope="row" dangerouslySetInnerHTML={{ __html: question.question }}></td>
                                                                                                        <td scope="row" style={{ textAlign: "center" }}>{question.category_name !== "" ? question.category_name : "-"}</td>
                                                                                                        <td scope="row" style={{ textAlign: "center" }}>{(question.Score).toFixed(2)}</td>
                                                                                                    </tr>
                                                                                                ))
                                                                                                : ""
                                                                                        }
                                                                                        {
                                                                                            <tr>
                                                                                                <td style={{ fontSize: "17px", paddingLeft: "10px" }}><span className="field-label">Sent To:</span> <label>{subFeedback.total_strength}</label></td>
                                                                                                <td style={{ fontSize: "17px", textAlign: "center" }}><span className="field-label">Received From:</span> <label>{subFeedback.feedback_received_from}</label></td>
                                                                                                <td style={{ fontSize: "17px", textAlign: "center" }}><span className="field-label">Teaching Effectiveness:</span> <label>{(parseFloat(subFeedback.overall_ffectiveness)).toFixed(2)}</label></td>
                                                                                            </tr>
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                                {/* <TableContainer>
                                                                                <Table id="fedbackListTable">
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell>Question</TableCell>
                                                                                            <TableCell>Category</TableCell>
                                                                                            <TableCell>Score</TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {
                                                                                            subFeedback.questions.length != 0
                                                                                                ? subFeedback.questions.map(question => (
                                                                                                    <TableRow key={question.id}>
                                                                                                        <TableCell>{question.question}</TableCell>
                                                                                                        <TableCell>{question.category_name}</TableCell>
                                                                                                        <TableCell>{question.Score}</TableCell>
                                                                                                    </TableRow>
                                                                                                ))
                                                                                                : ""
                                                                                        }
                                                                                        <TableRow>
                                                                                            <TableCell><span style={{ fontWeight: 500 }}>Sent To:</span> <label>{subFeedback.total_strength}</label></TableCell>
                                                                                            <TableCell><span style={{ fontWeight: 500 }}>Received From:</span> <label>{subFeedback.feedback_received_from}</label></TableCell>
                                                                                            <TableCell><span style={{ fontWeight: 500 }}>Overall Effectiveness:</span> <label>{subFeedback.overall_ffectiveness}</label></TableCell>
                                                                                        </TableRow>
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer> */}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))
                                                            }

                                                            <hr />
                                                        </>
                                                    ))
                                                    : (<p style={{ fontSize: '18px', textAlign: 'center', marginTop: '5%', fontWeight: '500' }}>No Feedback Reports to show!</p>)
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    // feedbackResultsList.length !== 0
                                    //     ? (<PdfDocument resultsData={feedbackResultsList[7].feedbacks} faculty_name={feedbackResultsList[7].faculty_name} />)
                                    //     : ""
                                }
                            </div>
                        )
                            : feedbackDownloadingFlag == false ? (
                                <div>
                                    <img src="/images/feedback_images/generate-report-giff.gif" className="reports-generate-gif" />
                                    <p className="generating-reports-label">Generating Reports . . .</p>
                                </div>
                            )
                            : ""
                    }
                    {
                        feedbackDownloadingFlag && (
                            <div>
                                <img src="/images/feedback_images/download-reports-gifff.gif" className="reports-generate-gif" />
                                <p className="generating-reports-label">Downloading Reports . . .</p>
                            </div>
                        )
                    }
                </LayoutwithSideNav>
            </div>
        </div >
    );
};

export default FeedbackResults;

