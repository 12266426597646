import * as React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArticleIcon from '@mui/icons-material/Article';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import * as XLSX from 'xlsx';
import moment from "moment";


// Sample Array
// const headArraySubject = [
//   {
//     id: 'department_name',
//     label: 'Department',
//   },
//   {
//     id: 'semester_id',
//     label: 'Semester',
//   },
// ];


export default function TableTopMenuCommon({
  setMainMenuOpen,
  mainMenuOpen,
  data,
  headArray
}) {
  // const [mainMenuOpen, setMainMenuOpen] = React.useState(null);
  const open = Boolean(mainMenuOpen);

  const downloadExcelFromJSON = (tableData,headArray) => {

    const timeStamp = moment().format("YYYY-MM-DD HH.mm.ss")

    const newData = tableData.map((row,i) => {
      const newRow = headArray.reduce((cumulated,currentElem)=>{
        cumulated[currentElem.label] = row[currentElem.id]
        return cumulated
      },{index : i+1})
      // console.log(newRow)
      return newRow;
    });
    
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Faculty");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, `Table Excel Download-${timeStamp}.xlsx`);
  };
  

  const handleDownloadExcel = () => {
    downloadExcelFromJSON(data,headArray)
    setMainMenuOpen(null);
  };

  const handleClose = () => {
    setMainMenuOpen(null);
  };

  return (
    <Paper>
      <Menu
        id="basic-menu"
        anchorEl={mainMenuOpen}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList
          sx={{
            py: "0px",
          }}
          dense
        >
          <MenuItem onClick={handleDownloadExcel}>
            {" "}
            <ListItemIcon>
              <SaveAltIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Download Excel</ListItemText>
          </MenuItem>
          {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
          {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
        </MenuList>
      </Menu>
    </Paper>
  );
}
