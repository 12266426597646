import React from "react";
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { Button } from "@mui/material";

const CustomizedCheckBox = styled(Checkbox)(({ sx }) => ({
    position: 'absolute',
    opacity: 0, // Hide the actual checkbox
    width: '100%',
    height: '100%',
    cursor: 'pointer',


}));

const CheckBoxLabel = styled('label')(({ sx }) => ({
    width: '100%',
    display: 'block',
    cursor: 'pointer',
}));

export default function MaterialCheckBox({ label, sx, value, onChange, defaultchecked, ...props }) {
    return (
        <Button {...props}
            sx={{
                position: 'relative',
                '&:hover': {
                    backgroundColor: props?.onHoverColor ? props?.onHoverColor : "", // Change the background color on hover
                },
                ...sx, // Merge any additional styles passed as props
            }}
        >
            <CheckBoxLabel>
                <CustomizedCheckBox
                    onChange={onChange}
                    defaultChecked={defaultchecked}
                    value={value}
                />
                {label}
            </CheckBoxLabel>
        </Button>
    );
}
