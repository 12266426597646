import React, { useEffect, useState } from "react";
import Breadcrumb from "../common/Breadcrumb";
import Box from "@mui/material/Box";
import HeadingWithButtons from "../common/HeadingWithButtons";
import { createSubjectFeedbacks } from "../../store/actions/facultyPerformanceReview.actions";
import { setNotification } from "../../store/reducers/common.reducer";
// import FullScreenLoader from "../components/common/FullScreenLoader";
// import Grow from "@mui/material/Grow";
// import InComponentLoader from "../components/common/InComponentLoader";
import SendSurvey from "./SendSurvey";
import { useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import moment from 'moment';
import { setSelectedSendSurvey } from "../../store/reducers/facultyPerformanceReview.reducer";

export default function SendSurveyFullWidth() {

  const {selectedSendSurvey} = useSelector((state) => state.FacultyPerformanceReview);
  const [startDate,setStartDate] = useState(moment().add(1, 'minutes'));
  const [endDate,setEndDate] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(()=>{
    if(!(selectedSendSurvey?.length >0)){
      navigate('/facultyPerformanceReview')
    }
  },[selectedSendSurvey,navigate])


  const handleCancel = ()=>{
    navigate('/facultyPerformanceReview')
  }

  const handleSendSurveyAPI = async () =>{

    // Validating Start and End Times.
    let errorMessage='';
    let validationError = false;

    if(!endDate){
      validationError = true;
      // errorMessage.push('Please enter Start Date\n')
      errorMessage = errorMessage + 'Please enter Start Date.\n'  
    }
    if(!endDate){
      validationError = true;
      // errorMessage.push('Please enter End Date\n')
      errorMessage = errorMessage + 'Please enter End Date.\n'  
    }
    if (startDate < moment().add(-10, 'minutes')) {
      validationError = true;
      // errorMessage.push('Please select valid start date. Start Date cannot be before the current date.\n')

      errorMessage = errorMessage + 'Please select valid start date. Start Date cannot be before the current date.\n'
    }
    if (endDate < startDate) {
      validationError = true;
      // errorMessage.push('Please select valid End Date. End Date cannot be before the Start Date.\n')

      errorMessage = errorMessage + 'Please select valid End Date. End Date cannot be before the Start Date.\n'
    }

    console.log(validationError);
    console.log(JSON.stringify(errorMessage));


    if(validationError){
      dispatch( setNotification({ 
        openNotification: true,
        notificationMessage: errorMessage,
        // notificationMessage: errorMessage.map((error, index)=>{
        //   return <li key={index}>{error}</li>
        // }),
        notificationType: "warning",
      }));
      
      return ;
    }

    // console.log(selectedSendSurvey)
    const selectedSendSurveyAPIData = selectedSendSurvey.map(({college_account_id,subject_semester_id,unit_id,section_id,elective})=>{
      // console.log(elective);
      return {
        college_account_id,
        subject_semester_id,
        unit_id,
        section_id : !elective ? section_id : "Null",
        elective_section_id : elective ? section_id : "Null",
        start_time : startDate?.format("YYYY-MM-DD hh:mm:ss"),
        doe : endDate?.format("YYYY-MM-DD hh:mm:ss")
      }
    })

    // console.log(selectedSendSurveyAPIData)
    const response = await dispatch(createSubjectFeedbacks(selectedSendSurveyAPIData));
    console.log(response.data)

    const {sections_skipped_no_students,sections_created} = response?.data;

    if (response?.data?.status  !== 200) {
      dispatch( setNotification({ 
        openNotification: true,
        notificationMessage: `Error : ${response?.data?.message}, Status : ${response?.data?.status}, Sending Faculty Performance Surveys Failed. Please try again.`,
        notificationType: "error",
      }));
      
      return ;
    }
    
    // If No Feedbacks are sent as of any reason, probably cause no students exist in the section.
    if (response?.data?.status  === 200 && sections_skipped_no_students?.length > 0) {
      let errorMessage = null;
      sections_created?.length === 0 ? 
      errorMessage = `No Students exist in the selected sections. Could not send any feedbacks.` 
      : 
      errorMessage = `Successfully sent Faculty Performance Surveys.\n Some Sections don't have Students. Could not send feedback to some of these sections.`;
      
      dispatch( setNotification({ 
        openNotification: true,
        notificationMessage: errorMessage,
        notificationType: "warning",
      }));
    }

    else if(response?.data?.status  === 200 && sections_skipped_no_students?.length === 0){
      dispatch( setNotification({ 
        openNotification: true,
        notificationMessage: `Successfully sent Faculty Performance Surveys.`,
        notificationType: "success",
      }));
    }

    dispatch(setSelectedSendSurvey({selectedSendSurvey : []}));
    navigate('/facultyPerformanceReview')
  }


  return (
    <React.Fragment>
      <Box sx={{ padding: "0 0 15px 0", m: 0 }}>
        <Breadcrumb
          currentPage={"Send Survey"}
          links={[
            {
              name: "Faculty Performance Review",
              path: "/facultyPerformanceReview",
            },
          ]}
        />
      </Box>

      <Box sx={{ pl: "1.5rem" }}>
        <HeadingWithButtons
            onCancelClick ={handleCancel}
            onSubmitClick ={handleSendSurveyAPI}
          submitLabel={"Send Surveys"}
          title={"Add Faculty to Section"}
        />
        <SendSurvey 
        sx={{pt :3}}
        startDate={startDate}
        setStartDate ={setStartDate}
        endDate = {endDate}
        setEndDate ={setEndDate}
        />
      </Box>
    </React.Fragment>
  );
}
