import React  from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Logout() {
    const navigate = useNavigate();
    const facultyWebBaseUrl = useSelector(state => state.Common?.facultyWebBaseUrl)

    const userCollegeId = JSON.parse(localStorage.getItem('user'))?.college_id;

    if(!userCollegeId){
        navigate(-1);
        return (
            <div>
                Logging you out.
            </div>
        )
    
    }
    console.log("logging out");
    const user = localStorage.getItem('user');
    localStorage.clear();
    localStorage.setItem('user', user);

    window.location.replace(`${facultyWebBaseUrl}/logout.html`);

    return(
        <div>
            Logging you out.
        </div>
    )

}

