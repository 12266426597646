import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { current_user_id, getAllTeams, getEnrolledMembers, getLeaderBoard, getMyTeam, getWeeklyChallengeData, getWeeklyChallengeDetails, setAllTeamChat, setCaptaincyStatus, submitChallengeAnswer } from "../../../api/weeklyChallengeAPIs";
import { closePAblyConnection } from "../../../store/reducers/joinChallenge.reducer";
import ReactCountdownClock from "react-countdown-clock";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RiTeamLine } from "react-icons/ri";
import { BiChat } from "react-icons/bi";
import { AiFillLock } from "react-icons/ai";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { AiFillCloseCircle } from "react-icons/ai";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { FaLongArrowAltRight } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import moment from "moment";
import { Buffer } from "buffer";
//import * as joinchallenge from "../../../store/actions/joinchallengeactions";
//import RichTextEditor from "react-rte";
import styled from "styled-components";
import "./joinChallenge.styles.css";
import Wheel from "../spinWheel/spinWheel.component";
//import { realtimePChannel } from "../userChallengeLobby/userChallengeLobby.component";
import LeaderBoard from "../../leaderBoard/leaderBoard.component";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Chatwidget from "../../chatwindow/chatroom";
import { messages } from "../../chatwindow/chatroom";
import notification from "../Notification.mp3";
import { firebase_db } from "../../chatwindow/chatroom";

const JoinChallenge = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const realtimePChannel = useSelector(state => state.joinChallenge.realtimePChannel);
    const channel = useSelector(state => state.joinChallenge.participantChannel);
    const [challengeLobby, setChallengeLobby] = useState(true);
    const [isTeamsGeneration, setIsTeamsGeneration] = useState(false);
    const [generatingTeamsLoader, setGeneratingTeamsLoader] = useState(false);
    const [spinWheelLoader, setSpinWheelLoader] = useState(false);
    const [showSpinWheel, setShowSpinWheel] = useState(false);
    const [showQuestions, setShowQuestions] = useState(false);
    const [isShowLeaderBoard, setIsShowLeaderBoard] = useState(false);
    const [isShowFinalLeaderBoard, setIsShowFinalLeaderBoard] = useState(false);
    const [leaderBoardLoader, setLeaderBoardLoader] = useState(false);
    const [challengeDetails, setChallengeDetails] = useState(location.state.challengeDetails);
    const [weekly_challenge_details, setWeeklyChallengeDetails] = useState(null);
    const [weekly_challenge_data, setWeeklyChallengeData] = useState(null);
    const [enrollsList, setEnrollersList] = useState([]);
    const [allTeams, setAllTeams] = useState([]);
    const [myTeam, setMyTeam] = useState([]);
    const [myTeamRank, setMyTeamRank] = useState(1);
    const [is_captain, setIsCaptain] = useState(false);
    const [myTeamName, setMyTeamName] = useState(null);
    const [myTeamNewName, setMyTeamNewName] = useState("");
    const [showCapConfirmedAlert, setShowCapConfirmedAlert] = useState(true);
    const [showCapConfirmedLabel, setShowCapConfirmedLabel] = useState(false);
    const [spinWheelData, setSpinWheelData] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [questionData, setQuestionData] = useState("");
    const [answer, setAnswer] = useState("");
    const [selectedoption, setSelectedOption] = useState(null);
    const [timetaken, setTimeTaken] = useState(new Date().getTime());
    const [question_submit, setQuestionSubmit] = useState(false);
    const [answer_text, setAnswerText] = useState("");
    const [showResults, setShowResults] = useState(false);
    const [leaderBoardData, setLeaderBoardData] = useState([]);
    const [showOtherTeamsModal, setShowOtherTeamsModal] = useState(false);
    const [showChangeTeamNameModal, setShowChangeTeamNameModal] = useState(false);
    const [duplicateTeamNameError, setDuplicateTeamNameError] = useState(false);
    const [activeMembers, setActiveMembers] = useState([]);
    const [showChallengeCloseAlertModal, setShowChallengeCloseAlertModal] = useState(false);
    const [teamCaptainConfirmed, setTeamCaptainConfirmed] = useState(false);
    const [capConfirmedTeams, setCapConfirmedTeams] = useState([]);
    const [questionPosition, setQuestionPosition] = useState(0);
    const [newmsgrecieved, setnewmsgrecieved] = useState(false);
    const [showQuestionTimer, setShowQuestionTimer] = useState(true);
    const [showLeaderBoardTimer, setShowLeaderBoardTimer] = useState(true);
    const [deleteChat, setDeleteChat] = useState(false);
    const [showParticipantTeamGenTimer, setShowParticipantTeamGenTimer] = useState(true);
    const [showToastTimeAlert, setShowToastTimeAlert] = useState(false);
    const [viceCaptain, setViceCaptain] = useState(null);
    let intervalStopper = useRef(true);
    var timeInterval;
    const audio = new Audio(notification);

    const bordercol = [
        "green",
        "yellow",
        "blue",
        "red",
        "green",
        "yellow",
        "blue",
        "red",
    ];
    const options = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
    const optionbgcolors = [
        "#f7d5b2",
        "#fee091",
        "#f1c7db",
        "#bdcaff",
        "#f7d5b2",
        "#fee091",
        "#f1c7db",
        "#bdcaff",
    ];
    const img_extensions = [
        'png',
        'jpg',
        'jpeg',
        'gif',
        'tiff'
    ];
    const video_extensions = [
        'mp4',
        'mov',
        'wmv',
        'avi',
        'flv',
        'f4v',
        'swf',
        'mkv'
    ];
    const audio_extensions = [
        'mp3',
        'aac',
        'flac',
        'alac',
        'wac',
        'aiff',
        'dsd',
        'pcm'
    ];

    useEffect(() => {
        if (challengeDetails.is_completed === 1) {
            navigate("/weeklyChallenge");
        }

        if (challengeLobby && enrollsList.length === 0) {
            getEnrollers();
        }
        if (weekly_challenge_details === null) {
            getMoreWeeklyChallengeDetails();
        }
        if (weekly_challenge_data === null) {
            getChallengeData();
        }
        if (channel) {
            channel.subscribe((msg) => {
                console.log(msg);
                if (msg.name === "topic_selection_on") showspinwheelloader(msg.data);
                else if (msg.name === "topic_selected") showSpinwheel(msg.data);
                else if (msg.name === "question") updateQuestion(msg.data);
                else if (msg.name === "team_generated") generatingTeams();
                else if (msg.name === "show_team_timer") showTeamTimer();
                else if (msg.name === "leader_board") showLeaderboard(msg.data);
                else if (msg.name === "team_regenerated") regenerateteam();
                else if (msg.name === "final_leader_board") finalleaderboard(msg.data);
                else if (msg.name === "captain_confirmed") setCaptainConfirmed(msg.data);
            });

            return function cleanup() {
                channel.unsubscribe();
            };
        }
    });

    const getMoreWeeklyChallengeDetails = async () => {
        const details = await getWeeklyChallengeDetails(challengeDetails.id);
        setWeeklyChallengeDetails(details.data);
    };

    const getChallengeData = async () => {
        const challengeData = await getWeeklyChallengeData(challengeDetails.id);
        setWeeklyChallengeData(challengeData.data);
    }

    const getEnrollers = async () => {
        const enrollers = await getEnrolledMembers(challengeDetails.id);
        let enrollers_list = [];
        for (let i = 0; i < enrollers.data.length; i++) {
            let participant_type, participant_name, department_name, roll_number, participant_id;
            if (enrollers.data[i].college_account_id !== '') {
                participant_type = "Facuty";
                participant_name = enrollers.data[i].faculty_first_name + " " + enrollers.data[i].faculty_last_name;
                department_name = enrollers.data[i].department_name;
                roll_number = "-";
                participant_id = enrollers.data[i].faculty_id;
            } else {
                participant_type = "Student";
                participant_name = enrollers.data[i].student_name;
                department_name = enrollers.data[i].department_name;
                roll_number = enrollers.data[i].student_roll_number;
                participant_id = enrollers.data[i].student_id;
            }
            const status = "Active";
            enrollers_list.push({
                "participant_type": participant_type,
                "participant_name": participant_name,
                "department_name": department_name,
                "roll_number": roll_number,
                "participant_id": participant_id,
            });
        }
        setEnrollersList(enrollers_list);
    };

    const showspinwheelloader = (data) => {
        setSpinWheelData(JSON.parse(data));
        setChallengeLobby(false);
        setIsTeamsGeneration(false);
        setIsShowLeaderBoard(false);
        setShowSpinWheel(false);
        setShowQuestions(false);
        setIsShowFinalLeaderBoard(false);
        setSpinWheelLoader(true);
        setLeaderBoardLoader(false);
        setShowLeaderBoardTimer(true);
    };

    const showSpinwheel = (data) => {
        setSelectedTopic(parseInt(data));
        setSpinWheelData(weekly_challenge_data.topics);

        setChallengeLobby(false);
        setIsTeamsGeneration(false);
        setSpinWheelLoader(false);
        setIsShowLeaderBoard(false);
        setShowQuestions(false);
        setIsShowFinalLeaderBoard(false);
        setLeaderBoardLoader(false);
        setShowSpinWheel(false);
        setTimeout(function () {
            setShowSpinWheel(true);
        }, 100);

        if (weekly_challenge_details.participation_type === 1 && myTeam.length === 0) {
            resetMyTeam();
        }
    };
    const updateQuestion = (data) => {
        getChallengeData();
        const question = JSON.parse(data);
        setQuestionData(JSON.parse(data));
        setQuestionPosition(parseInt(question.current_pos) + 1);
        console.log(question);
        // setTimeTaken(moment);
        // setAnswer(question.field_type === 1 ? RichTextEditor.createEmptyValue() : "",);
        setShowQuestionTimer(true);
        setShowQuestions(false);
        setChallengeLobby(false);
        setIsTeamsGeneration(false);
        setSpinWheelLoader(false);
        setShowSpinWheel(false);
        setIsShowLeaderBoard(false);
        setIsShowFinalLeaderBoard(false);
        setGeneratingTeamsLoader(false);
        setLeaderBoardLoader(false);
        setTimeTaken(new Date().getTime());
        setShowQuestions(true);

        timealert(weekly_challenge_details.time_per_question);
        if (weekly_challenge_details.participation_type === 1 && myTeam.length === 0) {
            resetMyTeam();
        }
    };
    console.log("-------------");
    console.log(showQuestions);
    console.log(showQuestionTimer);
    if (weekly_challenge_details) {
        console.log(weekly_challenge_details.time_per_question);
    }
    console.log(weekly_challenge_data);
    console.log("---------");
    const generatingTeams = async () => {
        //show teams generation loader
        setChallengeLobby(false);
        setIsTeamsGeneration(false);
        setSpinWheelLoader(false);
        setShowSpinWheel(false);
        setIsShowLeaderBoard(false);
        setIsShowFinalLeaderBoard(false);
        setShowQuestions(false);
        setLeaderBoardLoader(false);
        setGeneratingTeamsLoader(true);
    };

    const setCaptainConfirmed = (msg) => {
        const team_data = msg.split(/[_]/);
        const team_id = team_data[0];
        const vice_captain_id = team_data[2];
        if (parseInt(team_id) === parseInt(myTeam.id)) {
            setTeamCaptainConfirmed(true);
            setCapConfirmedTeams(prevData => [...prevData, parseInt(team_id)]);
        }
        showTeams();
    };

    const showTeams = async () => {
        const myteam = await getMyTeam(challengeDetails.id);
        setMyTeam(myteam.data);
        let is_captain = false;
        for (let i = 0; i < myteam.data.members.length; i++) {
            if (myteam.data.members[i].is_captain === 1) {
                if (myteam.data.members[i].faculty_id !== "") {
                    if (current_user_id === myteam.data.members[i].faculty_id) {
                        is_captain = true;
                    }
                } else {
                    if (current_user_id === myteam.data.members[i].student_id) {
                        is_captain = true;
                    }
                }
            }
        }
        console.log(myteam.data.team_name);
        setMyTeamName(myteam.data.team_name);
        setIsCaptain(is_captain);
        setChallengeLobby(false);
        setSpinWheelLoader(false);
        setShowSpinWheel(false);
        setShowQuestions(false);
        setIsShowFinalLeaderBoard(false);
        setIsShowLeaderBoard(false);
        setGeneratingTeamsLoader(false);
        setLeaderBoardLoader(false);
        setIsTeamsGeneration(true);
    };

    const resetMyTeam = async () => {
        const myteam = await getMyTeam(challengeDetails.id);
        setMyTeam(myteam.data);
        let is_captain = false;
        for (let i = 0; i < myteam.data.members.length; i++) {
            if (myteam.data.members[i].is_captain === 1) {
                if (myteam.data.members[i].faculty_id !== "") {
                    if (current_user_id === myteam.data.members[i].faculty_id) {
                        is_captain = true;
                    }
                } else {
                    if (current_user_id === myteam.data.members[i].student_id) {
                        is_captain = true;
                    }
                }
            }
        }
        setMyTeamName(myteam.data.team_name);
        setIsCaptain(is_captain);
    };

    const showTeamTimer = () => {
        showTeams();
        setTimeout(async () => {
            const myteam = await getMyTeam(challengeDetails.id);
            if (!capConfirmedTeams.includes(parseInt(myteam.data.id))) {
                channel.publish("team_member_status", `${myteam.data.id}_${current_user_id}`);
            }
        }, (challengeDetails.captain_conmfirmation_timer * 1000 - 10000));
    };

    const showLeaderboard = async (msg) => {
        const data = msg.split(/[_]/);
        const timer = parseInt(data[0]);
        const question_position = parseInt(data[1]);
        const question_id = parseInt(data[2]);
        //get leaderboard data
        const leaderboard = await getLeaderBoard(challengeDetails.id, question_id);
        setLeaderBoardData(leaderboard.data);
        //getting team rank
        if (weekly_challenge_details.participation_type === 1) {
            let team_rank = 1;
            for (let i = 0; i < leaderboard.data.length; i++) {
                if (leaderboard.data[i].team_id === myTeam.id) {
                    team_rank = i + 1;
                    break;
                }
            }
            setMyTeamRank(team_rank);
        } else {
            let team_rank = 1;
            for (let i = 0; i < leaderboard.data.length; i++) {
                if (leaderboard.data[i].student_id === current_user_id || leaderboard.data[i].college_account_id === current_user_id) {
                    team_rank = i + 1;
                    break;
                }
            }
            setMyTeamRank(team_rank);
        }


        setChallengeLobby(false);
        setIsTeamsGeneration(false);
        setSpinWheelLoader(false);
        setShowSpinWheel(false);
        setShowQuestions(false);
        setIsShowFinalLeaderBoard(false);
        setGeneratingTeamsLoader(false);
        setLeaderBoardLoader(false);
        setIsShowLeaderBoard(true);
        setShowToastTimeAlert(false);

        if (weekly_challenge_details.participation_type === 1 && myTeam.length === 0) {
            resetMyTeam();
        }

        audio.pause();
        clearInterval(timeInterval);
        timeInterval = null;
    };
    const regenerateteam = () => {
        showTeams();
        setShowCapConfirmedAlert(false);
        setShowCapConfirmedLabel(true);
    };
    const finalleaderboard = async (msg) => {
        const data = msg.split(/[_]/);
        const timer = parseInt(data[0]);
        const question_position = parseInt(data[1]);
        const question_id = parseInt(data[2]);
        //get final leaderboard data
        const leaderboard = await getLeaderBoard(challengeDetails.id, question_id);
        setLeaderBoardData(leaderboard.data);
        //getting team rank
        if (weekly_challenge_details.participation_type === 1) {
            let team_rank = 1;
            for (let i = 0; i < leaderboard.data.length; i++) {
                if (leaderboard.data[i].team_id === myTeam.id) {
                    team_rank = i + 1;
                    break;
                }
            }
            setMyTeamRank(team_rank);
        } else {
            let team_rank = 1;
            for (let i = 0; i < leaderboard.data.length; i++) {
                if (leaderboard.data[i].student_id === current_user_id || leaderboard.data[i].college_account_id === current_user_id) {
                    team_rank = i + 1;
                    break;
                }
            }
            setMyTeamRank(team_rank);
        }
        setChallengeLobby(false);
        setIsTeamsGeneration(false);
        setSpinWheelLoader(false);
        setShowSpinWheel(false);
        setShowQuestions(false);
        setIsShowLeaderBoard(false);
        setGeneratingTeamsLoader(false);
        setLeaderBoardLoader(false);
        setIsShowFinalLeaderBoard(true);
        setShowToastTimeAlert(false);

        //setting team chat
        if (weekly_challenge_details.participation_type === 1 && is_captain) {
            let chatmessages = JSON.stringify(messages);
            let chatmessagesJsonB64 = Buffer.from(chatmessages).toString("base64");
            const insertMsgs = await setAllTeamChat(challengeDetails.id, myTeam.id, chatmessagesJsonB64);
        }
        if (weekly_challenge_details.participation_type === 1 && myTeam.length === 0) {
            resetMyTeam();
        }

        audio.pause();
        clearInterval(timeInterval);
        timeInterval = null;
    };

    const handleTeamTimerComplete = () => {
        setShowParticipantTeamGenTimer(false);
    };

    console.log(challengeDetails);
    console.log(weekly_challenge_details);
    const handleCaptaincyBtn = () => {
        setShowChangeTeamNameModal(true);
    }
    const handleChangeTeamNameModalClose = () => setShowChangeTeamNameModal(false);
    const handleChangeTeamName = (e) => {
        e.preventDefault();
        setMyTeamNewName(e.target.value);
        setDuplicateTeamNameError(false);
    }
    const handleAcceptCaptaincy = async () => {
        let team_name = myTeamNewName;
        let team_name2 = myTeamName;
        if (myTeamName !== myTeamNewName) {
            team_name = myTeamNewName;
            if (myTeamNewName === "") {
                team_name2 = myTeamName;
            } else {
                team_name2 = myTeamNewName;
            }
        } else {
            team_name = "";
            team_name2 = myTeamName;
        }
        const acceptCap = await setCaptaincyStatus(challengeDetails.id, myTeam.id, team_name, 1, viceCaptain);
        if (acceptCap.message === "Success") {
            setShowCapConfirmedAlert(false);
            setShowCapConfirmedLabel(true);
            setShowChangeTeamNameModal(false);
            channel.publish("captain_confirmed", `${myTeam.id}_${team_name2}_${viceCaptain}`);
        } else if (acceptCap.message === "Duplicate team name found. Please change the team name") {
            setDuplicateTeamNameError(true);
        }
    };

    const handleChangeViceCaptain = (e) => {
        console.log(e.target.value);
        setViceCaptain(e.target.value);
    };
    console.log("vice cap", viceCaptain);
    const optionselected = (optionid) => {
        if (weekly_challenge_details.participation_type === 1) {
            if (weekly_challenge_data.is_captain === 1 || weekly_challenge_data.is_vice_captain === 1) {
                if (optionid !== selectedoption) {
                    setSelectedOption(optionid);
                } else {
                    setSelectedOption(null);
                }
            }
        } else {
            if (optionid !== selectedoption) {
                setSelectedOption(optionid);
                } else {
                setSelectedOption(null);
            }
        }
    };
    const onAnswerChange = (event) => {
        let answer = "";
        answer = event.target.value;
        setAnswer(answer);
    };
    const onSubmitAnswer = async () => {
        if (channel && (selectedoption || answer)) {
            let ans = answer.toString("html");
            let option_id;
            if (questionData.field_type === 0) {
                option_id = selectedoption;
                ans = "";
            } else if (questionData.field_type === 1) {
                option_id = null;
                ans = ans.replace(/<\/?[^>]+(>|$)/g, "") ? answer : ""
            }
            let time_taken = Math.round((new Date().getTime() - timetaken) / 1000);
            if (time_taken > parseInt(weekly_challenge_details.time_per_question)) {
                time_taken = parseInt(weekly_challenge_details.time_per_question);
            }
            if (weekly_challenge_details.participation_type === 1) {
                const submitAnswer = await submitChallengeAnswer(myTeam.id, challengeDetails.id, questionData.id, parseInt(option_id), ans, time_taken);
            } else {
                const submitAnswer = await submitChallengeAnswer("", challengeDetails.id, questionData.id, parseInt(option_id), ans, time_taken);
            }

            setSelectedOption(null);
            setAnswer("");

            setChallengeLobby(false);
            setIsTeamsGeneration(false);
            setSpinWheelLoader(false);
            setShowSpinWheel(false);
            setShowQuestions(false);
            setIsShowLeaderBoard(false);
            setGeneratingTeamsLoader(false);
            setIsShowFinalLeaderBoard(false);
            setLeaderBoardLoader(true);
        }

        setSelectedOption(null);
        setAnswer("");

        setChallengeLobby(false);
        setIsTeamsGeneration(false);
        setSpinWheelLoader(false);
        setShowSpinWheel(false);
        setShowQuestions(false);
        setIsShowLeaderBoard(false);
        setGeneratingTeamsLoader(false);
        setIsShowFinalLeaderBoard(false);
        setLeaderBoardLoader(true);

        setShowQuestionTimer(false);
        setShowToastTimeAlert(false);
        audio.pause();
        clearInterval(timeInterval);
        timeInterval = null;
        intervalStopper = false;
    };

    const hideToastTimeAlert = () => {
        setShowToastTimeAlert(false);
    };

    const handleQuestionTimerComplete = () => {
        setSelectedOption(null);
        setChallengeLobby(false);
        setIsTeamsGeneration(false);
        setSpinWheelLoader(false);
        setShowSpinWheel(false);
        setShowQuestions(false);
        setIsShowLeaderBoard(false);
        setGeneratingTeamsLoader(false);
        setIsShowFinalLeaderBoard(false);
        setLeaderBoardLoader(true);
    }

    const openchatwidget = () => {
        setShowResults(true);
        setnewmsgrecieved(false);
    }
    const closechatwidget = () => {
        setShowResults(false);
        setnewmsgrecieved(false);
    }

    const msgrecieved = () => {
        setnewmsgrecieved(true);
    };

    const handleShowOtherTeamsModalOpen = async () => {
        setShowOtherTeamsModal(true);
        const allteams = await getAllTeams(challengeDetails.id);
        setAllTeams(allteams.data);
    }

    const handleShowOtherTeamsModalClose = () => setShowOtherTeamsModal(false);

    const handleHomeButton = () => {
        setShowChallengeCloseAlertModal(true);
    };
    console.log("delete chat = ", deleteChat);
    const handleChallengeCloseAlertModalClose = () => setShowChallengeCloseAlertModal(false);
    const handleGoHome = async () => {
        if (weekly_challenge_details.participation_type === 1 && (is_captain || weekly_challenge_data.is_vice_captain === 1)) {
            let chatmessages = JSON.stringify(messages);
            let chatmessagesJsonB64 = Buffer.from(chatmessages).toString("base64");
            const insertMsgs = await setAllTeamChat(challengeDetails.id, myTeam.id, chatmessagesJsonB64);
        }
        if (channel) {
            channel.detach();
            //dispatch({ type: 'closePAblyCon', challenge_id: challengeDetails.id });
            dispatch(closePAblyConnection({ challenge_id: challengeDetails.id }));
            realtimePChannel.close();
        }
        setDeleteChat(true);
        // const roomid = `${challengeDetails.id}_${myTeam.id}`;
        // firebase_db.ref(roomid).remove();
        setTimeout(() => {
            navigate('/weeklyChallenge');
        }, 100);
    };

    const removeTags = (str) => {
        if (str === null || str === "") return false;
        else str = str.toString();

        return str.replace(/<[^>]*>/g, "");
    }

    const handleLeaderBoardTimer = () => {
        setShowLeaderBoardTimer(false);
    };

    const timealert = async (time) => {
        let remainingtime = time;

        if (!timeInterval) {
            timeInterval = setInterval(() => {
                if (intervalStopper) {
                    if (remainingtime == 12 && remainingtime >= 1) {
                        setShowToastTimeAlert(true);
                    } else if (remainingtime == 0) {
                        clearInterval(timeInterval);
                        timeInterval = null;
                        setShowToastTimeAlert(false);
                        return;
                    }
                }
                remainingtime--;
            }, 1000);
        }
    };

    if (challengeLobby) {
        return (
            <div className="row w-100">
                <div className="col-lg-12 col-md-12 col-sm-12" style={{ height: "700px", overflowY: "hidden" }}>
                    <div className="join-lobby-div">
                        <h2 id="challenge-title">{challengeDetails.title}</h2>
                        <p id="challenge-desc">{challengeDetails.description}</p>
                        <div className="start-date">
                            <span style={{ color: 'grey' }}><strong>Starts At:-</strong></span><br />
                            <span><strong>{challengeDetails.start_time}</strong></span>
                        </div>

                        <div className="row w-100">
                            <div className="col-lg-3 col-md-3 col-sm-3"></div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <p style={{ textAlign: 'center', marginTop: '2.5%' }}><strong>Total Enrollments : {enrollsList.length}</strong></p>
                                <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                                    {
                                        enrollsList.length !== 0
                                            ? (
                                                <table className="table table-bordered " id="enrollers-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Position</th>
                                                            <th scope="col">Roll ID</th>
                                                            <th scope="col">Department</th>
                                                            {/* <th scope="col">Status</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            enrollsList.map(enroller => (
                                                                <tr>
                                                                    <td scope="row">{enroller.participant_name}</td>
                                                                    <td scope="row">{enroller.participant_type}</td>
                                                                    <td scope="row">{enroller.roll_number}</td>
                                                                    <td scope="row">{enroller.department_name}</td>
                                                                    {/* <td scope="row" style={{color:"green"}}>{activeMembers.includes(enroller.participant_id) ? "Active" : "---"}</td> */}
                                                                </tr>)
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            )
                                            : (<p style={{ textAlign: 'center', marginTop: '2.5%', fontSize: '1.2em' }}><strong>No one has enrolled yet!</strong></p>)
                                    }
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3"></div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    //teams generation
    if (isTeamsGeneration) {
        return (
            <div className="row w-100">
                <div className="col-lg-12 col-md-12 col-sm-12">

                    {/*other teams modal*/}
                    <Modal show={showOtherTeamsModal} onHide={handleShowOtherTeamsModalClose} id="otherTeamsModal" dialogClassName="otherTeamsModalWidth">
                        <Modal.Header closeButton>
                            <Modal.Title>Teams Information</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                {
                                    allTeams.length !== 0
                                        ? allTeams.map(team => (
                                            <div className="col-lg-4 col-md-4 col-sm-4">
                                                {
                                                    team.id === myTeam.id
                                                        ? (<strong style={{ marginLeft: "10%" }}>Your Team</strong>)
                                                        : ''
                                                }
                                                <table className="table table-bordered table-curved" id="teams-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" colSpan="3">
                                                                {team.team_name}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            team.members.length > 0
                                                                ? team.members.map(teamMember => (
                                                                    <tr>
                                                                        <td scope="row">
                                                                            {
                                                                                teamMember.faculty_id != ''
                                                                                    ? teamMember.is_captain
                                                                                        ? teamMember.faculty_name + " (C)"
                                                                                        : teamMember.is_vice_captain
                                                                                            ? teamMember.faculty_name + " (VC)"
                                                                                            : teamMember.faculty_name
                                                                                    : teamMember.is_captain
                                                                                        ? teamMember.student_name + " (C)"
                                                                                        : teamMember.is_vice_captain
                                                                                            ? teamMember.student_name + " (VC)"
                                                                                            : teamMember.student_name
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                : (
                                                                    <tr>
                                                                        <td colSpan="3">
                                                                            <span colSpan="3" className="d-flex justify-content-center" id="no-coordinators-msg-label">This Team has no members!</span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        ))
                                        : (<p style={{ textAlign: 'center' }}>There are no teams to show!</p>)
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn btn-primary shadow-sm" onClick={handleShowOtherTeamsModalClose} id="closeErrorAlertModalBtn" >
                                Close
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showChangeTeamNameModal} onHide={handleChangeTeamNameModalClose} scrollable dialogClassName="changeTeamNamemodalWidth">
                        <Modal.Body>
                            <div>
                                {
                                    duplicateTeamNameError && (
                                        <div className="dublicate-error-label" style={{ color: 'red' }}>Name already exists, Choose a different team name.</div>
                                    )
                                }
                                <label><strong>Team Name</strong></label>
                                <input type="text" className="form-control" defaultValue={myTeam.team_name} placholder="Team name" onChange={handleChangeTeamName} />
                            </div>
                            <div style={{ fontSize: '0.9em', marginTop: '15px' }}>
                                <span style={{ color: 'red' }}>*</span>Note: Changing team name also accepts your captaincy.
                            </div>
                            <div>
                                <label><strong>Choose Vice Captain</strong></label>
                                <table className="table table-bordered " id="enrollers-table" style={{ marginTop: "10px" }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Role</th>
                                            <th scope="col">Pick</th>
                                            {/* <th scope="col">Status</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            myTeam.members.length > 1
                                                ? myTeam.members.map(member => (
                                                    <>
                                                        {
                                                            member.faculty_id !== ""
                                                                ? member.faculty_id !== current_user_id
                                                                    ? (
                                                                        <tr>
                                                                            <td>
                                                                                <span style={{ marginLeft: "10px", fontSize: "1em" }}><strong>
                                                                                    {
                                                                                        member.faculty_name
                                                                                    }
                                                                                </strong></span>
                                                                            </td>
                                                                            <td>Faculty</td>
                                                                            <td>
                                                                                <input type="radio" style={{ width: "15px", height: "15px" }} onChange={handleChangeViceCaptain} value={member.faculty_id} name="selectViceCaptain" />
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                    : ""
                                                                : member.student_id !== current_user_id
                                                                    ? (
                                                                        <tr>
                                                                            <td>
                                                                                <span style={{ marginLeft: "10px", fontSize: "1em" }}><strong>
                                                                                    {
                                                                                        member.student_name
                                                                                    }
                                                                                </strong></span>
                                                                            </td>
                                                                            <td>
                                                                                Student
                                                                            </td>
                                                                            <td>
                                                                                <input type="radio" style={{ width: "15px", height: "15px" }} onChange={handleChangeViceCaptain} value={member.student_id} name="selectViceCaptain" />
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                    : ""
                                                        }

                                                    </>
                                                ))
                                                : ""
                                        }
                                    </tbody>
                                </table>
                                {
                                    myTeam.members.length <= 1
                                        ? (<span style={{ fontSize: "0.9em", textAlign: "center" }}>You don`t have any team members!</span>)
                                        : ""
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" style={{ width: '25%' }} onClick={handleAcceptCaptaincy} id="captain-teamname-continue-btn">Continue</button>
                        </Modal.Footer>
                    </Modal>


                    <div className="join-lobby-div">
                        <h2 id="challenge-title">{challengeDetails.title}</h2>
                        <p id="challenge-desc">{challengeDetails.description}</p>
                        <div className="team-timer">
                            <div style={{ marginLeft: '100px', marginTop: '-25px' }}>
                                {
                                    showParticipantTeamGenTimer && (
                                        <ReactCountdownClock seconds={challengeDetails.captain_conmfirmation_timer}
                                            color="red"
                                            size={80}
                                            weight={6}
                                            alpha={0.9}
                                            showMilliseconds={false}
                                            onComplete={handleTeamTimerComplete} />
                                    )
                                }
                            </div>
                        </div>

                        <div className="row w-100">
                            <div className="col-lg-3 col-md-3 col-sm-3"></div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                {
                                    myTeam.length !== 0
                                        ? (
                                            <div>
                                                {
                                                    is_captain && (
                                                        showCapConfirmedAlert && (
                                                            <div>
                                                                <p><strong>Hey! Do you want to be the captain? </strong>
                                                                    <AiOutlineCheckCircle className="captain-confirm-icon" style={{ color: "green", fontSize: "25px", marginLeft: '10px' }}
                                                                        onClick={handleCaptaincyBtn} />
                                                                </p>
                                                            </div>
                                                        )
                                                    )
                                                }
                                                {
                                                    showCapConfirmedLabel || teamCaptainConfirmed ? (
                                                        <div>
                                                            <p style={{ color: 'green' }}><strong>Hurray! Your team captaincy is confirmed! </strong></p>
                                                        </div>
                                                    )
                                                        : ''
                                                }
                                                <span>
                                                    <strong>
                                                        {myTeamName} &nbsp;
                                                        {
                                                            is_captain && (<BiEdit onClick={handleCaptaincyBtn} style={{ cursor: 'pointer' }} />)
                                                        }
                                                    </strong>
                                                </span>
                                                <table className="table table-bordered " id="enrollers-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Role</th>
                                                            <th scope="col">Position</th>
                                                            {/* <th scope="col">Status</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            myTeam.members.map(member => (
                                                                <tr>
                                                                    <td scope="row">
                                                                        {
                                                                            member.faculty_id !== ""
                                                                                ? member.faculty_name
                                                                                : member.student_name
                                                                        }
                                                                    </td>
                                                                    <td scope="row">
                                                                        {
                                                                            // member.is_captain === 1
                                                                            //     ? (<strong>Captain</strong>)
                                                                            //     : viceCaptainId !== null
                                                                            //         ? parseInt(viceCaptainId) === parseInt(member.faculty_id) || parseInt(viceCaptainId) === parseInt(member.student_id)
                                                                            //             ? (<strong>Vice Captain</strong>)
                                                                            //             : "-"
                                                                            //         : "-"
                                                                            member.is_captain === 1
                                                                                ? (<strong>Captain</strong>)
                                                                                : member.is_vice_captain === 1
                                                                                    ? (<strong>Vice Captain</strong>)
                                                                                    : "-"
                                                                        }
                                                                    </td>
                                                                    <td scope="row">
                                                                        {
                                                                            member.faculty_id !== ""
                                                                                ? "Faculty"
                                                                                : "Student"
                                                                        }
                                                                    </td>
                                                                    {/* <td scope="row">---</td> */}
                                                                </tr>)
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                        : (<p style={{ textAlign: 'center', marginTop: '2.5%', fontSize: '1.2em' }}><strong>You are not part of any team.Hang tight while added to a team!</strong></p>)
                                }
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3"></div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div class="chat-icon-teams-icon">
                                    <div style={{ marginLeft: "5%" }}>
                                        <RiTeamLine className="teams-icon" onClick={handleShowOtherTeamsModalOpen} style={{ cursor: 'pointer' }} /><br />
                                        <span>Other Teams</span>
                                    </div>
                                    <div style={{ marginRight: "5%" }}>
                                        <div
                                            style={{
                                                float: "right",
                                                cursor: "pointer",
                                                position: "relative",
                                                bottom: "0px",
                                                left: "-10px",
                                            }}
                                            onClick={openchatwidget}
                                            title={`Chat with team`}
                                        >
                                            {newmsgrecieved ? (
                                                <img
                                                    src="/images/new-message-recieved.gif"
                                                    style={{
                                                        position: "absolute",
                                                        top: "-10px",
                                                        left: "-10px",
                                                        width: "30px",
                                                        height: "30px"
                                                    }}
                                                ></img>
                                            ) : null}
                                            <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
                                            <div>{`Team`}</div>
                                        </div>
                                        <div
                                            className={showResults ? "fadeIn" : "fadeOut"}
                                            id="supportmonboxid"
                                        >
                                            <div
                                                className="supportmonbox_head col-md-12 col-sm-12"
                                                id="supbox"
                                                style={{ display: "block", height: "41px" }}
                                            >
                                                <div className="col-xs-1" style={{ float: "right" }}>
                                                    <AiFillCloseCircle
                                                        onClick={closechatwidget}
                                                        style={{ fontSize: "26px", cursor: "pointer" }}
                                                    />
                                                </div>
                                            </div>

                                            <Chatwidget
                                                roomid={`${challengeDetails.id}_${myTeam.id}`}
                                                foundnewmsg={msgrecieved}
                                                deletechat={false}
                                            />
                                        </div>{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //showing spin wheel loader
    if (spinWheelLoader) {
        return (
            <div className="row w-100">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="join-lobby-div">
                        <img src="/images/animation_initiating_challenge.gif" className="challenge-environment-gif" />
                        <div className="generatingteams-label" style={{ fontSize: "20px", marginTop: "21px" }}>
                            Initiating Arena environment. Get ready!
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //showing spin wheel loader
    if (leaderBoardLoader) {
        return (
            <div className="row w-100">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="join-lobby-div">
                        <img src="/images/animation_waiting_loader.gif" className="challenge-environment-gif" />
                        <div className="generatingteams-label" style={{ fontSize: "20px", marginTop: "21px" }}>
                            Generating Leaderboard. Please wait!
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //showing teams generation Loader
    if (generatingTeamsLoader) {
        return (
            <div className="row w-100">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="join-lobby-div">
                        <img src="/images/animation_team_generation.gif" className="challenge-environment-gif" />
                        <div className="generatingteams-label" style={{ fontSize: "20px", marginTop: "21px" }}>
                            Teams are generating. Please wait!
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //showing spin wheel
    if (showSpinWheel) {
        return (
            <div className="row w-100">

                <Modal show={showOtherTeamsModal} onHide={handleShowOtherTeamsModalClose} id="otherTeamsModal" dialogClassName="otherTeamsModalWidth">
                    <Modal.Header closeButton>
                        <Modal.Title>Teams Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {
                                allTeams.length !== 0 && myTeam.length !== 0
                                    ? allTeams.map(team => (
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            {
                                                team.id === myTeam.id
                                                    ? (<strong style={{ marginLeft: "10%" }}>Your Team</strong>)
                                                    : ''
                                            }
                                            <table className="table table-bordered table-curved" id="teams-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" colSpan="3">
                                                            {team.team_name}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        team.members.length > 0
                                                            ? team.members.map(teamMember => (
                                                                <tr>
                                                                    <td scope="row">
                                                                        {
                                                                            teamMember.faculty_id != ''
                                                                                ? teamMember.is_captain
                                                                                    ? teamMember.faculty_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.faculty_name + " (VC)"
                                                                                        : teamMember.faculty_name
                                                                                : teamMember.is_captain
                                                                                    ? teamMember.student_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.student_name + " (VC)"
                                                                                        : teamMember.student_name
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            : (
                                                                <tr>
                                                                    <td colSpan="3">
                                                                        <span colSpan="3" className="d-flex justify-content-center" id="no-coordinators-msg-label">This Team has no members!</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    ))
                                    : (<p style={{ textAlign: 'center' }}>There are no teams to show!</p>)
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary shadow-sm" onClick={handleShowOtherTeamsModalClose} id="closeErrorAlertModalBtn" >
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="join-lobby-div">
                        <h2 id="challenge-title">{challengeDetails.title}</h2>
                        <div className="participant-question-position">Question: {questionPosition + 1}</div>
                        <div>
                            <Wheel
                                topic_data={spinWheelData}
                                topicselected={selectedTopic}
                            />
                            {
                                weekly_challenge_details.participation_type === 1
                                    ? (
                                        <div className="row" style={{ marginTop: "-150px" }}>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div class="chat-icon-teams-icon">
                                                    <div>
                                                        <RiTeamLine className="teams-icon" onClick={handleShowOtherTeamsModalOpen} style={{ cursor: 'pointer' }} /><br />
                                                        <span>Other Teams</span>
                                                    </div>
                                                    <div>
                                                        <div
                                                            style={{
                                                                float: "right",
                                                                cursor: "pointer",
                                                                position: "relative",
                                                                bottom: "0px",
                                                                left: "-10px",
                                                            }}
                                                            onClick={openchatwidget}
                                                            title={`Chat with team`}
                                                        >
                                                            {newmsgrecieved ? (
                                                                <img
                                                                    src="/images/new-message-recieved.gif"
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "-10px",
                                                                        left: "-10px",
                                                                        width: "30px",
                                                                        height: "30px"
                                                                    }}
                                                                ></img>
                                                            ) : null}
                                                            <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
                                                            <div>{`Team`}</div>
                                                        </div>
                                                        <div
                                                            className={showResults ? "fadeIn" : "fadeOut"}
                                                            id="supportmonboxid"
                                                        >
                                                            <div
                                                                className="supportmonbox_head col-md-12 col-sm-12"
                                                                id="supbox"
                                                                style={{ display: "block", height: "41px" }}
                                                            >
                                                                <div className="col-xs-1" style={{ float: "right" }}>
                                                                    <AiFillCloseCircle
                                                                        onClick={closechatwidget}
                                                                        style={{ fontSize: "26px", cursor: "pointer" }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <Chatwidget
                                                                roomid={`${challengeDetails.id}_${myTeam.id}`}
                                                                foundnewmsg={msgrecieved}
                                                                deletechat={false}
                                                            />
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : ('')
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //showing leader board
    if (isShowLeaderBoard) {
        return (
            <div className="row w-100" style={{ background: `linear-gradient(to right, rgb(253, 243, 194), rgb(255, 194, 213))`, paddingBottom: '70px', marginLeft: "0px" }}>

                <Modal show={showOtherTeamsModal} onHide={handleShowOtherTeamsModalClose} id="otherTeamsModal" dialogClassName="otherTeamsModalWidth">
                    <Modal.Header closeButton>
                        <Modal.Title>Teams Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {
                                allTeams.length !== 0 && myTeam.length !== 0
                                    ? allTeams.map(team => (
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            {
                                                team.id === myTeam.id
                                                    ? (<strong style={{ marginLeft: "10%" }}>Your Team</strong>)
                                                    : ''
                                            }
                                            <table className="table table-bordered table-curved" id="teams-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" colSpan="3">
                                                            {team.team_name}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        team.members.length > 0
                                                            ? team.members.map(teamMember => (
                                                                <tr>
                                                                    <td scope="row">
                                                                        {
                                                                            teamMember.faculty_id != ''
                                                                                ? teamMember.is_captain
                                                                                    ? teamMember.faculty_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.faculty_name + " (VC)"
                                                                                        : teamMember.faculty_name
                                                                                : teamMember.is_captain
                                                                                    ? teamMember.student_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.student_name + " (VC)"
                                                                                        : teamMember.student_name
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            : (
                                                                <tr>
                                                                    <td colSpan="3">
                                                                        <span colSpan="3" className="d-flex justify-content-center" id="no-coordinators-msg-label">This Team has no members!</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    ))
                                    : (<p style={{ textAlign: 'center' }}>There are no teams to show!</p>)
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary shadow-sm" onClick={handleShowOtherTeamsModalClose} id="closeErrorAlertModalBtn" >
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="join-lobby-div">
                        <h2 id="challenge-title">{challengeDetails.title}</h2>
                        <div className="leaderboard-timer">
                            {
                                showLeaderBoardTimer && (
                                    <ReactCountdownClock
                                        seconds={challengeDetails.leaderBoard_timer}
                                        alpha={0.9}
                                        color="red"
                                        size={70}
                                        weight={5}
                                        showMilliseconds={false}
                                        onComplete={handleLeaderBoardTimer}
                                    />
                                )
                            }
                        </div>
                        {
                            weekly_challenge_details.participation_type === 1
                                ? (<div style={{ fontSize: '1.5em', marginLeft: '10%' }} className="myteam-rank-label"><strong>Your Team Rank: {myTeamRank}</strong></div>)
                                : (<div style={{ fontSize: '1.5em', marginLeft: '10%' }} className="myteam-rank-label"><strong>Your Rank: {myTeamRank}</strong></div>)
                        }
                        <div style={{ height: "425px", overflowY: "auto", overflowX: "hidden" }}>
                            {
                                weekly_challenge_details.participation_type === 1
                                    ? weekly_challenge_details.random_questions === 1
                                        ? (<LeaderBoard data={leaderBoardData} current_question_id={questionData.id} myTeam_id={myTeam.id} user_type="participant" question_index={questionPosition - 1} type="running" challenge_details={weekly_challenge_details} />)
                                        : (<LeaderBoard data={leaderBoardData} current_question_id={questionData.id} myTeam_id={myTeam.id} user_type="participant" question_index={questionPosition} type="running" challenge_details={weekly_challenge_details} />)
                                    : weekly_challenge_details.random_questions === 1
                                        ? (<LeaderBoard data={leaderBoardData} current_question_id={questionData.id} myTeam_id={current_user_id} user_type="participant" question_index={questionPosition - 1} type="running" challenge_details={weekly_challenge_details} />)
                                        : (<LeaderBoard data={leaderBoardData} current_question_id={questionData.id} myTeam_id={current_user_id} user_type="participant" question_index={questionPosition} type="running" challenge_details={weekly_challenge_details} />)
                            }
                        </div>

                        {
                            weekly_challenge_details.participation_type === 1
                                ? (
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div class="chat-icon-teams-icon2">
                                                <div>
                                                    <RiTeamLine className="teams-icon" onClick={handleShowOtherTeamsModalOpen} style={{ cursor: 'pointer' }} /><br />
                                                    <span>Other Teams</span>
                                                </div>
                                                <div>
                                                    <div
                                                        style={{
                                                            float: "right",
                                                            cursor: "pointer",
                                                            position: "relative",
                                                            bottom: "0px",
                                                            left: "-10px",
                                                        }}
                                                        onClick={openchatwidget}
                                                        title={`Chat with team`}
                                                    >
                                                        {newmsgrecieved ? (
                                                            <img
                                                                src="/images/new-message-recieved.gif"
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "-10px",
                                                                    left: "-10px",
                                                                    width: "30px",
                                                                    height: "30px"
                                                                }}
                                                            ></img>
                                                        ) : null}
                                                        <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
                                                        <div>{`Team`}</div>
                                                    </div>
                                                    <div
                                                        className={showResults ? "fadeIn" : "fadeOut"}
                                                        id="supportmonboxid"
                                                    >
                                                        <div
                                                            className="supportmonbox_head col-md-12 col-sm-12"
                                                            id="supbox"
                                                            style={{ display: "block", height: "41px" }}
                                                        >
                                                            <div className="col-xs-1" style={{ float: "right" }}>
                                                                <AiFillCloseCircle
                                                                    onClick={closechatwidget}
                                                                    style={{ fontSize: "26px", cursor: "pointer" }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <Chatwidget
                                                            roomid={`${challengeDetails.id}_${myTeam.id}`}
                                                            foundnewmsg={msgrecieved}
                                                            deletechat={false}
                                                        />
                                                    </div>{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                : ('')
                        }
                    </div>
                </div>
            </div>
        )
    }

    //showing final leader board
    if (isShowFinalLeaderBoard) {
        return (
            <div className="row w-100" style={{ backgroundImage: `url('/images/animation_final_leaderboard.gif')`, marginLeft: "0px" }}>

                <Modal show={showChallengeCloseAlertModal} onHide={handleChallengeCloseAlertModalClose} scrollable id="challengeCloseAlertModal" dialogClassName="challengeCustomAlertmodalWidth">
                    <Modal.Body>
                        <div>
                            <img src="/images/thankyou-animation.gif" className="thankyou-animation" />
                            <span className="d-flex justify-content-center" id="challenge-custom-msg-label"><strong>Hope you enjoyed the Arena. Thanks for your Participation!</strong></span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleGoHome} id="modalHomeBtn">
                            Continue
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showOtherTeamsModal} onHide={handleShowOtherTeamsModalClose} id="otherTeamsModal" dialogClassName="otherTeamsModalWidth">
                    <Modal.Header closeButton>
                        <Modal.Title>Teams Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {
                                allTeams.length !== 0 && myTeam.length !== 0
                                    ? allTeams.map(team => (
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            {
                                                team.id === myTeam.id
                                                    ? (<strong style={{ marginLeft: "10%" }}>Your Team</strong>)
                                                    : ''
                                            }
                                            <table className="table table-bordered table-curved" id="teams-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" colSpan="3">
                                                            {team.team_name}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        team.members.length > 0
                                                            ? team.members.map(teamMember => (
                                                                <tr>
                                                                    <td scope="row">
                                                                        {
                                                                            teamMember.faculty_id != ''
                                                                                ? teamMember.is_captain
                                                                                    ? teamMember.faculty_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.faculty_name + " (VC)"
                                                                                        : teamMember.faculty_name
                                                                                : teamMember.is_captain
                                                                                    ? teamMember.student_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.student_name + " (VC)"
                                                                                        : teamMember.student_name
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            : (
                                                                <tr>
                                                                    <td colSpan="3">
                                                                        <span colSpan="3" className="d-flex justify-content-center" id="no-coordinators-msg-label">This Team has no members!</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    ))
                                    : (<p style={{ textAlign: 'center' }}>There are no teams to show!</p>)
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary shadow-sm" onClick={handleShowOtherTeamsModalClose} id="closeErrorAlertModalBtn" >
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="join-lobby-div">
                        <h2 id="challenge-title">{challengeDetails.title}</h2>
                        <button className="btn btn-primary" id="go-to-home-btn" onClick={handleHomeButton}>Home <FaLongArrowAltRight /></button>
                        <p style={{ textAlign: 'center', fontSize: '1.5em' }}><strong>Final LeaderBoard</strong></p>

                        {
                            weekly_challenge_details.participation_type === 1
                                ? (<div style={{ fontSize: '1.5em', marginLeft: '10%' }} className="myteam-rank-label"><strong>Your Team Rank: {myTeamRank}</strong></div>)
                                : (<div style={{ fontSize: '1.5em', marginLeft: '10%' }} className="myteam-rank-label"><strong>Your Rank: {myTeamRank}</strong></div>)
                        }
                        <div style={{ height: "425px", overflowY: "auto", overflowX: "hidden" }}>
                            {
                                weekly_challenge_details.participation_type === 1
                                    ? weekly_challenge_details.random_questions === 1
                                        ? (<LeaderBoard data={leaderBoardData} current_question_id={questionData.id} myTeam_id={myTeam.id} user_type="participant" question_index={questionPosition - 1} type="running" challenge_details={weekly_challenge_details} />)
                                        : (<LeaderBoard data={leaderBoardData} current_question_id={questionData.id} myTeam_id={myTeam.id} user_type="participant" question_index={questionPosition} type="running" challenge_details={weekly_challenge_details} />)
                                    : weekly_challenge_details.random_questions === 1
                                        ? (<LeaderBoard data={leaderBoardData} current_question_id={questionData.id} myTeam_id={current_user_id} user_type="participant" question_index={questionPosition - 1} type="running" challenge_details={weekly_challenge_details} />)
                                        : (<LeaderBoard data={leaderBoardData} current_question_id={questionData.id} myTeam_id={current_user_id} user_type="participant" question_index={questionPosition} type="running" challenge_details={weekly_challenge_details} />)
                            }
                        </div>

                        {
                            weekly_challenge_details.participation_type === 1
                                ? (
                                    <div className="row" style={{ marginTop: "-25px" }}>
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div class="chat-icon-teams-icon2">
                                                <div>
                                                    <RiTeamLine className="teams-icon" onClick={handleShowOtherTeamsModalOpen} style={{ cursor: 'pointer' }} /><br />
                                                    <span>Other Teams</span>
                                                </div>
                                                <div>
                                                    <div
                                                        style={{
                                                            float: "right",
                                                            cursor: "pointer",
                                                            position: "relative",
                                                            bottom: "0px",
                                                            left: "-10px",
                                                        }}
                                                        onClick={openchatwidget}
                                                        title={`Chat with team`}
                                                    >
                                                        {newmsgrecieved ? (
                                                            <img
                                                                src="/images/new-message-recieved.gif"
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "-10px",
                                                                    left: "-10px",
                                                                    width: "30px",
                                                                    height: "30px"
                                                                }}
                                                            ></img>
                                                        ) : null}
                                                        <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
                                                        <div>{`Team`}</div>
                                                    </div>
                                                    <div
                                                        className={showResults ? "fadeIn" : "fadeOut"}
                                                        id="supportmonboxid"
                                                    >
                                                        <div
                                                            className="supportmonbox_head col-md-12 col-sm-12"
                                                            id="supbox"
                                                            style={{ display: "block", height: "41px" }}
                                                        >
                                                            <div className="col-xs-1" style={{ float: "right" }}>
                                                                <AiFillCloseCircle
                                                                    onClick={closechatwidget}
                                                                    style={{ fontSize: "26px", cursor: "pointer" }}
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            deleteChat === true
                                                                ? (
                                                                    <Chatwidget
                                                                        roomid={`${challengeDetails.id}_${myTeam.id}`}
                                                                        foundnewmsg={msgrecieved}
                                                                        deletechat={true}
                                                                    />
                                                                )
                                                                : (
                                                                    <Chatwidget
                                                                        roomid={`${challengeDetails.id}_${myTeam.id}`}
                                                                        foundnewmsg={msgrecieved}
                                                                        deletechat={false}
                                                                    />
                                                                )
                                                        }

                                                    </div>{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                : ('')
                        }
                    </div>
                </div>
            </div>
        )
    }


    //showing questions
    if (showQuestions) {
        console.log("Captain status: ", weekly_challenge_data.is_captain);
        console.log("vide captain status: ", weekly_challenge_data.is_vice_captain);
        console.log(weekly_challenge_data);
        console.log(weekly_challenge_details);
        let showsubmitoption;
        let submit = "pointer";
        let autosubmitans = (
            <ReactCountdownClock
                seconds={parseInt(weekly_challenge_details.time_per_question)}
                alpha={0.9}
                color="red"
                size={70}
                weight={5}
                showMilliseconds={false}
                onComplete={handleQuestionTimerComplete}
            />
        );
        if (
            weekly_challenge_details.participation_type === 1 &&
            challengeDetails.is_member === 1 &&
            challengeDetails.is_viewer === 0
            && myTeam.length !== 0
        ) {
            {
                myTeam.members.map((val, key) => {
                    if (
                        val.faculty_id == current_user_id &&
                        (val.is_captain || weekly_challenge_data.is_vice_captain)
                    ) {
                        submit = "pointer";
                        showsubmitoption = (
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <button
                                    onClick={onSubmitAnswer}
                                    className="btn btn-danger"
                                    style={{ marginTop: "7px", width: "225px" }}
                                >
                                    Submit Answer
                                </button>
                            </div>
                        );
                        autosubmitans = (
                            <ReactCountdownClock
                                seconds={parseInt(weekly_challenge_details.time_per_question)}
                                alpha={0.9}
                                color="red"
                                size={70}
                                weight={5}
                                onComplete={onSubmitAnswer}
                                showMilliseconds={false}
                            />
                        );
                    }
                });
            }
        } else if (
            weekly_challenge_details.participation_type === 0 &&
            challengeDetails.is_member === 1 &&
            challengeDetails.is_viewer === 0
        ) {
            submit = "pointer";
            showsubmitoption = (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                        onClick={onSubmitAnswer}
                        className="btn btn-danger"
                        style={{ marginTop: "7px", width: "225px" }}
                    >
                        Submit Answer
                    </button>
                </div>
            );
            autosubmitans = (
                <ReactCountdownClock
                    seconds={parseInt(weekly_challenge_details.time_per_question)}
                    alpha={0.9}
                    color="red"
                    size={70}
                    weight={5}
                    showMilliseconds={false}
                    onComplete={onSubmitAnswer}
                />
            );
        }
        let hasimageoption = false;
        if (questionData.field_type == 0) {
            questionData.options.map(({ id, option, media }, index) => {
                if (media) {
                    hasimageoption = true;
                }
            });
        }

        return (
            <div className="row w-100">

                <Modal show={showOtherTeamsModal} onHide={handleShowOtherTeamsModalClose} id="otherTeamsModal" dialogClassName="otherTeamsModalWidth">
                    <Modal.Header closeButton>
                        <Modal.Title>Teams Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {
                                allTeams.length !== 0 && myTeam.length !== 0
                                    ? allTeams.map(team => (
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            {
                                                team.id === myTeam.id
                                                    ? (<strong style={{ marginLeft: "10%" }}>Your Team</strong>)
                                                    : ''
                                            }
                                            <table className="table table-bordered table-curved" id="teams-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" colSpan="3">
                                                            {team.team_name}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        team.members.length > 0
                                                            ? team.members.map(teamMember => (
                                                                <tr>
                                                                    <td scope="row">
                                                                        {
                                                                            teamMember.faculty_id != ''
                                                                                ? teamMember.is_captain
                                                                                    ? teamMember.faculty_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.faculty_name + " (VC)"
                                                                                        : teamMember.faculty_name
                                                                                : teamMember.is_captain
                                                                                    ? teamMember.student_name + " (C)"
                                                                                    : teamMember.is_vice_captain
                                                                                        ? teamMember.student_name + " (VC)"
                                                                                        : teamMember.student_name
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            : (
                                                                <tr>
                                                                    <td colSpan="3">
                                                                        <span colSpan="3" className="d-flex justify-content-center" id="no-coordinators-msg-label">This Team has no members!</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    ))
                                    : (<p style={{ textAlign: 'center' }}>There are no teams to show!</p>)
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary shadow-sm" onClick={handleShowOtherTeamsModalClose} id="closeErrorAlertModalBtn" >
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="join-lobby-div" style={{ marginBottom: "20px" }}>
                        <div className="row">
                            <div className="col-lg-10 col-md-10 col-sm-9">
                                <h2 style={{ marginLeft: '10%' }} id="challenge-title">{challengeDetails.title}</h2>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-3">
                                {
                                    showQuestionTimer && (
                                        <div style={{ float: 'right' }}>
                                            {autosubmitans}
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        <div className="questionsection" style={{ display: "flex" }}>
                            <div className="classdiv" dir="auto">
                                <span role="heading" aria-level="1">
                                    {questionData.current_pos + 1}. {removeTags(questionData.question)}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8">
                                <div className="verticalline"></div>
                                {questionData.field_type == 0 && !hasimageoption ? (
                                    <div className="answersdiv_withimage" style={{ height: "425px", overflowY: "auto", overflowX: "hidden" }}>
                                        {questionData.options.map(
                                            ({ id, option, media }, index) => {
                                                const isselected = selectedoption === id;
                                                const classname =
                                                    selectedoption && !isselected
                                                        ? "boxshadow answermaindiv"
                                                        : selectedoption && isselected
                                                            ? "boxshadow1 answermaindiv"
                                                            : "answermaindiv";
                                                if (id == selectedoption) {
                                                    let isselected = true;
                                                }
                                                return (
                                                    <div className="row" style={{ display: "flex", alignItems: "center" }}>
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <div
                                                                style={{
                                                                    borderTop: `3px dashed ${optionbgcolors[index]}`,
                                                                    margin: "40px 10px 2px -38px",
                                                                    width: "9%",
                                                                }}
                                                            ></div>
                                                            <div
                                                                color="#e21b3c"
                                                                onClick={() => optionselected(id)}
                                                                className={classname}
                                                                style={{ cursor: `${submit}` }}
                                                            >
                                                                <div className="">
                                                                    <div
                                                                        className="card-icon__icon"
                                                                        style={{
                                                                            background: `${optionbgcolors[index]}`,
                                                                            opacity: "0.9",
                                                                        }}
                                                                    >
                                                                        {options[index]}
                                                                    </div>
                                                                </div>
                                                                <span
                                                                    aria-live="off"
                                                                    dir="auto"
                                                                    className="answertext"
                                                                >
                                                                    <span>{removeTags(option)}</span>
                                                                </span>
                                                                {isselected ? (
                                                                    <AiFillLock
                                                                        style={{ color: "green", fontSize: "40px" }}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                ) : questionData.field_type == 0 && hasimageoption ? (
                                    <div className="answersdiv_withimage" style={{ height: "425px", overflowY: "auto", overflowX: "hidden" }}>
                                        {questionData.options.map(
                                            ({ id, option, media }, index) => {
                                                const isselected = selectedoption === id;
                                                const classname =
                                                    selectedoption && !isselected
                                                        ? "boxshadow answermaindiv"
                                                        : selectedoption && isselected
                                                            ? "boxshadow1 answermaindiv"
                                                            : "answermaindiv";
                                                if (id == selectedoption) {
                                                    let isselected = true;
                                                }
                                                return (
                                                    <div className="row" style={{ display: "flex", alignItems: "center" }}>
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <div
                                                                style={{
                                                                    borderTop: `3px dashed ${optionbgcolors[index]}`,
                                                                    margin: "40px 10px 2px -38px",
                                                                    width: "9%",
                                                                }}
                                                            ></div>
                                                            <div
                                                                color="#e21b3c"
                                                                onClick={() => optionselected(id)}
                                                                className={classname}
                                                                style={{ cursor: `${submit}` }}
                                                            >
                                                                <div className="">
                                                                    <div
                                                                        className="card-icon__icon"
                                                                        style={{
                                                                            background: `${optionbgcolors[index]}`,
                                                                            opacity: "0.9",
                                                                        }}
                                                                    >
                                                                        {options[index]}
                                                                    </div>
                                                                </div>
                                                                <span
                                                                    aria-live="off"
                                                                    dir="auto"
                                                                    className="answertext"
                                                                >
                                                                    <span>{removeTags(option)}</span>
                                                                </span>
                                                                {isselected ? (
                                                                    <AiFillLock
                                                                        style={{ color: "green", fontSize: "40px" }}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-sm-12 col-md-12">
                                                            <img src={media} style={{ width: "30%", marginLeft: "35%", height: "150px" }} />
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                ) : (
                                    // <div className="answersdiv">
                                    //     <input type="text"
                                    //         value={answer}
                                    //         onChange={onAnswerChange}
                                    //         placeholder="write..."
                                    //         style={{
                                    //             padding: "5px",
                                    //             borderRadius: "5px",
                                    //             borderColor: "green",
                                    //             marginLeft: "25%",
                                    //             marginTop: "15%",
                                    //             width: "50%"
                                    //         }}
                                    //     />
                                    //     </div>
                                    <div className="answersdiv">
                                        <div style={{ width: '60%', marginLeft: '10%', marginTop: '5%' }}>
                                            <label><strong>Enter you answer:</strong></label>
                                            <textarea type="text"
                                                value={answer}
                                                onChange={onAnswerChange}
                                                className="form-control"
                                                placeholder="write..."
                                                style={{ width: '100%', height: "100px", borderColor: "green", resize: "none" }} ></textarea>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                {
                                    questionData.question_img
                                        ? (
                                            <div className="question-image-div" style={{ height: '350px', overflowY: 'auto', width: '100%', marginTop: '10px' }}>
                                                {
                                                    img_extensions.includes(questionData.question_img.split('.').pop().toLowerCase())
                                                        ? (
                                                            <img src={questionData.question_img} className="question-image" alt="question picture" />
                                                        )
                                                        : video_extensions.includes(questionData.question_img.split('.').pop().toLowerCase())
                                                            ? (
                                                                <video alt="question video" controls style={{ width: "80%", marginLeft: "10%", marginTop: "2.5%" }}>
                                                                    <source src={questionData.question_img} type={`video/${questionData.question_img.split('.').pop().toLowerCase()}`} loop={true} />
                                                                </video>
                                                            )
                                                            : (
                                                                <audio controls style={{ marginTop: "25%" }}>
                                                                    {
                                                                        questionData.question_img.split('.').pop().toLowerCase() === "mp3"
                                                                            ? (<source src={questionData.question_img} type="audio/mpeg" />)
                                                                            : (<source src={questionData.question_img} type={`audio/${questionData.question_img.split('.').pop().toLowerCase()}`} />)
                                                                    }
                                                                    Your browser does not support the audio element.
                                                                </audio>
                                                            )
                                                }
                                            </div>
                                        )
                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer className="p-3" position="top-center">
                    <Toast
                        animation={true}
                        show={showToastTimeAlert}
                        onClose={hideToastTimeAlert}
                    >
                        <Toast.Header
                            style={{ paddingLeft: "23px", background: "#f43e3e" }}
                        >
                            <img src="/images/time_alert.gif" style={{ height: "70px" }} />
                            <strong className="me-auto" style={{ color: "white" }}>
                                Alert! Alert! <br /> Running out of time!
                            </strong>
                        </Toast.Header>
                    </Toast>
                </ToastContainer>
                {
                    weekly_challenge_details.participation_type === 1
                        ? (
                            <div className="row" style={{ float: 'right', marginTop: '-25px', marginRight: '10px' }}>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div class="chat-icon-teams-icon2">
                                        <div style={{ marginLeft: "25px" }}>
                                            <RiTeamLine className="teams-icon" onClick={handleShowOtherTeamsModalOpen} style={{ cursor: 'pointer' }} /><br />
                                            <span>Other Teams</span>
                                        </div>

                                        <div style={{ marginTop: "10px" }}>{showsubmitoption}</div>
                                        {
                                            is_captain === false && weekly_challenge_data.is_vice_captain === 0
                                                ? (<span style={{ color: "red" }}>Note: Only your team captain or vice captain can answer the question.</span>)
                                                : ""
                                        }

                                        <div>
                                            <div
                                                style={{
                                                    float: "right",
                                                    cursor: "pointer",
                                                    position: "relative",
                                                    bottom: "0px",
                                                    left: "-10px",
                                                }}
                                                onClick={openchatwidget}
                                                title={`Chat with team`}
                                            >
                                                {newmsgrecieved ? (
                                                    <img
                                                        src="/images/new-message-recieved.gif"
                                                        style={{
                                                            position: "absolute",
                                                            top: "-10px",
                                                            left: "-10px",
                                                            width: "30px",
                                                            height: "30px"
                                                        }}
                                                    ></img>
                                                ) : null}
                                                <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
                                                <div>{`Team`}</div>
                                            </div>
                                            <div
                                                className={showResults ? "qfadeIn" : "fadeOut"}
                                                id="supportmonboxid"
                                            >
                                                <div
                                                    className="supportmonbox_head col-md-12 col-sm-12"
                                                    id="supbox"
                                                    style={{ display: "block", height: "41px" }}
                                                >
                                                    <div className="col-xs-1" style={{ float: "right" }}>
                                                        <AiFillCloseCircle
                                                            onClick={closechatwidget}
                                                            style={{ fontSize: "26px", cursor: "pointer" }}
                                                        />
                                                    </div>
                                                </div>

                                                <Chatwidget
                                                    roomid={`${challengeDetails.id}_${myTeam.id}`}
                                                    foundnewmsg={msgrecieved}
                                                    deletechat={false}
                                                />
                                            </div>{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        : ('')
                }
                {
                    weekly_challenge_details.participation_type !== 1
                        ? (
                            <div style={{ marginTop: "-50px", marginLeft: "-50px" }}>{showsubmitoption}</div>
                        )
                        : ""
                }
            </div>
        )
    }



}

export default JoinChallenge;