import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAllProfileDetails } from "../api/weeklyChallengeAPIs";
import { Buffer } from "buffer";
import { setUserData } from "../store/reducers/common.reducer";
import { useDispatch, useSelector } from "react-redux";


const getprofileDetails = async () => {
  const profData = await getAllProfileDetails();
  console.log(profData)

  if (profData?.status ===200) {            
      localStorage.setItem(
        "profile_details",
        JSON.stringify(profData?.data?.basic_details)
      );
  }
};

export const BASE_WEBSITE_URL = "https://master-duplicate.d254q3igzk9d9p.amplifyapp.com/";
export let accessToken;
const IndexPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const facultyWebBaseUrl = useSelector(state => state.Common?.facultyWebBaseUrl)
  // console.log('Index Page Loaded')

  useEffect(() => {
    let userData;

    const fetchData = async () => {
      const searchParams = new URLSearchParams(window.location.search);

      // const getAllFeedbacks = async () => {
      //     const profData = await getAllProfileDetails();
      //     localStorage.setItem('profile_details', JSON.stringify(profData.data.basic_details));
      //     navigate('/feedbackHome');
      // }

      if (searchParams.has("user")) {
        const userDataString = Buffer.from(
          searchParams.get("user"),
          "base64"
        ).toString("utf8");

        // console.log(userDataString);
        localStorage.setItem("user", userDataString);
        userData = JSON.parse(userDataString);
        console.log(userData);
        dispatch(setUserData({user : userData}))
        localStorage.setItem("token", userData?.token);

        console.log(`setting localstorage times in index page complete`);

        await getprofileDetails();
      }

      if(searchParams.has("token")){
        console.log(searchParams.get("token"))
        localStorage.setItem('token', searchParams.get("token"));
        await getprofileDetails();
      }

      // // If no token exists routing back to web module
      if(!userData?.token && !searchParams.has("token")){
        window.location.replace(facultyWebBaseUrl);
      }

      //setting the Arena and coordinator details
      if(userData?.token || searchParams.has("token")){
        if(userData?.weekly_challenge_admin && userData?.weekly_challenge_coordinator){
          localStorage.setItem("is_admin", userData.weekly_challenge_admin);
          localStorage.setItem("is_coordinator", userData.weekly_challenge_coordinator);
        }
      }

      // Handling Routing to the respective module
      if (userData?.token || searchParams.has("token")) {
        if (searchParams.has("wca") && searchParams.has("wcc")) {
          localStorage.setItem("is_admin", searchParams.get("wca"));
          localStorage.setItem("is_coordinator", searchParams.get("wcc"));
          navigate("/weeklyChallenge", { state: userData, replace: true});
        } 
        else if (searchParams.has("feedbacks")) {
          // localStorage.setItem('token', searchParams.get("token"));
          // await getprofileDetails();
          navigate("/feedbackHome", { state: userData, replace: true });
        } 
        else if (searchParams.has("fsm")) {
          // localStorage.setItem('token', searchParams.get("token"));
          // await getprofileDetails();
          navigate("/facultySectionMapping", { state: userData, replace: true });
        }
        else if (searchParams.has("fpr")) {
          // localStorage.setItem('token', searchParams.get("token"));
          // await getprofileDetails();
          navigate("/facultyPerformanceReview", { state: userData, replace: true });
        }
        else if(searchParams.has("inplace_reports")){
          let test_id = searchParams.get('test_id');
          let download_type = searchParams.get('download_type')
          let report_type = searchParams.get('report_type')
          navigate("reports/inplaceReports", {state: {test_id: test_id, download_type: download_type, report_type: report_type}});
        }
      }
    };

    try {
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, [navigate, dispatch, facultyWebBaseUrl]);
};

export default IndexPage;
