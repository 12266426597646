import * as Ably from 'ably/promises';
import { openAblyConnection } from "../reducers/joinChallenge.reducer";
const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const getAccessToken = () => localStorage.getItem("token");

export const openNewAblyConnection = (challengeDetails) => {
  return async (dispatch) => {
    const openNewConnection = async () => {
      const realtime = new Ably.Realtime({ authUrl: `${BASE_API_URL}/weekly_challenge/weeklyChallengeAuth?weekly_challenge_id=${challengeDetails.challenge_id}&token=${getAccessToken()}` });
      console.log(realtime);
      realtime.connection.once("connected", () => {
          console.log("authenticated");
          console.log(realtime);
      });
      let channel = realtime.channels.get(String(challengeDetails.pChannelId));
      let viewer_channel = realtime.channels.get(String(challengeDetails.vChannelId));

      dispatch(openAblyConnection({challenge_id: challengeDetails.challenge_id, realtime: realtime, channel: channel, viewer_channel: viewer_channel}));
    };

    try {
      const fetchedData = await openNewConnection();
      console.log(fetchedData);
      return fetchedData;
    } catch (error) {
      console.log(error);
      return new Error(error);
    }
  };
};
