import * as React from 'react';
import moment from "moment";
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Grid from "@mui/material/Grid";

MaterialDateTimePicker.defaultProps = {
  value : moment(),
  setValue : null,
  label : 'Date Time Picker',
}

export default function MaterialDateTimePicker({value, setValue,label, ...props}) {
  // const [value, setValue] = React.useState(moment('2022-04-07'));

  return (
    <Grid item xs>
      <LocalizationProvider dateAdapter={AdapterMoment} >
          <DateTimePicker
            {...props}
            renderInput={(props) => <TextField {...props} fullWidth helperText={props?.inputProps?.placeholder}/>}
            label={label}
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            showDaysOutsideCurrentMonth
            // showToolbar
            views={['year','month', 'day', 'hours', 'minutes']}
            componentsProps={{
              actionBar: { actions: ["today", "cancel", "accept"] },
          }}
          inputFormat="DD/MM/YYYY hh:mm A"
          //  PaperProps={{sx:{overflowY : 'scroll'}}}
          />
      </LocalizationProvider>
    </Grid>
  );
}