import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


// the "value" state with the value/onChange props combination
// the "input value" state with the inputValue/onInputChange props combination
// Mandatory to give values with label key in object

// getOptionLabel is provided if the label key is someother key in the given object


SearchDropDown.defaultProps = {
  label : 'Search',
  data : [],
  value : null,
}


export default function SearchDropDown({label,data,getOptionLabel,value,setValue}) {
  // const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  console.log(inputValue)

  // const options = data ? data : [];


  return (
    <div>
      {/* <div>{`value: ${value !== null ? `'${value}'` : 'null'}`}</div>
      <div>{`inputValue: '${inputValue}'`}</div>
      <br /> */}
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={data}
        sx={{ m: 1, minWidth: 100 }}
        renderInput={(params) => <TextField {...params} label={label}
        size="small"
        />}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        getOptionLabel={(option) => option? option[getOptionLabel] : ''}
        isOptionEqualToValue={(option,value)=>option[getOptionLabel]===value[getOptionLabel]}
      />
    </div>
  );
}

