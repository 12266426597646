import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Grid, Divider, Typography, Button, Checkbox } from "@mui/material";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Navbar from "../../../navbar/navbar.component";
import LayoutwithSideNav from "../../../common/LayoutwithSideNav";
import { getAllGeneralFeedbackQuestions, getGeneralCategoryQuestions, createsurvey, addQuestion } from "../../../../api/facultyfeedbackapis";
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import RichTextEditor from "react-rte";
import { ToastContainer, toast } from "react-toastify";
import TelegramIcon from '@mui/icons-material/Telegram';
// import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ModalwithButtons from "../../../common/ModalwithButtons";
import LoadingOverlay from 'react-loading-overlay';

const GeneralFeedbackQuestions = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedCategoryId, setselectedCategoryId] = useState(-1);
    const [allFeedbackQuestions, setAllFeedbackQuestions] = useState("");
    const [categoryQuestions, setCategoryQuestions] = useState([]);
    const [trainingQuestions, setTrainingQuestions] = useState([]);
    const [selectedquestions, setSelectedQuestions] = useState([]);
    const [addedQuestions, setAddedQuestions] = useState([]);
    const [flag, setFlag] = useState(1);
    const [chooseQuestions, setChooseQuestions] = useState(true);
    const [addQuestions, setAddQuestions] = useState(false);
    const [newQuestion, setNewQuestion] = useState(RichTextEditor.createEmptyValue());
    const [viewMoreQuestions, setViewMoreQuestions] = useState([]);
    const [checkedAllQuesions, setCheckedAllQuesions] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [isCreateLoaderActive, setIsCreateLoaderActive] = useState(false);

    const styles = {
        button: {
            textTransform: 'none', // Disable capitalization
            fontSize: '1.1rem',
            maxWidth: "150px",
            float: "right"
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            marginTop: "15px",
            fontSize: "1.1rem",
            marginBottom: "7.5px",
            fontWeight: 600,
            ml: 1,
        },
        category_name: {
            width: "100%",
            cursor: "pointer",
            padding: "5px",

        },
        category_name_active: {
            background: "linear-gradient(131deg, #7175E0 0%, #4D50CB 33%, #E25BC8 100%) left bottom #fefefe no-repeat",
            backgroundSize: "100% 3px",
            fontSize: "larger",
            color: "black",
            cursor: "pointer",
            padding: "5px",
            pl: "10px",
            pr: "10px",
            fontWeight: 600,
        },
        categorySection: {
            margin: "auto 10px",
            display: "inline-block",
            textAlign: "center",
        },
        question_box: {
            border: "1px solid slategray",
            borderRadius: "15px",
            padding: "10px 5px 5px 5px",
            // margin: "7.5% 0 1.5% 0",
            height: "600px",
            overflowY: "auto",
            mt: 1,
        },
        divider2: {
            width: "100%",
            height: "0.2px",
            textAlign: "start",
            ml: 0,
            mt: 2,
            backgroundColor: "#e6e6e6",
            opacity: "50%",
        },
        addedQuestionLabel: {
            padding: "5px",
            border: "1px solid #d7d7d9",
            borderRadius: "15px",
            fontSize: "14px",
            marginBottom: "7px",
            maxHeight: "125px",
            wordBreak: "break-all",
            overflow: "hidden",
        },
        redText: {
            color: 'red',
            position: 'relative',
            top: '-0.1em',
        },
    };

    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };

    useEffect(() => {
        if (allFeedbackQuestions.length === 0) {
            handleAllQuestions();
        }
    }, [])

    const handleAllQuestions = async () => {
        setselectedCategoryId(-1);
        setFlag(1);
        console.log(location.state.selectedCategories);
        let category_ids = [];
        let training_questions = 0;
        for (let i = 0; i < location.state.selectedCategories.length; i++) {
            if (location.state.selectedCategories[i].value !== "training_questions") {
                category_ids.push(location.state.selectedCategories[i].value);
            } else {
                training_questions = 1;
            }
        }
        console.log(location.state.selectedDepartments);
        console.log(location.state.selectedSemesters);
        let dept_data = [];
        for (let i = 0; i < location.state.selectedSemesters.length; i++) {
            let data = location.state.selectedSemesters[i];
            data = data.toString();
            let dept_id = parseInt(data.slice(0, -1));
            let sem_id = parseInt(data.slice(-1));
            if (dept_data.some((obj) => obj.university_degree_department_id === dept_id)) {
                for (let j = 0; j < dept_data.length; j++) {
                    if (dept_data[j].university_degree_department_id === dept_id) {
                        dept_data[j].semesters.push(sem_id);
                    }
                }
            } else {
                dept_data.push({ "university_degree_department_id": dept_id, "semesters": [sem_id] });
            }
        }
        console.log(dept_data);
        const allQuestions = await getAllGeneralFeedbackQuestions(category_ids, dept_data, training_questions);
        console.log(allQuestions);
        setAllFeedbackQuestions(allQuestions?.data);
        if(allQuestions?.data){
            let flag = 1;
            if(allQuestions?.data?.general_questions){
                for(let i = 0; i< allQuestions.data.general_questions.length;i++){
                    if(!selectedquestions.includes(allQuestions.data.general_questions[i].id)){
                        flag = 0;
                        break;
                    }
                }
            }
            if(allQuestions?.data?.training_questions){
                for(let i = 0; i< allQuestions.data.training_questions.length;i++){
                    if(!selectedquestions.includes(allQuestions.data.training_questions[i].id)){
                        flag = 0;
                        break;
                    }
                }
            }

            if(flag){
                setCheckedAllQuesions(true);
            }else{
                setCheckedAllQuesions(false);
            }
        }
    };

    const handleCategoryQuestions = async (cat_id) => {
        setselectedCategoryId(cat_id);
        if (cat_id === "training_questions") {
            let dept_data = [];
            for (let i = 0; i < location.state.selectedSemesters.length; i++) {
                let data = location.state.selectedSemesters[i];
                data = data.toString();
                let dept_id = parseInt(data.slice(0, -1));
                let sem_id = parseInt(data.slice(-1));
                if (dept_data.some((obj) => obj.university_degree_department_id === dept_id)) {
                    for (let j = 0; j < dept_data.length; j++) {
                        if (dept_data[j].university_degree_department_id === dept_id) {
                            dept_data[j].semesters.push(sem_id);
                        }
                    }
                } else {
                    dept_data.push({ "university_degree_department_id": dept_id, "semesters": [sem_id] });
                }
            }
            console.log(dept_data);
            const questions_data = await getGeneralCategoryQuestions("", dept_data, 1);
            setCategoryQuestions(questions_data?.data);
            setFlag(2);

            if(questions_data?.data){
                let flag = 1;
                if(questions_data?.data){
                    for(let i = 0; i< questions_data.data.length;i++){
                        if(!selectedquestions.includes(questions_data.data[i].id)){
                            flag = 0;
                            break;
                        }
                    }
                }
    
                if(flag){
                    setCheckedAllQuesions(true);
                }else{
                    setCheckedAllQuesions(false);
                }
            }
        } else {
            const questions_data = await getGeneralCategoryQuestions(cat_id, "", 0);
            setCategoryQuestions(questions_data?.data);
            setFlag(2);

            if(questions_data?.data){
                let flag = 1;
                if(questions_data?.data){
                    for(let i = 0; i< questions_data.data.length;i++){
                        if(!selectedquestions.includes(questions_data.data[i].id)){
                            flag = 0;
                            break;
                        }
                    }
                }
    
                if(flag){
                    setCheckedAllQuesions(true);
                }else{
                    setCheckedAllQuesions(false);
                }
            }
        }
    };

    const handleSelectQuestions = (e, question) => {
        console.log(e.target.checked);
        console.log(question);

        if (e.target.checked) {
            setSelectedQuestions([...selectedquestions, parseInt(e.target.value)]);
            setAddedQuestions([...addedQuestions, question]);
        }
        else {
            setSelectedQuestions(selectedquestions.filter((ev) => (ev !== parseInt(e.target.value))));
            setAddedQuestions(addedQuestions.filter(ques => parseInt(ques.id) != parseInt(e.target.value)));
            setCheckedAllQuesions(false);
        }
    }

    const handleChooseQuestions = () => {
        setChooseQuestions(true);
        setAddQuestions(false);
    }
    const handleAddQuestions = () => {
        setChooseQuestions(false);
        setAddQuestions(true);
    }

    const handleInsertnewQuestion = (newQues) => {
        console.log(newQues);
        setNewQuestion(newQues);
    }

    const handleSaveQuestions = async (e) => {
        let cat_id = selectedCategoryId;
        if (cat_id === -1) {
            toast.error("Please select a category to add question!", {
                position: "top-right",
                autoClose: 2000,
            });
            return;
        }
        if (cat_id === "training_questions") {
            toast.error("You can`t add a training question!", {
                position: "top-right",
                autoClose: 2000,
            });
            return;
        }

        const questionName = JSON.stringify(newQuestion.toString("html")).replace(/<\/?p>/g, '').slice(1, -1)
        if (questionName === "" || questionName === "<br>") {
            toast.error("Please add a question to continue!", {
                position: "top-right",
                autoClose: 2000,
            });
            return;
        } else {
            const q = await addQuestion(
                cat_id,
                questionName
            );
            if (q.message === "Successfully added Question" && q.status === 200) {
                handleAllQuestions();
                toast.success("Question added Successfully!", {
                    position: "top-right",
                    autoClose: 2000,
                });
                setNewQuestion(RichTextEditor.createEmptyValue());

            } else {
                toast.error("Unable to add question, Please try again!", {
                    position: "top-right",
                    autoClose: 2000,
                });
            }
        }
    };

    // const handleEditCategories = () => {
    //     navigate('/feedbacks/feedbackCategories', {
    //         state: {
    //             selectedSections: location.state.selectedSections,
    //             startTime: location.state.startTime,
    //             endTime: location.state.endTime,
    //             gradingScale: location.state.gradingScale,
    //             title: location.state.title,
    //             Description: location.state.Description,
    //             ratingScale: location.state.ratingScale
    //         }
    //     })
    // };
    const handleViewMoreDetails = (question_id) => {
        setViewMoreQuestions(prevData => [...prevData, parseInt(question_id)]);
    };

    const handleViewLessDetails = (question_id) => {
        setViewMoreQuestions(viewMoreQuestions.filter(qid => qid != question_id));
    };

    const SubmitSurvey = async () => {
        closeConfirmationModal();
        if (location.state.title === "" || location.state.Description === "" || location.state.startTime === "" ||
            location.state.endTime === "" || location.state.gradingScale === "" || location.state.ratingScale === "") {
            toast.error("Please enter all the details", {
                position: "top-right",
                autoClose: 2000,
            });
            return;
        }
        if (selectedquestions.length === 0) {
            toast.error("Select Questions to Continue!", {
                position: "top-right",
                autoClose: 2000,
            });
            return;
        }
        setIsCreateLoaderActive(true);
        const create = await createsurvey(location.state.selectedSections,
            location.state.startTime,
            location.state.endTime,
            location.state.gradingScale,
            2,
            selectedquestions,
            location.state.title,
            location.state.Description,
            "", location.state.ratingScale);
        if(parseInt(create.status) == 200){
            setTimeout(() => {
                setIsCreateLoaderActive(false);
                navigate("/submit");
            },2000);
        }else{
            setTimeout(() => {
                setIsCreateLoaderActive(false);
                toast.error(`Some error occured, Please try again! - ${create.message}`, {
                position: "top-right",
                });
            },2000);
        }
    }
    console.log("allFeedbackQuestions -> ", allFeedbackQuestions);
    console.log(selectedquestions);
    console.log(addedQuestions);

    const selectAllQuestions = (e) => {
        console.log(e.target.checked);
        // setSelectedQuestions([...selectedquestions, parseInt(e.target.value)]);
        // setAddedQuestions([...addedQuestions, question]);
        let selectedQuestionIds = [...selectedquestions];
        let addedQuestionsNames = [...addedQuestions];
        if (e.target.checked) {
            if (flag === 1 && allFeedbackQuestions?.general_questions?.length > 0) {
                for (let i = 0; i < allFeedbackQuestions.general_questions.length; i++) {
                    if (!selectedQuestionIds.includes(allFeedbackQuestions.general_questions[i].id)) {
                        selectedQuestionIds.push(allFeedbackQuestions.general_questions[i].id);
                        addedQuestionsNames.push(allFeedbackQuestions.general_questions[i]);
                    }
                }
            }
            if (flag === 1 && allFeedbackQuestions?.training_questions?.length > 0) {
                for (let i = 0; i < allFeedbackQuestions.training_questions.length; i++) {
                    if (!selectedQuestionIds.includes(allFeedbackQuestions.training_questions[i].id)) {
                        selectedQuestionIds.push(allFeedbackQuestions.training_questions[i].id);
                        addedQuestionsNames.push(allFeedbackQuestions.training_questions[i]);
                    }
                }
            }
            if (flag === 2 && categoryQuestions?.length > 0) {
                for (let i = 0; i < categoryQuestions.length; i++) {
                    if (!selectedQuestionIds.includes(categoryQuestions[i].id)) {
                        selectedQuestionIds.push(categoryQuestions[i].id);
                        addedQuestionsNames.push(categoryQuestions[i]);
                    }
                }
            }
            setCheckedAllQuesions(true);
        } else {
            console.log("1");
            if (flag === 1 && allFeedbackQuestions?.general_questions?.length > 0) {
                console.log("2");
                console.log(" -> ",selectedQuestionIds);
                for (let i = 0; i < allFeedbackQuestions.general_questions.length; i++) {
                    console.log("..-> ",allFeedbackQuestions.general_questions[i].id);
                    selectedQuestionIds = selectedQuestionIds.filter((ev) => (ev !== parseInt(allFeedbackQuestions.general_questions[i].id)));
                    addedQuestionsNames = addedQuestionsNames.filter(ques => parseInt(ques.id) != parseInt(allFeedbackQuestions.general_questions[i].id));
                }
                console.log(" -> ",selectedQuestionIds);
            }
            if (flag === 1 && allFeedbackQuestions?.training_questions?.length > 0) {
                console.log("3");
                for (let i = 0; i < allFeedbackQuestions.training_questions.length; i++) {
                    selectedQuestionIds = selectedQuestionIds.filter((ev) => (ev !== parseInt(allFeedbackQuestions.training_questions[i].id)));
                    addedQuestionsNames = addedQuestionsNames.filter(ques => parseInt(ques.id) != parseInt(allFeedbackQuestions.training_questions[i].id));
                }
            }
            if (flag === 2 && categoryQuestions?.length > 0) {
                console.log("4");
                for (let i = 0; i < categoryQuestions.length; i++) {
                    selectedQuestionIds = selectedQuestionIds.filter((ev) => (ev !== parseInt(categoryQuestions[i].id)));
                    addedQuestionsNames = addedQuestionsNames.filter(ques => parseInt(ques.id) != parseInt(categoryQuestions[i].id));
                }
            }
            setCheckedAllQuesions(false);
        }
        setSelectedQuestions(selectedQuestionIds);
        setAddedQuestions(addedQuestionsNames);
    }

    const closeConfirmationModal = () => {
        setOpenConfirmationModal(false);
    }

    const openShowConfirmationModal = () => {
        setOpenConfirmationModal(true);
    }

    return (
        <Box>
            <Navbar />
            <LayoutwithSideNav>
            <LoadingOverlay
                active={isCreateLoaderActive}
                spinner
                text='Creating Feedbacks, Please wait!'
                >
                {/* page header part */}
                <Box sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={8} md={8} lg={8}>
                            <Typography variant="h4" sx={{ wordBreak: "break-all", fontFamily: "Source Sans Pro" }}>
                                {location.state.title}
                            </Typography>
                            <Divider sx={{
                                width: "5rem",
                                height: "4px",
                                textAlign: "start",
                                ml: 0,
                                mt: 1,
                                mb: "15px",
                                background: "linear-gradient(131deg, #7175E0 0%, #4D50CB 33%, #E25BC8 100%)",
                                borderRadius: "5px",
                                opacity: "100%",
                            }} />
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <Button variant="contained" id="next" sx={styles.button} onClick={openShowConfirmationModal}>Send &nbsp;
                                <TelegramIcon sx={{ fontSize: "1.25rem" }} /></Button>
                        </Grid>
                    </Grid>
                </Box>

                {
                    openConfirmationModal 
                    ? (
                        <ModalwithButtons
                            open={openConfirmationModal}
                            onClose={closeConfirmationModal}
                            submitLabel={"Continue"}
                            onSubmit={SubmitSurvey}
                            title={"Confirmation Alert"}
                            submitButtonType="primary"
                            errorMessage=""
                        >
                            <Typography variant="body1">Are you sure, Do you want to send the feedback?</Typography>
                        </ModalwithButtons>
                    )
                    : ""
                }

                {/* After header  */}
                <Box sx={{ p: 2 }}>
                    <Grid container spacing={2} sx={{ mb: 1 }}>
                        <Grid item xs={2} md={2} lg={2}>
                            <Typography variant="body1"
                                sx={{ fontWeight: 600, color: 'grey', float: 'right', fontSize: '0.9rem', mt: 1 }}
                            >
                                Categories:
                            </Typography>
                        </Grid>
                        <Grid item xs={10} md={10} lg={10}>
                            <Grid container spacing={2}>
                                <Grid item sx={styles.categorySection}>
                                    <Typography variant="body1" sx={selectedCategoryId === -1 ? styles.category_name_active : styles.category_name} onClick={handleAllQuestions}>All</Typography>
                                </Grid>
                                {
                                    location.state.selectedCategories.length > 0
                                        ? location.state.selectedCategories.map(cat => (
                                            <Grid item sx={styles.categorySection}>
                                                <Typography
                                                    variant="body1"
                                                    sx={selectedCategoryId === cat.value ? styles.category_name_active : styles.category_name}
                                                    onClick={() => handleCategoryQuestions(cat.value)}
                                                >
                                                    {cat.name}
                                                </Typography>
                                            </Grid>
                                        ))
                                        : null
                                }
                                {/* <Grid item sx={styles.categorySection}>
                                    <BorderColorOutlinedIcon sx={{ fontSize: '1.25rem', cursor: 'pointer' }} onClick={handleEditCategories} />
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Table for showing Questions */}
                    <Grid container spacing={2}>
                        <Grid item sm={2} md={2} lg={2}>
                            <Typography variant="body1" sx={styles.label}>Question Panel</Typography>
                            <Grid container spacing={0} sx={styles.question_box}>
                                <div>
                                    {
                                        addedQuestions.length !== 0
                                            ? addedQuestions.map((question, index) => (
                                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mb: 1 }}>
                                                    <Typography variant="body1" sx={{ ...styles.addedQuestionLabel, margin: 0 }}>
                                                        {index + 1}) {question.question}
                                                    </Typography>
                                                </Grid>
                                            ))
                                            : (<Typography sx={{ fontSize: "12px", textAlign: "center", fontWeight: 600 }}>No Questions added yet!</Typography>)
                                    }
                                </div>
                            </Grid>
                        </Grid>

                        {
                            chooseQuestions && (
                                <Grid item sm={10} md={10} lg={10}>
                                    <Grid container spacing={2} sx={{ marginTop: '-20px' }}>
                                        <Grid item xs={5} sm={5} md={5} lg={5} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                            <Typography variant="body1" sx={styles.label}>Choose Questions</Typography>
                                            <Typography variant="body1" sx={{ marginTop: '18px', marginLeft: '15px', textDecoration: 'underline', color: 'grey' }}>Select all: </Typography>
                                            <Checkbox
                                                value="checkall"
                                                onChange={(e) => selectAllQuestions(e)}
                                                checked={checkedAllQuesions}
                                                sx={{ marginTop: '8px' }}
                                            />
                                        </Grid>
                                        <Grid item xs={7} sm={7} md={7} lg={7}>
                                            {
                                                chooseQuestions && (
                                                    <Button variant="contained" id="next"
                                                        sx={{ ...styles.button, maxWidth: "200px", float: 'right' }}
                                                        onClick={handleAddQuestions}>Add Questions &nbsp;
                                                        <AddIcon sx={{ fontSize: "1.4rem" }} /></Button>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={styles.question_box}>
                                        <Grid item sm={12} md={12} lg={12}>
                                            {
                                                flag === 1 && allFeedbackQuestions?.general_questions?.length > 0
                                                    ? allFeedbackQuestions.general_questions.map((question, index) => (
                                                        <div sx={{ width: '100%' }}>
                                                            <Grid container spacing={2} sx={{ p: 1, ml: 1, width: '100%' }}>
                                                                <Grid item sm={10} md={10} lg={10}>
                                                                    <Typography variant="body1" sx={{ display: 'inline', fontWeight: 600 }}>Q{index + 1}. </Typography>
                                                                    <Typography variant="body1" sx={{ display: 'inline' }}>{question.question}</Typography>
                                                                </Grid>
                                                                <Grid item sm={2} md={2} lg={2}>
                                                                    <Checkbox
                                                                        value={question.id}
                                                                        onChange={(e) => handleSelectQuestions(e, question)}
                                                                        checked={selectedquestions.length !== 0 ? selectedquestions.includes(question.id) ? "checked" : false : false}
                                                                        sx={{ float: "right", mr: 3 }}
                                                                    />
                                                                </Grid>
                                                                <Grid item sm={12} md={12} lg={12}>
                                                                    {
                                                                        question.training_question === 0
                                                                            ? (
                                                                                <>
                                                                                    <Grid container spacing={2}>
                                                                                        <Grid item sm={12} md={12} lg={12} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                            <Typography variant="body1" sx={{ fontWeight: 600, color: 'grey', fontSize: '14px' }}>Category: &nbsp;</Typography>
                                                                                            <Typography variant="body1" sx={{ color: 'grey', fontSize: '14px' }}>{question.category_name}</Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    {
                                                                                        question.options.length > 0
                                                                                        ? (
                                                                                            <Grid container spacing={2}>
                                                                                                <Grid item sm={12} md={12} lg={12} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                                    <Typography variant="body1" sx={{ fontWeight: 600, color: 'grey', fontSize: '14px' }}>Options: &nbsp;</Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        )
                                                                                        : null
                                                                                    }
                                                                                    {
                                                                                        question.options.length > 0
                                                                                        ? (
                                                                                            <Grid container spacing={2}>
                                                                                                {
                                                                                                    question.options.reverse().map(option => {
                                                                                                        return (
                                                                                                            <Grid item sm={6} md={6} lg={6} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                                                <Typography variant="body1" sx={{ fontSize: '14px' }}>{option.name}</Typography>
                                                                                                            </Grid>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </Grid>
                                                                                        )
                                                                                        : null
                                                                                    }

                                                                                </>
                                                                            )
                                                                            : null
                                                                    }
                                                                </Grid>
                                                                <Divider sx={styles.divider2} />
                                                            </Grid>
                                                        </div>
                                                    ))
                                                    : null
                                            }
                                            {
                                                flag === 1 && allFeedbackQuestions?.training_questions?.length > 0
                                                    ? allFeedbackQuestions.training_questions.map((question, index) => (
                                                        <div sx={{ width: '100%' }}>
                                                            <Grid container spacing={2} sx={{ p: 1, ml: 1, width: '100%' }}>
                                                                <Grid item sm={10} md={10} lg={10}>
                                                                    <Typography variant="body1" sx={{ display: 'inline', fontWeight: 600 }}>Q{index + 1 + allFeedbackQuestions?.general_questions?.length}. </Typography>
                                                                    <Typography variant="body1" sx={{ display: 'inline' }}>{question.question}</Typography>
                                                                </Grid>
                                                                <Grid item sm={2} md={2} lg={2}>
                                                                    <Checkbox
                                                                        value={question.id}
                                                                        onChange={(e) => handleSelectQuestions(e, question)}
                                                                        checked={selectedquestions.length !== 0 ? selectedquestions.includes(parseInt(question.id)) ? true : false : false}
                                                                        sx={{ float: "right", mr: 3 }}
                                                                    />
                                                                </Grid>
                                                                <Grid item sm={12} md={12} lg={12}>
                                                                    {
                                                                        question.training_question === 1
                                                                            ? (
                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                        <Typography variant="body1" sx={{ fontWeight: 600, color: 'grey', fontSize: '14px' }}>Category: &nbsp;</Typography>
                                                                                        <Typography variant="body1" sx={{ color: 'grey', fontSize: '14px' }}>Training Question</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                                        <Accordion sx={{ boxShadow: 'none', border: 'none', p: 0, mt: -3 }}>
                                                                                            <AccordionSummary aria-controls="panel-content"
                                                                                                sx={{
                                                                                                    cursor: 'pointer',
                                                                                                    width: '150px',
                                                                                                    float: 'right',
                                                                                                    marginTop: '-20px'
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    viewMoreQuestions.includes(parseInt(question.id))
                                                                                                        ? (
                                                                                                            <Typography variant="body1" sx={{ ml: 2 }} onClick={() => handleViewLessDetails(question.id)}>View Less
                                                                                                                <ExpandLessOutlinedIcon />
                                                                                                            </Typography>
                                                                                                        )
                                                                                                        : (
                                                                                                            <Typography variant="body1" sx={{ ml: 2 }} onClick={() => handleViewMoreDetails(question.id)}>View More
                                                                                                                <ExpandMoreOutlinedIcon />
                                                                                                            </Typography>
                                                                                                        )
                                                                                                }

                                                                                            </AccordionSummary>
                                                                                            <AccordionDetails>
                                                                                                <Grid container spacing={2}>
                                                                                                    <Grid item sm={4} md={4} lg={4} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                                        <Typography variant="body1" sx={{ fontWeight: 600 }}>Training Name: &nbsp;</Typography>
                                                                                                        <Typography variant="body1">{question.training_name}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item sm={4} md={4} lg={4} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                                        <Typography variant="body1" sx={{ fontWeight: 600 }}>Batch Start: &nbsp;</Typography>
                                                                                                        <Typography variant="body1">{question.batch_start}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item sm={4} md={4} lg={4} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                                        <Typography variant="body1" sx={{ fontWeight: 600 }}>Batch End: &nbsp;</Typography>
                                                                                                        <Typography variant="body1">{question.batch_end}</Typography>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                                {
                                                                                                    question?.options.length > 0
                                                                                                    ? (
                                                                                                        <Grid container spacing={2} sx={{marginTop: '-40px'}}>
                                                                                                            <Grid item sm={12} md={12} lg={12} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                                                <Typography variant="body1" sx={{ fontWeight: 600, color: 'grey', fontSize: '14px' }}>Options: &nbsp;</Typography>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    )
                                                                                                    : null
                                                                                                }
                                                                                                {
                                                                                                    question?.options.length > 0
                                                                                                    ? (
                                                                                                        <Grid container spacing={2} sx={{marginTop: '-30px'}}>
                                                                                                            {
                                                                                                                question.options.reverse().map(option => {
                                                                                                                    return (
                                                                                                                        <Grid item sm={6} md={6} lg={6} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                                                            <Typography variant="body1" sx={{ fontSize: '14px' }}>{option.name}</Typography>
                                                                                                                        </Grid>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </Grid>
                                                                                                    )
                                                                                                    : null
                                                                                                }
                                                                                            </AccordionDetails>
                                                                                        </Accordion>
                                                                                    </Grid>
                                                                                </Grid>

                                                                            )
                                                                            : null
                                                                    }
                                                                </Grid>
                                                                <Divider sx={styles.divider2} />
                                                            </Grid>
                                                        </div>
                                                    ))
                                                    : null
                                            }

                                            {
                                                flag === 2 && categoryQuestions?.length > 0
                                                    ? categoryQuestions.map((question, index) => (
                                                        <div sx={{ width: '100%' }}>
                                                            <Grid container spacing={2} sx={{ p: 1, ml: 1, width: '100%' }}>
                                                                <Grid item sm={10} md={10} lg={10}>
                                                                    <Typography variant="body1" sx={{ display: 'inline', fontWeight: 600 }}>Q{index + 1}. </Typography>
                                                                    <Typography variant="body1" sx={{ display: 'inline' }}>{question.question}</Typography>
                                                                </Grid>
                                                                <Grid item sm={2} md={2} lg={2}>
                                                                    <Checkbox
                                                                        value={question.id}
                                                                        onChange={(e) => handleSelectQuestions(e, question)}
                                                                        checked={selectedquestions.length !== 0 ? selectedquestions.includes(question.id) ? "checked" : false : false}
                                                                        sx={{ float: "right", mr: 3 }}
                                                                    />
                                                                </Grid>
                                                                {
                                                                    question.options.length > 0
                                                                    ? (
                                                                        <Grid item container spacing={2} sx={{marginTop: '-40px'}}>
                                                                            <Grid item sm={12} md={12} lg={12} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                <Typography variant="body1" sx={{ fontWeight: 600, color: 'grey', fontSize: '14px' }}>Options: &nbsp;</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    )
                                                                    : null
                                                                }
                                                                {
                                                                    question.options.length > 0
                                                                    ? (
                                                                        <Grid item container spacing={2} sx={{marginTop: '-30px'}}>
                                                                            {
                                                                                question.options.reverse().map(option => {
                                                                                    return (
                                                                                        <Grid item sm={6} md={6} lg={6} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                            <Typography variant="body1" sx={{ fontSize: '14px' }}>{option.name}</Typography>
                                                                                        </Grid>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Grid>
                                                                    )
                                                                    : null
                                                                }
                                                                <Grid item sm={12} md={12} lg={12}>
                                                                    {
                                                                        question.training_question === 0
                                                                            ? (<>
                                                                                {/* // <Grid container spacing={2}>
                                                                                //     <Grid item sm={12} md={12} lg={12} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                //         <Typography variant="body1" sx={{ fontWeight: 600, color: 'grey' }}>Category: &nbsp;</Typography>
                                                                                //         <Typography variant="body1" sx={{ color: 'grey' }}>{question.category_name}</Typography>
                                                                                //     </Grid>
                                                                                // </Grid> */}
                                                                            </>
                                                                            )
                                                                            : (
                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                        <Typography variant="body1" sx={{ fontWeight: 600, color: 'grey', fontSize: '14px' }}>Category: &nbsp;</Typography>
                                                                                        <Typography variant="body1" sx={{ color: 'grey', fontSize: '14px' }}>Training Question</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                                        <Accordion sx={{ boxShadow: 'none', border: 'none', p: 0, mt: -3 }}>
                                                                                            <AccordionSummary aria-controls="panel-content"
                                                                                                sx={{
                                                                                                    cursor: 'pointer',
                                                                                                    width: '150px',
                                                                                                    float: 'right',
                                                                                                    marginTop: '-20px'
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    viewMoreQuestions.includes(parseInt(question.id))
                                                                                                        ? (
                                                                                                            <Typography variant="body1" sx={{ ml: 2 }} onClick={() => handleViewLessDetails(question.id)}>View Less
                                                                                                                <ExpandLessOutlinedIcon />
                                                                                                            </Typography>
                                                                                                        )
                                                                                                        : (
                                                                                                            <Typography variant="body1" sx={{ ml: 2 }} onClick={() => handleViewMoreDetails(question.id)}>View More
                                                                                                                <ExpandMoreOutlinedIcon />
                                                                                                            </Typography>
                                                                                                        )
                                                                                                }
                                                                                            </AccordionSummary>
                                                                                            <AccordionDetails>
                                                                                                <Grid container spacing={2}>
                                                                                                    <Grid item sm={4} md={4} lg={4} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                                        <Typography variant="body1" sx={{ fontWeight: 600 }}>Training Name: &nbsp;</Typography>
                                                                                                        <Typography variant="body1">{question.training_name}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item sm={4} md={4} lg={4} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                                        <Typography variant="body1" sx={{ fontWeight: 600 }}>Batch Start: &nbsp;</Typography>
                                                                                                        <Typography variant="body1">{question.batch_start}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item sm={4} md={4} lg={4} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                                        <Typography variant="body1" sx={{ fontWeight: 600 }}>Batch End: &nbsp;</Typography>
                                                                                                        <Typography variant="body1">{question.batch_end}</Typography>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                                {
                                                                                                    question?.options.length > 0
                                                                                                    ? (
                                                                                                        <Grid container spacing={2} sx={{marginTop: '-40px'}}>
                                                                                                            <Grid item sm={12} md={12} lg={12} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                                                <Typography variant="body1" sx={{ fontWeight: 600, color: 'grey', fontSize: '14px' }}>Options: &nbsp;</Typography>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    )
                                                                                                    : null
                                                                                                }
                                                                                                {
                                                                                                    question?.options.length > 0
                                                                                                    ? (
                                                                                                        <Grid container spacing={2} sx={{marginTop: '-30px'}}>
                                                                                                            {
                                                                                                                question.options.reverse().map(option => {
                                                                                                                    return (
                                                                                                                        <Grid item sm={6} md={6} lg={6} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                                                                                            <Typography variant="body1" sx={{ fontSize: '14px' }}>{option.name}</Typography>
                                                                                                                        </Grid>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </Grid>
                                                                                                    )
                                                                                                    : null
                                                                                                }
                                                                                            </AccordionDetails>
                                                                                        </Accordion>
                                                                                    </Grid>
                                                                                </Grid>

                                                                            )
                                                                    }
                                                                </Grid>
                                                                <Divider sx={styles.divider2} />
                                                            </Grid>
                                                        </div>
                                                    ))
                                                    : null
                                            }
                                            {
                                                flag === 1 && allFeedbackQuestions?.general_questions?.length === 0 && allFeedbackQuestions?.training_questions?.length === 0
                                                    ? (
                                                        <Grid container justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
                                                            <Grid item>
                                                                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                                                    No Questions to show!
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                    : null
                                            }
                                            {
                                                flag === 2 && categoryQuestions?.length === 0
                                                    ? (
                                                        <Grid container justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
                                                            <Grid item>
                                                                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                                                    No Questions to show!
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                    : null
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            addQuestions && (
                                <Grid item sm={10} md={10} lg={10}>
                                    <Grid container spacing={2} sx={{ marginTop: '0px', mb: 1 }}>
                                        <Grid xs={5} sm={5} md={5} lg={5}>
                                            <Typography variant="body1" sx={styles.label}>Type in your Question</Typography>
                                        </Grid>
                                        <Grid xs={7} sm={7} md={7} lg={7}>
                                            {
                                                addQuestions && (
                                                    <Button variant="contained" id="next" sx={{ ...styles.button, maxWidth: "250px", float: 'right' }} onClick={handleChooseQuestions}>Choose Questions &nbsp;
                                                        <AddIcon sx={{ fontSize: "1.4rem" }} /></Button>
                                                )
                                            }
                                        </Grid>
                                    </Grid>

                                    <RichTextEditor toolbarConfig={toolbarConfig} className="RTE" placeholder="Write your own question" editorClassName="my-editor" value={newQuestion} onChange={handleInsertnewQuestion} />


                                    <Grid container spacing={2}>
                                        <Grid item sm={9} md={9} lg={9} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                            <Typography variant="body1" sx={styles.label}>
                                                Note
                                                <Typography variant="body1" component="sup" sx={styles.redText}>
                                                    *
                                                </Typography>
                                            </Typography>
                                            <Typography variant="body1" sx={{ ...styles.label, fontWeight: 500, fontSize: '1.1rem' }}>
                                                &nbsp;Rating will be given on the scale of
                                            </Typography>
                                            <Typography variant="body1" sx={{ ...styles.label, fontSize: '1.1rem' }}>
                                                &nbsp;1-{location.state.ratingScale}.
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={3} md={3} lg={3}>
                                            <Button variant="contained" id="next" sx={{ ...styles.button, maxWidth: "200px", mt: 1, fontSize: '1rem' }} onClick={handleSaveQuestions}>Add Question &nbsp;
                                                <AddIcon sx={{ fontSize: "1.4rem" }} /></Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }

                    </Grid>
                </Box>
                <ToastContainer />
            </LoadingOverlay>
            </LayoutwithSideNav >
        </Box >
    )
}

export default GeneralFeedbackQuestions;