import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import ModalwithButtons from "../common/ModalwithButtons";
import TableWithSortingCommon from "../common/Table/TableWithSortingCommon";
import { setSelectedSendSurvey } from "../../store/reducers/facultyPerformanceReview.reducer";
// import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from "@mui/material/Tooltip";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import MaterialDateTimePicker from "../common/MaterialDateTimePicker.jsx";
import Grid from "@mui/material/Grid";
import moment from 'moment';
import SendSurveyUnitDetailsCard from "./SendSurveyUnitDetailsCard";
import Alert from "@mui/material/Alert";


SendSurvey.defaultProps = {
  startDate : moment().add(1, 'minutes'),
  endDate : null,
  setStartDate : null,
  setEndDate : null,
}


export default function SendSurvey({sx,startDate,setStartDate,endDate,setEndDate}) {
  const { selectedSendSurvey } = useSelector((state) => state.FacultyPerformanceReview);
  const [hasFeedbacks,setHasFeedbacks] = useState(false);
  // const [endDate,setEndDate] = useState(null);


  const dispatch = useDispatch();
  // let hasFeedbacks = false;

  useEffect(() => {
    console.log(selectedSendSurvey)
    const hasFeedbacksCheck= selectedSendSurvey?.some((element)=>{
      return element?.has_feedback
    })
    console.log(hasFeedbacksCheck)
    setHasFeedbacks(hasFeedbacksCheck)
  }, [selectedSendSurvey]);

//   Error Handling
  useEffect(()=>{
    const currentTime = moment();
    
    if(currentTime>startDate && setStartDate){
      setStartDate(currentTime.add(1, 'minutes'))
    }
    // if(endDate<startDate){
    //     setEndDate(startDate.add(1, 'minutes'))
    // }
  },[startDate,setStartDate])

  const headArray = [
    {
      id: "department_name",
      label: "Department",
    },
    {
      id: "semester_id",
      label: "Semester",
    },
    {
      id: "subject_name",
      label: "Subject",
    },
    {
      id: "section_name",
      label: "Section",
    },
    {
      id: "faculty_name",
      label: "Faculty",
    },
    {
      id: "unit_name",
      label: "Unit",
    },
    {
      id: "score",
      label: "Score",
    },
    {
      id: "has_feedback",
      label: "Has Feedback",
    },
  ];

  const handleDeleteButton = (row) => {
    let newselectedSendFeedback = [...selectedSendSurvey];
    console.log(row);
    const selectedIndex = newselectedSendFeedback.findIndex(
      ((selectedUnitData) => selectedUnitData?.college_account_subject_college_department_section === row?.college_account_subject_college_department_section
        &&  selectedUnitData?.unit_id === row?.unit_id) 
    );

    newselectedSendFeedback = [].concat(
      newselectedSendFeedback.slice(0, selectedIndex),
      newselectedSendFeedback.slice(selectedIndex + 1)
    );

    dispatch(
      setSelectedSendSurvey({ selectedSendSurvey: newselectedSendFeedback })
    );
  };

  return (
    <Grid container flexDirection={'column'} sx={sx}>

      {hasFeedbacks && <Grid item xs={12} sx={{py:2}}>
      <Alert severity="info">
            Be aware that if a feedback already exits for the unit,
            any feedback you send will overwrite the previous
            feedback, including any feedback submitted by the students.
          </Alert>
      </Grid>}

      <Grid container item spacing={3} direction='row' justifyContent='center'>
        {/* <Grid item> Please select Start Date and Time : </Grid> */}
        <Grid item xs={6} lg={3}>
          <MaterialDateTimePicker
            value={startDate}
            setValue={setStartDate}
            label="Start Date"
            minDateTime = {moment()}
            // maxDateTime ={endDate}
            />
          </Grid>
        <Grid item xs={6} lg={3}>
          <MaterialDateTimePicker
            value={endDate}
            setValue={setEndDate}
            label="End Date"
            minDateTime = {startDate}
          />
        </Grid>
      </Grid>
      {/* If there are more than two selected then we show a table view, or else we'll show a Unit View */}
      {selectedSendSurvey?.length > 1 ? <Grid item>
        <TableWithSortingCommon
          data={selectedSendSurvey}
          heading={"Selected Faculty to Send Survey"}
          headArray={headArray}
          primaryKey={"unique_unit"}
          multiselect={false}
          showCheckbox={false}
          buttonName={
            <Tooltip title="Remove">
              <RemoveCircleIcon size="small" />
            </Tooltip>
          }
          // buttonName={"Remove"}
          handleButtonSubmit={handleDeleteButton}
          buttonColor="grey"
        />
      </Grid>
      : selectedSendSurvey?.length === 1 ?
        <Grid item>
          <SendSurveyUnitDetailsCard selectedSectionData={selectedSendSurvey[0]}/>
        </Grid>
      : null
      }
    </Grid>
  );
}
