import axios from "axios";
import { setAllDepartments, setAllSemesters, setSectionMapping ,setAllSubjects,setAllSections, setAllFaculty} from "../reducers/facultySectionMapping.reducer";
import { v4 as uuidv4 } from 'uuid';
import { sortByString, getAllDepartments,getAllSemester,getAllSubjects, getAllSections, getAllFaculty } from "../../lib/utils";


const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const getAccessToken = () => localStorage.getItem("token");

export const getFacultySubjectMappings = () => {
  return async (dispatch) => {
    const fetchData = async () => {

      let endpoint = `${BASE_API_URL}/feedback/getFacultySubjectMappings`;
      let options = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        redirect: "follow",
      };
      const response = await axios.get(endpoint, options);

      if (response?.data?.status !== 200) {
        // alert(
        //   `Error : ${response?.data?.message}, Retriving Faculty-Student Allocation Data Failed`
        // );
        throw response;
      }
      // console.log(response);

      const semMappingResponse = response?.data?.data;

      // Transforming Response data to Faculty Section Mapping Data
      let deptSemMappingData = [];
      semMappingResponse.forEach(({ id, short_name, sections }) => {
        sections.forEach(
          ({ section_id, section_name, semester_id, subjects }) => {
            subjects.forEach((subject) => {
              const unique_id = uuidv4().slice(0,8)
              const subjectData = {
                ...subject,
                department_id: id,
                department_name: short_name,
                section_id,
                section_name,
                semester_id,
                unique_id,
              };
              deptSemMappingData.push(subjectData);
            });
            // return spreadedData
          }
        );
      });
      console.log(deptSemMappingData);
      dispatch(setSectionMapping(deptSemMappingData));


      if (deptSemMappingData?.length) {
        // ------ Setting Department Data
        let allDepartments = getAllDepartments(deptSemMappingData);

        allDepartments.sort(sortByString); 
        console.log(allDepartments);
        dispatch(setAllDepartments({ allDepartments }));

        // -------- Setting Subjects Data
        const allSubjects = getAllSubjects(deptSemMappingData);

        // console.log(allSubjects);
        dispatch(setAllSubjects({ allSubjects }));

        // ----- Setting Semesters Data
        const allSemesters = getAllSemester(deptSemMappingData);
        // console.log(allSemesters);
        dispatch(setAllSemesters({ allSemesters }));

        // ----- Setting Sections Data
        const allSections = getAllSections(deptSemMappingData);
        // console.log(allSections);
        dispatch(setAllSections({ allSections }));

        // ----- Setting Faculty Data
        const allFaculty = getAllFaculty(deptSemMappingData);
        // console.log(allFaculty);
        dispatch(setAllFaculty({ allFaculty }));
      }

      // const data = response.data;
      // console.log(data);
      return response;
    };

    try {
      const fetchedData = await fetchData();
      console.log(fetchedData);
      return fetchedData;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
};


export const fetchAllDepartments = () => {
  return async (dispatch) => {
    const fetchData = async () => {

      let endpoint = `${BASE_API_URL}/weekly_challenge/getDepartments`;
      let options = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        redirect: "follow",
      };
      const response = await axios.get(endpoint, options);

      if (response?.data?.status !== 200) {
        alert(
          `Error : ${response?.data?.message}, Retriving Department Data`
        );
        throw response;
      }
      // console.log(response);

      // const data = response.data;
      // console.log(data);
      return response;
    };

    try {
      const fetchedData = await fetchData();
      console.log(fetchedData);
      return fetchedData;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
};


export const getAllFaculties = () => {

  
  return async (dispatch) => {
    const fetchData = async () => {

      const formData = new FormData();
      let endpoint = `${BASE_API_URL}/feedback/getAllFaculties`;
      let options = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        redirect: "follow",
        body: formData,

      };
      const response = await axios.post(endpoint,formData, options);

      if (response?.data?.status !== 200) {
        alert(
          `Error : ${response?.data?.message}, Retriving Faculty Data`
        );
        throw response;
      }
      // console.log(response);

      // const data = response.data;
      // console.log(data);
      return response;
    };

    try {
      const fetchedData = await fetchData();
      console.log(fetchedData);
      return fetchedData;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
};


export const addFacultySubjectMapping = (section_id,subject_semester_id,college_account_id) => {
  return async (dispatch) => {
    const fetchData = async () => {

      let endpoint = `${BASE_API_URL}/feedback/addFacultySubjectMapping?section_id=${section_id}&subject_semester_id=${subject_semester_id}&college_account_id=${college_account_id}`;
      let options = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        redirect: "follow",
      };
      const response = await axios.get(endpoint, options);

      // // Handling Faculty Already Added Message
      // if(response?.data?.status === 204){
      //   alert('The faculty is already added to the Subject-Section')
      // }

      if (response?.data?.status !== 200) {
        // alert(
        //   `Error : ${response?.data?.message}, Could not add Faculty to the Subject-Section`
        // );
        throw response;
      }
      // console.log(response);

      // const data = response.data;
      // console.log(data);
      return response;
    };

    try {
      const fetchedData = await fetchData();
      console.log(fetchedData);
      return fetchedData;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
};


export const deactivateFacultySubjectMapping = (college_account_subject_college_department_section_id) => {
  return async (dispatch) => {
    const fetchData = async () => {

      let endpoint = `${BASE_API_URL}/feedback/deactivateFacultySubjectMapping?college_account_subject_college_department_section_id=${college_account_subject_college_department_section_id}`;
      let options = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        redirect: "follow",
      };
      const response = await axios.get(endpoint, options);

      if (response?.data?.status !== 200) {
        alert(
          `Error : ${response?.data?.message}, Could not delete Faculty from the Subject-Section`
        );
        throw response;
      }
      // console.log(response);

      // const data = response.data;
      // console.log(data);
      return response;
    };

    try {
      const fetchedData = await fetchData();
      console.log(fetchedData);
      return fetchedData;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
};
