import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BsPencil } from "react-icons/bs";
import { IoAlarmOutline } from "react-icons/io5";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { TiTick } from "react-icons/ti";
import { FaRegSave } from "react-icons/fa";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { BiHelpCircle } from "react-icons/bi";

import "./editWeeklyChallenge.styles.css";
import { getDepartments, getSemesters, editWeeklyChallenge, getWeeklyGeneralCoordinators, getWeeklyChallengeDetails, getChallengeCategories } from "../../api/weeklyChallengeAPIs";

const EditWeeklyChallenge = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [getExistedDetails, setGetExistedDetails] = useState(true);
    const [existing_challenge_details, setExistingChallengeDetails] = useState(null);
    const [is_ctitle, setIsCTitle] = useState(true);
    const [challengeTitle, setChallengeTitle] = useState('Arena Title');
    const [is_cdesc, setIsCDesc] = useState(true);
    const [challengeDesc, setChallengeDesc] = useState('Set Arena Description');
    const [is_cdate, setIsCDate] = useState(true);
    const [challengeDate, setChallengeDate] = useState('Date & Time');
    const [is_iterrative, setIsCIterrative] = useState(false);
    const [challengeFrequency, setChallengeFrequency] = useState(0);
    const [challengeSubFrequency, setChallengeSubFrequency] = useState('');
    const [challengeFrequencyDays, setChallengeFrequencyDays] = useState('');
    const [challengeFrequencyWeek, setChallengeFrequencyWeek] = useState('');
    const [is_sub_frequency1, setIsSubFrequency1] = useState(false);
    const [is_sub_frequency2, setIsSubFrequency2] = useState(false);
    const [enrollmentType, setEnrollmentType] = useState(2);
    const [viewer_enrollmentType, setViewerEnrollmentType] = useState(2);
    const [showEnrollType, setshowEnrollType] = useState('Both students and faculty can enroll.');
    const [participationType, setParticipationType] = useState(1);
    const [teamSize, setTeamSize] = useState(4);
    const [isTeamSize, setIsTeamSize] = useState(true);
    const [maxRegistrations, setMaxRegistrations] = useState(150);
    const [showQuestionsCategory, setshowQuestionsCategory] = useState([]);
    const [noofquestions, setNoOfQuestions] = useState(15);
    const [departments, setDepartments] = useState([]);
    const [viewer_departments, setViewerDepartments] = useState([]);
    const [semesters, setSemesters] = useState([]);
    const [showChallengeModal, setChallengeModalShow] = useState(false);
    const [showChallengeCoordinatorsModal, setChallengeCoordinatorsModal] = useState(false);
    const [allDepartments, setAllDepartments] = useState([]);
    const [allGeneralCoordinators, setAllGeneralCoordinators] = useState(null);
    const [allChallengeCoordinators, setAllChallengeCoordinators] = useState([]);
    const [showChallengeAlertModal, setShowChallengeAlertModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState("Loading...");
    const [customizeContinueBtn, setCustomizeContinueBtn] = useState(false);
    const [defaultContinueBtn, setDefaultContinueBtn] = useState(false);
    const [showChallengeCreatedAlertModal, setShowChallengeCreatedAlertModal] = useState(false);
    const [is_loading, setIsLoading] = useState(true);
    const [is_challengeConfirmMsg, setChallengeCreatedConfirmMsg] = useState(false);
    const [challengeCreateResponse, setChallengeCreateResponse] = useState("Arena Created Successfully!");
    const [is_univDegreeDptIds, setIsUnivDegreeDptIds] = useState(false);
    const [univDegreeDepts, setUnivDegreeDepts] = useState([]);
    const [semester_items, setAllSemesters] = useState([]);
    const [departmentSemData, setDepartmentSemData] = useState([]);
    const [viewer_departmentSemData, setViewerDepartmentSemData] = useState([]);
    const [departmentSemDataDisplay, setDepartmentSemDataDisplay] = useState([]);
    const [prevDepartmentSemDataDisplay, setPrevDepartmentSemDataDisplay] = useState([]);
    const [viewer_departmentSemDataDisplay, setViewerDepartmentSemDataDisplay] = useState([]);
    const [questionsCategory, setQuestionsCategory] = useState([1, 2]);
    const [masterCategoryData, setMasterCategoryData] = useState([]);
    const [enrollmentClass1, setEnrollmentClass1] = useState("");
    const [enrollmentClass2, setEnrollmentClass2] = useState("");
    const [enrollmentClass3, setEnrollmentClass3] = useState("input-selected");
    const [viewer_enrollmentClass1, setViewerEnrollmentClass1] = useState("");
    const [viewer_enrollmentClass2, setViewerEnrollmentClass2] = useState("");
    const [viewer_enrollmentClass3, setViewerEnrollmentClass3] = useState("input-selected");
    const [participationClass1, setParticipationClass1] = useState("");
    const [participationClass2, setParticipationClass2] = useState("input-selected");
    const [timePerQuestionClass1, setTimePerQuestionClass1] = useState("");
    const [timePerQuestionClass2, setTimePerQuestionClass2] = useState("");
    const [timePerQuestionClass3, setTimePerQuestionClass3] = useState("input-selected");
    const [timePerQuestion, setTimePerQuestion] = useState(30);
    const [choosingQuestionsClass1, setChoosingQuestionsClass1] = useState("");
    const [choosingQuestionsClass2, setChoosingQuestionsClass2] = useState("input-selected");
    const [choosingQuestions, setChoosingQuestion] = useState(0);
    const [isRecommendedBtnActive, setIsRecommendedActive] = useState(true);
    const [isCustomizeBtnActive, setIsCustomizeActive] = useState(false);
    const [showChallengeErrorAlertModal, setShowChallengeErrorAlertModal] = useState(false);
    const [challengeErrorMessage, setChallengeErrorMessage] = useState("");
    const [selectedSemesterData, setSelectedSemesterData] = useState([]);
    const [prevSelectedSemData, setPrevSelectedSemData] = useState([]);
    const [prevSelectedDepartments, setPrevSelectedDepartments] = useState([]);
    const [selectedViewerSemesterData, setSelectedViewerSemesterData] = useState([]);
    const [prevSelectedViewerSemData, setPrevSelectedViewerSemData] = useState([]);
    const [prevSelectedViewerDepartments, setPrevSelectedViewerDepartments] = useState([]);
    const [selectedDepartmentsFile, setSelectedDepartmentsFile] = useState(null);
    const [participantDeptSel, setParticipantDeptSel] = useState("");
    const [participantDeptSelColor, setParticipantDeptSelColor] = useState("");
    const [participantDeptSelFile, setParticipantDeptSelFile] = useState("");
    const [participantDeptSelFileColor, setParticipantDeptSelFileColor] = useState("");
    const [viewerDeptSel, setViewerDeptSel] = useState("");
    const [viewerDeptSelColor, setViewerDeptSelColor] = useState("");
    const [showCreateHelpModal, setShowCreateHelpModal] = useState(false);

    useEffect(() => {
        if (getExistedDetails) {
            getWeeklyChallengeExistedDetails();
            setGetExistedDetails(false);
        }
    });
    const getWeeklyChallengeExistedDetails = async () => {
        const challengeDetails = await getWeeklyChallengeDetails(location.state.challenge_id);
        console.log(challengeDetails.data);
        setExistingChallengeDetails(challengeDetails.data);
        setChallengeTitle(challengeDetails.data.title);
        setChallengeDesc(challengeDetails.data.description);
        setChallengeDate(challengeDetails.data.start_date);
        setChallengeFrequency(challengeDetails.data.recurring);
        setEnrollmentType(challengeDetails.data.enrollment_type);
        if (challengeDetails.data.enrollment_type === 0) {
            setshowEnrollType('Only students can enroll.');
            setEnrollmentClass1("input-selected");
            setEnrollmentClass2("");
            setEnrollmentClass3("");
        } else if (challengeDetails.data.enrollment_type === 1) {
            setshowEnrollType('Only faculty can enroll.');
            setEnrollmentClass1("");
            setEnrollmentClass2("input-selected");
            setEnrollmentClass3("");
        } else {
            setshowEnrollType('Both students and faculty can enroll.');
            setEnrollmentClass1("");
            setEnrollmentClass2("");
            setEnrollmentClass3("input-selected");
        }
        setViewerEnrollmentType(challengeDetails.data.viewer_type);
         if (challengeDetails.data.viewer_type === 0) {
             setViewerEnrollmentClass1("input-selected");
             setViewerEnrollmentClass2("");
             setViewerEnrollmentClass3("");
         } else if (challengeDetails.data.viewer_type === 1) {
             setViewerEnrollmentClass1("");
             setViewerEnrollmentClass2("input-selected");
             setViewerEnrollmentClass3("");
         } else {
             setViewerEnrollmentClass1("");
             setViewerEnrollmentClass2("");
             setViewerEnrollmentClass3("input-selected");
         }
        setParticipationType(challengeDetails.data.participation_type);
        if (challengeDetails.data.participation_type === 0) {
            setIsTeamSize(false);
            setParticipationClass1("input-selected");
            setParticipationClass2("");
        } else {
            setIsTeamSize(true);
            setParticipationClass1("");
            setParticipationClass2("input-selected");
        }
        setTeamSize(challengeDetails.data.team_size);
        setMaxRegistrations(challengeDetails.data.no_of_participants);
        setNoOfQuestions(challengeDetails.data.no_of_questions);
        setDepartments(challengeDetails.data.college_university_degree_department_id);
        setSemesters([]);
        setTimePerQuestion(challengeDetails.data.time_per_question);
        if (challengeDetails.data.time_per_question === '30') {
            setTimePerQuestionClass1("input-selected");
            setTimePerQuestionClass2("");
            setTimePerQuestionClass3("");
        } else if (challengeDetails.data.time_per_question === '60') {
            setTimePerQuestionClass1("");
            setTimePerQuestionClass2("input-selected");
            setTimePerQuestionClass3("");
        } else {
            setTimePerQuestionClass1("");
            setTimePerQuestionClass2("");
            setTimePerQuestionClass3("input-selected");
        }
        setChoosingQuestion(challengeDetails.data.random_questions);
        if (challengeDetails.data.random_questions === 0) {
            setChoosingQuestionsClass1("");
            setChoosingQuestionsClass2("input-selected");
        } else {
            setChoosingQuestionsClass1("input-selected");
            setChoosingQuestionsClass2("");
        }
        if (challengeDetails.data.random_questions === 1) {
            setIsRecommendedActive(false);
            setIsCustomizeActive(true);
        }

        setAllChallengeCoordinators(challengeDetails.data.coordinators);

        setQuestionsCategory(challengeDetails.data.category_ids);
        //get Arena categories
        const master_categories = await getChallengeCategories();
        let category_list_labels = [];
        for (let i = 0; i < challengeDetails.data.category_ids.length; i++) {
            for (let j = 0; j < master_categories.data.length; j++) {
                if (parseInt(challengeDetails.data.category_ids[i]) === parseInt(master_categories.data[j].id)) {
                    category_list_labels.push(master_categories.data[j].name);
                }
            }
        }
        setshowQuestionsCategory(category_list_labels);

        if (challengeDetails.data.department_sem_data) {
            setDepartmentSemData(challengeDetails.data.department_sem_data);
            setParticipantDeptSel("#EB5C67");
            setParticipantDeptSelColor("white");
        }
        //getting departmentSemDataDisplay
        const departments = await getDepartments();
        let department_sem_data_display = [];
        let selected_semester_data = [];
        let selected_departments = [];
        for (let i = 0; i < challengeDetails.data.department_sem_data.length; i++) {
            let semester_list_labels = [];
            let department_label;
            let semesters = challengeDetails.data.department_sem_data[i].semesters;
            for (let j = 0; j < semesters.length; j++) {
                const sem_id = semesters[j];
                selected_semester_data.push(parseInt(challengeDetails.data.department_sem_data[i].college_university_degree_department_id + "" + sem_id));
            }
            //get department label
            for (let j = 0; j < departments.data.length; j++) {
                if (challengeDetails.data.department_sem_data[i].college_university_degree_department_id === departments.data[j].id) {
                    department_label = departments.data[j].name;
                    for (let k = 0; k < departments.data[j].semesters.length; k++) {
                        semester_list_labels.push(parseInt(departments.data[j].semesters[k].id));
                    }
                }
            }
            department_sem_data_display.push({
                "id": challengeDetails.data.department_sem_data[i].college_university_degree_department_id,
                "department_name": department_label,
                "semesters": semester_list_labels
            });
            selected_departments.push(parseInt(challengeDetails.data.department_sem_data[i].college_university_degree_department_id));
        }
        setDepartmentSemDataDisplay(department_sem_data_display);
        setPrevDepartmentSemDataDisplay(department_sem_data_display);
        setSelectedSemesterData(selected_semester_data);
        setPrevSelectedSemData(selected_semester_data);
        setPrevSelectedDepartments(selected_departments);

        if (challengeDetails.data.viewer_department_sem_data) {
            setViewerDepartmentSemData(challengeDetails.data.viewer_department_sem_data);
            setViewerDeptSel("#EB5C67");
            setViewerDeptSelColor("white");
        }
        //getting departmentSemDataDisplay
        //const departments = await getDepartments();
        let viewer_department_sem_data_display = [];
        let viewer_selected_semester_data = [];
        let viewer_selected_departments = [];
        for (let i = 0; i < challengeDetails.data.viewer_department_sem_data.length; i++) {
            let semester_list_labels = [];
            let department_label;
            let semesters = challengeDetails.data.viewer_department_sem_data[i].semesters;
            for (let j = 0; j < semesters.length; j++) {
                const sem_id = semesters[j];
                viewer_selected_semester_data.push(parseInt(challengeDetails.data.viewer_department_sem_data[i].college_university_degree_department_id + "" + sem_id));
            }
            //get department label
            for (let j = 0; j < departments.data.length; j++) {
                if (challengeDetails.data.viewer_department_sem_data[i].college_university_degree_department_id === departments.data[j].id) {
                    department_label = departments.data[j].name;
                    for (let k = 0; k < departments.data[j].semesters.length; k++) {
                        semester_list_labels.push(parseInt(departments.data[j].semesters[k].id));
                    }
                }
            }
            viewer_department_sem_data_display.push({
                "id": challengeDetails.data.viewer_department_sem_data[i].college_university_degree_department_id,
                "department_name": department_label,
                "semesters": semester_list_labels
            });
            viewer_selected_departments.push(parseInt(challengeDetails.data.viewer_department_sem_data[i].college_university_degree_department_id));
        }
        setViewerDepartmentSemDataDisplay(viewer_department_sem_data_display);
        setSelectedViewerSemesterData(viewer_selected_semester_data);
        setPrevSelectedViewerSemData(viewer_selected_semester_data);
        setPrevSelectedViewerDepartments(viewer_selected_departments);


    };

    const handleChallengeCustomAlertModalShow = () => {
        setDefaultContinueBtn(false);
        setCustomizeContinueBtn(true);
        setAlertMessage("Are you sure. Do you want to customize the Arena?");
        setShowChallengeAlertModal(true);
    };

    const handleChallengeFefaultAlertModalShow = () => {
        setDefaultContinueBtn(true);
        setCustomizeContinueBtn(false);
        setAlertMessage("Are you sure. Do you want to contiue with Recommended options?");
        setShowChallengeAlertModal(true);
    };
    const handleChallengeAlertModalClose = () => setShowChallengeAlertModal(false);

    const handleChallengeCreatedAlertModalClose = () => setShowChallengeCreatedAlertModal(false);

    const handleChallengeModalClose = () => {
        setChallengeModalShow(false);
    }
    const handleChallengeModalShow = () => {
        executeDepartments();
        executeCategories();
        setShowChallengeAlertModal(false);
        setChallengeModalShow(true);
        setIsRecommendedActive(false);
        setIsCustomizeActive(true);
    }

    const executeCategories = async () => {
        const categories = await getChallengeCategories();
        setMasterCategoryData(categories.data);
    }

    const handleChallengeCoordinatorsModalClose = () => setChallengeCoordinatorsModal(false);
    const handleChallengeCoordinatorsModalShow = async () => {
        const coordinators_list = await getWeeklyGeneralCoordinators();
        setChallengeCoordinatorsModal(true);
        setAllGeneralCoordinators(coordinators_list.data);
    }
    const handleCTitleHide = () => {
        setIsCTitle(false);
    }
    const handleCTitleShow = () => {
        setIsCTitle(true);
    }
    const handleWeeklyChallengeTitle = (e) => {
        e.preventDefault();
        setChallengeTitle(e.target.value);
    }
    const handleCDescHide = () => {
        setIsCDesc(false);
    }
    const handleCDescShow = () => {
        setIsCDesc(true);
    }
    const handleWeeklyChallengeDesc = (e) => {
        e.preventDefault();
        setChallengeDesc(e.target.value);
    }
    const handleDateShow = () => {
        setIsCDate(true);
    }
    const handleDateHide = () => {
        setIsCDate(false);
    }
    const handleChallengeErrorAlertModalClose = () => setShowChallengeErrorAlertModal(false);
    const handleWeeklyChallengeDate = (e) => {
        e.preventDefault();
        const currentTimeStamp = Date.parse(e.target.value);
        const t = new Date(currentTimeStamp);
        const formattedDate =
            (t.getFullYear())
            + '-' + ('0' + (t.getMonth() + 1)).slice(-2)
            + '-' + ('0' + t.getDate()).slice(-2)
            + ' ' + ('0' + t.getHours()).slice(-2)
            + ':' + ('0' + t.getMinutes()).slice(-2)
            + ':' + ('0' + t.getSeconds()).slice(-2)
        setChallengeDate(formattedDate);
    }
    const handleFrequency = (e) => {
        if (e.target.value === '1') {
            setIsCIterrative(true);
        } else {
            setIsCIterrative(false);
            setChallengeSubFrequency('');
            setChallengeFrequencyDays('');
        }
        setChallengeFrequency(parseInt(e.target.value));
    }
    const handleSubFrequency = (e) => {
        setChallengeSubFrequency(e.target.value);
        if (e.target.value === "1" || e.target.value === "2") {
            setIsSubFrequency1(true);
            setIsSubFrequency2(false);
        } else if (e.target.value === "3") {
            setIsSubFrequency1(false);
            setIsSubFrequency2(true);
        }
    }
    const handlChallengeRecurringDays = (e) => {
        setChallengeFrequencyDays(e.target.value);
    }
    const handlChallengeRecurringWeek = (e) => {
        setChallengeFrequencyWeek(e.target.value);
    }
    const handleEnrollmentType = (e) => {
        setEnrollmentType(parseInt(e.target.value));
        if (e.target.value === '0') {
            setshowEnrollType('Only students can enroll.');
            setEnrollmentClass1("input-selected");
            setEnrollmentClass2("");
            setEnrollmentClass3("");
        } else if (e.target.value === '1') {
            setshowEnrollType('Only faculty can enroll.');
            setEnrollmentClass1("");
            setEnrollmentClass2("input-selected");
            setEnrollmentClass3("");
        } else {
            setshowEnrollType('Both students and faculty can enroll.');
            setEnrollmentClass1("");
            setEnrollmentClass2("");
            setEnrollmentClass3("input-selected");
        }
    }
    const handleViewerEnrollmentType = (e) => {
        setViewerEnrollmentType(parseInt(e.target.value));
        if (e.target.value === '0') {
            setViewerEnrollmentClass1("input-selected");
            setViewerEnrollmentClass2("");
            setViewerEnrollmentClass3("");
        } else if (e.target.value === '1') {
            setViewerEnrollmentClass1("");
            setViewerEnrollmentClass2("input-selected");
            setViewerEnrollmentClass3("");
        } else {
            setViewerEnrollmentClass1("");
            setViewerEnrollmentClass2("");
            setViewerEnrollmentClass3("input-selected");
        }
    }
    const handleParticipationType = (e) => {
        setParticipationType(parseInt(e.target.value));
        if (e.target.value === '0') {
            setIsTeamSize(false);
            setParticipationClass1("input-selected");
            setParticipationClass2("");
        } else {
            setIsTeamSize(true);
            setParticipationClass1("");
            setParticipationClass2("input-selected");
        }
    }

    const handleTeamSize = (e) => {
        setTeamSize(e.target.value);
    }
    const handleMaxRegistrations = (e) => {
        setMaxRegistrations(e.target.value);
    }
    const handleQuestionsCategory = (e) => {
        for (let i = 0; i < masterCategoryData.length; i++) {
            if (parseInt(e.target.value) === parseInt(masterCategoryData[i].id) && e.target.checked === true) {
                setQuestionsCategory(prevVals => [...prevVals, parseInt(e.target.value)]);
                setshowQuestionsCategory(prevData => [...prevData, masterCategoryData[i].name]);
                if (parseInt(masterCategoryData[i].id) === 3) {
                    setIsUnivDegreeDptIds(true);
                }
            } else if (parseInt(e.target.value) === parseInt(masterCategoryData[i].id) && e.target.checked === false) {
                let existingCats = questionsCategory;
                existingCats = existingCats.filter((item) => item !== parseInt(masterCategoryData[i].id));
                setQuestionsCategory(existingCats);

                let existingShowCats = showQuestionsCategory;
                existingShowCats = existingShowCats.filter((item) => item !== masterCategoryData[i].name);
                setshowQuestionsCategory(existingShowCats);
                if (parseInt(masterCategoryData[i].id) === 3) {
                    setIsUnivDegreeDptIds(false);
                    setUnivDegreeDepts([]);
                }
            }
        }
    };

    const handleUnivDegreedepartments = (e) => {
        setUnivDegreeDepts(e);
    }

    const handleTotalNoQuestions = (e) => {
        setNoOfQuestions(e.target.value);
    };

    const handleTimePerQuestion = (e) => {
        setTimePerQuestion(e.target.value);
        if (e.target.value === '30') {
            setTimePerQuestionClass1("input-selected");
            setTimePerQuestionClass2("");
            setTimePerQuestionClass3("");
        } else if (e.target.value === '60') {
            setTimePerQuestionClass1("");
            setTimePerQuestionClass2("input-selected");
            setTimePerQuestionClass3("");
        } else {
            setTimePerQuestionClass1("");
            setTimePerQuestionClass2("");
            setTimePerQuestionClass3("input-selected");
        }
    };

    const handleChoosingQuestions = (e) => {
        setChoosingQuestion(e.target.value);
        if (e.target.value === "0") {
            setChoosingQuestionsClass1("");
            setChoosingQuestionsClass2("input-selected");
        } else {
            setChoosingQuestionsClass1("input-selected");
            setChoosingQuestionsClass2("");
        }
    };

    const handleDepartments = (e) => {
        setDepartments(e);
        //getAllSemesters(e.value);
    }
    const handleViewerDepartments = (e) => {
        setViewerDepartments(e);
    }
    const handleSemesters = (e) => {
        setSemesters(e);
    }
    const handleDefaultDetails = () => {
        setChallengeFrequency(0);
        setChallengeSubFrequency('');
        setChallengeFrequencyDays('');
        setEnrollmentType(2);
	    setViewerEnrollmentType(2);
        setshowEnrollType('Both students and faculty can enroll.');
        setParticipationType(1);
        setTeamSize(4);
        setMaxRegistrations(100);
        setQuestionsCategory([1, 2]);
        //setshowQuestionsCategory('Aptitude');
        setDepartments([]);
        setSemesters([]);
        setShowChallengeAlertModal(false);
        setIsRecommendedActive(true);
        setIsCustomizeActive(false);

        executeCategories();
    }

    const getAllSemesters = async () => {
        let allSemester_list = [];
        for (let i = 0; i < departments.length; i++) {
            //check already department exists
            let flag = true;
            for (let j = 0; j < departmentSemDataDisplay.length; j++) {
                if (parseInt(departmentSemDataDisplay[j].id) === parseInt(departments[i].value)) {
                    flag = false;
                }
            }
            if (flag === true) {
                const sem_data = await getSemesters(departments[i].value);
                let semesters = [];
                for (let j = 0; j < sem_data.data.length; j++) {
                    const sem_id = sem_data.data[j].id;
                    semesters.push(parseInt(sem_id));
                }
                allSemester_list.push({ "id": departments[i].value, "department_name": departments[i].label, "semesters": semesters });
            }
        }
        setDepartmentSemDataDisplay(prevValues => [...prevValues, ...allSemester_list]);
    }

    const getAllViewerSemesters = async () => {
        let allSemester_list = [];
        for (let i = 0; i < viewer_departments.length; i++) {
            //check already department exists
            let flag = true;
            for (let j = 0; j < viewer_departmentSemDataDisplay.length; j++) {
                if (parseInt(viewer_departmentSemDataDisplay[j].id) === parseInt(viewer_departments[i].value)) {
                    flag = false;
                }
            }
            if (flag === true) {
                const sem_data = await getSemesters(viewer_departments[i].value);
                let semesters = [];
                for (let j = 0; j < sem_data.data.length; j++) {
                    const sem_id = sem_data.data[j].id;
                    semesters.push(parseInt(sem_id));
                }
                allSemester_list.push({ "id": viewer_departments[i].value, "department_name": viewer_departments[i].label, "semesters": semesters });
            }
        }
        setViewerDepartmentSemDataDisplay(prevValues => [...prevValues, ...allSemester_list]);
    }

    const departments_list = [];
    const executeDepartments = async () => {
        const departments = await getDepartments();
        for (let i = 0; i < departments.data.length; i++) {
            const id = departments.data[i].id;
            const key = departments.data[i].name;
            departments_list.push({ value: id, label: key });
        }
        setAllDepartments(departments_list);
        //getAllSemesters();
    };



    const handleEditWeeklyChallenge = async () => {
        let department_list = [];
        let deptSemData;
        let deptSemDataFile;
        let viewer_deptSemData;
        for (let i = 0; i < univDegreeDepts.length; i++) {
            const id = univDegreeDepts[i].value;
            department_list.push(parseInt(id));
        }

        if (!challengeTitle) {
            setChallengeErrorMessage("Please enter the Arena name.");
            setShowChallengeErrorAlertModal(true);
            return;
        }
        if (!maxRegistrations) {
            setChallengeErrorMessage("Please enter the maximum registrations allowed.");
            setShowChallengeErrorAlertModal(true);
            return;
        }
        if (!noofquestions) {
            setChallengeErrorMessage("Please enter the total number of questions.");
            setShowChallengeErrorAlertModal(true);
            return;
        }
        if (participationType === 1) {
            if (!teamSize) {
                setChallengeErrorMessage("Please enter the team size.");
                setShowChallengeErrorAlertModal(true);
                return;
            }
        }
        if (allChallengeCoordinators.length === 0) {
            setChallengeErrorMessage("Please select the coordinators.");
            setShowChallengeErrorAlertModal(true);
            return;
        }
        if (challengeDate === "Date & Time") {
            setChallengeErrorMessage("Please select Date & Time.");
            setShowChallengeErrorAlertModal(true);
            return;
        }

        if (selectedDepartmentsFile != null) {
            deptSemData = "";
            deptSemDataFile = selectedDepartmentsFile;
        } else {
            deptSemData = departmentSemData;
            deptSemDataFile = "";
        }
        if (viewer_departmentSemData.length !== 0) {
            viewer_deptSemData = viewer_departmentSemData;
        } else {
            viewer_deptSemData = '';
        }

        const response = await editWeeklyChallenge(location.state.challenge_id, challengeTitle, challengeDesc, challengeDate, challengeFrequency,
            enrollmentType, participationType, teamSize, maxRegistrations, noofquestions, department_list,
            questionsCategory, timePerQuestion, choosingQuestions, allChallengeCoordinators, deptSemData, challengeSubFrequency,
            challengeFrequencyDays, challengeFrequencyWeek, deptSemDataFile, viewer_enrollmentType, viewer_deptSemData);

        setShowChallengeCreatedAlertModal(true);
        setChallengeCreatedConfirmMsg(false);
        setIsLoading(true);
        setTimeout(function () {
            setIsLoading(false);
            if (response.message === "Successfully edited weekly Challenge") {
                setChallengeCreateResponse("Arena edited Successfully!");
                setChallengeCreatedConfirmMsg(true);
                setTimeout(function () {
                    navigate('/weeklyChallenge');
                }, 1000);
            } else {
                setChallengeCreateResponse("Unable to edit Arena. Try again!");
                setChallengeCreatedConfirmMsg(true);
                setTimeout(function () {
                    setShowChallengeCreatedAlertModal(false);
                }, 1000);
            }

        }, 2000);
    };

    const handleCoordinatorFormSubmit = (e) => {
        e.preventDefault();
        let challenge_coordinators = [];
        for (let i = 2; i < e.target.length - 2; i++) {
            if (e.target[i].checked === true) {
                challenge_coordinators.push(parseInt(e.target[i].id));
            }
        }
        setAllChallengeCoordinators(challenge_coordinators);
        setChallengeCoordinatorsModal(false);
    };

    const handleCheckAllCoordinators = (e) => {
        if (e.target.checked === true) {
            let challenge_coordinator_list = [];
            for (let i = 0; i < allGeneralCoordinators.length; i++) {
                const id = allGeneralCoordinators[i].id;
                challenge_coordinator_list.push(parseInt(id));
            }
            for (let i = 0; i < challenge_coordinator_list.length; i++) {
                document.getElementById(challenge_coordinator_list[i]).checked = "checked";
            }
        } else {
            let challenge_coordinator_list = [];
            for (let i = 0; i < allGeneralCoordinators.length; i++) {
                const id = allGeneralCoordinators[i].id;
                challenge_coordinator_list.push(parseInt(id));
            }
            for (let i = 0; i < challenge_coordinator_list.length; i++) {
                document.getElementById(challenge_coordinator_list[i]).checked = false;
            }
        }
    };

    const handleSubmitDepartmentSemData = (e) => {
        getAllSemesters();
        setSelectedDepartmentsFile(null);
        setParticipantDeptSel("#EB5C67");
        setParticipantDeptSelColor("white");
        setParticipantDeptSelFile("");
        setParticipantDeptSelFileColor("");
    };
    const handleSubmitViewerDepartmentSemData = (e) => {
        getAllViewerSemesters();
        setViewerDeptSel("#EB5C67");
        setViewerDeptSelColor("white");
    };

    const handleDegDeptSemester = (e, department_id) => {
        if (!prevSelectedSemData.includes(parseInt(department_id + "" + e.target.value))) {
            if (e.target.checked === true) {
                let existingData = departmentSemData;
                let i = 0;
                for (i = 0; i < departmentSemData.length; i++) {
                    if (departmentSemData[i].college_university_degree_department_id === department_id) {
                        existingData[i].semesters.push(parseInt(e.target.value));
                        break;
                    }
                }
                if (i === departmentSemData.length) {
                    existingData.push({ "college_university_degree_department_id": department_id, "semesters": [parseInt(e.target.value)] });
                }
                setDepartmentSemData(existingData);
                setSelectedSemesterData(prevData => [...prevData, parseInt(department_id + "" + e.target.value)]);
            } else {
                let existingData = departmentSemData;
                let i = 0;
                for (i = 0; i < departmentSemData.length; i++) {
                    if (departmentSemData[i].college_university_degree_department_id === department_id) {
                        if (departmentSemData[i].semesters.length > 1) {
                            let existing_sems = departmentSemData[i].semesters;
                            existing_sems = existing_sems.filter(item => item !== parseInt(e.target.value));
                            existingData[i].semesters = existing_sems;
                        } else {
                            existingData = existingData.filter(item => parseInt(item.college_university_degree_department_id) !== parseInt(department_id));
                        }
                    }
                }
                setDepartmentSemData(existingData);
                let existingSelectedSemData = selectedSemesterData;
                existingSelectedSemData = existingSelectedSemData.filter(item => item != parseInt(department_id + "" + e.target.value));
                setSelectedSemesterData(existingSelectedSemData);
            }
        }

    };

    const handleViewerDegDeptSemester = (e, department_id) => {
        if (!prevSelectedViewerSemData.includes(parseInt(department_id + "" + e.target.value))) {
            if (e.target.checked === true) {
                let existingData = viewer_departmentSemData;
                let i = 0;
                for (i = 0; i < viewer_departmentSemData.length; i++) {
                    if (viewer_departmentSemData[i].college_university_degree_department_id === department_id) {
                        existingData[i].semesters.push(parseInt(e.target.value));
                        break;
                    }
                }
                if (i === viewer_departmentSemData.length) {
                    existingData.push({ "college_university_degree_department_id": department_id, "semesters": [parseInt(e.target.value)] });
                }
                setViewerDepartmentSemData(existingData);
                setSelectedViewerSemesterData(prevData => [...prevData, parseInt(department_id + "" + e.target.value)]);
            } else {
                let existingData = viewer_departmentSemData;
                let i = 0;
                for (i = 0; i < viewer_departmentSemData.length; i++) {
                    if (viewer_departmentSemData[i].college_university_degree_department_id === department_id) {
                        if (viewer_departmentSemData[i].semesters.length > 1) {
                            let existing_sems = viewer_departmentSemData[i].semesters;
                            existing_sems = existing_sems.filter(item => item !== parseInt(e.target.value));
                            existingData[i].semesters = existing_sems;
                        } else {
                            existingData = existingData.filter(item => parseInt(item.college_university_degree_department_id) !== parseInt(department_id));
                        }
                    }
                }
                setViewerDepartmentSemData(existingData);
                let existingSelectedSemData = selectedViewerSemesterData;
                existingSelectedSemData = existingSelectedSemData.filter(item => item != parseInt(department_id + "" + e.target.value));
                setSelectedViewerSemesterData(existingSelectedSemData);
            }
        }
    };
    console.log("==viewer=", selectedViewerSemesterData);

    const handleDelDeptSemData = (e) => {
        e.preventDefault();
        const id = parseInt(e.target.id);
        if (!prevSelectedDepartments.includes(id)) {
            let existingDepts = departmentSemData;
            existingDepts = existingDepts.filter((item) => item.college_university_degree_department_id !== id);
            setDepartmentSemData(existingDepts);
            let existingDeptsDisplay = departmentSemDataDisplay;
            existingDeptsDisplay = existingDeptsDisplay.filter((item) => item.id !== id);
            setDepartmentSemDataDisplay(existingDeptsDisplay);

            let existingSelectedSemData = selectedSemesterData;
            for (let i = 1; i <= 8; i++) {
                existingSelectedSemData = existingSelectedSemData.filter((item) => item !== parseInt(id + "" + i));
            }
            if (existingSelectedSemData.length === 0) {
                setParticipantDeptSel("");
                setParticipantDeptSelColor("");
            }
            setSelectedSemesterData(existingSelectedSemData);
        }
    };

    const handleViewerDelDeptSemData = (e) => {
        e.preventDefault();
        const id = parseInt(e.target.id);
        if (!prevSelectedViewerDepartments.includes(id)) {
            let existingDepts = viewer_departmentSemData;
            existingDepts = existingDepts.filter((item) => item.college_university_degree_department_id !== id);
            setViewerDepartmentSemData(existingDepts);
            let existingDeptsDisplay = viewer_departmentSemDataDisplay;
            existingDeptsDisplay = existingDeptsDisplay.filter((item) => item.id !== id);
            setViewerDepartmentSemDataDisplay(existingDeptsDisplay);

            let existingSelectedSemData = selectedViewerSemesterData;
            for (let i = 1; i <= 8; i++) {
                existingSelectedSemData = existingSelectedSemData.filter((item) => item !== parseInt(id + "" + i));
            }
            if (existingSelectedSemData.length === 0) {
                setViewerDeptSel("");
                setViewerDeptSelColor("");
            }
            setSelectedViewerSemesterData(existingSelectedSemData);
        }
    };

    //selecting departments excel file
    const handleSelectDepartmentsFile = (e) => {
        console.log(e.target.files);
        setSelectedDepartmentsFile(e.target.files[0]);
        setDepartmentSemData(existing_challenge_details.department_sem_data);
        setDepartmentSemDataDisplay(prevDepartmentSemDataDisplay);
        setSelectedSemesterData(prevSelectedSemData);

        setParticipantDeptSel("");
        setParticipantDeptSelColor("");
        setParticipantDeptSelFile("#EB5C67");
        setParticipantDeptSelFileColor("white");
    };
    console.log("file= ", selectedSemesterData);

    const handleCreateHelpModalClose = () => {
        setShowCreateHelpModal(false);
        handleChallengeModalShow();
    }
    const handleCreateHelpModalOpen = () => {
        setShowCreateHelpModal(true);
        handleChallengeModalClose();
    }

    return (
        <div className="all-weekly-challenges w-100">
            <div className="container-fluid px-0">
                <div className="internal-padding">
                    <div className="headerBanner px-3">
                        <div className="row w-100">
                            <div className="col-lg-8 col-md-8 col-sm-8">
                                <h2 className="mt-1">
                                    <p className="font-weight-bold" id="courseName">Edit Arena</p>
                                    <hr className="titlehr" />
                                </h2>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 d-flex align-items-center justify-content-end">
                                <button className="btn btn-primary shadow-sm" id="chooseChallengeCoordinatorsBtn"
                                    onClick={handleChallengeCoordinatorsModalShow}>
                                    Choose Coordinators
                                </button>
                                <button className="btn btn-primary shadow-sm" onClick={handleEditWeeklyChallenge} id="saveChallengeBtn">Save Changes</button>
                            </div>

                            <Modal show={showChallengeCoordinatorsModal} onHide={handleChallengeCoordinatorsModalClose} scrollable dialogClassName="addClgCoordinatorsmodalWidth">
                                <form method="POST" onSubmit={handleCoordinatorFormSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Choose Coordinators to Arena</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>

                                        <div>
                                            {
                                                allGeneralCoordinators != null
                                                    ? (
                                                        <table className="table table-bordered table-curved" id="challenge-coordinators-table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Name</th>
                                                                    <th scope="col">Department</th>
                                                                    <th scope="col">Select all
                                                                        <input type="checkbox" name="weekly-challenge-all-checkbox" onChange={handleCheckAllCoordinators} className="weekly-challenge-all-checkbox" />
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    allGeneralCoordinators.map(coordinator => (
                                                                        <tr key={coordinator.id}>
                                                                            <td scope="row">{coordinator.name}</td>
                                                                            <td scope="row">{coordinator.department_name}</td>
                                                                            <td scope="row" className="d-flex align-items-center justify-content-center">
                                                                                <input type="checkbox" name="weekly-challenge-checkbox" value={coordinator.id} id={coordinator.id} defaultChecked={allChallengeCoordinators.length != 0 ? allChallengeCoordinators.includes(String(coordinator.id)) ? "checked" : false : false} className="weekly-challenge-checkbox" />
                                                                            </td>
                                                                        </tr>)
                                                                    )
                                                                }

                                                            </tbody>
                                                        </table>
                                                    )
                                                    : (<span className="d-flex justify-content-center" id="no-coordinators-msg-label2">No Coordinators added yet!</span>)
                                            }
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleChallengeCoordinatorsModalClose} style={{ width: '20%' }} id="modalCloseBtn">
                                            Close
                                        </Button>
                                        <button type="submit" className="btn btn-primary shadow-sm" id="saveChallengeCoordinatorsBtn" >
                                            Save Coordinators
                                        </button>
                                    </Modal.Footer>
                                </form>
                            </Modal>

                            <Modal show={showChallengeCreatedAlertModal} onHide={handleChallengeCreatedAlertModalClose} id="challengeCreateAlertModal" dialogClassName="challengeCreateAlertmodalWidth">
                                <Modal.Body>
                                    {
                                        is_loading && (
                                            <div className="row w-100 d-flex align-items-center loading-div p-5">
                                                <span className="loading-div-label">Editing Arena</span><br />
                                                <div className="loading">...</div>

                                            </div>
                                        )
                                    }
                                    {
                                        is_challengeConfirmMsg && (
                                            <div className="row w-100 d-flex align-items-center p-5">
                                                {
                                                    challengeCreateResponse === "Arena Created Successfully!"
                                                        ? <TiTick id="success-tick-icon" />
                                                        : ''
                                                }
                                                <span className="challenge-success-label">{challengeCreateResponse}</span><br />
                                            </div>
                                        )
                                    }
                                </Modal.Body>
                            </Modal>

                            <Modal show={showChallengeErrorAlertModal} onHide={handleChallengeErrorAlertModalClose} id="challengeCreateAlertModal" dialogClassName="challengeCreateAlertmodalWidth">
                                <Modal.Header closeButton>
                                    <Modal.Title>Arena Alert</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p style={{ textAlign: 'center', fontSize: '1.2em' }}><strong>{challengeErrorMessage}</strong></p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="submit" className="btn btn-primary shadow-sm" onClick={handleChallengeErrorAlertModalClose} id="closeErrorAlertModalBtn" >
                                        Okay
                                    </button>
                                </Modal.Footer>
                            </Modal>



                        </div>
                    </div>
                    <div className="row w-100 d-flex align-items-center">
                        <div className="col-lg-1 col-md-1 col-sm-1"></div>
                        <div className="col-lg-10 col-md-10 col-sm-10">
                            <form action="" method="post" className="weekly-challenge-form">
                                {is_ctitle
                                    ? (<div><span className="edit_challenge-title" style={{ color: 'red' }}><strong>{challengeTitle}</strong></span>
                                        <BsPencil onClick={handleCTitleHide} className="editPencil" /></div>)
                                    : (<div><input type="text" className="form-control" defaultValue={challengeTitle} style={{ width: '55%', marginLeft: '20%', float: 'left' }} onKeyUp={handleWeeklyChallengeTitle} />
                                        <button type="button" className="btn btn-success" style={{ width: '5%', marginRight: '20%' }} onClick={handleCTitleShow}><FaRegSave className="input-save-btn" /></button></div>)
                                }
                                {is_cdesc
                                    ? (<div><span className="challenge-desc" style={{ color: '#969997' }}><strong>{challengeDesc}</strong></span>
                                        <BsPencil onClick={handleCDescHide} className="editdescPencil" /></div>)
                                    : (<div><textarea rows="2" type="text" defaultValue={challengeDesc} className="form-control" style={{ width: '55%', marginLeft: '20%', float: 'left', resize: 'none' }} onKeyUp={handleWeeklyChallengeDesc}></textarea>
                                        <button type="button" className="btn btn-success" style={{ width: '5%', marginRight: '20%' }} onClick={handleCDescShow}><FaRegSave className="input-save-btn" /></button></div>)
                                }
                                <br />
                                <h5 style={{ color: '#868786', fontWeight: "400", fontSize: "14px", marginLeft: "-10px" }}>STARTS AT</h5>
                                {is_cdate
                                    ? (<div><span className="edit_challenge-date"><strong>{challengeDate}</strong></span>
                                        <BsPencil onClick={handleDateHide} className="editPencil" /></div>)
                                    : (
                                        <div>
                                            <input type="datetime-local" value={challengeDate} className="form-control challenge-date" style={{ width: '55%', marginLeft: '20%', float: 'left' }} onChange={handleWeeklyChallengeDate} />
                                            <button type="button" className="btn btn-success" style={{ width: '5%', marginRight: '20%' }} onClick={handleDateShow}><FaRegSave className="input-save-btn" /></button>
                                        </div>
                                    )
                                }

                                <div className="row" style={{ marginTop: '1.5%' }}>
                                    <div className="col-lg-3 col-md-3 col-sm-3"></div>
                                    <div className="col-lg-3 col-md-3 col-sm-3">
                                        <input type="radio" name="challenge_frequency" className="challenge_frequency" value={0} defaultChecked={challengeFrequency === 0 ? "checked" : null} onChange={handleFrequency} disabled="disabled" /><span className="challenge_frequency_txt">One-Time</span>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3">
                                        <input type="radio" name="challenge_frequency" className="challenge_frequency" value={1} defaultChecked={challengeFrequency === 1 ? "checked" : null} onChange={handleFrequency} disabled="disabled" /><span className="challenge_frequency_txt">Recurring</span>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3"></div>
                                </div>
                                {is_iterrative
                                    ? (<div className="row" style={{ marginTop: '1.5%' }}>
                                        <div className="col-lg-3 col-md-3 col-sm-3"></div>
                                        <div className="col-lg-2 col-md-2 col-sm-2">
                                            <input type="radio" name="sub_frequency" className="sub_frequency" value={1} onChange={handleSubFrequency} /><span className="challenge_subfrequency_txt">Weekly</span>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-2">
                                            <input type="radio" name="sub_frequency" className="sub_frequency" value={2} onChange={handleSubFrequency} /><span className="challenge_subfrequency_txt">Bi-Weekly</span>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-2">
                                            <input type="radio" name="sub_frequency" className="sub_frequency" value={3} onChange={handleSubFrequency} /><span className="challenge_subfrequency_txt">Monthly</span>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3"></div>
                                        {
                                            is_sub_frequency1 ? (
                                                <div className="row">
                                                    <h5 style={{ marginTop: '1.5%' }}>Day`s</h5>
                                                    <select className="form-control on_days" style={{ width: '60%', marginLeft: '20%', float: 'left', resize: 'none' }} onChange={handlChallengeRecurringDays}>
                                                        <option value="sun">Every Sunday</option>
                                                        <option value="mon">Every Monday</option>
                                                        <option value="tue">Every Tuesday</option>
                                                        <option value="wed">Every Wednesday</option>
                                                        <option value="thu">Every Thursday</option>
                                                        <option value="fri">Every Friday</option>
                                                        <option value="sat">Every Saturday</option>
                                                    </select>
                                                </div>
                                            )
                                                : ''
                                        }
                                        {
                                            is_sub_frequency2 ? (
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-2"></div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4">
                                                        <h5 style={{ marginTop: '1.5%' }}>Day`s</h5>
                                                        <select className="form-control on_days" style={{ width: '60%', marginLeft: '20%', float: 'left', resize: 'none' }} onChange={handlChallengeRecurringDays}>
                                                            <option value="sun">Every Sunday</option>
                                                            <option value="mon">Every Monday</option>
                                                            <option value="tue">Every Tuesday</option>
                                                            <option value="wed">Every Wednesday</option>
                                                            <option value="thu">Every Thursday</option>
                                                            <option value="fri">Every Friday</option>
                                                            <option value="sat">Every Saturday</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4">
                                                        <h5 style={{ marginTop: '1.5%' }}>Week</h5>
                                                        <select className="form-control on_days" style={{ width: '60%', marginLeft: '20%', float: 'left', resize: 'none' }} onChange={handlChallengeRecurringWeek}>
                                                            <option value={1}>1</option>
                                                            <option value={2}>2</option>
                                                            <option value={3}>3</option>
                                                            <option value={4}>4</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-2"></div>
                                                </div>
                                            )
                                                : ''
                                        }

                                    </div>)
                                    : ('')
                                }
                                <br />
                                <br />
                                <div className="row w-100" style={{ marginTop: '-1%' }}>
                                    <div className="col-lg-3 col-sm-3 col-md-3"></div>
                                    <div className="col-lg-3 col-sm-3 col-md-3">
                                        <span>Default</span><br />
                                        <button type="button" className="btn btn-danger form-control recommended_btn" style={{ backgroundColor: isRecommendedBtnActive ? 'red' : '#e6e6e6', color: isRecommendedBtnActive ? 'white' : 'black', border: 'none' }} onClick={handleChallengeFefaultAlertModalShow}>Recommended</button>
                                    </div>
                                    <div className="col-lg-3 col-sm-3 col-md-3">
                                        <button type="button" className="btn btn-default form-control customize_btn" style={{ backgroundColor: isCustomizeBtnActive ? 'red' : '#e6e6e6', color: isCustomizeBtnActive ? 'white' : 'black', marginTop: '24px' }} onClick={handleChallengeCustomAlertModalShow}>Customize</button>
                                    </div>
                                    <div className="col-lg-3 col-sm-3 col-md-3"></div>
                                </div>
                            </form>
                            <ul className="intimation-list">
                                <li>{showEnrollType}</li>
                                <li>Participation limit - {maxRegistrations}.</li>
                                <li>Questions category - {showQuestionsCategory.map((category, index) => {
                                    if (showQuestionsCategory.length === 1) {
                                        return category;
                                    } else {
                                        if (showQuestionsCategory.length === 2) {
                                            if (index === 0) {
                                                return category + ", ";
                                            } else {
                                                return category;
                                            }
                                        } else {
                                            if (index === 2) {
                                                return category;
                                            } else {
                                                return category + ", ";
                                            }
                                        }
                                    }
                                })}.</li>
                                <li>It will be a {challengeFrequency === 1 ? "recurring" : "onetime"} event.</li>
                                <li>Team will have a maximum of {teamSize} members.</li>
                                {
                                    choosingQuestions === 0
                                        ? (<li>Questions will be picked at the time of Quiz.</li>)
                                        : (<li>Questions will be randomly picked.</li>)
                                }
                                <li>Enrollment will be closed an hour before the Arena starts.</li>
                            </ul>


                            <Modal show={showChallengeAlertModal} onHide={handleChallengeAlertModalClose} scrollable dialogClassName="challengeCustomAlertmodalWidth">
                                <Modal.Header closeButton>
                                    <Modal.Title>Alert </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        <span className="d-flex justify-content-center" id="challenge-custom-msg-label">{alertMessage}</span>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleChallengeAlertModalClose} id="modalCloseBtn" style={{ width: '25%' }}>
                                        Cancel
                                    </Button>
                                    {
                                        customizeContinueBtn && (
                                            <button className="btn btn-primary challenge-customize-continue-btn" onClick={handleChallengeModalShow} style={{ width: '25%' }} id="create-challenge-customize-continue-btn">Continue</button>
                                        )
                                    }{
                                        defaultContinueBtn && (
                                            <button className="btn btn-primary challenge-customize-continue-btn" onClick={handleDefaultDetails} style={{ width: '25%' }} id="create-challenge-customize-continue-btn">Continue</button>
                                        )
                                    }

                                </Modal.Footer>
                            </Modal>

                            <Modal show={showCreateHelpModal} onHide={handleCreateHelpModalClose} scrollable dialogClassName="challengeCustomAlertmodalWidth">
                                <Modal.Header>
                                    <div style={{ fontWeight: "500", fontSize: "18px" }}>Help</div>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="help-content">
                                        <label>Who can enroll?</label>
                                        <p>- You can choose the departments and semesters for whom the Arena invitation should sent.</p>
                                        <p>- You can either upload an excel file with the exact format that we provide using the
                                            “Upload participants” button. Upload participants format template can be downloaded using
                                            “Download template” button.</p>
                                        <p>- If you don't choose the departments and don’t upload the excel file, then the Arena
                                            invitation will be sent to all the members you chose under “Who can enroll?”</p>
                                        <label>Who can view?</label>
                                        <p>- You can choose the departments and semesters for whom the Arena view invitation should sent.</p>
                                        <p>- If you don't choose the departments and semesters, then the Arena view
                                            invitation will be sent to all the members except the participants you chose under “Who can view?”.</p>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCreateHelpModalClose} id="modalCloseBtn" style={{ width: '25%' }}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                            <Modal show={showChallengeModal} scrollable onHide={handleChallengeModalClose} dialogClassName="weeklyChallengemodalWidth">
                                <Modal.Header>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div style={{ fontWeight: "500", fontSize: "20px" }}>Create Arena</div>
                                        <div className="help-div" onClick={handleCreateHelpModalOpen}>help <BiHelpCircle style={{ fontSize: "18px" }} /></div>
                                    </div>
                                </Modal.Header>
                                <Modal.Body>
                                    <form action="" method="post" className="custom-weeklyChallenge-form">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="row w-100 noofregistrations-div">
                                                    <label className="custom-input-label">Maximun Registrations Allowed : </label>
                                                    <input type="number" className="form-control" defaultValue={maxRegistrations} placeholder="Enter a number" onKeyUp={handleMaxRegistrations} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="row w-100 noofquestions-div mt-2">
                                                    <div className="col-lg-4 col-md-4 col-sm-4">
                                                        <label className="custom-input-label">Total no of questions : </label>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4">
                                                        <input type="number" className="form-control" defaultValue={noofquestions} placeholder="Enter a number" onKeyUp={handleTotalNoQuestions} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <label className="custom-input-label">Participation Type : </label>
                                                <div className="row w-100 participation-div">
                                                    <label className={participationClass1}>Single <input value={0} className="radiobtn1" type='radio' onChange={handleParticipationType} style={{ display: 'none' }} name='selectParticipantion' /></label>
                                                    <label className={participationClass2}>Group <input value={1} className="radiobtn1" type='radio' onChange={handleParticipationType} style={{ display: 'none' }} name='selectParticipantion' /></label>
                                                </div>

                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="row w-100 timeforeachquestion-div">
                                                    <label className="custom-input-label">Time Per Question : </label>
                                                    <div className="row w-100 timeperquestion-div">
                                                        <label className={timePerQuestionClass1}>
                                                            <IoAlarmOutline className="timer-icon" />
                                                            30 sec<input value={30} className="radiobtn1" type='radio' onChange={handleTimePerQuestion} style={{ display: 'none' }} name='selecttimeperquestion' /></label>
                                                        <label className={timePerQuestionClass2}>
                                                            <IoAlarmOutline className="timer-icon" />
                                                            60 sec<input value={60} className="radiobtn1" type='radio' onChange={handleTimePerQuestion} style={{ display: 'none' }} name='selecttimeperquestion' /></label>
                                                        <label className={timePerQuestionClass3}>
                                                            <IoAlarmOutline className="timer-icon" />
                                                            90 sec<input value={90} className="radiobtn1" type='radio' onChange={handleTimePerQuestion} style={{ display: 'none' }} name='selecttimeperquestion' /></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {isTeamSize && (<div className="row w-100 teamsize-div mt-2">
                                                    <label className="custom-input-label">Team Size : </label>
                                                    <input type="number" className="form-control" defaultValue={teamSize} placeholder="Enter a number" onKeyUp={handleTeamSize} />
                                                </div>)}

                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="row w-100 choosingquestions-div">
                                                    <label className="custom-input-label">Choosing Questions : </label>
                                                    <div className="row w-100 choosingquestions">
                                                        <label className={choosingQuestionsClass1}>Randomly <input value={1} className="radiobtn1" type='radio' onChange={handleChoosingQuestions} style={{ display: 'none' }} name='selectchooseingquestions' /></label>
                                                        <label className={choosingQuestionsClass2}>During Quiz <input value={0} className="radiobtn1" type='radio' onChange={handleChoosingQuestions} style={{ display: 'none' }} name='selectchooseingquestions' /></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* choose questions category div */}
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12 col-md-12">
                                                <label className="col-form-label custom-input-label">Choose the Questions Category for Arena : </label>
                                                <div className="row w-100 category-div">
                                                    {
                                                        masterCategoryData.length !== 0
                                                            ? masterCategoryData.map(category => (
                                                                <label className={questionsCategory.includes(parseInt(category.id)) ? "input-selected" : ""}>{category.name}<input value={category.id} className="radiobtn1" defaultChecked={questionsCategory.includes(category.id) ? true : false} type='checkbox' onChange={handleQuestionsCategory} style={{ display: 'none' }} name='selectCategory' /></label>
                                                            ))
                                                            : ''
                                                    }
                                                </div>
                                                {
                                                    is_univDegreeDptIds && (
                                                        <div className="row w-50 departments-div">
                                                            <label>Departments</label>
                                                            <Select
                                                                isMulti
                                                                name="univDegreedepartments"
                                                                options={allDepartments}
                                                                className="departments-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={handleUnivDegreedepartments}
                                                                defaultValue={univDegreeDepts}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        {/* who can enroll div */}
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <label className="col-form-label mt-2 custom-input-label" id="col-form-label">Who can enroll?</label>
                                                <div className="row w-100 enroll-div">
                                                    <label className={enrollmentClass1}>Students <input value={0} className="radiobtn1" type='radio' onChange={handleEnrollmentType} style={{ display: 'none' }} name='selectEnrollment' /></label>
                                                    <label className={enrollmentClass2}>Faculty <input value={1} className="radiobtn1" type='radio' onChange={handleEnrollmentType} style={{ display: 'none' }} name='selectEnrollment' /></label>
                                                    <label className={enrollmentClass3}>Both <input value={2} className="radiobtn1" type='radio' onChange={handleEnrollmentType} style={{ display: 'none' }} name='selectEnrollment' /></label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6"></div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-5 col-md-5 col-sm-5">
                                                <div className="row w-100 departments-div">
                                                    <label>Departments</label>
                                                    <Select
                                                        isMulti
                                                        name="departments"
                                                        options={allDepartments}
                                                        className="departments-multi-select"
                                                        classNamePrefix="select"
                                                        onChange={handleDepartments}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7">
                                                <div style={{ marginTop: "35px", float: "left" }}>
                                                    <button type="button" className="btn btn-primary edit-add-departments-btn" style={{ backgroundColor: `${participantDeptSel}`, color: `${participantDeptSelColor}` }} id="edit-add-departments-btn" onClick={handleSubmitDepartmentSemData}>Add/Edit Department</button>
                                                </div>

                                                <div style={{ marginTop: "36px" }}>
                                                    <span style={{ marginLeft: "25px" }}>(OR)</span>
                                                    <label role="button" className="btn btn-primary selectDeptFileBtn" style={{ backgroundColor: `${participantDeptSelFile}`, color: `${participantDeptSelFileColor}` }} id="selectDeptFileBtn"><input type="file" onChange={handleSelectDepartmentsFile} style={{ display: "none" }} />Upload Participants</label>

                                                    <a role="button" href="/files/participants-upload-template.xlsx" className="btn btn-primary downloadDeptTemplateBtn" id="downloadDeptTemplateBtn" download="participants-upload-template.xlsx">Download Template <AiOutlineCloudDownload /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                {
                                                    departmentSemData.length === 0
                                                        ? (<span style={{ color: "red" }}>*Note: Participants were uploaded using the excel file.</span>)
                                                        : ""
                                                }
                                            </div>
                                        </div>

                                        <div className="row">
                                            {
                                                departmentSemDataDisplay.length != 0
                                                    ?
                                                    departmentSemDataDisplay.map(depSemId => (
                                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                                            <div className="degdeprt-outer-div">
                                                                <div className="row no-gutters">
                                                                    <div className="col-lg-11 col-md-11 col-sm-11">
                                                                        <div className="degdept-name">
                                                                            {depSemId.department_name}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-1 col-md-1 col-sm-1">
                                                                        <div className="delDeptSemDataItem" id={depSemId.id} onClick={handleDelDeptSemData}>X</div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <span style={{ color: "grey", fontSize: "0.9em" }}>Select semesters:</span>
                                                                </div>
                                                                <div className="row degdept-sem-div">
                                                                    {
                                                                        depSemId.semesters.length != 0
                                                                            ? depSemId.semesters.map(depSemName => (
                                                                                <div className="col-lg-3 col-md-3 col-sm-3 deg-department-semesters-div">
                                                                                    <label className={selectedSemesterData.includes(parseInt(depSemId.id + "" + depSemName)) ? "degdept-sem input-selected" : "degdept-sem"} style={{ textAlign: "center", cursor: "pointer" }}>{depSemName}
                                                                                        <input value={depSemName} className="semesterCheckBox" defaultChecked={selectedSemesterData.includes(parseInt(depSemId.id + "" + depSemName)) ? true : false} type='checkbox' style={{ display: 'none' }} name='selectSemester'
                                                                                            disabled={prevSelectedSemData.includes(parseInt(depSemId.id + "" + depSemName)) ? true : false} onChange={(e) => handleDegDeptSemester(e, depSemId.id)} /></label>
                                                                                </div>
                                                                            ))
                                                                            : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    : ''
                                            }
                                        </div>


                                        {/* Who can View div */}
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <label className="col-form-label mt-2 custom-input-label" id="col-form-label">Who can view?</label>
                                                <div className="row w-100 enroll-div">
                                                    <label className={viewer_enrollmentClass1}>Students <input value={0} className="radiobtn1" type='radio' onChange={handleViewerEnrollmentType} style={{ display: 'none' }} name='selectEnrollment' /></label>
                                                    <label className={viewer_enrollmentClass2}>Faculty <input value={1} className="radiobtn1" type='radio' onChange={handleViewerEnrollmentType} style={{ display: 'none' }} name='selectEnrollment' /></label>
                                                    <label className={viewer_enrollmentClass3}>Both <input value={2} className="radiobtn1" type='radio' onChange={handleViewerEnrollmentType} style={{ display: 'none' }} name='selectEnrollment' /></label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6"></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-5 col-md-5 col-sm-5">
                                                <div className="row w-100 departments-div">
                                                    <label>Departments</label>
                                                    <Select
                                                        isMulti
                                                        name="departments"
                                                        options={allDepartments}
                                                        className="departments-multi-select"
                                                        classNamePrefix="select"
                                                        onChange={handleViewerDepartments}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7">
                                                <div style={{ marginTop: "25px" }}>
                                                    <button type="button" className="btn btn-primary edit-add-departments-btn" style={{ backgroundColor: `${viewerDeptSel}`, color: `${viewerDeptSelColor}` }} id="edit-add-departments-btn" onClick={handleSubmitViewerDepartmentSemData}>Add/Edit Department</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {
                                                viewer_departmentSemDataDisplay.length != 0
                                                    ?
                                                    viewer_departmentSemDataDisplay.map(depSemId => (
                                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                                            <div className="degdeprt-outer-div">
                                                                <div className="row no-gutters">
                                                                    <div className="col-lg-11 col-md-11 col-sm-11">
                                                                        <div className="degdept-name">
                                                                            {depSemId.department_name}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-1 col-md-1 col-sm-1">
                                                                        <div className="delDeptSemDataItem" id={depSemId.id} onClick={handleViewerDelDeptSemData}>X</div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <span style={{ color: "grey", fontSize: "0.9em" }}>Select semesters:</span>
                                                                </div>
                                                                <div className="row degdept-sem-div">
                                                                    {
                                                                        depSemId.semesters.length != 0
                                                                            ? depSemId.semesters.map(depSemName => (
                                                                                <div className="col-lg-3 col-md-3 col-sm-3 deg-department-semesters-div">
                                                                                    <label className={selectedViewerSemesterData.includes(parseInt(depSemId.id + "" + depSemName)) ? "degdept-sem input-selected" : "degdept-sem"} style={{ textAlign: "center", cursor: "pointer" }}>{depSemName}
                                                                                        <input value={depSemName} className="semesterCheckBox" defaultChecked={selectedViewerSemesterData.includes(parseInt(depSemId.id + "" + depSemName)) ? true : false} type='checkbox' style={{ display: 'none' }} name='selectSemester'
                                                                                            onChange={(e) => handleViewerDegDeptSemester(e, depSemId.id)} /></label>
                                                                                </div>
                                                                            ))
                                                                            : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    : ''
                                            }
                                        </div>

                                    </form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleChallengeModalClose} id="modalCloseBtn" style={{ width: '15%' }}>
                                        Cancel
                                    </Button>
                                    <button className="btn btn-primary shadow-sm" onClick={handleChallengeModalClose} id="saveWeeklyChallengeChangesBtn" >
                                        Save Changes
                                    </button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-1"></div>
                    </div>
                </div >
            </div >
        </div >
    );
}

export default EditWeeklyChallenge;