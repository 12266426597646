import React from 'react'
import { Form, useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/esm/Button';
import { BsArrowRight } from "react-icons/bs";
//import submitgif from "/images/feedback_images/submitgif.gif";
import "./Submit.css"
const Submit = () => {
  const navigate = useNavigate();
  const changeComponent = () => {
    navigate("/feedbackHome");
  }
  return (
    <div className="row">
      <div className='col-lg-12 col-md-12 col-sm-12'>
        <h5 style={{ textAlign: "center", marginTop:"2.5%" }}>Feedback created Successfully</h5>
        <img src="/images/feedback_images/submitgif.gif" id="submitimg" />
        <Button variant="secondary" onClick={changeComponent} id="homebtn">
          Home page
          &nbsp;<BsArrowRight />
        </Button>
      </div>
    </div>
  )
}
export default Submit;
