const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const REACT_INPLACE_ENV = process.env.REACT_APP_INPLACE_ENV;
const getAccessToken = () => localStorage.getItem("token");
const accessToken = getAccessToken();
let user_details;
let current_user;
if (accessToken  && accessToken !== 'null') {
  user_details = atob(accessToken.split('.')[1]);
  user_details = JSON.parse(user_details);
  current_user = user_details.user_id;
}
export const current_user_id = current_user;

export const AllQuestions = async () => {
  let endpoint = `${BASE_API_URL}/semester_feedback/getAllQuestions`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};
export const allDepartments = async () => {
  let endpoint = `${BASE_API_URL}/semester_feedback/getAllDepartments`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};
export const allSemesters = async (id) => {
  let endpoint = `${BASE_API_URL}/semester_feedback/getSemesters?university_degree_department_id=${id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};
export const addQuestion = async (category_id, question_name) => {
  let endpoint = `${BASE_API_URL}/semester_feedback/addNewQuestion`;
  const formData = new FormData();
  formData.append("category_id", category_id);
  formData.append("name", question_name);
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "POST",
    redirect: "follow",
    body: formData,
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};
export const getSubjects = async (deptsAndSems) => {
  let endpoint = `${BASE_API_URL}/semester_feedback/getSubjects?input_data=${deptsAndSems}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};
export const getSections = async (deptsAndSems) => {
  let endpoint = `${BASE_API_URL}/semester_feedback/getSections?input_data=${deptsAndSems}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};
export const createsurvey = async (sections, start_time, end_time, grade_points, feedback_type, questions, name, description, subjects, ratingScale) => {
  let endpoint = `${BASE_API_URL}/semester_feedback/createSurveys`;
  // let endpoint = `http://127.0.0.1:5000/semester_feedback/createSurveys`;
  const formData = new FormData();
  formData.append("sections", JSON.stringify(sections));
  formData.append("start_time", start_time);
  formData.append("end_time", end_time);
  formData.append("grade_points", grade_points);
  formData.append("feedback_type", JSON.stringify(feedback_type));
  formData.append("questions", JSON.stringify(questions));
  formData.append("name", name);
  formData.append("description", description);
  formData.append("subjects", JSON.stringify(subjects));
  formData.append("rating_scale", ratingScale);
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "POST",
    redirect: "follow",
    body: formData,
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};
export const viewsurveys = async () => {
  let endpoint = `${BASE_API_URL}/semester_feedback/getAllFeedbacks`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const addSubCategory = async (name) => {
  let endpoint = `${BASE_API_URL}/semester_feedback/addFeedbackCategories?category_name=${name}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const fetchAllData = async () => {
  let endpoint = `${BASE_API_URL}/semester_feedback/getFeedbackCategories`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const getCategoryQuestions = async (id) => {
  let endpoint = `${BASE_API_URL}/semester_feedback/getCategoryQuestions?category_id=${id}`;
    let options = {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
      method: "GET",
      redirect: "follow",
    };
    return fetch(endpoint, options)
      .then((res) => res.json())
      .then((json) => {
        return json;
      })
      .catch((error) => console.log(error));
};

export const getFeedbackResults = async (type,id) => {
    // let endpoint = `${BASE_API_URL}/semester_feedback/generateFeedbackReports?${type}=${id}`;
    let endpoint = "";
    if(type == "master_feedback_id"){
      endpoint = `https://ff6wpej4xj.execute-api.ap-south-1.amazonaws.com/${REACT_INPLACE_ENV}/subjectiveFeedback?master_feedback_id=${id}&feedback_id`;
    }else{
      endpoint = `https://ff6wpej4xj.execute-api.ap-south-1.amazonaws.com/${REACT_INPLACE_ENV}/subjectiveFeedback?master_feedback_id=&feedback_id=${id}`;
    }
    
    // let endpoint = `http://127.0.0.1:5000/semester_feedback/generateFeedbackReportsTemp?feedback_ids=[1408,1409,1410,1411,1412,1413,1414,1415,1416,1417,1418,1419,1420,1421,1422,1423,1424,1425,1426,1427,1428,1429,1430,1431,1432,1433,1434,1435,1436,1437,1438,1439,1440,1441,1442,1443,1444,1445,1446,1447,1448,1449,1450,1451,1452,1453,1454,1455,1456,1457,1458,1459,1460,1461,1462,1463,1464,1465,1466,1467,1468,1469,1470,1471,1472,1473,1474,1475,1476,1477,1478,1479,1480,1481,1482,1483,1484,1485,1486,1487,1488,1489,1490,1491,1492,1493,1494,1495,1496,1497,1498,1499,1500,1501,1502,1503,1504,1505,1506,1507,1508,1509,1510,1511,1512,1513,1514,1515,1516,1517,1518,1519,1520,1521,1522,1523,1524,1525,1526,1527,1528,1529,1530,1531,1532,1533,1534,1535,1536,1537,1538,1539,1540,1541,1542,1543,1544,1545,1546,1547,1548,1549,1550,1551,1552,1553,1554,1555,1556,1557,1558,1559,1560,1561,1562,1563,1564,1565,1566,1567,1568,1569,1570,1571,1572,1573,1574,1575,1576,1577,1578,1579,1580,1581,1582,1583,1584,1585,1586,1587,1588,1589,1590,1591,1592,1593,1594,1595,1596,1597,1598,1599,1600,1601,1602,1603,1604,1605,1606,1607,1608,1609,1610,1611,1612,1613,1614,1615,1616,1617,1618,1619,1620,1621,1622,1623,1624,1625,1626,1627,1628,1629,1630,1631,1632,1633,1634,1635,1636,1637,1638,1639,1640,1641,1642,1643,1644,1645,1646,1647,1648,1649,1650,1651,1652,1653,1654,1655,1656,1657,1658,1659,1660,1661,1662,1663,1664,1665,1666,1667,1668,1669,1670,1671,1672,1673,1674,1675,1676,1677,1678,1679,1680,1681,1682,1683,1684,1685,1686]`
    let options = {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
      method: "GET",
      redirect: "follow",
    };
    return fetch(endpoint, options)
      .then((res) => res.json())
      .then((json) => {
        return json;
      })
      .catch((error) => console.log(error));
};

export const semesterFeedbackAnalytics = async (type,id,feedback_type) => {
  // let endpoint = `${BASE_API_URL}/semester_feedback/generateFeedbackReports?${type}=${id}`;
  let endpoint = "";
  if(type == "master_feedback_id"){
    endpoint = `https://rm6nqresne.execute-api.ap-south-1.amazonaws.com/${REACT_INPLACE_ENV}/semesterFeedbackAnalytics?feedback_id=&master_feedback_id=${id}&feedback_type=${feedback_type}`
  }else{
    endpoint = `https://rm6nqresne.execute-api.ap-south-1.amazonaws.com/${REACT_INPLACE_ENV}/semesterFeedbackAnalytics?feedback_id=${id}&master_feedback_id=&feedback_type=${feedback_type}`
  }
  
  // let endpoint = `http://127.0.0.1:5000/semester_feedback/generateFeedbackReportsTemp?feedback_ids=[1408,1409,1410,1411,1412,1413,1414,1415,1416,1417,1418,1419,1420,1421,1422,1423,1424,1425,1426,1427,1428,1429,1430,1431,1432,1433,1434,1435,1436,1437,1438,1439,1440,1441,1442,1443,1444,1445,1446,1447,1448,1449,1450,1451,1452,1453,1454,1455,1456,1457,1458,1459,1460,1461,1462,1463,1464,1465,1466,1467,1468,1469,1470,1471,1472,1473,1474,1475,1476,1477,1478,1479,1480,1481,1482,1483,1484,1485,1486,1487,1488,1489,1490,1491,1492,1493,1494,1495,1496,1497,1498,1499,1500,1501,1502,1503,1504,1505,1506,1507,1508,1509,1510,1511,1512,1513,1514,1515,1516,1517,1518,1519,1520,1521,1522,1523,1524,1525,1526,1527,1528,1529,1530,1531,1532,1533,1534,1535,1536,1537,1538,1539,1540,1541,1542,1543,1544,1545,1546,1547,1548,1549,1550,1551,1552,1553,1554,1555,1556,1557,1558,1559,1560,1561,1562,1563,1564,1565,1566,1567,1568,1569,1570,1571,1572,1573,1574,1575,1576,1577,1578,1579,1580,1581,1582,1583,1584,1585,1586,1587,1588,1589,1590,1591,1592,1593,1594,1595,1596,1597,1598,1599,1600,1601,1602,1603,1604,1605,1606,1607,1608,1609,1610,1611,1612,1613,1614,1615,1616,1617,1618,1619,1620,1621,1622,1623,1624,1625,1626,1627,1628,1629,1630,1631,1632,1633,1634,1635,1636,1637,1638,1639,1640,1641,1642,1643,1644,1645,1646,1647,1648,1649,1650,1651,1652,1653,1654,1655,1656,1657,1658,1659,1660,1661,1662,1663,1664,1665,1666,1667,1668,1669,1670,1671,1672,1673,1674,1675,1676,1677,1678,1679,1680,1681,1682,1683,1684,1685,1686]`
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
      'X-Amz-Invocation-Type': 'Event'
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const getFeedbackSummaryResults = async (type,id) => {
  let endpoint = `${BASE_API_URL}/semester_feedback/generateFeedbackReports?${type}=${id}`;
  // let endpoint = `http://127.0.0.1:5000/semester_feedback/generateFeedbackReportsSummaryTemp?feedback_ids=[3600,3601,3602,3603,3604,3605,3606,3607,3608,3609,3610,3611,3612,3613,3614,3615,3616,3617,3618,3619,3620,3621,3622,3623,3624,3625,3626,3627,3628,3629,3630,3631,3632,3633,3634,3635,3636,3637,3638,3639,3640,3641,3642,3643,3644,3645,3646,3647,3648,3649,3650,3651,3652,3653,3654,3655,3656,3657,3658,3659,3660,3661,3662,3663,3664,3665,3666,3667,3668,3669,3670,3671,3672,3673,3674,3675,3676,3677,3678,3679,3680,3681,3682,3683,3684,3685,3686,3687,3688,3689,3690,3691,3692,3693,3694,3695,3696,3697,3698,3699,3700,3701,3702,3703,3704,3705,3706,3707,3708,3709,3710,3711,3712,3713,3714,3715,3716,3717,3718,3719,3720,3721,3722,3723,3724,3725,3726,3727,3728,3807,3808,3809]`
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};


//phase 2 feedback apis
export const getGeneralFeedbackCategories = async () => {
  let endpoint = `${BASE_API_URL}/semester_feedback/getGeneralFeedbackCategories`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const getAllGeneralFeedbackQuestions = async (category_ids, dept_data,training_questions) => {
  // let endpoint = `${BASE_API_URL}/semester_feedback/getAllGeneralFeedbackQuestions?category_ids=${JSON.stringify(category_ids)}&dept_data=${JSON.stringify(dept_data)}&training_questions=${training_questions}`;
  let endpoint = `${BASE_API_URL}/semester_feedback/getAllGeneralFeedbackQuestions?category_ids=${JSON.stringify(category_ids)}&dept_data=${JSON.stringify(dept_data)}&training_questions=${training_questions}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const getGeneralCategoryQuestions = async (category_id, dept_data,training_questions) => {
  let endpoint = `${BASE_API_URL}/semester_feedback/getGeneralCategoryQuestions?category_id=${category_id}&dept_data=${JSON.stringify(dept_data)}&training_questions=${training_questions}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const generateGeneralFeedbackReports = async (master_feedback_id) => {
  let endpoint = `https://mj6ki4lwr6.execute-api.ap-south-1.amazonaws.com/${REACT_INPLACE_ENV}/general_feedback?master_feedback_id=${master_feedback_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const generateMcqReports = async (test_id) => {
  let endpoint = `https://strson3456.execute-api.ap-south-1.amazonaws.com/${REACT_INPLACE_ENV}/mcqreports?function_name=mcqTestReports&test_id=${test_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const generateLiveAssessmentsReports = async (assessment_id) => {
  let endpoint = `https://strson3456.execute-api.ap-south-1.amazonaws.com/${REACT_INPLACE_ENV}/liveassessments?function_name=liveAssessmentsReports&assessment_id=${assessment_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const generateLiveSurveyReports = async (survey_id) => {
  let endpoint = `https://strson3456.execute-api.ap-south-1.amazonaws.com/${REACT_INPLACE_ENV}/livesurveys?function_name=liveSurveyReports&survey_id=${survey_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const generateWeeklyChallengeReports = async (challenge_id) => {
  let endpoint = `https://strson3456.execute-api.ap-south-1.amazonaws.com/${REACT_INPLACE_ENV}/challengereports?function_name=weeklyChallengeReports&challenge_id=${challenge_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};


export const editFeedbackDetails = async (master_feedback_id, start_time, end_time) => {
  let endpoint = `${BASE_API_URL}/semester_feedback/editSemesterFeedbackDetails`;
  const formData = new FormData();
  formData.append("master_feedback_id", master_feedback_id);
  formData.append("start_time", start_time);
  formData.append("end_time", end_time);
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: "POST",
    redirect: "follow",
    body: formData,
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};

export const getFeedbackStats = async (master_feedback_id) => {
  let endpoint = `${BASE_API_URL}/semester_feedback/getSemesterFeedbackStats?master_feedback_id=${master_feedback_id}`;
  let options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    },
    method: "GET",
    redirect: "follow",
  };
  return fetch(endpoint, options)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((error) => console.log(error));
};