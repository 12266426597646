import React from "react";
import Navbar from "../components/navbar/navbar.component";
import StartChallenge from "../components/startChallenge/startChallenge.component";

const StartChallengePage = () => {
    return (
        <div className="weekly-challenge" style={{overflowX:"hidden"}}>
            <Navbar />
            <div className="row" style={{marginTop:'70px',backgroundColor:'#f9f9f9'}}>
                <StartChallenge />
            </div>
        </div>
    );
}

export default StartChallengePage;