import React, { useEffect, useMemo, useState } from 'react';
import './App.css';
import { Routes, Route,useLocation, useNavigate } from 'react-router-dom';
//import Navbar from './components/navbar/navbar.component';
import WeeklyChallenge from './pages/WeeklyChallenge';
import CreateWeeklyChallenge from './pages/CreateWeeklyChallenge';
import StartChallengePage from './pages/StartChallengePage';
import EditWeeklyChallengePage from './pages/editWeeklyChallengePage';
import ChallengeLobbyPage from './pages/challengeLobbyPage';
import QuestionnairePage from './pages/questionnairePage';
import LeaderBoard from './components/leaderBoard/leaderBoard.component';
import SpinWheelPage from './pages/spinWheelPage';
import FinalLeaderBoardPage from './pages/finalLeaderBoardPage';
import GenerateTeamsPage from './pages/generateTeamsPage';
import UserChallengeLobbyPage from './pages/userChallengeLobbyPage';
import JoinChallenge from './components/participants/joinChallenge/joinChallenge.component';
import ViewChallenge from './components/viewers/viewChallenge.component';
import FacultySectionMapping from './pages/FacultySectionMapping';
import IndexPage from './pages/IndexPage';
import AddFacultyToSectionsPage from './pages/AddFacultyToSectionsPage';
import FacultyPerformanceReviewPage from './pages/FacultyPerformanceReviewPage';
import { useDispatch, useSelector } from 'react-redux';
import { setFacultyWebBaseUrl } from './store/reducers/common.reducer';
import Logout from './pages/Logout';

//feedback components
import FeedbackHome from './components/Feedbacks/feedbackHome/FeedbackHome';
import SubjectFeedback from './components/Feedbacks/subjectFeedback/SubjectFeedback';
import FeedbackDetails from './components/Feedbacks/feedbackDetails/Feedbackdetails';
import CategoryQuestion from './components/Feedbacks/categoryQuestions/CategoryQuestion';
import Submit from './components/Feedbacks/submit/Submit';
import FeedbackResults from './components/Feedbacks/feedbackResults/feedbackResults.component';

//feedback phase 2 components
import GeneralFeedback from './components/Feedbacks/generalFeedback/generalFeedbackDetails/generalFeedbackDetails.component';
import FeedbackCategories from './components/Feedbacks/generalFeedback/feedbackCategories/feedbackCategories.component';
import GeneralFeedbackQuestions from './components/Feedbacks/generalFeedback/feedbackQuestions/generalFeedbackQuestions.component';
import FeedbackGeneralResults from './components/Feedbacks/feedbackResults/feedbackGeneralResults.component';

import FeedbackSummaryReport from './components/Feedbacks/feedbackSummaryReports/feedbackSummaryReport';
import ReportsDownload from './components/facultyReportDownloads/reportsDownload.component';

// import ThemeProvider from './theme';

function CheckAuthComponent({facultyWebBaseUrl}) {
  console.log('triggering redirection on no url match')
  window.location.replace(facultyWebBaseUrl);
}

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const facultyWebBaseUrl = useSelector(state => state.Common?.facultyWebBaseUrl)

  const [firstLoad, setFirstLoad] = useState(true);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [token, setToken] = useState(localStorage.getItem('token'));

  // const searchParams = new URLSearchParams(window.location.search);
  // let user;
  // let token; 

  const searchParams = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []); 


  // let userDataViaStorage =JSON.parse(localStorage.getItem('user'));

  useEffect(()=>{
    if(location.state?.userData){
      setUser(location.state?.userData)
    }

    // else {
    //   setUser(userDataViaStorage);
    // }  
  },[location.state?.userData])

  // console.log(token)
  // console.log(user)
  // console.log(`searchParams.has("user") : ${searchParams.has("user")}`)

    // Setting First Load False after first load
    useEffect(()=>{
      setFirstLoad(false)
    },[])
  

  // Handling Routing to HTML Modules Base URL
  useEffect(()=>{
    const userCollegeId = user?.college_id;
    console.log(userCollegeId)
    let facultyWebUrl;

    if(!userCollegeId && !firstLoad){
      navigate(-1)
      return
    }

    if (!firstLoad) {
      console.log('Not First Load')
    }
    else{
      console.log('First Load')
    }
    
    // console.log(process.env.ENVIRONMNET)
    // if (!process.env.ENVIRONMNET || process.env.ENVIRONMNET === 'staging') {
    console.log(process.env)

    if (process.env.REACT_APP_ENVIRONMNET === "staging") {
    // if (process.env.NODE_ENV === 'development') {
    // dev code
    console.log("Detected Staging env");
    facultyWebUrl = 'https://staging.educator.edwisely.com';
    } 
    else {    
    // production code
    console.log("Detected Production env");
    facultyWebUrl = userCollegeId === 27 ? "https://nextgenfaculty.rmkec.ac.in"
    :  userCollegeId === 29 ? "https://nextgenfaculty.rmd.ac.in"
    :  userCollegeId === 32 ? "https://nextgenfaculty.rmkcet.ac.in"
    :  userCollegeId === 38 ? "https://kluai.educator.edwisely.com"
    :  userCollegeId === 40 ? "https://sailmentor.sairam.edu.in"
    :  userCollegeId === 41 ? "https://sailmentor.sairamit.edu.in"
    :"https://prod.educator.edwisely.com";
    }

    console.log(facultyWebUrl)
    dispatch(setFacultyWebBaseUrl(facultyWebUrl))

    if (!token && !searchParams.has("user")) {
    // if (!token && !user?.token && !searchParams.has("token")) {
      // if (!token && !firstLoad) {
      console.log('triggering redirection on no token and searchParams')
      window.location.replace(facultyWebUrl);
    }
    
  },[user,token,dispatch,firstLoad,navigate,searchParams])

  useEffect(()=>{
    setUser(JSON.parse(localStorage.getItem('user')));
    setToken(localStorage.getItem('token'));
  },[localStorage.getItem('user'),localStorage.getItem('token')])



  // console.log(`user?.role_id : ${user?.role_id}`)
  return (
    <div className="App">
      {/* <ThemeProvider> */}
      <Routes>
          {user?.role_id <=3 ? 
            <>
            <Route exact path="/facultyPerformanceReview" element={<FacultyPerformanceReviewPage />} />
            <Route exact path="/facultyPerformanceReview/:subsemId/:facId/:sectionId/:unitId" element={<FacultyPerformanceReviewPage />} />
            <Route exact path="/facultyPerformanceReview/sendSurvey" element={<FacultyPerformanceReviewPage />} />
            </>
            : null
          }
          <Route exact path="/" element={<IndexPage />} />
          <Route exact path="/weeklyChallenge" element={<WeeklyChallenge />} />
          <Route exact path="/createWeeklyChallenge" element={<CreateWeeklyChallenge />} />
          <Route exact path="/startChallenge" element={<StartChallengePage />} />
          <Route exact path="/editWeeklyChallenge" element={<EditWeeklyChallengePage />} />
          <Route exact path="/challengeLobby" element={<ChallengeLobbyPage />} />
          <Route exact path="/questionnaire" element={<QuestionnairePage />} />
          <Route exact path="/leaderBoard" element={<LeaderBoard />} />
          <Route exact path="/spinWheel" element={<SpinWheelPage />} />
          <Route exact path="/finalLeaderBoard" element={<FinalLeaderBoardPage />} />
          <Route exact path="/generateTeams" element={<GenerateTeamsPage />} />
          <Route exact path="/userChallengeLobby" element={<UserChallengeLobbyPage />} />
          <Route exact path="/joinChallenge" element={<JoinChallenge />} />
          <Route exact path="/viewChallenge" element={<ViewChallenge />} />
          <Route exact path="/feedbackHome" element={<FeedbackHome />} />
          <Route exact path="/feedback" element={<SubjectFeedback />} />
          <Route exact path="/facultySectionMapping" element={<FacultySectionMapping />} />
          <Route exact path="/facultySectionMapping/addFaculty/:uniqueId" element={<AddFacultyToSectionsPage />} />
          <Route exact path="/questions" element={<CategoryQuestion />} />
          <Route exact path="/details" element={<FeedbackDetails />} />
          <Route exact path="/submit" element={<Submit />} />
          <Route exact path="/feedbackResults" element={<FeedbackResults />} />
          <Route exact path="/feedbackSummaryReports" element={<FeedbackSummaryReport />} />

          {/* feedback phase 2 */}
          <Route exact path="/feedbacks/generalFeedback" element={<GeneralFeedback />} />
          <Route exact path="/feedbacks/feedbackCategories" element={<FeedbackCategories />} />
          <Route exact path="/feedbacks/feedbackQuestions" element={<GeneralFeedbackQuestions />} />
          <Route exact path="/feedbacks/generalFeedbackResults" element={<FeedbackGeneralResults />} />

          <Route exact path="/reports/inplaceReports" element={<ReportsDownload />} />

          <Route path="/logout" element={<Logout/>} />
          <Route path="/*" element={<CheckAuthComponent facultyWebBaseUrl={facultyWebBaseUrl}/>} />
      </Routes>
      {/* </ThemeProvider> */}
    </div>
  );
}

export default App;
