import { useEffect, useRef, useState } from "react";
import "./chatroom.css";
import { AiOutlineSend } from "react-icons/ai";
import firebase from "firebase";
import React, { Component } from "react";
import notification from "./Notification.mp3";
import { current_user_id } from "../../api/weeklyChallengeAPIs";
import firebaseConfig from "./chatroom.js";
const firebaseConfig2 = {
  apiKey: "AIzaSyAaXnabo2q527gyVrTd8klX37uJOo42OEI",
  authDomain: "edwisely-college-student.firebaseapp.com",
  databaseURL: "https://edwisely-college-student.firebaseio.com",
  projectId: "edwisely-college-student",
  storageBucket: "edwisely-college-student.appspot.com",
  messagingSenderId: "1042030850549",
  appId: "1:1042030850549:web:ef28dedda735c3ccbfb0bc",
};

// firebase.initializeApp(firebaseConfig2);
export var firebase_db = firebase.database();
var db = firebase.database();
let user_id = current_user_id;
let username;
let getlocalstorage = localStorage.getItem("profile_details");
const audio = new Audio(notification);
export let messages = [];
if (localStorage.getItem("profile_details")) {
  getlocalstorage = JSON.parse(getlocalstorage);
  username = getlocalstorage.first_name + " " + getlocalstorage.last_name;
  user_id = current_user_id;
} else {
  username = "User";
  user_id = current_user_id;
}

function Chatwidget2(props) {
  const bottomRef = useRef(null);
  const [msgrecieved, setmsgrecieved] = useState("");
  const firstUpdate = useRef(true);
  const secondUpdate = useRef(false);
  const deletechat = useRef(false);
  useEffect(() => {
    db.ref(props.roomid).on("value", (snapshot) => {
      messages = [];
      snapshot.forEach((snap) => {
        console.log(snap.val());
        messages.push(snap.val());
      });

      console.log(messages);
      setmsgrecieved(messages);
      setTimeout(() => {
        document
          .getElementById("scrollintoview")
          .scrollIntoView({ behavior: "smooth" });
      }, 1000);
      if (firstUpdate.current) {
        firstUpdate.current = false;
        secondUpdate.current = true;
        console.log("first update chat");
      } else if (secondUpdate.current) {
        secondUpdate.current = false;
        console.log("second update chat");
      } else if (!props.deletechat) {
        console.log("New message found");
        props.foundnewmsg();
        //audio.play();
      }
    });
  }, []);
  if (props.deletechat) {
    console.log("chat deleted!");
    db.ref(props.roomid).remove();
    deletechat.current = true;
  }

  // setmsgrecieved(true);
  const [msg, setFormValue] = useState("");

  const sendMessage = async (e) => {
    e.preventDefault();
    const timestamp = Date.now();
    db.ref(props.roomid).push({
      msg: msg,
      ts: `${timestamp}`,
      name: username,
      id: `${user_id}`,
    });

    setFormValue("");
    // bottomRef.current.scrollIntoView({ behavior: "smooth" });

    setTimeout(() => {
      document
        .getElementById("scrollintoview")
        .scrollIntoView({ behavior: "smooth" });
    }, 10);
  };

  return (
    <>
      <div id="conversation" class="conversationclassName">
        {messages &&
          messages.map((msg) => (
            <ChatMessage key={msg.timestamp} message={msg} />
          ))}
        <div ref={bottomRef} id="scrollintoview" />
      </div>

      <form onSubmit={sendMessage}>
        <div style={{ display: "flex", background: "white" }}>
          <input
            className="sendmessage form-control"
            value={msg}
            onChange={(e) => setFormValue(e.target.value)}
            placeholder="Chat with your team"
          />
          <button type="submit" style={{backgroundColor:"#FFDFD6", color:"darkslategrey"}} disabled={!msg}>
            <AiOutlineSend style={{ fontSize: "40px" }} />
          </button>
        </div>
      </form>
    </>
  );
}

function ChatMessage(props) {
  const { msg, id, ts, name } = props.message;
  let date = new Date(parseInt(ts));
  let Filter = require('bad-words');
  let filter = new Filter();

  let hours = date.getHours();
  let minutes = date.getMinutes();
  const messageClass = parseInt(id) == user_id ? "sent" : "received";

  return (
    <>
      <div class={`msg ${messageClass}`}>
        <div class="msg-bubble">
          <div class="msg-info">
            <div class="msg-info-name">{name}</div>
            <div class="msg-info-time">{`${hours}:${minutes}`}</div>
          </div>

          <div class="msg-text">{filter.clean(msg)}</div>
        </div>
      </div>

      {/* <p>{content}</p>*/}
    </>
  );
}
export default Chatwidget2;
