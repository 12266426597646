import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ChallengeSpinWheel from "../components/challengeSpinWheel/challengeSpinWheel.component";
import Navbar from "../components/navbar/navbar.component";

const SpinWheelPage = (props) => {
    const location = useLocation();   
    return (
        <div className="weekly-challenge" style={{ overflowX: "hidden" }}>
            <Navbar />
            <div className="row" style={{ marginTop: '70px', backgroundColor: '#f9f9f9' }}>
                <ChallengeSpinWheel segTopics={location.state.allTopics} segColors={location.state.segColors}
                    topicsData={location.state.topicsData} challenge_id={location.state.challenge_id}
                    challenge_details={location.state.challenge_details} question_index={location.state.question_index}
                    selected_topic_name={location.state.topic_to_select} selected_topic_id={location.state.topic_to_select_id} viewer_topic_on={location.state.viewer_topic_on} />
            </div>
        </div>
    )
}

export default SpinWheelPage;