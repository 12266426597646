import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TableWithSorting from './TableWithSorting';
import Box from '@mui/material/Box';
import SearchComponent from './SearchComponent';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import NoDataFound from '../common/NoDataFound';
import InComponentLoader from '../common/InComponentLoader';
import Fade from '@mui/material/Fade';

// Tabs Functionality
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


// -------- Component

const DeptSemFacSectionMapping = () => {
    const {filteredSectionMapping,tableIsLoading} = useSelector((state) => state.SectionMapping);
    const [tabsValue, setTabsValue] = useState(0);
    // const {loadingFirstTime,setLoadingFirstTime} = useState(true);

    if (!filteredSectionMapping) {
      return;  
    }

    console.log(`tableIsLoading : ${tableIsLoading}`)
  const headArraySubject = [
    {
      id: 'department_name',
      label: 'Department',
    },
    {
      id: 'semester_id',
      label: 'Semester',
    },
    {
      id: 'subject_name',
      label: 'Subject',
    },
    {
      id: 'section_name',
      label: 'Section',
    },
    {
      id: 'faculty_name',
      label: 'Faculty',
    },
  ];


  const headArraySection = [
    {
      id: 'department_name',
      label: 'Department',
    },
    {
      id: 'semester_id',
      label: 'Semester',
    },
    {
      id: 'section_name',
      label: 'Section',
    },
    {
      id: 'subject_name',
      label: 'Subject',
    },
    {
      id: 'faculty_name',
      label: 'Faculty',
    },
  ];

  const headArrayFaculty = [
    {
      id: 'department_name',
      label: 'Department',
    },
    {
      id: 'faculty_name',
      label: 'Faculty',
    },
    {
      id: 'semester_id',
      label: 'Semester',
    },
    {
      id: 'subject_name',
      label: 'Subject',
    },
    {
      id: 'section_name',
      label: 'Section',
    },
  ];

  // Assig
  let headArray;
  let heading;
  // console.log(tabsValue)
  switch (tabsValue) {
    case 0:
      headArray = headArraySubject;
      heading = "Subject Wise Faculty Mapping";
      break;
    case 1:
      headArray = headArraySection;
      heading = "Section Wise Faculty Mapping";
      break;
    case 2:
      headArray = headArrayFaculty;
      heading = "Faculty Wise Section Mapping";
      break;

    default:
      headArray = headArraySubject;
      heading = "Subject Wise Faculty Mapping";
      break;
  }


  // Table Data
  const tableData = filteredSectionMapping?.length ? (
    <Grid item xs={12}>
      <TableWithSorting
        data={filteredSectionMapping}
        heading={heading}
        headArray={headArray}
        primaryKey={"unique_id"}
      />
    </Grid>
  ) : <React.Fragment/>;

  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue);
  };


    return (
      <React.Fragment>
        <Box sx={{ minWidth: 0, borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabsValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            // indicatorColor ='#ffdfd6'
          >
            <Tab label="Subject View" {...a11yProps(0)} />
            <Tab label="Section View" {...a11yProps(1)} />
            <Tab label="Faculty View" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <Grid
          container
          sx={{
            display: "flex",
            justfityContent: "center",
            flexDirection: "column",
            alignItems: "center",
            pt: 2,
          }}
        >
          <Grid item xs={12}>
            <SearchComponent tabsValue={tabsValue}/>{" "}
          </Grid>

          {/* {filteredSectionMapping?.length !== 0 && (
                tableData
          )} */}


          {tableIsLoading && (
            <Grid item pt={"100px"}  xs={12}>
              <InComponentLoader />
            </Grid>
          )}

          {filteredSectionMapping?.length !== 0 && !tableIsLoading && (
              <Fade in={!tableIsLoading}>
                {tableData}
              </Fade>
          )}
          {/* {tableData} */}
          {/* <TabPanel value={tabsValue} index={0}>
            {tableData}
          </TabPanel>
          <TabPanel value={tabsValue} index={1}>
            {tableData}
          </TabPanel>
          <TabPanel value={tabsValue} index={2}>
            {tableData}
          </TabPanel> */}

          {!tableIsLoading && filteredSectionMapping?.length === 0 && (
            <Grid item  xs={12}>
              <NoDataFound message="No Data Found, Please Re-Apply Filter" />
              {/* No Data Found */}
            </Grid>
          )}

        </Grid>
      </React.Fragment>
    );

}

export default DeptSemFacSectionMapping;

