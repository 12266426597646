import * as React from "react";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Grid from '@mui/material/Grid';

SearchField.defaultProps={
    onChange : null,
}

export default function SearchField({onChange}) {
return (
  <Grid container item sx={{ "& > :not(style)": { m: 2} }}>
    <TextField
      fullWidth
      id="input-with-icon-textfield"
    //   label="Search Faculty"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircle />
          </InputAdornment>
        ),
      }}
      variant="standard"
      size="small"
      placeholder="Search Faculty"
      onChange={onChange}
    />
  </Grid>
);
}
